import React from 'react';
import { useSelector } from 'react-redux';
import snackbarSelectors from 'modules/snackbar/snackbarSelectors';
import ConnectedSnackbar from './ConnectedSnackbar';

const SnackbarConnection = () => {
	const ids = useSelector(snackbarSelectors.getMessageIds);

	return (
		<>
			{ids.map((id) => (
				<ConnectedSnackbar key={id} id={id} />
			))}
		</>
	);
};

export default SnackbarConnection;

import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';

import { roles } from 'utility/constants/roles';

import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';
import { useSelector } from 'react-redux';
import { isBasketAdmin as getIsBasketAdmin } from 'modules/auth';

const HomeRouter = (props) => {
	const { navData } = props;

	const isBasketAdmin = useSelector(getIsBasketAdmin);

	if (isBasketAdmin) {
		return <Redirect to="/shop/marketplace/admin-packages" />;
	}

	return (
		<Switch>
			<RoleRestrictedRoute
				exact
				path={navData?.path}
				component={navData?.component}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
		</Switch>
	);
};

HomeRouter.propTypes = {
	navData: PropTypes.object.isRequired,
};

export default HomeRouter;

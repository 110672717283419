import { createContext } from 'react';

import loadBalancerConstants from 'utility/constants/loadBalancers';

const LoadBalancerFormContext = createContext({
	destinationPort: ['', () => null],
	enableSSLTermination: [false, () => null],
	includeIntermediateCertificate: [false, () => null],
	ips: [[], () => null],
	isSubmitting: undefined,
	name: ['', () => null],
	regionId: ['', () => null],
	service: [loadBalancerConstants.SERVICE.WEB, () => null],
	sessionPersistence: [false, () => null],
	sourcePort: ['', () => null],
	sslCertificate: ['', () => null],
	sslCertificateError: undefined,
	sslIntermediateCertificate: ['', () => null],
	sslIntermediateCertificateError: undefined,
	sslPrivateKey: ['', () => null],
	sslPrivateKeyError: undefined,
	strategy: ['', () => null],
});

export default LoadBalancerFormContext;

import { defaultParams, defaultData } from 'modules/gtm/defaults';

const cartStack = {
	CART_STACK_SEND_ANALYTICS: ({ payload: { countryCode, sendGtmData } }) => {
		const obj = {
			...defaultParams,
			event: 'lw.structured',
			eventName: 'CartStack',
			data: {
				...defaultData,
				category: sendGtmData ? 'Data Sent' : 'Suppressed',
				label: countryCode,
			},
		};
		return obj;
	},
};

export default cartStack;

import testUtilActions from 'testUtils/actions';
import actions from './actions';

const defaultState = {
	/* oneOf: 'vps', 'cloudDedicated', or a private parent uniq_id */
	deployOnto: 'vps',
	selectedValue: '',
};

const options = (state = defaultState, action) => {
	const { type } = action;
	switch (type) {
		case actions.SET_SERVER_RESIZE_DEPLOY_ONTO:
			return { ...state, deployOnto: action.deployOnto };
		case actions.SET_SERVER_RESIZE_CLOUD_FILTER_SELECTED_VALUE:
			return { ...state, selectedValue: action.value };
		case testUtilActions.TEST_UTIL_CLEAR_STATE:
		case actions.SET_SERVER_RESIZE_RESET:
			return defaultState;
		default:
			return state;
	}
};

export default options;

import { css } from 'styled-components';

export default css`
	// This only works in Webkit-based browsers
	&::-webkit-scrollbar {
		width: 6px;
	}
	&::-webkit-scrollbar-track {
		background: ${({ theme }) => theme.palette.common.white1};
	}
	&::-webkit-scrollbar-thumb {
		background: ${({ theme }) => theme.palette.primary.washed};
		border-radius: 3px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: ${({ theme }) => theme.palette.primary.faded};
	}
	scrollbar-width: thin; // These are needed for Firefox (is the newer standard)
	scrollbar-color: ${({ theme }) =>
		`${theme.palette.primary.washed} ${theme.palette.common.white1}`};
`;

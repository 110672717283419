import { call, put, select, take, takeEvery } from 'redux-saga/effects';
import {
	actions as vmwareLoginUrlActions,
	selectors as vmwareLoginUrlSelectors,
} from 'modules/api/vmware/vcloud/vdc/loginUrlModule';
import snackbarSagas from 'modules/snackbar/sagas';
import actions from './actions';

export function* openVmwarePortal({ uniqId }) {
	yield put(
		vmwareLoginUrlActions.fetch({
			uniq_id: uniqId,
		}),
	);
	const result = yield take([
		vmwareLoginUrlActions.setType,
		vmwareLoginUrlActions.errorType,
	]);
	const isError = result?.type === vmwareLoginUrlActions.errorType;
	if (isError) {
		yield call(snackbarSagas, {
			error: true,
			errorMessage: 'Unable to load the VMWare Portal',
		});
	}
	const url = yield select(vmwareLoginUrlSelectors.getUrl);
	if (url) yield call([window, 'open'], url, '_blank', 'noopener');
}

export default function* root() {
	yield takeEvery(actions.OPEN_VMWARE_PORTAL, openVmwarePortal);
}

import { spawn } from 'redux-saga/effects';

import addon from './addon/sagas';
import { sagas as detailsSagas } from './detailsModule';
import { sagas as detailList } from './detailListModule';
import { flatDetailsSagas as flatDetails } from './flatDetailsModule';
import { sagas as price } from './priceModule';
import { sagas as primaryCategoryMapping } from './primaryCategoryMappingModule';

export default function* sagas() {
	yield spawn(addon);
	yield spawn(detailList);
	yield spawn(detailsSagas);
	yield spawn(flatDetails);
	yield spawn(price);
	yield spawn(primaryCategoryMapping);
}

import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import LWTypography from 'components/common/LWTypography';
import LWCheckbox from 'components/atoms/LWCheckbox';

import Context from './context';

const SSLInputs = () => {
	const {
		enableSSLTermination: [enableSSLTermination, setEnableSSLTermination],
		includeIntermediateCertificate: [
			includeIntermediateCertificate,
			setIncludeIntermediateCertificate,
		],
		isSubmitting,
		sslCertificate: [sslCertificate, setSslCertificate],
		sslCertificateError,
		sslIntermediateCertificate: [
			sslIntermediateCertificate,
			setSslIntermediateCertificate,
		],
		sslIntermediateCertificateError,
		sslPrivateKey: [sslPrivateKey, setSslPrivateKey],
		sslPrivateKeyError,
	} = useContext(Context);

	const handleCertChange = (event) => setSslCertificate(event.target.value);
	const handleEnableSSLTerminationChange = () =>
		setEnableSSLTermination(!enableSSLTermination);
	const handleIncludeIntermediateCertificateChange = () =>
		setIncludeIntermediateCertificate(!includeIntermediateCertificate);
	const handleIntCertChange = (event) =>
		setSslIntermediateCertificate(event.target.value);
	const handlePrivateKeyChange = (event) =>
		setSslPrivateKey(event.target.value);

	return (
		<Grid spacing={2} container>
			<Grid item xs={12}>
				<LWCheckbox
					checked={enableSSLTermination}
					onChange={handleEnableSSLTerminationChange}
					ariaLabel="Enable SSL Termination"
					label={
						<LWTypography bold inline>
							Enable Load Balancer SSL Termination ($25.00 / mo)
						</LWTypography>
					}
					disabled={isSubmitting}
				/>
			</Grid>
			{enableSSLTermination && (
				<>
					<Grid item xs={12}>
						<LWCheckbox
							checked={includeIntermediateCertificate}
							onChange={handleIncludeIntermediateCertificateChange}
							ariaLabel="Include Intermediate Certificate"
							label={
								<LWTypography bold inline>
									Include Intermediate Certificate
								</LWTypography>
							}
							disabled={isSubmitting}
						/>
					</Grid>
					{includeIntermediateCertificate && (
						<>
							<Grid item xs={3}>
								<LWTypography variant="body2">
									<strong>Intermediate Certificate:</strong>
								</LWTypography>
							</Grid>
							<Grid item xs={9}>
								<FormControl variant="outlined">
									<TextareaAutosize
										placeholder="Paste Intermediate Certificate"
										value={sslIntermediateCertificate}
										onChange={handleIntCertChange}
										rowsMin={5}
										rowsMax={5}
										disabled={isSubmitting}
									/>
								</FormControl>
								{Boolean(sslIntermediateCertificateError) && (
									<Box mt={1}>
										<LWTypography variant="body2" color="error">
											{sslIntermediateCertificateError}
										</LWTypography>
									</Box>
								)}
							</Grid>
						</>
					)}
					<Grid item xs={3}>
						<LWTypography variant="body2">
							<strong>SSL Certificate:</strong>
						</LWTypography>
					</Grid>
					<Grid item xs={9}>
						<FormControl variant="outlined">
							<TextareaAutosize
								placeholder="Paste SSL Certificate"
								value={sslCertificate}
								onChange={handleCertChange}
								rowsMin={5}
								rowsMax={5}
								disabled={isSubmitting}
							/>
						</FormControl>
						{Boolean(sslCertificateError) && (
							<Box mt={1}>
								<LWTypography variant="body2" color="error">
									{sslCertificateError}
								</LWTypography>
							</Box>
						)}
					</Grid>
					<Grid item xs={3}>
						<LWTypography variant="body2">
							<strong>Private Key:</strong>
						</LWTypography>
					</Grid>
					<Grid item xs={9}>
						<FormControl variant="outlined">
							<TextareaAutosize
								placeholder="Paste Private Key"
								value={sslPrivateKey}
								onChange={handlePrivateKeyChange}
								rowsMin={5}
								rowsMax={5}
								disabled={isSubmitting}
							/>
						</FormControl>
						{Boolean(sslPrivateKeyError) && (
							<Box mt={1}>
								<LWTypography variant="body2" color="error">
									{sslPrivateKeyError}
								</LWTypography>
							</Box>
						)}
					</Grid>
				</>
			)}
		</Grid>
	);
};

export default SSLInputs;

import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';
import SubNavRoutes from 'components/routers/SubNavRoutes';
import { roles } from 'utility/constants/roles';

const ProjectDashboard = lazy(() =>
	import('containers/pages/projects/ProjectDashboard'),
);

const ProjectsRouter = (props) => {
	const { navData } = props;
	return (
		<Switch>
			<RoleRestrictedRoute
				exact
				path={navData?.path}
				component={navData?.component}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
			<RoleRestrictedRoute
				path={`${navData?.path}/dashboard/:projectId`}
				component={ProjectDashboard}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
			<SubNavRoutes
				subNavs={navData?.subNav}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
		</Switch>
	);
};

ProjectsRouter.propTypes = {
	navData: PropTypes.object.isRequired,
};

export default ProjectsRouter;

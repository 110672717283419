import { createSelector } from 'reselect';

const getStateSlice = (state) => state.server.targetPower;

const getTargetPower = createSelector(
	getStateSlice,
	(slice) => slice.targetPower,
);

const selectors = {
	getStateSlice,
	getTargetPower,
};

export default selectors;

import { LWApiPublic } from 'club-sauce';
import getAppConfigs from 'modules/appConfig/reducer';

export const apiEndpointLocalStorageKey = 'apiEndpoint';

const appConfigs = getAppConfigs();

// allow for overriding the api endpoint in local storage in non production environments
const allowOverride = process.env.REACT_APP_CONFIG !== '!prod';
const localUrl = allowOverride
	? window.localStorage.getItem(apiEndpointLocalStorageKey)
	: undefined;

// remove trailing slash in base urls in app configs
const fixedUrl = appConfigs?.baseURL.slice(0, -1);

const api = new LWApiPublic({
	url: localUrl || fixedUrl,
});

export default api;

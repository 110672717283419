import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.network.loadbalancer.create;

const { actions, reducer, sagas, selectors, additionalSagas } = createAPIModule(
	{
		getStateSlice,
		actionType: 'NETWORK_LOAD_BALANCER_CREATE',
		method: 'POST',
		url: '/network/loadbalancer/create.json',
	},
);

export { actions, reducer, sagas, selectors, additionalSagas };

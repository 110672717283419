import React, { useContext } from 'react';

import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import LWTypography from 'components/common/LWTypography';

import Context from './context';

const NameInput = () => {
	const {
		isSubmitting,
		name: [name, setName],
	} = useContext(Context);

	const handleNameChange = (event) => setName(event.target.value);

	return (
		<Grid spacing={2} container>
			<Grid item xs={4}>
				<LWTypography>
					<strong>Name:</strong>
				</LWTypography>
			</Grid>
			<Grid item xs={8}>
				<FormControl variant="outlined">
					<TextField
						type="text"
						label="Load Balancer Name"
						value={name}
						onChange={handleNameChange}
						disabled={isSubmitting}
					/>
				</FormControl>
			</Grid>
		</Grid>
	);
};

export default NameInput;

import {
	call,
	put,
	takeLatest,
	select,
	all,
	takeEvery,
} from 'redux-saga/effects';
import {
	cartDetailsAdditionalSagas,
	cartDetailsSelectors,
} from 'modules/api/market/cart/detailsModule';
import get from 'lodash/get';
import {
	selectors as contactListSelectors,
	contactListAdditionalSagas,
} from 'modules/api/contact/listModule';
import snackbarSaga from 'modules/snackbar/sagas';
import routeActions from 'modules/route/actions';
import { cartItemRemoveSaga, waitForCartInit } from 'modules/cart/sagas';
import { getErrorStringHelper } from 'utility/redux/selectorHelperFunctions/apiModules';
import { cartItemModifyAdditionalSagas } from 'modules/api/market/cart/item/modifyModule';
import basketDomainsActions from '../actions/domains';
import {
	initOptionsFromBasket,
	initializeFlatDetails,
} from '../productConfig/flatDetailsSagas';
import { handleQuickAddToCart, retrieveConfigs } from '../productConfig/sagas';

function* handleInitConfigureDomains() {
	yield call(initializeFlatDetails, { productCode: 'DREG' });

	yield call(waitForCartInit);

	const dregItems = yield select(
		cartDetailsSelectors.getDomainRegistrationDomains,
	);

	const initOptionsEffects = dregItems.map(({ uuid }) =>
		call(initOptionsFromBasket, { uuid }),
	);

	yield all(initOptionsEffects);
}

function* bulkModifyDomainRegs({ properties = {} } = {}) {
	const cartUuid = yield select(cartDetailsSelectors.getUuid);

	const dregBasketItems = yield select(
		cartDetailsSelectors.getDomainRegistrationDomains,
	);

	// cant yield saga effects in array.map - using a for loop instead
	for (let i = 0; i < dregBasketItems.length; i += 1) {
		const { code, cycle, details, uuid } = dregBasketItems[i];
		const options = yield call(retrieveConfigs, uuid);
		const { error } = yield call(cartItemModifyAdditionalSagas.fetchDirectly, {
			alsowith: [], // speed up these calls with empty alsowiths, refetch the whole cart below
			cycle,
			item: {
				code,
				options,
				properties: { ...details.properties, ...properties },
				region_id: details.region_id,
			},
			uuid: cartUuid,
			item_uuid: uuid,
		});

		if (error) {
			yield call(snackbarSaga, { errorMessage: getErrorStringHelper(error) });
		}
	}

	const { error } = yield call(cartDetailsAdditionalSagas.fetchDirectly, {
		uuid: cartUuid,
	});

	if (error) {
		yield call(snackbarSaga, { errorMessage: getErrorStringHelper(error) });
	}
}

function* handleEditNameServers({ nameServers: { value } }) {
	const properties = {
		nameServers: value,
	};

	yield put(basketDomainsActions.toggleLoading({ key: 'DNS', value: true }));

	yield call(bulkModifyDomainRegs, { properties });

	yield put(basketDomainsActions.toggleLoading({ key: 'DNS', value: false }));
}

const mapKeys = (contactObj) => {
	// Use first phone number
	if (contactObj.phones) {
		/* eslint-disable-next-line no-param-reassign */
		contactObj.phone = contactObj.phones[0].number;
	}

	// Use first email
	if (contactObj.emails) {
		/* eslint-disable-next-line no-param-reassign */
		contactObj.email = contactObj.emails[0].address;
	}

	const keysObj = {
		fname: 'FirstName',
		lname: 'LastName',
		address: 'Address1',
		address2: 'Address2',
		email: 'EmailAddress',
		phone: 'Phone',
		city: 'City',
		state: 'StateProvince',
		country: 'Country',
		postal_code: 'PostalCode',
		organization: 'OrganizationName',
		fax: 'Fax',
		contact_role: 'JobTitle',
	};

	const keys = Object.keys(keysObj);

	const contact = {};

	keys.forEach((key) => {
		contact[keysObj[key]] = contactObj[key];
	});

	return contact;
};

function* handleEditWhoIs({ whoIs }) {
	const { lwtech: lwtechBool, ...contactObj } = whoIs;

	const contact = mapKeys(contactObj);

	const properties = {
		...contact,
		lwtech: Number(lwtechBool),
	};

	yield call(bulkModifyDomainRegs, { properties });

	yield put(basketDomainsActions.toggleLoading({ key: 'WHO_IS', value: true }));

	yield put(
		basketDomainsActions.toggleLoading({ key: 'WHO_IS', value: false }),
	);
}

function* handleRemoveDomain({ uuid, domain }) {
	yield put(basketDomainsActions.toggleDomainLoading({ domain, value: true }));

	yield call(cartItemRemoveSaga, { itemUuid: uuid });

	yield put(basketDomainsActions.toggleDomainLoading({ domain, value: false }));
}

function* handleAddDomain({
	domain,
	max_period: minPeriod,
	min_period: maxPeriod,
}) {
	yield put(basketDomainsActions.toggleDomainLoading({ domain, value: true }));
	yield call(contactListAdditionalSagas.fetchDirectly, null, null, {
		init: true,
	});

	const basketDomainsReducer = yield select((state) => state.basket.domains);

	// User has saved nameServers, using it for new domains in cart
	const nameServers = get(basketDomainsReducer, 'nameServers.value', [
		'ns.liquidweb.com',
		'ns1.liquidweb.com',
	]);

	const contactsList = yield select(contactListSelectors.getNativeItems);

	yield call(handleQuickAddToCart, {
		productCode: 'DREG',
		properties: {
			domain,
			max_period: minPeriod,
			min_period: maxPeriod,
			nameServers,
			...mapKeys(contactsList[0]),
		},
	});

	yield put(basketDomainsActions.toggleDomainLoading({ domain, value: false }));
}

function* handleModifyDomains() {
	yield put(routeActions.smartNavigate({ path: '/cart' }));

	yield call(bulkModifyDomainRegs);
}

export default function* brute() {
	yield takeLatest(
		basketDomainsActions.EDIT_NAME_SERVERS,
		handleEditNameServers,
	);

	yield takeLatest(
		basketDomainsActions.DREG_INIT_CONFIGURE_DOMAINS,
		handleInitConfigureDomains,
	);

	yield takeLatest(basketDomainsActions.SUBMIT_WHO_IS, handleEditWhoIs);

	yield takeEvery(basketDomainsActions.ADD_DOMAIN, handleAddDomain);
	yield takeEvery(basketDomainsActions.REMOVE_DOMAIN, handleRemoveDomain);
	yield takeEvery(
		basketDomainsActions.DREG_MODIFY_DOMAINS,
		handleModifyDomains,
	);
}

import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.network.dns.zone.importCpanelZone;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_DNS_ZONE_IMPORTCPANELZONE',
	method: 'POST',
	url: '/network/dns/zone/importCpanelZone.json',
});

export {
	actions as importCpanelZoneActions,
	reducer,
	sagas,
	selectors as importCpanelZoneSelectors,
};

import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.network.loadbalancer.available;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_LOADBALANCER_AVAILABLE',
	url: '/network/loadbalancer/available.json',
});

export { actions, reducer, sagas, selectors };

const notificationsActions = {
	NOTIFICATIONS_READ_SET: 'NOTIFICATIONS_READ_SET',
	pushReadNotifications: (readNotifications) => ({
		type: notificationsActions.NOTIFICATIONS_READ_SET,
		readNotifications,
	}),
	POLL_NOTIFICATIONS: 'POLL_NOTIFICATIONS',
	pollNotifications: ({ category, toFetchAfter, uniqId }) => ({
		type: notificationsActions.POLL_NOTIFICATIONS,
		category,
		toFetchAfter,
		uniqId,
	}),
	NOTIFICATIONS_TOGGLE_DRAWER: 'NOTIFICATIONS_TOGGLE_DRAWER',
	toggleDrawer: (drawerOpen) => ({
		type: notificationsActions.NOTIFICATIONS_TOGGLE_DRAWER,
		drawerOpen,
	}),
	NOTIFICATIONS_DISMISS_ALL: 'NOTIFICATIONS_DISMISS_ALL',
	dismissAll: () => ({ type: notificationsActions.NOTIFICATIONS_DISMISS_ALL }),
	NOTIFICATIONS_DISMISS_ONE: 'NOTIFICATIONS_DISMISS_ONE',
	dismissOne: (id) => ({
		type: notificationsActions.NOTIFICATIONS_DISMISS_ONE,
		id,
	}),
	NOTIFICATIONS_SHOW_MORE: 'NOTIFICATIONS_SHOW_MORE',
	showMore: () => ({ type: notificationsActions.NOTIFICATIONS_SHOW_MORE }),
	NOTIFICATION_REINIT: 'NOTIFICATION_REINIT',
	reinit: () => ({ type: notificationsActions.NOTIFICATION_REINIT }),
};

export default notificationsActions;

const domainRegistrationActions = {
	DOMAIN_REGISTRATION_PURCHASE_WHOIS: 'DOMAIN_REGISTRATION_PURCHASE_WHOIS',
	/**
	 * @param {object} param0
	 * @param {string} param0.uniqId
	 */
	purchaseWHOIS: ({ uniqId }) => ({
		type: domainRegistrationActions.DOMAIN_REGISTRATION_PURCHASE_WHOIS,
		uniqId,
	}),
};

export default domainRegistrationActions;

const templatesActions = {
	TEMPLATES_CLEAR: 'TEMPLATES_CLEAR',
	templatesClear: () => ({
		type: templatesActions.TEMPLATES_CLEAR,
	}),
	TEMPLATES_SET_SHOW_VALUE: 'TEMPLATES_SET_SHOW_VALUE',
	templatesSetShowValue: (showValue) => ({
		type: templatesActions.TEMPLATES_SET_SHOW_VALUE,
		showValue,
	}),
};

export default templatesActions;

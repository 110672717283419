const authActions = {
	USER_DETAILS_INIT: 'USER_DETAILS_INIT',
	userDetailsInit: (timeout) => ({
		type: authActions.USER_DETAILS_INIT,
		timeout,
	}),
	SET_USER_REDIRECTING: 'SET_USER_REDIRECTING',
	setUserRedirecting: (bool) => ({
		type: authActions.SET_USER_REDIRECTING,
		isRedirecting: bool,
	}),
	SET_USER_EXPIRED: 'SET_USER_EXPIRED',
	setUserExpired: ({ isUserExpired }) => ({
		type: authActions.SET_USER_EXPIRED,
		isUserExpired,
	}),
	SET_WAITING_FOR_TOKEN: 'SET_WAITING_FOR_TOKEN',
	setWaitingForToken: ({ isWaitingForToken }) => ({
		type: authActions.SET_WAITING_FOR_TOKEN,
		isWaitingForToken,
	}),
	ACCOUNT_AUTH_TOKEN_CLEAR: 'ACCOUNT_AUTH_TOKEN_CLEAR',
	clearToken: () => ({
		type: authActions.ACCOUNT_AUTH_TOKEN_CLEAR,
	}),
	ACCOUNT_AUTH_CLEAR: 'ACCOUNT_AUTH_CLEAR_STATE',
	/** clears auth module slice of state to initial values */
	clear: () => ({
		type: authActions.ACCOUNT_AUTH_CLEAR,
	}),
	AUTH_ACTIONS_GTM_IDENTIFY: 'AUTH_ACTIONS_GTM_IDENTIFY',
	gtmIdentify: ({ idData }) => ({
		type: authActions.AUTH_ACTIONS_GTM_IDENTIFY,
		payload: { idData },
	}),
};

export default authActions;

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import dialogActions from 'modules/dialogs/actions';
import { selectors as routeSelectors } from 'modules/route';
import cartActions from 'modules/cart/actions';

// MUI components
import Box from '@material-ui/core/Box';

// custom components
import LWTypography from 'components/common/LWTypography';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';

const AbandonCartModalContents = ({ onAbandoned }) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const pathName = useSelector(routeSelectors.getPathName);

	const closeDialog = () => {
		dispatch(dialogActions.close());
		if (pathName.startsWith('/shop/add')) {
			history.goBack();
		}
	};

	const onSubmit = () => {
		closeDialog();
		dispatch(
			cartActions.abandon({
				onAbandoned,
			}),
		);
	};

	return (
		<Box p={2}>
			<LWTypography BoxProps={{ pb: 4 }}>
				You are attempting to add a product to a quoted cart, which is not
				possible. The quoted cart will need to be abandoned so a new cart can be
				created in order to proceed.
			</LWTypography>
			<LWTypography bold BoxProps={{ pb: 4 }}>
				Would you like to abandon the quoted cart and add the product to a new
				cart?
			</LWTypography>
			<ConfirmCancel
				confirmText="Abandon Cart"
				confirm={onSubmit}
				cancel={closeDialog}
			/>
		</Box>
	);
};

AbandonCartModalContents.propTypes = {
	onAbandoned: PropTypes.func,
};

export default AbandonCartModalContents;

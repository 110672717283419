import { select, put, call } from 'redux-saga/effects';
import { takeSetOrError } from 'utility/redux/apiModuleHelpers';
import {
	actions as stormImageListActions,
	selectors as stormImageListSelectors,
} from 'modules/api/storm/image/listModule';

/**
 * Fetches image options if needed
 * @returns {boolean} ready status
 */
function* initStormImageList() {
	const isReady = yield select(stormImageListSelectors.hasData);
	if (isReady) return true;

	yield put(stormImageListActions.init({ page_size: 9999 }));
	const imageRes = yield call(takeSetOrError, {
		actions: stormImageListActions,
		selectors: stormImageListSelectors,
	});

	if (imageRes.type === stormImageListActions.errorType) {
		return false;
	}

	return true;
}

// eslint-disable-next-line import/prefer-default-export
export { initStormImageList };

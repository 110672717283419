// @ts-check
import { categoryMap } from 'modules/primaryCategoryMapping';

/** @param {string[]} categories */
const createAssetType = (categories) => {
	if (categories.includes('NocworxOpenstack')) return 'open stack'; // open stack has "VirtualDedicated" as well. TODO: create a better way to reduce the array to assetType. NEWMAN-2104
	const type = categories.reduce(
		(reduction, category) =>
			// @ts-ignore
			categoryMap[category] || reduction,
		'asset',
	);

	return type;
};

export default createAssetType;

import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.notifications.details;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'NOTIFICATIONS_DETAILS',
	isPaginated: true,
	url: '/notifications/details.json',
});

/**
 * @typedef Alert
 * @property {string} alertdate
 * @property {string} description
 * @property {string | number} id
 */

export { actions, reducer, sagas, selectors, getStateSlice };

import React, { lazy, Suspense, useState } from 'react';
import styled from 'styled-components';
import { Redirect, Route, Switch } from 'react-router-dom';
import get from 'lodash/get';

import Box from '@material-ui/core/Box';

import AccountBalanceBar from 'components/structural/AccountBalanceBar';
import AccountBar from 'containers/structural/AccountBar';
import Errors from 'components/structural/Errors';
import Navbar from 'containers/structural/NavBar';
import { navMap } from 'config/routingConfig';
import CartRouter from 'components/routers/CartRouter';
import ChatRouter from 'components/routers/ChatRouter';
import ShopRouter from 'components/routers/ShopRouter';
import SupportRouter from 'components/routers/SupportRouter';
import GlobalComponents from 'components/structural/GlobalComponents';
import Loading from 'components/common/Loading';
import scrollbars from 'components/styles/scrollbars';
import HeaderContext from 'utility/contexts/header';

import { ACCOUNT_CREATE_PATH } from 'components/routers/AccountRouter';

const SideSheet = lazy(() => import('components/common/SideSheet'));

const Cart = lazy(() => import('containers/pages/shop/Cart'));
const Logout = lazy(() => import('containers/structural/Logout'));
const Go = lazy(() => import('containers/structural/Go'));
const NotFound = lazy(() => import('pages/error/NotFound'));
const LiveChatLoading = lazy(() =>
	import('containers/structural/LiveChatLoading'),
);
const LiveChatUnavailable = lazy(() =>
	import('containers/structural/LiveChatUnavailable'),
);
const OpenIDReturn = lazy(() => import('containers/structural/OpenIDReturn'));
const OpenIDSilentReturn = lazy(() =>
	import('components/structural/OpenIDSilentReturn'),
);
const News = lazy(() => import('containers/pages/login/News'));
const InvoicePDFDisplay = lazy(() =>
	import('containers/pages/billing/invoice/InvoicePDFDisplay'),
);
const VNCConsole = lazy(() =>
	import('containers/pages/servers/beyond-hosting/VNCConsole'),
);
const AccountCreate = lazy(() => import('containers/pages/account/create'));
const AccessDenied = lazy(() => import('pages/error/AccessDenied'));

const ScrollY = styled(Box)`
	overflow-y: scroll;
	& * {
		${scrollbars}
	}
`;

const AlwaysRoutesRaw = () => [
	<Route key="shopRoute" path="/shop">
		<ShopRouter navData={get(navMap, 'shop')} />
	</Route>,
	<Route key="cartBasketUuid" exact path="/cart/:basketUuid?">
		<Cart />
	</Route>,
	// legacy support for "saved baskets"
	<Route key="cartSavedBasketUuid" exact path="/cart/saved/:basketUuid">
		<Cart />
	</Route>,
];

const AuthenticatedRoutes = () => (
	<>
		<Switch>
			<Route path="/logout">
				<Logout />
			</Route>
			<Route path="/go/:uniqId" render={(props) => <Go {...props} />} />
			<Route path="/account/create">
				<AccountCreate />
			</Route>
			{Object.values(navMap).map((rootNav) => (
				<Route
					exact={get(rootNav, 'isExact')}
					key={get(rootNav, 'path')}
					path={get(rootNav, 'path')}
					render={get(rootNav, 'router')}
				/>
			))}
			<Route path="/cart-login">
				<Redirect to="/cart" />
			</Route>
			<Route path="/cart">
				<CartRouter />
			</Route>
			<Route path="/chat">
				<ChatRouter />
			</Route>
			<Route path="/support">
				<SupportRouter />
			</Route>
			<Route path="/access-denied">
				<AccessDenied />
			</Route>
			<Route>
				<NotFound />
			</Route>
		</Switch>
	</>
);

const UnAuthenticatedRoutes = () => (
	<Switch>
		{AlwaysRoutesRaw()}
		{/* Land the user on a page that forces them to be authed */}
		<Route path="/" key="unauthed" render={() => <div />} />
	</Switch>
);

const KeroOnlyRoutes = () => (
	<Switch>
		{AlwaysRoutesRaw()}
		<Route path={ACCOUNT_CREATE_PATH}>
			<AccountCreate />
		</Route>
		<Route path="/logout">
			<Logout />
		</Route>
		<Redirect to={ACCOUNT_CREATE_PATH} />
	</Switch>
);

const externalSite = () => (
	<Switch>
		<Route path="/external/chatLoading">
			<LiveChatLoading />
		</Route>
		<Route path="/external/chatUnavailable">
			<LiveChatUnavailable />
		</Route>
		<Route path="/external/openid/return">
			<OpenIDReturn />
		</Route>
		<Route path="/external/openid/silent-return">
			<OpenIDSilentReturn />
		</Route>
		<Route path="/external/news">
			<News />
		</Route>
		<Route
			path="/external/invoicepdf/:id"
			render={(props) => <InvoicePDFDisplay {...props} />}
		/>
		<Route
			path="/external/vnc-console/:uniqId"
			render={(props) => <VNCConsole {...props} />}
		/>
		<Route>
			<NotFound />
		</Route>
	</Switch>
);

const Routes = ({ isLoggedIn, isKeroOnly }) => {
	if (isLoggedIn && isKeroOnly) return <KeroOnlyRoutes />;
	if (isLoggedIn) return <AuthenticatedRoutes />;
	return <UnAuthenticatedRoutes />;
};

const PageStructure = ({ isLoggedIn, isKeroOnly, isBasketAdmin }) => {
	const [headerHeight, setHeaderHeight] = useState();
	return (
		<HeaderContext.Provider value={{ height: [headerHeight, setHeaderHeight] }}>
			<Box
				display="inline-flex"
				width="100%"
				maxHeight="100vh"
				// https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
				height="-webkit-fill-available" // NEWMAN-2040
			>
				<ScrollY display="block" flexGrow={1}>
					<Navbar />
					{!isBasketAdmin && <AccountBar />}
					{isLoggedIn && !isKeroOnly && <AccountBalanceBar />}
					<Errors />
					<GlobalComponents />
					<Suspense fallback={<Loading />}>
						<Routes isLoggedIn={isLoggedIn} isKeroOnly={isKeroOnly} />
					</Suspense>
				</ScrollY>
				<SideSheet />
			</Box>
		</HeaderContext.Provider>
	);
};

const RouteHandler = ({ isLoggedIn, isKeroOnly, isBasketAdmin }) => {
	return (
		<Suspense fallback={<Loading />}>
			<Switch>
				<Route path="/external" component={externalSite} />
				<Route>
					<PageStructure
						isLoggedIn={isLoggedIn}
						isKeroOnly={isKeroOnly}
						isBasketAdmin={isBasketAdmin}
					/>
				</Route>
			</Switch>
		</Suspense>
	);
};

export default RouteHandler;

const initialState = {
	isOpen: false,
	serverDetails: null,
};

const unBlockIPDialog = (state = initialState, action) => {
	switch (action.type) {
		case 'UNBLOCK_IP_DIALOG_TOGGLE':
			return { ...state, isOpen: action.isOpen, serverDetails: null };
		case 'SET_SERVER_DETAILS':
			return {
				...state,
				isOpen: action.isOpen,
				serverDetails: action.serverDetails,
			};
		default:
			return state;
	}
};

export default unBlockIPDialog;

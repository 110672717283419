import {
	createAPIModule,
	createSelectors,
} from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';

const getStateSlice = (state) => state.api.account.user.details;

const moduleKeys = {
	LOGGED_IN_USER: 'loggedInUser',
};

const {
	actions,
	reducer,
	sagas,
	selectors: detailsSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ACCOUNT_USER_DETAILS',
	url: '/account/user/details.json',
});

const loggedInSelectors = createSelectors(
	getStateSlice,
	false,
	moduleKeys.LOGGED_IN_USER,
);

const loggedInUser = {
	...loggedInSelectors,
	isSuspended: createSelector(
		loggedInSelectors.getData,
		(slice) => (slice ? slice.get('suspended', 0) === 1 : null),
	),
};

const mainRole = createSelector(
	detailsSelectors.getStateSlice,
	(slice) =>
		slice
			.getIn(['data', 'roles'], ImmutableList())
			.filter((role) => role !== 'AccountLogin')
			.first(), // comes back as an array, but we only plan to support one role per user
);

const selectors = {
	loggedInUser,
	mainRole,
	tfaEnabled: createSelector(
		detailsSelectors.getStateSlice,
		(slice) => !!slice.getIn(['data', 'tfa_enabled']),
	),
	...detailsSelectors,
};

export { actions, reducer, sagas, selectors, moduleKeys };

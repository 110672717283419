import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';

const getStateSlice = (state) =>
	state.api.network.dns.domain.nameserver.listRegistered;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_DNS_DOMAIN_NAMESERVER_LISTREGISTERED',
	method: 'POST',
	url: '/network/dns/domain/nameserver/listRegistered.json',
});

const nameservers = createSelector(
	defaultSelectors.getStateSlice,
	(slice) =>
		slice
			.getIn(['data', 'results'], ImmutableList())
			.sortBy((value) => value.get('name_server')),
);

const selectors = {
	...defaultSelectors,
	nameservers,
};

export { actions, reducer, sagas, selectors };

import { call, delay, put, select, take, takeEvery } from 'redux-saga/effects';

import { actions as blockVolumeAttachActions } from 'modules/api/storage/block/volume/attachModule';
import { actions as bulkAttachDetachActions } from 'modules/api/network/private/bulkAttachDetachModule';
import {
	actions as currentNotificationActions,
	selectors as currentNotificationSelectors,
} from 'modules/api/notifications/currentModule';
import { actions as firewallRulesetUpdateActions } from 'modules/api/network/firewall/ruleset/updateModule';
import { actions as backupRestoreActions } from 'modules/api/storm/backup/restoreModule';
import { actions as imageCreateActions } from 'modules/api/storm/image/createModule';
import { actions as imageRestoreActions } from 'modules/api/storm/image/restoreModule';
import { actions as templateRestoreActions } from 'modules/api/storm/template/restoreModule';
import { actions as ipAddActions } from 'modules/api/network/ip/addModule';
import { actions as ipRemoveActions } from 'modules/api/network/ip/removeModule';
import { actions as networkFirewallUpdateActions } from 'modules/api/network/firewall/updateModule';
import { actions as resizeActions } from 'modules/api/server/resizeModule';
import { actions as resizeStorageActions } from 'modules/api/server/resizeStorageModule';
import { actions as resizeVolumeActions } from 'modules/api/server/resizeVolumeModule';
import { actions as serverRebootActions } from 'modules/api/server/rebootModule';
import { actions as serverShutdownActions } from 'modules/api/server/shutdownModule';
import { actions as serverStartActions } from 'modules/api/server/startModule';
import { actions as assetRemoveActions } from 'modules/api/asset/removeModule';
import { actions as notificationResolveActions } from 'modules/api/notifications/resolveModule';
import {
	hasAuthToken as getHasAuthToken,
	isBasketAdmin as getIsBasketAdmin,
} from 'modules/auth/authSelectors';
import {
	actions as drawerActions,
	selectors as drawerSelectors,
	contentKeys as drawerContentKeys,
} from 'modules/drawer';
import selectors from './selectors';
import actions from './actions';

const POLL_SECONDS = 30;
const FETCH_DELAY_SECONDS = 0.5;
const UNIQ_POLL_SECONDS = 5;

function* fetchCurrentNotifications() {
	const hasAuthToken = yield select(getHasAuthToken);
	const isBasketAdmin = yield select(getIsBasketAdmin);
	if (hasAuthToken && !isBasketAdmin) {
		yield put(currentNotificationActions.fetch());
	}
}

export function* waitForUniqId(action) {
	yield fetchCurrentNotifications();
	yield take(currentNotificationActions.setType);
	let notifications = yield select(currentNotificationSelectors.newestPerAsset);

	let notification = notifications.find(
		(x) =>
			x.get('uniq_id') === action.uniqId &&
			x.get('category') === action.category,
	);
	while (notification) {
		yield fetchCurrentNotifications();
		yield delay(UNIQ_POLL_SECONDS * 1000);
		yield take(currentNotificationActions.setType);
		notifications = yield select(currentNotificationSelectors.newestPerAsset);
		notification = notifications.find(
			(x) =>
				x.get('uniq_id') === action.uniqId &&
				x.get('category') === action.category,
		);
	}
	if (action.toFetchAfter) {
		yield call(action.toFetchAfter);
	}
}

function* setAllNotificationsToRead() {
	const currentNotifications = yield select(
		currentNotificationSelectors.getFilteredAndSorted,
	);
	yield put(
		actions.pushReadNotifications(
			Object.fromEntries(currentNotifications.map(({ id }) => [id, true])),
		),
	);
}

function* toggleDrawer({ drawerOpen }) {
	const notificationDrawerIsOpen = yield select(
		drawerSelectors.getContentKeyIsNotification,
	);
	const opening =
		typeof drawerOpen === 'boolean' ? drawerOpen : !notificationDrawerIsOpen;
	if (opening) yield call(setAllNotificationsToRead);
	yield put(
		drawerActions[opening ? 'open' : 'close']({
			contentKey: drawerContentKeys.NotificationDrawer,
		}),
	);
}

function* handleCriticalAlert() {
	const criticalUnread = yield select(selectors.getCriticalUnread);
	if (criticalUnread.length) yield put(actions.toggleDrawer(true));
}

function* dismissAllNotifications() {
	const currentNotifications = yield select(
		currentNotificationSelectors.getFilteredAndSorted,
	);
	// The api does not accept an array. :/
	for (let idx = 1; idx < currentNotifications.length; idx += 1) {
		yield put(
			notificationResolveActions.fetch({ id: currentNotifications[idx].id }),
		);
	}
	yield put(actions.toggleDrawer(false));
}

function* dismissOneNotification({ id }) {
	yield put(notificationResolveActions.fetch({ id }));
}

export default function* saga() {
	yield takeEvery(
		[
			assetRemoveActions.setType,
			backupRestoreActions.setType,
			blockVolumeAttachActions.setType,
			bulkAttachDetachActions.setType,
			firewallRulesetUpdateActions.setType,
			imageCreateActions.setType,
			imageRestoreActions.setType,
			ipAddActions.setType,
			ipRemoveActions.setType,
			networkFirewallUpdateActions.setType,
			resizeActions.setType,
			resizeStorageActions.setType,
			resizeVolumeActions.setType,
			serverRebootActions.setType,
			serverShutdownActions.setType,
			serverStartActions.setType,
			templateRestoreActions.setType,
			notificationResolveActions.setType,
		],
		fetchCurrentNotifications,
		FETCH_DELAY_SECONDS,
	);
	yield takeEvery(actions.POLL_NOTIFICATIONS, waitForUniqId);
	yield takeEvery(currentNotificationActions.setType, handleCriticalAlert);
	yield takeEvery(actions.NOTIFICATIONS_TOGGLE_DRAWER, toggleDrawer);
	yield takeEvery(actions.NOTIFICATIONS_DISMISS_ALL, dismissAllNotifications);
	yield takeEvery(actions.NOTIFICATIONS_DISMISS_ONE, dismissOneNotification);

	// We are polling like this since the fetchAndPoll method stops upon navigation.
	while (true) {
		yield fetchCurrentNotifications();
		yield delay(POLL_SECONDS * 1000);
	}
}

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import DividerBox from 'components/atoms/DividerBox';
import LWLink from 'components/common/LWLink';
import LWTypography from 'components/common/LWTypography';

import { selectors as appConfigSelectors } from 'modules/appConfig';

const LegalBar = ({ wwwHostname, hasDarkBackground = true }) => {
	const copyright = '\u00a9';
	return (
		<Box display="flex" alignItems="center">
			<DividerBox
				px={1}
				$side="right"
				$shade={hasDarkBackground ? 'light' : 'dark'}
			>
				<LWTypography
					color={
						hasDarkBackground
							? 'palette.primary.contrastText'
							: 'palette.primary.main'
					}
				>
					{`${copyright} ${new Date().getFullYear()} Liquid Web, LLC`}
				</LWTypography>
			</DividerBox>
			<DividerBox
				px={1}
				$side="right"
				$shade={hasDarkBackground ? 'light' : 'dark'}
			>
				<LWLink
					to={`${wwwHostname}/about-us/policies/privacy-policy/`}
					darkBackground={hasDarkBackground}
					underline="none"
				>
					Privacy Policy
				</LWLink>
			</DividerBox>
			<DividerBox
				px={1}
				$side="right"
				shade={hasDarkBackground ? 'light' : 'dark'}
			>
				<LWLink
					to={`${wwwHostname}/about-us/policies/terms-of-service/`}
					darkBackground={hasDarkBackground}
					underline="none"
				>
					Terms & Conditions
				</LWLink>
			</DividerBox>
		</Box>
	);
};

const mapStateToProps = (state) => ({
	wwwHostname: appConfigSelectors.getWwwHostname(state),
});

LegalBar.propTypes = {
	wwwHostname: PropTypes.string.isRequired,
	hasDarkBackground: PropTypes.bool,
};

export default connect(mapStateToProps)(LegalBar);

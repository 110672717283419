import React, { lazy } from 'react';
import get from 'lodash/get';

import Home from '@material-ui/icons/HomeRounded';
import Dns from '@material-ui/icons/DnsRounded';
import Language from '@material-ui/icons/LanguageRounded';
import SettingsSystemDaydream from '@material-ui/icons/SettingsSystemDaydreamRounded';
import AddShoppingCart from '@material-ui/icons/AddShoppingCartRounded';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Layers from '@material-ui/icons/LayersRounded';
import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';
import { roles } from 'utility/constants/roles';
import assetTypes from 'utility/constants/asset/assetTypes';
import ServersRouter from 'components/routers/ServersRouter';
import HomeRouter from 'components/routers/HomeRouter';
import ServicesRouter from 'components/routers/ServicesRouter';
import ShopRouter from 'components/routers/ShopRouter';
import DomainsRouter from 'components/routers/DomainsRouter';
import BillingRouter from 'components/routers/BillingRouter';
import AccountRouter from 'components/routers/AccountRouter';
import ProjectsRouter from 'components/routers/ProjectsRouter';
import SecureNotesBadge from 'containers/badges/SecureNotesBadge';

const DomainSearch = lazy(() =>
	import('containers/pages/domains/Register/Search'),
);

const HomePage = lazy(() => import('containers/pages/Home'));
const Servers = lazy(() => import('containers/pages/servers/Servers'));
const SSHKeys = lazy(() => import('containers/pages/servers/SSHKeys/Page'));
const ScheduledTasks = lazy(() =>
	import('containers/pages/servers/ScheduledTasks'),
);
const Domains = lazy(() => import('containers/pages/domains/Domains'));
const DnsZones = lazy(() => import('containers/pages/domains/dns'));
const Ssls = lazy(() => import('containers/pages/domains/Ssls'));
const LoadBalancerList = lazy(() =>
	import('containers/pages/services/LoadBalancersContainer'),
);

const AccountMain = lazy(() => import('containers/pages/account/AccountMain'));
const SecureNotes = lazy(() =>
	import('containers/pages/account/secureNotes/SecureNotes'),
);
const Users = lazy(() => import('containers/pages/account/users/Users'));
const BillingPage = lazy(() => import('containers/pages/billing/BillingPage'));
const Policies = lazy(() => import('containers/pages/account/Policies'));
const History = lazy(() => import('containers/pages/account/History'));
const BillingPay = lazy(() => import('containers/pages/billing/BillingPay'));
const Transactions = lazy(() =>
	import(
		'containers/pages/billing/details/TransactionTimeline/TransactionTimelinePage'
	),
);
const PaymentMethodPage = lazy(() =>
	import('containers/pages/billing/details/PaymentMethodPage'),
);
const ProjectCreate = lazy(() =>
	import('containers/pages/projects/ProjectCreate'),
);
const ProjectList = lazy(() => import('containers/pages/projects/ProjectList'));
// const AddCloudBlockStorage = lazy(() =>
// 	import('containers/pages/services/CloudBlockStorage/AddCloudBlockStorage'),
// );
const CloudBlockStorageList = lazy(() =>
	import('containers/pages/services/CloudBlockStorage/CloudBlockStorageList'),
);

const ThreatStack = lazy(() => import('containers/pages/services/ThreatStack'));

const VPNs = lazy(() => import('containers/pages/services/VPNs'));

// const ServerSecurePlus = lazy(() =>
// 	import('containers/pages/services/ServerSecurePlus'),
// );

const Acronis = lazy(() => import('containers/pages/services/Acronis'));
const CloudPrivateNetworks = lazy(() =>
	import('containers/pages/services/CloudPrivateNetworks'),
);
const AdvancedServices = lazy(() =>
	import('containers/pages/services/AdvancedServices'),
);
const FirewallPresets = lazy(() =>
	import('containers/pages/services/FirewallPresets'),
);
const ServicesPage = lazy(() => import('containers/pages/services/Services'));

const Marketplace = lazy(() => import('containers/pages/shop/Marketplace'));

const navMap = {
	home: {
		name: 'Home',
		icon: (props) => <Home {...props} />,
		isExact: true,
		path: '/',
		router: () => <HomeRouter navData={get(navMap, 'home')} />,
		component: () => <HomePage navData={get(navMap, 'home')} />,
		subNav: {},
	},
	projects: {
		name: 'Projects',
		mobileName: 'Projects List',
		icon: (props) => <Layers {...props} />,
		path: '/projects',
		component: () => <ProjectList navData={get(navMap, 'projects')} />,
		router: () => <ProjectsRouter navData={get(navMap, 'projects')} />,
		subNav: {
			create: {
				path: '/projects/create',
				name: 'Create Project',
				component: () => (
					<ProjectCreate
						navData={get(navMap, ['projects', 'subNav', 'create'])}
					/>
				),
			},
		},
	},
	servers: {
		name: 'Servers',
		mobileName: 'Servers List',
		icon: (props) => <Dns {...props} />,
		path: '/servers',
		router: () => <ServersRouter navData={get(navMap, 'servers')} />,
		component: () => <Servers navData={get(navMap, 'servers')} />,
		subNav: {
			sshKeys: {
				path: '/servers/ssh-keys',
				name: 'Public SSH Keys',

				component: () => (
					<SSHKeys navData={get(navMap, ['servers', 'subNav', 'sshKeys'])} />
				),
			},
			migrationCenter: {
				name: 'Migration Center',
				path: '/servers/migration-center',
			},
			scheduledTasks: {
				path: '/servers/scheduled-tasks',
				name: 'Scheduled Tasks',
				component: () => <ScheduledTasks />,
			},
		},
	},

	domain: {
		name: 'Domains',
		mobileName: 'Domains List',
		icon: (props) => <Language {...props} />,
		path: '/domain',
		component: () => (
			<Domains navData={get(navMap, 'domain')} assetType={assetTypes.ALL} />
		),
		router: () => <DomainsRouter navData={get(navMap, 'domain')} />,
		subNav: {
			registration: {
				path: '/shop/domain',
				name: 'Register a New Domain',
				component: () => <DomainSearch />,
			},
			transfer: {
				path: '/domain/create-transfer',
				name: 'Transfer A Domain',
			},
			dns: {
				path: '/domain/dns',
				name: 'DNS',
				component: () => <DnsZones />,
			},
			cloudFlare: {
				path: '/domain/cloudflare',
				name: 'Cloudflare',
			},
			ssl: {
				path: '/domain/ssl',
				name: 'SSL',
				component: () => <Ssls />,
			},
		},
	},
	services: {
		name: 'Services',
		mobileName: 'Services List',
		icon: (props) => <SettingsSystemDaydream {...props} />,
		path: '/services',
		component: () => <ServicesPage navData={get(navMap, 'services')} />,
		router: () => <ServicesRouter navData={get(navMap, 'services')} />,
		subNav: {
			loadBalancers: {
				navHidden: true,
				path: '/services/load-balancers',
				name: 'Load Balancers',
				component: () => (
					<LoadBalancerList
						navData={get(navMap, ['services', 'subNav', 'loadBalancers'])}
					/>
				),
			},
			cloudPrivateNetworks: {
				navHidden: true,
				path: '/services/cloud-private-networks',
				name: 'Cloud Private Networks',
				component: () => (
					<CloudPrivateNetworks
						navData={get(navMap, [
							'services',
							'subNav',
							'cloudPrivateNetworks',
						])}
					/>
				),
			},
			advancedServices: {
				navHidden: true,
				path: '/services/advanced-services',
				name: 'Advanced Services',
				component: () => (
					<AdvancedServices
						navData={get(navMap, ['services', 'subNav', 'advancedServices'])}
					/>
				),
			},
			cloudBlockStorage: {
				navHidden: true,
				path: '/services/cloud-block-storage',
				name: 'Cloud Block Storage',
				component: () => (
					<CloudBlockStorageList
						navData={get(navMap, ['services', 'subNav', 'cloudBlockStorage'])}
					/>
				),
			},
			firewallPresets: {
				navHidden: true,
				path: '/services/firewall-presets',
				name: 'Firewall Presets',
				component: () => (
					<FirewallPresets
						navData={get(navMap, ['services', 'subNav', 'firewallPresets'])}
					/>
				),
			},
			acronis: {
				navHidden: true,
				path: '/services/acronis',
				name: 'Acronis',
				component: () => (
					<Acronis navData={get(navMap, ['services', 'subNav', 'acronis'])} />
				),
			},
			threatStack: {
				navHidden: true,
				path: '/services/threatstack',
				name: 'Threat Stack',
				component: () => (
					<ThreatStack
						navData={get(navMap, ['services', 'subNav', 'threatStack'])}
					/>
				),
			},
			VPNs: {
				navHidden: true,
				path: '/services/vpns/:uniqId?',
				name: 'Cloud VPNs',
				component: () => <VPNs />,
			},
			// The product is still being developed
			// serverSecurePlus: {
			// 	navHidden: true,
			// 	path: '/services/server-secure-plus',
			// 	name: 'Server Secure Plus',
			// 	component: () => (
			// 		<ServerSecurePlus
			// 			navData={get(navMap, ['services', 'subNav', 'serverSecurePlus'])}
			// 		/>
			// 	),
			// },
		},
	},
	shop: {
		name: 'Shop',
		mobileName: 'Shop',
		icon: (props) => <AddShoppingCart {...props} />,
		component: () => <Marketplace />,
		path: '/shop',
		allowUnauth: true, // Need this for the navmenu item to show for logged out users
		router: () => <ShopRouter navData={get(navMap, 'shop')} />,
		subNav: {
			domain: {
				navHidden: true,
				path: '/shop/domain',
				name: 'Register a Domain',
				component: () => <DomainSearch />,
			},
			marketplace: {
				name: 'Marketplace',
				path: '/shop/marketplace',
				component: () => <Marketplace />,
			},
			cart: {
				name: 'Cart',
				path: '/cart',
				// configKey: 'cart',
			},
			checkout: {
				name: 'Checkout',
				path: '/shop/checkout',
				// configPath: 'checkout',
				// configKey: 'cart',
			},
			/* TODO: add this back once this page is live
			cloudBlockStorage: {
				path: '/add/cloud-block-storage/',
				name: 'Add Cloud Block Storage',
				component: () => <AddCloudBlockStorage />,
			},
			*/
		},
	},
	billing: {
		navHidden: true,
		name: 'Billing',
		icon: (props) => <MonetizationOn {...props} />,
		path: '/billing',
		component: () => <BillingPage navData={get(navMap, 'billing')} />,
		router: () => <BillingRouter navData={get(navMap, 'billing')} />,
		subNav: {
			pay: {
				path: '/billing/pay',
				name: 'Make a Payment',
				component: () => (
					<BillingPay
						parent={get(navMap, 'billing')}
						navData={get(navMap, ['billing', 'subNav', 'pay'])}
					/>
				),
			},
			transactions: {
				path: '/billing/transactions',
				name: 'Transactions',
				component: () => (
					<Transactions
						parent={get(navMap, 'billing')}
						navData={get(navMap, ['billing', 'subNav', 'transactions'])}
					/>
				),
			},
			paymentMethod: {
				path: '/billing/payment-method',
				name: 'Update Payment Method',
				component: () => (
					<PaymentMethodPage
						parent={{
							name: 'Billing',
							path: '/billing',
						}}
						navData={get(navMap, ['billing', 'subNav', 'paymentMethod'])}
					/>
				),
			},
		},
	},
	account: {
		name: 'Account',
		mobileName: 'Account Overview',
		icon: (props) => <AccountCircle {...props} />,
		path: '/account',
		component: () => <AccountMain navData={get(navMap, 'account')} />,
		router: () => <AccountRouter navData={get(navMap, 'account')} />,
		subNav: {
			users: {
				path: '/account/users',
				name: 'Users',
				component: () => (
					<RoleRestrictedRoute
						path="/account/users"
						component={Users}
						parent={{
							name: 'Account',
							path: '/account',
						}}
						navData={get(navMap, ['account', 'subNav', 'users'])}
					/>
				),
			},
			secureNotes: {
				path: '/account/secure-notes',
				name: 'Secure Notes',
				Badge: SecureNotesBadge,
				whitelistedRoles: [roles.PURCHASER, roles.TECHNICIAN],
				component: () => (
					<SecureNotes
						parent={{
							name: 'Account',
							path: '/account',
						}}
						navData={get(navMap, ['account', 'subNav', 'secureNotes'])}
					/>
				),
			},
			billing: {
				name: 'Billing Center',
				icon: (props) => <MonetizationOn {...props} />,
				path: '/account/billing',
				whitelistedRoles: [
					roles.ACCOUNT_OWNER,
					roles.SECONDARY_OWNER,
					roles.PURCHASER,
					roles.SUSPENDED_OWNER,
				],
				component: () => (
					<BillingPage
						navData={get(navMap, ['account', 'subNav', 'billing'])}
					/>
				),
				router: () => (
					<BillingRouter
						navData={get(navMap, ['account', 'subNav', 'billing'])}
					/>
				),
				subNav: {
					pay: {
						path: '/account/billing/pay',
						name: 'Make a Payment',
						navHidden: true,
						whitelistedRoles: [
							roles.ACCOUNT_OWNER,
							roles.SECONDARY_OWNER,
							roles.PURCHASER,
							roles.SUSPENDED_OWNER,
						],
						component: () => (
							<BillingPay
								parent={get(navMap, ['account'])}
								navData={get(navMap, [
									'account',
									'subNav',
									'billing',
									'subNav',
									'pay',
								])}
							/>
						),
					},
					transactions: {
						path: '/account/billing/transactions',
						name: 'Transactions',
						navHidden: true,
						component: () => (
							<Transactions
								parent={get(navMap, ['account'])}
								navData={get(navMap, [
									'account',
									'subNav',
									'billing',
									'subNav',
									'transactions',
								])}
							/>
						),
					},
					paymentMethod: {
						path: '/account/billing/payment-method',
						name: 'Update Payment Method',
						navHidden: true,
						whitelistedRoles: [
							roles.ACCOUNT_OWNER,
							roles.SECONDARY_OWNER,
							roles.PURCHASER,
							roles.SUSPENDED_OWNER,
						],
						component: () => (
							<PaymentMethodPage
								parent={{
									name: 'Account',
									path: '/account',
								}}
								navData={get(navMap, [
									'account',
									'subNav',
									'billing',
									'subNav',
									'paymentMethod',
								])}
							/>
						),
					},
				},
			},
			policies: {
				name: 'Policies',
				path: '/account/policies',
				whitelistedRoles: [
					roles.ACCOUNT_OWNER,
					roles.SECONDARY_OWNER,
					roles.PURCHASER,
					roles.SUSPENDED_OWNER,
				],
				component: () => <Policies />,
			},
			history: {
				name: 'History',
				path: '/account/history',
				whitelistedRoles: [
					roles.ACCOUNT_OWNER,
					roles.SECONDARY_OWNER,
					roles.PURCHASER,
					roles.SUSPENDED_OWNER,
				],
				component: () => <History />,
			},
			pay: {
				path: '/account/billing/pay',
				name: 'Make a Payment',
				navHidden: true,
				breadcrumbHidden: true,
				whitelistedRoles: [
					roles.ACCOUNT_OWNER,
					roles.SECONDARY_OWNER,
					roles.PURCHASER,
					roles.SUSPENDED_OWNER,
				],
				component: () => (
					<BillingPay
						parent={get(navMap, ['account'])}
						navData={get(navMap, [
							'account',
							'subNav',
							'billing',
							'subNav',
							'pay',
						])}
					/>
				),
			},
			transactions: {
				path: '/account/billing/transactions',
				name: 'Transactions',
				navHidden: true,
				breadcrumbHidden: true,
				component: () => (
					<Transactions
						parent={get(navMap, ['account'])}
						navData={get(navMap, [
							'account',
							'subNav',
							'billing',
							'subNav',
							'transactions',
						])}
					/>
				),
			},
			paymentMethod: {
				path: '/account/billing/payment-method',
				name: 'Update Payment Method',
				navHidden: true,
				breadcrumbHidden: true,
				whitelistedRoles: [
					roles.ACCOUNT_OWNER,
					roles.SECONDARY_OWNER,
					roles.PURCHASER,
					roles.SUSPENDED_OWNER,
				],
				component: () => (
					<PaymentMethodPage
						parent={{
							name: 'Account',
							path: '/account',
						}}
						navData={get(navMap, [
							'account',
							'subNav',
							'billing',
							'subNav',
							'paymentMethod',
						])}
					/>
				),
			},
		},
	},
};

const navLinks = Object.values(navMap);

export { navMap, navLinks };

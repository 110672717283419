import actions from './actions';

const initialState = {
	open: false,
	content: undefined,
	color: undefined,
	title: undefined,
	dialogProps: null,
	contentKey: '',
	contentProps: undefined,
	dismissible: true,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.SET_DIALOGS_OPEN:
			return {
				open: true,
				content: action.content,
				title: action.title,
				color: action.color,
				dialogProps: action.dialogProps,
				contentKey: action.contentKey,
				contentProps: action.contentProps,
				dismissible:
					action.dismissible === undefined ? true : action.dismissible,
			};
		case actions.SET_DIALOGS_CLOSE:
			return { ...state, open: false };
		case actions.DIALOGS_SET_TITLE:
			return { ...state, title: action.title };
		case actions.DIALOGS_RESET:
			return initialState;
		default:
			return state;
	}
};

export default reducer;

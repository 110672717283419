import Cookies from 'js-cookie';
import {
	call,
	put,
	takeLatest,
	takeEvery,
	select,
	spawn,
} from 'redux-saga/effects';
import { USER_EXPIRED, USER_FOUND } from 'redux-oidc';
import isUUID from 'validator/es/lib/isUUID';
import { push } from 'connected-react-router';
import {
	hasAuthToken as hasAuthTokenSelector,
	isKeroOnly as isKeroOnlySelector,
	isBasketAdmin as isBasketAdminSelector,
} from 'modules/auth/authSelectors';
import { selectors as routeSelectors } from 'modules/route';
import cartSelectors from 'modules/cart/selectors';
import { CART_UUID } from 'utility/constants/baskets';
import {
	REDIRECT_PATH,
	OPP_ID,
	UUID_IS_FROM_URL,
	COUPON_CODE,
} from 'utility/constants/auth';

import { signinRedirect, storeQueryParams } from 'modules/auth/authSagas';
import cartActions from 'modules/cart/actions';
import { cartDetailsAdditionalSagas } from 'modules/api/market/cart/detailsModule';

import { createCart as createCartSaga } from 'modules/cart/sagas';
import { sagas as addItemForCloneSagas } from './addItemForClone';
import { sagas as addItemForParentSagas } from './addItemForParent';
import basketActions from './actions';
import checkout from './checkout/sagas';
import claimSaga from './sagas/claim';
import detailsSaga from './sagas/details';
import domainSaga from './sagas/domains';
import packageConfigSaga from './packageConfig/sagas';
import productConfigSaga from './productConfig/sagas';
import storeUuid from './sagas/storeUuid';
import { checkForSharedCart } from './sagas/details/details';

function* resetBasket() {
	Cookies.remove(CART_UUID);
	const uuid = yield call(createCartSaga);
	return uuid;
}

function* handleClaimError(action) {
	if (action.payload.status === 404) {
		// when basket is not found, clear the stored basket info and setup new basket
		yield call(resetBasket);
	}
}

/**
 * @param {unknown} pathname
 */
export function parseUuidFromPathName(pathName) {
	if (typeof pathName === 'string' && pathName.startsWith('/cart')) {
		// Regular expression to match a UUID
		const uuidRegex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
		const match = pathName.match(uuidRegex);

		// double check that it is in fact a UUID
		if (match && isUUID(match[0])) {
			return match[0];
		}
	}

	return null; // No UUID found in the URL
}

/* gets uuid from url and sets in local storage or retrieves from storage */
function* getUuid({ pathName: pathNameArg } = {}) {
	let uuid;

	// If loading a cart URL directly, need to store those details into local state
	const pathName = pathNameArg || (yield select(routeSelectors.getPathName));
	const uuidPath = parseUuidFromPathName(pathName);

	if (uuidPath) {
		uuid = uuidPath;
		yield call(storeUuid, { uuid });
		yield call([sessionStorage, 'setItem'], REDIRECT_PATH, '/cart');
		yield call([sessionStorage, 'setItem'], UUID_IS_FROM_URL, true);
	} else {
		uuid = Cookies.get(CART_UUID);
	}
	return uuid;
}

function* handleCoupon() {
	const couponCode = yield call([sessionStorage, 'getItem'], COUPON_CODE);
	if (couponCode) {
		yield put(cartActions.addCoupon({ code: couponCode }));
		yield call([sessionStorage, 'removeItem'], COUPON_CODE);
	}
}

function* initialize({
	isBasketAdmin: isBasketAdminArg,
	isKeroOnly: isKeroOnlyArg,
	uuid: uuidArg,
} = {}) {
	let uuid = uuidArg || (yield call(getUuid)); // this also sets uuid from url (if there is one) in local storage. This needs to happen before anything else

	yield call(storeQueryParams); // store query params before anything else in case there is a redirect

	yield put(cartActions.uninitialized());

	const isKeroOnly = isKeroOnlyArg || (yield select(isKeroOnlySelector));
	if (isKeroOnly) {
		yield call(detailsSaga);
		yield put(cartActions.initialized());
		return;
	}

	const oppId = yield call([sessionStorage, 'getItem'], OPP_ID);

	if (uuid && !oppId) {
		const { error } = yield call(detailsSaga, { uuid });
		if (error) {
			yield call(resetBasket);
		}
	} else {
		// uuid or not, we're getting a new one to go with the opportunity.
		uuid = yield call(createCartSaga, oppId);
		if (!uuid) {
			return;
		}
	}

	// At this point, we can count on having a basket uuid.
	yield call(handleCoupon);

	const isBasketAdmin =
		isBasketAdminArg || (yield select(isBasketAdminSelector));
	if (isBasketAdmin) {
		if (oppId) {
			yield put(push('/shop/marketplace'));
			yield call([sessionStorage, 'removeItem'], OPP_ID);
		}
		// TODO: handle no oppId for admins
	} else {
		yield put(cartActions.claim({ isManual: false }));
	}

	yield put(cartActions.initialized());
}

function* handleUserExpired() {
	const cartInitHasStarted = yield select(cartSelectors.getCartInitHasStarted);

	// USER_EXPIRED is getting dispatched once every second when a token is expired.
	if (cartInitHasStarted) {
		return;
	}

	let uuid = yield call(getUuid); // this also sets uuid from url (if there is one) in local storage. This needs to happen before anything else

	yield call(storeQueryParams); // store query params before anything else in case there is a redirect

	yield put(cartActions.initHasStarted()); // Additional calls will be no-op.
	yield put(cartActions.uninitialized());

	const oppId = yield call([sessionStorage, 'getItem'], OPP_ID);
	// If oppId or clone have value but the token is expired or unauthed, a signinRedirect needs to happen first.
	if (oppId) {
		// Note that a null expiration date (aka unathed) will also be expired.
		yield call(signinRedirect, { force: true });
		// escape hatch here so our sessionStorage doesn't just get deleted right after we created it!
		return;
	}

	if (uuid) {
		const { data, error } = yield call(
			cartDetailsAdditionalSagas.fetchDirectly,
			{ uuid, alsowith: ['discounts', 'items', 'metadata', 'totals'] },
		);

		// if user is authed at this point, do not continue
		const hasAuthToken = yield select(hasAuthTokenSelector);
		if (hasAuthToken) {
			return;
		}

		yield call(checkForSharedCart, data);

		if (error) {
			yield call(resetBasket);
		}
	} else {
		// uuid or not, we're getting a new one to go with the opportunity.
		uuid = yield call(createCartSaga);
		if (!uuid) {
			return;
		}
	}

	yield call(handleCoupon);

	yield put(cartActions.initialized());
}

export { resetBasket, handleClaimError, getUuid, initialize };
// TODO: Move all "takeLatest" calls to their respective roots, and spawn their exports.
export default function* basketRoot() {
	yield spawn(addItemForCloneSagas);
	yield spawn(addItemForParentSagas);
	yield spawn(domainSaga);
	yield spawn(claimSaga);
	yield spawn(packageConfigSaga);
	yield spawn(productConfigSaga);
	yield spawn(checkout);

	yield takeLatest(basketActions.BASKET_RESET, resetBasket);

	yield takeEvery([cartActions.CART_INITIALIZE, USER_FOUND], initialize);

	// takeEvery, because we don't want the first one cancelled since it can only happen once. NEWMAN-2463 AND NEWMAN-2510
	yield takeEvery([USER_EXPIRED], handleUserExpired);

	yield takeLatest(basketActions.BASKET_STORE_UUID, storeUuid);
}

import { getDefaultZonesByRegion as getDefaultZonesByRegionHelper } from 'banana-stand/parsers/network';
import { createSelector } from 'reselect';
import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { uniqueList } from 'utility/array';

const getStateSlice = (state) => state.api.network.zone.list;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
	additionalSagas,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_ZONE_LIST',
	method: 'POST',
	url: '/network/zone/list.json',
});

const items = createSelector(
	defaultSelectors.getNativeData,
	(slice) => slice?.items || [],
);

const getDefaultZone = createSelector(
	items,
	(slice) => slice.find((elem) => elem?.is_default === 1),
);

const getDefaultZoneId = createSelector(
	getDefaultZone,
	(defaultZone) => defaultZone?.id.toString(),
);

const getZoneToRegionMap = createSelector(
	items,
	/** @returns {Object.<string, number>} */
	(slice) => {
		const zoneToRegionMap = {};

		slice.forEach((zone) => {
			zoneToRegionMap[zone.id] = zone.region.id;
		});

		return zoneToRegionMap;
	},
);

const getDefaultRegion = createSelector(
	getDefaultZone,
	(defaultZone) => defaultZone?.region.id,
);

const getRegionIdToName = createSelector(
	items,
	(slice) => {
		const regionIdToName = {};

		slice.forEach((item) => {
			regionIdToName[item.region.id] = item.region.name;
		});

		return regionIdToName;
	},
);

const getAvailableRegions = createSelector(
	getZoneToRegionMap,
	(zoneToRegion) => uniqueList(Object.values(zoneToRegion)),
);

const getDefaultZonesByRegion = createSelector(
	defaultSelectors.getNativeItems,
	(zones) => getDefaultZonesByRegionHelper(zones),
);

const getRegions = createSelector(
	items,
	(zones) =>
		zones
			.sort((a, b) => a.is_default - b.is_default)
			.map((zone) => zone.region)
			.filter(
				// exclude duplicates
				(region, index, regions) =>
					index === regions.findIndex((item) => item.id === region.id),
			),
);

const selectors = {
	items,
	selected: createSelector(
		items,
		(slice) => slice.find((zone) => zone?.is_default),
	),
	getAvailableRegions,
	getDefaultRegion,
	getDefaultZone,
	getDefaultZoneId,
	getDefaultZonesByRegion,
	getRegionIdToName,
	getZoneToRegionMap,
	getRegions,
	...defaultSelectors,
};

export {
	actions,
	reducer,
	sagas,
	selectors,
	additionalSagas as networkZoneListAdditionalSagas,
};

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
	isBasketAdmin as getIsBasketAdmin,
	getIsWaitingForToken,
} from 'modules/auth/authSelectors';
import LWLoader from 'components/common/LWLoader';

const Component = ({ component: Render, ...props }) => {
	const isBasketAdmin = useSelector(getIsBasketAdmin);
	const isWaitingForToken = useSelector(getIsWaitingForToken);
	if (isWaitingForToken) return <LWLoader />;
	if (isBasketAdmin) return <Render {...props} />;
	return <Redirect to={{ pathname: '/access-denied' }} />;
};

const BasketAdminRoute = ({ component, path, ...rest }) => {
	return (
		<Route
			{...rest}
			path={path}
			render={() => <Component component={component} {...rest} />}
		/>
	);
};

BasketAdminRoute.propTypes = {
	component: PropTypes.elementType.isRequired,
	path: PropTypes.string,
};

export default BasketAdminRoute;

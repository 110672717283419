import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'utility/compose';
import { Field, reduxForm, change } from 'redux-form';
import { required } from 'redux-form-validators';
import { ipv4 } from 'utility/form/validators';
import LWTypography from 'components/common/LWTypography';
import LWLink from 'components/common/LWLink';
import MenuItem from '@material-ui/core/MenuItem';
import LWTooltip from 'components/common/LWTooltip';
import { selectors as appConfigSelectors } from 'modules/appConfig';
import { renderCheckbox, renderSelectField } from 'utility/form/renderers';
import TextField from 'components/atoms/TextField';
import {
	actions as whatsMyIpActions,
	selectors as whatsMyIpSelectors,
} from 'modules/api/utilities/info/whatismyipModule';
import {
	actions as listActions,
	selectors as listSelectors,
} from 'modules/api/asset/listModule';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';

const styles = ({ palette, breakpoints }) => ({
	summary: {
		marginBottom: '1rem',
	},
	button: {
		color: palette.secondary.contrastText,
	},
	field: {
		width: '18rem',
		[breakpoints.down('xs')]: {
			width: '12rem',
		},
	},
	buttonContainer: {
		textAlign: 'center',
	},
	link: {
		paddingTop: '1em',
	},
	loading: {
		margin: 'auto',
		display: 'block',
	},
});

/**
 * @param {object} prop
 * @param {import('modules/queries/asset/useDetails').AssetDetails} prop.serverDetails
 */
const UnblockIPForm = ({
	classes,
	onClose,
	changeFieldValue,
	cPanelServerList = [],
	iscPanelServerLoading,
	invalid,
	getMyPublicIPAddress,
	myPublicIPAddress,
	fetchingPublicIPAddress,
	serverDetails,
	submitPending,
	handleSubmit,
	fetchCPanelServerList,
	onSubmit,
	communityURL,
}) => {
	useEffect(() => {
		getMyPublicIPAddress();
		fetchCPanelServerList();
	}, [getMyPublicIPAddress, fetchCPanelServerList]);

	const myPublicIP = myPublicIPAddress ? myPublicIPAddress.ip : null;

	if (fetchingPublicIPAddress || iscPanelServerLoading)
		return <CircularProgress classes={{ root: classes.loading }} />;

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={1} data-testid="UnblockIPForm">
				<Grid className={classes.summary} item xs={12} md={4}>
					<LWTypography variant="body1">
						Server
						<LWTooltip>
							Only cPanel enabled servers will show up in this list
						</LWTooltip>
					</LWTypography>
				</Grid>
				<Grid className={classes.summary} item xs={12} md={8}>
					{serverDetails ? (
						<LWTypography variant="body1">
							{serverDetails.domain} - {serverDetails.ip}
						</LWTypography>
					) : (
						<Field
							name="serverUniqId"
							label="Please select a server"
							component={renderSelectField}
							validate={required()}
							className={classes.field}
							onChange={(e) => {
								changeFieldValue('serverUniqId', e.target.value);
							}}
						>
							{cPanelServerList.map((record) => (
								<MenuItem value={record.uniq_id} key={record.ip}>
									{record.domain} - {record.ip}
								</MenuItem>
							))}

							{iscPanelServerLoading && (
								<MenuItem value="loader" disabled>
									<CircularProgress size={18} />
									Loading IPs
								</MenuItem>
							)}
						</Field>
					)}
				</Grid>
				<Grid className={classes.summary} item xs={4}>
					<LWTypography variant="body1">
						IP Address
						<LWTooltip>
							Enter an IP to quickly unblock it from the cPanel firewall on your
							cPanel Server.
						</LWTooltip>
					</LWTypography>
				</Grid>
				<Grid className={classes.summary} item xs={4} md={8}>
					<Field
						label="IP"
						name="ip"
						component={TextField}
						validate={[required(), ipv4()]}
						fullWidth
						className={classes.field}
					/>
					<LWTypography variant="body2">
						{`Your public IP address is ${myPublicIP}`}
					</LWTypography>
				</Grid>
				<Grid className={classes.summary} item>
					<Field
						label="Whitelist IP(s) to prevent future blocks"
						name="whitelist"
						component={renderCheckbox}
						className={classes.field}
					/>
				</Grid>
				<Grid className={classes.summary} item>
					<LWTypography>
						<LWLink
							classes={{ root: classes.link }}
							to={`${communityURL}/article/Unblocking-Your-IP-Address`}
						>
							{"Learn more about Managing Your Server's Firewall"}
						</LWLink>
					</LWTypography>
				</Grid>
			</Grid>
			<ConfirmCancel
				confirmType="submit"
				disabled={submitPending || invalid}
				isLoading={submitPending}
				confirmText="Unblock IP"
				cancel={onClose}
			/>
		</form>
	);
};

UnblockIPForm.propTypes = {
	changeFieldValue: PropTypes.func.isRequired,
	cPanelServerList: PropTypes.array,
	getMyPublicIPAddress: PropTypes.func.isRequired,
	submitPending: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	invalid: PropTypes.bool.isRequired,
	fetchCPanelServerList: PropTypes.func.isRequired,
	communityURL: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
	const myPublicIPAddress = whatsMyIpSelectors.getNativeData(state);
	let initialValues;
	if (myPublicIPAddress) {
		initialValues = {
			whitelist: true,
			ip: myPublicIPAddress.ip,
		};
		if (ownProps.serverDetails) {
			initialValues = {
				whitelist: true,
				ip: myPublicIPAddress.ip,
				serverUniqId: ownProps.serverDetails.uniq_id,
			};
		}
	}
	return {
		initialValues,
		myPublicIPAddress,
		fetchingPublicIPAddress: whatsMyIpSelectors.isLoading(state),
		iscPanelServerLoading: listSelectors.iscPanelLoading(state),
		cPanelServerList: listSelectors.getcPanelServers(state),
		communityURL: appConfigSelectors.getCustomerCommunityHostname(state),
	};
};

const mapDispatchToProps = (dispatch) => ({
	changeFieldValue: (field, value) => dispatch(change(field, value)),
	getMyPublicIPAddress: () => dispatch(whatsMyIpActions.fetch()),
	fetchCPanelServerList: () =>
		dispatch(
			listActions.fetch(
				{
					capability: 'whmLogin',
				},
				'cPanelServers',
			),
		),
});

export { UnblockIPForm };

export default compose(
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	reduxForm({
		form: 'unBlockIpdialogForm',
		enableReinitialize: true,
	}),
)(UnblockIPForm);

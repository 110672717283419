import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';

const LWCHECKBOX_TEST_ID = 'LWCheckbox';
const LWCheckbox = ({
	ariaLabel,
	checked,
	onChange,
	label,
	value: inValue,
	dataTestID,
	disabled,
	input = {},
	...BoxProps
}) => {
	return (
		<Box
			alignItems="center"
			pl={1.375} // FormControlLabel adds margin left of -11px
			data-testid={dataTestID || LWCHECKBOX_TEST_ID}
			{...BoxProps}
		>
			<FormControlLabel
				control={
					<Checkbox
						checked={!!(checked || input.value)}
						value={inValue || input.value}
						color="primary"
						onChange={onChange}
						inputProps={{ 'aria-label': ariaLabel }}
						disabled={disabled}
						{...input} // overrides props above for redux forms
					/>
				}
				label={label}
			/>
		</Box>
	);
};

LWCheckbox.propTypes = {
	ariaLabel: PropTypes.string,
	checked: PropTypes.bool,
	onChange: PropTypes.func,
	label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export { LWCHECKBOX_TEST_ID };
export default LWCheckbox;

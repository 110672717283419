// @ts-check
import isFQDN from 'validator/es/lib/isFQDN';
import toString from 'lodash/toString';

/** @type {import('components/LWForm/LWForm').Validator} */
export const validateDomain = (domainValue) => {
	if (domainValue && !isFQDN(toString(domainValue))) {
		return 'Please enter a valid domain name';
	}
	return '';
};

/** @type {import('components/LWForm/LWForm').Validator} */
export const validateSubDomain = (domainValue) => {
	if (domainValue && !isFQDN(toString(domainValue), { require_tld: false })) {
		return 'Please enter a valid subdomain name';
	}
	return '';
};

/** @type {import('components/LWForm/LWForm').Validator} */
export const validateOppId = (oppId) => {
	const stringOppId = toString(oppId);
	const isValid =
		/006[A-Za-z0-9]{12,15}/.test(stringOppId) && stringOppId.length <= 18;
	return !isValid ? 'Please enter a valid opportunity ID' : '';
};

import * as React from 'react';

import LWCard from 'components/common/LWCard/LWCard';
import LWTypography from 'components/common/LWTypography';
import Services from 'components/Services';

import Processor from './Processor';
import Disk from './Disk';
import Network from './Network';

const CloudHealth = ({ uniqId }) => {
	return (
		<LWCard title="Server Health" data-testid="CloudHealth__Container">
			<Processor uniqId={uniqId} />
			<Disk uniqId={uniqId} />
			<Network uniqId={uniqId} />
			<LWTypography variant="h3">Monitored Services</LWTypography>
			<Services uniqId={uniqId} />
		</LWCard>
	);
};

export default CloudHealth;

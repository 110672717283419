import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LWTypography from 'components/common/LWTypography';
import ConfirmCancel from 'containers/connectedMolecules/ConnectedConfirmCancel';
import { actions, selectors } from 'modules/serviceWorker';
import AppInfoDetails from 'containers/connectedMolecules/AppInfoDetails';
import Grid from '@material-ui/core/Grid';

const AppVersionModalContents = () => {
	const dispatch = useDispatch();

	const handleConfirm = () => {
		dispatch(actions.serviceWorkerUpdate());
	};

	const hasUpdate = useSelector(selectors.getUpdateIsAvailable);
	return (
		<Grid container spacing={3}>
			<Grid item>
				<AppInfoDetails />
			</Grid>
			{hasUpdate && (
				<Grid item>
					<LWTypography>
						We recently finished releasing an update to the my.liquidweb.com
						portal. To ensure all features work correctly for you,
						<b> please update the portal to the newest version.</b>
					</LWTypography>
					<ConfirmCancel
						confirmText="Update"
						cancelText="Postpone"
						confirm={handleConfirm}
					/>
				</Grid>
			)}
		</Grid>
	);
};

export default AppVersionModalContents;

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getAuthToken } from 'modules/auth/authSelectors';
import appConfigSelectors from 'modules/appConfig/selectors';
import api from 'modules/queries/api';

const useSetAuth = () => {
	const authToken = useSelector(getAuthToken);
	const guestCredentials = useSelector(appConfigSelectors.getGuestCredentials);

	useEffect(() => {
		if (authToken) {
			api.apiSetAuthorization(`Bearer ${authToken}`);

			return;
		}

		api.apiSetBasicAuthorization(
			guestCredentials.username,
			guestCredentials.password,
		);
	}, [authToken, guestCredentials]);
};

export default useSetAuth;

import testUtilActions from 'testUtils/actions';
import actions from './actions';

const initialState = {
	apiParams: {},
	hostname: null,
	isOpen: false,
	task: null,
	canAutoReboot: false,
	forceReboot: false,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (
	state = initialState,
	{ type, forceReboot, apiParams = {}, hostname, task, canAutoReboot },
) => {
	switch (type) {
		case actions.OPEN_DIALOG:
			return {
				...state,
				apiParams,
				hostname,
				task,
				isOpen: true,
				canAutoReboot,
			};
		case actions.CLOSE_DIALOG:
			return {
				...state,
				...initialState,
			};
		case actions.SET_FORCE_REBOOT:
			return { ...state, forceReboot };
		case testUtilActions.TEST_UTIL_CLEAR_STATE:
			return initialState;
		default:
			return state;
	}
};

export default reducer;

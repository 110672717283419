import React, { useRef, useEffect, useState, useMemo } from 'react';
import Box from '@material-ui/core/Box';
import CheckMarkIcon from '@material-ui/icons/DoneRounded';
import CircleIt from 'components/atoms/CircleIt';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import Fade from '@material-ui/core/Fade';
import useGradient from 'utility/effects/useGradient';
import DataKeys from 'components/molecules/DataKeys';
import colorArrays from 'utility/effects/colorArrays'; // Used for the default BarFill color
import Marks from './Marks';

const BarFill = styled(Box)`
	${({ $bgcolor }) => useGradient($bgcolor)}
`;

const SFadebar = styled(Box)`
	${() => useGradient(['grey.disabledOpaque', 'common.white1'], 'horizontal')}
`;

const DataBars = ({
	values = [],
	max = 100,
	selected,
	marks,
	showFadebar = false,
}) => {
	const theme = useTheme();

	const sortedValues = useMemo(
		() => [...values].sort(({ value: a }, { value: b }) => a - b),
		[values],
	); // used for data keys.
	const reversedSortedValues = useMemo(() => [...sortedValues].reverse(), [
		sortedValues,
	]); // Reversed sort makes the shortest bar on the top after mapping.

	// Since we're layering absolutely positioned elements on top of each other, we need to know the width of the container.
	const container = useRef(null);
	const [containerWidth, setContainerWidth] = useState();

	// Set the width of the variable setting the absolutely positioned elements.
	const resizeObserver = useMemo(
		() =>
			new ResizeObserver((entries) => {
				const { target } = entries[0];
				setContainerWidth(target.clientWidth - theme.spacing(1));
			}),
		[theme],
	);

	useEffect(() => {
		const { current } = container;
		setContainerWidth(current?.clientWidth - theme.spacing(1)); // 1 is for the padding and the border; 4px on each side.
		resizeObserver.observe(current);
		return () => {
			resizeObserver.unobserve(current);
		};
	}, [container, resizeObserver, theme]);

	// Using dimensionsSet to hide stuff until the component is mounted and knows the width.
	const dimensionsSet = containerWidth !== undefined;
	const lastDisabled = marks?.slice(-1)[0]?.disabled;
	const firstDisabled = marks?.[0]?.disabled && marks?.[0]?.value === 0;
	const inheritBugFix = `solid ${theme.spacing(0.25)}px ${
		theme.palette.primary.dark
	}`; // Bug in MUI. This will not inherit if the value used to be different. :\
	return (
		// relative makes the absolute positioning of children relative.
		<Box>
			<DataKeys keys={sortedValues.filter(({ label }) => label)} />
			<Box width="100%" position="relative" mt={1} mb={selected ? 3.25 : 0}>
				<Box display="flex" alignItems="center">
					<Box
						ref={container}
						display="block"
						borderColor="primary.dark"
						border={`solid ${theme.spacing(0.25)}px`}
						padding={0.25}
						borderRadius={showFadebar ? '8px 0 0 8px' : '8px'}
						// These borders can sneak in past the grey disabled marks, so we remove them.
						borderRight={lastDisabled ? '0px' : inheritBugFix}
						borderLeft={firstDisabled ? '0px' : inheritBugFix}
						width="100%"
						data-testid="DataBars__MainContainer"
					>
						<Box height={theme.spacing(2.5)}>
							{reversedSortedValues.map(({ value, color }) => (
								<Fade in={dimensionsSet} key={value + color?.toString()}>
									<BarFill
										position="absolute"
										height={theme.spacing(2.5)}
										width={`${Math.min(
											(value / max) * containerWidth,
											containerWidth,
										) || 0}px`}
										$bgcolor={color || colorArrays.main}
										borderRadius={4}
										zIndex={1}
									/>
								</Fade>
							))}
							<Marks
								data={marks}
								max={max}
								containerWidth={containerWidth}
								firstDisabled={firstDisabled}
							/>
						</Box>
					</Box>
					{showFadebar && (
						<SFadebar m="2px" height={theme.spacing(3)} width="20%" />
					)}
				</Box>
				<Fade in={Boolean(selected) && dimensionsSet}>
					{/* https://stackoverflow.com/questions/57078732/material-ui-fade-component-does-not-show-hide-or-fade-components */}
					<div data-testid="DataBars__selectedCheckmark">
						<CircleIt
							bgcolor="primary.main"
							size={20}
							BoxProps={{
								color: 'common.white',
								position: 'absolute',
								left: `${(selected / max) * containerWidth -
									theme.spacing(1.825)}px`,
								margin: 0.75,
							}}
						>
							<CheckMarkIcon fontSize="small" />
						</CircleIt>
					</div>
				</Fade>
			</Box>
		</Box>
	);
};

// A light wrapper to make a single data bar without having to think too hard.
const DataBar = ({ value, label, color, bold, ...rest }) => (
	<DataBars values={[{ value, label, color, bold }]} {...rest} />
);

DataBars.propTypes = {
	/** An array of objects. Value describes how far a value bar goes to the right. Color can be a string or an array of two strings to achieve a gradient. See colorArrays.js for examples. */
	values: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.number.isRequired,
			color: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.arrayOf(PropTypes.string),
			]),
			label: PropTypes.string,
			bold: PropTypes.bool,
		}),
	),
	/** max defaults to 100. Feel free to pass in percentages! */
	max: PropTypes.number,
	/** Displays the checkmark at the passed in value if it is passed in. */
	selected: PropTypes.number,
	/** Pass in an array of objects that describes the lines on the bar. The disabled property makes that sections grey. */
	marks: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.number.isRequired,
			disabled: PropTypes.bool,
		}),
	),
};

export { DataBar };
export default DataBars;

import Cookies from 'js-cookie';
import { put, call, select } from 'redux-saga/effects';
import { CART_UUID } from 'utility/constants/baskets';
import { selectors as routeSelectors } from 'modules/route';
import { replace } from 'connected-react-router';
import { cartDetailsAdditionalSagas } from 'modules/api/market/cart/detailsModule';

import basketActions from '../../actions';
import storeUuid from '../storeUuid';

const defaultAlsowith = [
	'cpqMetadata',
	['discountSources', 'itemDiscounts', 'totals'],
	'totals',
	[
		'items',
		'totals',
		'timeUnitTotals',
		'productInfo',
		'maxDiscount',
		['configs', 'totals'],
	],
	'timeUnitTotals',
	'isApproved',
];

/**
 * Checks if the uuid in local storage is different than the uuid of the passed in cart. If so, replaces the old uuid in local storage and in the url
 * @param {import('club-sauce/public/market/cart/index.raw').LWApiPublicMarketCartDetailsResultRawI} cart
 */
function* checkForSharedCart(cart) {
	if (!cart) return;
	const currentUuid = Cookies.get(CART_UUID);

	if (currentUuid !== cart.uuid) {
		yield call(storeUuid, { uuid: cart.uuid });
		const pathname = yield select(routeSelectors.getPathName);
		yield put(replace(pathname.replace(currentUuid, cart.uuid)));
	}
}

/**
 * @param {object} [param0]
 * @param {string} [param0.moduleKey]
 * @param {string} [param0.init] passes `init` option to `fetchDirectly`
 * @param {string} [param0.guestAuth] passes `guestAuth` option to `fetchDirectly`
 * @param {string} [param0.uuid] the uuid of the cart. If not provided, function will use uuid in local storage
 * @returns
 */
function* detailsSaga({ moduleKey, init, guestAuth, uuid: uuidArg } = {}) {
	const uuid = uuidArg || Cookies.get(CART_UUID);

	const { data, error } = yield call(
		cartDetailsAdditionalSagas.fetchDirectly,
		{ uuid, alsowith: ['admin', 'discounts', 'items', 'metadata', 'totals'] },
		moduleKey,
		{ init, guestAuth },
	);

	if (error) {
		yield put(basketActions.setError('load cart'));

		return { error };
	}

	yield call(checkForSharedCart, data);

	return { data };
}

export { checkForSharedCart, defaultAlsowith, detailsSaga };
export default detailsSaga;

import {
	createAPIModule,
	createSelectors,
} from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';

const getStateSlice = (state) => state.api.account.ssh.key.list;
const moduleKeys = { ALL: 'all' }; // init this using init all from modules/sshkeys/actions

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ACCOUNT_SSH_KEY_LIST',
	method: 'POST',
	url: '/account/ssh/key/list.json',
});

// init this using init all from modules/sshkeys/actions
const all = createSelectors(getStateSlice, false, moduleKeys.ALL);

const selectOptions = createSelector(
	all.getNativeState,
	(slice) => [
		{ label: 'No SSH Key', value: 'None' },
		...(slice?.data?.items?.map(({ id: value, public_key_name: label }) => ({
			value: value.toString(),
			label,
		})) || []),
	],
);

// These are here because most apis are going to be expecting the id. But basket/item/asserProperties wants the name.
const idToNameMap = createSelector(
	selectOptions,
	(slice) => {
		const map = {};
		slice.forEach(({ value, label }) => {
			map[value] = label;
		});
		return map;
	},
);

// Used to set the value of the SSHPropertySelect component based on the name from basket/item/details (properties key)
const nameToIdMap = createSelector(
	selectOptions,
	(slice) => {
		const map = {};
		slice.forEach(({ value, label }) => {
			map[label] = value;
		});
		return map;
	},
);

const selectors = {
	items: createSelector(
		defaultSelectors.getStateSlice,
		(slice) => slice.getIn(['data', 'items'], ImmutableList()).toJS(),
	),
	selectOptions,
	idToNameMap,
	nameToIdMap,
	all,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors, moduleKeys };

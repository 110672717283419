import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.storage.objectstore.details;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'STORAGE_OBJECTSTORE_DETAILS',
	method: 'POST',
	url: '/storage/objectstore/details.json',
});

const selectors = {
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import LWTypography from 'components/common/LWTypography';
import useMonitoringBandwidthGraph from 'modules/queries/monitoring/bandwidth/useGraph';
import useDebounce from 'utility/effects/useDebounce';

const SImg = styled.img`
	width: 100%;
`;

const GraphiteBandwidth = ({ uniqId }) => {
	const ref = useRef();

	const [width, setWidth] = useState(900);
	const debounceWidth = useDebounce(width, 1000);

	const { data, isLoading } = useMonitoringBandwidthGraph({
		uniq_id: uniqId,
		width: Math.floor(debounceWidth),
	});

	const content = data?.content;

	// Handle page resize
	useEffect(() => {
		const { current: element } = ref;

		if (!element) return () => {};

		const resizeObserver = new ResizeObserver((entries) => {
			entries.forEach(({ contentRect }) => {
				setWidth(contentRect.width);
			});
		});

		resizeObserver.observe(element);

		return () => {
			resizeObserver.unobserve(element);
		};
	}, [ref]);

	return (
		<Grid ref={ref}>
			<LWTypography variant="h3">Server Bandwidth</LWTypography>
			{isLoading && <CircularProgress />}
			{!isLoading &&
				(content ? (
					<>
						<LWTypography color="textSecondary" variant="caption">
							Bandwidth Avg - Current Month
						</LWTypography>
						<SImg
							src={`data:image/png;base64,${content}`}
							alt="Server Load Graph"
						/>
					</>
				) : (
					<LWTypography>No bandwidth data available</LWTypography>
				))}
		</Grid>
	);
};

GraphiteBandwidth.propTypes = {
	uniqId: PropTypes.string.isRequired,
};

export default GraphiteBandwidth;

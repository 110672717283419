import styled from 'styled-components';
import Sort from '@material-ui/icons/SortRounded';

const SortIcon = styled(Sort)`
	color: ${({ $active, theme }) =>
		$active ? theme.palette.primary.dark : theme.palette.text.disabled};
	transform: rotateX(
		${({
			$sort: sort,
			$isSortIconInverted: isSortIconInverted,
			$active: isActive,
		}) => {
			if (!isActive) {
				return '0deg';
			}
			if (sort === 'desc' && isSortIconInverted) {
				return '0deg';
			}
			if (sort === 'asc' && isSortIconInverted) {
				return '180deg';
			}
			if (sort === 'desc') {
				return '180deg';
			}
			return '0deg';
		}}
	);

	transition: transform 0.2s;
	cursor: pointer;
`;

export default SortIcon;

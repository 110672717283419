import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.market.cart.item.modify;

const { actions, reducer, sagas, selectors, additionalSagas } = createAPIModule(
	{
		getStateSlice,
		actionType: 'MARKET_CART_ITEM_MODIFY',
		url: '/market/cart/item/modify.json',
	},
);

export {
	actions as cartItemModifyActions,
	reducer as cartItemModifyReducer,
	sagas as cartItemModifySagas,
	selectors as cartItemModifySelectors,
	additionalSagas as cartItemModifyAdditionalSagas,
};

import React from 'react';
import ReimageDialogContents from 'components/structural/ReimageDialogContents';
import { connect } from 'react-redux';

import { selectors as assetDetailsSelectors } from 'modules/api/asset/detailsModule';
import { selectors as templateListSelectors } from 'modules/api/storm/template/listModule';
import { selectors as templateRestoreSelectors } from 'modules/api/storm/template/restoreModule';

import restoreActions from 'modules/server/backupsAndImages/restore/actions';
import dialogActions from 'modules/dialogs/actions';

const TemplateConfirmationDialog = ({
	name,
	uniqId,
	onConfirm,
	templateId,
	...props
}) => {
	return (
		<ReimageDialogContents
			onConfirm={(values) => onConfirm({ id: templateId, ...values })}
			{...props}
		/>
	);
};

const mapStateToProps = (state, props) => {
	const template = templateListSelectors.getSelected(state, props);
	return {
		confirmText: 'Reimage Server',
		isLoading: templateRestoreSelectors.isLoading(state),
		sourceTitle: 'Selected Template',
		sourceDetails: [template?.description],
		targetTitle: 'Selected Server',
		targetDetails: [
			assetDetailsSelectors.domain(state),
			assetDetailsSelectors.templateDescription(state),
		],
		templateId: template.id,
		verb: 'reimaging',
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	onConfirm: ({ rebuild, id }) => {
		dispatch(
			restoreActions.restoreTemplate({
				force: rebuild,
				id,
				uniqId: props.uniqId,
			}),
		);
	},
	onCancel: () => {
		dispatch(dialogActions.close());
	},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(TemplateConfirmationDialog);

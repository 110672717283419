import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.api.sslCertificate.changeAutoRenew;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'SSL_CERTIFICATE_CHANGE_AUTO_RENEW',
	method: 'POST',
	url: '/ssl/certificate/changeAutoRenew.json',
});

const selectors = {
	autorenew: createSelector(
		defaultSelectors.getStateSlice,
		(slice) => slice.getIn(['data', 'autorenew_value'], ''),
	),
	status: createSelector(
		defaultSelectors.getStateSlice,
		(slice) => slice.getIn(['data', 'verification_status'], ''),
	),
	uniqId: createSelector(
		defaultSelectors.getStateSlice,
		(slice) => slice.getIn(['data', 'uniq_id'], ''),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };

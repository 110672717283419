import { spawn } from 'redux-saga/effects';
import discountSagas from './discount/sagas';
import itemSagas from './item/sagas';
import cartAdminSagas from './admin/sagas';
import { cartClaimSagas } from './claimModule';
import { cartDetailsSagas } from './detailsModule';
import { cartDomainSagas } from './domainModule';
import { cartExpireSagas } from './expireModule';
import { cartMetadataSagas } from './metadataModule';
import { cartOrderSagas } from './orderModule';

export default function* sagas() {
	yield spawn(cartAdminSagas);
	yield spawn(cartClaimSagas);
	yield spawn(cartDetailsSagas);
	yield spawn(cartDomainSagas);
	yield spawn(cartExpireSagas);
	yield spawn(cartMetadataSagas);
	yield spawn(cartOrderSagas);
	yield spawn(discountSagas);
	yield spawn(itemSagas);
}

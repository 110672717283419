import { spawn } from 'redux-saga/effects';
import { cartItemAddSagas } from './addModule';
import { cartItemModifySagas } from './modifyModule';
import { cartItemRemoveSagas } from './removeModule';

export default function* sagas() {
	yield spawn(cartItemAddSagas);
	yield spawn(cartItemModifySagas);
	yield spawn(cartItemRemoveSagas);
}

import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.product.addon.lookup;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'PRODUCT_ADDON_LOOKUP',
	url: '/product/addon/lookup.json',
});

const selectors = {
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };

import {
	createAPIModule,
	createSelectors,
} from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';
import isFQDN from 'validator/es/lib/isFQDN';
import { splitHostname } from 'utility/redux/selectorHelperFunctions/common';
import { uniqueList } from 'utility/array';

const getStateSlice = (state) => state.api.asset.list;

const moduleKeys = {
	THREAT_STACK: 'THREAT_STACK',
	SERVERS: 'SERVERS',
	ACRONIS: 'ACRONIS',
	cPanelServers: 'cPanelServers',
	OBJECT_STORAGE: 'OBJECT_STORAGE',
	cloudSites: 'cloudSites',
	whmLogin: 'whmLogin',
};

const {
	actions,
	reducer,
	sagas,
	additionalSagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ASSET_LIST',
	method: 'POST',
	url: '/asset/list.json',
	isPaginated: true,
});

const getType = (categories = [], type) => {
	if (type === 'VMware.vCloud.vDC') {
		return 'vmware';
	}
	if (categories.includes('StrictDedicated')) {
		return 'dedicated';
	}
	if (categories.includes('NocworxOpenstack')) {
		return 'open stack';
	}
	if (categories.includes('PrivateParent')) {
		return 'private parent';
	}
	if (categories.includes('VirtualDedicated')) {
		return 'cloud hosting';
	}
	if (categories.includes('CloudSitesList')) {
		return 'cloud sites';
	}
	if (categories.includes('ManagedApplication')) {
		return 'managed app';
	}
	return null;
};

const withType = createSelector(
	defaultSelectors.getStateSlice,
	(slice) =>
		slice
			.getIn(['data', 'items'], ImmutableList())
			.map((asset) =>
				asset.set(
					'assetType',
					getType(asset.get('categories'), asset.get('type')),
				),
			),
);

const uniqueDomains = createSelector(
	defaultSelectors.getNativeItems,
	(items) =>
		uniqueList(
			items
				?.filter(({ domain }) => isFQDN(domain))
				.map(
					({ domain }) =>
						splitHostname(domain).rootDomain ||
						domain /* extractDomain returns empty string is already a domain. */,
				) || [],
		),
);

const cPanelServerSelectors = createSelectors(
	getStateSlice,
	false,
	moduleKeys.cPanelServers,
);

const cloudSitesSelectors = createSelectors(
	getStateSlice,
	false,
	moduleKeys.cloudSites,
);

const whmLoginSelectors = createSelectors(
	getStateSlice,
	false,
	moduleKeys.whmLogin,
);

const selectors = {
	uniqueDomains,
	options: createSelector(
		defaultSelectors.getNativeItems,
		(items) => {
			return items.map((item) => ({ label: item.domain, value: item.uniq_id }));
		},
	),
	withType,
	getcPanelServers: cPanelServerSelectors.getNativeItems,
	iscPanelLoading: cPanelServerSelectors.isLoading,
	cloudSitesSelectors,
	whmLoginSelectors,
	...defaultSelectors,
};

export {
	actions,
	reducer,
	sagas,
	additionalSagas,
	selectors,
	moduleKeys,
	getType,
	getStateSlice,
};

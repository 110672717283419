import { combineReducers } from 'redux';

import addon from './addon/reducer';
import { reducer as details } from './detailsModule';
import { reducer as detailList } from './detailListModule';
import { flatDetailsReducer as flatDetails } from './flatDetailsModule';
import { reducer as price } from './priceModule';
import { reducer as primaryCategoryMapping } from './primaryCategoryMappingModule';

export default combineReducers({
	addon,
	details,
	detailList,
	flatDetails,
	price,
	primaryCategoryMapping,
});

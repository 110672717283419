import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import withWidth from '@material-ui/core/withWidth';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import LWLink from 'components/common/LWLink';
import LWTypography from 'components/common/LWTypography';
import LWButton from 'components/common/LWButton';

import { selectors as appConfigSelectors } from 'modules/appConfig';
import {
	CAUSES,
	MANAGE_REDIRECT_CAUSES,
} from 'modules/disallowedUser/constants';

const SDialog = styled(Dialog)`
	&.MuiDialog-root {
		z-index: ${(p) => p.theme.zIndex.appBar} !important;
	}

	.MuiBackdrop-root {
		background-color: rgba(0, 0, 0, 0.9);
	}

	.MuiDialog-paper {
		padding: ${(p) => p.theme.spacing(4)}px;

		@media (max-width: ${(p) => p.theme.breakpoints.values.md}px) {
			margin-top: ${(p) => p.theme.spacing(6)}px;
		}
	}
`;

const DisallowedUserDialog = ({ cause, manageHostname, width }) => {
	const [isNavigating, setIsNavigating] = useState(false);

	if (!cause) return null;

	let reasonMessage;
	switch (cause) {
		case CAUSES.WRONG_PORTAL:
			reasonMessage =
				'Your account is not able to use My Liquid Web. Please contact support to gain access.';
			break;
		case CAUSES.NO_ACCOUNT_LOGIN:
			reasonMessage =
				'Your user does not have the permission to log in to this interface. Your account owner will need to create a new user to allow logging in to this interface.';
			break;
		default:
			reasonMessage = 'Your account is not able to use My Liquid Web';
	}

	const isFullScreen = width === 'xs' || width === 'sm';

	return (
		<SDialog open fullScreen={isFullScreen}>
			<LWTypography variant="h2" gutterBottom>
				{"We're glad you're here, but ..."}
			</LWTypography>
			<LWTypography variant="body1">{reasonMessage}</LWTypography>
			<Grid container justify="space-evenly">
				{MANAGE_REDIRECT_CAUSES.includes(cause) && (
					<Box mt={4}>
						<LWLink to={`${manageHostname}/`} target="_self" underline="none">
							<LWButton
								color="secondary"
								variant="contained"
								isLoading={isNavigating}
								onClick={() => setIsNavigating(true)}
								endIcon={<ArrowForwardIcon />}
							>
								Go to Manage
							</LWButton>
						</LWLink>
					</Box>
				)}
				<Box mt={4}>
					<LWLink to="/logout" underline="none">
						<LWButton variant="contained">Logout</LWButton>
					</LWLink>
				</Box>
			</Grid>
		</SDialog>
	);
};

const mapStateToProps = (state) => ({
	manageHostname: appConfigSelectors.getManageHostname(state),
	cause: state.disallowedUser.cause,
});

export default compose(
	withWidth(),
	connect(mapStateToProps),
)(DisallowedUserDialog);

// @ts-check
import { fromApiBoolean } from 'club-sauce';
import addonCapabilityDetails from 'utility/constants/asset/addonCapabilityDetails';

/** @param {import('modules/queries/asset/useDetails').AssetDetails['capabilities']} capabilities */
const createAvailableSecurityAddonsDetails = (capabilities) => {
	/** @type {import('utility/constants/asset/addonCapabilityDetails').AddonCapabilityT[]} */
	const availableAddOns = [];
	if (!capabilities) return availableAddOns;

	const addOnRegExp = new RegExp('^addon.*');
	Object.entries(capabilities).forEach(([capability, value]) => {
		if (addOnRegExp.test(capability) && fromApiBoolean(value)) {
			const addOnDetails = addonCapabilityDetails[capability];
			if (addOnDetails.type === 'security') {
				availableAddOns.push(addOnDetails);
			}
		}
	});
	return availableAddOns;
};

export default createAvailableSecurityAddonsDetails;

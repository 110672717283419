import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.storm.backup.restore;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
	additionalSagas,
} = createAPIModule({
	getStateSlice,
	actionType: 'STORM_BACKUP_RESTORE',
	method: 'POST',
	url: '/storm/backup/restore.json',
});

const selectors = {
	...defaultSelectors,
};

export {
	actions,
	reducer,
	sagas,
	selectors,
	additionalSagas as backupRestoreAdditionalSagas,
};

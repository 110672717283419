import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import LWTypography from 'components/common/LWTypography';

const ellipsis = keyframes`
	to {
		width: 1em;
 	}
`;

const AnimatedEllipsis = styled.span`
	&::after {
		overflow: hidden;
		display: inline-block;
		vertical-align: bottom;
		width: 0px;
		content: '...';
		animation: ${ellipsis} steps(4, end) 1.2s infinite;
	}
`;

const testIds = { LWLoader: 'LWLoader' };

const LWLoader = ({ redirect, children }) => (
	<div
		style={{
			display: 'flex',
			flexDirection: 'column',
			gap: '32px',
			alignItems: 'center',
		}}
		data-testid={testIds.LWLoader}
	>
		<CircularProgress size={100} color="secondary" />
		<LWTypography bold variant="subtitle1">
			{redirect ? 'Redirecting' : 'Loading'}
			<Box display="inline-block" width={1.25}>
				<AnimatedEllipsis />
			</Box>
		</LWTypography>
		<div>
			<LWTypography align="center" variant="h3">
				{redirect ? 'You are being redirected.' : 'Finishing up a few things.'}
			</LWTypography>
			<LWTypography align="center" variant="h3">
				One more moment.
			</LWTypography>
		</div>
		{children}
	</div>
);

LWLoader.propTypes = {
	/** Set to true for the redirect variant. */
	redirect: PropTypes.bool,
	/** Optional children */
	children: PropTypes.node,
};

export { testIds };
export default LWLoader;

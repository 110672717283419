import { select, call, all } from 'redux-saga/effects';
import { initStormBackupList } from 'modules/backups/sagas';
import { initStormImageList } from 'modules/images/sagas';
import { getHasActivatedAccount } from 'modules/auth';

function* initBackupsAndImages() {
	const hasActivatedAccount = yield select(getHasActivatedAccount);
	if (!hasActivatedAccount) return;

	yield all([call(initStormImageList), call(initStormBackupList)]);
}

// eslint-disable-next-line import/prefer-default-export
export { initBackupsAndImages };

import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.network.loadbalancer.update;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_LOAD_BALANCER_UPDATE',
	url: '/network/loadbalancer/update.json',
});

export { actions, reducer, sagas, selectors };

// @ts-check
import { useQuery } from '@tanstack/react-query';
import api from 'modules/queries/api';

/**
 * @param {import('club-sauce/public/monitoring/bandwidth/index.raw').LWApiPublicMonitoringBandwidthGraphParamsRawI} params
 */
const useMonitoringBandwidthGraph = (params) =>
	useQuery({
		queryKey: ['/monitoring/bandwidth/graph', params],
		queryFn: () => api.monitoring.bandwidth.apiRaw.graph(params),
	});

export default useMonitoringBandwidthGraph;

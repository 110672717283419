import { packagePreviewContentKey } from 'components/common/SideSheet/contents/PackagePreviewContents';

const actions = {
	OPEN: 'SIDESHEET_OPEN',
	open: ({ title, contentKey, contentProps }) => ({
		type: actions.OPEN,
		title,
		contentKey,
		contentProps,
	}),
	IS_LOADING: 'SIDESHEET_IS_LOADING',
	isLoading: () => ({
		type: actions.IS_LOADING,
	}),
	SIDESHEET_OPEN_CART_PREVIEW: 'SIDESHEET_OPEN_CART_PREVIEW',
	openCartPreview: ({ title, contentKey }) => ({
		type: actions.SIDESHEET_OPEN_CART_PREVIEW,
		title,
		contentKey,
	}),
	SIDESHEET_OPEN_PACKAGE_PREVIEW: 'SIDESHEET_OPEN_PACKAGE_PREVIEW',
	/**
	 * @param {import('club-sauce/public/market/package/index.raw').LWApiPublicMarketPackageDetailsResultRawI} payload
	 * */
	openPackagePreview: (payload) => {
		const { title } = payload;

		return {
			contentProps: payload,
			title,
			type: actions.SIDESHEET_OPEN_PACKAGE_PREVIEW,
			contentKey: packagePreviewContentKey,
		};
	},
	CLOSE: 'SIDESHEET_CLOSE',
	close: () => ({
		type: actions.CLOSE,
	}),
	SET_TITLE: 'SIDESHEET_SET_TITLE',
	setTitle: (title) => ({
		type: actions.SET_TITLE,
		title,
	}),
};

export default actions;

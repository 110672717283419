import React from 'react';
import { useDispatch } from 'react-redux';
import taskSchedulerActions from 'modules/server/taskScheduler/actions';

import { SLWButton } from '../actionStyles';

/** @param {import('modules/queries/asset/useDetails').AssetDetails} props */
const DedicatedActions = ({
	domain,
	uniq_id: uniqId,
	capabilities,
	status,
}) => {
	const dispatch = useDispatch();

	const canAutoReboot = !!capabilities?.autoReboot;

	const openServerRebootDialog = () => {
		dispatch(
			taskSchedulerActions.openDialog({
				apiParams: { uniq_id: uniqId },
				hostname: domain,
				task: 'reboot',
				canAutoReboot,
			}),
		);
	};

	return (
		<SLWButton
			onClick={openServerRebootDialog}
			color="secondary"
			variant="outlined"
			disabled={status !== 'Active'}
		>
			Reboot
		</SLWButton>
	);
};

export default DedicatedActions;

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import NavIcon from 'components/atoms/NavIcon';

import { useLocation } from 'react-router-dom';

// Custom components
import MenuButton from 'components/organisms/MenuButton';

/**
 * Get base path
 * This is used to determine if the icon should be set to active based on the url
 * @param {string} fullPath url including path
 */
const getBasePath = (fullPath) => {
	// Split the URL by "/"
	const parts = fullPath.split('/');
	// Get the last part of the URL
	const basePath = parts[1];
	// If the base path returns nothing that indicates that it is the home page
	if (basePath.length === 0) {
		return 'home';
	}
	// This is a workaround so that the cart url will set the Shop icon to active
	if (basePath === 'cart') {
		return 'shop';
	}
	return basePath;
};

/**
 * An opinionated connected version of `MenuButton` that transposes raw data into nodes and functions. This enables us to drive MenuButton from a selector.
 *
 * iconKey is used to get the correct `NavIcon`.
 * action is transposed into a function and passed into MenuButton.
 * menuData is forwarded to `MenuPopper`.
 */
const NavMenuButton = ({
	iconKey,
	children,
	action,
	menuData: menuDataProp = [],
	notifications,
	to,
}) => {
	const dispatch = useDispatch();
	const onClick = action ? () => dispatch(action) : undefined;

	// Transpose actions into functions so we can pass them down to unconnected components.
	const menuData = menuDataProp.map(
		({
			label: menuLabel,
			action: menuAction,
			notifications: menuNotifications,
			to: menuTo,
		}) => ({
			label: menuLabel,
			onClick: menuAction ? () => dispatch(menuAction) : undefined,
			notifications: menuNotifications,
			to: menuTo,
		}),
	);

	const { pathname } = useLocation();

	const isActive = getBasePath(pathname) === iconKey;

	return (
		<MenuButton
			icon={<NavIcon iconKey={iconKey} active={isActive ? 'Active' : ''} />}
			onClick={onClick}
			menuData={menuData}
			notifications={notifications}
			to={to}
			isActive={isActive}
		>
			{children}
		</MenuButton>
	);
};

NavMenuButton.propTypes = {
	/** The text that appears underneath the icon */
	children: PropTypes.string,
	/** A preconstructed action that is ready dispatched as such. */
	action: PropTypes.object,
	/** An array to be passed into the MenuPopper if it has length. See MenuPopper for more details. */
	menuData: PropTypes.array,
};

export default NavMenuButton;

// @ts-check
import loadBalancerConstants from 'utility/constants/loadBalancers';

/**
 * @param {import('club-sauce/public/network/loadbalancer/index.raw.ts').LWApiPublicNetworkLoadBalancerPossibleNodeI[]} rawNodes
 */
export const formatIPs = (rawNodes = []) => {
	return rawNodes
		.filter(
			// exclude duplicates
			(node, index, nodes) =>
				index === nodes.findIndex((item) => item.uniq_id === node.uniq_id),
		)
		.map(({ domain, region_id: regionId, uniq_id: uniqId }) => {
			/**
			 * @param {string} ip
			 */
			const ip2Int = (ip) =>
				ip
					.split('.')
					.reduce((acc, cur, i) => acc + Number(cur) * 256 ** (3 - i), 0);

			const ips = rawNodes
				.reduce(
					/**
					 * @param {string[]} result
					 * @param {import('club-sauce/public/network/loadbalancer/index.raw.ts').LWApiPublicNetworkLoadBalancerPossibleNodeI} item
					 * @return {string[]}
					 */
					(result, item) =>
						item.uniq_id === uniqId ? [...result, item.ip] : result,
					[],
				)
				.sort(
					/**
					 * @param {string} a
					 * @param {string} b
					 */
					(a, b) => ip2Int(a) - ip2Int(b),
				);

			return {
				domain,
				regionId,
				uniqId,
				ips,
			};
		});
};

/**
 * @param {import('club-sauce/public/network/zone.raw.ts').LWApiPublicNetworkZoneListResultItemRawI[]} zones
 */
export const getZoneRegions = (zones = []) =>
	zones
		.sort((a, b) => Number(a.is_default) - Number(b.is_default))
		.map((zone) => zone.region)
		.filter(
			// exclude duplicates
			(region, index, regions) =>
				index === regions.findIndex((item) => item.id === region.id),
		);

/**
 * @param {object} param0
 * @param {string} param0.destinationPort
 * @param {boolean} param0.enableSSLTermination
 * @param {boolean} param0.includeIntermediateCertificate
 * @param {string[]} param0.ips
 * @param {string} param0.name
 * @param {string} param0.regionId
 * @param {string} param0.service
 * @param {boolean} param0.sessionPersistence
 * @param {string} param0.sourcePort
 * @param {string} param0.sslCertificate
 * @param {string} param0.sslIntermediateCertificate
 * @param {string} param0.sslPrivateKey
 * @param {string} param0.strategy
 */
export const makeCreateLoadBalancerApiParams = ({
	destinationPort,
	enableSSLTermination,
	includeIntermediateCertificate,
	ips,
	name,
	regionId,
	service,
	sessionPersistence,
	sourcePort,
	sslCertificate,
	sslIntermediateCertificate,
	sslPrivateKey,
	strategy,
}) => {
	let sslIncludes = includeIntermediateCertificate ? 1 : 0;
	if (!enableSSLTermination) {
		sslIncludes = 0;
	}
	const payload = {
		name,
		region: regionId,
		strategy,
		session_persistence: sessionPersistence ? 1 : 0,
		nodes: ips,
		ssl_termination: enableSSLTermination ? 1 : 0,
		ssl_includes: sslIncludes,
		/** @type {null | string} */
		ssl_cert: null,
		/** @type {null | string} */
		ssl_key: null,
		/** @type {null | string} */
		ssl_int: null,
		/** @type {object[]} */
		services: [],
	};

	if (service === loadBalancerConstants.SERVICE.WEB) {
		payload.services = [
			{
				src_port: loadBalancerConstants.PORT.WEB.SRC,
				dest_port: loadBalancerConstants.PORT.WEB.SRC,
			},
			{
				src_port: loadBalancerConstants.PORT.WEB.DEST,
				dest_port: loadBalancerConstants.PORT.WEB.DEST,
			},
		];
	} else {
		payload.services = [
			{
				src_port: sourcePort,
				dest_port: destinationPort,
			},
		];
	}

	if (enableSSLTermination) {
		payload.ssl_cert = sslCertificate;
		payload.ssl_key = sslPrivateKey;

		if (includeIntermediateCertificate) {
			payload.ssl_int = sslIntermediateCertificate;
		}
	}

	return payload;
};

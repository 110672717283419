import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.usertask.list;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'USERTASK_LIST',
	method: 'POST',
	url: '/usertask/list.json',
	isPaginated: true,
});

export { actions, reducer, sagas, selectors };

import { useMutation, useQueryClient } from '@tanstack/react-query';
import useSnackbarError from 'utility/hooks/useSnackbarError';
import api from 'modules/queries/api';

const useProjectRename = () => {
	const queryClient = useQueryClient();
	const snackbarError = useSnackbarError();

	return useMutation({
		mutationFn:
			/** @param {import('club-sauce/public/project/index.raw').LWApiPublicProjectRenameParamsRawI} params */
			(params) => api.project.apiRaw.rename(params),
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({
				queryKey: ['/project/details', { id: variables.id }],
			});

			queryClient.invalidateQueries({ queryKey: ['/project/list'] });
		},
		onError: snackbarError,
	});
};

export default useProjectRename;

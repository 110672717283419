import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import LWForm from 'components/LWForm';
import HookedTextField from 'components/LWForm/HookedTextField';
import HookedConfirmCancel from 'components/LWForm/HookedConfirmCancel';
import LWTypography from 'components/common/LWTypography';
import dialogActions from 'modules/dialogs/actions';
import { cartDetailsSelectors } from 'modules/api/market/cart/detailsModule';
import cartActions from 'modules/cart/actions';
import { getErrorStringHelper } from 'utility/redux/selectorHelperFunctions/apiModules';
import snackbarActions from 'modules/snackbar/snackbarActions';
import { validateDomain } from 'utility/lwForm/validators';

const EditCartDetailsDialogContents = () => {
	const dispatch = useDispatch();
	const {
		name: initialName = '',
		description: initialDescription = '',
		domain: initialDomainToAutoAdd = '',
	} = useSelector(cartDetailsSelectors.getMetadata);

	const [formValues, setFormValues] = useState({
		name: initialName,
		description: initialDescription,
		domainToAutoAdd: initialDomainToAutoAdd,
	});
	const [formErrors, setFormErrors] = useState({
		domainToAutoAdd: '',
	});

	const handleSubmit = () => {
		dispatch(dialogActions.close());

		const { name, description, domainToAutoAdd } = formValues;
		dispatch(
			cartActions.editDetails({
				name,
				description,
				domainToAutoAdd,
				onError: (error) =>
					dispatch(
						snackbarActions.pushMessage({
							variant: 'error',
							message: getErrorStringHelper(error),
						}),
					),
			}),
		);
	};
	const handleClose = () => {
		dispatch(dialogActions.close());
	};

	return (
		<Grid
			container
			spacing={3}
			direction="column"
			component={LWForm}
			values={formValues}
			onValuesChange={setFormValues}
			errors={formErrors}
			onErrorsChange={setFormErrors}
			onSubmit={handleSubmit}
			validators={{ domainToAutoAdd: validateDomain }}
		>
			<Grid item>
				<HookedTextField label="Name" name="name" />
			</Grid>
			<Grid item>
				<HookedTextField
					label="Description"
					name="description"
					multiline
					rows={4}
				/>
			</Grid>
			<Grid item>
				<Divider />
			</Grid>
			<Grid item>
				<LWTypography variant="body2">
					By using a domain to auto-add, any items in the quote will
					automatically use the domain. If the domain is changed here, all items
					in the quote will be changed to use that domain. Domains of individual
					items are still editable on their own.
				</LWTypography>
			</Grid>
			<Grid item>
				<HookedTextField label="Domain to Auto-add" name="domainToAutoAdd" />
			</Grid>
			<Grid item>
				<HookedConfirmCancel
					confirmText="Save"
					cancelText="Cancel"
					cancel={handleClose}
				/>
			</Grid>
		</Grid>
	);
};

export default EditCartDetailsDialogContents;

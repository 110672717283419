import React from 'react';
import LabeledIcon from 'components/atoms/LabeledIcon';
import LWTypography from 'components/common/LWTypography';
import GradientSquare from 'components/atoms/GradientSquare';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

const DataKeys = ({ keys }) => (
	<Grid container direction="row">
		{keys.map(({ color, label, bold }, idx) => {
			const BoxProps = { pr: idx < keys.length - 1 ? 3 : 0 };
			return (
				<Grid item key={label}>
					{color ? (
						<LabeledIcon
							textProps={{
								bold,
								BoxProps,
							}}
							icon={<GradientSquare color={color} />}
							text={label}
						/>
					) : (
						<LWTypography {...BoxProps} bold={bold}>
							{label}
						</LWTypography>
					)}
				</Grid>
			);
		})}
	</Grid>
);

DataKeys.propTypes = {
	keys: PropTypes.arrayOf(
		PropTypes.shape({
			color: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.arrayOf(PropTypes.string),
			]),
			label: PropTypes.string.isRequired,
			bold: PropTypes.bool,
		}),
	),
};

export default DataKeys;

const contentArrays = {
	default: [
		{
			key: 'about',
			label: 'About 404',
			text: 'This page may have been removed, relocated or does not exist.',
		},
		{
			key: 'what',
			label: 'What happened?',
			text:
				'If you typed the URL directly, something might be misspelled. If you clicked a link to get here, the link may be outdated.',
		},
		{
			key: 'action',
			label: 'What can I do?',
			text:
				' For best results, sign-in and use menu and page navigation to find up-to-date pages.',
		},
	],
	addToCart: [
		{
			key: 'about',
			label: 'About Add-to-Cart Error',
			text: 'The item selected was not added to the cart.',
		},
		{
			key: 'what',
			label: 'What happened?',
			text:
				'A parameter may hold an incompatible value, the product has changed, or the API call timed out.',
		},
		{
			key: 'action',
			label: 'What can I do?',
			text:
				'Try again, but if the error continues, contact sales for direct support.',
		},
	],
};

const titles = {
	default: '404 Error',
	addToCart: 'Add-to-Cart Error',
};

const breadCrumbNames = {
	default: '404 Error - Page not Found',
	addToCart: 'Add-to-Cart Error',
};

const refreshButtons = { default: null, addToCart: 'Try Again' };

export { contentArrays, titles, breadCrumbNames, refreshButtons };

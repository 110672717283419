import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';

const getStateSlice = (state) => state.api.network.dns.zone.history.compare;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_DNS_ZONE_HISTORY_COMPARE',
	method: 'POST',
	url: '/network/dns/zone/history/compare.json',
});

const selectors = {
	records: createSelector(
		defaultSelectors.getStateSlice,
		(slice) => {
			const records = slice.getIn(['data', 'records'], ImmutableList());
			if (!records) return ImmutableList();
			return records.sortBy((record) => record.get('type'));
		},
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };

// @ts-check
import { useQuery } from '@tanstack/react-query';
import { fromApiBoolean } from 'club-sauce';
import api from 'modules/queries/api';

/**
 * @param {import('club-sauce/internal/market/package/product.raw').LWApiInternalMarketPackageProductListResultRawI} data
 */
const selectProducts = (data) => {
	const filtered = data.items.filter(
		({ add_on: addOn }) => !fromApiBoolean(addOn),
	);
	const allSorted = [...filtered].sort(
		(a, b) => a.display_order - b.display_order,
	);

	/** @type {Record<string, import('club-sauce/internal/market/package/product.raw').LWApiInternalMarketPackageProductDetailsResultRawI[]>} */
	const relatedProducts = {};

	allSorted.forEach((product) => {
		const { related_market_package_to_product_id: relatedProductId } = product;

		if (!relatedProductId) {
			return;
		}

		relatedProducts[relatedProductId] = relatedProducts[relatedProductId] || [];
		relatedProducts[relatedProductId].push(product);
	});

	const parents = allSorted
		.filter((product) => !product.related_market_package_to_product_id)
		.map((parent) => [parent, ...(relatedProducts[parent.id] || [])]);

	return parents.flat();
};

/**
 * @param {import('club-sauce/public/market/package/product.raw').LWApiPublicMarketPackageProductListParamsRawI} params
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 */
const usePackageProductList = (params, { enabled } = {}) =>
	useQuery({
		queryKey: ['/market/package/product/list', params],
		queryFn: () => api.market.package.product.apiRaw.list(params),
		enabled,
		select: selectProducts,
	});

export default usePackageProductList;

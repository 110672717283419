import { takeEvery, put, call, select, take } from 'redux-saga/effects';
import {
	actions as assetDetailsActions,
	selectors as assetDetailsSelectors,
} from 'modules/api/asset/detailsModule';
import { replace } from 'connected-react-router';
import snackbarSaga from 'modules/snackbar/sagas';
import goActions from './actions';

function* redirect({ uniqId }) {
	yield put(
		assetDetailsActions.fetch({ uniq_id: uniqId, alsowith: ['categories'] }),
	);
	const error =
		(yield take([assetDetailsActions.setType, assetDetailsActions.errorType]))
			?.type === assetDetailsActions.errorType;
	yield call(snackbarSaga, {
		error,
		errorMessage:
			'Something went wrong with loading your asset. Please try again or contact support',
	});
	const newPath = yield select(assetDetailsSelectors.getAssetRoute);
	yield put(replace(newPath));
}

export default function*() {
	yield takeEvery(goActions.GO_REDIRECT_BY_UNIQID, redirect);
}

import { spawn, put, takeLatest } from 'redux-saga/effects';
import {
	sagas as assignableIpsSagas,
	dnsReverseAssignableIpsActions,
} from './assignableIpsModule';
import { sagas as deleteSagas, dnsReverseDeleteActions } from './deleteModule';
import { sagas as updateSagas, dnsReverseUpdateActions } from './updateModule';

function* fetchAssignableIps() {
	yield put(dnsReverseAssignableIpsActions.fetch());
}

export default function*() {
	yield spawn(assignableIpsSagas);
	yield spawn(deleteSagas);
	yield spawn(updateSagas);
	yield takeLatest(
		[dnsReverseUpdateActions.setType, dnsReverseDeleteActions.setType],
		fetchAssignableIps,
	);
}

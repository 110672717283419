import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// MUI Components
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Badge from '@material-ui/core/Badge';

// Custom Components
import LWTypography from 'components/common/LWTypography';
import LWLink from 'components/common/LWLink';

const testids = { MenuPopper__Container: 'MenuPopper__Container' };

const SLWLink = styled(LWLink)`
	text-decoration: none;
`;

// puts navbuttons ontop of sticky table
// TODO: Do we need this?
const SPopper = styled(Popper)`
	z-index: 1401;
`;

const MenuPopper = ({ open, anchorEl, menuData }) => {
	if (!menuData) return null;
	return (
		<SPopper open={open} anchorEl={anchorEl} placement="bottom">
			<Paper>
				{menuData.map(
					({ label: menuLabel, onClick: menuOnClick, notifications, to }) => (
						<SLWLink key={menuLabel} to={to} onClick={menuOnClick}>
							<MenuItem>
								<Badge
									variant={notifications > 0 ? 'dot' : undefined}
									color="error"
								>
									<LWTypography uppercase color="palette.primary.alt">
										{menuLabel}
									</LWTypography>
								</Badge>
							</MenuItem>
						</SLWLink>
					),
				)}
			</Paper>
		</SPopper>
	);
};

MenuPopper.propTypes = {
	/** Should the popper be open? */
	open: PropTypes.bool,
	/** Pass in the "ref.current" from a useRef hook attached to the parent component */
	anchorEl: PropTypes.any.isRequired,
	/** The labels and functions describing the menu. */
	menuData: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			onClick: PropTypes.func,
			to: PropTypes.string,
		}),
	).isRequired,
};

export { testids };
export default MenuPopper;

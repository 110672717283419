import { takeEvery, put, select, call } from 'redux-saga/effects';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import selectors from './selectors';
import actions from './actions';

// This is designed to only go back if the user's previous route was in the app.
function* goBack() {
	const previousRoute = yield select(selectors.getPreviousRoute);
	if (previousRoute) yield put(push(previousRoute));
}

function* pushCurrentRoute({
	payload: {
		location: { pathname },
	},
}) {
	const stalePathName = yield select(selectors.getCurrentRoute);
	if (pathname !== stalePathName)
		yield put(actions.pushCurrentRoute({ route: pathname }));
}

/**
 * DEPRECATED - avoid using as navigating to URLs outside the app via redux saga
 * can cause unexpected behaviors. If you need to navigate to a URL outside the
 * app, do so with an anchor tag. If you need to route to an internal path, use
 * `connected-react-router`'s `push`
 * @deprecated
 */
function* smartNavigate({ path }) {
	if (!path) {
		// eslint-disable-next-line no-console
		console.error('path is required.');
		return;
	}

	const isInbound = path[0] === '/';
	if (isInbound) {
		yield put(push(path));
	} else {
		yield call([window, 'open'], path, 'noopener');
	}
}

function* handleTakeToHomepage() {
	const homepagePath = yield select(selectors.getHomepagePath);
	yield call(smartNavigate, { path: homepagePath });
}

export default function*() {
	const pathname = yield select(selectors.getPathName);
	yield call(pushCurrentRoute, { payload: { location: { pathname } } });
	yield takeEvery(LOCATION_CHANGE, pushCurrentRoute);
	yield takeEvery(actions.ROUTE_GO_BACK, goBack);
	yield takeEvery(actions.ROUTE_TAKE_TO_HOMEPAGE, handleTakeToHomepage);
	yield takeEvery(actions.ROUTE_SMART_NAVIGATE, smartNavigate);
}

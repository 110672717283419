import { call, put, select, takeLatest } from 'redux-saga/effects';

import { updateGaAttribution } from 'modules/attribution/sagas';
import { cartDetailsSelectors } from 'modules/api/market/cart/detailsModule';
import { UUID_IS_FROM_URL } from 'utility/constants/auth';
import dialogActions from 'modules/dialogs/actions';
import { isMasquerade } from 'modules/auth/authSelectors';
import { mergeOldCartWithNew } from 'modules/cart/sagas';
import cartActions from 'modules/cart/actions';

import {
	cartClaimActions,
	cartClaimAdditionalSagas,
} from 'modules/api/market/cart/claimModule';
import basketActions from '../../actions';

function* handleClaimError({ error }) {
	if (error) yield put(basketActions.setError('claim cart'));
}

function* handleClaimBasket({ uuid, isManual }) {
	const uuidFromUrl = yield call([sessionStorage, 'getItem'], UUID_IS_FROM_URL);
	if ((yield select(isMasquerade)) && !isManual && uuidFromUrl) {
		yield put(
			dialogActions.open({
				title: 'Proceed with Claim Cart?',
				contentKey: 'MasqueradeModalContents',
				dismissible: false,
			}),
		);
		yield call([sessionStorage, 'removeItem'], UUID_IS_FROM_URL);
		return;
	}

	const { data, error } = yield call(cartClaimAdditionalSagas.fetchDirectly, {
		uuid,
		// claiming a cart can affect discounts and totals if the account that
		// claims that cart has a account level discount
		alsowith: ['discounts', 'totals'],
	});

	if (error) {
		yield call(handleClaimError, { error });
		return;
	}

	yield call(mergeOldCartWithNew, { newCartPortion: data });
}

function* claimBasket({
	isManual, // If called from the claim action, this is true by default. See BASKET_CLAIM action creator.
} = {}) {
	const { orderable, uuid } = yield select(
		cartDetailsSelectors.getNativeData,
	) || {};

	yield call(updateGaAttribution);

	if (orderable) {
		yield call(handleClaimBasket, { uuid, isManual });
	}
}

export { handleClaimBasket, handleClaimError, claimBasket };
export default function*() {
	yield takeLatest(cartClaimActions.errorType, handleClaimError);
	yield takeLatest(cartActions.CART_CLAIM, claimBasket);
}

import { takeEvery, call, put } from 'redux-saga/effects';
import snackbarActions from 'modules/snackbar/snackbarActions';
import isHTML from 'utility/tools/isHTML';
import actions from './actions';

/**
 *
 * @param {object} param0.node the html node to be copied.
 */
function* handleCopyRichText({ html }) {
	if (navigator.clipboard.write) {
		const blob = new Blob([html], { type: 'text/html' });
		const data = [new window.ClipboardItem({ [blob.type]: blob })];
		yield call([navigator.clipboard, 'write'], data);
	} else {
		/* while "execCommand" is deprecated, the alternative "write" method
		of navigator.clipboard is not fully implemented.

		For more information: https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard */
		const listener = (e) => {
			e.clipboardData.setData('text/html', html);
			e.clipboardData.setData('text/plain', html);
			e.preventDefault();
		};
		yield call([document, 'addEventListener'], 'copy', listener);
		yield call([document, 'execCommand'], 'copy');
		yield call([document, 'removeEventListener'], 'copy', listener);
	}
}

/**
 *
 * @param {string} params.text the text to copy
 */
function* handleCopyToClipboard({
	text,
	message = 'Copied to clipboard.',
	contentKey,
	contentProps,
}) {
	if (isHTML(text)) {
		yield call(handleCopyRichText, { html: text });
	} else {
		yield call([navigator.clipboard, 'writeText'], text);
	}
	yield put(
		snackbarActions.pushMessage({
			message,
			variant: 'info',
			contentKey,
			contentProps,
		}),
	);
}

export default function* copyToClipboardSagas() {
	yield takeEvery(actions.UTILITY_COPY_TO_CLIPBOARD, handleCopyToClipboard);
}

import { cartDetailsActions } from 'modules/api/market/cart/detailsModule';
import basketDomainsActions from 'modules/basket/actions/domains';
import testUtilActions from 'testUtils/actions';

const initialState = {
	whoIs: null,
	nameServers: null,
	isLoading: {
		DNS: false,
		WHO_IS: false,
	},
	domainLoadingMap: {},
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case cartDetailsActions.setType: {
			// Fill nameserver / whoIs form on basket details
			const { payload } = action;
			const firstDreg = payload?.items?.find(
				(item) => item.product_code === 'DREG',
			);
			if (firstDreg) {
				const { properties } = firstDreg;
				if (properties) {
					const { nameServers, ...whoIs } = properties;

					const mapKeys = () => {
						const keysObj = {
							FirstName: 'fname',
							LastName: 'lname',
							Address1: 'address',
							Address2: 'address2',
							EmailAddress: 'email',
							Phone: 'phone',
							City: 'city',
							StateProvince: 'state',
							Country: 'country',
							PostalCode: 'postal_code',
							OrganizationName: 'organization',
							Fax: 'fax',
							JobTitle: 'contact_role',
							lwtech: 'lwtech',
						};

						const keys = Object.keys(keysObj);

						const contact = {};

						keys.forEach((key) => {
							contact[keysObj[key]] = whoIs[key];
						});

						return contact;
					};

					return {
						...state,
						whoIs: mapKeys(),
						nameServers,
					};
				}
			}

			return state;
		}

		case basketDomainsActions.TOGGLE_LOADING:
			return {
				...state,
				isLoading: { ...state.isLoading, [action.key]: action.value },
			};

		case basketDomainsActions.SUBMIT_WHO_IS:
			return {
				...state,
				whoIs: action.whoIs,
			};

		case basketDomainsActions.EDIT_NAME_SERVERS:
			return { ...state, nameServers: action.nameServers };

		case basketDomainsActions.TOGGLE_DOMAIN_LOADING:
			return {
				...state,
				domainLoadingMap: {
					...state.domainLoadingMap,
					[action.domain]: action.value,
				},
			};

		case testUtilActions.TEST_UTIL_CLEAR_STATE:
			return initialState;
		default:
			return state;
	}
};

export { initialState };
export default reducer;

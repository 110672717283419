import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.api.network.loadbalancer.possibleNodes;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_LOADBALANCER_POSSIBLENODES',
	url: '/network/loadbalancer/possibleNodes.json',
});

const selectors = {
	getNodesGroupedByDomain: createSelector(
		defaultSelectors.getNativeItems,
		(items) =>
			items
				.filter(
					// exclude duplicates
					(node, index, nodes) =>
						index === nodes.findIndex((item) => item.uniq_id === node.uniq_id),
				)
				// eslint-disable-next-line camelcase
				.map(({ domain, region_id, uniq_id }) => {
					const ip2Int = (ip) =>
						ip
							.split('.')
							.reduce((acc, cur, i) => acc + Number(cur) * 256 ** (3 - i), 0);

					const ips = items
						.reduce(
							(result, item) =>
								// eslint-disable-next-line camelcase
								item.uniq_id === uniq_id ? [...result, item.ip] : result,
							[],
						)
						.sort((a, b) => ip2Int(a) - ip2Int(b));

					return {
						domain,
						region_id,
						uniq_id,
						ips,
					};
				}),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };

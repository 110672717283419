import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// MUI Components
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';

// Custom Components
import LabeledIcon from 'components/atoms/LabeledIcon';
import LWLink from 'components/common/LWLink';
import MenuPopper from 'components/molecules/MenuPopper';

const SBadge = styled(Badge)`
	& span {
		transform: scale(0.85) translate(25%, -25%);
	}
`;

const Container = styled(Box)`
	&:hover {
		svg {
			background-color: ${({ theme, $isActive }) =>
				$isActive ? theme.palette.primary.main : '#ebebeb'};
		}
	}
`;

/**
 * Originally designed for the navbar, this allows us to have a labeled icon that
 * pops open more options onMouseEnter/onFocus. The button itself and its submenu
 * have their own click handlers.
 */
const MenuButton = ({
	icon,
	children,
	onClick = () => {},
	menuData = [],
	notifications = 0,
	to,
	isActive,
}) => {
	const [showingMenu, setShowingMenu] = useState(false);
	const showMenu = () => setShowingMenu(true);
	const hideMenu = () => setShowingMenu(false);
	const ref = useRef();
	return (
		<Container
			ref={ref}
			display="inline-flex"
			onMouseEnter={showMenu}
			onMouseLeave={hideMenu}
			$isActive={isActive}
		>
			<LWLink to={to}>
				<LabeledIcon
					flexDirection="column"
					onClick={onClick}
					color="grey.dark"
					icon={
						<SBadge
							color="error"
							badgeContent={notifications}
							data-testid={`MenuButton__${children}__Notification`}
							anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						>
							{icon}
						</SBadge>
					}
					text={children}
					textProps={{
						variant: 'subtitle2',
						color: isActive ? 'palette.primary.main' : 'palette.grey.dark',
					}}
					spacing={0.5}
				/>
			</LWLink>
			{!!menuData.length && !!ref.current && (
				<MenuPopper
					open={showingMenu}
					anchorEl={ref.current}
					menuData={menuData}
				/>
			)}
		</Container>
	);
};

MenuButton.propTypes = {
	/** The icon that should be displayed as the focal point of the component */
	icon: PropTypes.node,
	/** The text that appears underneath the icon */
	children: PropTypes.string,
	/** A function executed when the box is clicked */
	onClick: PropTypes.func,
	/** An array to be passed into the MenuPopper if it has length. See MenuPopper for more details. */
	menuData: PropTypes.array,
	/** A number that should appear in the red dot. */
	notifications: PropTypes.number,
};

export default MenuButton;

import { spawn } from 'redux-saga/effects';

import domainSagas from './domain/domainSagas';
import recordSagas from './record/recordSagas';
import reverseSagas from './reverse/reverseSagas';
import zoneSagas from './zone/zoneSagas';

export default function* dnsSagas() {
	yield spawn(domainSagas);
	yield spawn(recordSagas);
	yield spawn(reverseSagas);
	yield spawn(zoneSagas);
}

const attributionActions = {
	BASKET_PURCHASE_SEND_ATTRIBUTION_DATA:
		'BASKET_PURCHASE_SEND_ATTRIBUTION_DATA',
	/**
	 * This action is taken by redux beacon which sends the attribution data to google analytics.
	 * @param {Object} param0
	 * @param {import("./AttributionData").AttributionPayload} param0.attributionData
	 */
	sendPurchaseGaData: ({ attributionData, basketDetails }) => ({
		type: attributionActions.BASKET_PURCHASE_SEND_ATTRIBUTION_DATA,
		payload: { attributionData, basketDetails },
	}),
};

export default attributionActions;

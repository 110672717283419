import { createAPIModule } from 'utility/redux/apiModuleHelpers';
// import { createSelector } from 'reselect';

const getStateSlice = (state) => state.api.network.firewall.ruleset.details;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_FIREWALL_RULESET_DETAILS',
	method: 'POST',
	url: '/network/firewall/ruleset/details.json',
});

const selectors = {
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };

import actions from './actions';

const initialState = {
	countryCode: '',
	error: '',
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GDPR_SET_COUNTRY_CODE: {
			const { countryCode } = payload;
			return { error: '', countryCode };
		}
		case actions.GDRP_SET_ERROR: {
			const { error } = payload;
			return { error, countryCode: '' };
		}
		default:
			return state;
	}
};

export default reducer;

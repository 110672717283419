// TODO: put this into the snackbarSagas file as NOT the default export.

import { put } from 'redux-saga/effects';
import snackbarActions from 'modules/snackbar/snackbarActions';

function* snackbarSaga({
	error,
	errorMessage = 'Something went wrong',
	errorContentKey,
	errorContentProps,
	successMessage,
	successContentKey,
	successContentProps,
	variant = undefined,
}) {
	if (error) {
		yield put(
			snackbarActions.pushMessage({
				message: errorMessage,
				variant: variant || 'error',
				contentKey: errorContentKey,
				contentProps: errorContentProps,
			}),
		);
	} else if (successMessage || successContentKey) {
		yield put(
			snackbarActions.pushMessage({
				message: successMessage,
				variant: variant || 'success',
				contentKey: successContentKey,
				contentProps: successContentProps,
			}),
		);
	}
}

export default snackbarSaga;

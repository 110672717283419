import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import LWTypography from 'components/common/LWTypography';
import { DataBar } from 'components/molecules/DataBars';
import AcronisThanks from 'containers/pages/servers/BackupsAndImages/cloud/Settings/Acronis/AcronisThanks';

import formatBytes from 'utility/formatBytes';
import useAcronisUsages from 'modules/queries/acronis/backup/useUsages';

/** @param {import('modules/queries/asset/useDetails').AssetDetails} props */
const Acronis = ({
	acronisId,
	acronisStatus,
	status,
	'data-testid': dataTestId,
}) => {
	const { data: acronisUsageData, isLoading } = useAcronisUsages(
		{ uniq_id: acronisId },
		{
			enabled:
				Boolean(acronisId) && status === 'Active' && acronisStatus === 'Active',
		},
	);

	const usage = acronisUsageData?.usages.storage.value;
	const maxUsage = acronisUsageData?.usages.storage.quota.max_usage;

	const content = () => {
		if (isLoading)
			return (
				<Box textAlign="center" pb={2}>
					<CircularProgress />
				</Box>
			);

		if (
			acronisId &&
			acronisStatus === 'Active' &&
			maxUsage &&
			status === 'Active'
		) {
			return (
				<Box pb={2}>
					<LWTypography color="textSecondary" variant="caption">
						Backups
					</LWTypography>
					<DataBar
						max={maxUsage}
						value={usage}
						label={`${`${formatBytes(usage).withUnit} of ${
							formatBytes(maxUsage).withUnit
						} used`}`}
					/>
				</Box>
			);
		}
		if (acronisId && acronisStatus !== 'Active') {
			return <AcronisThanks />;
		}
		return null;
	};
	return <div data-testid={dataTestId}>{content()}</div>;
};

Acronis.propTypes = {
	acronisId: PropTypes.string,
	acronisStatus: PropTypes.string,
	status: PropTypes.string,
};

export default Acronis;

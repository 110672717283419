import actions from './actions';

const initState = {
	sort: 'asc',
	categories: [], // used to be "fitered"
	pageSize: 10,
	pageNum: 1,
	searchtext: '',
	projectName: null,
	// When adding a property, pay attention to SERVER_LIST_RESET_FILTERS
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case actions.SERVER_LIST_SET_SORT: {
			// pass in undefined to toggle.
			const { sort = state.sort === 'asc' ? 'desc' : 'asc' } = action;
			return { ...state, sort };
		}
		case actions.SERVER_LIST_SET_PAGE_SIZE: {
			const { pageSize } = action;
			return { ...state, pageSize };
		}
		case actions.SERVER_LIST_SET_PAGE_NUM: {
			const { pageNum } = action;
			return { ...state, pageNum };
		}
		case actions.SERVER_LIST_SET_CATEGORIES: {
			const { categories } = action;
			return { ...state, categories };
		}
		case actions.SERVER_LIST_SET_SEARCH_TEXT: {
			const { searchText } = action;
			return { ...state, searchText };
		}
		case actions.SERVER_LIST_SET_PROJECT_NAME: {
			const { projectName } = action;
			return { ...state, projectName };
		}
		case actions.SERVER_LIST_RESET_FILTERS: {
			return initState;
		}
		default:
			return state;
	}
};

export default reducer;

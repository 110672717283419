import React, { useCallback, useEffect, useRef } from 'react';
import { useSnackbar as useNotistackSnackbar } from 'notistack';
import SnackbarContent from './SnackbarContent';

export function useSnackbarEnqueue() {
	const { enqueueSnackbar } = useNotistackSnackbar();

	const enqueue = useCallback(
		(message, options) => {
			const {
				variant,
				actionLabel,
				onActionClick,
				showClose,
				contentKey,
				contentProps,
				onClose,
			} = options;

			const handleClose = (ev, reason) => {
				if (reason === 'clickaway') return false;

				if (onClose) onClose();
				return true;
			};

			return enqueueSnackbar(message, {
				onClose: handleClose,
				content: () => (
					<SnackbarContent
						variant={variant}
						actionLabel={actionLabel}
						onActionClick={onActionClick}
						showClose={showClose}
						contentKey={contentKey}
						contentProps={contentProps}
						onClose={handleClose}
					>
						{message}
					</SnackbarContent>
				),
			});
		},
		[enqueueSnackbar],
	);

	return enqueue;
}

export default function useSnackbar(notification, onClose) {
	const { closeSnackbar } = useNotistackSnackbar();
	const enqueueSnackbar = useSnackbarEnqueue();

	const notificationRef = useRef();
	notificationRef.current = notification;

	useEffect(() => {
		const handleClose = () => {
			if (onClose) {
				onClose(false);
			}
		};

		if (!notificationRef.current) {
			handleClose();
			return () => {};
		}

		const {
			message,
			variant,
			actionLabel,
			onActionClick,
			showClose,
			contentKey,
			contentProps,
		} = notificationRef.current;

		const id = enqueueSnackbar(message, {
			variant,
			actionLabel,
			onActionClick,
			showClose,
			contentKey,
			contentProps,
			onClose: handleClose,
		});

		return () => {
			// Close if it hasn't already
			closeSnackbar(id);
			handleClose();
		};
	}, [enqueueSnackbar, closeSnackbar, onClose]);
}

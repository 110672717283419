import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.product.flatDetails;

const { actions, reducer, sagas, selectors, additionalSagas } = createAPIModule(
	{
		getStateSlice,
		actionType: 'PRODUCT_FLATDETAILS',
		url: '/product/flatDetails.json',
	},
);

export {
	actions as flatDetailsActions,
	reducer as flatDetailsReducer,
	sagas as flatDetailsSagas,
	selectors as flatDetailsSelectors,
	additionalSagas as flatDetailsAdditionalSagas,
};

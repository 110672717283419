const chatActions = {
	CHAT_START: 'CHAT_START',
	startChat: () => ({
		type: chatActions.CHAT_START,
	}),
	CHAT_SET_IS_STARTING: 'CHAT_SET_IS_STARTING',
	setIsStarting: (isStarting) => ({
		type: chatActions.CHAT_SET_IS_STARTING,
		isStarting,
	}),
	CHAT_SET_WIDTH: 'CHAT_SET_WIDTH',
	setWidth: (width) => ({
		type: chatActions.CHAT_SET_WIDTH,
		width,
	}),
	CHAT_SET_ACTIVE: 'CHAT_SET_ACTIVE',
	setActive: (active) => ({
		type: chatActions.CHAT_SET_ACTIVE,
		active,
	}),
};

export default chatActions;

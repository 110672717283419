const formName = 'PaymentMethodSelect';

const paymentMethodSelect = (state) =>
	state.form[formName]?.values?.paymentMethod;

const selectors = {
	paymentMethodSelect,
};

export { formName };
export default selectors;

import React from 'react';

// redux
import { useSelector } from 'react-redux';
import { selectors as navSelectors } from 'modules/route/nav';

// custom components
import NavMenuButton from 'containers/connectedMolecules/NavMenuButton';

const navMenuButtonsTestIds = { container: 'navMenuButtonsContainer' };

/**
 * NavMenuButtons handles the data layer of the nav bar.
 *
 * Since the parent configs and the child configs are all bound together
 * in the same source (src/modules/route/nav/selectors/configSelectors.js),
 * we are passing in the configs bound together as well. This will allow us
 * to use NavMenuButton seperately.
 */
const NavMenuButtons = () => {
	const parentConfigs = useSelector(navSelectors.getParentConfigs);
	const childConfigs = useSelector(navSelectors.getChildConfigs);
	return (
		<div
			style={{ display: 'flex', gap: '24px' }}
			data-testid={navMenuButtonsTestIds.container}
		>
			{Object.entries(parentConfigs).map(
				([parentKey, { action, iconKey, label, notifications, to }]) => {
					const menuData = childConfigs[parentKey];
					return (
						<NavMenuButton
							key={parentKey}
							action={action}
							iconKey={iconKey}
							menuData={menuData}
							notifications={notifications}
							to={to}
						>
							{label}
						</NavMenuButton>
					);
				},
			)}
		</div>
	);
};

export { navMenuButtonsTestIds };
export default NavMenuButtons;

const packageConfigActions = {
	PACKAGE_CONFIG_ADD_TO_CART: 'PACKAGE_CONFIG_ADD_TO_CART',
	/**
	 * @param {object} param0
	 * @param {import("club-sauce/internal/market/package/index.raw").CycleT} param0.cycle
	 * @param {import("club-sauce/public/market/cart/item.raw").PackageItemParamI} param0.item
	 */
	addToCart: ({ cycle, item }) => ({
		type: packageConfigActions.PACKAGE_CONFIG_ADD_TO_CART,
		cycle,
		item,
	}),
	PACKAGE_CONFIG_QUICK_ADD_TO_CART: 'PACKAGE_CONFIG_QUICK_ADD_TO_CART',
	/**
	 * @param {object} param0
	 * @param {import("club-sauce/internal/market/package/index.raw").CycleT} param0.cycle
	 * @param {import("club-sauce/public/market/cart/item.raw").PackageItemParamI} param0.item
	 */
	quickAddToCart: ({ cycle, item, onFinally }) => ({
		type: packageConfigActions.PACKAGE_CONFIG_QUICK_ADD_TO_CART,
		cycle,
		item,
		onFinally,
	}),
	PACKAGE_CONFIG_ADMIN_QUICK_ADD_TO_CART:
		'PACKAGE_CONFIG_ADMIN_QUICK_ADD_TO_CART',
	adminQuickAddToCart: ({ cycle, item, onFinally }) => ({
		type: packageConfigActions.PACKAGE_CONFIG_ADMIN_QUICK_ADD_TO_CART,
		cycle,
		item,
		onFinally,
	}),
	PACKAGE_CONFIG_SAVE_CHANGES: 'PACKAGE_CONFIG_SAVE_CHANGES',
	/**
	 * @param {object} param0
	 * @param {string} param0.itemUuid
	 * @param {import("club-sauce/internal/market/package/index.raw").CycleT} param0.cycle
	 * @param {import("club-sauce/public/market/cart/item.raw").PackageItemParamI} param0.item
	 */
	saveChanges: ({ itemUuid, cycle, item }) => ({
		type: packageConfigActions.PACKAGE_CONFIG_SAVE_CHANGES,
		itemUuid,
		cycle,
		item,
	}),
	PACKAGE_CONFIG_ADD_PROPERTY: 'PACKAGE_CONFIG_ADD_PROPERTY',
	/**
	 * @param {object} param0
	 * @param {string} param0.key - the key under which to store the property.
	 * @param {string} param0.packageProductId
	 * @param {*} param0.value - the value to which to set the key.
	 */
	addProperty: ({ key, packageProductId, value }) => ({
		type: packageConfigActions.PACKAGE_CONFIG_ADD_PROPERTY,
		key,
		packageProductId,
		value,
	}),
	PACKAGE_CONFIG_DELETE_PROPERTY: 'PACKAGE_CONFIG_DELETE_PROPERTY',
	/**
	 * @param {object} param0
	 * @param {string} param0.key - the key to be deleted.
	 * @param {string} param0.packageProductId
	 */
	deleteProperty: ({ key, packageProductId }) => ({
		type: packageConfigActions.PACKAGE_CONFIG_DELETE_PROPERTY,
		key,
		packageProductId,
	}),
	PACKAGE_CONFIG_SET_PROPERTIES: 'PACKAGE_CONFIG_SET_PROPERTIES',
	/**
	 * @param {object} param0
	 * @param {string} param0.packageProductId
	 * @param {object} param0.properties
	 */
	setProperties: ({ packageProductId, properties }) => ({
		type: packageConfigActions.PACKAGE_CONFIG_SET_PROPERTIES,
		packageProductId,
		properties,
	}),
	PACKAGE_CONFIG_SET_BILLING_CYCLE: 'PACKAGE_CONFIG_SET_BILLING_CYCLE',
	/**
	 * @param {object} param0
	 * @param {string} param0.cycleId
	 */
	setBillingCycle: ({ cycleId }) => ({
		type: packageConfigActions.PACKAGE_CONFIG_SET_BILLING_CYCLE,
		cycleId,
	}),
	PACKAGE_CONFIG_SET_SELECTED_CONFIGS: 'PACKAGE_CONFIG_SET_SELECTED_CONFIGS',
	/**
	 * @param {object} param0
	 * @param {import("banana-stand/productConfig").SelectedConfigsT} param0.selectedConfigs
	 * @param {string} param0.packageProductId
	 */
	setSelectedConfigs: ({ selectedConfigs, packageProductId }) => ({
		type: packageConfigActions.PACKAGE_CONFIG_SET_SELECTED_CONFIGS,
		selectedConfigs,
		packageProductId,
	}),
	PACKAGE_CONFIG_SET_SELECTED_REGION: 'PACKAGE_CONFIG_SET_SELECTED_REGION',
	/**
	 * @param {object} param0
	 * @param {string} param0.selectedRegion
	 * @param {string} param0.packageProductId
	 */
	setSelectedRegion: ({ selectedRegion, packageProductId }) => ({
		type: packageConfigActions.PACKAGE_CONFIG_SET_SELECTED_REGION,
		selectedRegion,
		packageProductId,
	}),
	PACKAGE_CONFIG_SET_PTOK_TO_IS_REVEALED:
		'PACKAGE_CONFIG_SET_PTOK_TO_IS_REVEALED',
	/**
	 * @param {object} param0
	 * @param {string} param0.packageProductId
	 * @param {Record<string, boolean>} param0.ptokToIsRevealed
	 */
	setPtokToIsRevealed: ({ packageProductId, ptokToIsRevealed }) => ({
		type: packageConfigActions.PACKAGE_CONFIG_SET_PTOK_TO_IS_REVEALED,
		packageProductId,
		ptokToIsRevealed,
	}),
};

export default packageConfigActions;

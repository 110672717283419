import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { enabled as maintenanceModeEnabledSelector } from 'modules/maintenanceMode/selectors';
import LWLoader from 'components/common/LWLoader';

const MaintenancePage = lazy(() =>
	import('containers/pages/error/Maintenance'),
);

const MaintenanceModeGate = ({ maintenanceModeEnabled, children }) => {
	return maintenanceModeEnabled ? (
		<Suspense fallback={<LWLoader />}>
			<MaintenancePage />
		</Suspense>
	) : (
		children
	);
};

const mapStateToProps = (state) => ({
	maintenanceModeEnabled: maintenanceModeEnabledSelector(state),
});

export { MaintenanceModeGate };
export default connect(mapStateToProps)(MaintenanceModeGate);

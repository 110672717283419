import { createSelector } from 'reselect';

const getStateSlice = (state) => ({
	...state.dialogs,
});

const getIsOpen = createSelector(
	getStateSlice,
	(slice) => slice.open,
);

const getTitle = createSelector(
	getStateSlice,
	(slice) => slice.title,
);

const contentKey = createSelector(
	getStateSlice,
	(slice) => slice?.contentKey,
);

const getContentProps = createSelector(
	getStateSlice,
	(slice) => slice?.contentProps,
);

export { getStateSlice, contentKey };
export default {
	getStateSlice,
	getIsOpen,
	getTitle,
	contentKey,
	getContentProps,
};

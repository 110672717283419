import React from 'react';
import ErrorText from 'components/molecules/ErrorText';
import LWLink from 'components/common/LWLink';

const contentArray = [
	{
		key: 'about',
		label: 'Unauthorized',
		text: 'You are not authorized to view this content.',
	},
	{
		key: 'actions',
		label: 'What can I do?',
		text: 'Click the link below for assistance.',
	},
];

const testIds = { EnvironmentError__Container: 'EnvironmentError__Container' };

const EnvironmentError = () => (
	<ErrorText
		title="Error - 403"
		hideRefresh
		contentArray={contentArray}
		data-testid={testIds.EnvironmentError__Container}
	>
		<LWLink semiBold to="https://my.liquidweb.com">
			my.liquidweb.com
		</LWLink>
	</ErrorText>
);

export { testIds };
export default EnvironmentError;

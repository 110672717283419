import {
	createAPIModule,
	createSelectors,
} from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.api.project.availableAssets;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'PROJECT_AVAILABLEASSETS',
	url: '/project/availableAssets.json',
});

const moduleKeys = { SERVERS: 'servers' };

const serversSelectors = createSelectors(
	getStateSlice,
	false,
	moduleKeys.SERVERS,
);

const serverUniqIdMap = createSelector(
	serversSelectors.getNativeData,
	(serverList) =>
		serverList?.assets
			? Object.fromEntries(
					serverList?.assets.map((elem) => [elem.uniq_id, elem]),
			  )
			: {},
);

const selectors = {
	assets: createSelector(
		defaultSelectors.getStateSlice,
		(slice) => slice.getIn(['data', 'assets'], null),
	),
	serversSelectors,
	serverUniqIdMap,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors, moduleKeys };

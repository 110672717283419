const rootPaths = {
	shop: {
		name: 'Shop',
		path: '/shop',
	},
	cart: { name: 'Shopping Cart', path: '/cart' },
};

const pages = {
	cartText: {
		name: 'Cart Text',
		path: `${rootPaths.shop.path}/cart-text`,
	},
	shoppingCart: {
		name: rootPaths.cart.name,
		path: rootPaths.cart.path,
	},
};

const breadcrumbs = {
	shoppingCart: [
		{ ...rootPaths.shop, disabled: true },
		{ ...pages.shoppingCart, currentPage: true },
	],
	cartText: [
		{ ...rootPaths.shop, disabled: true },
		{ ...pages.shoppingCart },
		{ ...pages.cartText, currentPage: true },
	],
};

export { rootPaths, pages, breadcrumbs };

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import LWCard, { types as lwCardTypes } from 'components/common/LWCard';
import DataGridItem from 'components/common/DataGridItem';
import LWLink from 'components/common/LWLink';
import MESIcon from 'components/common/MESIcon';
import LWTypography from 'components/common/LWTypography';

/** @param {import('modules/queries/asset/useDetails').AssetDetails} props */
const Project = ({
	project_name: projectName,
	project_id: projectId,
	mesType,
	mesRole,
}) => {
	const MES_TYPES = {
		file_replication: 'File Replication',
		db_replication: 'Database Cluster',
	};

	const MES_ROLES = {
		host: 'Host',
		client: 'Client',
		primary: 'Primary',
		'read-only': 'Read-Only',
		lb: 'Load Balancer',
	};

	const mesText =
		mesType && mesRole ? `${MES_TYPES[mesType]} ${MES_ROLES[mesRole]}` : '';

	return (
		<LWCard title="Project Details" type={lwCardTypes.LIGHT}>
			<Grid container spacing={4}>
				<Grid item sm={12} md={4}>
					<DataGridItem title="Project">
						<LWLink to={`/projects/dashboard/${projectId}`}>
							{projectName}
						</LWLink>
					</DataGridItem>
				</Grid>
				{mesText && (
					<Grid container item sm={12} md={4} alignItems="center" spacing={1}>
						<Grid item>
							<MESIcon type={mesType} role={mesRole} />
						</Grid>
						<Grid item>
							<LWTypography>{mesText}</LWTypography>
						</Grid>
					</Grid>
				)}
			</Grid>
		</LWCard>
	);
};

Project.propTypes = {
	project_name: PropTypes.string.isRequired,
	project_id: PropTypes.number.isRequired,
	mesType: PropTypes.string,
	mesRole: PropTypes.string,
};

export default Project;

import moment from 'moment';
import { call, takeLatest, put, take, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import snackbarSagas from 'modules/snackbar/sagas';
import { POWER_ACTION, POWER_STATUS } from 'utility/powerInfo';
import { actions as usertaskListActions } from 'modules/api/usertask/listModule';
import { removeUndefinedKeys } from 'utility/tools/objects';
import targetPowerActions from 'modules/server/targetPower/actions';
import taskSchedulerActions from './actions';
import taskSchedulerSelectors from './selectors';
import supportedTasks from './supportedTasks';

function* scheduleTask({ payload: { dateTime, params, task } }) {
	let deferSeconds;
	if (dateTime) {
		const now = moment();
		const scheduledTime = moment(dateTime);

		deferSeconds = Math.floor(
			moment.duration(scheduledTime.diff(now)).asSeconds(),
		);
	}

	const { action, selectors } = supportedTasks[task];

	const force = select(taskSchedulerSelectors.getForceReboot);

	yield put(
		action.fetchRaw({
			data: { params, ...(task === 'reboot' ? { force } : {}) },
			headers: removeUndefinedKeys({
				// removes this if undefined.
				'X-Meta-Timing-Defer': deferSeconds || undefined,
			}),
		}),
	);

	const result = yield take([action.setType, action.errorType]);

	const error = result?.type === action.errorType;
	yield call(snackbarSagas, {
		error,
		errorMessage: yield select(selectors.getErrorString),
	});
	if (error) return;

	if (task === 'reboot') {
		yield put(
			targetPowerActions.setServerTargetPower({
				uniqId: params.uniq_id,
				action: POWER_ACTION.REBOOT,
				status: POWER_STATUS.RUNNING,
			}),
		);
	}

	yield put(
		push(
			deferSeconds
				? '/servers/scheduled-tasks'
				: supportedTasks[task].redirectPath(params),
		),
	);

	yield put(taskSchedulerActions.closeDialog());
}

function* getCurrentTasks({ uniqId }) {
	yield put(
		usertaskListActions.fetch({
			uniq_id: uniqId,
			alsowith: ['action'],
			status: 'pending',
		}),
	);
}

export { scheduleTask, getCurrentTasks };

export default function* taskSchedulerSagas() {
	yield takeLatest(taskSchedulerActions.SCHEDULE, scheduleTask);
	yield takeLatest(taskSchedulerActions.GET_CURRENT_TASKS, getCurrentTasks);
}

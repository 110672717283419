import * as React from 'react';
import makeGraphData from 'modules/queries/asset/measurements/makeGraphData';
import Graph from 'components/molecules/Graph';
import useAssetMeasurementsLookup from 'modules/queries/asset/measurements/useLookup';

const Disk = ({ uniqId }) => {
	const { data, isLoading, isError } = useAssetMeasurementsLookup({
		uniq_id: uniqId,
		name: 'cloud_disk',
		fields: ['read', 'write'],
		duration: 'day',
	});

	const graphData = makeGraphData({
		data: data?.data,
		keys: ['Read (KBps)', 'Write (KBps)'],
	});

	return (
		<Graph
			data={graphData}
			title="Disk"
			subtitle="Kilobytes per Second - Last 24 Hours"
			isLoading={isLoading}
			errorText={isError ? 'Unable to load disk usage data' : ''}
		/>
	);
};

export default Disk;

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	actions as availableAssetsActions,
	selectors as availableAssetsSelectors,
} from 'modules/api/project/availableAssetsModule';
import {
	actions as categoriesActions,
	selectors as categoriesSelectors,
} from 'modules/api/project/categoriesModule';
import {
	actions as priceActions,
	selectors as priceSelectors,
} from 'modules/api/product/priceModule';
import { selectors } from 'modules/projectAddDialog/selectors';
import actions from 'modules/projectAddDialog/actions';
import ProjectAddDialog from './ProjectAddDialog';

const mapStateToProps = (state) => ({
	availableAssetsData: {
		hasData: availableAssetsSelectors.hasData(state),
		assets: availableAssetsSelectors.assets(state),
		isLoading: availableAssetsSelectors.isLoading(state),
	},
	categoriesData: {
		categories: categoriesSelectors.categories(state),
		isLoading: categoriesSelectors.isLoading(state),
	},
	priceDataObj: {
		data: priceSelectors.getData(state),
	},
	isOpen: selectors.isOpen(state),
	dialogProps: selectors.dialogProps(state),
});

const mapDispatchToProps = (dispatch) => ({
	fetchCategories: () => dispatch(categoriesActions.fetch()),
	fetchPriceData: (region) =>
		dispatch(
			priceActions.fetch({
				code: 'DatabaseClustering.Service',
				features: {
					MES: 'DBClusteringService',
				},
				region,
			}),
		),
	toggleDialog: (value) => dispatch(actions.toggleDialog(value)),
	setProjectAddDialogProps: (props) => dispatch(actions.setProps(props)),
	fetchAvailableAssets: ({ category, projectId }) =>
		dispatch(availableAssetsActions.fetch({ category, id: projectId })),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(ProjectAddDialog));

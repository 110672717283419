import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import round from 'lodash/round';
import { useDispatch } from 'react-redux';
import Chip from '@material-ui/core/Chip';
import cartActions from 'modules/cart/actions';

export const maybeRound = (number) => {
	const num = Number(number);

	if (num % 1 !== 0) {
		return round(num, 2);
	}

	return num;
};

export const createDiscountLabel = (discount) => {
	const { repetitions, type, amount, owner } = discount;

	let label = `${maybeRound(amount)}% off`;

	if (repetitions) {
		label += ` ${repetitions} months`;
	}

	if (type === 'coupon') {
		label += ` ${owner}`;
	}

	return label;
};

const FlexBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing(0.5)}px;
	align-items: end;
`;

const SChip = styled(Chip)`
	background-color: ${({ theme }) => theme.palette.tertiary.light};
	width: fit-content;
`;

const Discount = ({ discount }) => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);

	const handleDelete = () => {
		setIsLoading(true);
		dispatch(
			cartActions.removeDiscount({
				uuid: discount.discount_uuid,
				onFinally: () => setIsLoading(false),
			}),
		);
	};

	return (
		<SChip
			label={createDiscountLabel(discount)}
			onDelete={discount.type === 'admin' ? handleDelete : null}
			disabled={isLoading}
			size="small"
		/>
	);
};

const CartItemDiscounts = ({ discounts }) => {
	if (!discounts?.length) {
		return null;
	}

	return (
		<FlexBox>
			{discounts
				.filter((discount) => Boolean(discount.amount_off_today))
				.map((discount) => (
					<Discount key={discount.discount_uuid} discount={discount} />
				))}
		</FlexBox>
	);
};

CartItemDiscounts.propTypes = {
	discounts: PropTypes.array,
};

export default CartItemDiscounts;

// TODO: make this a part of the updateList saga?
import { takeLatest, call } from 'redux-saga/effects';
import { updateList } from 'modules/contact/sagas';
import actions from './actions';

function* updateTaxInfo({ values }) {
	const { taxExempt, taxExemptId, taxId, taxExemptReason } = values;
	yield call(updateList, {
		payload: {
			tax_exempt: taxExempt || 0,
			tax_exempt_id: taxExemptId || undefined,
			tax_id: taxId || undefined,
			tax_exempt_reason: taxExemptReason || undefined,
		},
	});
}

export default function* saga() {
	yield takeLatest(actions.TAX_INFO_UPDATE, updateTaxInfo);
}

import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.market.cart.discount.add;

const { actions, reducer, sagas, selectors, additionalSagas } = createAPIModule(
	{
		getStateSlice,
		actionType: 'MARKET_CART_DISCOUNT_ADD',
		url: '/market/cart/discount/add.json',
	},
);

export {
	actions as cartDiscountAddActions,
	reducer as cartDiscountAddReducer,
	sagas as cartDiscountAddSagas,
	selectors as cartDiscountAddSelectors,
	additionalSagas as cartDiscountAddAdditionalSagas,
};

import React from 'react';

// MUI components
import Grid from '@material-ui/core/Grid';

import PaperBox from 'components/molecules/PaperBox';
import LWTypography from 'components/common/LWTypography';
import CircleIt from 'components/atoms/CircleIt';
import Check from '@material-ui/icons/Done';

const AcronisThanks = () => {
	return (
		<PaperBox>
			<Grid container spacing={2} direction="column" alignItems="center">
				<Grid item>
					<CircleIt
						bgcolor="success.main"
						BoxProps={{ color: 'common.white1' }}
						size={20}
					>
						<Check fontSize="small" />
					</CircleIt>
				</Grid>
				<Grid item>
					<LWTypography gutterBottom>
						Thank you for purchasing Acronis Cyber Backups!
					</LWTypography>
				</Grid>
				<Grid item>
					<LWTypography>
						An email will be sent shortly with further details.
					</LWTypography>
				</Grid>
			</Grid>
		</PaperBox>
	);
};

export default AcronisThanks;

import { useQuery } from '@tanstack/react-query';
import api from 'modules/queries/api';

/**
 * @param {import('club-sauce/public/market/package/index.raw').LWApiPublicMarketPackageDetailsParamsRawI} params
 */
const usePackageDetails = (params, { enabled, staleTime } = {}) =>
	useQuery({
		queryKey: ['/market/package/details', params],
		queryFn: () => api.market.package.apiRaw.details(params),
		enabled,
		staleTime,
	});

export default usePackageDetails;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { OidcProvider, loadUser } from 'redux-oidc';
import { selectors as appConfigSelectors } from 'modules/appConfig';

import { store } from 'store';

const OpenIDProviderWrapper = ({ children, userManager }) => {
	useEffect(() => {
		loadUser(store, userManager);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<OidcProvider userManager={userManager} store={store}>
			{children}
		</OidcProvider>
	);
};

OpenIDProviderWrapper.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	userManager: PropTypes.object,
};

const mapStateToProps = (state) => ({
	userManager: appConfigSelectors.getUserManager(state),
});

export { OpenIDProviderWrapper };
export default connect(mapStateToProps)(OpenIDProviderWrapper);

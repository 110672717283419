import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.product.primaryCategoryMapping;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'PRODUCT_PRIMARYCATEGORYMAPPING',
	url: '/product/primaryCategoryMapping.json',
});

const selectors = {
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };

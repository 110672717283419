import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.support.request.authenticate;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'SUPPORT_REQUEST_AUTHENTICATE',
	method: 'POST',
	url: '/support/request/authenticate.json',
});

export { actions, reducer, sagas, selectors };

import moment from 'moment';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(isSameOrAfter);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Convert unix timestamp to human readable format
 *
 * @param {number} s unix timestamp in seconds
 * @return {string} in HH:mm:ss A
 */
function time(s) {
	return moment.unix(s).format('HH:mm:ss A');
}

/**
 * @param {number} s datetime
 * @return {string} in MM-DD-YY hh:MM a
 */
function dateTime(s) {
	return moment(s).format('MM-DD-YY hh:MM a');
}

/**
 * @param {number} s datetime
 * @return {string} in MMMM Do, YYYY - hh:mm A
 */
function dateTimeLong(s) {
	return moment(s).format('MMMM Do, YYYY - hh:mm A');
}

export { dateTime, dateTimeLong, time };

import { spawn } from 'redux-saga/effects';
import { cartDiscountAddSagas } from './addModule';
import { cartDiscountBulkSagas } from './bulkModule';
import { cartDiscountModifySagas } from './modifyModule';
import { cartDiscountRemoveSagas } from './removeModule';

export default function* sagas() {
	yield spawn(cartDiscountAddSagas);
	yield spawn(cartDiscountBulkSagas);
	yield spawn(cartDiscountModifySagas);
	yield spawn(cartDiscountRemoveSagas);
}

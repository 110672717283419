import React from 'react';
import { useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import Box from '@material-ui/core/Box';
import PackageConfigProvider from 'banana-stand/packageConfig/PackageConfigProvider';
import LabeledText from 'components/atoms/LabeledText';
import useConfigPackage from 'containers/pages/shop/ConfigPackage/useConfigPackage';
import packageConfigSelectors from 'modules/basket/packageConfig/selectors';
import ConfigPackageProducts from './ConfigPackageProducts';

export const packagePreviewContentKey = 'PackagePreview';

const PaddingWrapper = ({ children }) => (
	<Box py={2} px={4}>
		{children}
	</Box>
);

/**
 * @param {import('club-sauce/public/market/package/index.raw').LWApiPublicMarketPackageDetailsResultRawI} props
 * */
const PackagePreviewContents = ({ code, version }) => {
	const {
		packageDetailsData,
		packageProductsData,
		zonesData,
		configsData,
		templatesData,
		isLoading,
	} = useConfigPackage({ packageCode: code });

	const {
		allSelectedConfigs,
		allSelectedRegions,
		allProductProperties,
		selectedBillingCycleId,
	} = useSelector(packageConfigSelectors.getStateSlice);

	const { description, features } = version;

	if (isLoading) {
		return (
			<PaddingWrapper>
				<Skeleton width="95%" />
				<Skeleton width="75%" />
				<Skeleton width="85%" />
			</PaddingWrapper>
		);
	}

	return (
		<PaddingWrapper>
			<PackageConfigProvider
				allProductProperties={allProductProperties}
				allSelectedConfigs={allSelectedConfigs}
				allSelectedDataCenters={allSelectedRegions}
				packageDetails={packageDetailsData}
				packageProducts={packageProductsData}
				selectedBillingCycleId={selectedBillingCycleId}
			>
				<ConfigPackageProducts
					code={code}
					packageProducts={packageProductsData}
					zones={zonesData?.items}
					configIds={configsData?.items}
					templates={templatesData?.items}
				/>
				<LabeledText label="Description" text={description?.split('\n')} />
				<LabeledText label="Features" text={features} />
			</PackageConfigProvider>
		</PaddingWrapper>
	);
};

export default PackagePreviewContents;

import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.api.network.zone.setDefault;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_ZONE_SETDEFAULT',
	method: 'POST',
	url: '/network/zone/setDefault.json',
});

const selectors = {
	defaultZone: createSelector(
		defaultSelectors.getStateSlice,
		(slice) => slice.getIn(['data', 'default_zone']),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };

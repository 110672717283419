import React, { useEffect, useCallback, useMemo } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import authActions from 'modules/auth/authActions';
import {
	roles as userRolesSelector,
	isSuspended as isSuspendedSelector,
	hasAuthToken,
	isSetupUser as isSetupUserSelector,
	isBasketAdmin as isBasketAdminSelector,
} from 'modules/auth/authSelectors';
import { selectors as routeSelectors } from 'modules/route';

import { actions as basketCheckoutActions } from 'modules/basket/checkout';

import { masterRoles } from 'utility/constants/roles';
import UnauthorizedPage from 'components/structural/UnauthorizedPage';

const RoleRestrictedRoute = ({
	component: Component,
	whitelistedRoles,
	isBasketAdmin,
	isSuspended,
	isSetupUser,
	isLoggedIn,
	userRoles,
	fetchUserDetails,
	allowUnauth,
	queryParams,
	takeToNextStep,
	path,
	name,
	...rest
}) => {
	useEffect(() => {
		if (!userRoles) {
			fetchUserDetails(); // TODO: why is this here? Isn't this is already called in handleLogin (take(USER_FOUND)).
		}
	}, [fetchUserDetails, userRoles]);
	const whiteListedRoleKeys = whitelistedRoles.map((role) => role.key);
	const masterRoleKeys = masterRoles.map((role) => role.key);

	const hasProperRoles = useMemo(() => {
		return (
			userRoles &&
			(userRoles.some((role) => masterRoleKeys.includes(role)) ||
				userRoles.some((role) => whiteListedRoleKeys.includes(role)))
		);
	}, [masterRoleKeys, userRoles, whiteListedRoleKeys]);

	const getRenderComponent = useCallback(
		(props) => {
			if (isBasketAdmin || (allowUnauth && !isLoggedIn) || hasProperRoles)
				return <Component name={name} path={path} {...props} {...rest} />;
			if (isLoggedIn && isSetupUser) {
				takeToNextStep();
			}

			return <UnauthorizedPage isSuspended={isSuspended} />;
		},
		[
			isBasketAdmin,
			allowUnauth,
			isLoggedIn,
			hasProperRoles,
			rest,
			isSetupUser,
			isSuspended,
			takeToNextStep,
			name,
			path,
		],
	);

	return <Route path={path} render={getRenderComponent} />;
};

const mapStateToProps = (state) => ({
	userRoles: userRolesSelector(state),
	isBasketAdmin: isBasketAdminSelector(state),
	isSetupUser: isSetupUserSelector(state),
	isSuspended: isSuspendedSelector(state),
	isLoggedIn: hasAuthToken(state),
	queryParams: routeSelectors.getQueryParams(state),
	allowUnauth: routeSelectors.getAllowUnauth(state),
});

const mapDispatchToProps = (dispatch) => ({
	fetchUserDetails: (timeout) => dispatch(authActions.userDetailsInit(timeout)),
	takeToNextStep: () => {
		dispatch(basketCheckoutActions.takeToNextStep());
	},
});

RoleRestrictedRoute.propTypes = {
	component: PropTypes.elementType,
	path: PropTypes.string,
	name: PropTypes.string,
	whitelistedRoles: PropTypes.array,
	isSuspended: PropTypes.bool,
	isSetupUser: PropTypes.bool,
	isLoggedIn: PropTypes.bool.isRequired,
	userRoles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	fetchUserDetails: PropTypes.func,
	allowUnauth: PropTypes.bool,
};

RoleRestrictedRoute.defaultProps = {
	whitelistedRoles: [],
	allowUnauth: false,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(RoleRestrictedRoute);

const actions = {
	GATOR_SEND_REPORT: 'GATOR_SEND_REPORT',
	/**
	 * Send a report to gator
	 * @example
	 * sendReport({
	 *   name: 'Warning'
	 *   message: 'App start',
	 *   additionalFields: {
	 *     component: 'Payment',
	 *   }
	 * });
	 * @param {Object} report Gator report
	 * @returns action
	 */
	sendReport: (report) => ({
		type: actions.GATOR_SEND_REPORT,
		report,
	}),
	GATOR_LOG_MESSAGE: 'GATOR_LOG_MESSAGE',
	/**
	 * Send a message to gator
	 * @param {string} message
	 * @returns action
	 */
	logMessage: (message) => ({
		type: actions.GATOR_LOG_MESSAGE,
		message,
	}),
	GATOR_LOG_ERROR: 'GATOR_LOG_ERROR',
	/**
	 * Send an Error to gator
	 * @param {Error} error
	 * @returns action
	 */
	logError: (error) => ({
		type: actions.GATOR_LOG_ERROR,
		error,
	}),
};

export default actions;

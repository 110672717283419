import React from 'react';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';
import { useForm } from '../LWForm';

/**
 * Dialog actions to be used within context of LWForm
 * @param {import('components/common/Dialogs/ConfirmCancel/ConfirmCancel').ConfirmCancelI} props
 */
const HookedConfirmCancel = ({ disabled, confirmType, ...rest }) => {
	const { isValid } = useForm();

	return (
		<ConfirmCancel
			confirmType="submit"
			disabled={!isValid || disabled}
			{...rest}
		/>
	);
};

export default HookedConfirmCancel;

import { eventChannel, END } from 'redux-saga';
import { takeEvery, call, select, put } from 'redux-saga/effects';
import gatorClient, { tracekit } from 'gator-client';
import { selectors as appConfigSelectors } from 'modules/appConfig';
import { actions as snackbarActions } from 'modules/snackbar';
import { username as usernameSelector } from 'modules/auth/authSelectors';
import { selectors as accountDetailsSelectors } from 'modules/api/account/detailsModule';
import getAppVersion from 'utility/getAppVersion';
import gatorActions from './actions';

/**
 * Handle `sendReport` action
 *
 * This appends user fields and sends all gator reports
 * @param {GatorClient} client
 */
function* sendReport(client, action) {
	const { report } = action;
	const username = yield select(usernameSelector);
	const accnt = yield select(accountDetailsSelectors.getAccnt);
	const version = getAppVersion({ prefix: true });

	const additionalFields = {
		username,
		accnt,
		version,
		...report.additionalFields,
	};

	yield call([client, 'send'], {
		...report,
		additionalPrefix: 'mylw_',
		additionalFields,
	});
}

/**
 * Handle `logMessage` action
 */
function* logMessage(action) {
	const { message } = action;

	yield put(gatorActions.sendReport({ message }));
}

/**
 * Handle `logError` action
 */
function* logError(action) {
	const { error } = action;

	// Process errors through tracekit
	try {
		yield call([tracekit, 'report'], error);
	} catch (e) {
		// Prevent rethrow, it is not relevant to our browser versions
		// See: https://github.com/csnover/TraceKit/blob/master/tracekit.js#L107
	}
}

/**
 * Handle snackbar error messages
 */
function* handleSnackbarMessage(action) {
	const { variant, message } = action;

	// Just report errors variants
	if (variant !== 'error') return;

	yield put(gatorActions.logMessage(message));
}

export default function* gatorSaga() {
	const gatorHost = yield select(appConfigSelectors.getGatorHostname);
	const client = yield call(gatorClient, gatorHost, 'my.liquidweb');

	const tracekitChannel = eventChannel((emitter) => {
		function handleTraceKit(report) {
			try {
				emitter(gatorActions.sendReport(report));
			} catch (error) {
				// Prevent circular stack calls
			}
		}

		tracekit.report.subscribe(handleTraceKit);

		return () => {
			tracekit.report.unsubscribe(handleTraceKit);
			emitter(END);
		};
	});

	yield takeEvery(tracekitChannel, sendReport, client);

	yield takeEvery(gatorActions.GATOR_SEND_REPORT, sendReport, client);
	yield takeEvery(gatorActions.GATOR_LOG_MESSAGE, logMessage);
	yield takeEvery(gatorActions.GATOR_LOG_ERROR, logError);
	yield takeEvery(snackbarActions.SNACKBAR_PUSH_MESSAGE, handleSnackbarMessage);
}

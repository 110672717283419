import actions from './authActions';

/**
 * @param {boolean} isRedirecting keep track of redirecting status to prevent token loop
 * @param {boolean} isWaitingForToken set to false once USER_EXPIRED or USER_FOUND actions fire
 * @param {boolean} isUserExpired set to true if USER_EXPIRED fires
 */
const initialState = {
	isRedirecting: false,
	isWaitingForToken: true,
	isUserExpired: false,
};

const reducer = (state = initialState, action) => {
	const { isRedirecting, isUserExpired, isWaitingForToken } = action;
	switch (action.type) {
		case actions.SET_USER_REDIRECTING:
			return { ...state, isRedirecting };
		case actions.SET_USER_EXPIRED:
			return { ...state, isUserExpired };
		case actions.SET_WAITING_FOR_TOKEN:
			return { ...state, isWaitingForToken };
		case actions.ACCOUNT_AUTH_CLEAR:
			return initialState;
		default:
			return state;
	}
};

export default reducer;

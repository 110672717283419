import React from 'react';
import styled from 'styled-components';
import Visiblity from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import Popper from 'components/atoms/Popper';

const testids = { VisibleToAdmins__Main: 'VisibleToAdmins__Main' };
const SIcon = styled(Visiblity)`
	fill: ${(p) => p.theme.palette.primary.light};
`;

const VisibleToAdmins = () => (
	<Tooltip
		title="Visible to admins"
		data-testid={testids.VisibleToAdmins__Main}
		PopperComponent={Popper}
	>
		<SIcon />
	</Tooltip>
);

export default VisibleToAdmins;

import { createSelector } from 'reselect';

const getStateSlice = (state) => state.marketplace;

const getSelectedProduct = createSelector(
	getStateSlice,
	(slice) => slice?.productCode,
);

// This get the whole object including tags that were selected on other tabs that are not active.
const getSelectedTags = createSelector(
	getStateSlice,
	(slice) => slice?.selectedTags,
);

export default {
	getStateSlice,
	getSelectedProduct,
	getSelectedTags,
};

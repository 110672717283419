import actions from './actions';

/**
 * @typedef UsertaskState
 * @property {Set} tasksBeingCancelled set of unique strings
 */
const initialState = {
	tasksBeingCancelled: new Set(),
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) => {
	const tasksBeingCancelledCopy = new Set(state.tasksBeingCancelled);
	switch (action.type) {
		case actions.USERTASK_CANCEL_TASK:
			tasksBeingCancelledCopy.add(action.payload.code);
			return {
				...state,
				tasksBeingCancelled: tasksBeingCancelledCopy,
			};

		case actions.USERTASK_SCHEDULEDTASK_CANCEL_TASK:
			tasksBeingCancelledCopy.add(action.payload.code);
			return {
				...state,
				tasksBeingCancelled: tasksBeingCancelledCopy,
			};

		case actions.USERTASK_CANCEL_REQUEST_FINISHED:
			tasksBeingCancelledCopy.delete(action.payload.code);
			return {
				...state,
				tasksBeingCancelled: tasksBeingCancelledCopy,
			};
		default:
			return state;
	}
};

export default reducer;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import LWTypography from 'components/common/LWTypography';
import useSupportRequestCreate from 'modules/queries/support/request/useCreate';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';
import LWForm from 'components/LWForm';
import HookedTextField from 'components/LWForm/HookedTextField';
import HookedConfirmCancel from 'components/LWForm/HookedConfirmCancel';

const UnblockIPSupportForm = ({
	onClose,
	showTextField,
	setShowTextField,
	errorMsg,
	serverDetails,
}) => {
	const [formValues, setFormValues] = useState({
		description: `I received this error "${errorMsg}" when trying to unblock IP "${serverDetails.ip}" from server "${serverDetails.server.domain} - ${serverDetails.server.ip}". I would like the IP unblocked from the server as soon as possible.`,
	});
	const [formErorrs, setFormErrors] = useState({});
	const { mutate: createSupportTicket, isLoading } = useSupportRequestCreate({
		onSuccess: onClose,
	});

	const handleSubmit = (values) => {
		createSupportTicket({
			queue: 'Linux_Support_Simple_New',
			subject: `my.liquidweb.com unblock IP from`,
			description: values.description,
		});
	};

	return showTextField ? (
		<LWForm
			values={formValues}
			onValuesChange={setFormValues}
			errors={formErorrs}
			onErrorsChange={setFormErrors}
			onSubmit={handleSubmit}
		>
			<HookedTextField
				name="description"
				label="Comments"
				multiline
				fullWidth
				required
			/>
			<HookedConfirmCancel
				isLoading={isLoading}
				cancel={onClose}
				confirmText="Submit Request"
			/>
		</LWForm>
	) : (
		<>
			<Grid container spacing={1}>
				<LWTypography>
					{`We're sorry, but there was a problem unblocking that IP.  Would you like to open a support request?`}
				</LWTypography>
			</Grid>
			<ConfirmCancel
				confirm={() => setShowTextField(true)}
				confirmText="Yes"
				cancel={onClose}
				cancelText="No"
			/>
		</>
	);
};

UnblockIPSupportForm.propTypes = {
	onClose: PropTypes.func.isRequired,
};

export default UnblockIPSupportForm;

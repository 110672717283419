import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { hasAuthToken, email as getEmail } from 'modules/auth/authSelectors';
import Cookies from 'js-cookie';
import hitEndpoint, { loadScriptFromUrl } from 'utility/redux/saga/hitEndpoint';
import { selectors as appConfigSelectors } from 'modules/appConfig';
import { waitForGaReady } from 'modules/attribution/sagas';
import {
	actions as contactListActions,
	selectors as contactListSelectors,
} from 'modules/api/contact/listModule';
import { actions as createAccountActions } from 'modules/account/create';
import cartActions from 'modules/cart/actions';
import { cartDetailsSelectors } from 'modules/api/market/cart/detailsModule';
import { cartItemAddActions } from 'modules/api/market/cart/item/addModule';
import { cartItemRemoveActions } from 'modules/api/market/cart/item/removeModule';
import actions from './actions';
import selectors from './selectors';

// get the cookie, and store the country code in redux.
function* getLwPiiPreferencesCookie() {
	const cartHost = yield select(appConfigSelectors.getCartHostname);
	try {
		yield call(hitEndpoint, `${cartHost}/ajax/dataconsentcookies`);
		return JSON.parse(Cookies.get('lwPiiPreferences'))?.detected_country;
	} catch (e) {
		yield put(actions.setError({ error: e.message || e }));
		return '';
	}
}

function* sendCartDataIfAllowed() {
	if (!(yield select(selectors.sendGtmData))) {
		return; // no opp if we're not allowed or don't want to track this user.
	}

	const trackingScriptUrl = yield select(
		appConfigSelectors.getTrackingScriptUrl,
	);

	const cartStackLoaded = yield call(
		loadScriptFromUrl,
		trackingScriptUrl,
		'cartstack_updatecart',
	);

	if (!cartStackLoaded) {
		// eslint-disable-next-line no-console
		console.error('cartstack script failed to initialize');
		return;
	}
	const cartHasItems = yield select(cartDetailsSelectors.getHasItems);

	if (!cartHasItems) {
		return; // Don't report empty carts to CartStack
	}

	const cartData = yield select(selectors.cartData);
	if (window.cartstack_updatecart)
		yield call(window.cartstack_updatecart, cartData);
}

function* setUserCountry({
	payload: { address: { country: countryArg } = {} } = {},
} = {}) {
	let countryCode = countryArg;
	if (!countryCode) {
		const isAuthed = yield select(hasAuthToken);
		if (isAuthed) {
			yield put(contactListActions.init());
			// since this is called on app load, we will be here from the beginning, making this safe to count on.
			yield take(contactListActions.setType);
		}
		countryCode = (yield select(
			contactListSelectors.getDefaultContactWithDefaultValues,
		))?.toJS().country;
		if (!countryCode) countryCode = yield call(getLwPiiPreferencesCookie);
	}
	if (countryCode) {
		// This will also dispatch the GTM event logging data suppression.
		yield put(actions.setCountryCode({ countryCode }));

		// The whole point of this is to have their email. If we don't, then do not send the supression or success data.
		const hasEmail = !!(yield select(getEmail));

		// We don't care about baskets with no items. Do not report these.
		const cartHasItems = yield select(cartDetailsSelectors.getHasItems);

		if (!hasEmail || !cartHasItems) return;
		const sendGtmData = yield select(selectors.sendGtmData); // This selector will now take the country code into account.
		yield call(waitForGaReady);
		yield put(actions.sendAnalytics({ countryCode, sendGtmData }));

		// If there has been a change in country code allowing us to send data, we want to tell cartstack about it.
		yield call(sendCartDataIfAllowed);
	}
}

export default function*() {
	yield takeLatest(
		[
			cartActions.CART_INITIALIZED,
			createAccountActions.ACCOUNT_CREATE_SIGNUP_PUT,
		],
		setUserCountry,
	);
	yield takeLatest(
		[cartItemAddActions.setType, cartItemRemoveActions.setType],
		sendCartDataIfAllowed,
	);
}

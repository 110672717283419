// @ts-check
import { useQuery } from '@tanstack/react-query';
import api from 'modules/queries/api';

/**
 * @param {import('club-sauce/public/asset/measurements/index.raw').LWApiPublicAssetMeasurementsLookupParamsRawI} params
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 */
const useAssetMeasurementsLookup = (params, { enabled } = {}) =>
	useQuery({
		queryKey: ['/asset/measurements/lookup', params],
		queryFn: () => api.asset.measurements.apiRaw.lookup(params),
		enabled,
	});

export default useAssetMeasurementsLookup;

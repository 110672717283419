import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

const CircleIt = ({
	bgcolor = 'grey.light',
	size = 32,
	BoxProps,
	children,
}) => (
	<Box
		bgcolor={bgcolor}
		borderRadius="100%" // It will be a circle.
		height={size}
		width={size}
		display="flex"
		alignItems="center"
		justifyContent="center"
		{...BoxProps}
	>
		{children}
	</Box>
);

CircleIt.propTypes = {
	bgcolor: PropTypes.string,
	size: PropTypes.number,
	BoxProps: PropTypes.object,
};

export default CircleIt;

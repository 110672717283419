import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.account.user.update;

const { actions, reducer, sagas, additionalSagas, selectors } = createAPIModule(
	{
		isPaginated: true,
		getStateSlice,
		actionType: 'ACCOUNT_USER_UPDATE',
		url: '/account/user/update.json',
	},
);

export { actions, reducer, sagas, additionalSagas, selectors };

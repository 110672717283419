import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.storm.ip.pool.list;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'STORM_IP_POOL_LIST',
	method: 'POST',
	url: '/storm/ip/pool/list.json',
});

const selectors = {
	...defaultSelectors,
};

export {
	actions as poolListActions,
	reducer as poolListReducer,
	sagas as poolListSagas,
	selectors as poolListSelectors,
};

import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.market.cart.metadata;

const { actions, reducer, sagas, selectors, additionalSagas } = createAPIModule(
	{
		getStateSlice,
		actionType: 'MARKET_CART_METADATA',
		url: '/market/cart/metadata.json',
	},
);

export {
	actions as cartMetadataActions,
	reducer as cartMetadataReducer,
	sagas as cartMetadataSagas,
	selectors as cartMetadataSelectors,
	additionalSagas as cartMetadataAdditionalSagas,
};

const maintenanceModeActions = {
	ON: 'MAINTENANCE_MODE_ON',
	on: (payload) => ({
		type: maintenanceModeActions.ON,
		payload,
	}),
	OFF: 'MAINTENANCE_MODE_OFF',
	off: (payload) => ({
		type: maintenanceModeActions.OFF,
		payload,
	}),
};

export default maintenanceModeActions;

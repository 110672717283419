import acronisActions from './actions';

const initialState = {
	isDialogOpen: false,
	error: null,
	selected: {
		selectedObj: {},
		selectedProductOptionObj: null,
		originalSelectedObj: null,
	},
	pageNum: 1,
	pageSize: 10,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case acronisActions.SET_ERROR:
			return { ...state, error: action.error };
		case acronisActions.TOGGLE_DIALOG:
			return { ...state, isDialogOpen: action.isDialogOpen };
		case acronisActions.SET_SELECTED:
			return { ...state, selected: action.selected };
		case acronisActions.ACRONIS_SET_PAGE_NUM:
			return { ...state, pageNum: action.pageNum };
		case acronisActions.ACRONIS_SET_PAGE_SIZE:
			return { ...state, pageSize: action.pageSize };
		case acronisActions.SET_SELECTED_PRODUCT_OPTION_OBJ:
			return {
				...state,
				selected: {
					...state.selected,
					selectedProductOptionObj: action.selectedProductOptionObj,
				},
			};
		default:
			return state;
	}
};

export default reducer;

import { createAPIModule } from 'utility/redux/apiModuleHelpers';
// import { createSelector } from 'reselect';

const getStateSlice = (state) => state.api.storm.image.create;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'STORM_IMAGE_CREATE',
	method: 'POST',
	url: '/storm/image/create.json',
});

const selectors = {
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };

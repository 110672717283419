import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import LWButton from 'components/common/LWButton';
import LWTypography from 'components/common/LWTypography';

import AddressDataCard from './AddressDataCard';

const ValidateContent = ({
	isLoading,
	setIsOpen,
	submitValidated,
	submitOriginal,
	addressData,
}) => {
	return (
		<>
			<LWTypography variant="body1" centered>
				{'We found a match for your address. '}
			</LWTypography>
			<LWTypography variant="body1" centered paragraph>
				{'Does this look accurate?'}
			</LWTypography>

			<AddressDataCard title="Verified Address" addressData={addressData} />
			<Grid container spacing={2} justify="flex-end">
				<Grid item>
					<LWButton
						color="tertiary"
						isLoading={isLoading}
						onClick={() => setIsOpen(false)}
						variant="outlined"
					>
						Cancel
					</LWButton>
				</Grid>
				<Grid item>
					<LWButton
						color="secondary"
						isLoading={isLoading}
						onClick={submitOriginal}
						variant="outlined"
					>
						Use mine instead
					</LWButton>
				</Grid>
				<Grid item>
					<LWButton
						data-testid="ValidateContent__SubmitButton"
						color="secondary"
						isLoading={isLoading}
						onClick={submitValidated}
						variant="contained"
					>
						Use verified address
					</LWButton>
				</Grid>
			</Grid>
		</>
	);
};

ValidateContent.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	addressData: PropTypes.shape({
		address: PropTypes.string,
		address2: PropTypes.string,
		city: PropTypes.string,
		country: PropTypes.string,
		postal_code: PropTypes.string,
		state: PropTypes.string,
	}),
	setIsOpen: PropTypes.func.isRequired,
	submitValidated: PropTypes.func.isRequired,
	submitOriginal: PropTypes.func.isRequired,
};

export default ValidateContent;

import actions from './actions';

const initialState = {
	enabled: false,
};
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.ON:
			return { ...state, enabled: true };
		case actions.OFF:
			return { ...state, enabled: false };
		default:
			return state;
	}
};

export default reducer;

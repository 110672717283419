import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.api.network.util.isOfficeIp;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
	additionalSagas,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_UTIL_ISOFFICEIP',
	method: 'POST',
	url: '/network/util/isOfficeIp.json',
});

const selectors = {
	getIsOfficeIp: createSelector(
		defaultSelectors.getStateSlice,
		(slice) => !!slice.getIn(['data', 'isOfficeIp']),
	),
	...defaultSelectors,
};

export {
	actions,
	reducer,
	sagas,
	selectors,
	additionalSagas as isOfficeIpAdditionalSagas,
};

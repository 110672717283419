import Cookies from 'js-cookie';

const setCookie = ({ path = '/', domain = 'liquidweb.com', name, value }) => {
	const securityObj = (() => {
		switch (process.env.NODE_ENV) {
			case 'test':
				return {};
			case 'development':
				return { secure: true };
			default:
				return { domain, secure: true };
		}
	})();
	Cookies.set(name, value, { path, sameSite: 'None', ...securityObj });
};

const getCjReferralData = () => {
	const cookie = Cookies.get('CJPIDLW');
	const eventCookie = Cookies.get('CJEVENTLW');

	if (!cookie) {
		return null;
	}

	const fields = cookie.match(/pid=([^&]+)&aid=([^&]+)?&sid=([^&]+)?/);

	if (!fields) {
		return null;
	}

	return {
		Network: 'cj',
		PartnerId: fields[1],
		AdId: fields[2],
		SubId: fields[3],
		ClickId: eventCookie,
	};
};

const getLwReferralData = () => {
	const cookie = Cookies.get('lwReferralData');

	if (!cookie) {
		return null;
	}

	const cookieData = JSON.parse(cookie);

	switch (cookieData.program) {
		case 'impact_radius':
			return {
				Network: cookieData.program,
				PartnerId: cookieData.irpid,
				AdId: cookieData.iradid,
				SubId: cookieData.sharedid,
				ClickId: cookieData.clickid,
			};

		case 'awin':
			return {
				Network: cookieData.program,
				PartnerId: cookieData.awid,
				AdId: cookieData.aid,
				SubId: cookieData.sid,
				ClickId: cookieData.awc,
			};

		default:
			return null;
	}
};

export { getCjReferralData, getLwReferralData, setCookie };

import basketActions from '../actions';

const initialState = {
	errorText: null,
	coupon: null,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case basketActions.BASKET_SET_ERROR:
			return { ...state, errorText: action.text };
		case basketActions.BASKET_RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export { initialState };
export default reducer;

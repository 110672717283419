import { spawn } from 'redux-saga/effects';
import backups from './backup/backupSagas';
import image from './image/imageSagas';
import ipSagas from './ip/sagas';
import template from './template/templateSagas';
import privateSagas from './private/privateSagas';
import configSagas from './config/configSagas';

export default function* stormSaga() {
	yield spawn(backups);
	yield spawn(image);
	yield spawn(ipSagas);
	yield spawn(privateSagas);
	yield spawn(template);
	yield spawn(configSagas);
}

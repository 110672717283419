import React from 'react';
import { fromApiBoolean } from 'club-sauce';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import NewTabIcon from '@material-ui/icons/OpenInNewRounded';
import LWLink from 'components/common/LWLink';
import { actions as consoleActions } from 'modules/server/console';
import vmwareActions from 'modules/vmware/actions';
import { selectors as vmwareLoginUrlSelectors } from 'modules/api/vmware/vcloud/vdc/loginUrlModule';
import { SLWButton } from '../actionStyles';

/** @param {import('modules/queries/asset/useDetails').AssetDetails} props */
const FooterActions = ({
	isPrivateParent,
	isBeyondHosting,
	isVmware,
	isOpenStack,
	assetType: serverType,
	status,
	uniq_id: uniqId,
	ip,
	capabilities,
}) => {
	const dispatch = useDispatch();

	const canSpice = fromApiBoolean(capabilities?.canUseSpiceConsole);
	const isLoadingvmWareLink = useSelector(vmwareLoginUrlSelectors.isLoading);

	const openConsole = () => {
		dispatch(
			consoleActions.openConsole({
				uniqId,
				canSpice,
			}),
		);
	};

	const history = useHistory();

	const isCpanel = fromApiBoolean(capabilities?.cpanelLogin);
	const isInterworx = fromApiBoolean(capabilities?.interworxLogin);
	const isPlesk = fromApiBoolean(capabilities?.pleskLogin);
	const isCloud =
		serverType === 'cloud' &&
		!isOpenStack &&
		!isBeyondHosting &&
		!isPrivateParent;

	const resizeServer = () => history.push(`/servers/cloud/${uniqId}/resize`);

	return (
		<Box mt={2} mb={-1} pt={1} borderTop={1} borderColor="grey.disabledOpaque">
			{(isBeyondHosting || isOpenStack || canSpice) && (
				<SLWButton
					onClick={openConsole}
					color="secondary"
					variant="outlined"
					disabled={status !== 'Active'}
				>
					Console
				</SLWButton>
			)}
			{isCpanel && (
				<SLWButton
					onClick={() =>
						window.open(`https://${ip}:2083`, '_blank', 'noopener')
					}
					color="secondary"
					variant="outlined"
					endIcon={<NewTabIcon />}
					disabled={status !== 'Active'}
				>
					Go to cPanel
				</SLWButton>
			)}
			{isCpanel && (
				<SLWButton
					onClick={() =>
						window.open(`https://${ip}:2087`, '_blank', 'noopener')
					}
					color="secondary"
					variant="outlined"
					endIcon={<NewTabIcon />}
					disabled={status !== 'Active'}
				>
					Go to WHM
				</SLWButton>
			)}
			{isInterworx && (
				<SLWButton
					onClick={() =>
						window.open(`https://${ip}:2443`, '_blank', 'noopener')
					}
					color="secondary"
					variant="outlined"
					endIcon={<NewTabIcon />}
					disabled={status !== 'Active'}
				>
					Go to Interworx
				</SLWButton>
			)}
			{isPlesk && (
				<SLWButton
					onClick={() =>
						window.open(`https://${ip}:8443`, '_blank', 'noopener')
					}
					color="secondary"
					variant="outlined"
					endIcon={<NewTabIcon />}
					disabled={status !== 'Active'}
				>
					Go to Plesk
				</SLWButton>
			)}
			{isVmware && (
				<SLWButton
					onClick={() => dispatch(vmwareActions.openVmwarePortal({ uniqId }))}
					color="secondary"
					variant="outlined"
					endIcon={<NewTabIcon />}
					disabled={status !== 'Active'}
					isLoading={isLoadingvmWareLink}
				>
					Go to VMWare
				</SLWButton>
			)}
			{!isPrivateParent && (
				<LWLink to={`${uniqId}/support-access`} underline="none">
					<SLWButton
						variant="outlined"
						color="tertiary"
						disabled={status !== 'Active'}
					>
						Support Access
					</SLWButton>
				</LWLink>
			)}
			{(isBeyondHosting || isCloud || isOpenStack) && !isVmware && (
				<SLWButton
					onClick={resizeServer}
					color="tertiary"
					variant="outlined"
					disabled={status !== 'Active'}
				>
					Resize
				</SLWButton>
			)}
		</Box>
	);
};

export default FooterActions;

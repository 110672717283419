import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// redux
import { selectors as routeSelectors } from 'modules/route';

import PageOverview from 'components/structural/PageOverview';
import ErrorText from 'components/molecules/ErrorText';
import {
	contentArrays,
	titles,
	breadCrumbNames,
	refreshButtons,
} from './variantContent';

const NotFound = ({ variant = 'default' }) => {
	const backBreadCrumb = useSelector(routeSelectors.getBackBreadCrumb)[variant];
	return (
		<PageOverview
			hideFavorite
			parent={[
				...(backBreadCrumb ? [backBreadCrumb] : []),
				{ name: breadCrumbNames[variant], currentPage: true },
			]}
			breadCrumbProps={{ hideHome: !!backBreadCrumb }}
		>
			<ErrorText
				contentArray={contentArrays[variant]}
				title={titles[variant]}
				hideRefresh={!refreshButtons[variant]} // Dont show if there's no button text.
				refreshButtonText={refreshButtons[variant]}
				data-testid={`NotFound__${variant}__ErrorText`}
			/>
		</PageOverview>
	);
};

const variants = ['default', 'addToCart'];
NotFound.propTypes = {
	variant: PropTypes.oneOf(variants),
};

export { variants };
export default NotFound;

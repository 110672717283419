import { formValueSelector, change } from 'redux-form';
import connectAndWrap from 'utility/form/connectAndWrap';
import AddressForm from 'components/forms/AddressForm';
import { selectors as contactListSelectors } from 'modules/api/contact/listModule';
import { countryLabelMap } from 'utility/countriesMappedToOptions';

const formName = 'AccountCreateAddressForm';
const formSelector = formValueSelector(formName);

const mapStateToProps = (state) => {
	const {
		address,
		address2,
		city,
		country,
		postal_code: postalCode,
	} = contactListSelectors.getDefaultContactWithDefaultValues(state)?.toJS();

	return {
		formValues: formSelector(
			state,
			'address',
			'address2',
			'city',
			'state',
			'country',
			'postalCode',
		),
		initialValues: {
			address,
			address2,
			city,
			state: contactListSelectors.stateOption(state),
			country: country
				? { label: countryLabelMap[country], value: country }
				: undefined,
			postalCode,
		},
		selectedCountry: formSelector(state, 'country'),
		isLoading: contactListSelectors.isLoading(state),
	};
};

const mapDispatchToProps = (dispatch) => ({
	changeFieldValue: (field, value) => dispatch(change(formName, field, value)),
});

export { formName, formSelector };
export default connectAndWrap({
	mapStateToProps,
	mapDispatchToProps,
	form: formName,
})(AddressForm);

const actions = {
	ASSET_UNIQ_ID_DETAILS_SYNC: 'ASSET_UNIQ_ID_DETAILS_SYNC',
	/**
	 * Syncs the loaded asset details to the uniqId
	 * @param {string} param0.uniqId
	 * @returns {object}
	 */
	uniqIdDetailsSync: ({ uniqId }) => ({
		type: actions.ASSET_UNIQ_ID_DETAILS_SYNC,
		uniqId,
	}),
};

export default actions;

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LWTypography from 'components/common/LWTypography';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getIsRedirecting } from 'modules/auth';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import LWError from 'components/common/LWError';
import LWLink from 'components/common/LWLink';
import LWLoader from 'components/common/LWLoader';

const styles = () => ({
	root: {
		width: '40rem',
		margin: '6rem auto',
		display: 'block',
	},
	errorText: {},
});

const UnauthorizedPage = ({
	children,
	classes,
	dispatch,
	isSuspended,
	isRedirecting,
	...rest
}) => {
	const getErrorText = () => {
		if (isSuspended) {
			return (
				<>
					{
						'Your account has been suspended. Please contact our Billing or Support department if you require assistance. '
					}
					<List>
						<ListItem>
							<LWLink to="/account/billing">Billing Details</LWLink>
						</ListItem>
						<ListItem>
							<LWLink to="/support/mytickets">Help Center</LWLink>
						</ListItem>
					</List>
				</>
			);
		}
		if (children) return children;
		return `Only authorized users may view this page.  Please contact the account owner to gain access.`;
	};
	if (isRedirecting) return <LWLoader redirect />;

	return (
		<LWError
			classes={{ root: classes.root }}
			title="Unauthorized"
			{...rest}
			shown
			data-testid="UnauthorizedPage__LWError"
		>
			<LWTypography className={classes.errorText}>
				{getErrorText()}
			</LWTypography>
		</LWError>
	);
};

UnauthorizedPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

export { UnauthorizedPage };

const mapStateToProps = (state) => ({
	isRedirecting: getIsRedirecting(state),
});

export default compose(
	withStyles(styles),
	connect(mapStateToProps),
)(UnauthorizedPage);

const maxWidthActions = {
	MAX_WIDTH_UPDATE: 'MAX_WIDTH_UPDATE',
	updateMaxWidth: (maxWidth) => ({
		type: maxWidthActions.MAX_WIDTH_UPDATE,
		maxWidth,
	}),
	MAX_WIDTH_SET_MARGIN_WIDTH: 'MAX_WIDTH_SET_MARGIN_WIDTH',
	/**
	 * Sets the width of the margin from the edge of PageOverview to the edge of the page.
	 * @param {number} width
	 * @returns {object}
	 */
	setMarginWidth: (marginWidth) => ({
		type: maxWidthActions.MAX_WIDTH_SET_MARGIN_WIDTH,
		marginWidth,
	}),
};

export default maxWidthActions;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'utility/compose';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as UserProfileIcon } from 'images/new-icons/account-sub.svg';
import LWLink from 'components/common/LWLink';
import { selectors as appConfigSelectors } from 'modules/appConfig';

const styles = (theme) => ({
	root: {},
	icon: {
		textDecoration: 'none',
		color: theme.palette.grey.dark,
		fill: theme.palette.grey.dark,
		'&:hover svg path': {
			fill: theme.palette.primary.main,
		},
		'&:hover': {
			background: theme.palette.background.default,
		},
		'&:hover span': {
			background: theme.palette.background.default,
			color: theme.palette.primary.main,
			textDecoration: 'underline',
		},
	},
	svg: {
		position: 'relative',
		top: '1px',
	},
	label: {
		position: 'relative',
		color: theme.palette.grey.dark,
		fontSize: '12px',
		top: '-2px',
		marginLeft: '-2px',
		'&:hover': {
			color: theme.palette.primary.main,
			textDecoration: 'underline',
		},
	},
});

const UserProfile = ({ classes, userProfilePath }) => (
	<LWLink
		className={classes.icon}
		aria-label="User Profile"
		to={userProfilePath}
	>
		<UserProfileIcon className={classes.svg} />{' '}
		<span className={classes.label}>User Profile</span>
	</LWLink>
);

UserProfile.propTypes = {
	classes: PropTypes.object.isRequired,
	userProfilePath: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
	userProfilePath: appConfigSelectors.getUserProfilePath(state),
});

export { UserProfile };

export default compose(
	connect(mapStateToProps),
	withStyles(styles),
)(UserProfile);

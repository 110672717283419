import { all, put, select, call } from 'redux-saga/effects';
import {
	getIsCloudDedicated,
	getIsVps,
	getRelatedProductCode,
} from 'banana-stand/parsers';
import { actions as resizeOptionsActions } from 'modules/server/resize/options';
import {
	actions as dynamicChildActions,
	selectors as dynamicChildSelectors,
} from 'modules/api/account/limits/dynamicChildModule';
import {
	actions as parentListActions,
	selectors as parentListSelectors,
} from 'modules/api/storm/private/parent/listModule';
import dialogActions from 'modules/dialogs/actions';
import { selectors as privateParentSelectors } from 'modules/server/resize/privateParent';
import { push } from 'connected-react-router';
import { cartDetailsSelectors } from 'modules/api/market/cart/detailsModule';
import { getHasActivatedAccount } from 'modules/auth';
import { takeSetOrError } from 'utility/redux/apiModuleHelpers';
import { configValueStrings } from 'utility/constants/baskets';
import {
	initializeFlatDetails,
	mergeSelectedProductOptions,
} from './flatDetailsSagas';
import deployOntoSelectors from './deployOntoSelectors';
import productConfigActions from './actions';
import productConfigSelectors from './selectors';

const privateParentPropertyKeys = ['parent', 'vcpu', 'memory', 'diskspace'];

/**
 * Removes the properties that would be added to an item when associating a parent.
 */
function* clearPrivateParent() {
	const productKey = yield select(productConfigSelectors.getActiveProductKey);
	yield all(
		privateParentPropertyKeys.map((key) =>
			put(
				productConfigActions.deleteProperty({
					productKey,
					key,
				}),
			),
		),
	);
}

/**
 * Retrives data from all endpoints needed to create child instances on an account. It also sets the resize module's `deployOnto` state so that the resize selectors can be used to drive the productConfig UI.
 * @param {Object} param0
 * @param {string} param0.deployOnto - The value of the deploy onto that requires this initialization. This is typically the uniqId of the SS.PP asset.
 */
function* initPrivateParentData({ deployOnto }) {
	const hasActivatedAccount = yield select(getHasActivatedAccount);

	if (!hasActivatedAccount) return false;

	const hasData = yield select(parentListSelectors.hasData);

	if (!hasData) {
		yield put(parentListActions.init());
		yield call(takeSetOrError, {
			selectors: parentListSelectors,
			actions: parentListActions,
		});
	}

	const dynamicChildHasData = yield select(dynamicChildSelectors.hasData);
	if (!dynamicChildHasData) {
		yield put(dynamicChildActions.init());
		yield call(takeSetOrError, {
			selectors: dynamicChildSelectors,
			actions: dynamicChildActions,
		});
	}

	// get the defaults for adding a VPS to a private parent from the resize module.
	yield put(resizeOptionsActions.setDeployOnto({ deployOnto }));
	return true;
}

/**
 * Configures the active product to be a child instance of the given deployOnto value. This includes setting the config ID to '0' (Dynamically defined config)
 * @param {Object} param0
 * @param {string} param0.deployOnto - The value of the deploy onto with which to configure the product. This is typically the uniqId of the SS.PP asset.
 * @param {string} param0.productKey - productKey onto which to assign the parent properties. This must be passed in because navigation may be occuring, which makes getActiveProductKey volatile.
 */
function* setPrivateParent({ deployOnto, productKey }) {
	const ppDataInit = yield call(initPrivateParentData, { deployOnto });
	if (!ppDataInit) return; // This can't happen in context, because unauthed users won't have the opportunity to select a PP.

	// Set ConfigId to Dynamically defined config ('0')
	const keyStringToProductCodeToPtok = yield select(
		productConfigSelectors.getKeyStringToProductCodeToPtok,
	);
	const keyToValueToProductCodeToPtov = yield select(
		productConfigSelectors.getKeyToValueToProductCodeToPtov,
	);
	const activeProductCode = yield select(
		productConfigSelectors.getActiveProductCode,
	);
	const ptokId = keyStringToProductCodeToPtok?.ConfigId?.[activeProductCode];
	if (!ptokId) return; // This was called while the active product code did not have a ConfigId option. This is fine, but needs to be noOp.
	const ptovId =
		keyToValueToProductCodeToPtov.ConfigId[
			configValueStrings.dynamicConfigId
		]?.[activeProductCode];

	yield put(
		productConfigActions.cascadeUpdateOption({
			ptokId,
			ptovId,
		}),
	);

	const initValueMap = {
		// defaulting to 1's just in case initializing didn't go well.
		vcpu: (yield select(privateParentSelectors.getInitialCpu)) || 1,
		diskspace: (yield select(privateParentSelectors.getInitialDisk)) || 1,
		memory: (yield select(privateParentSelectors.getInitialRam)) || 1,
		parent: deployOnto,
	};

	yield all(
		Object.entries(initValueMap).map(([key, value]) =>
			put(
				productConfigActions.addProperty({
					productKey,
					key,
					value,
				}),
			),
		),
	);

	const region = yield select(privateParentSelectors.getParentRegion);
	yield put(
		productConfigActions.patchSelectedProductRegions({ productKey, region }),
	);
}

/**
 * Performs the necessary side effects when setting the deployOnto config dropdown.
 * @param {Object} param0
 * @param {string} param0.deployOnto - the value the deployOnto dropdown has just been set.
 * @param {boolean} param0.noop - set to true to skip side effects that are not applicable during initialization (such as redirecting to a new product code.)
 */
function* handleSetDeployOnto({ deployOnto, noop }) {
	if (noop) {
		return;
	}
	yield put(dialogActions.close()); // If swap needs to be called, there is a modal to close
	const productKey = yield select(productConfigSelectors.getActiveProductKey);
	const actionIsModify = Boolean(
		(yield select(cartDetailsSelectors.getItemsByUuid))[productKey],
	);
	const currentProductCode = yield select(
		productConfigSelectors.getActiveProductCode,
	);

	const isVps = getIsVps(currentProductCode);
	const isCloudDedicated = getIsCloudDedicated(currentProductCode);

	let newProductCode;
	// .VM to anything other than .VM needs a new product code
	// i.e. either public cloud or a specific private parent
	if (isCloudDedicated && deployOnto !== 'cloudDedicated') {
		newProductCode = getRelatedProductCode(currentProductCode);
	}
	// .VPS or children should get a new product code only if going to a .VM
	if (isVps && deployOnto === 'cloudDedicated') {
		newProductCode = getRelatedProductCode(currentProductCode);
	}

	if (newProductCode) {
		// Ensure that the newProductCode has the data ready before proceeding.
		yield call(initializeFlatDetails, { productCode: newProductCode });

		// merge the old options into the new product
		yield call(mergeSelectedProductOptions, {
			sourceProductKey: productKey,
			targetProductCode: newProductCode,
		});
		if (!actionIsModify) {
			yield put(push(`/shop/config/${newProductCode}`));
		}
	}

	if (deployOnto !== 'cloudDedicated' && deployOnto !== 'vps') {
		// Set private parent whenever deploying to a private parent
		yield call(setPrivateParent, {
			deployOnto,
			productKey: newProductCode || productKey,
		});
	} else {
		// Clear private parent properties whenever deploying to anything else.
		yield call(clearPrivateParent);
	}
}

/**
 * Initializes the deployOnto dropdown to the current activeProductKey and gets private parent data.
 */
function* initDeployOnto() {
	const deployOnto = yield select(deployOntoSelectors.getDeployOntoInitValue);
	if (deployOnto) {
		// Set the redux store without the redircts, merges, or flatDetails inits. We only need to set the dropdown value since the configs should already be set.
		yield put(productConfigActions.setDeployOnto({ deployOnto, noop: true }));
		yield call(initPrivateParentData, { deployOnto });
	}
}

/**
 * Checks to see if a productCode change is required. If not, the passed in `confirm` function is executed. If so, the function is passed to a modal, which will dislay to the user.
 * @param {Object} param0
 * @param {string} param0.value - the new value of the deployOnto dropdown.
 * @param {function} param0.confirm - the action to be executed if the modal is either not needed or confirmed.
 */
function* handleOnChangeDeployOnto({ value, confirm }) {
	const needsSwap = yield select(
		deployOntoSelectors.generateGetNeedsSwap(value),
	);
	if (needsSwap) {
		yield put(
			dialogActions.open({
				title: 'Change Product Type?',
				color: 'danger',
				contentKey: 'ConfigSelectModalContents',
				contentProps: {
					confirm,
					variant: 'type',
				},
			}),
		);
	} else {
		confirm();
	}
}

export { initDeployOnto, handleOnChangeDeployOnto, handleSetDeployOnto };

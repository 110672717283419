import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { required } from 'redux-form-validators';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LWButton from 'components/common/LWButton';
import Collapse from '@material-ui/core/Collapse';
import Hidden from '@material-ui/core/Hidden';
import { renderCheckbox, SelectField } from 'utility/form/renderers';
import TextField from 'components/atoms/TextField';

import { getTaxIdLabel } from 'utility/addressLabel';
import variantConfigs from './variantConfigs';

const TaxExemptFields = ({
	formValues,
	taxExemptReasons,
	changeFieldValue,
	displayTaxExemptFields,
	selectedCountry,
	variant,
	isLoading,
}) => {
	if (!displayTaxExemptFields) return null;
	const {
		gridItemSettings,
		gridContainerSettings,
		showUseEinButton,
		gridItemSpacerSettings,
	} = variantConfigs[variant];
	return (
		<>
			<Box px={1} py={3}>
				<Field
					aria-label="Tax Exempt"
					label="This account is owned by a tax exempt individual or organization"
					name="taxExempt"
					component={renderCheckbox}
					variant="outlined"
					disabled={isLoading}
				/>
			</Box>
			<Collapse in={formValues.taxExempt}>
				<Grid container {...gridContainerSettings}>
					<Grid item {...gridItemSettings}>
						<Field
							aria-label="Tax Exempt Reason"
							label="Tax Exempt Reason"
							name="taxExemptReason"
							validate={formValues.taxExempt ? [required()] : []}
							variant="outlined"
							component={SelectField}
							onChange={(e) => {
								changeFieldValue('taxExemptReason', e.target.value);
							}}
							options={taxExemptReasons}
							disabled={isLoading}
						/>
					</Grid>
					<Hidden xsDown>
						<Grid item {...gridItemSpacerSettings} />
					</Hidden>
					<Grid item {...gridItemSettings}>
						<Field
							label="Tax Exempt Id"
							aria-label="Tax Exempt Id"
							name="taxExemptId"
							component={TextField}
							validate={formValues.taxExempt ? [required()] : []}
							variant="outlined"
							disabled={isLoading}
						/>
					</Grid>
					{showUseEinButton && (
						<Grid item>
							<LWButton
								onClick={() =>
									changeFieldValue('taxExemptId', formValues.taxId)
								}
							>
								{`Use ${getTaxIdLabel(selectedCountry?.label)}`}
							</LWButton>
						</Grid>
					)}
				</Grid>
			</Collapse>
		</>
	);
};

TaxExemptFields.propTypes = {
	/* Function for changing values within the form */
	changeFieldValue: PropTypes.func,
	/* Current values of form inputs stored in state by redux form */
	formValues: PropTypes.object,
	/* Currently selected country */
	selectedCountry: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	/* returned from API */
	taxExemptReasons: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		}),
	),
	/** The key to the way you need this displayed */
	variant: PropTypes.string,
};

export default TaxExemptFields;

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import getAppVersion from 'utility/getAppVersion';
import getCommit from 'utility/getCommit';
import LabeledText from 'components/atoms/LabeledText';
import { selectors as whatsMyIpSelectors } from 'modules/api/utilities/info/whatismyipModule';
import {
	internalOnlySelectors,
	internalOnlyActions,
} from 'modules/internalOnly';

const AppInfoDetails = () => {
	const dispatch = useDispatch();
	const version = getAppVersion();
	const commit = getCommit();
	const ipIsLoading = useSelector(whatsMyIpSelectors.isLoading);
	const ip = useSelector(whatsMyIpSelectors.ip);
	const isInternalUser = useSelector(internalOnlySelectors.isInternalUser);

	useEffect(() => {
		if (!ip) dispatch(internalOnlyActions.check());
	}, [dispatch, ip]);

	return (
		<>
			<LabeledText label="Current Version" text={version} inline noMarginTop />
			<LabeledText label="Commit" text={commit} inline noMarginTop />
			<LabeledText
				isLoading={ipIsLoading}
				label="User IP Address"
				text={ip}
				inline
				noMarginTop
			/>
			{isInternalUser && (
				<LabeledText
					label="You are an internal user"
					text="This is only visible on a LW network"
					inline
					noMarginTop
				/>
			)}
		</>
	);
};

export default AppInfoDetails;

const cloudTypes = {
	'SS.VPS': 'VPS',
	'SS.VPS.WIN': 'VPS',
	'SS.VM': 'Cloud Dedicated',
	'SS.VM.WIN': 'Cloud Dedicated',
	'BH.VM': 'Cloud (BeyondHosting)',
	'Cloud.VPS': 'Cloud VPS',
	'Cloud.VPS.WIN': 'Cloud VPS',
	'Cloud.Metal': 'Cloud Metal',
	'Cloud.Metal.WIN': 'Cloud Metal',
};

export default cloudTypes;

import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.market.cart.expire;

const { actions, reducer, sagas, selectors, additionalSagas } = createAPIModule(
	{
		getStateSlice,
		actionType: 'MARKET_CART_EXPIRE',
		url: '/market/cart/expire.json',
	},
);

export {
	actions as cartExpireActions,
	reducer as cartExpireReducer,
	sagas as cartExpireSagas,
	selectors as cartExpireSelectors,
	additionalSagas as cartExpireAdditionalSagas,
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LWTypography from 'components/common/LWTypography';

const SContainer = styled.div`
	display: flex;
	position: relative;
	flex-direction: row;
	background-color: ${({ theme }) => theme.palette.common.white};
	padding-left 8px;
	padding-right: 8px;
	border-radius: 4px;
`;

const STitleContainer = styled.div`
	min-width: 122px;
	max-width: 122px;
	margin-right: 8px;
`;

const SValueContainer = styled.div`
	flex: 1 1 100%;
`;

const SCradle = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 4px;
	border-radius: 4px;
	border: 1px solid ${({ theme }) => theme.palette.grey.disabledOpaque};
	border-top-right-radius: 0;
	border-top-left-radius: 0;
	border-top: transparent;
`;

const DataGridItem = ({ title, children, ...containerProps }) => (
	<SContainer {...containerProps}>
		<STitleContainer>
			<LWTypography bold>{title}</LWTypography>
		</STitleContainer>
		<SValueContainer>{children}</SValueContainer>
		<SCradle />
	</SContainer>
);

DataGridItem.propTypes = {
	/** Data label */
	title: PropTypes.string.isRequired,
	/** Data value */
	children: PropTypes.node,
};

export default DataGridItem;

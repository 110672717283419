import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { types, stripPrefix } from 'utility/constants/contactListTypes';
import Immutable from 'immutable';

const getStateSlice = (state) => state.api.network.dns.domain.contact.list;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_DNS_DOMAIN_CONTACT_LIST',
	url: '/network/dns/domain/contact/list.json',
});

const removeEmptyMapsAndBadFields = (contact) =>
	contact &&
	contact
		.filter((value) => !(Immutable.Map.isMap(value) && value.isEmpty()))
		.delete('ContactMatch')
		.delete('ConsentStatus')
		.delete('ConsentStatusDisplay')
		.delete('PartyID')
		.delete('PhoneExt');

const getStrippedContactsWithType = createSelector(
	defaultSelectors.getData,
	(slice) =>
		slice &&
		slice.get('contacts').map((contact) => {
			const contactType = Object.values(types).find((type) =>
				contact.has(type.keyCheck),
			);
			return removeEmptyMapsAndBadFields(
				stripPrefix(contactType, contact).set(
					'type',
					Immutable.fromJS(contactType),
				),
			);
		}),
);

const getContactFromType = (state, props) => {
	const slice = defaultSelectors.getStateSlice(state);
	const contacts = slice.getIn(['data', 'contacts']);
	if (!contacts) return {};
	const type = props.contactListType;

	return {
		type,
		contact: removeEmptyMapsAndBadFields(
			contacts
				.filter((contact) => contact.findKey((_, key) => key === type.keyCheck))
				.first(),
		),
	};
};

const selectors = {
	getStrippedContactsWithType,
	getContactOfTypeSelector: () =>
		createSelector(
			getContactFromType,
			({ contact, type }) =>
				contact &&
				removeEmptyMapsAndBadFields(stripPrefix(type, contact, true)),
		),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };

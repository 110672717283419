import React from 'react';
import PropTypes from 'prop-types';
import LWLink from 'components/common/LWLink';
import LWTypography from 'components/common/LWTypography';

const CloudBlockStorage = ({ blockStorage }) => (
	<>
		<LWTypography color="textSecondary" variant="caption">
			Cloud Block Storage
		</LWTypography>
		{blockStorage.map((volume) => (
			<div key={volume.get('name')}>
				<LWLink to={`/services/cloud-block-storage/${volume.get('uniq_id')}`}>
					{`${volume.get('name')} - ${volume.get('size')} GB`}
				</LWLink>
			</div>
		))}
	</>
);

CloudBlockStorage.propTypes = {
	blockStorage: PropTypes.object.isRequired,
};

export default CloudBlockStorage;

const favoritesActions = {
	OPEN_FAVORITES_MODAL: 'OPEN_FAVORITES_MODAL',
	openFavoritesModal: () => ({ type: favoritesActions.OPEN_FAVORITES_MODAL }),
	CLOSE_FAVORITES_MODAL: 'CLOSE_FAVORITES_MODAL',
	closeFavoritesModal: ({ path } = {}) => ({
		type: favoritesActions.CLOSE_FAVORITES_MODAL,
		path,
	}),
};

export default favoritesActions;

import { createSelector } from 'reselect';
import { selectors as signupSelectors } from 'modules/api/account/signupModule';
import { selectors as updateSelectors } from 'modules/api/contact/updateModule';
import { selectors as contactListSelectors } from 'modules/api/contact/listModule';
import { selectors as paymentDetailsSelectors } from 'modules/api/billing/payment/profile/detailsModule';
import { selectors as billingAddressSelectors } from 'modules/api/account/billingAddress/detailsModule';

const signupPutIsLoading = createSelector(
	signupSelectors.isLoading,
	updateSelectors.isLoading,
	contactListSelectors.isLoading,
	(signup, update, contact) => signup || update || contact,
);

// payment and billing details may need to load at /cart during takeToNextStep in createSagas.js
const isTakingToNextStep = createSelector(
	paymentDetailsSelectors.isLoading,
	billingAddressSelectors.isLoading,
	(paymentIsLoading, billingAddressIsLoading) =>
		paymentIsLoading || billingAddressIsLoading,
);

export default { signupPutIsLoading, isTakingToNextStep };

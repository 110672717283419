import { combineReducers } from 'redux';

import { reducer as assignableIps } from './assignableIpsModule';
import { reducer as deleteReducer } from './deleteModule';
import { reducer as update } from './updateModule';

export default combineReducers({
	assignableIps,
	delete: deleteReducer,
	update,
});

import { spawn } from 'redux-saga/effects';
import { sagas as createKey } from './createKeyModule';
import { sagas as deleteKey } from './deleteKeyModule';
import { sagas as details } from './detailsModule';
import { sagas as deleteModule } from './deleteModule';
import { sagas as createModule } from './createModule';

export default function* clusterSaga() {
	yield spawn(createKey);
	yield spawn(deleteKey);
	yield spawn(details);
	yield spawn(deleteModule);
	yield spawn(createModule);
}

import { call, put, select } from 'redux-saga/effects';
import snackbarSagas from 'modules/snackbar/sagas';
import maintenanceModeActions from 'modules/maintenanceMode/actions';
import { enabled as maintenanceModeEnabledSelector } from 'modules/maintenanceMode/selectors';
import { getAuthToken, getIsTokenExpired } from 'modules/auth/authSelectors';
import { selectors as appConfigSelectors } from 'modules/appConfig';
import authActions from 'modules/auth/authActions';
import axiosClient from './axiosClient';

const currentPathname = (state) => state.router.location.pathname;

//
/**
 * proxy to axios call. Handles standard actions from bad status codes, and errors in general.
 * @param {object} axiosData - The object passed along to axios
 * @returns {(object)} - Returns keys request (full axios request) and data on good response,
 * returns error on bad response
 */
function* sendApiRequest(axiosData, attemptNumber = 1) {
	const axios = yield call(axiosClient);
	const isTokenExpired = yield select(getIsTokenExpired);
	try {
		const authedAxiosRequest = axiosData;
		const accessToken = yield select(getAuthToken);
		if (accessToken && !isTokenExpired) {
			authedAxiosRequest.headers = {
				...authedAxiosRequest.headers,
				Authorization: `Bearer ${accessToken}`,
			};
		} else {
			authedAxiosRequest.auth = yield select(
				appConfigSelectors.getGuestCredentials,
			);
		}
		const response = yield call(axios, authedAxiosRequest);
		return { response, data: response.data };
	} catch (error) {
		const { response, request } = error;
		if (response) {
			// server sent back bad status code
			switch (response.status) {
				case 401:
					// let private route/login handle that.
					if (axiosData.url.startsWith('/account/auth/')) break;
					yield put(authActions.clearToken());
					break;
				case 412:
					// Backend will send this status code when it wants us to retry the API call
					if (attemptNumber < 3) {
						yield call(sendApiRequest, axiosData, attemptNumber + 1);
					} else {
						yield call(snackbarSagas, {
							error: true,
							errorMessage:
								'An error has occured. Please retry and contact support if error persists',
						});
					}
					break;
				case 500:
					if (response.data.error_class === 'LW::Exception::API::Maintenance') {
						const maintenanceModeEnabled = yield select(
							maintenanceModeEnabledSelector,
						);
						if (!maintenanceModeEnabled) yield put(maintenanceModeActions.on());
					}
					// TODO: handle other 500 errors
					break;
				default:
					// TODO: handle unknown error
					break;
			}
		} else if (request) {
			// yield put(
			//	errorActions.pushError({
			//		message: `API request failed`,
			//	}),
			// );
		} else {
			// TODO: handle developer error
		}
		return { error };
	}
}

export { currentPathname };
export default sendApiRequest;

import * as React from 'react';
import makeGraphData from 'modules/queries/asset/measurements/makeGraphData';
import Graph from 'components/molecules/Graph';
import useAssetMeasurementsLookup from 'modules/queries/asset/measurements/useLookup';

const Processor = ({ uniqId }) => {
	const { data, isLoading, isError } = useAssetMeasurementsLookup({
		uniq_id: uniqId,
		name: 'cloud_cpu',
		fields: ['used'],
		duration: 'day',
	});

	const graphData = makeGraphData({
		data: data?.data,
		keys: ['Usage (percent)'],
	});

	return (
		<Graph
			data={graphData}
			title="Processor"
			subtitle="Percentage of CPU Usage - Last 24 Hours"
			isLoading={isLoading}
			errorText={isError ? 'Unable to load processor usage data' : ''}
		/>
	);
};

export default Processor;

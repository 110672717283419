import React from 'react';
import { ReactComponent as LogoText } from 'images/lw-text-logo.svg';
import LWTypography from 'components/common/LWTypography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

const AppConfigError = () => {
	return (
		<Box>
			<Grid container direction="column">
				<Grid item xs={12}>
					<LogoText />
				</Grid>
				<Grid item>
					<LWTypography variant="h1">Config Error</LWTypography>
				</Grid>
				<Grid item>
					<LWTypography>No configuration file was found.</LWTypography>
				</Grid>
			</Grid>
		</Box>
	);
};

export default AppConfigError;

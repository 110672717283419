import React from 'react';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import useGradient from 'utility/effects/useGradient';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const SBox = styled(Box)`
	${({ $color }) => useGradient($color)}
`;

const GradientSquare = ({ color }) => {
	const theme = useTheme();
	return (
		<SBox
			$color={color}
			height={theme.spacing(2)}
			width={theme.spacing(2)}
			borderRadius={4}
		/>
	);
};

GradientSquare.propTypes = {
	color: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
};

export default GradientSquare;

import { createSelector } from 'reselect';
import { matchPath } from 'react-router';
import {
	hasAuthToken as getHasAuthToken,
	isSetupUser as getIsSetupUser,
	hasGroup as getHasGroup,
	isBasketAdmin as getIsBasketAdmin,
} from 'modules/auth/authSelectors';
import unauthedRoutes from 'utility/constants/unauthedRoutes';
import { selectors as appConfigSelectors } from 'modules/appConfig';
import { rootPaths } from 'config/pages';

const routerSlice = (state) => state.router;
const getRouteSlice = (state) => state.route;

const simplifiedFooterRoutes = ['/cart', '/shop', '/account/create'];

const getPathName = createSelector(
	routerSlice,
	(slice) => slice.location.pathname,
);

const getIsCart = createSelector(
	getPathName,
	(pathName) => pathName.toLowerCase() === rootPaths.cart.path,
);

const getIsHome = createSelector(
	getPathName,
	(pathName) => pathName === '/',
);

const getSearch = createSelector(
	routerSlice,
	(slice) => slice.location.search,
);

const getFullPathName = createSelector(
	routerSlice,
	(slice) => `${slice.location.pathname}${slice.location.search}`,
);

const getQueryParams = createSelector(
	routerSlice,
	(slice) => slice.location.query,
);

const getRootPathName = createSelector(
	routerSlice,
	(slice) => slice.location.pathname.split('/')[1],
);

const showSimplifiedFooter = createSelector(
	getPathName,
	(pathName) =>
		!!simplifiedFooterRoutes.find((route) => pathName.startsWith(route)),
);

const getAllowUnauth = createSelector(
	getPathName,
	(pathName) =>
		!!unauthedRoutes.find((route) =>
			matchPath(pathName, {
				path: route,
				exact: true,
				strict: false,
			}),
		),
);

const getPreviousRoute = createSelector(
	getRouteSlice,
	(route) => route.routeHistory.previous,
);

/**
 * Gets the objects to pass to PageOverview's "parent" prop for a 404 page for each 404 variant.
 * variants include 'default' and 'addToCart', and these are used as keys to access the breadcrumb object
 * @returns {object}
 */
const getBackBreadCrumb = createSelector(
	getPreviousRoute,
	getHasAuthToken,
	(previousRoute, hasAuthToken) => {
		const marketPlaceBreadCrumb = {
			name: 'Marketplace',
			path: '/shop/marketplace',
		};
		const backBreadCrumb = { name: 'Back', path: previousRoute };
		if (!previousRoute) {
			if (hasAuthToken) return { addToCart: marketPlaceBreadCrumb }; // use PageOverview default breadcrumbs for default variant.
			return {
				default: marketPlaceBreadCrumb /* TODO: ask ken where to direct unauthed users with no navigation history. */,
				addToCart: marketPlaceBreadCrumb,
			};
		}
		return { default: backBreadCrumb, addToCart: marketPlaceBreadCrumb };
	},
);

const getCurrentRoute = createSelector(
	getRouteSlice,
	(route) => route.routeHistory.current,
);

/**
 * @returns {string} - this current user's homepage.
 */
const getHomepagePath = createSelector(
	getHasAuthToken,
	getIsSetupUser,
	getHasGroup,
	getIsBasketAdmin,
	appConfigSelectors.getWwwHostname,
	(hasAuthToken, isSetupUser, hasGroup, isBasketAdmin, wwwHostname) => {
		if (isBasketAdmin) {
			return '/shop/marketplace';
		}
		return hasAuthToken && hasGroup && !isSetupUser ? '/' : wwwHostname;
	},
);

export default {
	getAllowUnauth,
	getBackBreadCrumb,
	getCurrentRoute,
	getFullPathName,
	getHomepagePath,
	getIsCart,
	getIsHome,
	getPreviousRoute,
	getPathName,
	getQueryParams,
	getRootPathName,
	getSearch,
	showSimplifiedFooter,
};

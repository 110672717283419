import { useQuery } from '@tanstack/react-query';
import api from 'modules/queries/api';

/**
 * @param {import('club-sauce/public/storm/template.raw').LWApiPublicStormTemplateListParamsRawI} params
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 */
const useStormTemplateList = (params, { enabled } = {}) =>
	useQuery({
		queryKey: ['/storm/template/list', params],
		queryFn: () => api.storm.template.apiRaw.list(params),
		enabled,
	});

export default useStormTemplateList;

import maxWidthActions from './actions';

const initialState = { marginWidth: 0 };
const maxWidthReducer = (state = initialState, action) => {
	switch (action.type) {
		case maxWidthActions.MAX_WIDTH_SET_MARGIN_WIDTH:
			return { ...state, marginWidth: action.marginWidth };
		default:
			return state;
	}
};

export default maxWidthReducer;

import { combineReducers } from 'redux';
import adminReducer from './admin/reducer';
import discountReducer from './discount/reducer';
import itemReducer from './item/reducer';
import { cartClaimReducer } from './claimModule';
import { cartDetailsReducer } from './detailsModule';
import { cartDomainReducer } from './domainModule';
import { cartExpireReducer } from './expireModule';
import { cartMetadataReducer } from './metadataModule';
import { cartOrderReducer } from './orderModule';

export default combineReducers({
	admin: adminReducer,
	claim: cartClaimReducer,
	details: cartDetailsReducer,
	domain: cartDomainReducer,
	discount: discountReducer,
	expire: cartExpireReducer,
	item: itemReducer,
	metadata: cartMetadataReducer,
	order: cartOrderReducer,
});

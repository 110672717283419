import { createSelector } from 'reselect';

const getStateSlice = (state) => state.basket.generic;

const getErrorText = createSelector(
	getStateSlice,
	(basket) => basket.errorText,
);

const getBasketPurchaseDetails = createSelector(
	getStateSlice,
	(basket) => basket.purchase.basketDetails || {},
);

const getIsPurchaseProcessing = createSelector(
	getStateSlice,
	(basket) => basket.purchase.isProcessing,
);

const getDetailsMap = (state) => state.basket.generic.detailsMap;

const selectors = {
	getBasketPurchaseDetails,
	getIsPurchaseProcessing,

	getDetailsMap,
	getErrorText,
	getStateSlice,
};

export { getStateSlice, getBasketPurchaseDetails, getErrorText };
export default selectors;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';

import LWLink from 'components/common/LWLink';
import LWTypography from 'components/common/LWTypography';
import HomeLink from 'containers/connectedAtoms/HomeLink';

const SItem = styled(Box)`
	&::after {
		content: '>';
		font-size: 0.875rem;
		margin: 0 ${({ theme }) => theme.spacing(1)}px;
		color: ${({ theme }) => theme.palette.text.disabled};
	}
	&:last-child {
		&::after {
			content: '';
		}
	}
`;

const ItemContent = ({ item }) => {
	if (item?.currentPage) {
		return (
			<LWTypography variant="body2" color="palette.primary.main" bold>
				{item?.name}
			</LWTypography>
		);
	}
	if (item?.disabled) {
		return (
			<LWTypography variant="body2" color="palette.text.secondary">
				{item?.name}
			</LWTypography>
		);
	}
	if (item?.path) {
		return (
			<LWLink variant="body2" to={item?.path} underline="hover">
				{item?.name}
			</LWLink>
		);
	}
	return (
		<LWTypography variant="body2" color="textSecondary">
			{item?.name}
		</LWTypography>
	);
};

const breadCrumbTestids = { BreadCrumb__items: 'BreadCrumb__items' };
const BreadCrumb = ({ parent = null, isLoading = false, hideHome }) => {
	const theme = useTheme();

	if (!parent) return null;

	const parents = Array.isArray(parent) ? parent : [parent];
	return (
		<Box
			width="100%"
			borderBottom={`solid ${theme.spacing(0.125)}px ${
				theme.palette.common.grey?.[2]
			}`}
			display="flex"
			alignItems="center"
			pb={2}
			mb={1}
			data-testid={breadCrumbTestids.BreadCrumb__items}
		>
			{!hideHome && (
				<SItem display="flex" alignItems="center">
					<HomeLink />
				</SItem>
			)}
			{isLoading ? (
				<LWTypography isLoading SkeletonProps={{ width: '50px' }} />
			) : (
				parents.map((item) => (
					<SItem
						key={item?.path || item?.name}
						display="flex"
						alignItems="center"
					>
						<ItemContent item={item} />
					</SItem>
				))
			)}
		</Box>
	);
};

const parentShape = PropTypes.shape({
	name: PropTypes.string.isRequired,
	path: PropTypes.string,
	currentPage: PropTypes.bool,
});

BreadCrumb.propTypes = {
	parent: PropTypes.oneOfType([parentShape, PropTypes.arrayOf(parentShape)]),
	isLoading: PropTypes.bool,
	hideHome: PropTypes.bool,
};

export { breadCrumbTestids };
export default BreadCrumb;

// @ts-check
import { useQuery } from '@tanstack/react-query';
import api from 'modules/queries/api';

/**
 * @param {import('club-sauce/public/monitoring/load/index.raw').LWApiPublicMonitoringLoadGraphParamsRawI} params
 */
const useMonitoringLoadGraph = (params) =>
	useQuery({
		queryKey: ['/monitoring/load/graph', params],
		queryFn: () => api.monitoring.load.apiRaw.graph(params),
	});

export default useMonitoringLoadGraph;

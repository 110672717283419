import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useSelector, useDispatch } from 'react-redux';

import { selectors as userStateDataSelectors } from 'modules/api/account/user/stateData/listForKeyModule';
import { selectors as routeSelectors } from 'modules/route';
import { maxWidthActions } from 'modules/account/maxWidth';

import { withStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import BreadCrumb from 'components/structural/BreadCrumb';
import ExpandedFooter from 'components/structural/ExpandedFooter';
import CompactFooter from 'components/structural/CompactFooter';
import Loading from 'components/common/Loading';
import LWLoader from 'components/common/LWLoader';
import ErrorBoundary from 'containers/structural/ErrorBoundary';
import MainContent from './MainContent';

const styles = (theme) => ({
	root: {
		width: '100%',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		backgroundColor: theme.palette.background.default,
	},
	content: {
		padding: '1em',
		minHeight: '100vh',
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(2),
		},
		display: 'flex',
		flexDirection: 'column',
		gap: `${theme.spacing(2)}px`,
	},
	maxWidthBox: {
		transition: '0.2s ease',
		transitionProperty: 'max-width, padding',
	},
	titleWrapper: {
		display: 'flex',
		alignItems: 'baseline',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5em',
		},
	},
});

const Content = ({ isLoading, loadingVariant, children }) => {
	if (isLoading) {
		let content;
		switch (loadingVariant) {
			case 'long':
				content = <LWLoader />;
				break;
			case 'short':
			default:
				content = <Loading CircularProgressProps={{ size: 64 }} />;
				break;
		}
		return (
			<Box height={250} position="relative" p={2} m={2}>
				{content}
			</Box>
		);
	}
	return children;
};

const PageOverview = ({
	classes,
	children = null,
	subNavData = null,
	name = '',
	subTitle = undefined,
	bottomContent = null,
	sideContent,
	sideContentProps,
	breadCrumbProps,
	createIcon = null,
	parent = null,
	favoritesDisplayName = null,
	isLoading = false,
	showTitleWhileLoading = false,
	loadingVariant = 'short',
	hideFavorite = false,
	beta,
	addPadding,
}) => {
	const dispatch = useDispatch();
	const theme = useTheme();

	const maxWidthToggled = useSelector(userStateDataSelectors.maxWidth);
	const showSimplifiedFooter = useSelector(routeSelectors.showSimplifiedFooter);

	// Keep the width of the margin in redux to manage things like chat button not overlapping with other things.
	const contentRef = useRef();
	const containerRef = useRef();

	useEffect(() => {
		const callback = () => {
			const { clientWidth: contentWidth } = contentRef.current;
			const { clientWidth: containerWidth } = containerRef.current;
			dispatch(
				maxWidthActions.setMarginWidth((containerWidth - contentWidth) / 2),
			);
		};
		const observer = new ResizeObserver(callback);
		observer.observe(containerRef.current);
		callback();
		return () => observer.disconnect();
	}, [dispatch]);

	useEffect(() => {
		if (name) {
			document.title = `My Liquid Web - ${name}`;
		} else {
			document.title = 'My Liquid Web';
		}
	}, [name]);

	return (
		<div className={classes.root} ref={containerRef}>
			<Box className={classes.content}>
				<Box
					className={classes.maxWidthBox}
					margin="0 auto"
					width="100%"
					maxWidth={theme.maxWidth}
				>
					<div className={classes.titleWrapper}>
						<BreadCrumb
							{...breadCrumbProps}
							isLoading={isLoading}
							parent={parent}
						/>
					</div>
					<MainContent
						createIcon={createIcon}
						sideContent={sideContent}
						sideContentProps={sideContentProps}
						name={name}
						hideFavorite={hideFavorite}
						favoritesDisplayName={favoritesDisplayName}
						subTitle={subTitle}
						subNavData={subNavData}
						bottomContent={bottomContent}
						beta={beta}
						isLoading={isLoading}
						showTitleWhileLoading={showTitleWhileLoading}
					/>
				</Box>
				<Box
					className={classes.maxWidthBox}
					margin="0 auto"
					width="100%"
					maxWidth={maxWidthToggled ? '100%' : theme.maxWidth}
					pt={addPadding ? 4 : 0}
				>
					<div ref={contentRef}>
						<ErrorBoundary>
							<Content isLoading={isLoading} loadingVariant={loadingVariant}>
								{children}
							</Content>
						</ErrorBoundary>
					</div>
				</Box>
			</Box>
			{showSimplifiedFooter ? <CompactFooter /> : <ExpandedFooter />}
		</div>
	);
};

PageOverview.propTypes = {
	classes: PropTypes.object.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	createIcon: PropTypes.func,
	name: PropTypes.string,
	subTitle: PropTypes.string,
	subNavData: PropTypes.oneOfType([
		PropTypes.shape({
			name: PropTypes.string,
			path: PropTypes.string,
			component: PropTypes.object,
		}),
		PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string,
				path: PropTypes.string,
				component: PropTypes.object,
			}),
		),
	]),
	bottomContent: PropTypes.object,
	parent: PropTypes.oneOfType([
		PropTypes.shape({ path: PropTypes.string, name: PropTypes.string }),
		PropTypes.arrayOf(
			PropTypes.shape({ path: PropTypes.string, name: PropTypes.string }),
		),
	]),
	favoritesDisplayName: PropTypes.string,
	isLoading: PropTypes.bool,
	showTitleWhileLoading: PropTypes.bool,
	loadingVariant: PropTypes.oneOf(['short', 'long']),
	hideFavorite: PropTypes.bool,
	breadCrumbProps: PropTypes.shape({
		showArrow: PropTypes.bool,
	}),
};

export { PageOverview };

export default compose(withStyles(styles))(PageOverview);

import { useRef, useEffect, useState, useCallback } from 'react';

/**
 * useState-like that can be "reset" with `setPristine`
 * @typedef {unknown} T
 * @param {T} initialState initial and presetine value
 * @param {boolean} [ignoreEmpty] avoid updating dirtyState when value is changed to `undefined` or `null`
 * @returns {[T, (value: T) => void, () => void]} [dirtyState, setDirtyState, setPristine]
 */
export default function useDirty(initialState, ignoreEmpty = true) {
	const [dirtyState, setDirtyState] = useState(initialState);

	// Prevent needless dependancy updates
	const initialStateRef = useRef();
	initialStateRef.current = initialState;

	const setPristine = useCallback(
		() => setDirtyState(initialStateRef.current),
		[],
	);

	// Prefer initialState and update dirtyState should it change
	useEffect(() => {
		const isEmpty = initialState === null || initialState === undefined;
		if (ignoreEmpty && isEmpty) return;
		setDirtyState(initialState);
	}, [initialState, ignoreEmpty]);

	return [dirtyState, setDirtyState, setPristine];
}

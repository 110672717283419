import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import useTheme from '@material-ui/core/styles/useTheme';
import Box from '@material-ui/core/Box';

const lwSelectWidth = { standard: 37.5, productConfig: 45 };

const LWSelect = ({
	options = [],
	onChange,
	variant = 'outlined',
	label: fieldLabel,
	value: inValue,
	disabled,
	multiple,
	isLoading,
	'data-testid': testId,
	input,
	helperText,
	width = lwSelectWidth.standard,
	fullWidth,
	BoxProps,
	size = 'medium',
	required,
	...SelectProps
}) => {
	const theme = useTheme();
	const pxWidth = fullWidth ? '100%' : `${theme.spacing(width)}px`;
	const fieldValue = input?.value || inValue || (multiple ? [] : '');

	return (
		<Box width={`min(100%, ${pxWidth})`} {...BoxProps}>
			<TextField
				size={size}
				data-testid={testId}
				select
				disabled={disabled || isLoading}
				variant={variant}
				label={fieldLabel}
				value={
					multiple && !Array.isArray(fieldValue) ? [fieldValue] : fieldValue
				}
				onChange={(e) => {
					if (onChange) onChange(e.target.value);
					if (input?.onChange) input.onChange(e.target.value);
				}}
				component={Paper}
				elevation={0}
				helperText={helperText}
				InputProps={
					isLoading
						? {
								endAdornment: (
									<InputAdornment position="end">
										{/** avoids collision with arrow */}
										<Box pr={2}>
											<CircularProgress size={24} />
										</Box>
									</InputAdornment>
								),
						  }
						: {}
				}
				required={required}
				SelectProps={{
					multiple,
					renderValue: () =>
						multiple
							? options
									.filter(
										(elem) => fieldValue?.toString().indexOf(elem.value) > -1,
									)
									.map(({ label }) => label)
									.join(', ')
							: // if not multiple, then fieldValue is not an array.
							  options.find((elem) => elem?.value === fieldValue)?.label,
					...SelectProps,
				}}
			>
				{options.map(({ value, label, disabled: itemDisabled }) => (
					<MenuItem
						key={value || 'undefined'}
						value={value}
						disabled={itemDisabled}
					>
						{multiple && (
							<Checkbox
								color="primary"
								checked={fieldValue?.indexOf(value) > -1}
							/>
						)}
						{label}
					</MenuItem>
				))}
			</TextField>
		</Box>
	);
};

LWSelect.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
				.isRequired,
			label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
			disabled: PropTypes.bool,
		}),
	),
	onChange: PropTypes.func,
	variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
	label: PropTypes.string,
	disabled: PropTypes.bool,
	/** pass in theme units. */
	width: PropTypes.number,
	/** overrides width prop and sets width to 100% */
	fullWidth: PropTypes.bool,
	isLoading: PropTypes.bool,
	/** the size prop pass down to MUI's TextField */
	size: PropTypes.string,
};

export { lwSelectWidth };
export default LWSelect;

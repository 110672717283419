import React from 'react';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const testids = {
	CursorBox__Active: 'CursorBox__Active',
	CursorBox__Inactive: 'CursorBox__Inactive',
};
const SBox = styled(Box)`
	${({ $active }) => ($active ? 'cursor: pointer;' : '')}
`;

const CursorBox = ({ active = true, children, ...rest }) => (
	<SBox
		$active={active}
		{...rest}
		data-testid={
			active ? testids.CursorBox__Active : testids.CursorBox__Inactive
		}
	>
		{children}
	</SBox>
);

CursorBox.propTypes = {
	active: PropTypes.bool,
};

export { testids };
export default CursorBox;

import actions from './actions';

const initialState = {
	showValue: 'All',
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.TEMPLATES_SET_SHOW_VALUE: {
			return {
				...state,
				showValue: action.showValue,
			};
		}
		case actions.TEMPLATES_CLEAR: {
			return initialState;
		}
		default:
			return state;
	}
};
export { initialState };
export default reducer;

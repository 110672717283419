import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import ProjectsIcon from '@material-ui/icons/LayersRounded';
import LWCard, {
	types as lwCardTypes,
	headerAlignment as lwCardHeaderAlignment,
} from 'components/common/LWCard';

import LWButton from 'components/common/LWButton';
import EditHostnameDialog from 'containers/pages/servers/details/dialogs/EditHostnameDialog';
import AssetStatus from 'components/Asset/Status';
import DataGridItem from 'components/common/DataGridItem';
import LWTypography from 'components/common/LWTypography';

import dialogActions from 'modules/dialogs/actions';
import formatBytes from 'utility/formatBytes';

import SideActions from './SideActions';
import FooterActions from './FooterActions';
import DestroyOpenStack from './DestroyOpenStack';

const SLWCard = styled(LWCard)`
	.MuiCardContent-root {
		padding: ${({ theme }) => theme.spacing(2)}px;
	}
`;

const SHeaderContainer = styled(Grid)`
	@media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const SHeaderButtonContainer = styled(Grid)`
	@media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
		flex-direction: column;
	}
	@media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
		flex-wrap: nowrap;
	}
`;

const SHeaderNameStatusContainer = styled(Grid)`
	@media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
		flex-direction: column;
		align-items: flex-start;
	}
	@media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
		flex-wrap: nowrap;
	}
`;

const SHeaderNameContainer = styled(Grid)`
	overflow: auto;
	max-width: 100%;
`;

const SHeaderName = styled(Box)`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	@media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
		overflow-wrap: break-word;
		white-space: normal;
	}
`;

const SHeaderButton = styled(LWButton)`
	@media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
		margin: ${({ theme }) => theme.spacing(1)}px;
	}
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: ${({ theme }) => theme.spacing(1)}px 0;
	text-transform: unset;
	padding: ${({ theme }) => theme.spacing(0.5, 1)};
	height: 28px;
	color: ${({ theme }) => theme.palette.primary.contrastText};
	border-color: ${({ theme }) => theme.palette.primary.contrastText};
	border-radius: 4px;
	background-color: transparent;
`;

const SUnderline = styled.span`
	text-decoration: underline;
`;

const SColumns = styled(Box)`
	@media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
		column-count: 2;
	}
`;

const SDataGridItem = styled(DataGridItem)`
	margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`;

const SSideActionsContainer = styled(Grid)`
	@media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
		flex-direction: column;
	}
`;

/**
 * @param {import('modules/queries/asset/useDetails').AssetDetails} props
 * */
const ServerDetails = (props) => {
	const {
		uniq_id: uniqId,
		managedLevel,
		os,
		assetType,
		domain,
		status,
		powerInfo,
		ip,
		isPrivateParent,
		isBeyondHosting,
		isOpenStack,
		isVmware,
		privateParent,
		cpu,
		ram,
		hardDrives,
		capabilities,
		project_id: projectId,
		project_name: projectName,
		featureDetails,
		zone,
		type,
	} = props;
	const dispatch = useDispatch();
	const history = useHistory();
	const [isEditHostnameDialogOpen, setIsEditHostnameDialogOpen] = useState(
		false,
	);

	const name = domain || 'Server';

	const showServerActions = !isPrivateParent;
	const zoneName = zone?.name;
	const regionName = zone?.region.name;
	const regionNameWithZone =
		zoneName && regionName ? `${regionName} - ${zoneName}` : '';

	const openDialog = ({
		title,
		content,
		dialogProps,
		contentKey,
		contentProps,
	}) =>
		dispatch(
			dialogActions.open({
				title,
				content,
				dialogProps,
				contentKey,
				contentProps,
			}),
		);

	const gridData = [];
	if (ip) {
		gridData.push({
			title: 'Primary IP',
			value: ip,
		});
	}
	if (regionNameWithZone) {
		gridData.push({
			title: 'Zone',
			value: regionNameWithZone,
		});
	}
	if (os) {
		gridData.push({
			title: 'OS',
			value: os,
		});
	}
	if (managedLevel) {
		gridData.push({
			title: 'Mgmt Level',
			value: managedLevel,
		});
	}
	if (hardDrives) {
		gridData.push(...hardDrives);
	}
	if (ram && typeof ram === 'number') {
		gridData.push({
			title: 'RAM',
			value: formatBytes(ram * 1e6).withUnit,
		});
	}
	if (ram && typeof ram === 'string') {
		gridData.push({
			title: 'RAM',
			value: ram,
		});
	}
	if (cpu) {
		gridData.push({
			title: 'CPU',
			value: cpu,
		});
	}
	if (uniqId) {
		gridData.push({ title: 'Uniq ID', value: uniqId });
	}
	const headerActions = [];

	if (capabilities?.autoRename || capabilities?.manualRename) {
		headerActions.push({
			name: 'Edit Hostname',
			sendEvent: true,
			onClick: () => setIsEditHostnameDialogOpen(true),
		});
	}

	if (
		assetType === 'cloud' &&
		!isVmware &&
		!isOpenStack &&
		!isBeyondHosting &&
		!isPrivateParent
	) {
		headerActions.push({
			name: 'Clone Server',
			sendEvent: true,
			onClick: () =>
				openDialog({
					title: 'Clone VPS?',
					contentKey: 'CloneServerModalContents',
					contentProps: { uniqId, domain, productCode: type, featureDetails },
				}),
		});
	}

	if (capabilities?.cpanelLogin && featureDetails?.cPanelLicenseTier) {
		headerActions.push({
			name: 'cPanel License',
			sendEvent: true,
			onClick: () => history.push(`${history.location.pathname}/cpanel`),
		});
	}

	if (status === 'Active') {
		headerActions.push({
			name: 'Destroy Server',
			sendEvent: true,
			onClick: isOpenStack
				? () =>
						openDialog({
							title: 'Destroy a Volume',
							content: <DestroyOpenStack {...props} />,
							dialogProps: { color: 'danger' },
						})
				: () => history.push(`/servers/${assetType}/${uniqId}/destroy`),
		});
	}

	// Need to determine how much space to set aside for buttons in the header
	// They require about 2 spaces for each
	let headerButtonCount = 0;
	if (projectId) headerButtonCount += 1;
	if (privateParent) headerButtonCount += 1;
	const headerButtonSpace = headerButtonCount * 2;

	return (
		<SLWCard
			title={
				<SHeaderContainer container alignItems="center">
					<SHeaderNameStatusContainer
						item
						xs={12}
						md={12 - headerButtonSpace} // Need more space for the buttons if there is a project or a child button
						container
						alignItems="center"
					>
						<SHeaderNameContainer item>
							<SHeaderName pr={1}>{name}</SHeaderName>
						</SHeaderNameContainer>
						<Grid item>
							<AssetStatus
								uniqId={uniqId}
								subaccntStatus={status}
								listView={false}
								powerInfo={powerInfo}
							/>
						</Grid>
					</SHeaderNameStatusContainer>
					<SHeaderButtonContainer
						item
						xs={12}
						md={headerButtonSpace === 0 ? false : headerButtonSpace} // Need more space for the buttons if there is a project or a child button (also breakpoints can't be 0 width)
						container
						justify="flex-end"
					>
						{privateParent && (
							<Grid item>
								<SHeaderButton
									variant="outlined"
									onClick={() => history.push(`/go/${privateParent?.uniq_id}`)}
								>
									Child of:&nbsp;
									<SUnderline>{privateParent?.domain}</SUnderline>
								</SHeaderButton>
							</Grid>
						)}
						{projectId && (
							<Grid item>
								<SHeaderButton
									variant="outlined"
									startIcon={<ProjectsIcon />}
									onClick={() =>
										history.push(`/projects/dashboard/${projectId}`)
									}
								>
									<SUnderline>{projectName}</SUnderline>
								</SHeaderButton>
							</Grid>
						)}
					</SHeaderButtonContainer>
				</SHeaderContainer>
			}
			headerActions={headerActions}
			type={lwCardTypes.DARK}
			headerAlign={lwCardHeaderAlignment.LEFT}
		>
			<Grid container>
				<Grid
					item
					xs={12}
					md={showServerActions ? 9 : 12}
					lg={showServerActions ? 10 : 12}
				>
					<SColumns data-testid="ServerDetails__gridData">
						{domain &&
							gridData.map(({ title, value }) => (
								<SDataGridItem title={title} key={title}>
									<LWTypography>{value}</LWTypography>
								</SDataGridItem>
							))}
					</SColumns>
				</Grid>
				{showServerActions && (
					<Grid container item xs={12} md={3} lg={2}>
						<Hidden smDown>
							<Grid container item xs={2} justify="center">
								<Divider orientation="vertical" />
							</Grid>
						</Hidden>
						<SSideActionsContainer container item xs={12} md={10} spacing={1}>
							<SideActions {...props} />
						</SSideActionsContainer>
					</Grid>
				)}
			</Grid>
			<FooterActions {...props} />
			<EditHostnameDialog
				{...props}
				isOpen={isEditHostnameDialogOpen}
				onClose={() => setIsEditHostnameDialogOpen(false)}
			/>
		</SLWCard>
	);
};

ServerDetails.propTypes = {
	uniq_id: PropTypes.string.isRequired,
	domain: PropTypes.string.isRequired,
	status: PropTypes.string.isRequired,
	ip: PropTypes.string,
	managedType: PropTypes.string.isRequired,
	managedLevel: PropTypes.string.isRequired,
	os: PropTypes.string.isRequired,
	cpu: PropTypes.string.isRequired,
	ram: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	assetType: PropTypes.string.isRequired,
	hardDrives: PropTypes.array.isRequired,
	capabilities: PropTypes.object.isRequired,
	privateParent: PropTypes.object,
	isPrivateParent: PropTypes.bool,
	isBeyondHosting: PropTypes.bool,
	isOpenStack: PropTypes.bool,
	isVmware: PropTypes.bool,
};

export default ServerDetails;

import { createSelector } from 'reselect';
import { alertsRelations } from 'utility/constants/alerts';
import selectors from './selectors';

/**
 * @typedef DestinationObject - describes what is being forbidden
 * @property {string} displayValue - a readable string that was used to create the message.
 * @property {number} key - a ptok
 * @property {number} value - a ptov
 */

/**
 * @typedef SourceObject - describes wat is doing the forbidding
 * @property {number} key - a ptok
 * @property {number} value - a ptov
 */

/** @typedef {'forbids'|'requires'} Relation */

/**
 * @typedef AlertsDetails
 * @property {DestinationObject} destination
 * @property {string} message - error message describing why the alert exists.
 * @property {Relation} relation
 * @property {SourceObject} source
 */

/**
 * Constructs a single alert detail.
 * @param {Object} param0
 * @param {Relation} param0.relation
 * @param {number[]} param0.ptovArray
 * @param {Object.<string, import('./selectors').PtovDetails>} param0.ptovToDetailsMap
 * @param {number|string} param0.sourcePtokId
 * @param {number|string} param0.sourcePtovId
 * @returns {AlertsDetails}
 */
const getDetail = ({
	relation,
	ptovArray,
	ptovToDetailsMap,
	sourcePtokId,
	sourcePtovId,
}) => {
	const destination = [];

	const { displayValue: sourcePtovDisplayValue } = ptovToDetailsMap[
		sourcePtovId
	];
	ptovArray.forEach((value) => {
		const { ptokId: key, displayValue: desinationDisplayValue } =
			ptovToDetailsMap[value] || {};
		// if ptov does not exist, do not continue
		if (typeof key === 'undefined') return;
		destination.push({ key, value, displayValue: desinationDisplayValue });
	});
	return {
		destination,
		source: [
			{
				key: Number(sourcePtokId),
				value: Number(sourcePtovId),
			},
		],
		relation,
		message: `${sourcePtovDisplayValue} ${relation.replace(
			/_/,
			' ',
		)}: ${destination.map(({ displayValue }) => displayValue).join(', ')}`,
	};
};

/**
 * Gets the array of AlertsDetails
 * @param {Object} param0
 * @param {PtokData} param0.ptokData
 * @param {Object.<string, import('./selectors').PtovDetails>} param0.ptovToDetailsMap
 * @returns {AlertsDetails[]}
 */
const getDetailsArray = ({ ptokData, ptovToDetailsMap }) => {
	const detailsArray = [];
	Object.entries(ptokData).forEach(([sourcePtokId, { values }]) => {
		Object.entries(values).forEach(([sourcePtovId, { acls }]) => {
			if (acls) {
				Object.entries(acls).forEach(([relation, ptovArray]) => {
					if (
						relation === alertsRelations.forbids ||
						relation === alertsRelations.requires
					) {
						detailsArray.push(
							getDetail({
								relation,
								ptovArray,
								ptovToDetailsMap,
								sourcePtokId,
								sourcePtovId,
							}),
						);
					}
				});
			}
		});
	});
	return detailsArray;
};

/** Gets a map linking each product code to its aclData */
const getAlertsDetailsArrayMap = createSelector(
	selectors.getProductsData,
	selectors.getPtovToDetailsMap,
	/** @returns {Object.<string, AlertsDetails[]>} */
	(productsData, ptovToDetailsMap) => {
		const alertsDetailsArrayMap = {};
		Object.entries(productsData).forEach(([productCode, productData]) => {
			alertsDetailsArrayMap[productCode] = getDetailsArray({
				ptokData: productData.ptokData,
				ptovToDetailsMap,
			});
		});

		return alertsDetailsArrayMap;
	},
);

/**
 * @typedef Alert
 * @property {string} displayValue - A readable string describing the value being forbidden
 * @property {number} key - the ptok whose values is being forbidden.
 * @property {string} message - a readable reason for the value being forbidden.
 * @property {'forbids'|'requires'} relation
 * @property {number} value - the ptov being forbidden.
 */

/**
 * Gets a map linking productKeys to only the alerts that disable specific options from the configs.
 */
const getApplicableAlertsMap = createSelector(
	selectors.getSelectedProductOptions,
	selectors.getProductKeyToCodeMap,
	getAlertsDetailsArrayMap,
	/** @returns {Object.<string, Alert[]>} */
	(selectedProductOptions, keyToCodeMap, alertsDetailsArray) => {
		const applicableAlertsMap = {};
		Object.entries(selectedProductOptions).forEach(
			([productKey, currentSelections]) => {
				const applicableAlerts = [];
				const productCode = keyToCodeMap[productKey];
				if (!alertsDetailsArray[productCode]) return;
				alertsDetailsArray[productCode].forEach(
					({ source = [], destination = [], relation, message }) => {
						// Only add the alerts for things that are currently selected.
						if (
							!source.some(
								({ key, value }) => currentSelections?.[key]?.value === value,
							)
						) {
							return; // Do not add this to the array.
						}

						destination.forEach(({ key, ...rest }) => {
							// exclude forbidding siblings. That would be silly.
							if (source.some(({ key: sourceKey }) => sourceKey === key))
								return; // Do not add this to the array.
							applicableAlerts.push({ key, relation, message, ...rest });
						});
					},
				);
				applicableAlertsMap[productKey] = applicableAlerts;
			},
		);
		return applicableAlertsMap;
	},
);

/** Returns a map of ptoks containing forbidden ptovs with the reason they are forbidden for each productKey. */
const getForbiddenOptionsMap = createSelector(
	selectors.getProductsData,
	selectors.getProductKeyToCodeMap,
	getApplicableAlertsMap,
	/** @returns {Object.<string, Object.<string, Object.<string, Alert>>>} */
	(productsData, keyToCodeMap, applicableAlertsMap) => {
		const forbiddenOptionsMap = {};
		Object.entries(applicableAlertsMap).forEach(
			([productKey, applicableAlerts]) => {
				const currentProductData = productsData[keyToCodeMap[productKey]];
				const currentForbiddenOptionsMap = Object.fromEntries(
					applicableAlerts.map(({ key }) => [key, {}]),
				);
				applicableAlerts.forEach((alert) => {
					const { key, value, relation } = alert;
					switch (relation) {
						case alertsRelations.forbids: {
							currentForbiddenOptionsMap[key][value] = alert;
							break;
						}
						case alertsRelations.requires: {
							const options = Object.keys(
								currentProductData.ptokData[key].values,
							);
							options.forEach((optionValue) => {
								if (Number(optionValue) !== value)
									currentForbiddenOptionsMap[key][optionValue] = alert;
							});
							break;
						}
						default:
					}
				});
				forbiddenOptionsMap[productKey] = currentForbiddenOptionsMap;
			},
		);

		return forbiddenOptionsMap;
	},
);

/** Returns the forbidden options only for the product being acted upon. */
const getActiveForbiddenOptions = createSelector(
	selectors.getActiveProductKey,
	getForbiddenOptionsMap,
	/** @returns {Object.<string, Object.<string, Alert>>} */
	(activeProductKey, forbiddenOptionsMap) =>
		forbiddenOptionsMap[activeProductKey],
);

/** Returns the first non-forbidden option for each ptok */
const getFirstValidValueMap = createSelector(
	selectors.getRawProductsData, // Edge case: if the acl code selects something that doesn't exist in getProductsData, updateOptionActiveStates will select something else. That's why this is fine.
	selectors.getProductKeyToCodeMap,
	getForbiddenOptionsMap,
	/** @returns {Object.<string, Object.<string, number>>} */
	(productsData, keyToCodeMap, forbiddenOptionsMap) => {
		const firstValidValueMap = {};
		Object.entries(forbiddenOptionsMap).forEach(
			([productKey, currentForbiddenOptionsMap]) => {
				const currentFirstValidValueMap = {};
				const currentProductData = productsData[keyToCodeMap[productKey]];
				Object.entries(currentProductData.ptokData).forEach(
					([ptokId, { values }]) => {
						const valuesEntries = Object.entries(values).sort(
							([, { displayOrder: a }], [, { displayOrder: b }]) => a - b,
						);

						[currentFirstValidValueMap[ptokId]] =
							valuesEntries.find(
								([ptovId]) => !currentForbiddenOptionsMap?.[ptokId]?.[ptovId],
							) || [];
					},
				);
				firstValidValueMap[productKey] = currentFirstValidValueMap;
			},
		);
		return firstValidValueMap;
	},
);

const aclSelectors = {
	getActiveForbiddenOptions,
	getAlertsDetailsArrayMap,
	getApplicableAlertsMap,
	getForbiddenOptionsMap,
	getFirstValidValueMap,
};

export default aclSelectors;

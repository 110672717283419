import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.network.dns.reverse.delete;

const { actions, reducer, sagas, selectors, additionalSagas } = createAPIModule(
	{
		getStateSlice,
		actionType: 'NETWORK_DNS_REVERSE_DELETE',
		url: '/network/dns/reverse/delete.json',
	},
);

export {
	actions as dnsReverseDeleteActions,
	selectors as dnsReverseDeleteSelectors,
	reducer,
	sagas,
	additionalSagas as dnsReverseDeleteAdditionalSagas,
};

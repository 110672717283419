import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.utilities.info.ping;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'UTILITIES_INFO_PING',
	method: 'POST',
	url: '/utilities/info/ping.json',
});

export { actions, reducer, sagas, selectors };

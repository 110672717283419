import { useQuery } from '@tanstack/react-query';
import api from 'modules/queries/api';

/**
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetConnectionsParamsRawI} params
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 */
const useAssetConnections = (params, { enabled } = {}) =>
	useQuery({
		queryKey: ['/asset/connections', params],
		queryFn: () => api.asset.apiRaw.connections(params),
		enabled,
	});

export default useAssetConnections;

import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.market.cart.admin.quote.create;

const { actions, reducer, sagas, selectors, additionalSagas } = createAPIModule(
	{
		getStateSlice,
		actionType: 'MARKET_CART_ADMIN_QUOTE_CREATE',
		url: '/market/cart/admin/quote/create.json',
	},
);

export { actions, reducer, sagas, selectors, additionalSagas };

import React from 'react';
import { useDispatch } from 'react-redux';
import unblockIPDialogActions from 'modules/unblockIPDialog/unblockIPDialogActions';
import { SLWButton } from '../actionStyles';

/** @param {import('modules/queries/asset/useDetails').AssetDetails} props */
const CPanelActions = (props) => {
	const dispatch = useDispatch();
	const { status } = props;

	const handleClick = () => {
		dispatch(unblockIPDialogActions.setServerDetails(true, props));
	};

	return (
		<>
			<SLWButton
				onClick={handleClick}
				color="secondary"
				variant="outlined"
				disabled={status !== 'Active'}
			>
				Unblock IP
			</SLWButton>
		</>
	);
};

export default CPanelActions;

import { createSelector } from 'reselect';
import {
	email as getEmail,
	isMasquerade as getIsMasquerade,
	isBasketAdmin as getIsBasketAdmin,
} from 'modules/auth/authSelectors';
import gdprWhiteList from 'utility/geoLocation/gdprWhiteList';
import { selectors as appConfigSelectors } from 'modules/appConfig';
import { cartDetailsSelectors } from 'modules/api/market/cart/detailsModule';

const getStateSlice = (state) => state.cartStack;

const sendGtmData = createSelector(
	getStateSlice,
	getIsMasquerade,
	getIsBasketAdmin, // This wasn't in the spec, but it wouldn't make sense to leave it out.
	(slice, isMasquerade, isBasketAdmin) =>
		!!gdprWhiteList[slice.countryCode] && !isMasquerade && !isBasketAdmin,
);

const cartData = createSelector(
	getEmail,
	appConfigSelectors.getSiteId,
	cartDetailsSelectors.getNativeItems,
	cartDetailsSelectors.getUuid,
	cartDetailsSelectors.getTotalsByItemUuid,
	cartDetailsSelectors.getDueToday,
	(email, siteId, cartItems, cartUuid, totalsByItemUuid, dueToday) => {
		const cartStackItems = cartItems.map(
			({
				code: productID,
				details: { title = '', description = '', subTitle = '' },
				uuid: itemUuid,
			}) => [
				'setCartItem',
				{
					productID,
					productName: title || description || productID,
					productDescription: subTitle,
					quantity: 1,
					productPrice: totalsByItemUuid[itemUuid]?.price,
					productURL: `${window.location.origin}/shop/config/${itemUuid}`,
					productImageURL: '',
				},
			],
		);
		return [
			['setSiteID', siteId],
			['setCartTotal', dueToday],
			[
				'setDataItem',
				{ cartLink: `${window.location.origin}/cart/${cartUuid}` },
			],
			['setEmail', email],
			['setCartItem', {}],
			...cartStackItems,
		];
	},
);

export default { sendGtmData, cartData };

import React, { lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';
import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';
import BasketAdminRoute from 'utility/routing/BasketAdminRoute';
import Loading from 'components/common/Loading';
import { roles } from 'utility/constants/roles';

const Cart = lazy(() => import('containers/pages/shop/Cart'));
const CartText = lazy(() => import('containers/pages/shop/CartText'));

const CartRouter = () => {
	return (
		<Suspense fallback={<Loading />}>
			<Switch>
				<BasketAdminRoute
					name="Quotes"
					path="/cart/cart-text"
					component={CartText}
				/>
				<RoleRestrictedRoute
					exact
					whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
					path="/cart/:basketUuid?"
					component={Cart}
				/>
				<RoleRestrictedRoute
					exact
					whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
					path="/cart/saved/:basketUuid"
					component={Cart}
				/>
			</Switch>
		</Suspense>
	);
};

export default CartRouter;

// @ts-check
import { useQuery } from '@tanstack/react-query';
import api from 'modules/queries/api';

/**
 * @param {import('club-sauce/public/storage/block/cluster/index.raw').LWApiPublicStorageBlockClusterListParamsRawI} params
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 * */
const useStorageBlockClusterList = (params, { enabled } = {}) =>
	useQuery({
		queryKey: ['/storage/block/cluster/list'],
		queryFn: () => api.storage.block.cluster.apiRaw.list(params),
		enabled,
	});

/**
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 * */
export const useAllStorageBlockClusterList = ({ enabled } = {}) =>
	useStorageBlockClusterList({ page_num: 1, page_size: 9999 }, { enabled });

export default useStorageBlockClusterList;

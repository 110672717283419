import React from 'react';

function Paper(props) {
	return (
		<svg width={24} height={24} viewBox="0 0 24 24" {...props}>
			<g data-name="Icon-FileHost">
				<path data-name="Path 98" d="M0 0h24v24H0z" fill="none" />
				<path
					data-name="Path 99"
					d="M6 2a2 2 0 00-1.99 2L4 20a2 2 0 001.99 2H18a2.006 2.006 0 002-2V8.83a1.966 1.966 0 00-.59-1.41l-4.83-4.83A1.966 1.966 0 0013.17 2H6zm7 6V3.5L18.5 9H14a1 1 0 01-1-1z"
					fill="#007199"
				/>
			</g>
		</svg>
	);
}

export default Paper;

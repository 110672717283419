import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { additionalSagas as volumeAttachAdditionalSagas } from 'modules/api/storage/block/volume/attachModule';
import { additionalSagas as volumeCreateAdditionalSagas } from 'modules/api/storage/block/volume/createModule';
import { additionalSagas as detachModuleAdditionalSagas } from 'modules/api/storage/block/volume/detachModule';

import snackbarActions from 'modules/snackbar/snackbarActions';
import { waitForUniqId } from 'modules/notifications/sagas';

import actions from './actions';

function* attachVolume({ payload: { serverUniqId, volumeUniqId } }) {
	yield put(actions.addVolumeBeingUpdated(volumeUniqId));

	const { data, error } = yield call(
		volumeAttachAdditionalSagas.fetchDirectly,
		{ to: serverUniqId, uniq_id: volumeUniqId },
	);

	if (error) {
		yield put(
			snackbarActions.pushMessage({
				message: 'Failed to attach Cloud Block Storage.',
				variant: 'error',
			}),
		);

		yield put(actions.removeVolumeBeingUpdated(volumeUniqId));

		return;
	}

	if (data) {
		yield call(waitForUniqId, {
			category: 'Attach Volume',
			uniqId: serverUniqId,
		});

		yield put(actions.removeVolumeBeingUpdated(volumeUniqId));

		yield put(
			snackbarActions.pushMessage({
				message: 'Cloud Block Storage successfully attached.',
				variant: 'success',
			}),
		);
	}
}

function* createVolume({ payload: { domain, size, serverUniqId, region } }) {
	yield put(actions.addServerBeingAttachedTo(serverUniqId));

	yield put(
		snackbarActions.pushMessage({
			message: 'Cloud Block Storage creation initiated.',
			variant: 'info',
		}),
	);

	const { data, error } = yield call(
		volumeCreateAdditionalSagas.fetchDirectly,
		{ domain, size, attach: serverUniqId, region },
	);

	if (error) {
		yield put(
			snackbarActions.pushMessage({
				message: 'Failed to create Cloud Block Storage',
				variant: 'error',
			}),
		);

		yield put(actions.removeServerBeingAttachedTo(serverUniqId));

		return;
	}

	if (data) {
		if (serverUniqId) {
			yield put(push(`/servers/cloud/${serverUniqId}/storage`));
		}

		yield put(actions.removeServerBeingAttachedTo(serverUniqId));

		yield put(
			snackbarActions.pushMessage({
				message: 'Cloud Block Storage successfully created.',
				variant: 'success',
			}),
		);
	}
}

function* detachVolume({ payload: { serverUniqId, volumeUniqId } }) {
	yield put(actions.addVolumeBeingUpdated(volumeUniqId));

	const { data, error } = yield call(
		detachModuleAdditionalSagas.fetchDirectly,
		{ uniq_id: volumeUniqId, detach_from: serverUniqId },
	);

	if (error) {
		yield put(
			snackbarActions.pushMessage({
				message: 'Failed to detach Cloud Block Storage',
				variant: 'error',
			}),
		);

		yield put(actions.removeVolumeBeingUpdated(volumeUniqId));

		return;
	}

	if (data) {
		yield call(waitForUniqId, {
			category: 'Detach Volume',
			uniqId: serverUniqId,
		});

		yield put(actions.removeVolumeBeingUpdated(volumeUniqId));

		yield put(
			snackbarActions.pushMessage({
				message: 'Cloud Block Storage successfully detached.',
				variant: 'success',
			}),
		);
	}
}

function* watchActions() {
	yield takeEvery(actions.ATTACH_VOLUME, attachVolume);
	yield takeEvery(actions.CREATE_VOLUME, createVolume);
	yield takeEvery(actions.DETACH_VOLUME, detachVolume);
}

export default function* saga() {
	yield call(watchActions);
}

import React from 'react';
import PropTypes from 'prop-types';

// icons
import { ReactComponent as Support } from 'images/new-icons/support.svg';
import Menu from '@material-ui/icons/MenuRounded';
import ExpandMore from '@material-ui/icons/ExpandMoreRounded';
import { ReactComponent as Notifications } from 'images/new-icons/alerts.svg';
import { ReactComponent as Home } from 'images/new-icons/home.svg';
import { ReactComponent as Servers } from 'images/new-icons/servers.svg';
import { ReactComponent as Domains } from 'images/new-icons/domains.svg';
import { ReactComponent as Services } from 'images/new-icons/services.svg';
import { ReactComponent as Shop } from 'images/new-icons/shop.svg';
import Billing from '@material-ui/icons/MonetizationOn';
import { ReactComponent as Account } from 'images/new-icons/account.svg';
import { ReactComponent as Projects } from 'images/new-icons/projects.svg';
import Star from '@material-ui/icons/StarRounded';
// Use this instead once we have MUI 5.
// import Logout from '@mui/icons-material/LogoutRounded';
import { ReactComponent as Logout } from 'images/new-icons/logout.svg';

import styled from 'styled-components';

const SIcon = (svg) => {
	const Icon = styled(svg)`
		display: flex;
		padding: 6px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 8px;
		border-radius: 6px;
		box-sizing: unset;
		width: 17px;
		height: 16px;
		overflow: visible;
	`;
	return Icon;
};

const SIconActive = (svg) => {
	const Icon = styled(svg)`
		background: ${({ theme }) => theme.palette.primary.main};
		display: flex;
		padding: 6px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 8px;
		border-radius: 6px;
		box-sizing: unset;
		color: #f5f5f5;
		width: 17px;
		height: 16px;
		overflow: visible;
	`;
	return Icon;
};

const support = 'support';
const supportActive = 'supportActive';
const person = 'person';
const menu = 'menu';
const expandMore = 'expandMore';
const notifications = 'notifications';
const home = 'home';
const homeActive = 'homeActive';
const servers = 'servers';
const serversActive = 'serversActive';
const domain = 'domain';
const domainActive = 'domainActive';
const services = 'services';
const servicesActive = 'servicesActive';
const shop = 'shop';
const shopActive = 'shopActive';
const billing = 'billing';
const account = 'account';
const accountActive = 'accountActive';
const projects = 'projects';
const projectsActive = 'projectsActive';
const logout = 'logout';
const star = 'star';

const iconKeys = {
	support,
	person,
	menu,
	expandMore,
	notifications,
	home,
	homeActive,
	servers,
	serversActive,
	domain,
	domainActive,
	services,
	servicesActive,
	shop,
	shopActive,
	billing,
	account,
	accountActive,
	projects,
	projectsActive,
	logout,
	star,
};

/** @typedef {typeof iconKeys} IconKeysT */

const iconMap = {
	[support]: SIcon(Support),
	[supportActive]: SIconActive(Support),
	[person]: SIcon(Account),
	[menu]: Menu,
	[expandMore]: ExpandMore,
	[notifications]: SIcon(Notifications),
	[home]: SIcon(Home),
	[homeActive]: SIconActive(Home),
	[servers]: SIcon(Servers),
	[serversActive]: SIconActive(Servers),
	[domain]: SIcon(Domains),
	[domainActive]: SIconActive(Domains),
	[services]: SIcon(Services),
	[servicesActive]: SIconActive(Services),
	[shop]: SIcon(Shop),
	[shopActive]: SIconActive(Shop),
	[billing]: Billing,
	[account]: SIcon(Account),
	[accountActive]: SIconActive(Account),
	[projects]: SIcon(Projects),
	[projectsActive]: SIconActive(Projects),
	[logout]: SIcon(Logout),
	[star]: Star,
};

const NavIcon = ({ iconKey, active = '' }) => {
	const Component = iconMap[iconKey + active];
	if (!Component) {
		// eslint-disable-next-line no-console
		console.error(`The iconKey Key, ${iconKey} could not be found.`);
		return null;
	}
	return <Component fontSize="large" />;
};

NavIcon.propTypes = {
	/** The key to the icon being used. Using keys so that we can define the icon from a selector */
	iconKey: PropTypes.oneOf(Object.keys(iconKeys)).isRequired,
	/** If string is 'Active' the icon will use the active styling */
	active: PropTypes.string,
};

export { iconKeys };
export default NavIcon;

import { createSelector } from 'reselect';
import get from 'lodash/get';

const getStateSlice = (state) => state.acronis;

const getDialogIsOpen = createSelector(
	getStateSlice,
	(acronis) => acronis.isDialogOpen,
);

const getError = createSelector(
	getStateSlice,
	(acronis) => acronis.error,
);

const getIsLoading = createSelector(
	getStateSlice,
	(acronis) => acronis.isLoading,
);

const getSelected = createSelector(
	getStateSlice,
	(acronis) => acronis.selected,
);

/**
 * Gets the first type capability type of the selected acronis product tile.
 * @returns {string | false}
 */
const getBackupTypeCapability = createSelector(
	getSelected,
	({ selectedObj }) => {
		return (
			get(selectedObj, 'destination.capabilities') &&
			Object.keys(get(selectedObj, 'destination.capabilities'))[0]
		);
	},
);

const getPageNum = createSelector(
	getStateSlice,
	({ pageNum }) => pageNum,
);

const getPageSize = createSelector(
	getStateSlice,
	({ pageSize }) => pageSize,
);

const acronisSelectors = {
	getDialogIsOpen,
	getError,
	getIsLoading,
	getPageNum,
	getPageSize,
	getStateSlice,
	getSelected,
	getBackupTypeCapability,
};

export default acronisSelectors;

import axios from 'axios';
import { selectors as appConfigSelectors } from 'modules/appConfig';
import { select } from 'redux-saga/effects';

export default function* axiosCreate() {
	const wwwHost = yield select(appConfigSelectors.getWwwHostname);
	const baseURL = `${wwwHost}/lw-api/`;
	return axios.create({
		baseURL,
		timeout: 60000,
		withCredentials: true,
	});
}

import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.server.start;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'SERVER_START',
	url: '/server/start.json',
});

export { actions, reducer, sagas, selectors };

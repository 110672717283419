// Session storage key names
const REDIRECT_PATH = 'Auth__redirectPath';
const OPP_ID = 'Auth__oppId';
const UUID_IS_FROM_URL = 'uuid__is__from__url';
const COUPON_CODE = 'coupon__code';

const AUTH_TOKEN_TIMEOUT_MS = 250;

export {
	REDIRECT_PATH,
	OPP_ID,
	UUID_IS_FROM_URL,
	COUPON_CODE,
	AUTH_TOKEN_TIMEOUT_MS,
};

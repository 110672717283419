import { combineReducers } from 'redux';
import { cartDiscountAddReducer } from './addModule';
import { cartDiscountBulkReducer } from './bulkModule';
import { cartDiscountModifyReducer } from './modifyModule';
import { cartDiscountRemoveReducer } from './removeModule';

export default combineReducers({
	add: cartDiscountAddReducer,
	bulk: cartDiscountBulkReducer,
	modify: cartDiscountModifyReducer,
	remove: cartDiscountRemoveReducer,
});

/**
 * @file Abstracts flatDetails logic from the rest of the product config sagas.
 */

import { put, select, call, all } from 'redux-saga/effects';
import getAclMessage from 'banana-stand/parsers/getAclMessage';
import { flatDetailsAdditionalSagas } from 'modules/api/product/flatDetailsModule';
import { takeSetOrError, waitIfLoading } from 'utility/redux/apiModuleHelpers';
import { cartDetailsSelectors } from 'modules/api/market/cart/detailsModule';
import {
	selectors as zoneListSelectors,
	actions as zoneListActions,
} from 'modules/api/network/zone/listModule';
import {
	actions as stormTemplateListActions,
	selectors as stormTemplateListSelectors,
} from 'modules/api/storm/template/listModule';
import { actions as resizeOptionsActions } from 'modules/server/resize/options';
import { cloneDeep } from 'lodash';
import privateParentSelectors from 'modules/server/resize/privateParent/selectors';
import { push } from 'connected-react-router';
import snackbarSaga from 'modules/snackbar/sagas/sagas';
import isUUID from 'validator/es/lib/isUUID';
import { snackbarVariants } from 'components/common/Snackbar';
import { selectors as assetDetailsSelectors } from 'modules/api/asset/detailsModule';
import { pickDefaultRegion } from 'utility/redux/selectorHelperFunctions/productConfig';
import { initStormConfigList } from 'modules/storm/sagas';
import { configKeys } from 'utility/constants/baskets';
import { isBasketAdmin as getIsBasketAdmin } from 'modules/auth';
import productConfigActions from './actions';
import productConfigSelectors from './selectors';
import aclSelectors from './aclSelectors';
import { initBackupsAndImages } from './backupsAndImagesSagas';

/**
 * Initializes revealedHiddenOptions for a given productKey
 * @param {object} param0
 * @param {string} param0.productKey
 */
function* initRevealedHiddenOptions({ productKey }) {
	// If there is already a hidden option in the config, go ahead and mark the ptok as revealed.
	const isBasketAdmin = yield select(getIsBasketAdmin);
	if (isBasketAdmin) {
		const options = (yield select(
			productConfigSelectors.getSelectedProductOptions,
		))[productKey];
		const ptokToIsHidden = yield select(
			productConfigSelectors.getPtokToIsHidden,
		);
		yield all(
			Object.keys(options)
				.filter((ptokId) => ptokToIsHidden[ptokId])
				.map((ptokId) =>
					put(
						productConfigActions.revealHiddenOptions({
							productKey,
							ptokId,
						}),
					),
				),
		);
	}
}

/**
 * Either reverts changes or inits previously selected options of an item's config from an already loaded `basket/details.json`.
 *
 * It retrieves the current config of the item from basket/details and applies it to `selectedProductOptions` (`./reducer.js`)
 * @param {Object} param0
 * @param {String} param0.uuid - the uuid of the basket item.
 * @returns {boolean} false if an error occurs
 */
function* initOptionsFromBasket({ uuid }) {
	const itemOptions = (yield select(cartDetailsSelectors.getItemsByUuid))[uuid]
		?.details?.options;

	if (!itemOptions) {
		yield call(snackbarSaga, {
			error: true,
			errorMessage: `No item details could be found for ${uuid}`,
		});
		return false;
	}

	const options = Object.fromEntries(
		itemOptions.map(
			({ ptok_id: ptokId, ptov_id: ptovId, num_units: numUnits }) => {
				const option = {
					active: false, // Initialize as false until loaded by updateOptionActiveStates
					value: ptovId,
				};
				// If this is 0, we need to show QuantityConfig.jsx.
				if (typeof numUnits === 'number') option.numUnits = numUnits;
				return [ptokId, option];
			},
		),
	);

	yield put(
		productConfigActions.patchSelectedProductOptions({
			productKey: uuid,
			options,
		}),
	);

	yield call(initRevealedHiddenOptions, { productKey: uuid });

	// If there are properties on the item, we need those to as they interact with some configs.
	const {
		details: { properties },
	} = (yield select(cartDetailsSelectors.getItemsByUuid))[uuid];

	yield put(
		productConfigActions.setProperties({ productKey: uuid, properties }),
	);

	// These functions may call eachother, and that's expected
	// eslint-disable-next-line no-use-before-define
	yield call(updateOptionActiveStates, { productKey: uuid });

	return true;
}

function* retrievePrivateParentProperties() {
	const parentProps = {};
	const parent = yield select(assetDetailsSelectors.getPrivateParentUniqId);
	if (parent) {
		// get the defaults for adding a VPS to a private parent from the resize module.
		yield put(resizeOptionsActions.setDeployOnto({ deployOnto: parent }));
		parentProps.parent = parent;
		parentProps.vcpu = yield select(privateParentSelectors.getInitialCpu);
		parentProps.memory = yield select(privateParentSelectors.getInitialRam);
		parentProps.diskspace = yield select(assetDetailsSelectors.storageSize);
	}
	return parentProps;
}

/**
 * sets the options for the loaded asset's respective product code.
 */
function* initOptionsFromAssetDetails() {
	const productCode = yield select(assetDetailsSelectors.productType);

	// Set the regions and zone for the product code.
	const region = yield select(assetDetailsSelectors.regionId);
	yield put(
		productConfigActions.patchSelectedProductRegions({
			productKey: productCode,
			region,
		}),
	);
	const zone = yield select(assetDetailsSelectors.getZoneId);

	// set private parent data if applicable
	const parentProps = yield call(retrievePrivateParentProperties);

	yield put(
		productConfigActions.setProperties({
			productKey: productCode,
			properties: { zone, ...parentProps },
		}),
	);

	const featureDetails = yield select(assetDetailsSelectors.getFeatureDetails);
	const options = Object.fromEntries(
		Object.values(featureDetails).map(
			({ num_units: numUnits, ptok_id: ptokId, ptov_id: ptovId }) => {
				const option = {
					active: false, // Initialize as false until loaded by updateOptionActiveStates
					value: ptovId,
				};
				// If this is 0, we need to show QuantityConfig.jsx.
				if (typeof numUnits === 'number') option.numUnits = numUnits;
				return [ptokId, option];
			},
		),
	);

	yield put(
		productConfigActions.patchSelectedProductOptions({
			productKey: productCode,
			options,
		}),
	);

	// eslint-disable-next-line no-use-before-define
	yield call(updateOptionActiveStates, { productKey: productCode });
}

/**
 * Resets (or inits) an item's `selectedProductOptions` (`./reducer.js`) to its defaults from the static data (`productsData`)
 *
 * This can be applied to an item saved to the basket or an item still being configured using the state slice, `selectedProductOptions`.
 * uuids reference something in the basket. productCode reference something that has not yet been saved (no uuid).
 * @param {Object} param0
 * @param {String} param0.productKey - Either a uuid or the productCode.
 * @param {Boolean} param0.resetGeos - Reset Region and Zone
 * @param {Boolean} param0.clearNonDefaultProperties - reset specific item properties (see `productConfig/reducer` for hardcoded properties that are removed)
 */
function* resetOptionsToDefaults({
	productKey,
	resetGeos,
	clearNonDefaultProperties,
}) {
	const productCode = (yield select(
		productConfigSelectors.getProductKeyToCodeMap,
	))[productKey];
	const productData = (yield select(productConfigSelectors.getProductsData))[
		productCode
	];
	if (!productData) {
		// eslint-disable-next-line no-console
		console.error(`No product data could be found for ${productKey}`);
	}

	if (clearNonDefaultProperties) {
		yield put(productConfigActions.clearNonDefaultProperties({ productKey }));
	}

	if (resetGeos) {
		const userDefaultRegion = yield select(zoneListSelectors.getDefaultRegion);
		const defaultRegion = pickDefaultRegion({
			productCode,
			userDefaultRegion,
			regionIdPrices: productData.regionIdPrices,
		});

		yield put(
			productConfigActions.patchSelectedProductRegions({
				productKey,
				region: defaultRegion,
			}),
		);
	}

	const optionKeysToGeo = (yield select(
		productConfigSelectors.getProductKeysToOptionKeysToGeo,
	))[productKey];

	const options = Object.fromEntries(
		Object.entries(productData.ptokData).map(
			([ptokId, { key, defaultValuesByGeo, values }]) => {
				const geo = optionKeysToGeo[key];
				const value = defaultValuesByGeo[geo];

				const option = {
					active: false, // Initialize as false until loaded by updateOptionActiveStates
					value,
				};
				const numUnits = values[value]?.numUnits;
				// If this is a number, we need to show QuantityConfig.jsx.
				if (numUnits) {
					option.numUnits = numUnits.defaultValue;
				}
				return [ptokId, option];
			},
		),
	);

	yield put(
		productConfigActions.patchSelectedProductOptions({
			productKey,
			options,
		}),
	);
	// These functions may call eachother, and that's expected
	// eslint-disable-next-line no-use-before-define
	yield call(updateOptionActiveStates, { productKey });
}

/**
 * Sets the zone property on a product/item if it requires a zone. Sets the user's default zone if that zone exists in the selected region, otherwise sets an available zone in the selected region.
 * @param {String} productKey
 */
function* initZone(productKey) {
	const productCode = (yield select(
		productConfigSelectors.getProductKeyToCodeMap,
	))[productKey];

	const needsZone = (yield select(
		productConfigSelectors.getProductCodesToNeedsZone,
	))[productCode];

	if (needsZone) {
		yield call(waitIfLoading, {
			actions: zoneListActions,
			selectors: zoneListSelectors,
		});
		const region = (yield select(
			productConfigSelectors.getAllSelectedProductRegions,
		))[productKey];

		const defaultZonesByRegion = yield select(
			zoneListSelectors.getDefaultZonesByRegion,
		);

		const value = defaultZonesByRegion[region];

		yield put(
			productConfigActions.addProperty({
				productKey,
				key: 'zone',
				value,
			}),
		);
	}
}

/**
 * Fetches template options if needed
 * @returns {boolean} ready status
 */
function* initStormTemplateList() {
	const isReady = yield select(stormTemplateListSelectors.hasData);
	if (isReady) return true;

	yield put(stormTemplateListActions.init({ page_size: 999 }));
	const templateRes = yield call(takeSetOrError, {
		actions: stormTemplateListActions,
		selectors: stormTemplateListSelectors,
	});

	if (templateRes.type === stormTemplateListActions.errorType) {
		return false;
	}

	return true;
}

/**
 * Init product/flatDetails for the given product code and persist parts of the data via `./reducer.js` (productsData)
 *
 * This also calls `resetOptionstoDefault` so that selectedProductOptions is initialized for the give product code unless skipReset is passed in.
 * @param {Object} param0
 * @param {string} param0.productCode - The product code to be fetched and persisted.
 * @param {boolean} [param0.quickAdd] - if true, should storeAllRes on fetch calls
 * @param {boolean} [param0.skipReset] - if true, the SelectedProductOptions will not reset to defaults.
 * @returns {boolean} false if an error occurs
 */
function* initializeFlatDetails({ productCode, quickAdd, skipReset }) {
	const productsData = yield select(productConfigSelectors.getProductsData);
	// only init if it doesn't exist.
	if (!productsData[productCode]) {
		const { error } = yield call(
			flatDetailsAdditionalSagas.fetchDirectly,
			{
				product_code: productCode,

				// Converts pricing for products billed
				// in increments other than monthly,
				// such as hourly.
				price_time_unit_override: 'month',
			},
			undefined,
			{ setAllRes: quickAdd },
		);

		if (error) {
			return false;
		}

		yield call(initZone, productCode);

		const keyStringToProductCodeToPtok = yield select(
			productConfigSelectors.getKeyStringToProductCodeToPtok,
		);

		const hasTemplateOption = Boolean(
			keyStringToProductCodeToPtok.Template?.[productCode],
		);

		const hasConfigIdOption = Boolean(
			keyStringToProductCodeToPtok.ConfigId?.[productCode],
		);

		const { templatesIsReady, configIdIsReady } = yield all({
			templatesIsReady: hasTemplateOption ? call(initStormTemplateList) : true,
			backupsAndImagesIsReady: hasTemplateOption
				? call(initBackupsAndImages)
				: true,
			configIdIsReady: hasConfigIdOption ? call(initStormConfigList) : true,
		});

		if (!templatesIsReady || !configIdIsReady) return false;
	}

	// Reset configs to defaults unless flag is set.
	if (!skipReset) {
		yield call(resetOptionsToDefaults, { productKey: productCode });
	}

	return true;
}

const checkIsPtovAvailableInGeo = ({ valuesData, ptovId, geo }) =>
	valuesData[ptovId]?.pricesByGeo[geo] !== undefined;

/**
 * Calculates and updates the active state of the given ptok under the given product key.
 * @param {object} param0
 * @param {ProductDataDetails} param0.productData
 * @param {ProductOptions} param0.productOptions
 * @param {OptionKeysToGeo} param0.optionKeysToGeo
 * @param {{msSqlNumUnits?: number, windowsLicensePtovId?: number | string}} param0.specialConfigOptions values that need to be set manually on certain options
 * @param {boolean} param0.isBasketAdmin
 * @param {object.<string boolean>} param0.ptokToIsHidden
 * @param {object.<string boolean>} param0.revealedHiddenOptions
 */

function getNewSelectionsObj({
	allowList,
	productData,
	productOptions,
	optionKeysToGeo,
	ptokToLocalDetailsMap,
	specialConfigOptions,
	ptokToIsHidden,
	revealedHiddenOptions,
}) {
	// A "local-global" variable is a helpful way of thinking about `returnOptions`
	const returnOptions = cloneDeep(productOptions);

	/**
	 * Creates the new selection for the given ptokId based upon the existing selections.
	 *
	 * This function is declared in the scope of another function to prevent the implementation of an impure function. Instead, we're using a higher scoped variable.
	 * @param {Object} param0
	 * @param {Number|String} param0.ptokId - the ptokId being audited.
	 * @returns {Object}
	 */
	function getNewSelectionObj({ ptokId }) {
		const { ptokData, ptokParentMap, rootPtoks, ptokChildMap } = productData;
		const { parentPtovId, key, defaultValuesByGeo, values } = ptokData[ptokId];

		const { canBeVisible, hasValueInBasket } =
			ptokToLocalDetailsMap?.[ptokId] || {};

		const isRootPtok = rootPtoks[ptokId];

		const geo = optionKeysToGeo[key];
		const defaultPtovId = defaultValuesByGeo[geo];

		const defaultValueAvailableInGeo = checkIsPtovAvailableInGeo({
			valuesData: values,
			ptovId: defaultPtovId,
			geo,
		});

		const selectedValue = returnOptions[ptokId]?.value;
		const valueAvailableInGeo = checkIsPtovAvailableInGeo({
			valuesData: values,
			ptovId: selectedValue,
			geo,
		});

		// Sometimes hidden or unavailable options have to be selected anyway (ConfigId: '0' for deploying to a PrivateParent)
		const availabilityOverride =
			allowList[ptokId] === selectedValue && selectedValue !== undefined;
		let ptovId =
			valueAvailableInGeo || availabilityOverride
				? selectedValue
				: ptokData[ptokId].defaultValuesByGeo[geo];

		if (
			key === configKeys.WindowsLicense &&
			specialConfigOptions?.windowsLicensePtovId
		) {
			ptovId = specialConfigOptions.windowsLicensePtovId;
		}

		let numUnits = returnOptions[ptokId]?.numUnits;

		if (typeof returnOptions[ptokId]?.numUnits !== 'number') {
			numUnits = values[ptovId]?.numUnits?.defaultValue;
		}

		if (
			typeof numUnits === 'number' &&
			key === configKeys.MsSQL &&
			specialConfigOptions?.msSqlNumUnits // If the product does not have a ConfigId, specialConfigOptions will not exist.
		) {
			numUnits = specialConfigOptions.msSqlNumUnits;
		}

		const parentPtokId = ptokParentMap[ptokId];
		const parentValueIdSelected =
			returnOptions[parentPtokId]?.value === parentPtovId;
		const parentIsActive = returnOptions[parentPtokId]?.active;

		// For admins, a root ptok can be active but hidden. Children of hidden roots should be inactive both to hide it from the UI and remove the ptok/ptov pair from any payloads.
		const parentIsHidden =
			// the api says it is hidden
			ptokToIsHidden[parentPtokId] &&
			// the extra options button has not been clicked
			!revealedHiddenOptions?.[parentPtokId] &&
			// there is no value for the parent ptok in the cart item
			!hasValueInBasket;

		const active =
			availabilityOverride ||
			(canBeVisible &&
				defaultValueAvailableInGeo &&
				(isRootPtok ||
					(parentValueIdSelected && parentIsActive && !parentIsHidden)));
		const childPtoks = ptokChildMap[ptokId] || [];

		returnOptions[ptokId] = { active, value: ptovId };
		if (typeof numUnits === 'number') returnOptions[ptokId].numUnits = numUnits;
		// This recursion covers the edge case of a parent id being a higher value than its child.
		for (let i = 0; i < childPtoks.length; i += 1) {
			const childPtok = childPtoks[i];
			getNewSelectionObj({
				ptokId: childPtok,
			});
		}
		return returnOptions;
	}

	Object.keys(productData?.ptokData || {}).forEach((ptokId) => {
		getNewSelectionObj({ ptokId });
	});

	return returnOptions;
}

/**
 * Audits and updates the active state of each option under the given productKey.
 *
 * This should be called anytime an individual value in selectedProductOptions is changed or the product is being initialized.
 * @param {Object} param0
 * @param {String} param0.productKey - The key for selecting an entry in selectedProductOptions.
 */
function* updateOptionActiveStates({ productKey }) {
	const productCode = (yield select(
		productConfigSelectors.getProductKeyToCodeMap,
	))[productKey];
	if (!productCode) return; // 404 should already be getting displayed.

	const hasCircularRef = (yield select(
		productConfigSelectors.getProductCodeToHasCircularRef,
	))[productCode];

	if (hasCircularRef) {
		yield call(snackbarSaga, {
			error: true,
			errorMessage: `${productCode} has a circular reference and cannot be displayed`,
		});
		return;
	}

	const productData = (yield select(productConfigSelectors.getProductsData))[
		productCode
	];

	// ensure selectedProductOptions has value.
	let productOptions = (yield select(
		productConfigSelectors.getSelectedProductOptions,
	))[productKey];

	if (!productOptions) {
		// No need to stop the show. We have what is needed to create this object, so let's just initialize it.
		yield call(resetOptionsToDefaults, { productKey });
		productOptions = (yield select(
			productConfigSelectors.getSelectedProductOptions,
		))[productKey];
	}

	const ptokToLocalDetailsMap = (yield select(
		productConfigSelectors.getProductKeyToPtokToLocalDetails,
	))[productKey];

	const optionKeysToGeo = (yield select(
		productConfigSelectors.getProductKeysToOptionKeysToGeo,
	))[productKey];

	const allowList = (yield select(
		productConfigSelectors.getProductCodeToAllowList,
	))[productCode];

	const specialConfigOptions = (yield select(
		productConfigSelectors.getProductKeyToSpecialConfigs,
	))[productKey];

	const ptokToIsHidden = yield select(productConfigSelectors.getPtokToIsHidden);

	const revealedHiddenOptions = (yield select(
		productConfigSelectors.getRevealedHiddenOptions,
	))[productKey];

	const options = getNewSelectionsObj({
		allowList,
		productData,
		productOptions,
		optionKeysToGeo,
		ptokToLocalDetailsMap,
		specialConfigOptions,
		ptokToIsHidden,
		revealedHiddenOptions,
	});

	yield put(
		productConfigActions.patchSelectedProductOptions({
			productKey,
			options,
		}),
	);
}

/**
 * @param {object} param0
 * @param {string} param0.message
 * */
function* handleAclSnackBar({ message }) {
	yield call(snackbarSaga, {
		variant: snackbarVariants.INFO,
		successMessage: message,
	});
}

/** Updates any invalid option to a valid one base upon the acl data of the active product. */
function* aclAuditSelections({ productKey }) {
	const forbiddenOptions = (yield select(aclSelectors.getForbiddenOptionsMap))[
		productKey
	];
	const firstValidValueMap = (yield select(aclSelectors.getFirstValidValueMap))[
		productKey
	];
	const productOptionsEntries = Object.entries(
		(yield select(productConfigSelectors.getSelectedProductOptions))[
			productKey
		],
	);
	const keyToCodeMap = yield select(
		productConfigSelectors.getProductKeyToCodeMap,
	);
	const productData = (yield select(productConfigSelectors.getProductsData))[
		keyToCodeMap[productKey]
	];

	for (let i = 0; i < productOptionsEntries.length; i += 1) {
		const [ptokId, { value: ptovId }] = productOptionsEntries[i];
		const { relation } = forbiddenOptions?.[ptokId]?.[ptovId] || {};
		if (relation) {
			const firstValidPtovId = firstValidValueMap[ptokId];
			yield put(
				productConfigActions.updateOption({
					productKey,
					ptokId,
					ptovId: firstValidPtovId,
				}),
			);
			const { description: destinationDescription } =
				productData.ptokData[ptokId] || {};
			const message = getAclMessage(relation, destinationDescription);

			if (message) {
				yield call(handleAclSnackBar, { message });
			}
		}
	}
}

/**
 * Sets the ptok to the ptov under the given product key and calls updateOptionActiveStates.
 *
 * Triggered by config UI components via PRODUCT_CONFIG_CASCADE_UPDATE_OPTION.
 * @param {Object} param0
 * @param {String} [param0.productKey] - the product key being modified
 * @param {Number} [param0.ptokId] - the id of the option being set.
 * @param {Number} [param0.ptovId] - the id of the value the option is being set to.
 */
function* handleCascadeUpdateOption({
	productKey: productKeyArg,
	ptokId,
	ptovId,
	numUnits,
}) {
	const productKey =
		productKeyArg || (yield select(productConfigSelectors.getActiveProductKey));

	if (!productKey) {
		// eslint-disable-next-line no-console
		console.error('productKey is required for handleCascadeUpdateOption.');
		return; // Nothing useful can be done and there is probably a 404 displaying to the user.
	}

	// Set the option to the new value if applicable.
	if (ptokId && ptovId) {
		yield put(
			productConfigActions.updateOption({
				productKey,
				ptokId,
				ptovId,
				numUnits,
			}),
		);
		yield call(aclAuditSelections, { productKey });
	}
	yield call(updateOptionActiveStates, { productKey });
}

function* copyAuxData({ sourceProductKey, targetProductKey }) {
	const properties = yield select(productConfigSelectors.getProductProperties);
	const sourceProperties = properties[sourceProductKey];
	yield put(
		productConfigActions.setProperties({
			productKey: targetProductKey,
			properties: sourceProperties,
		}),
	);

	const sourceRegion = (yield select(
		productConfigSelectors.getAllSelectedProductRegions,
	))[sourceProductKey];
	yield put(
		productConfigActions.patchSelectedProductRegions({
			productKey: targetProductKey,
			region: sourceRegion,
		}),
	);
}

function* mergeSelectedProductOptions({ sourceProductKey, targetProductCode }) {
	const selectedProductOptions = yield select(
		productConfigSelectors.getSelectedProductOptions,
	);
	const keyToCodeMap = yield select(
		productConfigSelectors.getProductKeyToCodeMap,
	);
	const sourceProductCode = keyToCodeMap[sourceProductKey];
	const sourceSelectedOptions = selectedProductOptions[sourceProductKey];
	const targetSelectedOptions = selectedProductOptions[targetProductCode];

	const ptokTransposeMap = yield select(
		productConfigSelectors.getKeyStringToProductCodeToPtok,
	);
	const ptokToKeyMap = yield select(productConfigSelectors.getPtokToKey);
	const keyToValueToProductCodeToPtov = yield select(
		productConfigSelectors.getKeyToValueToProductCodeToPtov,
	);
	const ptovToStringValueMap = yield select(
		productConfigSelectors.getPtovToValueStringMap,
	);
	const productsData = yield select(productConfigSelectors.getProductsData);

	const newSelectedOptions = {};

	// Loop on the target so as to not add additional (unsupported) properties from the target.
	Object.entries(targetSelectedOptions).forEach(([ptokId, option]) => {
		const optionKey = ptokToKeyMap[ptokId]; // optionKey is applicable to both target and source.

		const sourcePtok = ptokTransposeMap[optionKey]?.[sourceProductCode];

		// If the source did not have the option, use what the target already has.
		if (!sourcePtok) {
			newSelectedOptions[ptokId] = option;
			return;
		}

		const { value: sourcePtov, ...sourceRest } = sourceSelectedOptions[
			sourcePtok
		];
		const sourceStringValue = ptovToStringValueMap[sourcePtov]; // The string value version of the currently selected ptov.
		const transposedValue =
			keyToValueToProductCodeToPtov[optionKey][sourceStringValue]?.[
				targetProductCode
			]; // The same value as the source, but with the target product's ptov.
		const transposedValueIsPublic =
			productsData[targetProductCode].ptokData[ptokId].values[transposedValue]
				?.isPublic;

		// This means that the selected value was not available on the new product.
		// For example, templates have different options between linux and windows.
		// So, use the existing option.
		if (!transposedValue || !transposedValueIsPublic) {
			newSelectedOptions[ptokId] = option;
			return;
		}

		newSelectedOptions[ptokId] = { value: transposedValue, ...sourceRest };
	});

	const isInBasket = isUUID(sourceProductKey);
	const productKey = isInBasket ? sourceProductKey : targetProductCode;
	yield put(
		productConfigActions.patchSelectedProductOptions({
			productKey,
			options: newSelectedOptions,
		}),
	);

	// The product key doesn't change, so no need to mess with the properties if already in the basket.
	if (!isInBasket) {
		// We need to copy the existing properties since those are percieved functionally as product options.
		yield call(copyAuxData, {
			sourceProductKey,
			targetProductKey: productKey,
		});
	}

	if (isInBasket) {
		// Save the new productCode to the store to be included in the payload later.
		// This is also used in the display selectors via getProductKeyToCodeMap.
		// This has to happen AFTER newSelectedOptions is created since getProductKeyToCodeMap is used above to get the source product code.
		// This has to happen BEFORE updateOptionActiveStates, since that also uses getProductKeyToCodeMap, but needs to audit using the new prouduct code.
		yield put(
			productConfigActions.storeChangedProductCode({
				productKey,
				newProductCode: targetProductCode,
			}),
		);
		yield call(initZone, productKey);
	}

	// audit the selections object to make sure thing are active/inactive as needed.
	yield call(updateOptionActiveStates, { productKey });
}

function* handleChangeOS({ productKey, newOS }) {
	const oldProductCode =
		(yield select(productConfigSelectors.getProductKeyToCodeMap))[productKey] ||
		'';

	if (!oldProductCode) {
		yield call(snackbarSaga, {
			error: true,
			errorMessage: `could not generate new product code for ${oldProductCode}`,
		});
		return;
	}

	// simplify the problem by stripping off the suffix no matter what. Add ".WIN" back on when needed.
	let newProductCode = oldProductCode.replace(/\.WIN$/, '');
	if (newOS === 'windows') {
		newProductCode += '.WIN';
	}

	const productIsInBasket = Boolean(
		(yield select(cartDetailsSelectors.getItemsByUuid))[productKey],
	);

	// Ensure that the newProductCode has the data ready before proceeding.
	yield call(initializeFlatDetails, { productCode: newProductCode });

	// merge the old options into the new product
	yield call(mergeSelectedProductOptions, {
		sourceProductKey: productKey,
		targetProductCode: newProductCode,
	});

	// Direct the user to the new path if product is not yet in basket.
	if (!productIsInBasket) {
		yield put(push(`/shop/config/${newProductCode}`));
	}
}

export {
	handleAclSnackBar,
	handleCascadeUpdateOption,
	handleChangeOS,
	initializeFlatDetails,
	initOptionsFromAssetDetails,
	initOptionsFromBasket,
	initZone,
	mergeSelectedProductOptions,
	resetOptionsToDefaults,
	updateOptionActiveStates,
};

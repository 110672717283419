import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'styled-components';

import Box from '@material-ui/core/Box';

import DividerBox from 'components/atoms/DividerBox';

const names = {
	badge: 'badge',
	blue: 'blue',
	emphasized: 'emphasized',
	faint: 'faint',
	grey: 'grey',
	lightgrey: 'lightgrey',
	neutral: 'neutral',
	opaqueBlue: 'opaqueBlue',
	tag: 'tag',
	gradient: 'gradient',
	disabled: 'disabled',
};

const variants = ({ theme }) => ({
	// used for cart badge, total panel
	[names.blue]: {
		border: '4px solid',
		borderRadius: 8,
		borderColor: 'primary.washed',
		bgcolor: 'primary.faint',
		paddingY: 2.5,
		paddingX: 3,
	},
	[names.opaqueBlue]: {
		bgcolor: 'primary.faintOpaque',
	},
	[names.badge]: {
		borderRadius: '24px 24px 8px 8px',
		paddingY: 2 /* overrides paddingY from "blue" */,
		paddingX: 1.5 /* overrides paddingX from "blue" */,
		paddingRight: 2,
	},
	// used for quickcarts cart badge.
	[names.disabled]: {
		border: '4px solid',
		borderColor: 'grey.disabled',
		bgcolor: 'grey.light',
	},
	// used for dark card/table headers
	[names.emphasized]: {
		bgcolor: 'primary.dark',
		color: 'primary.contrastText',
	},
	// used for hostname needed messaging
	[names.tag]: {
		display: 'flex',
		alignItems: 'center',
		border: '2px solid',
		borderRadius: 4,
		borderColor: 'primary.dark',
		bgcolor: 'primary.faint',
		padding: 0.5,
	},
	[names.grey]: {
		border: '1px solid',
		borderColor: 'divider',
		borderRadius: '8px',
		bgcolor: 'background.default',
	},
	[names.lightgrey]: {
		border: '0',
		bgcolor: 'grey.light',
	},
	[names.gradient]: {
		border: '2px solid',
		borderColor: 'primary.light',
		borderRadius: 8,
		style: {
			background: `linear-gradient(278deg, ${theme.palette.common.white1} 0%, ${theme.palette.common.blue11} 100%)`,
		},
	},
});

const getVariantProps = ({ variant, theme }) => {
	if (typeof variant === 'string') return variants({ theme })[variant];
	return variant.reduce((acc, v) => {
		return { ...acc, ...variants({ theme })[v] };
	}, {});
};

const StyledBox = ({
	$between,
	children,
	divider,
	dividerSide = 'bottom',
	variant = 'neutral',
	...rest
}) => {
	const theme = useTheme();

	const variantProps = getVariantProps({ variant, theme });
	const Component = divider ? DividerBox : Box;
	const dividerProps = divider ? { $side: dividerSide, $between } : {};
	return (
		<Component p={2} {...dividerProps} {...variantProps} {...rest}>
			{children}
		</Component>
	);
};

StyledBox.propTypes = {
	/** String to determine which variant styles to display.
	    If an array of strings is passed in, later variants will override styles of
			earlier variants
	*/
	variant: PropTypes.oneOfType([
		PropTypes.oneOf(Object.keys(names)),
		PropTypes.arrayOf(PropTypes.oneOf(Object.keys(names))),
	]),
};

export default StyledBox;

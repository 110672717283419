import MPopper from '@material-ui/core/Popper';
import styled from 'styled-components';

const Popper = styled(MPopper)`
	& > div {
		font-size: 0.75em;
		background-color: ${(p) => p.theme.palette.common.white};
		color: ${(p) => p.theme.palette.primary.main};
		box-shadow: ${(p) => p.theme.shadows[4]};
		border: 1px solid ${(p) => p.theme.palette.primary.main};
		line-height: 15px;
		white-space: pre-line;
	}
`;

export default Popper;

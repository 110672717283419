const actions = {
	BACKUPS_AND_IMAGES_SETTINGS_SET_DAYS_TO_RETAIN:
		'BACKUPS_AND_IMAGES_SETTINGS_SET_DAYS_TO_RETAIN',
	setDaysToRetain: ({ daysToRetain }) => ({
		type: actions.BACKUPS_AND_IMAGES_SETTINGS_SET_DAYS_TO_RETAIN,
		daysToRetain,
	}),
	SERVER_BACKUPS_AND_IMAGES_SET_SELECTED_PLAN:
		'SERVER_BACKUPS_AND_IMAGES_SET_SELECTED_PLAN',
	setSelectedPlan: ({ selectedPlan }) => ({
		type: actions.SERVER_BACKUPS_AND_IMAGES_SET_SELECTED_PLAN,
		selectedPlan,
	}),
	SERVER_BACKUPS_AND_IMAGES_SET_SELECTED_QUOTA:
		'SERVER_BACKUPS_AND_IMAGES_SET_SELECTED_QUOTA',
	setSelectedQuota: ({ selectedQuota }) => ({
		type: actions.SERVER_BACKUPS_AND_IMAGES_SET_SELECTED_QUOTA,
		selectedQuota,
	}),
	SERVER_BACKUPS_AND_IMAGES_INIT_SETTINGS:
		'SERVER_BACKUPS_AND_IMAGES_INIT_SETTINGS',
	init: ({ uniqId }) => ({
		type: actions.SERVER_BACKUPS_AND_IMAGES_INIT_SETTINGS,
		uniqId,
	}),
	SERVER_BACKUPS_AND_IMAGES_INIT_CHANGE_ACRONIS:
		'SERVER_BACKUPS_AND_IMAGES_INIT_CHANGE_ACRONIS',
	initChangeAcronis: ({ uniqId }) => ({
		type: actions.SERVER_BACKUPS_AND_IMAGES_INIT_CHANGE_ACRONIS,
		uniqId,
	}),
	SERVER_BACKUPS_AND_IMAGES_UPDATE_SETTINGS:
		'SERVER_BACKUPS_AND_IMAGES_UPDATE_SETTINGS',
	update: ({ uniqId, fromModal }) => ({
		type: actions.SERVER_BACKUPS_AND_IMAGES_UPDATE_SETTINGS,
		uniqId,
		fromModal,
	}),
	SERVER_BACKUPS_AND_IMAGES_RESET_SETTINGS:
		'SERVER_BACKUPS_AND_IMAGES_RESET_SETTINGS',
	reset: () => ({
		type: actions.SERVER_BACKUPS_AND_IMAGES_RESET_SETTINGS,
	}),
	SERVER_BACKUPS_AND_IMAGES_INIT_ADD_ACRONIS:
		'SERVER_BACKUPS_AND_IMAGES_INIT_ADD_ACRONIS',
	initAddAcronis: () => ({
		type: actions.SERVER_BACKUPS_AND_IMAGES_INIT_ADD_ACRONIS,
	}),
};

export default actions;

const copyToClipboardActions = {
	UTILITY_COPY_TO_CLIPBOARD: 'UTILITY_COPY_TO_CLIPBOARD',
	copyToClipboard: ({ text, message, contentKey, contentProps }) => ({
		type: copyToClipboardActions.UTILITY_COPY_TO_CLIPBOARD,
		text,
		message,
		contentKey,
		contentProps,
	}),
};

export default copyToClipboardActions;

import { call, takeEvery, put, select } from 'redux-saga/effects';

import { selectors as appConfigSelectors } from 'modules/appConfig';
import { selectors as accountDetailsSelectors } from 'modules/api/account/detailsModule';

import dialogActions from 'modules/dialogs/actions';
import actions from './manageRedirectActions';

/**
 *
 * @param {string} url url to parse and perform magic on
 * @param {string} manageHostname the hostname of manage for this environment
 * @returns a route that the user will navigate to
 */
function getUrl({ url, manageHostname }) {
	if (!url) {
		return manageHostname;
	}
	if (url[0] === '/') {
		return `${manageHostname}${url}`;
	}
	return url;
}

/**
 *
 * @param {string} url url to perform magic on
 * @param {bool} featureAvailableInManage determines the appearance of UI
 */
function* toggleManageRedirectDialog({ url, featureAvailableInManage }) {
	const managementPortal = yield select(
		accountDetailsSelectors.managementPortal,
	);

	const manageHostname = yield select(appConfigSelectors.getManageHostname);
	const route = yield call(getUrl, { url, manageHostname });

	yield put(
		dialogActions.open({
			title:
				managementPortal === 'any' && featureAvailableInManage
					? 'Temporary Detour'
					: 'Feature Not Implemented',
			contentKey: 'DetourDialogContent',
			contentProps: {
				route,
				buttonsVisible: managementPortal === 'any' && featureAvailableInManage,
			},
		}),
	);
}

export { getUrl, toggleManageRedirectDialog };

export default function* root() {
	yield takeEvery(
		[actions.SET_MANAGE_REDIRECT_IS_OPEN],
		toggleManageRedirectDialog,
	);
}

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { currency } from 'utility/format';
import LWTypography from 'components/common/LWTypography';
import LWButton from 'components/common/LWButton';
import LWLink from 'components/common/LWLink';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {
	selectors as balanceSelectors,
	actions as balanceActions,
} from 'modules/api/billing/balanceModule';
import {
	hasAuthToken,
	hasGroup,
	isBasketAdmin as isBasketAdminSelector,
	isSetupUser as isSetupUserSelector,
	mainRole as mainRoleSelector,
} from 'modules/auth/authSelectors';
import styled from 'styled-components';
import { roles } from 'utility/constants/roles';

const SBox = styled(Box)`
	border-bottom: 1px solid ${(p) => p.theme.palette.divider};
	padding: ${({ theme }) => `0px ${theme.spacing(2)}px`};
`;

const SGrid = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: ${({ theme }) => theme.spacing(1)}px;
	max-width: ${({ theme }) => theme.maxWidth}px;
	margin: auto;

	padding: ${({ theme }) => `${theme.spacing(1)}px 0px`};
	.balanceText {
		font-size: 1.3em;
	}
	.due {
		font-size: 1.3em;
		font-weight: 700;
	}
`;

const noPaymentDisplayData = {
	dueText: 'Nothing Due',
	payButtonText: 'Make a Payment',
};

const needPaymentDisplayData = {
	dueText: 'Due Now',
	payButtonText: 'Pay Now',
};

const AccountBalanceBar = ({
	isDisplayed,
	fetchBalance,
	isPaymentDue,
	paymentDue,
}) => {
	useEffect(() => {
		if (isDisplayed) {
			fetchBalance();
		}
	}, [fetchBalance, isDisplayed]);

	if (!isDisplayed) return null;

	const paymentDisplayData = isPaymentDue
		? needPaymentDisplayData
		: noPaymentDisplayData;

	if (paymentDue <= 0) return null;

	return (
		<SBox>
			<SGrid>
				<Grid item>
					<LWTypography className="balanceText" color="palette.text.secondary">
						Account Balance:
					</LWTypography>
				</Grid>
				<Grid item>
					<LWTypography className="due" color="palette.text.secondary">
						{currency(paymentDue)} {paymentDisplayData.dueText}
					</LWTypography>
				</Grid>
				<Grid item>
					<LWLink to="/account/billing/pay" underline="none">
						<LWButton color="secondary" variant="contained" fullWidth>
							{paymentDisplayData.payButtonText}
						</LWButton>
					</LWLink>
				</Grid>
				<Grid item>
					<LWLink to="/account/billing" underline="none">
						<LWButton variant="outlined" color="secondary" fullWidth>
							More Details
						</LWButton>
					</LWLink>
				</Grid>
			</SGrid>
		</SBox>
	);
};

AccountBalanceBar.propTypes = {
	isDisplayed: PropTypes.bool,
	isPaymentDue: PropTypes.bool.isRequired,
	paymentDue: PropTypes.string.isRequired,
	fetchBalance: PropTypes.func.isRequired,
};

AccountBalanceBar.defaultProps = {
	isDisplayed: false,
};

const mapStateToProps = (state) => ({
	isDisplayed:
		hasAuthToken(state) &&
		hasGroup(state) &&
		!isBasketAdminSelector(state) &&
		!isSetupUserSelector(state) &&
		mainRoleSelector(state) !== roles.TECHNICIAN.key &&
		!balanceSelectors.getError(state),
	isLoading: balanceSelectors.isLoading(state),
	isPaymentDue: balanceSelectors.isPaymentDue(state),
	paymentDue: balanceSelectors.displayBalance(state),
});

const mapDispatchToProps = (dispatch) => ({
	fetchBalance: () => dispatch(balanceActions.fetch()),
});

export { AccountBalanceBar };

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(AccountBalanceBar);

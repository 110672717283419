import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import get from 'lodash/get';

import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';
import SubNavRoutes from 'components/routers/SubNavRoutes';
import { roles } from 'utility/constants/roles';

const DomainDetails = lazy(() => import('containers/pages/domains/details'));

const DomainRegistration = lazy(() =>
	import('containers/pages/domains/details/registration/DomainRegistration'),
);

const DomainDNS = lazy(() =>
	import('containers/pages/domains/details/dns/DomainDNS'),
);

const DomainSSL = lazy(() =>
	import('containers/pages/domains/details/ssl/SSL'),
);

const CreateDNSZone = lazy(() =>
	import('containers/pages/domains/details/dns/CreateDNSZone'),
);

const ReverseDns = lazy(() =>
	import('containers/pages/domains/dns/ReverseDns'),
);

const CreateTransfer = lazy(() =>
	import('containers/pages/domains/CreateTransfer'),
);

const ManualSsl = lazy(() =>
	import('containers/pages/domains/Ssls/AddSsl/ManualSsl'),
);
const AutoSsl = lazy(() =>
	import('containers/pages/domains/Ssls/AddSsl/AutoSsl'),
);

const Cloudflare = lazy(() => import('containers/pages/domains/Cloudflare'));

const CloudflareDetails = lazy(() =>
	import('containers/pages/domains/Cloudflare/Details'),
);

const DomainsRouter = (props) => {
	const { navData } = props;
	return (
		<Switch>
			<RoleRestrictedRoute
				exact
				path={get(navData, 'path')}
				component={get(navData, 'component')}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
			<RoleRestrictedRoute
				exact
				path={`${get(navData, 'path')}/create-transfer`}
				component={CreateTransfer}
				whitelistedRoles={[roles.PURCHASER]}
			/>
			<RoleRestrictedRoute
				path={`${get(navData, 'path')}/dashboard/:domain`}
				component={DomainDetails}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
			<RoleRestrictedRoute
				path={`${get(navData, 'path')}/registration/:domain`}
				component={DomainRegistration}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
			<RoleRestrictedRoute
				exact
				path={`${get(navData, 'path')}/ssl/dashboard/:domain`}
				component={DomainSSL}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
			<RoleRestrictedRoute
				path={`${get(navData, 'path')}/dns/add`}
				component={CreateDNSZone}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
			<RoleRestrictedRoute
				exact
				path={`${get(navData, 'path')}/ssl/add`}
				component={ManualSsl}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
			<RoleRestrictedRoute
				path={`${get(navData, 'path')}/ssl/add/auto`}
				component={AutoSsl}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
			<RoleRestrictedRoute
				path={`${get(navData, 'path')}/dns/reverse-dns`}
				component={ReverseDns}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>

			<RoleRestrictedRoute
				path={`${get(navData, 'path')}/dns/:domain`}
				component={DomainDNS}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
			<RoleRestrictedRoute
				exact
				path={`${get(navData, 'path')}/cloudflare`}
				component={Cloudflare}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
			<RoleRestrictedRoute
				path={`${get(navData, 'path')}/cloudflare/:uniqId`}
				component={CloudflareDetails}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
			<SubNavRoutes
				subNavs={get(navData, 'subNav')}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
		</Switch>
	);
};

DomainsRouter.propTypes = {
	navData: PropTypes.object.isRequired,
};

export default DomainsRouter;

const getErrorStringHelper = (error) => {
	return (
		(() => {
			if (typeof error === 'object') {
				const data = error?.data || {};
				return (
					data.public_message ||
					data.details ||
					data.full_message ||
					error.publicMessage ||
					error.details ||
					error.fullMessage ||
					error.error
				);
			}
			return error;
		})()?.toString() || ''
	);
};

// eslint-disable-next-line import/prefer-default-export
export { getErrorStringHelper };

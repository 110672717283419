const actions = {
	INIT_CONTACT_LIST: 'INIT_CONTACT_LIST',
	initList: () => ({
		type: actions.INIT_CONTACT_LIST,
	}),
	UPDATE_CONTACT_LIST: 'UPDATE_CONTACT_LIST',
	updateList: ({ payload, newMainEmail, newMainPhone }) => ({
		type: actions.UPDATE_CONTACT_LIST,
		payload,
		newMainEmail,
		newMainPhone,
	}),
};

export default actions;

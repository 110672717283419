import React from 'react';
import { Field } from 'redux-form';
import { specialChars } from 'utility/form/validators';
import Grid from '@material-ui/core/Grid';
import TextField from 'components/atoms/TextField';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel/ConfirmCancel';

const PrivateParentNameFormContents = ({
	dirty,
	invalid,
	parentName,
	onConfirm,
	onCancel,
}) => (
	<Grid container direction="column" spacing={3}>
		<Grid item>
			<Field
				component={TextField}
				label="Parent Name"
				inputProps={{
					'data-lpignore': true,
				}}
				id="parentName"
				name="parentName"
				validate={[specialChars()]}
			/>
		</Grid>
		<Grid item>
			<ConfirmCancel
				disabled={!dirty || invalid || !parentName}
				confirmText="Save"
				confirm={onConfirm}
				cancel={onCancel}
			/>
		</Grid>
	</Grid>
);

export default PrivateParentNameFormContents;

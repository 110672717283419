import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.notifications.all;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'NOTIFICATIONS_ALL',
	isPaginated: true,
	url: '/notifications/all.json',
});

/**
 * @typedef Notification
 * @property {string} category
 * @property {string} description
 * @property {string} enddate
 * @property {string | number} id
 * @property {string} last_alert
 * @property {string} modfieddate
 * @property {import('club-sauce').APIBooleanT} resolved
 * @property {string} severity
 * @property {string} startdate
 * @property {string} system
 * @property {string | number} system_identifier
 * @property {string} uniq_id
 */

export { actions, reducer, sagas, selectors };

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as form } from 'redux-form';
import { immutableReducer as reduxOidc } from 'redux-oidc';

import account from './account/accountReducer';
import acronis from './acronis/reducer';
import api from './api/apiRootReducer'; // TODO move this module up one level
import { reducer as appConfig } from './appConfig';
import { reducer as auth } from './auth';
import basket from './basket/reducer';
import cart from './cart/reducer';
import chat from './chat/reducer';
import { reducer as cartStack } from './cartStack';
import deleteProjectDialog from './deleteProjectDialog/reducer';
import dialogs from './dialogs/reducer';
import disallowedUser from './disallowedUser/reducer';
import { reducer as drawer } from './drawer';
import errors from './error/errorReducer';
import firewallPresets from './firewallPresets/reducer';
import gtm from './gtm/reducer';
import internalOnly from './internalOnly/reducer';
import maintenanceMode from './maintenanceMode/reducer';
import manageRedirect from './manageRedirect/manageRedirectReducer';
import marketplace from './marketplace/reducer';
import notifications from './notifications/reducer';
import blockStorage from './blockStorage/reducer';
import openid from './openid/openidReducer';
import payment from './payment/paymentReducer';
import paymentForm from './paymentForm/reducer';
import projectAddDialog from './projectAddDialog/reducer';
import referAFriendDialog from './referAFriendDialog/reducer';
import renameProjectDialog from './renameProjectDialog/reducer';
import renewDialog from './renewDialog/reducer';
import { reducer as route } from './route';
import server from './server/reducer';
import serviceWorker from './serviceWorker/reducer';
import sideSheet from './sideSheet/reducer';
import snackbar from './snackbar/snackbarReducer';
import sshkeys from './sshkeys/reducer';
import statusSheet from './statusSheet/reducer';
import { reducer as threatStack } from './threatStack';
import transactionTimeline from './billing/transactionTimeline/reducer';
import unBlockIPDialog from './unblockIPDialog/unblockIPDialogReducer';
import usertaskOperations from './usertask/reducer';
import { reducer as utility } from './utility';
import www from './www-api/wwwReducer';

export default (history) =>
	combineReducers({
		account,
		acronis,
		api,
		appConfig,
		auth,
		basket,
		blockStorage,
		cart,
		cartStack,
		chat,
		deleteProjectDialog,
		dialogs,
		disallowedUser,
		drawer,
		errors,
		firewallPresets,
		form,
		gtm,
		internalOnly,
		maintenanceMode,
		manageRedirect,
		marketplace,
		notifications,
		openid,
		payment,
		paymentForm,
		projectAddDialog,
		reduxOidc,
		referAFriendDialog,
		renameProjectDialog,
		renewDialog,
		route,
		router: connectRouter(history),
		server,
		serviceWorker,
		sideSheet,
		snackbar,
		sshkeys,
		statusSheet,
		threatStack,
		transactionTimeline,
		unBlockIPDialog,
		usertaskOperations,
		utility,
		www,
	});

// @ts-check
import { useQuery } from '@tanstack/react-query';
import api from '../../api';

/**
 * @param {import('club-sauce/public/network/loadbalancer/index.raw').LWApiPublicNetworkLoadBalancerPossibleNodesParamsI} params
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 */
const useLoadBalancerPossibleNodes = (
	params,
	{ keepPreviousData, enabled } = {},
) =>
	useQuery({
		queryKey: ['/network/loadbalancer/possibleNodes', params],
		queryFn: () => api.network.loadbalancer.apiRaw.possibleNodes(params),
		keepPreviousData,
		enabled,
	});

export default useLoadBalancerPossibleNodes;

import { addValidator } from 'redux-form-validators';

// https://github.com/validatorjs/validator.js
import isIP from 'validator/es/lib/isIP';
import isFQDN from 'validator/es/lib/isFQDN';
import isPort from 'validator/es/lib/isPort';

const ipv4 = addValidator({
	defaultMessage: 'Valid IPv4 address required',
	validator: (options, value) => !value || isIP(value, 4),
});

const ipv6 = addValidator({
	defaultMessage: 'Valid IPv6 address required',
	validator: (options, value) => !value || isIP(value, 6),
});

const notIpv4 = addValidator({
	defaultMessage: 'Can not be an IPv4 address',
	validator: (options, value) => !isIP(value, 4),
});

const notIpv6 = addValidator({
	defaultMessage: 'Can not be an IPv6 address',
	validator: (options, value) => !isIP(value, 6),
});

const port = addValidator({
	defaultMessage: 'Not a valid port',
	validator: (options, value) => (value ? isPort(value) : true),
});

const phoneNumber = addValidator({
	defaultMessage: 'Please enter phone number in a valid format',
	validator: (options, value) => /^[0-9extnsio.,()+ -]{2,25}$/i.test(value),
});

const domainName = addValidator({
	defaultMessage: 'Please enter a valid domain name',
	validator: (options, value) => value && isFQDN(value),
});

const specialChars = addValidator({
	defaultMessage: 'No special charactors',
	validator: (options, value) => !/[^a-zA-Z0-9 ]/g.test(value),
});

const taxIdValidators = {
	AL: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) =>
			value ? /^[A-Z]{1}\d{8}[A-Z]$/.test(value) : true,
	}),
	AU: addValidator({
		defaultMessage: 'Please enter VAT Reg No in a valid format',
		validator: (options, value) => (value ? /^ABN\d{11}$/.test(value) : true),
	}),
	AT: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) => (value ? /^ATU\d{8}$/.test(value) : true),
	}),
	BE: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) =>
			value ? /^BE[0-1]{1}\d{9}$/.test(value) : true,
	}),
	BG: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) => (value ? /^BG\d{9,10}$/.test(value) : true),
	}),
	CH: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) =>
			value ? /^(CHE)?\d{6,9}$/.test(value) : true,
	}),
	CY: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) =>
			value ? /^(CY)?\d{8}[A-Z]$/.test(value) : true,
	}),
	CZ: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) => (value ? /^CZ\d{8,10}$/.test(value) : true),
	}),
	DE: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) => (value ? /^DE\d{9}$/.test(value) : true),
	}),
	DK: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) => (value ? /^DK\d{8}$/.test(value) : true),
	}),
	EE: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) => (value ? /^EE\d{9}$/.test(value) : true),
	}),
	ES: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) =>
			value ? /^ES[A-Z0-9]\d{7}[A-Z0-9]$/.test(value) : true,
	}),
	FI: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) => (value ? /^FI\d{8}$/.test(value) : true),
	}),
	FR: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) =>
			value ? /^FR[A-Z0-9]{2}\d{9}/.test(value) : true,
	}),
	GB: addValidator({
		defaultMessage: 'Please enter VAT Reg No in a valid format',
		validator: (options, value) => (value ? /^GB\d{9}$/.test(value) : true),
	}),
	GR: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) => (value ? /^EL\d{9}$/.test(value) : true),
	}),
	HR: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) => (value ? /^HR\d{11}$/.test(value) : true),
	}),
	HU: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) => (value ? /^HU\d{8}$/.test(value) : true),
	}),
	IE: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) =>
			value ? /IE[0-9][A-Z0-9]\d{4,5}[A-Z0-9][A-Z]/.test(value) : true,
	}),
	IL: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) => (value ? /^(IL)?\d{9}$/.test(value) : true),
	}),
	IN: addValidator({
		defaultMessage: 'Please enter GST/PAN in a valid format',
		validator: (options, value) =>
			value ? /^(\d{2}[A-Z0-9]{13}$)|(^[A-Z0-9]{10})$/.test(value) : true,
	}),
	IS: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) => (value ? /^VSK\d{5,10}$/.test(value) : true),
	}),
	IT: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) => (value ? /IT\d{11}/.test(value) : true),
	}),
	LT: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) =>
			value ? /(^LT\d{9}$)|(^LT\d{12}$)/.test(value) : true,
	}),
	LU: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) => (value ? /^LU\d{8}$/.test(value) : true),
	}),
	LV: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) => (value ? /^LV\d{11}$/.test(value) : true),
	}),
	MT: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) => (value ? /^MT\d{8}$/.test(value) : true),
	}),
	NL: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) =>
			value ? /^NL\d{9}B\d{2}$/.test(value) : true,
	}),
	PL: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) => (value ? /^PL\d{10}$/.test(value) : true),
	}),
	PT: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) => (value ? /^PT\d{9}$/.test(value) : true),
	}),
	RO: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) => (value ? /^RO\d{2,10}$/.test(value) : true),
	}),
	SE: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) => (value ? /^SE\d{12}$/.test(value) : true),
	}),
	SI: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) => (value ? /^SI\d{8}$/.test(value) : true),
	}),
	SK: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) => (value ? /^SK\d{10}$/.test(value) : true),
	}),
	NZ: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) => (value ? /^NZ\d{8,9}$/.test(value) : true),
	}),
	NO: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) => (value ? /^NO\d{9}$/.test(value) : true),
	}),
	RU: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) => (value ? /^RU\d{10}$/.test(value) : true),
	}),
	ZA: addValidator({
		defaultMessage: 'Please enter Business / Tax ID in a valid format',
		validator: (options, value) =>
			value ? /^(\d{4}\/\d{3,6}\/\d{2})|(4\d{9})$/.test(value) : true,
	}),
};

export {
	domainName,
	ipv4,
	ipv6,
	notIpv4,
	notIpv6,
	phoneNumber,
	port,
	specialChars,
	taxIdValidators,
};

const updateActions = {
	ACCOUNT_UPDATE_CONTACT_INFO: 'ACCOUNT_UPDATE_CONTACT_INFO',
	updateContactInfo: ({ payload, newMainEmail, newMainPhone }) => ({
		type: updateActions.ACCOUNT_UPDATE_CONTACT_INFO,
		payload,
		newMainEmail,
		newMainPhone,
	}),
};

export default updateActions;

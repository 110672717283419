/* eslint-disable camelcase */

const domains = {
	ADD_DOMAIN: 'ADD_DOMAIN',
	addDomain: ({ index, domain, max_period, min_period }) => ({
		type: domains.ADD_DOMAIN,
		index,
		domain,
		max_period,
		min_period,
	}),
	REMOVE_DOMAIN: 'REMOVE_DOMAIN',
	removeDomain: ({ index, uuid, domain }) => ({
		type: domains.REMOVE_DOMAIN,
		uuid,
		index,
		domain,
	}),
	EDIT_NAME_SERVERS: 'EDIT_NAME_SERVERS',
	editNameServers: ({ nameServers }) => ({
		type: domains.EDIT_NAME_SERVERS,
		nameServers,
	}),
	TOGGLE_LOADING: 'TOGGLE_LOADING',
	toggleLoading: ({ key, value }) => ({
		type: domains.TOGGLE_LOADING,
		key,
		value,
	}),
	SUBMIT_WHO_IS: 'SUBMIT_WHO_IS',
	submitWhoIs: ({ whoIs }) => ({
		type: domains.SUBMIT_WHO_IS,
		whoIs,
	}),
	TOGGLE_DOMAIN_LOADING: 'TOGGLE_DOMAIN_LOADING',
	toggleDomainLoading: ({ domain, value }) => ({
		type: domains.TOGGLE_DOMAIN_LOADING,
		domain,
		value,
	}),
	DREG_MODIFY_DOMAINS: 'DREG_MODIFY_DOMAINS',
	modifyDomains: () => ({
		type: domains.DREG_MODIFY_DOMAINS,
	}),
	DREG_INIT_CONFIGURE_DOMAINS: 'DREG_INIT_CONFIGURE_DOMAINS',
	initConfigureDomains: () => ({
		type: domains.DREG_INIT_CONFIGURE_DOMAINS,
	}),
};

export default domains;

import React from 'react';
import formatBytes from 'utility/formatBytes';
import Graph from 'components/molecules/Graph';
import useAssetMeasurementsLookup from 'modules/queries/asset/measurements/useLookup';
import makeGraphData from 'modules/queries/asset/measurements/makeGraphData';

const Memory = ({ uniqId }) => {
	const { data, isLoading, isError } = useAssetMeasurementsLookup({
		uniq_id: uniqId,
		name: 'memory',
		fields: ['used'],
		duration: 'day',
	});

	const graphData = makeGraphData({ data: data?.data, keys: ['memory'] });

	return (
		<Graph
			data={graphData}
			title="Server Memory"
			tickFormatter={(value) =>
				value && `${formatBytes(value).rounded} ${formatBytes(value).unit}`
			}
			isLoading={isLoading}
			errorText={isError ? "Failed to load server's load data" : ''}
		/>
	);
};

export default Memory;

import React from 'react';

import Box from '@material-ui/core/Box';

import AppInfo from 'containers/connectedMolecules/AppInfo';
import LegalBar from '../footer/LegalBar';

const CompactFooter = () => {
	return (
		<Box display="flex" alignItems="center" bgcolor="grey.light" padding={1}>
			<AppInfo />
			<LegalBar hasDarkBackground={false} />
		</Box>
	);
};

export default CompactFooter;

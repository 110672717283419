import React from 'react';
import { useDispatch } from 'react-redux';
import { actions as favoritesActions } from 'modules/favorites';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as StarIcon } from 'images/new-icons/favorites.svg';
import Tooltip from '@material-ui/core/Tooltip';

const styles = (theme) => ({
	root: {},
	icon: {
		color: theme.palette.grey.dark,
		'&:hover svg path': {
			fill: theme.palette.primary.main,
		},
		'&:hover': {
			background: theme.palette.background.default,
		},
		'&:hover span': {
			background: theme.palette.background.default,
			color: theme.palette.primary.main,
			textDecoration: 'underline',
		},
	},
	label: {
		color: theme.palette.grey.dark,
		fontSize: '12px',
		marginTop: '2px',
		marginLeft: '6px',
		'&:hover': {
			color: theme.palette.primary.main,
			textDecoration: 'underline',
		},
	},
});

const FavoritesList = ({ classes }) => {
	const dispatch = useDispatch();
	const openDialog = () => {
		dispatch(favoritesActions.openFavoritesModal());
	};
	return (
		<Tooltip title="Manage my favorites">
			<IconButton
				className={classes.icon}
				aria-label="Favorites"
				onClick={openDialog}
				disableRipple
			>
				<StarIcon /> <span className={classes.label}>Favorites</span>
			</IconButton>
		</Tooltip>
	);
};

export default withStyles(styles)(FavoritesList);

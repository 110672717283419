import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.api.storage.block.cluster.list;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'STORAGE_BLOCK_CLUSTER_LIST',
	method: 'POST',
	url: '/storage/block/cluster/list.json',
});

const selectors = {
	getRegions: createSelector(
		defaultSelectors.getNativeItems,
		(items) => items.map((item) => item.region),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };

const actions = {
	UTILITY_THROTTLE_DISPATCH: 'UTILITY_THROTTLE_DISPATCH',
	throttleDispatch: ({ action, ms = 2000, key }) => ({
		type: actions.UTILITY_THROTTLE_DISPATCH,
		action,
		ms,
		key,
	}),
	UTILITY_REMOVE_TROTTLED_DISPATCH: 'UTILITY_REMOVE_TROTTLED_DISPATCH',
	removeThrottledDispatch: ({ action, key }) => ({
		type: actions.UTILITY_REMOVE_TROTTLED_DISPATCH,
		action,
		key,
	}),
};

export default actions;

import React from 'react';

// redux
import { useSelector } from 'react-redux';
import { selectors as routeSelectors } from 'modules/route';

// custom components
import LWLink from 'components/common/LWLink';

const HomeLink = () => {
	const hompagePath = useSelector(routeSelectors.getHomepagePath);
	return (
		<LWLink variant="body2" to={hompagePath} underline="hover">
			Home
		</LWLink>
	);
};

export default HomeLink;

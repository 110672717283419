import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) =>
	state.api.network.dns.domain.nameserver.register;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_DNS_DOMAIN_NAMESERVER_REGISTER',
	method: 'POST',
	url: '/network/dns/domain/nameserver/register.json',
});

export { actions, reducer, sagas, selectors };

import React from 'react';
import Radio from '@material-ui/core/Radio';
import styled, { css } from 'styled-components';

const SRadio = styled(Radio)`
	${({ $isChecked }) =>
		!$isChecked &&
		css`
			/* This is a work around for the checked status showing when it shouldn't be */
			svg:nth-of-type(2) {
				display: none;
			}
		`}
`;

const LWRadio = ({ color = 'primary', ...props }) => (
	<SRadio color={color} {...props} />
);

export default LWRadio;

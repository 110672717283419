import { useQuery } from '@tanstack/react-query';
import api from 'modules/queries/api';

/**
 * @param {import('club-sauce/public/network/zone.raw').LWApiPublicNetworkZoneListParamsRawI} [params]
 */
const useNetworkZoneList = (params) =>
	useQuery({
		queryKey: ['/network/zone/list', params],
		queryFn: () => api.network.zone.apiRaw.list(params),
	});

export default useNetworkZoneList;

import React from 'react';
import PropTypes from 'prop-types';

// MUI components
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

// custom components
import LWTypography from 'components/common/LWTypography';

const PaperBox = ({
	title,
	TitleProps = { variant: 'subtitle2', bold: true, paragraph: true },
	padding = 4,
	'data-testid': testId,
	children,
	...PaperProps // passed to paper with Box as the component
}) => (
	<Paper
		data-testid={testId}
		component={Box}
		p={padding}
		variant="outlined"
		{...PaperProps}
	>
		{title && <LWTypography {...TitleProps}>{title}</LWTypography>}
		{children}
	</Paper>
);

PaperBox.propTypes = {
	title: PropTypes.string,
	titleVariant: PropTypes.string,
	padding: PropTypes.number,
};

export default PaperBox;

import React, { useRef, useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import LWTypography from 'components/common/LWTypography';
import Popper from 'components/atoms/Popper';
import Tooltip from '@material-ui/core/Tooltip';
import styled, { useTheme } from 'styled-components';

const SBox = styled(Box)`
	& > div {
		-ms-overflow-style: none; /* Internet Explorer 10+ */
		scrollbar-width: none; /* Firefox */
	}
	&::-webkit-scrollbar {
		display: none; /* Safari and Chrome */
	}
`;

const TruncAndTip = ({
	children,
	title = children,
	width = 28,
	...LWTypographyProps
}) => {
	const theme = useTheme();
	const typographyRef = useRef();
	const maxWidthInt = theme.spacing(width);
	const [popperShouldShow, setPopperShouldShow] = useState(false);
	useEffect(() => {
		setPopperShouldShow(typographyRef.current.clientWidth >= maxWidthInt);
	}, [maxWidthInt, typographyRef]);
	return (
		<Tooltip
			title={popperShouldShow ? title : ''}
			placement="right"
			PopperComponent={Popper}
		>
			<SBox maxWidth={`${maxWidthInt}px`} overflow="auto" whiteSpace="nowrap">
				<LWTypography
					noWrap
					BoxProps={{
						ref: typographyRef,
						display: popperShouldShow ? 'flex' : 'inline-flex', // inline-flex doesn't allow for the ellipsis
						...(LWTypographyProps?.BoxProps || {}),
					}}
					{...LWTypographyProps}
				>
					{children}
				</LWTypography>
			</SBox>
		</Tooltip>
	);
};

TruncAndTip.propTypes = {
	/** theme spacing units. */
	width: PropTypes.number,
	/** What the popper should display. Defaults to children */
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default TruncAndTip;

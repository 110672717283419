import { put, select, takeEvery, call } from 'redux-saga/effects';
import dialogActions from 'modules/dialogs/actions';
import actions from './actions';
import selectors from './selectors';

function* handleLaunchAppInfoModal({ dismissible = false } = {}) {
	yield put(
		dialogActions.open({
			contentKey: 'AppVersionModalContents',
			dismissible,
		}),
	);
}

function* handleServiceWorkerUpdate() {
	const { waiting } = yield select(selectors.getRegistration);
	if (waiting) {
		yield call([waiting, 'postMessage'], { type: 'SKIP_WAITING' });
		yield call([waiting, 'addEventListener'], 'statechange', (e) => {
			if (e.target.state === 'activated') {
				window.location.reload();
			}
		});
	}
}

export default function* root() {
	yield takeEvery(
		actions.SERVICE_WORKER_LAUNCH_APP_INFO_MODAL,
		handleLaunchAppInfoModal,
	);
	yield takeEvery(actions.SERVICE_WORKER_UPDATE, handleServiceWorkerUpdate);
}

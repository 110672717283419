import { defaultData, defaultParams } from 'modules/gtm/defaults';

import baskets from './events/baskets';
import cartStack from './events/cartStack';
import identify from './events/identify';

// https://rangle.gitbook.io/redux-beacon/index/google-tag-manager#event-definitions

const eventsMap = {
	'@@router/LOCATION_CHANGE': ({ payload }) => {
		if (payload.isFirstRendering) return null; // on initial app load, this occurs before GTM is initialized, so will cause an app crash if this is called;
		const pageViewData = {
			...defaultParams,
			event: 'lw.pageview',
			data: defaultData,
		};
		if (payload.location.pathname === '/cart') {
			// NEWMAN-1975 need to send data for visits to /cart
			const cartData = {
				...defaultParams,
				event: 'lw.structured',
				data: { ...defaultData, action: 'cart_viewedPage' },
			};
			return [cartData, pageViewData];
		}
		return pageViewData;
	},
	GTM_INITIALIZED: () => {
		// @@router/LOCATION_CHANGE happens before GTM_INITIALIZED on initial app load, so need to send the event once it's ready
		return {
			...defaultParams,
			event: 'lw.pageview',
			data: defaultData,
		};
	},
	ANALYTICS_EVENT: ({ payload }) => {
		return {
			...defaultParams,
			event: 'lw.structured',
			data: { ...defaultData, ...payload },
		};
	},
	...baskets,
	...cartStack,
	...identify,
};

export default eventsMap;

import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.market.cart.domain;

const { actions, reducer, sagas, selectors, additionalSagas } = createAPIModule(
	{
		getStateSlice,
		actionType: 'MARKET_CART_DOMAIN',
		url: '/market/cart/domain.json',
	},
);

export {
	actions as cartDomainActions,
	reducer as cartDomainReducer,
	sagas as cartDomainSagas,
	selectors as cartDomainSelectors,
	additionalSagas as cartDomainAdditionalSagas,
};

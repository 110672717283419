import { useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';

/**
 * Dispatch an action or call the function
 * @param {Function | Object} action
 * @returns Callback Function
 * @example
 * const handleClick = useDispatchOrCall(onClick);
 * const handleRtClick = useDispatchOrCall(actions.showModal('prompt'));
 * const handleDblClick = useDispatchOrCall(() => { dialog.open(); });
 */
export default function useDispatchOrCall(action) {
	const dispatch = useDispatch();

	// Reference action to avoid passing new callback instance every render
	const actionRef = useRef();
	actionRef.current = action;

	return useCallback(() => {
		const currentAction = actionRef.current;

		// Handle function calls
		if (typeof currentAction === 'function') {
			currentAction();
			return;
		}

		// Handle redux action
		if (currentAction?.type) {
			dispatch(currentAction);
		}
	}, [actionRef, dispatch]);
}

import { createSelector } from 'reselect';

const getStateSlice = (state) => state.server.templates;

const getTemplatesShowValue = createSelector(
	getStateSlice,
	(slice) => slice.showValue,
);

const templatesShowSelectors = {
	getStateSlice,
	getTemplatesShowValue,
};

export default templatesShowSelectors;

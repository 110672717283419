import React from 'react';
import LWLink from 'components/common/LWLink';
import { ReactComponent as ShoppingCartIcon } from 'images/new-icons/shop.svg';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';
import Badge from '@material-ui/core/Badge';
import PropTypes from 'prop-types';

const SLink = styled(LWLink)`
	text-decoration: none;
	.MuiCircularProgress-root {
		color: white;
	}
	:hover div {
		background: white;
	}
	display: inline-flex;
	position: relative;
`;

const SBadge = styled(Badge)`
	.MuiBadge-badge {
		font-weight: 600;
		height: 20px;
		min-width: 20px;
		top: -5px;
	}
`;

const SIconButton = styled(IconButton)`
	color: ${({ theme }) => theme.palette.grey.dark};
	:hover {
		background: ${({ theme }) => theme.palette.background.default};
		color: ${({ theme }) => theme.palette.primary.main};
		text-decoration: underline;
	}
	margin-right: 10px;
`;

const SLabel = styled('span')`
	font-size: 12px;
	margin-top: 2px;
	margin-left: 5px;
`;

const CompactCartSummary = ({ itemsCount }) => {
	return (
		<SLink to="/cart">
			<SIconButton disableRipple>
				<SBadge badgeContent={itemsCount} color="primary">
					<ShoppingCartIcon />
				</SBadge>
				<SLabel>My Cart</SLabel>
			</SIconButton>
		</SLink>
	);
};

CompactCartSummary.propTypes = {
	itemsCount: PropTypes.number,
};

export default CompactCartSummary;

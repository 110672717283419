import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.account.limits.dynamicChild;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ACCOUNT_LIMITS_DYNAMICCHILD',
	url: '/account/limits/dynamicChild.json',
});

const selectors = {
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };

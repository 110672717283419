import { flatDetailsActions } from 'modules/api/product/flatDetailsModule';
import testUtilActions from 'testUtils/actions';
import extrapolateFlatDetails from './extrapolateFlatDetails';
import productConfigActions from './actions';

/**
 * @type {ProductConfigState}
 */
const defaultState = {
	deployOnto: '',
	groupsExpanded: false,
	selectedProductOptions: {},
	productProperties: {},
	selectedProductRegions: {},
	productsData: {},
	activeProductKey: undefined,
	changedProductCodes: {},
	revealedHiddenOptions: {},
	templatesShowValue: 'All',
	quickaddLoadingMap: {},
};

const productConfigReducer = (state = defaultState, action) => {
	const {
		productKey,
		ptokId,
		ptovId,
		active = false,
		newProductCode,
		numUnits,
		key,
		value,
		properties,
	} = action;
	switch (action.type) {
		case productConfigActions.SET_PRODUCT_CONFIG_DEPLOY_ONTO:
			return { ...state, deployOnto: action.deployOnto };
		case productConfigActions.SET_PRODUCT_CONFIG_GROUPS_EXPANDED:
			return { ...state, groupsExpanded: action.groupsExpanded };
		case productConfigActions.PRODUCT_CONFIG_SET_ACTIVE_PRODUCT_KEY:
			return { ...state, activeProductKey: productKey };
		case productConfigActions.PRODUCT_CONFIG_CLEAR_ACTIVE_PRODUCT_KEY:
			return { ...state, activeProductKey: defaultState.activeProductKey };
		case productConfigActions.PRODUCT_CONFIG_PATCH_SELECTED_PRODUCT_OPTIONS:
			return {
				...state,
				selectedProductOptions: {
					...state.selectedProductOptions,
					[productKey]: action.options,
				},
			};
		case productConfigActions.PRODUCT_CONFIG_PATCH_SELECTED_PRODUCT_REGIONS:
			return {
				...state,
				selectedProductRegions: {
					...state.selectedProductRegions,
					[action.productKey]: action.region,
				},
			};
		case flatDetailsActions.setType:
			return {
				...state,
				productsData: {
					...state.productsData,
					[action.payload?.product_code]: extrapolateFlatDetails(
						action.payload,
					),
				},
			};
		case productConfigActions.PRODUCT_CONFIG_CLEAR_FLAT_DETAILS:
			return { ...state, productsData: defaultState.productsData };
		case productConfigActions.PRODUCT_CONFIG_CLEAR_SELECTED_PRODUCT_OPTIONS:
			return {
				...state,
				selectedProductOptions: defaultState.selectedProductOptions,
			};
		case productConfigActions.PRODUCT_CONFIG_UPDATE_OPTION: {
			const currentSelections = state.selectedProductOptions[productKey];
			const newSelectionObj = {
				active,
				value: Number(ptovId || currentSelections[ptokId].value),
			};
			if (typeof numUnits === 'number') newSelectionObj.numUnits = numUnits;
			return {
				...state,
				selectedProductOptions: {
					...state.selectedProductOptions,
					[productKey]: {
						...currentSelections,
						[ptokId]: newSelectionObj,
					},
				},
			};
		}
		case productConfigActions.PRODUCT_CONFIG_SET_PROPERTIES:
			return {
				...state,
				productProperties: {
					...state.productProperties,
					[productKey]: properties,
				},
			};
		case productConfigActions.PRODUCT_CONFIG_PATCH_PROPERTY: {
			const currentProperties = state.productProperties[productKey];
			return {
				...state,
				productProperties: {
					...state.productProperties,
					[productKey]: {
						...currentProperties,
						[key]: value,
					},
				},
			};
		}
		case productConfigActions.PRODUCT_CONFIG_DELETE_PROPERTY: {
			const { [key]: removed, ...currentProperties } =
				state.productProperties[productKey] || {};

			return {
				...state,
				productProperties: {
					...state.productProperties,
					[productKey]: {
						...currentProperties,
					},
				},
			};
		}
		case productConfigActions.PRODUCT_CONFIG_CLEAR_NON_DEFAULT_PROPERTIES: {
			const {
				create_from: createFrom,
				public_ssh_key: publicSshKey,
				use_ipv6: useIpV6,
				ip_pool: ipPool,
				...restOfProperties
			} = state.productProperties[productKey] || {};

			return {
				...state,
				productProperties: {
					...state.productProperties,
					[productKey]: restOfProperties,
				},
			};
		}
		case productConfigActions.PRODUCT_CONFIG_STORE_CHANGED_PRODUCT_CODE:
			return {
				...state,
				changedProductCodes: {
					...state.changedProductCodes,
					[productKey]: newProductCode,
				},
			};
		case productConfigActions.PRODUCT_CONFIG_REMOVE_CHANGED_PRODUCT_CODE: {
			const {
				[productKey]: removed,
				...changedProductCodes
			} = state.changedProductCodes;
			return {
				...state,
				changedProductCodes: {
					...changedProductCodes,
				},
			};
		}
		case productConfigActions.PRODUCT_CONFIG_REVEAL_HIDDEN_OPTIONS: {
			const currentReveals = state.revealedHiddenOptions[productKey];
			return {
				...state,
				revealedHiddenOptions: {
					...state.revealedHiddenOptions,
					[productKey]: { ...currentReveals, [ptokId]: true },
				},
			};
		}
		case productConfigActions.PRODUCT_CONFIG_SET_TEMPLATES_SHOW_VALUE:
			return {
				...state,
				templatesShowValue: action.value,
			};
		case productConfigActions.PRODUCT_CONFIG_SET_QUICKADD_LOADING:
			return {
				...state,
				quickaddLoadingMap: {
					...state.quickaddLoadingMap,
					[action.productCode]: true,
				},
			};
		case productConfigActions.PRODUCT_CONFIG_UNSET_QUICKADD_LOADING:
			return {
				...state,
				quickaddLoadingMap: {
					...state.quickaddLoadingMap,
					[action.productCode]: false,
				},
			};

		case testUtilActions.TEST_UTIL_CLEAR_STATE:
		case productConfigActions.PRODUCT_CONFIG_CLEAR:
			return defaultState;
		default:
			return state;
	}
};

export { defaultState };
export default productConfigReducer;

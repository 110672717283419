import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LWRadio from 'components/atoms/LWRadio';
import LWTypography from 'components/common/LWTypography';
import LWTooltip from 'components/common/LWTooltip';

const noOptionsLabel = 'No options available';
const lwLabel = 'Liquid Web (Off-Server)';
const cloudLabel = 'Acronis Cloud (Off-Server & Off-Site)';
const toolTipText =
	'Liquid Web stores your backups in a Liquid Web datacenter. Acronis Cloud stores your backups in an Acronis datacenter. To change your storage location in the future, please contact Support.';
const acronisRadioValues = { liquidWeb: 'lw', acronisCloud: 'cloud' };
const acronisRadioTestIds = {
	Acronis__StorageLocationLabel: 'Acronis__StorageLocationLabel',
};

const AcronisRadio = ({
	cloudAvailable,
	lwAvailable,
	onChange,
	radioValues = acronisRadioValues,
	selectedValue,
}) => {
	if (cloudAvailable && lwAvailable)
		return (
			<Box display="flex" alignItems="center">
				<RadioGroup value={selectedValue} onChange={onChange}>
					<Grid container direction="row">
						<Grid component={Box} item mr={4}>
							<FormControlLabel
								value={radioValues.liquidWeb}
								control={
									<LWRadio
										color="primary"
										$isChecked={selectedValue === radioValues.liquidWeb}
									/>
								}
								label={lwLabel}
							/>
						</Grid>
						<Grid item>
							<FormControlLabel
								value={radioValues.acronisCloud}
								control={
									<LWRadio
										color="primary"
										$isChecked={selectedValue === radioValues.acronisCloud}
									/>
								}
								label={cloudLabel}
							/>
						</Grid>
					</Grid>
				</RadioGroup>
				<LWTooltip>{toolTipText}</LWTooltip>
			</Box>
		);

	return (
		<LWTypography
			bold
			data-testid={acronisRadioTestIds.Acronis__StorageLocationLabel}
		>
			{(() => {
				if (cloudAvailable) return cloudLabel;
				if (lwAvailable) return lwLabel;
				return noOptionsLabel;
			})()}
		</LWTypography>
	);
};

AcronisRadio.propTypes = {
	cloudAvailable: PropTypes.bool,
	lwAvailable: PropTypes.bool,
	onChange: PropTypes.func,
	selectedValue: PropTypes.string,
};

export {
	acronisRadioValues,
	acronisRadioTestIds,
	noOptionsLabel,
	lwLabel,
	cloudLabel,
	toolTipText,
};
export default AcronisRadio;

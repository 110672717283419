import { createSelector } from 'reselect';

const getStateSlice = (state) => state.maintenanceMode;

const enabled = createSelector(
	getStateSlice,
	(slice) => slice?.enabled,
);

// eslint-disable-next-line import/prefer-default-export
export { enabled };

const actions = {
	SERVER_BACKUPS_IMAGES_HANDLE_DIALOG: 'SERVER_BACKUPS_IMAGES_HANDLE_DIALOG',
	handleDialog: ({ isOpen, data, type }) => ({
		type: actions.SERVER_BACKUPS_IMAGES_HANDLE_DIALOG,
		payload: {
			isOpen,
			type,
			data,
		},
	}),
	SERVER_BACKUPS_IMAGES__INITIALIZE: 'SERVER_BACKUPS_IMAGES__INITIALIZE',
	initialize: ({ uniqId }) => ({
		type: actions.SERVER_BACKUPS_IMAGES__INITIALIZE,
		uniqId,
	}),
	CREATE_IMAGE: 'CREATE_IMAGE',
	createImage: ({ name, uniqId }) => ({
		type: actions.CREATE_IMAGE,
		name,
		uniqId,
	}),
};

export default actions;

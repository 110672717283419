import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.market.cart.discount.modify;

const { actions, reducer, sagas, selectors, additionalSagas } = createAPIModule(
	{
		getStateSlice,
		actionType: 'MARKET_CART_DISCOUNT_MODIFY',
		url: '/market/cart/discount/modify.json',
	},
);

export {
	actions as cartDiscountModifyActions,
	reducer as cartDiscountModifyReducer,
	sagas as cartDiscountModifySagas,
	selectors as cartDiscountModifySelectors,
	additionalSagas as cartDiscountModifyAdditionalSagas,
};

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import AppInfo from 'containers/connectedMolecules/AppInfo';

import Ad from '../footer/Ad';
import Nav from '../footer/Nav';
import SocialIcons from '../footer/SocialIcons';
import LegalBar from '../footer/LegalBar';
import ChatCTA from '../footer/ChatCTA';

export const styles = ({ palette, breakpoints }) => ({
	root: {
		position: 'relative',
	},
	dark: {
		backgroundColor: palette.primary.main,
	},
	adContainer: {
		backgroundColor: palette.primary.light,
		height: '270px',
		minWidth: '900px',
		[breakpoints.down('md')]: {
			height: '100%',
			width: '100%',
			minWidth: 0,
		},
	},
	helpContainer: {
		backgroundColor: palette.primary.contrastText,
		height: '270px',
		minWidth: '250px',
		[breakpoints.down('md')]: {
			height: '100%',
			width: '100%',
		},
	},
	appInfoIcon: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',

		[breakpoints.up('md')]: {
			position: 'absolute',
			bottom: '1.35em',
			right: '6em',
		},
	},
});
const ExpandedFooter = ({ classes }) => (
	<footer className={classes.root}>
		<Grid container>
			<Grid item xs={12} md className={classes.helpContainer}>
				<ChatCTA />
			</Grid>
			<Grid item className={classes.adContainer}>
				<Ad />
			</Grid>
		</Grid>
		<div className={classes.dark}>
			<Nav />
			<SocialIcons />
			<Box p={2} display="flex" justifyContent="center">
				<LegalBar />
			</Box>
			<AppInfo className={classes.appInfoIcon} darkBackground />
		</div>
	</footer>
);

ExpandedFooter.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ExpandedFooter);

import React from 'react';

function DatabaseOutlined(props) {
	return (
		<svg width={24} height={24} viewBox="0 0 24 24" {...props}>
			<g data-name="Icon-DBReplicant">
				<path
					data-name="Path 110"
					d="M11.999 21.999a10.828 10.828 0 01-5.656-1.46 4.309 4.309 0 01-2.344-3.54V7.146 7c0-2.758 3.589-5 8-5s8 2.244 8 5v10c0 2.755-3.586 4.999-8 4.999zm-6-6.7v1.7c0 1.654 2.692 3 6 3s6-1.346 6-3v-1.7a11.455 11.455 0 01-12 0zm.6-4.609a1.872 1.872 0 00-.6 1.309c0 1.654 2.69 3 6 3a9.806 9.806 0 004.177-.848c1.124-.545 1.772-1.274 1.824-2.054v-.2a1.92 1.92 0 00-.6-1.209 11.79 11.79 0 01-10.8 0zm5.4-6.689c-3.308 0-6 1.346-6 3s2.69 3 6 3 6-1.345 6-3-2.691-3.002-6-3.002z"
					fill="#03a0ca"
				/>
				<path data-name="Path 111" d="M0 0h24v24H0z" fill="none" />
			</g>
		</svg>
	);
}

export default DatabaseOutlined;

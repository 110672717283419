import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.api.asset.measurements.lookup;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ASSET_MEASUREMENTS_LOOKUP',
	method: 'POST',
	url: '/asset/measurements/lookup.json',
});

const moduleKeys = {
	LOAD: 'load',
	BANDWIDTH: 'bandwidth',
	MEMORY: 'memory',
};

const getIsLoadingByModuleKey = createSelector(
	getStateSlice,
	(_, moduleKey) => moduleKey,
	(state, moduleKey) => {
		const moduleState = state?.[moduleKey];
		return (
			!!moduleState && !!moduleState.pending && !moduleState.isBackgroundPolling
		);
	},
);

const getHasErrorByModuleKey = createSelector(
	getStateSlice,
	(_, moduleKey) => moduleKey,
	(state, moduleKey) => {
		const moduleState = state?.[moduleKey];
		return !!moduleState?.error;
	},
);

const getNativeDataByModuleKey = createSelector(
	getStateSlice,
	(_, moduleKey) => moduleKey,
	(state, moduleKey) => {
		const moduleState = state?.[moduleKey];
		return moduleState?.data;
	},
);

const selectors = {
	...defaultSelectors,
	getIsLoadingByModuleKey,
	getHasErrorByModuleKey,
	getNativeDataByModuleKey,
};

export { actions, reducer, sagas, selectors, moduleKeys };

import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/CloseRounded';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import GenericError from 'components/common/GenericError';
import LWIconButton from 'components/atoms/LWIconButton';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import RootRef from '@material-ui/core/RootRef';
import compose from 'utility/compose';
import { withTheme } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Warning from '@material-ui/icons/WarningRounded';
import LWTypography from 'components/common/LWTypography';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';

const SDialog = styled(Dialog)`
	& > div:nth-of-type(3) > div:first-child > div:first-child {
		padding: 8px 16px;
		border-bottom: 4px solid ${(p) => p.theme.palette.primary.main};
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	${(p) =>
		p.color === 'danger' &&
		`
			& > div:nth-of-type(3) > div:first-child > div:first-child {
				border-bottom: 4px solid ${p.theme.palette.error.main};
			}
		`}
`;

const SDialogContent = styled(DialogContent)`
	min-height: 100px;
	${(p) =>
		p.$contentSize?.height &&
		`
			height: ${p.$contentSize?.height}px;
		`}
	${(p) =>
		p.$contentSize?.width &&
		`
			width: ${p.$contentSize?.width}px;
		`}
	${({ $contentPadding, theme }) =>
		$contentPadding &&
		`
			padding: ${theme.spacingPage}
		`}
`;

const SCircularProgress = styled(CircularProgress)`
	display: block;
	margin: 0 auto;
`;

const LWDialog = ({
	theme,
	title,
	children,
	classes,
	setRef,
	onClose,
	onCancel,
	color,
	isLoading,
	error,
	width,
	staticContext,
	contentSize,
	dismissible = true,
	disableEscapeKeyDown = !dismissible,
	disableBackdropClick = !dismissible,
	contentPadding = false, // backward compatible fix for have to add padding to every single dialogContents files. To use, add the contentKey to src/components/common/Dialogs/maps.js
	resetDialog = () => {},
	...dialogProps // Passing in these props via dialogProps will override these defaults.
}) => {
	const cancel = () => {
		if (onCancel) {
			onCancel();
		}
		if (onClose) {
			onClose();
		}
	};

	// TODO clean this up, remove duplicate code and figure out why we aren't using setRef
	const getErrorOrChildren = () => {
		if (error) {
			return (
				<SDialogContent $contentPadding={contentPadding}>
					<GenericError
						shown
						errorText={typeof error === 'string' ? error : undefined}
					/>
				</SDialogContent>
			);
		}
		let dialogContent;
		if (isLoading) {
			dialogContent = (
				<SDialogContent $contentPadding={contentPadding}>
					<SCircularProgress data-testid="LWDialog__CircularProgress" />
				</SDialogContent>
			);
		} else {
			dialogContent = (
				<SDialogContent $contentPadding={contentPadding}>
					{children}
				</SDialogContent>
			);
		}

		if (setRef) {
			return <RootRef rootRef={setRef}>{dialogContent}</RootRef>;
		}

		return dialogContent;
	};
	const fullScreen = width === 'xs' || width === 'sm';

	useEffect(() => {
		if (typeof color !== 'string') {
			// eslint-disable-next-line no-console
			console.error(new Error(`color passed into LWDialog as ${color}`));
		}
	}, [color]);

	const localColor = typeof color === 'string' ? color : 'initial';
	const isDanger = color === 'danger';
	return (
		<SDialog
			color={localColor}
			onClose={onClose}
			fullScreen={fullScreen}
			disableEscapeKeyDown={disableEscapeKeyDown}
			disableBackdropClick={disableBackdropClick}
			TransitionProps={{
				onExited: resetDialog,
			}}
			{...dialogProps}
		>
			<DialogTitle disableTypography>
				<LWTypography variant="subtitle1">{title}</LWTypography>
				<Box
					display="flex"
					flexWrap="nowrap"
					alignItems="center"
					justifyContent="space-between"
					flexDirection="row-reverse" // aligns close button right when Warning is absent.
					minWidth={isDanger ? '50%' : '10%'} // This is needed to center the warning icon.
					minHeight={38}
				>
					{dismissible && (
						<LWIconButton onClick={cancel}>
							<CloseIcon />
						</LWIconButton>
					)}
					{/* This keeps the flex alignment proper for the danger icon */}
					{!dismissible && <div />}
					{isDanger && <Warning color="error" />}
				</Box>
			</DialogTitle>
			{getErrorOrChildren()}
		</SDialog>
	);
};
LWDialog.defaultProps = {
	color: 'initial',
};

LWDialog.propTypes = {
	children: PropTypes.any,
	color: PropTypes.string,
	error: PropTypes.any,
	isLoading: PropTypes.any,
	onClose: PropTypes.any,
	setRef: PropTypes.any,
	staticContext: PropTypes.any,
	theme: PropTypes.any,
	title: PropTypes.any,
	width: PropTypes.string,
	resetDialog: PropTypes.func,
};

export { LWDialog };

export default compose(
	withWidth(),
	withTheme,
)(LWDialog);

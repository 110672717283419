import React from 'react';
import PropTypes from 'prop-types';

// redux
import { useDispatch } from 'react-redux';
import dialogActions from 'modules/dialogs/actions';
import productConfigActions from 'modules/basket/productConfig/actions';

// MUI components
import Grid from '@material-ui/core/Grid';

// custom components
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';
import LWTypography from 'components/common/LWTypography';

const AddAcronisModalContents = ({ productCode }) => {
	const dispatch = useDispatch();
	const closeDialog = () => {
		dispatch(dialogActions.close());
	};

	const addAcronisToBasket = () => {
		dispatch(
			productConfigActions.addToCart({
				productCode,
			}),
		);
		dispatch(dialogActions.close());
	};

	return (
		<Grid container direction="column" spacing={3}>
			<Grid item>
				<LWTypography bold>
					Thank you for choosing Acronis backups!
				</LWTypography>
			</Grid>
			<Grid item>
				<LWTypography>
					Just a friendly reminder: When switching from Pay Per GB or Quota
					Pricing to Acronis Backups,{' '}
					<b>your old backups will be retained for 60 days.</b> Please contact
					support to use these backups.
				</LWTypography>
			</Grid>
			<Grid item>
				<ConfirmCancel
					confirm={addAcronisToBasket}
					confirmText="Continue"
					cancel={closeDialog}
				/>
			</Grid>
		</Grid>
	);
};

AddAcronisModalContents.propTypes = {
	productCode: PropTypes.string.isRequired,
};

export default AddAcronisModalContents;

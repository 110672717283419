import { takeEvery, put } from 'redux-saga/effects';
import { actions as dialogActions } from 'modules/dialogs';
import {
	actions as listForKeyActions,
	moduleKeys,
} from 'modules/api/account/user/stateData/listForKeyModule';
import { push } from 'connected-react-router';
import actions from './actions';

function* closeFavoritesModal({ path }) {
	if (path) yield put(push(path));
	yield put(dialogActions.close());
}

function* openFavoritesModal() {
	yield put(
		listForKeyActions.fetch(
			{
				key: moduleKeys.FAVORITES,
			},
			moduleKeys.FAVORITES,
		),
	);
	yield put(
		dialogActions.open({
			contentKey: 'FavoritesListDialogContents',
			dialogProps: { maxWidth: 'md' },
		}),
	);
}

export default function*() {
	yield takeEvery(actions.OPEN_FAVORITES_MODAL, openFavoritesModal);
	yield takeEvery(actions.CLOSE_FAVORITES_MODAL, closeFavoritesModal);
}

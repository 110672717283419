const usertaskActions = {
	USERTASK_CANCEL_TASK: 'USERTASK_CANCEL_TASK',
	cancelTask: (code) => ({
		type: usertaskActions.USERTASK_CANCEL_TASK,
		payload: {
			code,
		},
	}),

	USERTASK_CANCEL_REQUEST_FINISHED: 'USERTASK_CANCEL_REQUEST_FINISHED',
	cancelTaskRequestFinished: (code) => ({
		type: usertaskActions.USERTASK_CANCEL_REQUEST_FINISHED,
		payload: {
			code,
		},
	}),

	USERTASK_SCHEDULEDTASK_CANCEL_TASK: 'USERTASK_SCHEDULEDTASK_CANCEL_TASK',
	cancelScheduledTask: (code, taskTable = 'scheduledTasks') => ({
		type: usertaskActions.USERTASK_SCHEDULEDTASK_CANCEL_TASK,
		payload: {
			code,
			taskTable,
		},
	}),
};

export default usertaskActions;

import Immutable from 'immutable';
import actions from './actions';

const initialState = Immutable.fromJS({
	isInternalUser: false,
});

const internalOnly = (state = initialState, action) => {
	switch (action.type) {
		case actions.internalOnlySet:
			return state.set('isInternalUser', action.payload);
		default:
			return state;
	}
};

export default internalOnly;

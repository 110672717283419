// @ts-check
import { useQuery } from '@tanstack/react-query';
import api from 'modules/queries/api';

/**
 * @param {import('club-sauce/public/monitoring/services/index.raw').LWApiPublicMonitoringServicesStatusParamsRawI} params
 */
const useMonitoringServiesStatus = (params) =>
	useQuery({
		queryKey: ['/monitoring/services/status', params],
		queryFn: () => api.monitoring.services.apiRaw.status(params),
	});

export default useMonitoringServiesStatus;

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import LWCard, { types as lwCardTypes } from 'components/common/LWCard';
import manageRedirectActions from 'modules/manageRedirect/manageRedirectActions';

import CloudBlockStorage from './CloudBlockStorage';
import SecurityProducts from './SecurityProducts';

/** @param {import('modules/queries/asset/useDetails').AssetDetails} props */
const Services = (props) => {
	const {
		assetType: serverType,
		backupsAndStorage,
		isOpenStack,
		uniq_id: uniqId,
	} = props;
	const dispatch = useDispatch();

	const blockStorage = backupsAndStorage?.block_storage;
	const isDedicated = serverType === 'dedicated';

	return (
		<LWCard
			title="Services"
			type={lwCardTypes.LIGHT}
			links={[
				{
					href: '/server/details/services/',
					text: 'View Services',
					eventOptions: {
						action: 'Services Page',
						label: 'Services Box',
					},
					onClick: (e) => {
						e.preventDefault();
						dispatch(
							manageRedirectActions.setOpen(
								`/manage/server/${uniqId}.html#dash`,
							),
						);
					},
				},
			]}
		>
			{!isDedicated && !isOpenStack && Boolean(blockStorage?.size) && (
				<CloudBlockStorage blockStorage={blockStorage} />
			)}
			<SecurityProducts {...props} />
		</LWCard>
	);
};

Services.propTypes = {
	uniq_id: PropTypes.string.isRequired,
};

export default Services;

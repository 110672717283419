import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import LWIconButton from 'components/atoms/LWIconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Delete from '@material-ui/icons/Delete';
import classnames from 'classnames';
import analyticsActions from 'modules/gtm/actions';
import eventCategories from 'utility/constants/analyticEventCategories';
import LWTooltip from 'components/common/LWTooltip';
import dialogActions from 'modules/dialogs/actions';
import Box from '@material-ui/core/Box';

const styles = (theme) => ({
	root: {
		display: 'flex',
	},
	popper: {
		zIndex: 999,
	},
	iconButton: {
		border: `1px solid ${theme.palette.divider}`,
		background: theme.palette.common.white,
		color: theme.palette.text.primary,
		'&:hover': {
			background: theme.palette.grey.disabledOpaque,
		},
	},
	menuItem: {
		color: theme.palette.text.primary,
	},
	menuItemDestroy: {
		backgroundColor: theme.palette.background.red,
		color: '#fff',
		textAlign: 'center',
	},
	menuListHorizontal: {
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
		},
	},
});

const rowActionsTestIds = { RowActions__Container: 'RowActions__Container' };
const RowActions = ({
	classes,
	rowActions = [],
	rowData = null,
	tooltip: tooltipArg = null,
	light = false,
	destroy,
	disableDestroyButton = () => false,
	placement = 'bottom-end',
	'data-testid': testid,
	BoxProps,
	disabled,
}) => {
	const dispatch = useDispatch();
	const sendEvent = (payload) =>
		dispatch(analyticsActions.sendAnalyticsEvent(payload));
	const openDialog = ({ title, content, dialogProps, color }) =>
		dispatch(dialogActions.open({ title, content, dialogProps, color }));

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const anchorRef = useRef(null);

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setIsMenuOpen(false);
	};

	const handleClick = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	const menuListClasses = { padding: classes.menuListPadding };

	const tooltip =
		tooltipArg ||
		rowActions.find(({ tooltip: rowActionTootip }) => !!rowActionTootip)
			?.tooltip;
	if (!tooltip && rowActions.length === 0 && !destroy) return null;
	return (
		<Box
			className={classes.root}
			data-testid={rowActionsTestIds.RowActions__Container}
			{...BoxProps}
		>
			{tooltip ? (
				<LWTooltip data-testid="RowAction__Tooltip">{tooltip}</LWTooltip>
			) : (
				<>
					<LWIconButton
						onClick={handleClick}
						ref={anchorRef}
						data-testid={testid}
						disabled={disabled}
					>
						<MoreVertIcon
							className={classnames({
								[classes.closeIcon]: isMenuOpen,
								[classes.light]: light,
							})}
						/>
					</LWIconButton>
					<Popper
						anchorEl={anchorRef.current}
						open={isMenuOpen}
						className={classes.popper}
						transition
						disablePortal
						modifiers={{
							flip: {
								enabled: false,
							},
							preventOverflow: {
								enabled: true,
							},
						}}
						placement={placement}
					>
						{({ TransitionProps }) => (
							<Grow
								{...TransitionProps}
								style={{ transformOrigin: 'right top' }}
							>
								<Paper>
									<ClickAwayListener onClickAway={handleClose}>
										<MenuList classes={menuListClasses} onClick={handleClose}>
											{rowActions &&
												rowActions.map((action) => (
													<MenuItem
														disabled={action.isLoading}
														key={action.name}
														className={classes.menuItem}
														onClick={() => {
															sendEvent({
																category: eventCategories.USER_INTERACTION,
																action: action.name,
																label: 'Row Options',
															});
															// The second arg is for functions commonly used in rowActionsHelpers.js
															if (typeof action.onClick === 'function')
																action.onClick(rowData, { openDialog });
															else dispatch(action.onClick); // In this case, onClick is a pre-created action.
														}}
													>
														{action.name}
													</MenuItem>
												))}
											{destroy && (
												<MenuItem
													key="destroy"
													className={classes.menuItemDestroy}
													disabled={disableDestroyButton(rowData)}
													onClick={() => {
														destroy(rowData);
													}}
												>
													<Delete />
												</MenuItem>
											)}
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</>
			)}
		</Box>
	);
};

RowActions.propTypes = {
	classes: PropTypes.object.isRequired,
	rowActions: PropTypes.arrayOf(
		PropTypes.shape({
			sendEvent: PropTypes.bool,
			name: PropTypes.string.isRequired,
			onClick: PropTypes.oneOfType([
				PropTypes.func,
				// May be a pre-created action
				PropTypes.object,
			]).isRequired,
			/** Overrides all functionality and displays tooltip instead. */
			tooltip: PropTypes.string,
		}),
	),
	rowData: PropTypes.object,
	tooltip: PropTypes.string,
	light: PropTypes.bool,
	destroy: PropTypes.func,
	disableDestroyButton: PropTypes.func,
	placement: PropTypes.string,
	disabled: PropTypes.bool,
};

export { RowActions, rowActionsTestIds };

export default withStyles(styles)(RowActions);

import React, { useContext } from 'react';

import loadBalancerConstants from 'utility/constants/loadBalancers';

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import LWTypography from 'components/common/LWTypography';

import Context from './context';

const ServiceInput = () => {
	const {
		destinationPort: [destinationPort, setDestinationPort],
		isSubmitting,
		service: [service, setService],
		sourcePort: [sourcePort, setSourcePort],
	} = useContext(Context);

	const handleDestPortChange = (event) =>
		setDestinationPort(event.target.value);
	const handleServiceChange = (event) => setService(event.target.value);
	const handleSrcPortChange = (event) => setSourcePort(event.target.value);

	return (
		<>
			<Grid spacing={2} container>
				<Grid item xs={4}>
					<LWTypography>
						<strong>Service:</strong>
					</LWTypography>
				</Grid>
				<Grid item xs={8}>
					<FormControl variant="outlined">
						<InputLabel id="region">Select</InputLabel>
						<Select
							label="Service"
							labelId="service"
							value={service}
							onChange={handleServiceChange}
							inputProps={{
								name: 'service',
								id: 'service',
							}}
							disabled={isSubmitting}
						>
							<MenuItem value={loadBalancerConstants.SERVICE.WEB}>
								Web Services (HTTP/HTTPS)
							</MenuItem>
							<MenuItem value={loadBalancerConstants.SERVICE.CUSTOM}>
								Custom (Source/Destination Port)
							</MenuItem>
						</Select>
					</FormControl>
				</Grid>
			</Grid>
			{service === loadBalancerConstants.SERVICE.CUSTOM && (
				<Grid spacing={2} container>
					<Grid item xs={4}>
						<LWTypography>
							<strong>Ports:</strong>
						</LWTypography>
					</Grid>
					<Grid item xs={4}>
						<FormControl variant="outlined">
							<TextField
								type="number"
								label="Load Balancer*"
								value={sourcePort}
								onChange={handleSrcPortChange}
								InputProps={{ inputProps: { min: 1 } }}
								disabled={isSubmitting}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={4}>
						<FormControl variant="outlined">
							<TextField
								type="number"
								label="Node"
								value={destinationPort}
								onChange={handleDestPortChange}
								InputProps={{ inputProps: { min: 1 } }}
								disabled={isSubmitting}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={4} />
					<Grid item xs={8}>
						<Box mb={2}>
							<LWTypography variant="body2" inline>
								* If you are balancing HTTP or HTTPS traffic, the Load Balancer
								Port must be port 80 or 443, respectively. The Node Port,
								however, can be any of your choosing.
							</LWTypography>
						</Box>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default ServiceInput;

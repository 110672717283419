import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import LWTypography from 'components/common/LWTypography';
import snackbarActions from 'modules/snackbar/snackbarActions';
import { POWER_STATUS } from 'utility/powerInfo';
import useServerStart from 'modules/queries/server/useStart';
import useServerShutdown from 'modules/queries/server/useShutdown';
import { SLWButton } from '../actionStyles';

/** @param {import('modules/queries/asset/useDetails').AssetDetails} props */
const BeyondHostingActions = ({ powerInfo, uniq_id: uniqId, capabilities }) => {
	const dispatch = useDispatch();
	const [showStartConfirmation, setShowStartConfirmation] = useState(false);
	const [showShutdownConfirmation, setShowShutdownConfirmation] = useState(
		false,
	);

	const { mutate: startServer, isLoading: startIsLoading } = useServerStart();
	const {
		mutate: shutdownServer,
		isLoading: shutdownIsLoading,
	} = useServerShutdown();

	const handleConfirmStart = () => {
		startServer(
			{ uniq_id: uniqId },
			{
				onSuccess: () => {
					dispatch(snackbarActions.pushMessage({ message: 'Server started' }));
				},
			},
		);
	};

	const handleConfirmShutdown = () => {
		shutdownServer(
			{ uniq_id: uniqId },
			{
				onSuccess: () => {
					dispatch(snackbarActions.pushMessage({ message: 'Server shutdown' }));
				},
			},
		);
	};

	const isStartable =
		capabilities?.nonLWProvStart && powerInfo?.status === POWER_STATUS.SHUTDOWN;
	const isShutdownable =
		capabilities?.nonLWProvShutdown &&
		powerInfo?.status === POWER_STATUS.RUNNING;

	const closeDialog = () => {
		setShowStartConfirmation(false);
		setShowShutdownConfirmation(false);
	};

	return (
		<>
			{isStartable && (
				<SLWButton
					onClick={() => {
						setShowStartConfirmation(true);
					}}
					color="secondary"
					variant="outlined"
					isLoading={startIsLoading}
				>
					Start
				</SLWButton>
			)}
			{isShutdownable && (
				<SLWButton
					onClick={() => {
						setShowShutdownConfirmation(true);
					}}
					color="secondary"
					variant="outlined"
					isLoading={shutdownIsLoading}
				>
					Shutdown
				</SLWButton>
			)}
			<ConfirmationDialog
				open={showStartConfirmation}
				onClose={closeDialog}
				title="Confirm Start"
				onConfirm={handleConfirmStart}
			>
				<LWTypography>Are you sure you want to start this server?</LWTypography>
			</ConfirmationDialog>
			<ConfirmationDialog
				open={showShutdownConfirmation}
				onClose={closeDialog}
				title="Confirm Shutdown"
				onConfirm={handleConfirmShutdown}
			>
				<LWTypography>
					Are you sure sure you want to shutdown this server? This does not
					destroy the server and you will continued to be invoiced as normal
					while it is inactive.
				</LWTypography>
			</ConfirmationDialog>
		</>
	);
};

BeyondHostingActions.propTypes = {
	capabilities: PropTypes.object.isRequired,
};

export default BeyondHostingActions;

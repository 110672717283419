import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.billing.paypal.payment.execute;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'BILLING_PAYPAL_PAYMENT_EXECUTE',
	url: '/billing/paypal/payment/execute.json',
});

const selectors = {
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };

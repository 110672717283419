import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { cartDetailsSelectors } from 'modules/api/market/cart/detailsModule';

const usePackageInCart = () => {
	const { uuid } = useParams();
	const cartItems = useSelector(cartDetailsSelectors.getNativeItems);

	return useMemo(() => cartItems.find((item) => item.uuid === uuid), [
		cartItems,
		uuid,
	]);
};

export default usePackageInCart;

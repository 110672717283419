const snackbarActions = {
	SNACKBAR_PUSH_MESSAGE: 'SNACKBAR_PUSH_MESSAGE',
	/**
	 * Push snackbar message to the top of the stack
	 * @param {object} message
	 * @param {string?} message.id
	 * @param {string} message.message
	 * @param {'info' | 'success' | 'error'} message.variant
	 * @param {string} message.autoHideDuration
	 * @param {string} message.contentKey
	 * @param {object} message.contentProps
	 */
	pushMessage: ({
		id,
		message,
		variant,
		actionLabel,
		onActionClick,
		showClose,
		autoHideDuration,
		contentKey,
		contentProps,
	}) => ({
		type: snackbarActions.SNACKBAR_PUSH_MESSAGE,
		id,
		message,
		variant,
		actionLabel,
		onActionClick,
		showClose,
		autoHideDuration,
		contentKey,
		contentProps,
	}),
	SNACKBAR_POP_MESSAGE: 'SNACKBAR_POP_MESSAGE',
	/**
	 * Remove last snackbar message
	 * @returns
	 */
	popMessage: () => ({ type: snackbarActions.SNACKBAR_POP_MESSAGE }),
	SNACKBAR_REMOVE_MESSAGE: 'SNACKBAR_REMOVE_MESSAGE',
	/**
	 * Remove snackbar message
	 * @param {string} id snackbar id
	 * @returns
	 */
	removeMessage: (id) => ({
		type: snackbarActions.SNACKBAR_REMOVE_MESSAGE,
		payload: id,
	}),
};

export default snackbarActions;

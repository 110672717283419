import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.market.cart.discount.remove;

const { actions, reducer, sagas, selectors, additionalSagas } = createAPIModule(
	{
		getStateSlice,
		actionType: 'MARKET_CART_DISCOUNT_REMOVE',
		url: '/market/cart/discount/remove.json',
	},
);

export {
	actions as cartDiscountRemoveActions,
	reducer as cartDiscountRemoveReducer,
	sagas as cartDiscountRemoveSagas,
	selectors as cartDiscountRemoveSelectors,
	additionalSagas as cartDiscountRemoveAdditionalSagas,
};

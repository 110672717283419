import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { mainRole as getMainRole } from 'modules/auth';
import { roles } from 'utility/constants/roles';

const getStateSlice = (state) => state.api.billing.payment.profile.details;
const getIsBlacklisted = (state) => getMainRole(state) === roles.TECHNICIAN.key;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'BILLING_PAYMENT_PROFILE_DETAILS',
	url: '/billing/payment/profile/details.json',
	getIsBlacklisted,
});

// TODO: getPaymentMethod
const paymentMethod = createSelector(
	defaultSelectors.getNativeData,
	(slice) => slice?.payment_method || '',
);

const getHasPaymentMethod = createSelector(
	paymentMethod,
	(method) => !!method,
);

const getPaymentMethodIsPaypal = createSelector(
	paymentMethod,
	(slice) => {
		switch (slice) {
			case 'paypal':
			case 'onetime_paypal':
			case 'paypal_agreement':
				return true;
			default:
				return false;
		}
	},
);

const selectors = {
	paymentMethod,
	getHasPaymentMethod,
	getPaymentMethodIsPaypal,
	maskedNumber: createSelector(
		defaultSelectors.getStateSlice,
		(number) => {
			const maskedNumber = number.getIn(['data', 'masked_number'], '');
			return maskedNumber ? `x${maskedNumber.slice(4)} ` : null;
		},
	),
	issuer: createSelector(
		defaultSelectors.getStateSlice,
		(slice) => slice.getIn(['data', 'issuer']),
	),
	ccExp: createSelector(
		defaultSelectors.getStateSlice,
		(slice) => slice.getIn(['data', 'cc_exp']),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };

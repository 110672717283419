import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) =>
	state.api.network.dns.domain.registrationDetails;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_DNS_DOMAIN_REGISTRATIONDETAILS',
	method: 'POST',
	url: '/network/dns/domain/registrationDetails.json',
});

const selectors = {
	domain: createSelector(
		defaultSelectors.getStateSlice,
		(slice) => slice.getIn(['data', 'domain']),
	),
	isAvailable: createSelector(
		defaultSelectors.getStateSlice,
		(slice) => !!slice.getIn(['data', 'available']),
	),
	price: createSelector(
		defaultSelectors.getStateSlice,
		(slice) => slice.getIn(['data', 'price']),
	),
	allDomains: createSelector(
		defaultSelectors.getNativeData,
		(slice) => {
			if (slice) {
				const { suggested, ...searchResult } = slice;
				return [searchResult, ...suggested];
			}
			return [];
		},
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };

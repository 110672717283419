import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import MTextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import WarningIcon from '@material-ui/icons/WarningRounded';

const StyledLabel = styled.span`
	${({ theme, $error }) => !$error && `color: ${theme.palette.primary.dark};`}
`;

const getInputProps = ({ InputProps, isLoading, error }) => {
	if (isLoading)
		return {
			...InputProps,
			endAdornment: (
				<InputAdornment position="end">
					<CircularProgress />
				</InputAdornment>
			),
		};
	if (error)
		return {
			...InputProps,
			endAdornment: (
				<InputAdornment position="end">
					<WarningIcon color="error" />
				</InputAdornment>
			),
		};
	return InputProps;
};

/**
 * @param {import('@material-ui/core').TextFieldProps} props
 */
const TextField = ({
	isLoading,
	disabled = false,
	variant = 'outlined',
	label,
	error,
	hasError, // TODO: is this used?
	required = false,
	optional = false,
	InputProps,
	input, // passed by magic from redux-forms
	meta: { touched, invalid, error: reduxFormError } = {}, // passed by magic from redux-forms
	placeholder,
	helperText,
	...TextFieldProps
}) => {
	return (
		<MTextField
			InputProps={getInputProps({ InputProps, error: !!error, isLoading })}
			label={
				<>
					{label}
					{label && required && <StyledLabel $error={error}> *</StyledLabel>}
					{label && optional && ' (optional)'}
				</>
			}
			error={!!error || (touched && invalid) || hasError}
			helperText={(touched && reduxFormError) || helperText}
			variant={variant}
			placeholder={placeholder || label}
			type="text" // TODO: do we really need to specify this?
			{...input}
			{...TextFieldProps}
			disabled={isLoading || disabled}
		/>
	);
};

TextField.propTypes = {
	isLoading: PropTypes.bool,
	disabled: PropTypes.bool,
	variant: PropTypes.string,
	label: PropTypes.string,
	error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	required: PropTypes.bool,
	optional: PropTypes.bool,
	InputProps: PropTypes.object,
};

export default TextField;

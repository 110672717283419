const routeActions = {
	ROUTE_PUSH_CURRENT: 'ROUTE_PUSH_CURRENT',
	/**
	 * pushes the new route into slice.current,
	 * slice.current to slice.previous,
	 * and slice.previous into the abyss.
	 * @param {string} param0.route
	 * @returns {object}
	 */
	pushCurrentRoute: ({ route }) => ({
		type: routeActions.ROUTE_PUSH_CURRENT,
		route,
	}),
	ROUTE_GO_BACK: 'ROUTE_GO_BACK',
	/**
	 * Sends the user back to the previous route only if that route is in the app.
	 * @returns {object}
	 */
	goBack: () => ({
		type: routeActions.ROUTE_GO_BACK,
	}),
	ROUTE_CLEAR: 'ROUTE_CLEAR',
	/**
	 * Clears out slice.routeHistory. Useful for testing.
	 * @returns {object}
	 */
	clear: () => ({
		type: routeActions.ROUTE_CLEAR,
	}),
	ROUTE_TAKE_TO_HOMEPAGE: 'ROUTE_TAKE_TO_HOMEPAGE',
	/**
	 * Takes the user to the homepage.
	 * @returns {object}
	 */
	takeToHomepage: () => ({
		type: routeActions.ROUTE_TAKE_TO_HOMEPAGE,
	}),
	ROUTE_SMART_NAVIGATE: 'ROUTE_SMART_NAVIGATE',
	/**
	 * DEPRECATED - avoid using as navigating to URLs outside the app via redux
	 * saga can cause unexpected behaviors. If you need to navigate to a URL
	 * outside the app, do so with an anchor tag. If you need to route to an
	 * internal path, use `connected-react-router`'s `push`
	 *
	 * Takes the user to
	 * the specified path. Accepts inbound or ourbound links.
	 * @deprecated
	 * @param {object} param0
	 * @param {string} param0.path - The path to navigate to.
	 * @param {bool} param0.newWindow - Should a new window open? Leave undefined for intuitive behavior.
	 * @returns {object}
	 */
	smartNavigate: ({ path, newWindow }) => ({
		type: routeActions.ROUTE_SMART_NAVIGATE,
		path,
		newWindow,
	}),
};

export default routeActions;

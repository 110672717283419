import { combineReducers } from 'redux';

import domains from './domains';
import generic from './generic';
import packageConfig from '../packageConfig/reducer';
import productConfig from '../productConfig/reducer';

export default combineReducers({
	domains,
	generic,
	packageConfig,
	productConfig,
});

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// redux
import { actions as serviceWorkerActions } from 'modules/serviceWorker';
import { useDispatch } from 'react-redux';
import getAppVersion from 'utility/getAppVersion';

// custom conmponents
import LWLink from 'components/common/LWLink';
import CursorBox from 'components/atoms/CursorBox';

const SCursorBox = styled(CursorBox)`
	gap: ${({ theme }) => theme.spacing(0.5)}px;
`;

const AppInfo = ({ darkBackground, ...BoxProps }) => {
	const dispatch = useDispatch();
	const handleClick = () => {
		dispatch(
			serviceWorkerActions.launchAppInfoModal({
				dismissible: true,
			}),
		);
	}; // launches the version/update modal.
	const version = getAppVersion({ prefix: true }); // Not a selector. Hardcoded at build time.

	return (
		<SCursorBox
			display="flex"
			flexwrap="nowrap"
			alignItems="center"
			onClick={handleClick}
			{...BoxProps}
		>
			<LWLink underline="none" darkBackground={darkBackground}>
				MLW {version}
			</LWLink>
		</SCursorBox>
	);
};

AppInfo.propTypes = {
	darkBackground: PropTypes.bool,
};

export default AppInfo;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from '@material-ui/core/Link';
import Skeleton from '@material-ui/lab/Skeleton';

import { Link as RouterLink } from 'react-router-dom';
import eventCategories from 'utility/constants/analyticEventCategories';

const SLink = styled(Link)`
	display: inline;
	${({ $semiBold }) => ($semiBold ? 'font-weight: 600;' : '')}
	${({ $uppercase }) => ($uppercase ? 'text-transform: uppercase;' : '')}
	${(
		{ $hasClassOrColor, $darkBackground, theme }, // don't override classes (backwards compatible)
	) =>
		$hasClassOrColor
			? ''
			: `
	color: ${
		$darkBackground
			? theme.palette.primary.contrastText
			: theme.palette.primary.main
	};
	&:hover {
		color: ${theme.palette.primary.alt};
	}
	`}
`;

const LWLink = ({
	children,
	to = '#',
	underline = 'always',
	eventOptions = null,
	sendEvent,
	onClick = null,
	isLoading,
	SkeletonProps,
	color,
	darkBackground,
	semiBold,
	uppercase,
	...rest
}) => {
	const parseTo = (href) => {
		const parser = document.createElement('a');
		parser.href = href;
		return parser;
	};
	const isInternal = (href) => {
		const toLocation = parseTo(href);
		return toLocation.host && toLocation.host === window.location.host;
	};
	const myProps = isInternal(to)
		? { to, component: RouterLink }
		: { href: to, target: '_blank', rel: 'noopener noreferrer' };

	if (isLoading) return <Skeleton variant="text" {...SkeletonProps} />;

	return (
		<SLink
			underline={underline}
			variant="inherit"
			color={color}
			$hasClassOrColor={!!rest.classes || !!color || !!rest.className}
			$darkBackground={darkBackground}
			$semiBold={semiBold}
			$uppercase={uppercase}
			onClick={(e) => {
				if (eventOptions) {
					sendEvent({
						category: eventCategories.NAV_INTERACTION,
						...eventOptions,
					});
				}
				if (onClick) {
					onClick(e);
				}
			}}
			{...myProps}
			{...rest}
		>
			{children}
		</SLink>
	);
};

LWLink.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	/** Path to take them to in the app or an external url */
	to: PropTypes.string, // remove required due to large number of console errors TODO: fix this
	/** Changes styles of underline */
	underline: PropTypes.oneOf(['none', 'hover', 'always']),
	/** Options used for google tracking */
	eventOptions: PropTypes.shape({
		category: PropTypes.string,
		action: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
	}),
	/** Change default behavoir of the link */
	onClick: PropTypes.func,
	/** Redux action to trigger GA tracking - passed in by mapDispatchToProps */
	sendEvent: PropTypes.func.isRequired,
	/** MUI Prop we pass along */
	color: PropTypes.string,
	/** Determines whether to show a skeleton loader */
	isLoading: PropTypes.bool,
	/** Props passed to skeleton loader */
	SkeletonProps: PropTypes.object,
};

export default LWLink;

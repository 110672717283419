import { combineReducers } from 'redux';
import backup from './backup/backupReducer';
import image from './image/imageReducer';
import template from './template/templateReducer';
import privateReducer from './private/privateReducer';
import config from './config/configReducer';
import ip from './ip/reducer';

export default combineReducers({
	backup,
	image,
	ip,
	template,
	private: privateReducer,
	config,
});

import actions from './actions';

const initialState = {
	volumesBeingUpdated: [],
	serversBeingAttachedTo: [],
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.ADD_VOLUME_BEING_UPDATED:
			return {
				...state,
				volumesBeingUpdated: [...state.volumesBeingUpdated, payload],
			};

		case actions.REMOVE_VOLUME_BEING_UPDATED:
			return {
				...state,
				volumesBeingUpdated: state.volumesBeingUpdated.filter(
					(key) => key !== payload,
				),
			};

		case actions.ADD_SERVER_BEING_ATTACHED_TO:
			return {
				...state,
				serversBeingAttachedTo: [...state.serversBeingAttachedTo, payload],
			};

		case actions.REMOVE_SERVER_BEING_ATTACHED_TO:
			return {
				...state,
				serversBeingAttachedTo: state.serversBeingAttachedTo.filter(
					(key) => key !== payload,
				),
			};

		default:
			return state;
	}
};

export default reducer;

const adminCartsActions = {
	ADMIN_CARTS_CREATE_NEW_CART: 'ADMIN_CARTS_CREATE_NEW_CART',
	/**
	 * @param {object} params
	 * @param {string} params.opportunityId - the id of opportunity to attach to the new cart
	 * @param {string} [params.name] - the value to be placed in the metadata 'name' field
	 * @param {string} [params.description] - the value to be placed in the metadata 'description' field
	 * @param {string} [params.domainToAutoAdd] - domain to be applied to items in the cart
	 */
	createNewCart: ({ opportunityId, name, description, domainToAutoAdd }) => ({
		type: adminCartsActions.ADMIN_CARTS_CREATE_NEW_CART,
		opportunityId,
		name,
		description,
		domainToAutoAdd,
	}),
	ADMIN_CARTS_ATTACH_OPPORTUNITY_ID: 'ADMIN_CARTS_ATTACH_OPPORTUNITY_ID',
	/**
	 * @param {object} params
	 * @param {string} params.opportunityId - the id of opportunity to attach to the new cart
	 */
	attachOpportunityId: ({ opportunityId }) => ({
		type: adminCartsActions.ADMIN_CARTS_ATTACH_OPPORTUNITY_ID,
		opportunityId,
	}),
};

export default adminCartsActions;

import * as React from 'react';
import makeGraphData from 'modules/queries/asset/measurements/makeGraphData';
import useAssetMeasurementsLookup from 'modules/queries/asset/measurements/useLookup';
import Graph from 'components/molecules/Graph';

const Network = ({ uniqId }) => {
	const { data, isLoading, isError } = useAssetMeasurementsLookup({
		uniq_id: uniqId,
		name: 'cloud_network_kbit',
		fields: ['received_kbps', 'sent_kbps'],
		tag: {
			// iface ${uniqId}_0 is public network traffic
			name: 'iface',
			value: `${uniqId}_0`,
		},
		duration: '3day',
	});

	const graphData = makeGraphData({
		data: data?.data,
		keys: ['Received (kbps)', 'Sent (kbps)'],
	});

	return (
		<Graph
			data={graphData}
			title="Network"
			subtitle="Kilobits per Second - Last 3 Days"
			isLoading={isLoading}
			errorText={isError ? 'Unable to load network usage data' : ''}
		/>
	);
};

export default Network;

/**
 * @typedef {object.<string, string>} SourceData - key/item pairs describing the details of a referral or attribution.
 */

/**
 * @typedef {object} ReferralData
 * @property {string} program - the referral program (usually impactRadius or commission_junction)
 * @property {SourceData} source_data
 */

/**
 * @typedef {object} AttributionDataObj
 * @property {string} source - the attribution source (i.e. affiliate_referral, hubspot, google_analytics)
 * @property {SourceData} source_data
 */

/**
 * @typedef {AttributionDataObj[]} AttributionDataArray
 */

/**
 * @typedef {object} AttributionPayload
 * @property {ReferralData} referral
 * @property {AttributionDataObj} attribution
 */

import Cookies from 'js-cookie';
import { getCjReferralData } from 'utility/tools/cookies';

const cookieToObj = (source, sourceKey, cookieKey) => {
	const cookie = Cookies.get(cookieKey);
	if (cookie) {
		return {
			source,
			source_data: { [sourceKey]: cookie },
		};
	}
	return null;
};

const getGoogleAnalyticsClientId = (gaTrackerId) => {
	if (typeof window.ga?.getAll !== 'function') return null;
	const clientId = window.ga
		?.getAll()
		?.filter((tracker) => tracker?.get('trackingId') === gaTrackerId)?.[0]
		?.get('clientId');
	if (clientId) {
		return {
			source: 'google_analytics',
			source_data: { cid: clientId },
		};
	}
	return null;
};

const parseIfObj = (thingToParse) => {
	if (typeof thingToParse === 'object') return thingToParse;
	if (typeof thingToParse === 'string') {
		try {
			return JSON.parse(thingToParse);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(new Error(`"${thingToParse}" is not valid JSON.`));
		}
	}
	return {};
};

class AttributionData {
	constructor({ gaTrackerId } = {}) {
		this.gaTrackerId = gaTrackerId;
	}

	getReferral = () => {
		const cookie = getCjReferralData();
		if (!cookie) return undefined;

		const { PartnerId: cjpid, AdId: cjaid, SubId: cjsid } = cookie;
		return {
			program: 'commission_junction',
			source_data: { cjpid, cjsid, cjaid },
		};
	};

	getAttribution = () => {
		const attributionArray = [];
		const { program, irpid, iradid, sharedid, clickid } =
			parseIfObj(Cookies.get('lwReferralData')) || {};
		if (program === 'impact_radius') {
			attributionArray.push({
				source: 'affiliate_referral',
				source_data: { program, irpid, iradid, sharedid, clickid },
			});
		}
		const { gspk, gsxid } = parseIfObj(Cookies.get('lwPartnerStack')) || {};
		if (gspk || gsxid) {
			attributionArray.push({
				source: 'partnerstack',
				source_data: { gspk, gsxid },
			});
		}

		attributionArray.push(cookieToObj('hubspot', 'utk', 'hubspotutk'));

		attributionArray.push(getGoogleAnalyticsClientId(this.gaTrackerId));
		const finalArray = attributionArray.filter((elem) => elem);
		return finalArray.length ? finalArray : undefined;
	};

	/** @type {ReferralData} */
	get referral() {
		return this.getReferral();
	}

	/** @type {AttributionDataArray} */
	get attribution() {
		return this.getAttribution();
	}

	/** @type {AttributionPayload} */
	get all() {
		return { referral: this.referral, attribution: this.attribution };
	}
}

export default AttributionData;

import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.account.ssh.key.update;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'ACCOUNT_SSH_KEY_UPDATE',
	method: 'POST',
	url: 'account/ssh/key/update.json',
});

export { actions, reducer, sagas, selectors };

import { createGlobalStyle, css } from 'styled-components';

const baseStyle = css`
	body {
		margin: 0;
		font-family: 'FK Grotesk Neue';
		background-color: ${({ theme }) => theme.palette.background.default};
		overflow-x: hidden; /* This hides the sidesheet from mobile when it should be hidden. */
	}
`;

// Stops page from shifting sizes between content shifts
const overflowFix = css`
	html {
		overflow: hidden;
	}
`;

// Drawer open causes padding on body
const noPaddingDrawer = css`
	body {
		padding: 0 !important;
	}
`;

// Fix box sizing so calc isn't required everywhere with padding
const boxSizingFix = css`
	html {
		box-sizing: border-box;
	}
	*,
	*:before,
	*:after {
		box-sizing: inherit;
	}
`;

const GlobalStyles = createGlobalStyle`
	${baseStyle}
	${boxSizingFix}
	${overflowFix}
	${noPaddingDrawer}
`;

const GlobalStylesStorybook = createGlobalStyle`
${baseStyle}
${boxSizingFix}
${noPaddingDrawer}
`;

export { GlobalStylesStorybook };
export default GlobalStyles;

import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.network.dns.zone.importZoneFile;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_DNS_ZONE_IMPORTZONEFILE',
	method: 'POST',
	url: '/network/dns/zone/importZoneFile.json',
});

export { actions, reducer, sagas, selectors };

const actions = {
	SET_SERVER_TARGET_POWER: 'TARGET_POWER_SET_SERVER_TARGET_POWER',
	setServerTargetPower: ({ uniqId, action, status }) => ({
		type: actions.SET_SERVER_TARGET_POWER,
		uniqId,
		targetPowerData: { action, status },
	}),
};

export default actions;

import { takeEvery, select, call, put, take } from 'redux-saga/effects';
import { selectors as appConfigSelectors } from 'modules/appConfig';
import { actions as vncConsoleActions } from 'modules/api/server/vncConsoleModule';
import snackbarSaga from 'modules/snackbar/sagas/sagas';
import consoleActions from './actions';

function* openConsole({ canSpice, uniqId } = {}) {
	const manageHostname = yield select(appConfigSelectors.getManageHostname);
	const argArray = (() => {
		if (canSpice)
			return [
				`${manageHostname}/manage/console/console.html?server=${uniqId}`,
				`console-${uniqId}`,
				'width=1024,height=940,noopener',
			];
		return [
			`/external/vnc-console/${uniqId}`,
			`console-${uniqId}`,
			'width=1024,height=940',
		];
	})();
	yield call([window, 'open'], ...argArray);
}

function* initVncConsole({ uniqId }) {
	yield put(vncConsoleActions.clear()); // TODO: put a comment explaining why we do this.
	yield put(vncConsoleActions.fetch({ uniq_id: uniqId }));
	const res = yield take([
		vncConsoleActions.setType,
		vncConsoleActions.errorType,
	]);
	if (res?.payload?.url) {
		yield call([window.location, 'assign'], res.payload.url);
	} else
		yield call(snackbarSaga, {
			error: true,
			errorMessage:
				'Something when wrong. Please contact support or try again.',
		});
}

export default function*() {
	yield takeEvery(consoleActions.SERVER_OPEN_CONSOLE, openConsole);
	yield takeEvery(consoleActions.SERVER_INIT_VNC_CONSOLE, initVncConsole);
}

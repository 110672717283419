import React from 'react';

function Database(props) {
	return (
		<svg width={24} height={24} viewBox="0 0 24 24" {...props}>
			<g data-name="Icon-DBPrimary">
				<path data-name="Path 109" d="M0 0h24v24H0z" fill="none" />
				<path
					data-name="Path 108"
					d="M12.002 22c-4.411 0-8-2.242-8-5V7c0-2.756 3.589-5 8-5s8 2.242 8 5v10c0 2.756-3.589 5-8 5zm0-18c-3.308 0-6 1.347-6 3s2.69 3 6 3 6-1.347 6-3-2.694-3-6-3z"
					fill="#007199"
				/>
			</g>
		</svg>
	);
}

export default Database;

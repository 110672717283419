import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';

const getStateSlice = (state) =>
	state.api.network.dns.domain.nameserver.listAssigned;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_DNS_DOMAIN_NAMESERVER_LISTASSIGNED',
	method: 'POST',
	url: '/network/dns/domain/nameserver/listAssigned.json',
});

const nameservers = createSelector(
	defaultSelectors.getStateSlice,
	(slice) =>
		slice.getIn(['data', 'results'], ImmutableList()) ||
		// NEWMAN-1131. We put this here to emulate error behavior if this object is null. But we will need to find a broader solution.
		// /containers/pages/domains/details/registration/RegistrationBox.jsx contains this same verbiage since that's where the error is/should be displaying.
		ImmutableList(['Error fetching nameservers']),
);
const type = createSelector(
	nameservers,
	(slice) => {
		const isDefault = slice.some(
			(ns) => ns === 'ns.liquidweb.com' || ns === 'ns1.liquidweb.com',
		);
		const isNonBranded = slice.some(
			(bool, ns) => ns === 'ns.sourcedns.com' || ns === 'ns1.sourcedns.com',
		);
		if (isDefault) {
			return 'default';
		}
		if (isNonBranded) {
			return 'nonbranded';
		}
		return 'custom';
	},
);

const selectors = {
	nameservers,
	type,
	form: createSelector(
		nameservers,
		type,
		(stateSlice, nsType) => {
			if (stateSlice.isEmpty()) return {};
			return stateSlice.slice(0, 2).reduce(
				(acc, ns, key) => ({
					...acc,
					[`ns${key + 1}`]: ns,
				}),
				{ type: nsType },
			);
		},
	),
	string: createSelector(
		nameservers,
		(slice) => slice.reduce((string, ns) => string + ns.get('name')),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };

import { createSelector } from 'reselect';
import contentKeys from './contentKeys';

const getStateSlice = (state) => state.drawer;

const getOpen = createSelector(
	getStateSlice,
	(slice) => slice.isOpen,
);

const getContentKey = createSelector(
	getStateSlice,
	(slice) => slice.contentKey,
);

const getContentProps = createSelector(
	getStateSlice,
	(slice) => slice.contentProps,
);

const getContentKeyIsNotification = createSelector(
	getContentKey,
	getOpen,
	(contentKey, drawerOpen) =>
		contentKey === contentKeys.NotificationDrawer && drawerOpen,
);

export default {
	getStateSlice,
	getOpen,
	getContentKey,
	getContentKeyIsNotification,
	getContentProps,
};

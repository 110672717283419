import React from 'react';
import PropTypes from 'prop-types';
import LWDialog from 'components/common/LWDialog';
import GenericError from 'components/common/GenericError';
import useServerUpdate from 'modules/queries/server/useUpdate';
import usePrivateParentUpdate from 'modules/queries/storm/private/parent/useUpdate';
import snackbarActions from 'modules/snackbar/snackbarActions';
import { useDispatch } from 'react-redux';
import EditHostnameForm from './EditHostnameForm';

/** @param {import('modules/queries/asset/useDetails').AssetDetails & {isOpen: boolean; onClose: () => void}} props */
const EditHostnameDialog = ({
	uniq_id: uniqId,
	domain,
	isPrivateParent,
	isOpen,
	onClose,
}) => {
	const dispatch = useDispatch();
	const {
		mutate: updateServer,
		isLoading: updateServerIsLoading,
		isError: updateServerIsError,
	} = useServerUpdate();
	const {
		mutate: updatePrivateParent,
		isLoading: updatePrivateParentIsLoading,
		isError: updatePrivateParentIsError,
	} = usePrivateParentUpdate();

	const isLoading = updateServerIsLoading || updatePrivateParentIsLoading;

	const handleSuccess = () => {
		dispatch(
			snackbarActions.pushMessage({
				variant: 'success',
				message: 'Hostname updated successfully.',
			}),
		);
		onClose();
	};

	const handleEditHostname = ({ domain: newDomain }) =>
		isPrivateParent
			? updatePrivateParent(
					{ uniq_id: uniqId, domain: newDomain },
					{ onSuccess: handleSuccess },
			  )
			: updateServer(
					{ uniq_id: uniqId, domain: newDomain },
					{ onSuccess: handleSuccess },
			  );

	return (
		<LWDialog
			title="Rename Server Hostname"
			onClose={onClose}
			maxWidth="sm"
			fullWidth
			open={isOpen}
		>
			<GenericError shown={updateServerIsError || updatePrivateParentIsError}>
				{'Error saving hostname.'}
			</GenericError>
			<EditHostnameForm
				initialValues={{
					domain,
				}}
				isLoading={isLoading}
				isPrivateParent={isPrivateParent}
				onSubmit={handleEditHostname}
				onClose={onClose}
			/>
		</LWDialog>
	);
};

EditHostnameDialog.propTypes = {
	uniq_id: PropTypes.string.isRequired,
	domain: PropTypes.string.isRequired,
	isPrivateParent: PropTypes.bool.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default EditHostnameDialog;

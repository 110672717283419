const labeledTextVariants = [
	'default',
	'indented',
	'secondary',
	'boldedLabel',
	'secondaryDark',
	'secondarySub2',
	'textSecondary',
	'justForYou',
];

const typographyVariants = {
	default: { label: 'subtitle2' },
	indented: { label: 'subtitle1', text: 'body1' },
	secondary: { label: 'body2', text: 'body2' },
	boldedLabel: { label: 'body1', text: 'body1' },
	secondaryDark: { label: 'subtitle2', text: 'subtitle2' },
	secondarySub2: { label: 'subtitle2', text: 'subtitle2' },
	justForYou: { text: 'body2' },
};

const colors = {
	secondary: { text: 'textSecondary' },
	secondaryDark: { label: 'textSecondary', text: 'palette.primary.dark' },
	secondarySub2: { label: 'textSecondary', text: 'textSecondary' },
	justForYou: { text: 'textSecondary' },
};

const bolded = {
	default: { label: true },
	boldedLabel: { label: true },
	secondaryDark: { text: true },
	justForYou: { label: true },
};

const labelBoxProps = {
	default: { mt: 2, mb: 0.5 },
	indented: { mt: 3, mb: 1 },
};

const textBoxProps = {
	indented: { ml: 2, pt: 1 },
};

export {
	labeledTextVariants,
	typographyVariants,
	colors,
	bolded,
	labelBoxProps,
	textBoxProps,
};

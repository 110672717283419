import actions from './actions';

const initialState = {
	initialized: false,
	registration: null,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.SERVICE_WORKER_INIT:
			return {
				...state,
				initialized: true,
			};
		case actions.SERVICE_WORKER_PERSIST_REGISTRATION:
			return {
				...state,
				registration: action.registration || state.registration, // Registration should not be overwritten when undefined in the action.
			};
		case actions.SERVICE_WORKER_RESET:
			return initialState;
		default:
			return state;
	}
};

export default reducer;

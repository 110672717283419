import React from 'react';
import compose from 'utility/compose';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import { domainName } from 'utility/form/validators';
import TextField from 'components/atoms/TextField';
import ThirdpartyExclude from 'components/common/ThirdpartyExclude';

const CREATE_TRANSFER_FORM_ID = 'createIncomingTransfer';

const CreateTransferForm = () => {
	return (
		<form>
			<Field
				name="domain"
				label="Domain"
				component={TextField}
				fullWidth
				margin="normal"
				validate={[(required(), domainName())]}
				variant="outlined"
			/>
			<ThirdpartyExclude>
				<Field
					name="eppCode"
					label="EPP Code"
					component={TextField}
					fullWidth
					margin="normal"
					validate={required()}
					variant="outlined"
				/>
			</ThirdpartyExclude>
		</form>
	);
};

CreateTransferForm.propTypes = {};

export { CREATE_TRANSFER_FORM_ID, CreateTransferForm };

export default compose(
	reduxForm({
		destroyOnUnmount: false,
		form: CREATE_TRANSFER_FORM_ID,
	}),
)(CreateTransferForm);

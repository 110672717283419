import { uniqueId } from 'lodash';

import actions from './snackbarActions';

const initialState = {
	messages: [],
};

const snackbarReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.SNACKBAR_PUSH_MESSAGE: {
			const { type, ...snack } = action;
			const { id = uniqueId(), message, onActionClick } = snack;

			const preventDuplicateId = state.messages.find(
				(snackRow) => id === snackRow.id,
			);
			if (preventDuplicateId) {
				return state;
			}

			const preventDuplicateMessage =
				message &&
				!onActionClick &&
				state.messages.find((snackRow) => message === snackRow.message);
			// preventDuplicate doesn't work right in notistack@1.0.10 and wont show the sixth snackbar
			if (preventDuplicateMessage) {
				return state;
			}

			return {
				...state,
				messages: [
					...state.messages,
					{
						...snack,
						id,
					},
				],
			};
		}
		case actions.SNACKBAR_POP_MESSAGE: {
			const { messages } = state;
			return {
				...state,
				messages: messages.slice(0, -1),
			};
		}
		case actions.SNACKBAR_REMOVE_MESSAGE: {
			const { payload } = action;
			return {
				...state,
				messages: state.messages.filter((message) => message.id !== payload),
			};
		}
		default:
			return state;
	}
};

export default snackbarReducer;

import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Header from './Header';
import Details from './Details';
import Price from './Price';

const icons = [
	'scan',
	'money-push-pins',
	'uptick',
	'locked-browser',
	'network',
];
const variants = ['checkout', 'upsell'];

const CartItem = ({
	item = { productInfo: {}, totals: {} },
	title,
	hideDomain,
	hideConfigure,
	hideClone,
	secondaryInfo,
	details,
	isLoading,
	readOnly,
	hideDetails,
	salesRepEmail = '',
	setRemoved = () => {},
	removed,
	subtitle,
	isNew = false,
	upsellDiscount = null,
	variant = 'checkout',
	icon = null,
	hostname,
}) => {
	const {
		properties,
		product_code: productCode,
		productInfo: {
			product_description: productDescription,
			title: productInfoTitle,
		} = {},
		totals: { item_prepay_once: itemPrepayTotal } = { item_prepay_once: 0 },
		timeUnitTotals: {
			item_price_total: itemPriceTotal = {},
			item_discount_total: itemDiscountTotal = {},
		} = {},
		uuid,
	} = item;
	const propsForHeader = {
		hideDomain,
		hideConfigure,
		hideClone,
		setRemoved,
		removed,
		secondaryInfo,
		readOnly,
		cloneFrom: properties?.uniq_id_for_clone,
		variant,
		isNew,
		icon,
	};
	const propsForPrice = {
		productCode,
		variant,
		upsellDiscount,
	};

	const headerTitle = title || productInfoTitle || productDescription; // Some products may provide a custom title

	const hostnameOrSubtitle = (() => {
		if (subtitle) return subtitle;
		if (hostname) return hostname;
		return '';
	})();

	const projectName = properties?.project?.project_name;

	return (
		<Box width="100%" data-testid="CartItem__Container">
			<Grid container>
				<Grid item xs={12} sm={8}>
					<Header
						title={headerTitle}
						hostnameOrSubtitle={hostnameOrSubtitle}
						projectName={projectName}
						itemUuid={uuid}
						isLoading={isLoading}
						itemDetails={item}
						{...propsForHeader}
					/>
					{!hideDetails && details && (
						<Details
							details={details}
							// TODO, get salesRepEmail from backend and move into a connected version of detials
							salesRepEmail={salesRepEmail}
							variant={variant}
						/>
					)}
				</Grid>
				<Grid item xs={12} sm={4}>
					<Price
						itemPriceTotal={itemPriceTotal}
						itemPrepayTotal={Number(itemPrepayTotal)}
						itemDiscountTotal={itemDiscountTotal}
						isLoading={isLoading}
						{...propsForPrice}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

CartItem.propTypes = {
	/** Item specs from the back end. See ./testData for more info. */
	item: PropTypes.object,
	/** Title that appears at the top of the display */
	title: PropTypes.string,
	/** Secondary text that appears just right of title. Overrides domain if present. */
	subtitle: PropTypes.string,
	/** Data that displays below editing options */
	secondaryInfo: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				value: PropTypes.string,
				subitems: PropTypes.arrayOf(PropTypes.string),
			}),
		]),
	),
	/** data that appears in the "Details" expansion panel. */
	details: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			subtitle: PropTypes.string.isRequired,
			subDetails: PropTypes.arrayOf(
				PropTypes.shape({
					title: PropTypes.string.isRequired,
					subtitle: PropTypes.string.isRequired,
				}),
			),
		}),
	),
	/** Can this item be edited? */
	readOnly: PropTypes.bool,
	/** Is this item loading? */
	isLoading: PropTypes.bool,
	/** Should the domain be visible? */
	hideDomain: PropTypes.bool,
	/** Should we be allow to edit the hostname? */
	hideEditHostname: PropTypes.bool,
	/** Is there a config screen to link to? */
	hideConfigure: PropTypes.bool,
	/** Is this item cloanable? */
	hideClone: PropTypes.bool,
	/** Should the admin section display? */
	isBasketAdmin: PropTypes.bool,
	/** Passed down from parent to Details. */
	salesRepEmail: PropTypes.string,
	/** instructions from parent for hiding item during removal. */
	setRemoved: PropTypes.func,
	/** Has item removal been attempted? */
	removed: PropTypes.bool,
	/** If the "New" highlight should display */
	isNew: PropTypes.bool,
	/** UI variation to display */
	variant: PropTypes.oneOf(variants),
	/** optional icon to display */
	icon: PropTypes.oneOf(icons),
	/** hostname provided by baskets api */
	hostname: PropTypes.string,
};

CartItem.defaultProps = {
	title: undefined,
	isLoading: false,
	readOnly: false,
	hideDomain: false,
	isBasketAdmin: false,
	removed: false,
};

export { variants };

export default CartItem;

import { currency as formatUScurrency } from 'utility/format';

/**
 * Creates an object designed to fetch and structure (for display) the basket item details for most products.
 * @param {object} param0.item
 * @returns {object[]}
 */
const getItemConfigsDetails = (item, priceless) => {
	const formatPrice = ({ priceTotal, timeUnit = 'month' }) => {
		if (priceless) return '';
		if (!Number(priceTotal)) return '';

		const normalizedTimeUnit = timeUnit.replace(/^static-/, '');

		const formatterOverridesByTimeUnit = {
			hour: {
				fixedDigits: 5,
				commas: false,
			},
		};

		const formattedPrice = formatUScurrency(priceTotal, {
			sign: true,
			dollarSign: true,
			...formatterOverridesByTimeUnit[normalizedTimeUnit],
		});

		return ` [${formattedPrice} / ${normalizedTimeUnit}]`;
	};

	// Descriptions can have either %{units} or ${num_units} that do the same thing
	const formatNumUnits = (numUnits, description) =>
		description.replace(/%{.*units}/, numUnits);

	return (
		item.configs
			?.filter(
				(config) =>
					!config.parent_key &&
					config.num_units !== -1 &&
					// If the description is missing, we cannot make a key and have nothing useful to display anyway.
					config.key_description,
			)
			.sort((a, b) => a.key_display_order - b.key_display_order)
			.map((config) => ({
				title: config.key_description,
				value: config.value,
				subtitle: `${formatNumUnits(
					config.num_units,
					config.value_description,
				)}${formatPrice({
					priceTotal: config?.price_total,
					timeUnit: config?.value_price_time_unit,
				})}`,
				subDetails: item.configs
					.filter((c) => c.parent_key === config.key && c.num_units !== -1)
					.map((c) => ({
						title: c.key_description,
						value: c.value,
						subtitle: `${formatNumUnits(
							c.num_units,
							c.value_description,
						)}${formatPrice({
							priceTotal: c?.price_total,
							timeUnit: c?.value_price_time_unit,
						})}`,
					})),
				ptokId: config.ptok,
				ptovId: config.ptov,
				numUnits: config.num_units,
			})) || []
	);
};

const getItemConfigsDetailsPriceless = (item) =>
	getItemConfigsDetails(item, true);

const getRegion = (item) => {
	return item.region;
};

const getProductInfoHelper = (item) => item?.productInfo || {};

const getProperties = (item) => item?.properties || {};

const getItemPriceTotalHelper = (item) => item?.totals?.item_price_total;

const getPrepayOnetimeCostHelper = (item) => item?.totals?.item_prepay_once;
const getProductCycleHelper = (item) =>
	getProductInfoHelper(item)?.cycle?.replace('nth', '');

const getItemPriceWithCycle = (item) => {
	if (getItemPriceTotalHelper(item)) {
		return `${formatUScurrency(
			getItemPriceTotalHelper(item),
		)} / ${getProductCycleHelper(item)}`;
	}
	return '';
};

const getProductDescriptionHelper = (item) =>
	getProductInfoHelper(item).product_description || '';

export const getIsDreg = (productCode) => productCode === 'DREG';

const getItemDomainDetails = (item) => {
	const properties = getProperties(item);
	const productInfo = getProductInfoHelper(item);
	const isDreg = getIsDreg(item?.product_code);

	return {
		assigned_subdomain: properties?.assigned_subdomain,
		custom_domain: properties?.custom_domain,
		custom_subdomain: properties?.custom_subdomain,
		product_subdomain: productInfo?.defaultSubdomain,
		can_omit_domain:
			Boolean(productInfo?.capabilities?.cartOmitDomain) || isDreg,
		fallback_domain: properties?.domain,
	};
};

const getSeriesMap = (items) => {
	const toReturn = {};
	items.forEach((item) => {
		const productInfo = getProductInfoHelper(item);
		const seriesDescription = productInfo.series_description || 'Misc';
		const seriesDisplayOrder = productInfo.series_description
			? productInfo.series_display_order || 0 // use returned display order or 0 if series description is returned
			: Infinity; // use infinity for 'misc'

		// define default obj if none exists for this key
		if (seriesDescription && !toReturn[seriesDescription])
			toReturn[seriesDescription] = {
				seriesDescription,
				seriesDisplayOrder,
				items: [],
			};
		// add this item to the array
		toReturn[seriesDescription].items.push(item);
	});
	return toReturn;
};

const getCartOmitDomain = (item) => {
	const productInfo = getProductInfoHelper(item);
	return Number(productInfo?.capabilities?.cartOmitDomain) === 1;
};

const sortBySeriesDisplayOrder = (series) =>
	Object.values(series).sort(
		(a, b) => a.seriesDisplayOrder - b.seriesDisplayOrder,
	);

const getSkeletons = (amountItemsPending) =>
	Array.from({ length: amountItemsPending }, (elem, i) => ({
		hideDetails: true,
		isLoading: true,
		readOnly: true,
		uuid: `loadingItem${i}`, // necessary to have unique uuids for React key props
	}));

const appendSkeletonLoaders = (series, skeletons) => {
	let newSeries = series;
	if (skeletons.length) {
		// splice in a skeleton loader
		if (series.length)
			newSeries = [
				{
					...series[0], // keep original properties
					items: [
						...series[0].items, // keep original items
						...skeletons,
					],
				},
				...(series.length > 1 ? series.slice(1) : []), // keep other series
			];
		else
			newSeries = [
				{
					items: skeletons,
				},
			];
	}
	return newSeries;
};

const itemHelpers = {
	getCartOmitDomain,
	getRegion,
	getProductInfoHelper,
	getItemConfigsDetails,
	getItemConfigsDetailsPriceless,
	getProperties,
	getItemPriceTotalHelper,
	getPrepayOnetimeCostHelper,
	getProductCycleHelper,
	getItemPriceWithCycle,
	getProductDescriptionHelper,
	getItemDomainDetails,
	getSeriesMap,
	sortBySeriesDisplayOrder,
	getSkeletons,
	appendSkeletonLoaders,
};

export default itemHelpers;

import styled from 'styled-components';
import PaperBox from 'components/molecules/PaperBox';

const ProductOptionTile = styled(PaperBox)`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing(2)}px;
	align-items: center;
	text-align: center;
	cursor: pointer;
	${({ $isSelected, theme }) =>
		$isSelected &&
		`
			background:${theme.palette.primary.faint};
			border: 2px solid ${theme.palette.primary.main};
		`}
`;

export default ProductOptionTile;

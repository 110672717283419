import React from 'react';
import formatBytes from 'utility/formatBytes';
import Graph from 'components/molecules/Graph';
import useAssetMeasurementsLookup from 'modules/queries/asset/measurements/useLookup';
import createBandwidthGraphList from './createBandwidthGraphList';

const Bandwidth = ({ uniqId }) => {
	const { data, isLoading, isError } = useAssetMeasurementsLookup({
		uniq_id: uniqId,
		name: 'network',
		difference: true,
		fields: ['sent', 'received'],
		duration: '3day',
	});

	const graphList = createBandwidthGraphList(data);
	const count = Object.keys(graphList).length;

	if (isLoading || isError || count < 1) {
		return (
			<Graph
				data={[]}
				title="Server Network Traffic"
				subtitle="Network Avg - 3 Days"
				isLoading={isLoading}
				errorText={isError ? "Failed to load server's bandwidth data" : ''}
			/>
		);
	}

	return Object.entries(graphList).map(([name, graphData]) => (
		<Graph
			key={name}
			data={graphData}
			title={`Server Network Traffic - ${name}`}
			subtitle="Network Avg - 3 Days"
			tickFormatter={(value) =>
				value && `${formatBytes(value).rounded} ${formatBytes(value).unit}`
			}
		/>
	));
};

export default Bandwidth;

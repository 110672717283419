import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import LWTypography from 'components/common/LWTypography';
import snackbarActions from 'modules/snackbar/snackbarActions';
import { selectors as notificationsSelectors } from 'modules/api/notifications/currentModule';
import taskSchedulerActions from 'modules/server/taskScheduler/actions';
import useServerStart from 'modules/queries/server/useStart';
import useServerShutdown from 'modules/queries/server/useShutdown';
import { SLWButton } from '../actionStyles';

/** @param {import('modules/queries/asset/useDetails').AssetDetails} props */
const OpenStackActions = ({
	status,
	uniq_id: uniqId,
	capabilities,
	domain,
	powerInfo,
}) => {
	const dispatch = useDispatch();
	const canAutoReboot = !!capabilities?.autoReboot;

	const [showStartConfirmation, setShowStartConfirmation] = useState(false);
	const [showShutdownConfirmation, setShowShutdownConfirmation] = useState(
		false,
	);
	const newestNotifications = useSelector(
		notificationsSelectors.newestPerAsset,
	);

	const { mutate: startServer, isLoading: startIsLoading } = useServerStart();
	const {
		mutate: shutdownServer,
		isLoading: shutdownIsLoading,
	} = useServerShutdown();

	const handleConfirmStart = () => {
		startServer(
			{ uniq_id: uniqId },
			{
				onSuccess: () => {
					dispatch(snackbarActions.pushMessage({ message: 'Server started' }));
				},
			},
		);
	};

	const handleConfirmShutdown = () => {
		shutdownServer(
			{ uniq_id: uniqId },
			{
				onSuccess: () => {
					dispatch(snackbarActions.pushMessage({ message: 'Server shutdown' }));
				},
			},
		);
	};

	const openServerRebootDialog = () => {
		dispatch(
			taskSchedulerActions.openDialog({
				apiParams: { uniq_id: uniqId },
				hostname: domain,
				task: 'reboot',
				canAutoReboot,
			}),
		);
	};

	const notification = newestNotifications.find(
		(x) => x.get('uniq_id') === uniqId,
	);

	const hasNocWorxNotification =
		notification && notification.get('system') === 'Nocworx Workflow';

	const closeDialog = () => {
		setShowStartConfirmation(false);
		setShowShutdownConfirmation(false);
	};

	return (
		<>
			<SLWButton
				onClick={() =>
					openServerRebootDialog({ domain, uniqId, canAutoReboot })
				}
				color="secondary"
				variant="outlined"
				disabled={hasNocWorxNotification || status !== 'Active'}
			>
				Reboot
			</SLWButton>
			{powerInfo.legacyStatus === 'off' && (
				<SLWButton
					onClick={() => {
						setShowStartConfirmation(true);
					}}
					color="secondary"
					variant="outlined"
					isLoading={startIsLoading}
					disabled={hasNocWorxNotification || status !== 'Active'}
				>
					Start
				</SLWButton>
			)}
			{(powerInfo.legacyStatus === 'on' ||
				powerInfo.legacyStatus === 'reboot' ||
				powerInfo.legacyStatus === 'reboot-soft' ||
				powerInfo.legacyStatus === 'resume') && (
				<SLWButton
					onClick={() => {
						setShowShutdownConfirmation(true);
					}}
					color="secondary"
					variant="outlined"
					isLoading={shutdownIsLoading}
					disabled={
						hasNocWorxNotification ||
						status !== 'Active' ||
						powerInfo.legacyStatus === 'reboot' ||
						powerInfo.legacyStatus === 'reboot-soft' ||
						powerInfo.legacyStatus === 'resume'
					}
				>
					Shutdown
				</SLWButton>
			)}
			<ConfirmationDialog
				open={showStartConfirmation}
				onClose={closeDialog}
				title="Confirm Start"
				onConfirm={handleConfirmStart}
			>
				<LWTypography>Are you sure you want to start this server?</LWTypography>
			</ConfirmationDialog>
			<ConfirmationDialog
				open={showShutdownConfirmation}
				onClose={closeDialog}
				title="Confirm Shutdown"
				onConfirm={handleConfirmShutdown}
			>
				<LWTypography>
					Are you sure sure you want to shutdown this server? This does not
					destroy the server and you will continued to be invoiced as normal
					while it is inactive.
				</LWTypography>
			</ConfirmationDialog>
		</>
	);
};

OpenStackActions.propTypes = {
	powerInfo: PropTypes.object,
};

export default OpenStackActions;

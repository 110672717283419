import { all, call } from 'redux-saga/effects';
import { sagas as list } from './listModule';
import { sagas as details } from './detailsModule';
import { sagas as isValid } from './isValidModule';
import { sagas as remove } from './removeModule';
import { sagas as update } from './updateModule';
import measurements from './measurements/measurementsSagas';

export default function* assetSaga() {
	yield all([
		call(list),
		call(isValid),
		call(details),
		call(measurements),
		call(remove),
		call(update),
	]);
}

import { put, takeLatest, select, take } from 'redux-saga/effects';
import { hasGroup } from 'modules/auth/authSelectors';
import {
	actions as contactListActions,
	selectors as contactListSelectors,
	generators as contactListGenerators,
} from 'modules/api/contact/listModule';
import { actions as contactUpdateActions } from 'modules/api/contact/updateModule';
import { merge } from 'lodash';
import actions from './actions';

function* initList() {
	if (yield select(hasGroup)) {
		yield put(contactListActions.init());
	}
}

// Since we need the ids from email and phone in order to update this, they are passed in separately.
// This allows us to let the function worry about whether or the the contact list is available.
/**
 * @param {object} param0
 * @param {object} param0.payload
 * @param {string} param0.newMainEmail
 * @param {string} param0.newMainPhone
 * @returns {boolean} whether or not api call was successful
 */
function* updateList({ payload, newMainEmail, newMainPhone }) {
	// include id in payload to override the default.
	let id = yield select(contactListSelectors.getDefaultContactId);
	if (!id) {
		yield put(contactListActions.fetch());
		// If this errors, it will propagate to the api call as a missing parameter and we'll see it there.
		yield take([contactListActions.setType, contactListActions.errorType]);
		id = yield select(contactListSelectors.getDefaultContactId);
	}

	// These selectors will return `undefined` if nothing is passed in.
	const emails = yield select(
		contactListGenerators.generateNewMainEmail(newMainEmail),
	);
	const phones = yield select(
		contactListGenerators.generateNewMainPhone(newMainPhone),
	);

	const finalPayload = {
		id,
		...merge(payload, { emails }, { phones }),
	};

	yield put(contactUpdateActions.fetch(finalPayload));
	const res = yield take([
		contactUpdateActions.setType,
		contactUpdateActions.errorType,
	]);
	return res.type === contactUpdateActions.errorType;
}

export { updateList };
export default function*() {
	yield takeLatest(actions.INIT_CONTACT_LIST, initList);
	yield takeLatest(actions.UPDATE_CONTACT_LIST, updateList);
}

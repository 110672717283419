import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice: (state) => state.api.server.history,
	actionType: 'SERVER_HISTORY',
	method: 'POST',
	url: '/server/history.json',
});

export { actions, reducer, sagas, selectors };

import { combineReducers } from 'redux';
import auth from './auth/authReducer';
import { reducer as resize } from './resizeModule';
import { reducer as resizeVolume } from './resizeVolumeModule';
import WHM from './WHM/whmReducer';
import { reducer as reboot } from './rebootModule';
import { reducer as resizeStorage } from './resizeStorageModule';
import { reducer as start } from './startModule';
import { reducer as availableFlavors } from './availableFlavorsModule';
import { reducer as availableStorage } from './availableStorageModule';
import { reducer as destroy } from './destroyModule';
import { reducer as shutdown } from './shutdownModule';
import { reducer as update } from './updateModule';
import { reducer as vncConsole } from './vncConsoleModule';
import { reducer as availableConfigs } from './availableConfigsModule';
import { reducer as history } from './historyModule';

export default combineReducers({
	auth,
	availableConfigs,
	availableFlavors,
	availableStorage,
	destroy,
	history,
	reboot,
	resize,
	resizeStorage,
	resizeVolume,
	shutdown,
	start,
	update,
	vncConsole,
	WHM,
});

import React from 'react';
import PropTypes from 'prop-types';
import LWButton from 'components/common/LWButton';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';

const confirmCancelTestIds = {
	ConfirmCancel__SubmitButton: 'ConfirmCancel__SubmitButton',
};

/**
 * @typedef ConfirmCancelI
 * @property {() => void} [cancel]
 * @property {boolean} [cancelDisabled]
 * @property {string} [cancelText]
 * @property {() => void} [confirm]
 * @property {string} [confirmText]
 * @property {'button' | 'submit' | 'reset'} [confirmType]
 * @property {boolean} [disabled]
 * @property {boolean} [isLoading]
 * @property {boolean} [disableSpacing]
 * @property {import('components/common/LWButton/LWButton').LWButtonPropsI['variant']} [variant]
 */

/**
 * @param {ConfirmCancelI} props
 */
const ConfirmCancel = ({
	cancel = () => {},
	cancelDisabled = false,
	cancelText = 'Cancel',
	confirm = () => {},
	confirmText = 'Confirm',
	confirmType = 'button',
	disabled = false,
	isLoading,
	disableSpacing = false,
	variant = 'strong',
}) => (
	<DialogActions disableSpacing={disableSpacing}>
		<Grid container spacing={2} justify="flex-end">
			<Grid item>
				<LWButton variant="subtle" onClick={cancel} disabled={cancelDisabled}>
					{cancelText}
				</LWButton>
			</Grid>
			<Grid item>
				<LWButton
					type={confirmType}
					onClick={confirm}
					variant={variant}
					isLoading={isLoading}
					disabled={disabled}
					data-testid={confirmCancelTestIds.ConfirmCancel__SubmitButton}
				>
					{confirmText}
				</LWButton>
			</Grid>
		</Grid>
	</DialogActions>
);

ConfirmCancel.propTypes = {
	cancelText: PropTypes.string,
	cancel: PropTypes.func,
	confirmText: PropTypes.string,
	confirmType: PropTypes.oneOf(['button', 'submit']),
	confirm: PropTypes.func,
	isLoading: PropTypes.bool,
	variant: PropTypes.string,
};

export { confirmCancelTestIds };
export default ConfirmCancel;

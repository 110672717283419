import { put, takeLatest, call } from 'redux-saga/effects';
import { whatismyipAdditionalSagas } from 'modules/api/utilities/info/whatismyipModule';
import { isOfficeIpAdditionalSagas } from 'modules/api/network/util/isOfficeIpModule';
import internalOnlyActions from './actions';

function* checkInternalUser({ payload }) {
	const guest = payload?.guest;
	// First get the users IP address
	const { data: whatismyipData } = yield call(
		whatismyipAdditionalSagas.fetchDirectly,
		undefined,
		undefined,
		{ guestAuth: Boolean(guest) },
	);
	if (!whatismyipData) {
		return;
	}

	// Next, check if that IP is internal
	const { data: isOfficeIpData } = yield call(
		isOfficeIpAdditionalSagas.fetchDirectly,
		{ ip: whatismyipData.ip },
		undefined,
		{ guestAuth: Boolean(guest) },
	);

	yield put(internalOnlyActions.set(Boolean(isOfficeIpData.isOfficeIp)));
}

export default function* rootSaga() {
	yield takeLatest(internalOnlyActions.internalOnlyCheck, checkInternalUser);
	if (process.env.REACT_APP_CONFIG !== 'prod')
		// Need to verify using "getIsOfficIp" for non-production environments.
		yield call(checkInternalUser, {});
}

import { take, takeLatest, put, select, call } from 'redux-saga/effects';

import { actions as deleteImageActions } from 'modules/api/storm/image/deleteModule';
import { actions as updateImageActions } from 'modules/api/storm/image/updateModule';
import { actions as imageListActions } from 'modules/api/storm/image/listModule';
import { actions as createImageActions } from 'modules/api/storm/image/createModule';
import { actions as backupListActions } from 'modules/api/storm/backup/listModule';
import { actions as templateListActions } from 'modules/api/storm/template/listModule';
import {
	actions as assetDetailsActions,
	selectors as assetDetailsSelectors,
} from 'modules/api/asset/detailsModule';
import {
	actions as productDetailsActions,
	selectors as productDetailsSelectors,
	moduleKeys,
} from 'modules/api/product/detailsModule';
import acronisProductMap from 'utility/acronisProductMap';

/* local redux modules */
import snackbarActions from 'modules/snackbar/snackbarActions';
import snackbarSagas from 'modules/snackbar/sagas';
import { initChangeAcronis } from '../settings/sagas';
import backupsAndImagesActions from './actions';

function* refreshImageList() {
	yield put(
		imageListActions.fetch({
			page_size: 9999,
		}),
	);
}

function* initialize({ uniqId }) {
	if (uniqId) {
		yield put(
			assetDetailsActions.fetch({
				uniq_id: uniqId,
				alsowith: [
					'categories',
					'featureDetails',
					'instance',
					'backupsAndStorage',
					'zone',
				],
			}),
		);

		let result;
		let moduleKey = 'exists';
		while (moduleKey) {
			result = yield take(assetDetailsActions.setType); // need to make sure we get the correct product code here
			moduleKey = result?.moduleKey;
		}
		const type = result?.payload?.type;
		yield put(
			productDetailsActions.fetch(
				{ code: type, include_pov_tags: true },
				moduleKeys.templates,
			),
		);

		yield put(
			backupListActions.fetch({
				uniq_id: uniqId,
				page_size: 9999,
			}),
		);

		if (yield select(assetDetailsSelectors.hasAcronis)) {
			yield call(initChangeAcronis); // TODO: make this function no op if the correct data is already loaded (should be down within src/modules/server/backupsAndImages/settings/sagas.js).
			const productCode = yield select(
				productDetailsSelectors.acronisSelectors.code,
			);
			if (productCode !== acronisProductMap(type)) {
				yield put(
					productDetailsActions.fetch(
						{ code: acronisProductMap(type) },
						moduleKeys.ACRONIS,
					),
				);
			}
		}
	}

	yield put(
		imageListActions.init({
			page_size: 9999,
		}),
	);
	yield put(
		templateListActions.init({
			page_size: 9999,
		}),
	);
}

function* handlePostDeleteImage() {
	yield put(snackbarActions.pushMessage({ message: 'Image deleted' }));
	yield put(deleteImageActions.clear());
	yield refreshImageList();
}

function* handlePostUpdateImage() {
	yield put(snackbarActions.pushMessage({ message: 'Image renamed' }));
	yield put(updateImageActions.clear());
	yield refreshImageList();
}

function* handleCreateImage({ name, uniqId }) {
	yield put(createImageActions.fetch({ name, uniq_id: uniqId }));
	const result = yield take([
		createImageActions.setType,
		createImageActions.errorType,
	]);
	const error = result.type === createImageActions.errorType;
	yield call(snackbarSagas, {
		error,
		errorMessage: 'Failed to create image',
		successContentKey: 'ServerImageCreate',
		successContentProps: { name },
		variant: error ? 'error' : 'info',
	});

	yield put(createImageActions.clear());
	yield refreshImageList();
}

export default function* rootSaga() {
	yield takeLatest(deleteImageActions.setType, handlePostDeleteImage);
	yield takeLatest(updateImageActions.setType, handlePostUpdateImage);
	yield takeLatest(backupsAndImagesActions.CREATE_IMAGE, handleCreateImage);
	yield takeLatest(
		backupsAndImagesActions.SERVER_BACKUPS_IMAGES__INITIALIZE,
		initialize,
	);
}

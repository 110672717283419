import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as StatusIcon } from 'images/statusLed.svg';
import useColor from 'utility/effects/useColor';

const FStatusIcon = ({ color, ...rest }) => {
	return <StatusIcon {...rest} />;
};

const SStatusLed = styled(FStatusIcon)`
	width: 1.5rem;
	height: 1.5rem;
	fill: ${({ color }) => color};
`;

const StatusLed = ({ color = 'palette.primary.dark', ...rest }) => {
	const ledColor = useColor(color);

	return <SStatusLed color={ledColor} {...rest} />;
};

StatusLed.propTypes = {
	/** Can be any CSS color, or a theme property */
	color: PropTypes.string,
};

export default StatusLed;

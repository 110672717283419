const gdprWhiteList = {
	AR: true,
	AU: true,
	BO: true,
	BR: true,
	CA: true,
	CL: true,
	CO: true,
	CR: true,
	DZ: true,
	EC: true,
	GY: true,
	HK: true,
	ID: true,
	IL: true,
	IN: true,
	JP: true,
	KR: true,
	MX: true,
	NZ: true,
	PE: true,
	PH: true,
	PK: true,
	PY: true,
	RU: true,
	SA: true,
	SR: true,
	TH: true,
	TN: true,
	TR: true,
	UA: true,
	US: true,
	UY: true,
	VE: true,
	VI: true,
	VN: true,
	ZA: true,
};
export default gdprWhiteList;

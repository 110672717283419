import React from 'react';
import LWTypography from 'components/common/LWTypography';

class NavLinkHelper {
	constructor(props) {
		Object.entries(props).forEach(([key, item]) => {
			this[key] = item;
		});
	}

	get linkContents() {
		const { navLink, classes } = this;
		const BadgeFromConfig = navLink.Badge;
		const contents = (
			<LWTypography
				className={classes?.linkTypography}
				color="palette.primary.dark"
			>
				{navLink.name}
			</LWTypography>
		);
		return BadgeFromConfig ? (
			<BadgeFromConfig>{contents}</BadgeFromConfig>
		) : (
			contents
		);
	}

	get onClick() {
		const { navLink, hosts, openManageRedirectDialog, openDialog } = this;

		if (navLink.configKey) {
			let route;
			if (navLink.configPath) {
				route = `https://${hosts[navLink.configKey]}/${navLink.configPath}`;
			} else {
				route = `https://${hosts[navLink.configKey]}`;
			}

			return () =>
				openDialog({
					title: 'Temporary Detour',
					contentKey: 'DetourDialogContent',
					contentProps: { route },
				});
		}
		if (navLink.notYetImplemented) {
			return openManageRedirectDialog;
		}
		if (navLink.onClick) {
			return navLink.onClick;
		}
		return null;
	}
}

export default NavLinkHelper;

import { cloneDeep } from 'lodash';
import moment from 'moment';
import actions from './actions';

const initialState = {
	'EXAMPLE_ACTION_TYPE.exampleKey': {
		action: {},
		targetTime: moment().valueOf() + 3000,
	},
};

const reducer = (state = initialState, action) => {
	const { type } = action;
	switch (type) {
		case actions.UTILITY_THROTTLE_DISPATCH: {
			const { action: actionForCue, ms, key = 'default' } = action;
			const targetTime = moment().valueOf() + ms;
			return {
				...state,
				[`${actionForCue.type}.${key}`]: { action: actionForCue, targetTime }, // using the action type as the key prevents queueing duplicates
			};
		}
		case actions.UTILITY_REMOVE_TROTTLED_DISPATCH: {
			const { action: actionToRemove, key = 'default' } = action;
			const clonedState = cloneDeep(state);
			delete clonedState[`${actionToRemove.type}.${key}`];
			return clonedState;
		}
		default:
			return state;
	}
};

export default reducer;

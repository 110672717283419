import Immutable from 'immutable';
import { packagePreviewContentKey } from 'components/common/SideSheet/contents/PackagePreviewContents';
import actions from '../actions';

const initialState = Immutable.fromJS({
	isOpen: false,
	title: null,
	contentKey: null,
	contentProps: null,
	cartPreviewOpen: false,
	isLoading: false,
});

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.OPEN:
		case actions.SIDESHEET_OPEN_CART_PREVIEW: // This sidesheet apears on multiple pages. The CartBadge and ProductTile components need to know about cartPreviewOpen.
			return state
				.set('title', action.title)
				.set('isOpen', true)
				.set('isLoading', false)
				.set('contentKey', action.contentKey)
				.set('contentProps', action.contentProps)
				.set('cartPreviewOpen', action.type === 'SIDESHEET_OPEN_CART_PREVIEW');
		case actions.SIDESHEET_OPEN_PACKAGE_PREVIEW:
			return state
				.set('title', action.title)
				.set('isOpen', true)
				.set('isLoading', false)
				.set('cartPreviewOpen', false)
				.set('contentKey', packagePreviewContentKey)
				.set('contentProps', action.contentProps);
		case actions.IS_LOADING:
			return state.set('isOpen', true).set('isLoading', true);
		case actions.CLOSE:
			return state.set('isOpen', false).set('cartPreviewOpen', false);

		case actions.SET_TITLE:
			return state.set('title', action.title);
		default:
			return state;
	}
};

export default reducer;

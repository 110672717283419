// @ts-check
import dayjs from 'dayjs';

/**
 * Converts the raw data from lookup.json to the format expected by recharts
 * @param {object} param0
 * @param {import("club-sauce/public/asset/measurements/index.raw").LWApiPublicAssetMeasurementsLookupResultRawI['data']} param0.data the raw data from the lookup module
 * @param {string[]} param0.keys the keys used to access the data arranged respectively to the order of the raw data.
 */
const makeGraphData = ({ data = [], keys = [] }) =>
	data.map(({ points, time }) => {
		return Object.fromEntries([
			...keys.map((key, keyIdx) => [key, Number(points[keyIdx]?.value)]),
			['time', dayjs.unix(Number(time)).format('MM-DD HH:mm')],
		]);
	});

export default makeGraphData;

import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.network.dns.zone.parkableIps;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_DNS_ZONE_PARKABLEIPS',
	method: 'POST',
	url: '/network/dns/zone/parkableIps.json',
});

const selectors = {
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };

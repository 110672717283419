import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.vpn.removeUser;

const { actions, reducer, sagas, additionalSagas, selectors } = createAPIModule(
	{
		getStateSlice,
		actionType: 'VPN_REMOVEUSER',
		method: 'POST',
		url: '/vpn/removeUser.json',
	},
);

export { actions, reducer, sagas, additionalSagas, selectors };

import React from 'react';
import LWCard from 'components/common/LWCard';
import Services from 'components/Services';
import LWTypography from 'components/common/LWTypography';
import { PropTypes } from 'prop-types';
import Bandwidth from 'components/connectedMolecules/graphs/Bandwidth';
import Load from 'components/connectedMolecules/graphs/Load';
import Memory from 'components/connectedMolecules/graphs/Memory';
import GraphiteBandwidth from 'components/connectedMolecules/graphs/GraphiteBandwidth';

const testIds = { TickStackHealth__Container: 'TickStackHealth__Container' };
const TickStackHealth = ({ uniqId }) => {
	return (
		<LWCard
			title="Server Health"
			data-testid={testIds.TickStackHealth__Container}
		>
			<Load uniqId={uniqId} />
			<Bandwidth uniqId={uniqId} />
			<Memory uniqId={uniqId} />
			<GraphiteBandwidth uniqId={uniqId} />
			<LWTypography variant="h3">Monitored Services</LWTypography>
			<Services uniqId={uniqId} />
		</LWCard>
	);
};

TickStackHealth.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({ uniqId: PropTypes.string }),
	}),
	uniqId: PropTypes.string,
};

export { testIds };
export default TickStackHealth;

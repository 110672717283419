import testUtilActions from 'testUtils/actions';
import actions from './actions';

const initialState = {
	cartInitHasStarted: false,
	cartInitialized: false,
	isCustomerPreview: false,
	isOrderProcessing: false,
};

const reducer = (state = initialState, action) => {
	const { isCustomerPreview } = action.payload || {};
	switch (action.type) {
		case actions.CART_INITIALIZED:
			return {
				...state,
				cartInitialized: true,
			};
		case actions.CART_UNINITIALIZED:
			return {
				...state,
				cartInitialized: false,
			};
		case actions.CART_INIT_HAS_STARTED:
			return { ...state, cartInitHasStarted: action.value };
		case actions.CART_SET_CUSTOMER_PREVIEW: {
			const setTo =
				isCustomerPreview === undefined
					? !state.isCustomerPreview
					: isCustomerPreview;
			return { ...state, isCustomerPreview: setTo };
		}
		case actions.CART_SUBMIT_ORDER: {
			return { ...state, isOrderProcessing: true };
		}
		case actions.CART_RESET_IS_ORDER_PROCESSING: {
			return { ...state, isOrderProcessing: false };
		}
		case testUtilActions.TEST_UTIL_CLEAR_STATE:
			return initialState;
		default:
			return state;
	}
};

export { initialState };
export default reducer;

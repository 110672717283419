import React from 'react';
import PropTypes from 'prop-types';
import compose from 'utility/compose';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import { domainName } from 'utility/form/validators';
import TextField from 'components/atoms/TextField';
import LWTypography from 'components/common/LWTypography';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';

const EditHostnameForm = ({
	isPrivateParent,
	isLoading,
	handleSubmit,
	onClose,
}) => {
	return (
		<>
			<Field
				name="domain"
				label="Server Hostname"
				component={TextField}
				fullWidth
				// Private Parents don't require a domain as a name
				validate={isPrivateParent ? required() : [(required(), domainName())]}
			/>
			{!isPrivateParent && (
				<LWTypography variant="caption">
					Server hostnames cannot have spaces or special characters.
				</LWTypography>
			)}
			<ConfirmCancel
				confirm={handleSubmit}
				confirmText="Rename"
				isLoading={isLoading}
				cancel={onClose}
			/>
		</>
	);
};

EditHostnameForm.propTypes = {
	isPrivateParent: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};

export { EditHostnameForm };

export default compose(
	reduxForm({
		form: 'editHostname',
		enableReinitialize: true,
	}),
)(EditHostnameForm);

import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import LWIconButton from 'components/atoms/LWIconButton';
import LWTypography from 'components/common/LWTypography';

const flexDirectionOptions = ['row', 'column', 'row-reverse', 'column-reverse'];

const spacingDirection = {
	row: 'ml',
	column: 'mt',
	'row-reverse': 'mr',
	'column-reverse': 'mb',
};

const testids = { LabeledIcon__Container: 'LabeledIcon__Container' };

const Icon = ({ icon, isLoading, onIconClick }) => {
	const dispatch = useDispatch();
	if (isLoading) return <Skeleton variant="circle" width="1em" height="1em" />;

	if (onIconClick) {
		const handleClick = () => {
			switch (typeof onIconClick) {
				case 'object':
					dispatch(onIconClick);
					break;
				case 'function':
					onIconClick();
					break;
				default:
					break;
			}
		};
		return (
			<LWIconButton $padding="0" $variant="plain" onClick={handleClick}>
				{icon}
			</LWIconButton>
		);
	}
	return icon;
};
const LabeledIcon = ({
	text,
	textProps,
	flexDirection = 'row',
	icon,
	onIconClick,
	color = 'primary.main',
	spacing = icon ? 0.5 : 0,
	isLoading = false,
	BoxProps,
	display = 'inline-flex', // if you're having trouble with vertical alignment, change this to "flex". Pair this with "justifyContent='flex-end'" to get back the left align.
	'data-testid': datatestid,
	...rest
}) => (
	<Box
		data-testid={datatestid || testids.LabeledIcon__Container}
		display={display}
		flexDirection={flexDirection}
		color={color}
		alignItems="center"
		{...BoxProps}
		{...rest}
	>
		{icon && (
			<Icon icon={icon} isLoading={isLoading} onIconClick={onIconClick} />
		)}
		<Box {...{ [spacingDirection[flexDirection]]: spacing }} display="flex">
			{typeof text === 'string' ? (
				<LWTypography isLoading={isLoading} {...textProps}>
					{text}
				</LWTypography>
			) : (
				text
			)}
		</Box>
	</Box>
);

LabeledIcon.propTypes = {
	/** The text of your labeled icon. */
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	/** supply props to the contained LWTypography. */
	textProps: PropTypes.object,
	/** Use this to change the orientation and order of the arrangement. */
	flexDirection: PropTypes.oneOf(flexDirectionOptions),
	/** The icon you are labeling, optional
	 *  if no icon is passed in this component will just render text
	 */
	icon: PropTypes.node,
	/** The color of the icon. */
	color: PropTypes.string,
	/** Spacing between icon and text */
	spacing: PropTypes.number,
	/** Box display type */
	display: PropTypes.string,
	/** Show loading state */
	isLoading: PropTypes.bool,
	/** Apply these props to box wrapping icon and text */
	BoxProps: PropTypes.object,
	/** A function to execute or action to dispatch when icon is clicked */
	onIconClick: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export { testids };
export default LabeledIcon;

// TODO: Put all Status files in the same folder. See components/common/Status/

import React from 'react';
import PropTypes from 'prop-types';
import GeneralStatus from 'components/common/Status';
import {
	isShutdown,
	isTransitioning,
	isUnknown,
	POWER_ACTION,
	POWER_QUERYING,
	POWER_STATUS,
} from 'utility/powerInfo';
import { useSelector } from 'react-redux';
import { selectors as targetPowerSelectors } from 'modules/server/targetPower';

// These are used to display default status messages based on subaccount status if there are no other notifications
const subaccountStatusDefaults = {
	New: {
		description: 'Building',
		notificationLevel: 'pending',
	},
	Active: {
		description: 'Running',
		notificationLevel: 'normal',
	},
	running: {
		description: 'Running',
		notificationLevel: 'normal',
	},
	'Pending-Activation': {
		description: 'Building',
		notificationLevel: 'pending',
	},
	'Pending-Config': {
		description: 'Configuring',
		notificationLevel: 'warning',
	},
	Disabled: {
		description: 'Disabled',
		notificationLevel: 'high',
	},
	'Pending-Termination': {
		description: 'Terminating',
		notificationLevel: 'warning',
	},
	'': {
		description: '',
		notificationLevel: 'pending',
	},
};

const TARGET_POWER_ACTION_MSG_MAP = {
	[POWER_ACTION.SHUTDOWN]: 'Shutting down server',
	[POWER_ACTION.START]: 'Starting server',
	[POWER_ACTION.REBOOT]: 'Rebooting server',
};

const Status = ({
	uniqId,
	subaccntStatus,
	newestNotifications, // from connected
	powerInfo,
	title,
	listView,
	iconOnly,
	BoxProps,
}) => {
	const targetPower = useSelector(targetPowerSelectors.getTargetPower);

	const getPowerStatus = (displayMessage) => {
		if (
			displayMessage !== 'Running' ||
			powerInfo?.status === POWER_STATUS.UNAVAILABLE
		) {
			return null;
		}

		const targetPowerInfo = targetPower?.[uniqId];

		/*
		Checks if the Power Status from API hasn't arrived at what the UI expects
		after an action. This is a hack due to timing issues as a result of
		polling + unreliable API status during transitions.

		'isPowerTransitioning' indicates that the current API status does not match
		the expected status after the completion of the action. For example - if
		user performs a server shutdown, 'isPowerTransitioning' will become true
		until the API returns a status of `shutdown`.

			********************************* Note *********************************
			'targetPowerInfo &&' below is intentional since power is not
			transitioning when we don't have an entry.
			************************************************************************
		*/
		const isPowerTransitioning =
			targetPowerInfo && targetPowerInfo?.status !== powerInfo?.status;
		if (isPowerTransitioning) {
			return {
				message: TARGET_POWER_ACTION_MSG_MAP[(targetPowerInfo?.action)],
				notificationLevel: 'pending',
			};
		}

		if (isTransitioning(powerInfo)) {
			return {
				message: powerInfo?.message,
				notificationLevel: 'pending',
			};
		}

		if (isShutdown(powerInfo) || isUnknown(powerInfo)) {
			return {
				message: powerInfo?.message,
				notificationLevel: 'disabled',
			};
		}

		return {
			message: powerInfo?.message,
		};
	};

	let boxProps = { ...BoxProps };
	let displayMessage = '';
	let displayNotificationLevel = 'pending';
	if (uniqId) {
		const notification = newestNotifications.find(
			(x) => x.get('uniq_id') === uniqId,
		);

		if (notification) {
			displayMessage = notification.get('last_alert')
				? notification.get('last_alert')
				: notification.get('description');
		} else {
			displayMessage =
				subaccountStatusDefaults[subaccntStatus] &&
				subaccountStatusDefaults[subaccntStatus].description;
		}

		displayNotificationLevel =
			subaccountStatusDefaults[subaccntStatus] &&
			subaccountStatusDefaults[subaccntStatus].notificationLevel;
		if (notification) displayNotificationLevel = 'high';
		if (
			notification &&
			// using startsWith since in dev there are different Provisioning systems that have extra values appended
			(notification.get('system').startsWith('Provisioning') ||
				notification.get('system') === 'Nocworx Workflow') &&
			notification.get('severity') !== 'Critical'
		)
			displayNotificationLevel = 'pending';

		const powerStatus = getPowerStatus(displayMessage);
		if (powerStatus?.message) {
			displayMessage = powerStatus?.message;
		}
		if (powerStatus?.notificationLevel) {
			displayNotificationLevel = powerStatus?.notificationLevel;
		}
		if (displayMessage === POWER_QUERYING) {
			boxProps = { ...BoxProps, fontStyle: 'italic' };
		}
	}

	return (
		<>
			<GeneralStatus
				message={displayMessage}
				level={displayNotificationLevel}
				title={title}
				listView={listView}
				iconOnly={iconOnly}
				BoxProps={boxProps}
			/>
		</>
	);
};

Status.propTypes = {
	uniqId: PropTypes.string,
	subaccntStatus: PropTypes.string,
	newestNotifications: PropTypes.object.isRequired,
	powerInfo: PropTypes.object,
	title: PropTypes.string,
	listView: PropTypes.bool,
	iconOnly: PropTypes.bool,
	BoxProps: PropTypes.object,
};

Status.defaultProps = {
	uniqId: null,
	subaccntStatus: '',
};

export default Status;

import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import LWTypography from 'components/common/LWTypography';

const OutlinedText = ({ text, color = 'primary.main', ...BoxProps }) => (
	<Box
		px={0.5}
		m={0.25}
		border={2}
		borderColor={color}
		borderRadius={4}
		display="inline-flex"
		{...BoxProps}
	>
		<LWTypography variant="caption" color={`palette.${color}`} bold inline>
			{text}
		</LWTypography>
	</Box>
);

OutlinedText.propTypes = {
	text: PropTypes.string.isRequired,
	color: PropTypes.string,
};

export default OutlinedText;

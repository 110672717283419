import React from 'react';
import makeGraphData from 'modules/queries/asset/measurements/makeGraphData';
import Graph from 'components/molecules/Graph';
import useAssetMeasurementsLookup from 'modules/queries/asset/measurements/useLookup';

const Load = ({ uniqId }) => {
	const { data, isLoading, isError } = useAssetMeasurementsLookup({
		uniq_id: uniqId,
		name: 'system',
		fields: ['load5'],
		duration: 'day',
	});

	const graphData = makeGraphData({ data: data?.data, keys: ['load'] });

	return (
		<Graph
			data={graphData}
			title="Server Load"
			subtitle="Load Avg - 5 Min - Current Day"
			isLoading={isLoading}
			errorText={isError ? "Failed to load server's load data" : ''}
		/>
	);
};

export default Load;

import actions from './actions';

const defaultState = { routeHistory: {} };

const reducer = (state = defaultState, action) => {
	const { type } = action;
	switch (type) {
		case actions.ROUTE_PUSH_CURRENT:
			// The purpose of this is to keep track of the previous route so we can use it for other selectors.
			// We only keep the previous route, and the current route in state since that's all we need to maintain the previous route.
			return {
				...state,
				routeHistory: {
					previous: state.routeHistory.current,
					current: action.route,
				},
			};
		case actions.ROUTE_CLEAR:
			return { ...state, routeHistory: {} };
		default:
			return state;
	}
};

export default reducer;

import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.api.network.dns.record.list;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_DNS_RECORD_LIST',
	method: 'POST',
	url: '/network/dns/record/list.json',
});

const records = createSelector(
	defaultSelectors.getStateSlice,
	(slice) => slice.getIn(['data', 'items'], null),
);

const selectors = {
	...defaultSelectors,
	records,
};

export { actions, reducer, sagas, selectors };

import { put, select, call } from 'redux-saga/effects';
import {
	stormConfigListActions,
	stormConfigListSelectors,
} from 'modules/api/storm/config/listModule';
import { takeSetOrError } from 'utility/redux/apiModuleHelpers';

/**
 * Fetches storm configs if needed
 * @returns {boolean} ready status
 */
function* initStormConfigList() {
	const isReady = yield select(stormConfigListSelectors.hasData);
	if (isReady) return true;

	yield put(
		stormConfigListActions.init({
			available: 1,
			category: 'all',
			page_size: 999,
		}),
	);

	const configRes = yield call(takeSetOrError, {
		actions: stormConfigListActions,
		selectors: stormConfigListSelectors,
	});

	if (configRes.type === stormConfigListActions.errorType) {
		return false;
	}

	return true;
}

// eslint-disable-next-line import/prefer-default-export
export { initStormConfigList };

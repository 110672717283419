import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.api.account.limits.servers;

const { actions, reducer, sagas, selectors: moduleSelectors } = createAPIModule(
	{
		getStateSlice,
		actionType: 'ACCOUNT_LIMITS_SERVERS',
		url: '/account/limits/servers.json',
	},
);

const limit = createSelector(
	moduleSelectors.getNativeData,
	(slice) => Number(slice?.limit || 0),
);
const used = createSelector(
	moduleSelectors.getNativeData,
	(slice) => Number(slice?.used || 0),
);

const selectors = {
	limit,
	used,
	...moduleSelectors,
};

export { actions, reducer, sagas, selectors };

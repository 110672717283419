// @ts-check
import { useQueryClient } from '@tanstack/react-query';

const useInvalidateAssets = () => {
	const queryClient = useQueryClient();

	/** @param {string} [uniqId] */
	return (uniqId) => {
		queryClient.invalidateQueries({
			queryKey: ['/asset/list'],
		});

		if (uniqId) {
			queryClient.invalidateQueries({
				queryKey: ['/asset/details', { uniq_id: uniqId }],
			});
			queryClient.invalidateQueries({
				queryKey: ['/asset/connections', { uniq_id: uniqId }],
			});
			queryClient.invalidateQueries({
				queryKey: ['/asset/measurements/lookup', { uniq_id: uniqId }],
			});
		}
	};
};

export default useInvalidateAssets;

import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

const paginationContainer = ({ component }) => ({
	display: (
		<Box px={1.5} py={0.5}>
			{component}
		</Box>
	),
	padding: 'none',
});

// pass in an array of buttons and they will be displayed neatly at the end of the container.
const actionButtons = ({ buttons = [] }) => ({
	key: 'actions',
	display: (
		<Grid container spacing={1} justify="flex-end">
			{buttons.map((button) => (
				<Grid item key={button.key}>
					{button}
				</Grid>
			))}
		</Grid>
	),
});

export { paginationContainer, actionButtons };

import React from 'react';
import PropTypes from 'prop-types';
import PaperIcon from 'components/icons/Paper';
import PaperDotted from 'components/icons/PaperDotted';
import Database from 'components/icons/Database';
import DatabaseOutlined from 'components/icons/DatabaseOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

const MES_ICONS = {
	file_replication: {
		host: PaperIcon,
		client: PaperDotted,
	},
	db_replication: {
		primary: Database,
		'read-only': DatabaseOutlined,
	},
};

const MES_LABEL = {
	file_replication: {
		host: 'Host File Replication',
		client: 'Client File Replication',
	},
	db_replication: {
		primary: 'Primary Database Replication',
		'read-only': 'Read-Only Database Replication',
	},
};

const MESIcon = ({ type, role, ...rest }) => {
	if (!type || !role) return null;
	const Icon = MES_ICONS[type][role];
	return Icon ? (
		<Tooltip title={MES_LABEL[type][role]}>
			<IconButton aria-label={MES_LABEL[type][role]}>
				<Icon alt={MES_LABEL[type][role]} {...rest} />
			</IconButton>
		</Tooltip>
	) : null;
};

MESIcon.propTypes = {
	type: PropTypes.string.isRequired,
	role: PropTypes.string.isRequired,
};

export default MESIcon;

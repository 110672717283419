const loadBalancerConstants = {
	PORT: {
		WEB: {
			SRC: 80,
			DEST: 443,
		},
	},
	SERVICE: {
		CUSTOM: 'custom',
		WEB: 'web',
	},
	TAB_INDEX: {
		cloud: 0,
		dedicated: 1,
	},
};

export default loadBalancerConstants;

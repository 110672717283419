const actions = {
	ATTACH_VOLUME: 'blockStorage/ATTACH_VOLUME',
	attachVolume: ({ serverUniqId, volumeUniqId }) => ({
		type: actions.ATTACH_VOLUME,
		payload: {
			serverUniqId,
			volumeUniqId,
		},
	}),

	CREATE_VOLUME: 'blockStorage/CREATE_VOLUME',
	createVolume: ({ domain, size, serverUniqId, region }) => ({
		type: actions.CREATE_VOLUME,
		payload: {
			domain,
			size,
			serverUniqId,
			region,
		},
	}),

	DETACH_VOLUME: 'blockStorage/DETACH_VOLUME',
	detachVolume: ({ serverUniqId, volumeUniqId }) => ({
		type: actions.DETACH_VOLUME,
		payload: {
			serverUniqId,
			volumeUniqId,
		},
	}),

	ADD_VOLUME_BEING_UPDATED: 'blockStorage/ADD_VOLUME_BEING_UPDATED',
	addVolumeBeingUpdated: (uniqId) => ({
		type: actions.ADD_VOLUME_BEING_UPDATED,
		payload: uniqId,
	}),

	REMOVE_VOLUME_BEING_UPDATED: 'blockStorage/REMOVE_VOLUME_BEING_UPDATED',
	removeVolumeBeingUpdated: (uniqId) => ({
		type: actions.REMOVE_VOLUME_BEING_UPDATED,
		payload: uniqId,
	}),

	ADD_SERVER_BEING_ATTACHED_TO: 'blockStorage/ADD_SERVER_BEING_ATTACHED_TO',
	addServerBeingAttachedTo: (uniqId) => ({
		type: actions.ADD_SERVER_BEING_ATTACHED_TO,
		payload: uniqId,
	}),

	REMOVE_SERVER_BEING_ATTACHED_TO:
		'blockStorage/REMOVE_SERVER_BEING_ATTACHED_TO',
	removeServerBeingAttachedTo: (uniqId) => ({
		type: actions.REMOVE_SERVER_BEING_ATTACHED_TO,
		payload: uniqId,
	}),
};

export default actions;

// @ts-check
import { useQuery } from '@tanstack/react-query';
import { getIsStorm } from 'banana-stand/parsers';
import { fromApiBoolean } from 'club-sauce';
import createAssetType from 'utility/createAssetType';
import displayName from 'utility/assetDisplayNames';
import {
	getBeyondHostingPowerStatus,
	getServerInstancePowerInfo,
} from 'utility/powerInfo';
import api from 'modules/queries/api';

/**
 * @param {string} assetType
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetFeatureDetailsRawT} [featureDetails]
 * */
const getManagedDetails = (assetType, featureDetails) => {
	if (!featureDetails) {
		return { managedType: '', managedLevel: '' };
	}

	if (assetType === 'dedicated') {
		const { value: managedType = '', description: managedLevel = '' } =
			featureDetails.ControlPanel || {};
		return { managedType, managedLevel };
	}

	if (assetType === 'cloud') {
		const {
			extra_software: managedType = '',
			support_level: managedLevel = '',
		} = featureDetails.Template || {};
		return { managedType, managedLevel };
	}

	if (assetType === 'open stack') {
		const { value: managedType = '', description: managedLevel = '' } =
			featureDetails.CloudManagement || {};
		return { managedType, managedLevel };
	}

	return { managedType: '', managedLevel: '' };
};

/**
 * @param {object} param0
 * @param {string} param0.assetType
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetFeatureDetailsRawT} [param0.featureDetails]
 * @param {boolean} [param0.isBeyondHosting]
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsResultRawI['nocworxRemoteDetails']} [param0.nocworxRemoteDetails]
 * */
const getOS = ({
	assetType,
	featureDetails,
	nocworxRemoteDetails,
	isBeyondHosting,
}) => {
	if (isBeyondHosting) {
		return nocworxRemoteDetails?.os?.identity || '';
	}

	if (!featureDetails) {
		return '';
	}

	if (assetType === 'dedicated') {
		return (
			featureDetails.centos?.description || featureDetails.OS?.description || ''
		);
	}

	if (assetType === 'cloud') {
		return featureDetails.Template?.description || '';
	}

	if (assetType === 'open stack') {
		return (
			Object.values(featureDetails)?.find((option) =>
				fromApiBoolean(option.capabilities.nocworxImage),
			)?.description || ''
		);
	}

	return '';
};

/**
 * @param {object} param0
 * @param {string} param0.assetType
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetFeatureDetailsRawT} [param0.featureDetails]
 * @param {boolean} [param0.isBeyondHosting]
 * @param {boolean} param0.isPrivateParent
 * @param {boolean} param0.isVmware
 * @param {boolean} param0.isChild
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsResultRawI['nocworxRemoteDetails']} [param0.nocworxRemoteDetails]
 * */
const getCpu = ({
	assetType,
	featureDetails,
	isBeyondHosting,
	isChild,
	isPrivateParent,
	isVmware,
	nocworxRemoteDetails,
}) => {
	if (isBeyondHosting) {
		const cores = nocworxRemoteDetails?.server_details?.cpu;

		if (!cores) return '';

		return cores + (cores > 1 ? ' cores' : ' core');
	}

	if (!featureDetails) {
		return '';
	}

	if (isChild) {
		const cores = featureDetails.ConfigId?.vcpu || 0;

		if (!cores) return '';
		return cores + (cores > 1 ? ' cores' : ' core');
	}

	if (isPrivateParent) {
		const { cpu_cores_per_socket: cores = 0, cpu_sockets: sockets = 0 } =
			featureDetails.ConfigId || {};
		const displayedCores = cores * sockets;

		if (!displayedCores) return '';
		return displayedCores + (displayedCores > 1 ? ' cores' : ' core');
	}

	if (isVmware) {
		const vCpus = featureDetails.vDCvCPU?.num_units;

		return vCpus ? `${vCpus} vCPU's` : '';
	}

	if (assetType === 'open stack') {
		return (
			Object.values(featureDetails).find((option) =>
				fromApiBoolean(option.capabilities.nocworxCPU),
			)?.description || ''
		);
	}

	if (assetType === 'cloud') {
		const { cpu_cores: cpuCores, vcpu } = featureDetails.ConfigId || {};
		const cores = vcpu || cpuCores;

		if (!cores) return '';
		return cores + (cores > 1 ? ' cores' : ' core');
	}

	if (assetType === 'dedicated') {
		return featureDetails.Processor?.description || '';
	}

	return '';
};

/**
 * @param {object} param0
 * @param {string} param0.assetType
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetFeatureDetailsRawT} [param0.featureDetails]
 * @param {boolean} [param0.isBeyondHosting]
 * @param {boolean} param0.isPrivateParent
 * @param {boolean} param0.isChild
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsResultRawI['nocworxRemoteDetails']} [param0.nocworxRemoteDetails]
 * */
const getRam = ({
	assetType,
	featureDetails,
	isBeyondHosting,
	isPrivateParent,
	isChild,
	nocworxRemoteDetails,
}) => {
	if (isBeyondHosting && nocworxRemoteDetails?.server_details?.ram) {
		return `${nocworxRemoteDetails?.server_details?.ram}MB`;
	}

	if (!featureDetails) {
		return '';
	}

	if (isPrivateParent || isChild || assetType === 'cloud') {
		return featureDetails.ConfigId?.memory;
	}

	if (assetType === 'open stack') {
		return (
			Object.values(featureDetails).find((option) =>
				fromApiBoolean(option.capabilities.nocworxMemory),
			)?.description || ''
		);
	}

	if (assetType === 'dedicated') {
		return featureDetails.RAM?.description || '';
	}

	return '';
};

/**
 * @param {object} param0
 * @param {string} param0.assetType
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetFeatureDetailsRawT} [param0.featureDetails]
 * @param {boolean} [param0.isBeyondHosting]
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsResultRawI['nocworxRemoteDetails']} [param0.nocworxRemoteDetails]
 * */
const getHardDrives = ({
	assetType,
	featureDetails,
	isBeyondHosting,
	nocworxRemoteDetails,
}) => {
	if (isBeyondHosting) {
		if (!nocworxRemoteDetails?.server_details) {
			return [];
		}
		return [
			{
				title: 'storage',
				value: `${nocworxRemoteDetails.server_details.disk_space}GB`,
			},
		];
	}

	if (!featureDetails) {
		return [];
	}

	if (assetType === 'open stack') {
		const diskOption = Object.values(featureDetails).find((option) =>
			fromApiBoolean(option.capabilities.nocworxVolumeSize),
		);
		const { num_units: diskValue, description: diskDescription } =
			diskOption || {};

		return [
			{
				title: 'storage',
				value: diskValue ? `${diskValue} GB` : diskDescription || '',
			},
		];
	}

	return Object.entries(featureDetails)
		.filter(
			([key, value]) =>
				key.search(/HD\d/) === 0 && value?.description !== 'No Tertiary Drive',
		)
		.map((drive) => ({ title: drive[0], value: drive[1].description }));
};

/**
 * @param {object} params
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsResultRawI['resourceState']} [params.resourceState]
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsResultRawI['diskDetails']} [params.diskDetails]
 */
const getParentDiskResources = ({
	resourceState,
	diskDetails = { allocated: 0, snapshots: 0 },
}) => {
	if (!resourceState) return null;

	const data = resourceState.find((value) => value.type === 'disk.lvm.volume');
	return data
		? {
				// for display purposes, we need to remove reserved snapshot space from the amounts
				free: Number(data.free) - diskDetails.snapshots,
				used: Number(data.used) - diskDetails.snapshots,
				total: Number(data.total) - diskDetails.snapshots,
		  }
		: null;
};

/** @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsResultRawI['resourceState']} resourceState */
const getParentMemoryResources = (resourceState) => {
	if (!resourceState) return null;
	return resourceState.find((value) => value.type === 'memory') || null;
};

/**
 * @param {boolean} hasAcronis
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetFeatureDetailsRawT} [featureDetails]
 * */
const getBackupPlan = (hasAcronis, featureDetails) => {
	const backupPlanRaw = featureDetails?.LiquidWebBackupPlan;
	if (hasAcronis) return 'Acronis';
	switch (backupPlanRaw?.value) {
		case 'BackupDay':
		case 'CloudDaily':
		case 'Daily':
			return 'Daily';
		case 'BackupQuota':
		case 'Quota':
			return 'Quota';
		default:
			return 'None';
	}
};

/**
 * @param {boolean} canAcronisBackup
 * @param {"Acronis" | "Daily" | "Quota" | "None"} currentBackupPlan
 * */

const getBackupsState = (canAcronisBackup, currentBackupPlan) => ({
	canAcronis: canAcronisBackup,
	currentPlan: currentBackupPlan,
	hasAcronis: currentBackupPlan === 'Acronis',
	hasBackups: currentBackupPlan !== 'None',
	hasLegacyBackups:
		currentBackupPlan === 'Daily' || currentBackupPlan === 'Quota',
});

/**
 * @typedef ExtendedAssetDetails
 * @property {string} [acronisId]
 * @property {string} [acronisStatus]
 * @property {string} assetType
 * @property {string} cpu
 * @property {string} displayType
 * @property {{title: string, value: string}[]} hardDrives
 * @property {boolean} hasBlockStorage
 * @property {boolean} [isBeyondHosting]
 * @property {boolean} isMWPv1
 * @property {boolean} [isOpenStack]
 * @property {boolean} isPrivateParent
 * @property {boolean} isStorm
 * @property {boolean} isVmware
 * @property {string} managedLevel
 * @property {string} managedType
 * @property {string} [mesRole]
 * @property {string} [mesType]
 * @property {string} os
 * @property {{free: number; used: number; total: number;} | null} parentDiskResources
 * @property {import('club-sauce/public/asset/index.raw').ResourceStateRawI | null} parentMemoryResources
 * @property {{ status: string; message: string; legacyStatus?: string; }} powerInfo
 * @property {string | number} [ram]
 * @property {boolean} [hasAcronis]
 * @property {"Acronis" | "Daily" | "Quota" | "None"} [backupPlan]
 * @property {{canAcronis: boolean, currentPlan: "Acronis" | "Daily" | "Quota" | "None", hasAcronis: boolean, hasBackups: boolean, hasLegacyBackups: boolean}} [backupsState]
 * @property {string} [backupQuotaValue]
 */

/**
 * @typedef {ExtendedAssetDetails & import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsResultRawI} AssetDetails
 * */

/**
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsResultRawI} data
 * @return {AssetDetails}
 * */
const supplementDetails = (data) => {
	const { uniq_id: acronisId, status: acronisStatus } =
		data.backupsAndStorage?.acronis || {};
	const assetType = createAssetType(data.categories || []);
	const displayType = displayName(assetType, data.type);
	const hasBlockStorage = Boolean(
		data.backupsAndStorage?.block_storage?.length,
	);
	const backupsAndStorage = data?.backupsAndStorage || {};
	const hasAcronis = 'acronis' in backupsAndStorage;
	const backupPlan = getBackupPlan(hasAcronis, data.featureDetails || {});
	const canAcronisBackup = data?.capabilities?.canAcronisBackup;
	const backupsState = getBackupsState(canAcronisBackup === 1, backupPlan);
	const backupQuotaValue = data.featureDetails?.BackupQuota?.value;
	const isBeyondHosting = data.categories?.includes('BeyondHostingVPS');
	const isChild = Boolean(data.privateParent);
	const isMWPv1 = data.type === 'WP.VM';
	const isOpenStack = data.categories?.includes('NocworxOpenstack');
	const isPrivateParent = data.type === 'SS.PP';
	const isStorm = getIsStorm(data.type);
	const isVmware = data.type === 'VMware.vCloud.vDC';
	const mesRole = data.mesDetails?.mes_role;
	const mesType = data.mesDetails?.mes_type;
	const { managedType, managedLevel } = getManagedDetails(
		assetType,
		data.featureDetails,
	);
	const os = getOS({
		assetType,
		featureDetails: data.featureDetails,
		nocworxRemoteDetails: data.nocworxRemoteDetails,
		isBeyondHosting,
	});
	const cpu = getCpu({
		assetType,
		featureDetails: data.featureDetails,
		isBeyondHosting,
		isChild,
		isPrivateParent,
		isVmware,
	});
	const ram = getRam({
		assetType,
		featureDetails: data.featureDetails,
		isBeyondHosting,
		isChild,
		isPrivateParent,
	});
	const hardDrives = getHardDrives({
		assetType,
		featureDetails: data.featureDetails,
		isBeyondHosting,
		nocworxRemoteDetails: data.nocworxRemoteDetails,
	});
	const powerInfo = isBeyondHosting
		? getBeyondHostingPowerStatus(data.nocworxRemoteDetails?.power_status || '')
		: getServerInstancePowerInfo(data);
	const parentDiskResources = getParentDiskResources({
		resourceState: data.resourceState,
		diskDetails: data.diskDetails,
	});
	const parentMemoryResources = getParentMemoryResources(data.resourceState);
	return {
		...data,
		acronisId,
		acronisStatus,
		assetType,
		cpu,
		displayType,
		hardDrives,
		hasBlockStorage,
		isBeyondHosting,
		isMWPv1,
		isOpenStack,
		isPrivateParent,
		isStorm,
		isVmware,
		managedLevel,
		managedType,
		mesRole,
		mesType,
		os,
		parentDiskResources,
		parentMemoryResources,
		powerInfo,
		ram,
		hasAcronis,
		backupPlan,
		backupsState,
		backupQuotaValue,
	};
};

/**
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsParamsRawI} params
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 */
const useAssetDetails = (params, { enabled } = {}) =>
	useQuery({
		queryKey: ['/asset/details', params],
		queryFn: () => api.asset.apiRaw.details(params),
		enabled,
		select: supplementDetails,
	});

/**
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsParamsRawI} params
 */
export const usePollAssetDetails = (params) =>
	useQuery({
		queryKey: ['/asset/details', params],
		queryFn: () => api.asset.apiRaw.details(params),
		refetchInterval: 30000,
		select: supplementDetails,
	});

export default useAssetDetails;

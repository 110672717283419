import actions from './actions';

const initialState = {
	isStarting: false,
	// There may be more of this in the future. If so, move this to a more generic module (`module/sizes`)?
	width: 0,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.CHAT_SET_IS_STARTING:
			return { ...state, isStarting: action.isStarting };
		case actions.CHAT_SET_WIDTH:
			return { ...state, width: action.width };
		case actions.CHAT_SET_ACTIVE:
			return { ...state, active: action.active };
		default:
			return state;
	}
};

export default reducer;

import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useSnackbarError from 'utility/hooks/useSnackbarError';
import { actions as currentNotificationsActions } from 'modules/api/notifications/currentModule';
import api from 'modules/queries/api';

const useProjectAdd = () => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const snackbarError = useSnackbarError();

	return useMutation({
		mutationFn:
			/** @param {import('club-sauce/public/project/index.raw').LWApiPublicProjectAddParamsRawI} params */
			(params) => api.project.apiRaw.add(params),
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({
				queryKey: ['/project/details', { id: variables.id }],
			});

			queryClient.invalidateQueries({
				queryKey: ['/asset/list'],
			});

			// TODO-DEV-2695 - queryClient.invalidateQueries
			dispatch(currentNotificationsActions.invalidate());
		},
		onError: snackbarError,
	});
};

export default useProjectAdd;

import { createSelector } from 'reselect';

const getStateSlice = (state) => state.internalOnly;

const selectors = {
	getStateSlice,
	isInternalUser: createSelector(
		getStateSlice,
		(slice) => slice.get('isInternalUser'),
	),
};

export default selectors;

const { createSelector } = require('reselect');

const getStateSlice = (state) => state.account.maxWidth;

const getMarginWidth = createSelector(
	getStateSlice,
	(slice) => slice.marginWidth,
);

const maxWidthSelectors = {
	getStateSlice,
	getMarginWidth,
};

export default maxWidthSelectors;

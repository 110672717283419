import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.billing.payment.profile.apply;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'BILLING_PAYMENT_PROFILE_APPLY',
	method: 'POST',
	url: '/billing/payment/profile/apply.json',
});

export { actions, reducer, sagas, selectors };

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuIcon from '@material-ui/icons/MenuRounded';
import { actions as drawerActions, contentKeys } from 'modules/drawer';
import MenuButton from 'components/organisms/MenuButton';
import navSelectors from 'modules/route/nav/selectors';
import Badge from '@material-ui/core/Badge';

const mobileNavToggleButtonTestIds = { icon: 'mobilNavToggleButton__icon' };

const MobileNavToggleButton = () => {
	const dispatch = useDispatch();
	const openMenu = () => {
		dispatch(drawerActions.open({ contentKey: contentKeys.MobileNavMenu }));
	};
	const hasNotifications = useSelector(navSelectors.getHasNotifications);
	return (
		<MenuButton
			icon={
				<Badge variant={hasNotifications ? 'dot' : undefined} color="error">
					<MenuIcon
						data-testid={mobileNavToggleButtonTestIds.icon}
						fontSize="large"
					/>
				</Badge>
			}
			onClick={openMenu}
		/>
	);
};

export { mobileNavToggleButtonTestIds };
export default MobileNavToggleButton;

import { useQuery } from '@tanstack/react-query';
import api from 'modules/queries/api';

/**
 * @param {import('club-sauce/public/storm/config.raw').LWApiPublicStormConfigListParamsRawI} params
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 */
const useStormConfigList = (params, { enabled } = {}) =>
	useQuery({
		queryKey: ['/storm/config/list', params],
		queryFn: () => api.storm.config.apiRaw.list(params),
		enabled,
	});

export default useStormConfigList;

import dayjs from 'dayjs';
import orderBy from 'lodash/orderBy';
import round from 'lodash/round';
import { createSelector } from 'reselect';
import { fromApiBoolean } from 'club-sauce';
import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { mainRole as getMainRole } from 'modules/auth';
import { roles } from 'utility/constants/roles';

const getStateSlice = (state) => state.api.market.cart.details;
const getIsBlacklisted = (state) => getMainRole(state) === roles.TECHNICIAN.key;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
	additionalSagas,
} = createAPIModule({
	getStateSlice,
	actionType: 'MARKET_CART_DETAILS',
	url: '/market/cart/details.json',
	getIsBlacklisted,
});

const getUuid = createSelector(
	defaultSelectors.getNativeData,
	(data) => data?.uuid,
);

const getTotals = createSelector(
	defaultSelectors.getNativeData,
	/** @param {MarketCartDetailsNativeData} data */
	(data) => data?.totals,
);

/**
 * @param {MarketCartDetailsNativeData['totals']} totals
 * @param {string} itemUuid
 */
export const findItemTotals = (totals, itemUuid) => {
	return totals?.summary.items.find((item) => item.item_uuid === itemUuid);
};

/** @param {MarketCartDetailsNativeData['totals']} totals */
export const getDueTodayHelper = (totals) =>
	round(totals?.summary.cart.due_today, 2);

const getDueToday = createSelector(
	getTotals,
	getDueTodayHelper,
);

/** @param {MarketCartDetailsNativeData['totals']} totals */
const getSubtotalHelper = (totals) => round(totals?.summary.cart.cost, 2);

const getSubtotal = createSelector(
	getTotals,
	getSubtotalHelper,
);

/** @param {MarketCartDetailsNativeData['totals']} totals */
const getAmountOffTodayHelper = (totals) =>
	round(totals?.summary.cart.amount_off_today, 2);

const getAmountOffToday = createSelector(
	getTotals,
	getAmountOffTodayHelper,
);

const getTotalsByItemUuid = createSelector(
	getTotals,
	/**
	 * @returns {Record<string, import('club-sauce/public/market/cart/index.raw').MarketCartTotalsItemSummaryI>}
	 */
	(totals) => {
		if (!totals) {
			return {};
		}

		const totalsByItemUuid = {};

		totals.summary.items.forEach((item) => {
			totalsByItemUuid[item.item_uuid] = item;
		});

		return totalsByItemUuid;
	},
);

const getBillingCycleTotals = createSelector(
	getTotals,
	(totals) =>
		totals?.charges.map(({ cycle, billing }) => ({
			cycle,
			amount: round(billing[0].cart.cost.total, 2),
		})),
);

const getProductItemsToRegion = createSelector(
	defaultSelectors.getNativeItems,
	(items) =>
		Object.fromEntries(
			items
				.filter((item) => item.type === 'product')
				.map((item) => [item.uuid, item.details.region_id]),
		),
);

const getItemsByUuid = createSelector(
	defaultSelectors.getNativeItems,
	/** @param {MarketCartDetailsNativeItems} items */
	(items) => Object.fromEntries(items.map((item) => [item.uuid, item])),
);

const getCodesByUuid = createSelector(
	defaultSelectors.getNativeItems,
	(items) => Object.fromEntries(items.map((item) => [item.uuid, item.code])),
);

const getDomainRegistrationDomains = createSelector(
	defaultSelectors.getNativeItems,
	(items) => {
		const ordered = orderBy(items, 'details.properties.domain');
		return ordered.filter(({ code }) => code === 'DREG');
	},
);

const getItemsByCode = createSelector(
	defaultSelectors.getNativeItems,
	/**
	 * @param {MarketCartDetailsNativeItems} items
	 * @returns {Record<string, MarketCartDetailsNativeItems>}
	 */
	(items) => {
		const itemsByCode = {};

		items.forEach((item) => {
			if (itemsByCode[item.code]) {
				itemsByCode[item.code].push(item);
			} else {
				itemsByCode[item.code] = [item];
			}
		});

		return itemsByCode;
	},
);

const getMetadata = createSelector(
	defaultSelectors.getNativeData,
	(data) => data?.metadata || {},
);

const getAccountDetailsVisited = createSelector(
	getMetadata,
	(metadata) => Boolean(metadata.accountDetailsVisited),
);

const getAdditionalInstructions = createSelector(
	getMetadata,
	(metadata) => metadata.additionalInstructions,
);

const getDiscounts = createSelector(
	defaultSelectors.getNativeData,
	/** @param {MarketCartDetailsNativeData} data */
	(data) => data?.discounts,
);

const getAppliedCoupon = createSelector(
	getDiscounts,
	(discounts) => discounts?.find((discount) => discount.type === 'coupon'),
);

const getHasSomeAdminDiscount = createSelector(
	getDiscounts,
	(discounts) =>
		discounts?.some((discount) => discount.type === 'admin') || false,
);

const getSpecialOffersVisited = createSelector(
	getMetadata,
	(metadata) => Boolean(metadata.specialOffersVisited),
);

const getIsExpired = createSelector(
	defaultSelectors.getNativeData,
	/** @param {MarketCartDetailsNativeData} data */
	(data) => {
		return fromApiBoolean(data?.is_expired);
	},
);

const getShowSpecialOffers = createSelector(
	getMetadata,
	(metadata) => Boolean(metadata.showSpecialOffers),
);

/** Ignores the timezone and time portion and just gets the date */
const getDateSimple = createSelector(
	defaultSelectors.getNativeData,
	(data) => data?.expiration.split(' ')[0],
);

const getFormattedExpirationDate = createSelector(
	getDateSimple,
	(date) => dayjs(date).format('YYYY-MM-DD'),
);

const getDisplayFormattedExpirationDate = createSelector(
	getDateSimple,
	(date) => dayjs(date).format('MM/DD/YY'),
);

/** @typedef {{description: string; items: MarketCartProductItem[]; displayOrder: number}} GroupI */

/**
 * Gets an array of each product series existing in the cart
 * sorted by display order. Includes each item in the series.
 * The selector is called `getProductGroups` because it is
 * confusing to have to distinguish between singular and
 * plural `series`
 * */
const getProductGroups = createSelector(
	defaultSelectors.getNativeItems,
	/** @param {MarketCartDetailsNativeItems} items
	 * @returns {GroupI[]} */
	(items) => {
		/** @type {GroupI[]} */
		const groups = [];

		items.forEach((item) => {
			if (item.type !== 'product') {
				return;
			}

			const description = item.details.series_description || 'Misc';

			const existingGroup = groups.find(
				(group) => group.description === description,
			);

			if (existingGroup) {
				existingGroup.items.push(item);
			} else {
				groups.push({
					description,
					items: [item],
					displayOrder: item.details.series_display_order,
				});
			}
		});

		groups.sort((a, b) => {
			if (a.displayOrder === null || a.displayOrder === undefined) {
				return 1;
			}

			if (b.displayOrder === null || b.displayOrder === undefined) {
				return -1;
			}

			return a.displayOrder - b.displayOrder;
		});

		// move 'Misc' to end of array
		const miscIndex = groups.findIndex(
			({ description }) => description === 'Misc',
		);

		if (miscIndex !== -1) {
			const [miscValue] = groups.splice(miscIndex, 1);
			groups.push(miscValue);

			return groups;
		}

		return groups;
	},
);

const getIsQuote = createSelector(
	defaultSelectors.getNativeData,
	(data) => fromApiBoolean(data?.is_quote),
);

const getIsOrderable = createSelector(
	defaultSelectors.getNativeData,
	(data) => fromApiBoolean(data?.orderable),
);

const getAdminData = createSelector(
	defaultSelectors.getNativeData,
	(data) => data?.admin || {},
);

const getOppId = createSelector(
	getAdminData,
	(adminData) => adminData.opportunity_id,
);

const getOppName = createSelector(
	getAdminData,
	(adminData) => adminData.opp_name,
);

const getQuoteId = createSelector(
	getAdminData,
	(adminData) => adminData.quote_id,
);

const getTotalUnits = createSelector(
	defaultSelectors.getNativeItems,
	/** @param {MarketCartDetailsNativeItems} items */
	(items) =>
		items.reduce((accumulator, currentValue) => {
			// legacy carts may have items with undefined units. Assume units is
			// 1 in those cases
			return accumulator + Number(currentValue.units || 1);
		}, 0),
);

const selectors = {
	...defaultSelectors,
	getAccountDetailsVisited,
	getAdditionalInstructions,
	getAdminData,
	getAmountOffToday,
	getAppliedCoupon,
	getBillingCycleTotals,
	getCodesByUuid,
	getDiscounts,
	getDisplayFormattedExpirationDate,
	getDomainRegistrationDomains,
	getDueToday,
	getFormattedExpirationDate,
	getHasSomeAdminDiscount,
	getIsExpired,
	getIsOrderable,
	getIsQuote,
	getItemsByCode,
	getItemsByUuid,
	getMetadata,
	getOppId,
	getOppName,
	getProductGroups,
	getProductItemsToRegion,
	getQuoteId,
	getShowSpecialOffers,
	getSpecialOffersVisited,
	getSubtotal,
	getTotals,
	getTotalsByItemUuid,
	getTotalUnits,
	getUuid,
};

export {
	actions as cartDetailsActions,
	reducer as cartDetailsReducer,
	sagas as cartDetailsSagas,
	selectors as cartDetailsSelectors,
	additionalSagas as cartDetailsAdditionalSagas,
};

import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.network.dns.record.delete;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_DNS_RECORD_DELETE',
	method: 'POST',
	url: '/network/dns/record/delete.json',
});

export { actions, reducer, sagas, selectors };

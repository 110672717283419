import React, { useContext } from 'react';

import Grid from '@material-ui/core/Grid';

import LWTypography from 'components/common/LWTypography';
import LWCheckbox from 'components/atoms/LWCheckbox';

import Context from './context';

const SessionPersistenceInput = () => {
	const {
		isSubmitting,
		sessionPersistence: [sessionPersistence, setSessionPersistence],
	} = useContext(Context);

	const handleSessionPersistenceChange = () =>
		setSessionPersistence(!sessionPersistence);

	return (
		<Grid spacing={2} container>
			<Grid item xs={12}>
				<LWCheckbox
					checked={sessionPersistence}
					onChange={handleSessionPersistenceChange}
					ariaLabel="Session Persistence"
					label={
						<LWTypography bold inline>
							Enable Session Persistence*
						</LWTypography>
					}
					disabled={isSubmitting}
				/>
			</Grid>
			<Grid item xs={1} />
			<Grid item xs={11}>
				<LWTypography inline variant="body2">
					* Installing an SSL Certificate on a load balancer with Session
					Persistence enabled, by default, uses &quot;cookie based&quot;
					persistence, which allows for proper session persistence between HTTP
					and HTTPS, and also allows for proper SourceLogging of SSL
					Connections.
				</LWTypography>
			</Grid>
		</Grid>
	);
};

export default SessionPersistenceInput;

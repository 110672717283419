import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import StarEmptyIcon from '@material-ui/icons/StarBorder';
import LWTypography from 'components/common/LWTypography';
import FavoritesToggle from 'components/structural/FavoritesToggle';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { getIconFromPath } from 'utility/constants/pathIcons';
import LWLink from 'components/common/LWLink';
import { selectors as listForKeySelectors } from 'modules/api/account/user/stateData/listForKeyModule';
import { withStyles } from '@material-ui/core/styles';
import { actions as favoritesActions } from 'modules/favorites';

const styles = (theme) => ({
	starEmptyIcon: {
		float: 'left',
		marginRight: '1em',
		color: theme.palette.text.secondary,
	},
});

const FavoritesListDialogContents = ({ classes }) => {
	const dispatch = useDispatch();
	const isLoading = useSelector(
		listForKeySelectors.favoritesSelectors.isLoading,
	);
	const favoritesData = useSelector(
		listForKeySelectors.favoritesSelectors.getNativeData,
	);
	const [localFavoritesData, setLocalFavoritesData] = useState(null);
	const closeDialog = (path) =>
		dispatch(favoritesActions.closeFavoritesModal({ path }));

	// We dont want to change the list as its updated
	// We want to get a snapshot of the favorites when it opens, and keep it till it closes
	useEffect(() => {
		if (
			!isLoading && // dont set before above fetch finishes
			!localFavoritesData // dont reset after first setting
		) {
			setLocalFavoritesData(favoritesData);
		}
	}, [favoritesData, isLoading, localFavoritesData]);
	const favoritesList = localFavoritesData?.data || [];

	if (!favoritesList.length)
		return (
			<>
				{!isLoading && (
					<StarEmptyIcon fontSize="large" className={classes.starEmptyIcon} />
				)}
				<LWTypography
					variant="body1"
					isLoading={isLoading}
					SkeletonProps={{ width: '75%' }}
				>
					You don’t have any favorites.
				</LWTypography>
				<LWTypography
					variant="body1"
					isLoading={isLoading}
					SkeletonProps={{ width: '55%' }}
				>
					Click the star next to a page title to add it to your favorites.
				</LWTypography>
			</>
		);

	return (
		<List>
			{favoritesList.map(({ path, displayName }, idx) => {
				if (!path || !displayName) return null;
				const Icon = getIconFromPath(path) || null;
				return (
					<ListItem divider={idx !== favoritesList.length - 1} key={path}>
						<ListItemIcon>{Icon && <Icon />}</ListItemIcon>
						<ListItemText
							primary={
								<LWLink onClick={() => closeDialog(path)}>{displayName}</LWLink>
							}
							secondary={path}
						/>
						<ListItemSecondaryAction>
							<FavoritesToggle displayName={displayName} path={path} />
						</ListItemSecondaryAction>
					</ListItem>
				);
			})}
		</List>
	);
};

export default withStyles(styles)(FavoritesListDialogContents);

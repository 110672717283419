import { takeLatest, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import dialogActions from 'modules/dialogs/actions';
import { uniqIdDetailsSync } from 'modules/asset/sagas';
import { alsowith as serverDetailsAlsowith } from 'containers/pages/servers/details/HostingDetails';
import snackbarActions from 'modules/snackbar/snackbarActions';
import snackbarSaga from 'modules/snackbar/sagas/sagas';
import { addToCartSaga, waitForCartInit } from 'modules/cart/sagas';
import {
	handleInitialize,
	retrieveConfigs,
	retrieveRegion,
} from '../productConfig/sagas';
import { initOptionsFromAssetDetails } from '../productConfig/flatDetailsSagas';
import cloneActions from './actions';
import productConfigSelectors from '../productConfig/selectors';

function* addForClone({ uniqId, force, productCode }) {
	yield put(dialogActions.close());
	yield put(
		snackbarActions.pushMessage({
			variant: 'info',
			message: 'Adding clone. You will be redirected.',
		}),
	);

	yield call(waitForCartInit);

	yield call(uniqIdDetailsSync, {
		uniqId,
		alsowith: serverDetailsAlsowith,
		force,
	});

	yield call(handleInitialize, { productCode });

	yield call(initOptionsFromAssetDetails);

	const canNotClone = yield select(productConfigSelectors.getCannotClone);
	if (canNotClone) {
		yield put(snackbarActions.popMessage()); // removes "Adding clone..." message
		yield call(snackbarSaga, {
			error: true,
			errorMessage:
				'The subaccount requested cannot be cloned because an option is no longer available. Please contact support for further assistance',
		});
		return;
	}

	const options = yield call(retrieveConfigs, productCode);
	const regionId = yield call(retrieveRegion, productCode);
	const properties = (yield select(
		productConfigSelectors.getProductProperties,
	))[productCode];

	const item = {
		code: productCode,
		options,
		properties: { ...properties, uniq_id_for_clone: uniqId },
		region_id: regionId,
	};
	yield call(addToCartSaga, {
		cycle: 'monthly',
		item,
	});

	yield put(snackbarActions.popMessage()); // removes "Adding clone..." message

	yield put(push('/cart'));
}

export default function*() {
	yield takeLatest([cloneActions.BASKET_ADD_ITEM_FOR_CLONE], addForClone);
}

import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Custom components
import { ReactComponent as LogoText } from 'images/lw-text-logo.svg';
import OutlinedText from 'components/atoms/OutlinedText';
import NavAppBar from 'components/structural/topbar/NavAppBar';
import NavMenuButtons from 'containers/connectedOrganisms/NavMenuButtons';
import MobileNavToggleButton from 'containers/connectedMolecules/MobileNavToggleButton';
import styled from 'styled-components';
// MUI components
import { actions as routeActions } from 'modules/route';

// redux
import { isBasketAdmin as getIsBasketAdmin } from 'modules/auth';
import { useSelector, useDispatch } from 'react-redux';

const SLogoText = styled(LogoText)`
	&:hover {
		cursor: pointer;
	}
`;

const NavBar = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const isBasketAdmin = useSelector(getIsBasketAdmin);
	const dispatch = useDispatch();
	const takeToHomepage = () => {
		dispatch(routeActions.takeToHomepage());
	};
	return (
		<NavAppBar admin={isBasketAdmin}>
			<div
				style={{
					display: 'flex',
					width: '100%',
					alignItems: 'center',
					justifyContent: 'space-between',
					flexWrap: 'wrap',
				}}
			>
				<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
					<SLogoText onClick={takeToHomepage} />
					{isBasketAdmin && <OutlinedText text="Admin" />}
				</div>
				{!isMobile ? <NavMenuButtons /> : <MobileNavToggleButton />}
			</div>
		</NavAppBar>
	);
};

export default NavBar;

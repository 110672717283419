import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.storage.block.volume.list;

const {
	actions,
	reducer,
	sagas,
	additionalSagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'STORAGE_BLOCK_VOLUME_LIST',
	method: 'POST',
	url: '/storage/block/volume/list.json',
});

const selectors = {
	...defaultSelectors,
};

export { actions, reducer, sagas, additionalSagas, selectors, getStateSlice };

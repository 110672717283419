import { createSelector } from 'reselect';
import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => {
	return state.api.server.WHM.dns.listzones;
};

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'SERVER_WHM_DNS_LISTZONES',
	method: 'POST',
	url: '/server/whm/dns/listzones.json',
});

const getZones = createSelector(
	defaultSelectors.getNativeData,
	(data) => data?.zones,
);

const selectors = {
	...defaultSelectors,
	getZones,
};

export {
	actions as whmDnsListzonesActions,
	reducer,
	sagas,
	selectors as whmDnsListzonesSelectors,
};

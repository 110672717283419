import React from 'react';
import PropTypes from 'prop-types';
import LWLink from 'components/common/LWLink';
import LWTypography from 'components/common/LWTypography';
import LabeledIcon from 'components/atoms/LabeledIcon';
import AssetStatus from 'components/Asset/Status';
import TruncAndTip from 'components/atoms/TruncAndTip';

const map = {
	LabeledIcon,
	LWLink,
	AssetStatus,
	TruncAndTip,
};

const ContentFromKey = ({ contentKey, contentProps, value, ...props }) => {
	const Component = (() => {
		if (map[contentKey]) {
			return map[contentKey];
		}
		return LWTypography;
	})();
	return (
		<Component {...contentProps} {...props}>
			{value}
		</Component>
	);
};

ContentFromKey.propTypes = {
	contentKey: PropTypes.string,
	contentProps: PropTypes.object,
	value: PropTypes.any,
};

export default ContentFromKey;

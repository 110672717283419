import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import connectAndWrap from 'utility/form/connectAndWrap';
import dialogActions from 'modules/dialogs/actions';
import cartActions from 'modules/cart/actions';
import PrivateParentNameFormContents from './PrivateParentNameFormContents';

const formName = 'PrivateParentName';
const getParentName = (state) => state.form?.[formName]?.values?.parentName;

const PrivateParentNameDialogContents = connectAndWrap({ form: formName })(
	PrivateParentNameFormContents,
);

const ConnectedPrivateParentNameDialogContents = ({
	itemUuid,
	initialName,
}) => {
	const dispatch = useDispatch();

	const parentName = useSelector(getParentName);

	const handleConfirm = () => {
		dispatch(dialogActions.close());
		dispatch(
			cartActions.patchItemProperties({
				uuid: itemUuid,
				properties: { domain: parentName },
			}),
		);
	};

	const handleCancel = () => dispatch(dialogActions.close());

	return (
		<PrivateParentNameDialogContents
			initialValues={{ parentName: initialName }}
			parentName={parentName}
			onConfirm={handleConfirm}
			onCancel={handleCancel}
		/>
	);
};

export default ConnectedPrivateParentNameDialogContents;

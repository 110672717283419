const contentKeyToTitleMap = {
	AbandonCartModalContents: 'Abandon Cart?',
	AddAcronisModalContents: 'Switching to Acronis Backups',
	AcronisCancelConfirmationDialogContents: 'Cancel Acronis - Confirmation',
	AcronisCancelDialogContents: 'Cancel Acronis',
	AppVersionModalContents: 'Application Information',
	BackupConfirmationDialogContents: 'Restore Server?',
	BackupRestoreDialogContents: 'Restore Server to a Previous State',
	CreateNewCartModalContents: 'Create New Cart?',
	DeleteDNSZoneDialogContents1: 'Deleting a DNS Zone',
	DeleteDNSZoneDialogContents2: 'Delete DNS Zone?',
	ImageConfirmationDialogContents: 'Reimage Server',
	ImageServerSelectDialogContents: 'Reimage Server',
	TemplateConfirmationDialogContents: 'Reimage Server?',
	HostnameSelectionDialog: 'Hostname Selection',
	PrivateParentNameDialog: 'Edit Private Parent Name',
	SwitchCartDialogContents: 'Switch Cart?',
	EditCartDetailsDialogContents: 'Edit Cart Details',
	FavoritesListDialogContents: 'Favorites',
};

const contentKeyToAddPadding = {
	AppVersionModalContents: true,
	BackupConfirmationDialogContents: true,
	BackupRestoreDialogContents: true,
	CloneServerModalContents: true,
	ImageConfirmationDialogContents: true,
	ImageServerSelectDialogContents: true,
	TemplateConfirmationDialogContents: true,
	DeleteDNSZoneDialogContents1: true,
	DeleteDNSZoneDialogContents2: true,
	DetourDialogContent: true,
	CreateAdminCartDialogContents: true,
	EditCartDetailsDialogContents: true,
	SwitchCartDialogContents: true,
	OpportunityModalContents: true,
};

const contentKeyButtonPadding = {
	AppVersionModalContents: true,
};

const contentKeyToColor = {
	DeleteDNSZoneDialogContents2: 'danger',
};

export {
	contentKeyToTitleMap,
	contentKeyToColor,
	contentKeyToAddPadding,
	contentKeyButtonPadding,
};

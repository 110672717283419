import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { theme } from 'theme';
import LWTypography from 'components/common/LWTypography';
import HookedConfirmCancel from 'components/LWForm/HookedConfirmCancel';
import LWForm from 'components/LWForm';
import HookedTextField from 'components/LWForm/HookedTextField';
import dialogActions from 'modules/dialogs/actions';
import { validateSubDomain, validateDomain } from 'utility/lwForm/validators';
import cartActions from 'modules/cart/actions';

const HostnameFieldsContainer = styled.div`
	display: flex;
	gap: 12px;
	flex-direction: column;

	@media screen and (min-width: ${theme.breakpoints.values.sm}px) {
		flex-direction: row;
	}
`;

const SLWForm = styled(LWForm)`
	display: grid;
	gap: 32px;
`;

/**
 * @param {object} props
 * @param {object} props.itemUuid
 * @param {string} props.domain
 * @param {string} props.subdomain
 */
const HostnameSelection = ({ itemUuid, domain, subdomain }) => {
	const dispatch = useDispatch();

	const [formValues, setFormValues] = useState({
		domain: domain || '',
		subdomain: subdomain || '',
	});
	const [formErrors, setFormErrors] = useState({ domain: '' });

	const handleCancel = () => {
		dispatch(dialogActions.close());
	};

	const handleSubmit = (values) => {
		dispatch(dialogActions.close());
		dispatch(
			cartActions.modifyHostname({
				entityUuid: itemUuid,
				domain: values.domain,
				subdomain: values.subdomain,
			}),
		);
	};

	return (
		<SLWForm
			values={formValues}
			onValuesChange={setFormValues}
			errors={formErrors}
			onErrorsChange={setFormErrors}
			validators={{ domain: validateDomain, subdomain: validateSubDomain }}
			onSubmit={handleSubmit}
		>
			<LWTypography>
				To help identify services on your account and invoices, please assign a
				hostname to your product. If you don’t set a hostname yourself, one will
				be generated automatically.
			</LWTypography>
			<HostnameFieldsContainer>
				<HookedTextField
					name="subdomain"
					label="Subdomain"
					size="small"
					style={{ flex: '1' }}
				/>
				<HookedTextField
					name="domain"
					label="Domain"
					size="small"
					style={{ flex: '3' }}
				/>
			</HostnameFieldsContainer>
			<HookedConfirmCancel confirmText="Save" cancel={handleCancel} />
		</SLWForm>
	);
};

export default HostnameSelection;

import React from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { actions as openIdActions } from 'modules/openid';
import { username as usernameSelector } from 'modules/auth/authSelectors';
import cartActions from 'modules/cart/actions';
import dialogActions from 'modules/dialogs/actions';

// api
import { selectors as accountDetailsSelectors } from 'modules/api/account/detailsModule';

// MUI components
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

// custom components
import LWTypography from 'components/common/LWTypography';
import LWButton from 'components/common/LWButton';

const commonButtonProps = { variant: 'outlined', color: 'secondary' };

const MasqueradeModalContents = () => {
	const dispatch = useDispatch();
	const username = useSelector(usernameSelector);
	const accountNumber = useSelector(accountDetailsSelectors.getAccnt);
	const logout = () => dispatch(openIdActions.logout());
	const claim = () => {
		dispatch(cartActions.claim());
		dispatch(dialogActions.close());
	};

	const usernamePhrase = username ? ` with User ${username}` : '';
	const accountNumberPhrase = accountNumber
		? ` for Account #${accountNumber}`
		: '';
	return (
		// TODO: fix the global dialog so that we don't have to keep adding padding to everything.
		<Box p={1}>
			<Grid container spacing={3} direction="column">
				<Grid item>
					<LWTypography>
						We noticed you are signed in on a customer’s behalf. Would you like
						to claim this cart{usernamePhrase}
						{accountNumberPhrase}?
					</LWTypography>
				</Grid>
				<Grid item>
					<LWTypography bold>
						If this cart claim is abandoned, you will be signed out to clear
						associated cart and session data.
					</LWTypography>
				</Grid>
				<Grid item>
					<DialogActions>
						<LWButton {...commonButtonProps} onClick={logout}>
							Abandon Claim
						</LWButton>
						<LWButton {...commonButtonProps} onClick={claim}>
							Claim Cart
						</LWButton>
					</DialogActions>
				</Grid>
			</Grid>
		</Box>
	);
};

export default MasqueradeModalContents;

const productConfigActions = {
	PRODUCT_CONFIG_INITIALIZE: 'PRODUCT_CONFIG_INITIALIZE',
	initialize: ({ productCode, uuid }) => ({
		type: productConfigActions.PRODUCT_CONFIG_INITIALIZE,
		productCode,
		uuid,
	}),
	SET_PRODUCT_CONFIG_DEPLOY_ONTO: 'SET_PRODUCT_CONFIG_DEPLOY_ONTO',
	setDeployOnto: ({ deployOnto, noop }) => ({
		type: productConfigActions.SET_PRODUCT_CONFIG_DEPLOY_ONTO,
		deployOnto,
		noop,
	}),
	SET_PRODUCT_CONFIG_GROUPS_EXPANDED: 'SET_PRODUCT_CONFIG_GROUPS_EXPANDED',
	setGroupsExpanded: (groupsExpanded) => ({
		type: 'SET_PRODUCT_CONFIG_GROUPS_EXPANDED',
		groupsExpanded,
	}),
	ON_CHANGE_DEPLOY_ONTO: 'ON_CHANGE_DEPLOY_ONTO',
	onChangeDeployOnto: ({ value, confirm }) => ({
		type: productConfigActions.ON_CHANGE_DEPLOY_ONTO,
		value,
		confirm,
	}),
	PRODUCT_CONFIG_SET_ACTIVE_PRODUCT_KEY:
		'PRODUCT_CONFIG_SET_ACTIVE_PRODUCT_KEY',
	setActiveProductKey: ({ productKey }) => ({
		type: productConfigActions.PRODUCT_CONFIG_SET_ACTIVE_PRODUCT_KEY,
		productKey,
	}),
	PRODUCT_CONFIG_CLEAR_ACTIVE_PRODUCT_KEY:
		'PRODUCT_CONFIG_CLEAR_ACTIVE_PRODUCT_KEY',
	clearActiveProductKey: () => ({
		type: productConfigActions.PRODUCT_CONFIG_CLEAR_ACTIVE_PRODUCT_KEY,
	}),
	PRODUCT_CONFIG_PATCH_SELECTED_PRODUCT_OPTIONS:
		'PRODUCT_CONFIG_PATCH_SELECTED_PRODUCT_OPTIONS',
	patchSelectedProductOptions: ({ productKey, options }) => ({
		type: productConfigActions.PRODUCT_CONFIG_PATCH_SELECTED_PRODUCT_OPTIONS,
		productKey,
		options,
	}),
	PRODUCT_CONFIG_PATCH_SELECTED_PRODUCT_REGIONS:
		'PRODUCT_CONFIG_PATCH_SELECTED_PRODUCT_REGIONS',
	patchSelectedProductRegions: ({ productKey, region }) => ({
		type: productConfigActions.PRODUCT_CONFIG_PATCH_SELECTED_PRODUCT_REGIONS,
		productKey,
		region,
	}),
	PRODUCT_CONFIG_CLEAR_FLAT_DETAILS: 'PRODUCT_CONFIG_CLEAR_FLAT_DETAILS',
	clearFlatDetails: () => ({
		type: productConfigActions.PRODUCT_CONFIG_CLEAR_FLAT_DETAILS,
	}),
	PRODUCT_CONFIG_CLEAR_SELECTED_PRODUCT_OPTIONS:
		'PRODUCT_CONFIG_CLEAR_SELECTED_PRODUCT_OPTIONS',
	clearSelectedProductOptions: () => ({
		type: productConfigActions.PRODUCT_CONFIG_CLEAR_SELECTED_PRODUCT_OPTIONS,
	}),
	PRODUCT_CONFIG_CLEAR: 'PRODUCT_CONFIG_CLEAR',
	clear: () => ({
		type: productConfigActions.PRODUCT_CONFIG_CLEAR,
	}),
	PRODUCT_CONFIG_UPDATE_OPTION: 'PRODUCT_CONFIG_UPDATE_OPTION',
	/**
	 * Updates the state of a single option.
	 *
	 * This should never be dispatched by a UI component. Use the smarter cascadeUpdateOption to keep the active states of each option up-to-date.
	 * @param {Object} param0
	 * @param {String} param0.productKey - the slice of selectedProductOptions being changed.
	 * @param {Number} param0.ptokId - the id of the option being set.
	 * @param {Number} param0.ptovId - the id of the value the option is being set to.
	 * @param {Boolean} param0.active - whether or not the option is active.
	 */
	updateOption: ({ productKey, ptokId, ptovId, active, numUnits }) => ({
		type: productConfigActions.PRODUCT_CONFIG_UPDATE_OPTION,
		productKey,
		ptokId,
		ptovId,
		active,
		numUnits,
	}),
	PRODUCT_CONFIG_CASCADE_UPDATE_OPTION: 'PRODUCT_CONFIG_CASCADE_UPDATE_OPTION',
	/**
	 * Updates the state the specified option and updates the active state on all options.
	 *
	 * This should be dispatched by the config UI components.
	 * @param {Object} param0
	 * @param {String} [param0.productKey] - the slice of selectedProductOptions being changed.
	 * @param {Number} [param0.ptokId] - the id of the option being set.
	 * @param {Number} [param0.ptovId] - the id of the value the option is being set to.
	 * @param {Number} [param0.numUnits]
	 */
	cascadeUpdateOption: ({ productKey, ptokId, ptovId, numUnits } = {}) => ({
		type: productConfigActions.PRODUCT_CONFIG_CASCADE_UPDATE_OPTION,
		productKey,
		ptokId,
		ptovId,
		numUnits,
	}),
	PRODUCT_CONFIG_REVERT_CHANGES: 'PRODUCT_CONFIG_REVERT_CHANGES',
	revertChanges: ({ uuid }) => ({
		type: productConfigActions.PRODUCT_CONFIG_REVERT_CHANGES,
		uuid,
	}),
	PRODUCT_CONFIG_RESET_OPTIONS: 'PRODUCT_CONFIG_RESET_OPTIONS',
	/**
	 * Resets (or inits) an item's `selectedProductOptions`
	 * @param {String} param0.productKey
	 * @param {boolean} [param0.resetGeos]
	 * @param {boolean} [param0.clearNonDefaultProperties] if true, should clear certain properties
	 */
	resetOptions: ({ productKey, resetGeos, clearNonDefaultProperties }) => ({
		type: productConfigActions.PRODUCT_CONFIG_RESET_OPTIONS,
		productKey,
		resetGeos,
		clearNonDefaultProperties,
	}),
	PRODUCT_CONFIG_SET_PROPERTIES: 'PRODUCT_CONFIG_SET_PROPERTIES',
	/**
	 * Sets the entire properties object for a productKey
	 * @param {Object} param0
	 * @param {String} param0.productKey - the slice of productProperties being changed.
	 * @param {Object} param0.properties - the object to be set to the product's properties
	 */
	setProperties: ({ productKey, properties }) => ({
		type: productConfigActions.PRODUCT_CONFIG_SET_PROPERTIES,
		productKey,
		properties,
	}),
	PRODUCT_CONFIG_PATCH_PROPERTY: 'PRODUCT_CONFIG_PATCH_PROPERTY',
	/**
	 * @param {Object} param0
	 * @param {String} param0.productKey - the slice of productProperties being changed.
	 * @param {String} param0.key - the key under which to store the property.
	 * @param {*} param0.value - the value to which to set the key.
	 */
	addProperty: ({ productKey, key, value }) => ({
		type: productConfigActions.PRODUCT_CONFIG_PATCH_PROPERTY,
		productKey,
		key,
		value,
	}),
	PRODUCT_CONFIG_DELETE_PROPERTY: 'PRODUCT_CONFIG_DELETE_PROPERTY',
	/**
	 *
	 * @param {Object} param0
	 * @param {String} param0.productKey - the slice of productProperties being changed.
	 * @param {String} param0.key - the key to be deleted.
	 */
	deleteProperty: ({ productKey, key }) => ({
		type: productConfigActions.PRODUCT_CONFIG_DELETE_PROPERTY,
		productKey,
		key,
	}),
	PRODUCT_CONFIG_CLEAR_NON_DEFAULT_PROPERTIES:
		'PRODUCT_CONFIG_CLEAR_NON_DEFAULT_PROPERTIES',
	clearNonDefaultProperties: ({ productKey }) => ({
		type: productConfigActions.PRODUCT_CONFIG_CLEAR_NON_DEFAULT_PROPERTIES,
		productKey,
	}),
	PRODUCT_CONFIG_CHANGE_OS: 'PRODUCT_CONFIG_CHANGE_OS',
	changeOS: ({ productKey, newOS }) => ({
		type: productConfigActions.PRODUCT_CONFIG_CHANGE_OS,
		productKey,
		newOS,
	}),
	PRODUCT_CONFIG_STORE_CHANGED_PRODUCT_CODE:
		'PRODUCT_CONFIG_STORE_CHANGED_PRODUCT_CODE',
	storeChangedProductCode: ({ productKey, newProductCode }) => ({
		type: productConfigActions.PRODUCT_CONFIG_STORE_CHANGED_PRODUCT_CODE,
		productKey,
		newProductCode,
	}),
	PRODUCT_CONFIG_REMOVE_CHANGED_PRODUCT_CODE:
		'PRODUCT_CONFIG_REMOVE_CHANGED_PRODUCT_CODE',
	removeChangedProductCode: ({ productKey }) => ({
		type: productConfigActions.PRODUCT_CONFIG_REMOVE_CHANGED_PRODUCT_CODE,
		productKey,
	}),
	PRODUCT_CONFIG_REVEAL_HIDDEN_OPTIONS: 'PRODUCT_CONFIG_REVEAL_HIDDEN_OPTIONS',
	/**
	 * Reveals the ptok and descending ptoks for admins under the given product key.
	 * @param {object} param0
	 * @param {string} param0.productKey
	 * @param {string|number} param0.ptokId
	 */
	revealHiddenOptions: ({ productKey, ptokId }) => ({
		type: productConfigActions.PRODUCT_CONFIG_REVEAL_HIDDEN_OPTIONS,
		productKey,
		ptokId,
	}),
	PRODUCT_CONFIG_SET_TEMPLATES_SHOW_VALUE:
		'PRODUCT_CONFIG_SET_TEMPLATES_SHOW_VALUE',
	setTemplatesShowValue: ({ value }) => ({
		type: productConfigActions.PRODUCT_CONFIG_SET_TEMPLATES_SHOW_VALUE,
		value,
	}),
	PRODUCT_CONFIG_ACL_SNACKBAR: 'PRODUCT_CONFIG_ACL_SNACKBAR',
	/**
	 * @param {object} param0
	 * @param {string} param0.message */
	aclSnackbar: ({ message }) => ({
		type: productConfigActions.PRODUCT_CONFIG_ACL_SNACKBAR,
		message,
	}),
	PRODUCT_CONFIG_ADD_TO_CART: 'PRODUCT_CONFIG_ADD_TO_CART',
	/**
	 * @param {object} param0
	 * @param {string} param0.productCode */
	addToCart: ({ productCode }) => ({
		type: productConfigActions.PRODUCT_CONFIG_ADD_TO_CART,
		productCode,
	}),
	PRODUCT_CONFIG_QUICK_ADD_TO_CART: 'PRODUCT_CONFIG_QUICK_ADD_TO_CART',
	/**
	 * @param {object} param0
	 * @param {string} param0.productCode
	 * @param {object} param0.properties */
	quickAddToCart: ({ productCode, properties }) => ({
		type: productConfigActions.PRODUCT_CONFIG_QUICK_ADD_TO_CART,
		productCode,
		properties,
	}),
	PRODUCT_CONFIG_ADMIN_QUICK_ADD_TO_CART:
		'PRODUCT_CONFIG_ADMIN_QUICK_ADD_TO_CART',
	/**
	 * @param {object} param0
	 * @param {string} param0.productCode */
	adminQuickAddToCart: ({ productCode }) => ({
		type: productConfigActions.PRODUCT_CONFIG_ADMIN_QUICK_ADD_TO_CART,
		productCode,
	}),
	PRODUCT_CONFIG_SET_QUICKADD_LOADING: 'PRODUCT_CONFIG_SET_QUICKADD_LOADING',
	setQuickaddLoading: ({ productCode }) => ({
		type: productConfigActions.PRODUCT_CONFIG_SET_QUICKADD_LOADING,
		productCode,
	}),
	PRODUCT_CONFIG_UNSET_QUICKADD_LOADING:
		'PRODUCT_CONFIG_UNSET_QUICKADD_LOADING',
	unsetQuickaddLoading: ({ productCode }) => ({
		type: productConfigActions.PRODUCT_CONFIG_UNSET_QUICKADD_LOADING,
		productCode,
	}),
	PRODUCT_CONFIG_MODIFY: 'PRODUCT_CONFIG_MODIFY',
	/**
	 * @param {object} param0
	 * @param {string} param0.uuid
	 */
	modifyProduct: ({ uuid }) => ({
		type: productConfigActions.PRODUCT_CONFIG_MODIFY,
		uuid,
	}),
};

export default productConfigActions;

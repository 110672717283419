const actions = {
	SET_SERVER_RESIZE_DEPLOY_ONTO: 'SET_SERVER_RESIZE_DEPLOY_ONTO',
	setDeployOnto: ({ deployOnto }) => ({
		type: actions.SET_SERVER_RESIZE_DEPLOY_ONTO,
		deployOnto,
	}),
	SET_SERVER_RESIZE_CLOUD_FILTER_SELECTED_VALUE:
		'SET_SERVER_RESIZE_CLOUD_FILTER_SELECTED_VALUE',
	setSelectedValue: ({ value }) => ({
		type: actions.SET_SERVER_RESIZE_CLOUD_FILTER_SELECTED_VALUE,
		value,
	}),
	SET_SERVER_RESIZE_RESET: 'SET_SERVER_RESIZE_RESET',
	resetResize: () => ({
		type: actions.SET_SERVER_RESIZE_RESET,
	}),
};
export default actions;

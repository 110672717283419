import { combineReducers } from 'redux';
import { reducer as createKey } from './createKeyModule';
import { reducer as deleteKey } from './deleteKeyModule';
import { reducer as details } from './detailsModule';

export default combineReducers({
	createKey,
	deleteKey,
	details,
});

import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import snackbarSelectors from 'modules/snackbar/snackbarSelectors';
import snackbarActions from 'modules/snackbar/snackbarActions';
import useSnackbar from './useSnackbar';

const ConnectedSnackbar = (props) => {
	const { id } = props;

	const dispatch = useDispatch();

	const notification = useSelector(snackbarSelectors.getMessageById(id));
	const handleClose = useCallback(() => {
		dispatch(snackbarActions.removeMessage(id));
	}, [id, dispatch]);

	useSnackbar(notification, handleClose);

	return null;
};

ConnectedSnackbar.props = {
	id: PropTypes.string.isRequired,
};

export default ConnectedSnackbar;

import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { required } from 'redux-form-validators';
import countriesMappedToOptions from 'utility/countriesMappedToOptions';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { renderCheckbox, renderAutocomplete } from 'utility/form/renderers';
import TextField from 'components/atoms/TextField';
import {
	getRegionLabel,
	getCityLabel,
	getPostalCodeLabel,
} from 'utility/addressLabel';

import {
	countryOption,
	countryWithRegions,
	stateOption,
	getRegions,
} from 'utility/countryRegionConverter';
import variantConfigs from './variantConfigs';

const TEST_ID = 'AddressFormSection';
const AddressFormSection = ({
	changeFieldValue,
	contact,
	reset, // from redux-form
	selectedCountry,
	showPhysicalAddress,
	variant = 'default',
	isLoading,
}) => {
	const [usePhysicalAddress, setUsePhysicalAddress] = useState(false);
	const stateOptions = useMemo(() => getRegions(selectedCountry), [
		selectedCountry,
	]);

	const { gridItemSettings, gridContainerSettings } = variantConfigs[variant];

	const togglePhysicalAddress = () => {
		if (!usePhysicalAddress && contact) {
			const country = countryWithRegions(contact.country);

			// copy mailing address into form fields
			changeFieldValue('address', contact.address);
			changeFieldValue('address2', contact.address2);
			changeFieldValue('city', contact.city);
			changeFieldValue('state', stateOption(country, contact.state));
			changeFieldValue('country', countryOption(country));
			changeFieldValue('postalCode', contact.postal_code);
			changeFieldValue('usePhysicalAddress', !usePhysicalAddress);
		} else {
			// clear form fields
			reset();
		}
		setUsePhysicalAddress(!usePhysicalAddress);
	};

	return (
		<Grid
			container
			{...gridContainerSettings}
			direction="column"
			data-testid={TEST_ID}
		>
			{showPhysicalAddress && (
				<Grid item>
					{/* Assigning xs and sm here adds padding to the parent. Seems like an MUI bug. */}
					<Box px={1} data-testid="AddressFormSection__UsePhysicalAddress">
						<Field
							aria-label="Use Physical Address"
							label="Use Physical Address"
							name="usePhysicalAddress"
							component={renderCheckbox}
							input={{
								value: usePhysicalAddress,
								onChange: togglePhysicalAddress,
							}}
							disabled={isLoading}
						/>
					</Box>
				</Grid>
			)}
			<Grid item {...gridItemSettings}>
				<Field
					aria-label="Country"
					label="Country"
					name="country"
					id="addressformsection__country"
					component={renderAutocomplete}
					options={countriesMappedToOptions}
					onChange={() => {
						changeFieldValue('taxExempt', null);
						changeFieldValue('taxExemptReason', null);
						changeFieldValue('taxExemptId', null);
						changeFieldValue('state', null);
					}}
					placeholder=""
					validate={[required()]}
					disabled={usePhysicalAddress || isLoading}
					required
				/>
			</Grid>
			<Grid item {...gridItemSettings}>
				<Field
					aria-label="Address1"
					label="Address"
					name="address"
					component={TextField}
					validate={[required()]}
					variant="outlined"
					disabled={usePhysicalAddress || isLoading}
					required
				/>
			</Grid>
			<Grid item {...gridItemSettings}>
				<Field
					aria-label="Address2"
					label="Address Line 2"
					name="address2"
					component={TextField}
					variant="outlined"
					disabled={usePhysicalAddress || isLoading}
					optional
				/>
			</Grid>
			<Grid item {...gridItemSettings}>
				<Field
					aria-label={selectedCountry && getCityLabel(selectedCountry.label)}
					label={selectedCountry && getCityLabel(selectedCountry.label)}
					name="city"
					component={TextField}
					validate={[required()]}
					variant="outlined"
					disabled={usePhysicalAddress || isLoading}
					required
				/>
			</Grid>
			<Grid item {...gridItemSettings}>
				<Field
					aria-label={selectedCountry && getRegionLabel(selectedCountry)}
					label={selectedCountry && getRegionLabel(selectedCountry)}
					name="state"
					id="addressformsection__state"
					component={renderAutocomplete}
					options={stateOptions || []}
					validate={[required()]}
					variant="outlined"
					disabled={usePhysicalAddress || isLoading}
					placeholder=""
					required
				/>
			</Grid>
			<Grid item {...gridItemSettings}>
				<Field
					aria-label="PostalCode"
					label={selectedCountry && getPostalCodeLabel(selectedCountry)}
					name="postalCode"
					component={TextField}
					validate={[required()]}
					variant="outlined"
					disabled={usePhysicalAddress || isLoading}
					required
				/>
			</Grid>
		</Grid>
	);
};

AddressFormSection.propTypes = {
	changeFieldValue: PropTypes.func,
	contact: PropTypes.object,
	reset: PropTypes.func,
	// selectedCountry: PropTypes.shape({
	//	label: PropTypes.string,
	//	value: PropTypes.string,
	// }),
	showPhysicalAddress: PropTypes.bool,
};

export { TEST_ID };
export default AddressFormSection;

const actionType = 'MANAGE_REDIRECT';
const actions = {
	setUrl: (url) => ({
		type: `SET_${actionType}_URL`,
		url,
	}),
	setFeatureAvailableInManage: (featureAvailableInManage) => ({
		type: `SET_${actionType}_IS_FEATURE_AVAILABLE_IN_MANAGE`,
		featureAvailableInManage,
	}),
	[`SET_${actionType}_IS_OPEN`]: `SET_${actionType}_IS_OPEN`,
	setOpen: (url = null, featureAvailableInManage = true) => ({
		type: `SET_${actionType}_IS_OPEN`,
		url,
		featureAvailableInManage,
	}),
};

export default actions;

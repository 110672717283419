const acronis = {
	GET_USAGE: 'ACRONIS_GET_USAGE',
	getUsage: ({ uniqId }) => ({
		type: acronis.GET_USAGE,
		uniqId,
	}),
	GET_SSO: 'ACRONIS_GET_SSO',
	getSSO: ({ uniqId }) => ({
		type: acronis.GET_SSO,
		uniqId,
	}),
	SUBMIT_CANCEL: 'ACRONIS_SUBMIT_CANCEL',
	submitCancel: ({ serverId, uniqId }) => ({
		type: acronis.SUBMIT_CANCEL,
		uniqId,
		serverId,
	}),
	SUBMIT_UPDATE: 'ACRONIS_SUBMIT_UPDATE',
	submitUpdate: ({ features }) => ({
		type: acronis.SUBMIT_UPDATE,
		features,
	}),
	TOGGLE_DIALOG: 'ACRONIS_TOGGLE_DIALOG',
	toggleDialog: (isDialogOpen) => ({
		type: acronis.TOGGLE_DIALOG,
		isDialogOpen,
	}),
	TOGGLE_LOADING: 'ACRONIS_TOGGLE_LOADING',
	toggleLoading: (isLoading) => ({
		type: acronis.TOGGLE_LOADING,
		isLoading,
	}),
	SET_ERROR: 'ACRONIS_SET_ERROR',
	setError: (error) => ({
		type: acronis.SET_ERROR,
		error,
	}),
	SET_SELECTED: 'ACRONIS_SET_SELECTED',
	setSelected: (selected) => ({
		type: acronis.SET_SELECTED,
		selected,
	}),
	ACRONIS_FETCH_LIST: 'ACRONIS_FETCH_LIST',
	fetchList: () => ({
		type: acronis.ACRONIS_FETCH_LIST,
	}),
	ACRONIS_SET_PAGE_SIZE: 'ACRONIS_SET_PAGE_SIZE',
	setPageSize: (pageSize) => ({
		type: acronis.ACRONIS_SET_PAGE_SIZE,
		pageSize,
	}),
	ACRONIS_SET_PAGE_NUM: 'ACRONIS_SET_PAGE_NUM',
	setPageNum: (pageNum) => ({
		type: acronis.ACRONIS_SET_PAGE_NUM,
		pageNum,
	}),
	SET_SELECTED_PRODUCT_OPTION_OBJ: 'SET_SELECTED_PRODUCT_OPTION_OBJ',
	setSelectedProductOptionObj: ({ selectedProductOptionObj }) => ({
		type: acronis.SET_SELECTED_PRODUCT_OPTION_OBJ,
		selectedProductOptionObj,
	}),
};

export default acronis;

import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) =>
	state.api.account.billingAddress.updateOrCreate;

const { actions, reducer, sagas, selectors: moduleSelectors } = createAPIModule(
	{
		getStateSlice,
		actionType: 'ACCOUNT_BILLINGADDRESS_UPDATEORCREATE',
		url: '/account/billingaddress/updateorcreate.json',
	},
);

const selectors = {
	getStateSlice,
	...moduleSelectors,
};

export { actions, reducer, sagas, selectors };

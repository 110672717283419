import { combineReducers } from 'redux';

import { reducer as changeAutoRenew } from './changeAutoRenewModule';
import { reducer as details } from './detailsModule';
import { reducer as renew } from './renewModule';

export default combineReducers({
	details,
	changeAutoRenew,
	renew,
});

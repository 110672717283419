export const POWER_ACTION = {
	REBOOT: 'Reboot',
	SHUTDOWN: 'Shutdown',
	START: 'Start',
};

export const POWER_STATUS = {
	BOOTING: 'Booting',
	REBOOTING: 'Rebooting',
	RUNNING: 'Running',
	SHUTDOWN: 'Shutdown',
	SHUTTING_DOWN: 'Shutting Down',
	UNAVAILABLE: 'Unavailable',
	UNKNOWN: 'Unknown',
};

export const POWER_QUERYING = 'querying status';

export const isStatusAvailable = (serverInstanceDetailedStatus) =>
	Object.values(POWER_STATUS)
		.filter(
			(status) =>
				![POWER_STATUS.UNAVAILABLE, POWER_STATUS.UNKNOWN].includes(status),
		)
		.includes(serverInstanceDetailedStatus);

export const isTransitioning = (powerInfo) =>
	[
		POWER_STATUS.BOOTING,
		POWER_STATUS.REBOOTING,
		POWER_STATUS.SHUTTING_DOWN,
	].includes(powerInfo?.status);

/** @param {{detailed_status?: string, status?: string}} serverInstanceDetailedStatus */
export const getMessage = (serverInstanceDetailedStatus) =>
	serverInstanceDetailedStatus?.detailed_status ||
	serverInstanceDetailedStatus?.status ||
	POWER_QUERYING;

const supportsPowerStatus = (server) =>
	Boolean(server?.instance?.detailedStatus);

/** @param {import("club-sauce/public/asset/index.raw").LWApiPublicAssetDetailsResultRawI} server */
export const getServerInstancePowerInfo = (server) => {
	let status = POWER_STATUS.UNKNOWN;
	if (supportsPowerStatus(server)) {
		status = isStatusAvailable(server?.instance?.detailedStatus?.status)
			? server?.instance?.detailedStatus?.status
			: POWER_STATUS.UNKNOWN;
	} else {
		status = POWER_STATUS.UNAVAILABLE;
	}

	return {
		status,
		message: getMessage(server?.instance?.detailedStatus),
		legacyStatus: server?.powerStatus || '',
	};
};

/** @param {string} nocworxRemoteDetailsPowerStatus */
export const getBeyondHostingPowerStatus = (
	nocworxRemoteDetailsPowerStatus,
) => {
	if (nocworxRemoteDetailsPowerStatus) {
		return {
			status: nocworxRemoteDetailsPowerStatus,
			message:
				nocworxRemoteDetailsPowerStatus === 'off'
					? POWER_STATUS.SHUTDOWN
					: POWER_STATUS.RUNNING,
		};
	}
	return { status: POWER_STATUS.UNKNOWN, message: POWER_QUERYING };
};

export const isRebooting = (powerInfo) =>
	powerInfo?.status === POWER_STATUS.REBOOTING;

export const isRunning = (powerInfo) =>
	powerInfo?.status === POWER_STATUS.RUNNING;

export const isStarting = (powerInfo) =>
	powerInfo?.status === POWER_STATUS.BOOTING;

export const isShutdown = (powerInfo) =>
	powerInfo?.status === POWER_STATUS.SHUTDOWN;

export const isShuttingDown = (powerInfo) =>
	powerInfo?.status === POWER_STATUS.SHUTTING_DOWN;

export const isUnknown = (powerInfo) =>
	powerInfo?.status === POWER_STATUS.UNKNOWN;

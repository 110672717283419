import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCartRounded';

import LWButton from 'components/common/LWButton';
import LWTypography from 'components/common/LWTypography';

import productConfigSelectors from 'modules/basket/productConfig/selectors';
import { cartDetailsSelectors } from 'modules/api/market/cart/detailsModule';
import cartSelectors from 'modules/cart/selectors';
import productConfigActions from 'modules/basket/productConfig/actions';
import cartActions from 'modules/cart/actions';

/**
 * @param {object} params
 * @param {string} params.code
 * @param {boolean} params.isLoading
 * */
export const useQuickAddCaption = ({ code, isLoading }) => {
	const itemsInCart = useSelector(cartDetailsSelectors.getItemsByCode)?.[code];

	return useMemo(() => {
		if (isLoading) {
			return 'Adding...';
		}

		if (!itemsInCart) {
			return '';
		}

		return `Added (${itemsInCart.length}) to Cart!`;
	}, [itemsInCart, isLoading]);
};

/**
 * @param {object} params
 * @param {string} params.code
 * */
export const useQuickAdd = ({ onQuickAdd }) => {
	const dispatch = useDispatch();
	const isCartLocked = useSelector(cartSelectors.getIsCartLocked);

	return () => {
		if (isCartLocked) {
			dispatch(cartActions.openAbandonCartDialog({ onAbandoned: onQuickAdd }));
		} else {
			onQuickAdd();
		}
	};
};

const testids = { QuickAddButton__button: 'QuickAddButton__button' };

const QuickAddButton = ({
	showText = 'none',
	productCode,
	domain = undefined,
	relatedSubaccnt = undefined,
	BoxProps,
}) => {
	const dispatch = useDispatch();

	const isLoading = useSelector(productConfigSelectors.getQuickaddLoadingMap)[
		productCode
	];

	const handleQuickAdd = () => {
		dispatch(
			productConfigActions.quickAddToCart({
				productCode,
				properties: { domain, related_subaccnt: relatedSubaccnt },
			}),
		);
	};

	const handleClick = useQuickAdd({ onQuickAdd: handleQuickAdd });
	const quickAddCaption = useQuickAddCaption({ code: productCode, isLoading });

	const getFlexDirection = () => {
		switch (showText) {
			case 'top':
				return 'column-reverse';
			case 'bottom':
				return 'column';
			case 'left':
				return 'row-reverse';
			case 'right':
				return 'row';
			default:
				// 'none'
				return 'inherit';
		}
	};

	const getTextProps = () => {
		switch (showText) {
			case 'top':
				return { mb: 1, alignSelf: 'flex-end' };
			case 'bottom':
				return { mt: 1, alignSelf: 'flex-end' };
			case 'left':
				return { mr: 2.25 };
			case 'right':
				return { ml: 2.25 };
			default:
				// 'none'
				return 'inherit';
		}
	};

	return (
		<Box display="flex" flexDirection={getFlexDirection()} pb={1}>
			<LWButton
				isLoading={isLoading}
				onClick={handleClick}
				endIcon={<AddShoppingCartIcon />}
				BoxProps={BoxProps}
				data-testid={testids.QuickAddButton__button}
			>
				Quick Add
			</LWButton>
			{showText !== 'none' && (
				<LWTypography
					BoxProps={{
						display: 'flex',
						alignItems: 'center',
						...getTextProps(),
					}}
					variant="caption"
					color="textSecondary"
				>
					{quickAddCaption}
				</LWTypography>
			)}
		</Box>
	);
};

QuickAddButton.propTypes = {
	showText: PropTypes.oneOf(['none', 'left', 'right', 'top', 'bottom']),
	productCode: PropTypes.string.isRequired,
	domain: PropTypes.string,
	relatedSubaccnt: PropTypes.string,
};

export { testids };
export default QuickAddButton;

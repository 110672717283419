import { useSelector } from 'react-redux';
import { cartDetailsSelectors } from 'modules/api/market/cart/detailsModule';

const usePackageVersionId = (itemUuid) => {
	const cartItems = useSelector(cartDetailsSelectors.getNativeItems);
	const packageVersionId = cartItems.find((item) => item.uuid === itemUuid)
		?.details?.version_id;

	return packageVersionId;
};

export default usePackageVersionId;

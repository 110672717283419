// @ts-check

/**
 * @typedef AddonCapabilityT
 * @property {string} displayName
 * @property {string} productCode
 * @property {string} listUrl
 * @property {'security' | 'backup'} type
 * @property {string} capability
 * */

/**
 * A list of the supported product addons with their notable attributes.
 * @type {Record<string, AddonCapabilityT>}
 * */
const addonCapabilityDetails = {
	addonThreatStack: {
		displayName: 'Threat Stack',
		productCode: 'ThreatStack',
		listUrl: '/services/threatstack',
		type: 'security',
		capability: 'addonThreatStack',
	},
	addonThreatStackVM: {
		displayName: 'Threat Stack',
		productCode: 'ThreatStack.VM',
		listUrl: '/services/threatstack',
		type: 'security',
		capability: 'addonThreatStackVM',
	},
	addonThreatStackVMWare: {
		displayName: 'Threat Stack',
		productCode: 'ThreatStack.VMware',
		listUrl: '/services/threatstack',
		type: 'security',
		capability: 'addonThreatStackVMWare',
	},
	addonAcronisBackup: {
		displayName: 'Acronis',
		productCode: 'addonAcronisBackup',
		listUrl: '/services/acronis',
		type: 'backup',
		capability: 'addonAcronisBackup',
	},
};

export default addonCapabilityDetails;

import { call, put, select, takeLatest, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import snackbarSaga from 'modules/snackbar/sagas';
import { hasGroup as getHasGroup } from 'modules/auth/authSelectors';
import {
	selectors as signupSelectors,
	actions as signupActions,
} from 'modules/api/account/signupModule';
import { selectors as updateSelectors } from 'modules/api/contact/updateModule';
import { actions as validateAddressActions } from 'modules/api/contact/validateAddressModule';
import { selectors as appConfigSelectors } from 'modules/appConfig';
import { cartDetailsSelectors } from 'modules/api/market/cart/detailsModule';

import { updateList } from 'modules/contact/sagas';
import cartActions from 'modules/cart/actions';

import internalOnlyActions from 'modules/internalOnly/actions';
import basketCheckoutActions from 'modules/basket/checkout/actions';
import createAccountActions from './actions';

function* initCreatePage() {
	yield put(internalOnlyActions.check({ guest: 1 }));
}

function* handleResponse({ errorString } = {}) {
	yield put(validateAddressActions.clear());
	if (errorString) {
		yield call(snackbarSaga, {
			error: true,
			errorMessage: errorString,
		});
		return;
	}

	// success
	const cartHasItems = yield select(cartDetailsSelectors.getHasItems);
	if (cartHasItems) yield put(basketCheckoutActions.takeToNextStep());
	else yield put(push('/shop/marketplace'));
}

function* handleSignup({ payload }) {
	yield put(signupActions.fetch(payload));
	const res = yield take([signupActions.errorType, signupActions.setType]);
	const error = res.type === signupActions.errorType;

	if (!error) {
		// Update token
		const userManager = yield select(appConfigSelectors.getUserManager);
		yield call([userManager, userManager.signinSilent]);

		// init basket
		yield put(cartActions.initialize());
	}

	yield call(handleResponse, {
		errorString: error ? yield select(signupSelectors.getErrorString) : false,
	});
}

function* handleUpdate({ payload }) {
	// Transform the signup call data into update data.
	const { address, contact, identity } = payload || {};
	const { company: organization, first_name: fname, last_name: lname } =
		identity || {};
	const { email, phone } = contact;
	// the id is inserted by the saga.
	const isError = yield call(updateList, {
		payload: {
			...address,
			zip: undefined, // replacing zip with postal_code
			postal_code: address.zip,
			organization,
			fname,
			lname,
		},
		newMainEmail: email,
		newMainPhone: phone,
	});
	yield call(handleResponse, {
		errorString: isError ? yield select(updateSelectors.getErrorString) : false,
	});
}

function* signupPut({ payload }) {
	const hasGroup = yield select(getHasGroup);
	if (hasGroup) {
		yield call(handleUpdate, { payload });
	} else {
		yield call(handleSignup, { payload }); // schema was originally structured for the signup endpoint.
	}
}

export default function* saga() {
	yield takeLatest(createAccountActions.ACCOUNT_CREATE_SIGNUP_PUT, signupPut);
	yield takeLatest(createAccountActions.CREATE_PAGE_INIT, initCreatePage);
}

import React from 'react';
import PropTypes from 'prop-types';
import StyledBox from 'components/atoms/StyledBox';
import LWTypography from 'components/common/LWTypography';

const InfoBox = ({ details, isLoading, ...rest }) => (
	<StyledBox variant="grey" {...rest}>
		{details
			.filter((detail) => detail)
			.map((detail, i) => {
				const props = {
					key: `${detail.key || detail}`,
				};
				if (isLoading) {
					return <LWTypography {...props} isLoading />;
				}
				if (typeof detail !== 'string') {
					return detail;
				}
				if (i === 0) {
					props.bold = true;
					props.variant = 'body2';
				} else {
					props.variant = 'body1';
				}
				return <LWTypography {...props}>{detail}</LWTypography>;
			})}
	</StyledBox>
);
InfoBox.propTypes = {
	/** array of strings or React elements to be displayed - first item in array gets different styling */
	details: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	).isRequired,
	/** if true, displays skeleton loader for each instance of item in details instead of text */
	isLoading: PropTypes.bool,
};

export default InfoBox;

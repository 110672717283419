import React, { useState } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { actions as dnsActions } from 'modules/dns';
import { actions as dialogActions } from 'modules/dialogs';

// api
import { selectors as zoneDetailsSelectors } from 'modules/api/network/dns/zone/detailsModule';

// MUI components
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';

// custom components.
import LWTypography from 'components/common/LWTypography';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel/ConfirmCancel';

const testIds = {
	DeleteDNSZoneDialogContents2__Checkbox:
		'DeleteDNSZoneDialogContents2__Checkbox',
};
const DeleteDNSZoneDialogContents2 = () => {
	const dispatch = useDispatch();
	const deleteZone = () => {
		dispatch(dnsActions.deleteZone());
	};
	const cancel = () => {
		dispatch(dialogActions.close());
	};
	const name = useSelector(zoneDetailsSelectors.getName);
	const willOrMay = useSelector(
		zoneDetailsSelectors.getIsDelegationStatusNotCorrect,
	)
		? 'may'
		: 'will';

	const [checked, setChecked] = useState(false);
	return (
		<Grid container spacing={3}>
			<Grid item>
				<LWTypography
					bold
					BoxProps={{
						py: 0.5 /* The mockups have slightly more padding than normal here. */,
					}}
				>
					Are you sure you want to delete the DNS zone for {name}?
				</LWTypography>
			</Grid>
			<Grid item container alignItems="baseline">
				<Grid item>
					<Checkbox
						color="primary"
						checked={checked}
						onClick={() => {
							setChecked(!checked);
						}}
						data-testid={testIds.DeleteDNSZoneDialogContents2__Checkbox}
					/>
				</Grid>
				<Grid item xs>
					<LWTypography>
						Yes, I confirm and acknowledge deleting the DNS zone {willOrMay}{' '}
						cause errors with the domain until a new DNS zone is added.
					</LWTypography>
				</Grid>
			</Grid>
			<Grid item xs>
				<ConfirmCancel
					confirmText="Delete"
					variant="negative"
					disabled={!checked}
					confirm={deleteZone}
					cancel={cancel}
				/>
			</Grid>
		</Grid>
	);
};

export { testIds };
export default DeleteDNSZoneDialogContents2;

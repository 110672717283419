import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'utility/compose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import LWLink from 'components/common/LWLink';
import { selectors as appConfigSelectors } from 'modules/appConfig';

const styles = {
	footerSubNav: {},
	navColumn: {},
	listItem: {},
};

const NavContact = ({ classes, wwwHostname }) => (
	// These Grids...I can't even... -Caleb
	<Grid item className={classes.navColumn}>
		<LWLink to={`${wwwHostname}/contact-us/`} underline="none" darkBackground>
			Contact
		</LWLink>
		<Grid container direction="column" className={classes.footerSubNav}>
			<List>
				<ListItem classes={{ root: classes.listItem }}>
					<LWLink
						to={`${wwwHostname}/contact-us/`}
						variant="caption"
						underline="none"
						darkBackground
					>
						{'Contact Us'}
					</LWLink>
				</ListItem>
				<ListItem classes={{ root: classes.listItem }}>
					<LWLink
						to="tel:1-800-580-4985"
						variant="caption"
						underline="none"
						darkBackground
					>
						{'Phone 800.580.4985'}
					</LWLink>
				</ListItem>
				<ListItem classes={{ root: classes.listItem }}>
					<LWLink
						to="tel:1-800-580-4985,1"
						variant="caption"
						underline="none"
						darkBackground
					>
						{'24 Hr Sales Option 1'}
					</LWLink>
				</ListItem>
				<ListItem classes={{ root: classes.listItem }}>
					<LWLink
						to="tel:1-800-580-4985,2"
						variant="caption"
						underline="none"
						darkBackground
					>
						{'24 Hr Support Option 2'}
					</LWLink>
				</ListItem>
				<ListItem classes={{ root: classes.listItem }}>
					<LWLink
						to="tel:1-517-322-0434"
						variant="caption"
						underline="none"
						darkBackground
					>
						{'International 1.517.322.0434'}
					</LWLink>
				</ListItem>
			</List>
		</Grid>
	</Grid>
);

NavContact.propTypes = {
	classes: PropTypes.object.isRequired,
	wwwHostname: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
	wwwHostname: appConfigSelectors.getWwwHostname(state),
});

export default compose(
	connect(mapStateToProps),
	withStyles(styles),
)(NavContact);

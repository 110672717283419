// @ts-check
import makeGraphData from 'modules/queries/asset/measurements/makeGraphData';

/** @param {import("club-sauce/public/asset/measurements/index.raw").LWApiPublicAssetMeasurementsLookupResultRawI} [lookupData] */
const createBandwidthGraphList = (lookupData) => {
	/** @type {Record<string, import('club-sauce/public/asset/measurements/index.raw').LWApiPublicAssetMeasurementsLookupPointRawI[]>} */
	const list = {};

	if (lookupData?.data) {
		lookupData.data.forEach((point) => {
			const name = point?.tag?.interface;
			if (!name || ['all', 'lo'].includes(name)) return;

			if (!(name in list)) {
				list[name] = [];
			}
			list[name].push(point);
		});
	}

	return Object.fromEntries(
		Object.entries(list).map(([name, data]) => [
			name,
			makeGraphData({
				data,
				keys: ['received', 'sent'],
			}),
		]),
	);
};

export default createBandwidthGraphList;

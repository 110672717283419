const actions = {
	BASKET_ABANDON: 'BASKET_ABANDON',
	/**
	 * @param {object} payload
	 * @param {object} payload.addItemPayload - the item to be added after abandoning the cart
	 * @param {boolean} payload.proceedToConfigure whether or not to navigate to the configure page after abandoning the cart
	 * @param {string} payload.productKey a uuid or product code
	 * @returns
	 */
	abandon: ({ addItemPayload, proceedToConfigure, productKey }) => ({
		type: actions.BASKET_ABANDON,
		addItemPayload,
		proceedToConfigure,
		productKey,
	}),
	BASKET_RESET: 'BASKET_RESET',
	reset: () => ({
		type: actions.BASKET_RESET,
	}),
	BASKET_SET_ERROR: 'BASKET_SET_ERROR',
	setError: (text) => ({
		type: actions.BASKET_SET_ERROR,
		text,
	}),
	BASKET_CREATED: 'BASKET_CREATED',
	created: () => ({
		type: actions.BASKET_CREATED,
	}),
	BASKET_RESET_STATE: 'BASKET_RESET_STATE',
	resetState: () => ({
		type: actions.BASKET_RESET_STATE,
	}),
	BASKET_STORE_UUID: 'BASKET_STORE_UUID',
	storeUuid: ({ uuid }) => ({
		type: actions.BASKET_STORE_UUID,
		uuid,
	}),
};

export default actions;

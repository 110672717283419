import { connect } from 'react-redux';
import {
	hasAuthToken,
	isKeroOnly as isKeroOnlySelector,
	isBasketAdmin,
} from 'modules/auth/authSelectors';
import RouteHandler from './RouteHandler';

const mapStateToProps = (state) => ({
	isLoggedIn: hasAuthToken(state),
	isKeroOnly: isKeroOnlySelector(state),
	isBasketAdmin: isBasketAdmin(state),
});

export default connect(mapStateToProps)(RouteHandler);

const drawerActions = {
	SET_DRAWER_OPEN: 'SET_DRAWER_OPEN',
	/**
	 * opens the global drawer with the passed in content key.
	 * @param {string} param0.contentKey
	 * @returns {object}
	 */
	open: ({ contentKey }) => ({
		type: drawerActions.SET_DRAWER_OPEN,
		contentKey,
	}),
	SET_DRAWER_CLOSE: 'SET_DRAWER_CLOSE',
	/**
	 * closes the drawer.
	 * @returns {object}
	 */
	close: () => ({
		type: drawerActions.SET_DRAWER_CLOSE,
	}),
	CLEAR_DRAWER_CONTENTS: 'CLEAR_DRAWER_CONTENTS',
	/**
	 * Called by global drawer component once it has closed.
	 * @returns {object}
	 */
	clearContents: () => ({
		type: drawerActions.CLEAR_DRAWER_CONTENTS,
	}),
};

export default drawerActions;

import { select, takeLatest, put, take, call } from 'redux-saga/effects';
import { actions as renewDomainsActions } from 'modules/api/network/dns/domain/renewModule';
import renewDialogActions from 'modules/renewDialog/actions';
import snackbarActions from 'modules/snackbar/snackbarActions';
import { actions as productActions } from 'modules/api/product/detailsModule';
import { getPayload, getFetchAfter } from 'modules/renewDialog/selectors';
import { actions as renewSSLActions } from 'modules/api/ssl/certificate/renewModule';
import { actions as detailsActions } from 'modules/api/network/dns/domain/detailsModule';

function* handlePostDomainRenew() {
	const toFetchAfter = yield select(getFetchAfter);
	yield put(
		snackbarActions.pushMessage({ message: 'Successfully renewed domain' }),
	);
	yield put(renewDomainsActions.clear());
	yield put(renewDialogActions.toggleDialog(false));
	if (toFetchAfter) {
		yield call(toFetchAfter);
	}
}

function* handlePostSSLRenew() {
	const { domain } = yield select(getPayload);
	const toFetchAfter = yield select(getFetchAfter);
	yield put(
		snackbarActions.pushMessage({
			message: `Successfully renewed SSL for ${domain}`,
		}),
	);
	yield put(renewSSLActions.clear());
	yield put(renewDialogActions.toggleDialog(false));

	if (toFetchAfter) {
		yield call(toFetchAfter);
	}
}

function* handleFetchProduct(action) {
	if (action.productType === 'DOMAIN') {
		yield put(productActions.fetch({ code: 'DREG' }));
	}
	if (action.productType === 'SSL') {
		yield put(productActions.fetch({ code: 'SSL.DV' }));
	}
}

function* handleAutoRenewDomainError() {
	yield put(snackbarActions.pushMessage({ message: 'Failed to renew domain' }));
	yield put(renewDomainsActions.clear());
	yield put(
		renewDialogActions.setError(
			'Failed to Renew,  Please retry. If the error persists, please contact support.',
		),
	);
}

function* handleAutoRenewSSLError() {
	yield put(snackbarActions.pushMessage({ message: 'Failed to renew SSL' }));
	yield put(renewSSLActions.clear());
	yield put(
		renewDialogActions.setError(
			'Failed to Renew,  Please retry. If the error persists, please contact support.',
		),
	);
}

function* handleDetails({ productType }) {
	if (productType === 'DOMAIN') {
		const {
			payload: { domain },
		} = yield take(renewDialogActions.SET_PAYLOAD);
		yield put(
			detailsActions.fetch({
				domain,
				alsowith: ['nameservers', 'renewal_max_years'],
			}),
		);
	}
}

export default function* rootSaga() {
	yield takeLatest(renewDialogActions.SET_PRODUCT_TYPE, handleFetchProduct);
	yield takeLatest(renewDialogActions.SET_PRODUCT_TYPE, handleDetails);

	// Snackbars
	yield takeLatest(renewDomainsActions.setType, handlePostDomainRenew);
	yield takeLatest(renewSSLActions.setType, handlePostSSLRenew);

	// Errors
	yield takeLatest(renewDomainsActions.errorType, handleAutoRenewDomainError);
	yield takeLatest(renewSSLActions.errorType, handleAutoRenewSSLError);
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'utility/compose';
import { useHistory } from 'react-router';
import CompactCartSummary from 'containers/pages/cart/CompactCartSummary';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import TextField from '@material-ui/core/TextField';
import Smartphone from '@material-ui/icons/Smartphone';
import { ReactComponent as Logout } from 'images/new-icons/logout.svg';
import Close from '@material-ui/icons/Close';
import { ReactComponent as Edit } from 'images/new-icons/pencil.svg';
import Done from '@material-ui/icons/Done';
import { ReactComponent as LockOpenIcon } from 'images/new-icons/eye-closed.svg';
import { ReactComponent as LockIcon } from 'images/new-icons/eye.svg';
import { selectors as accountDetailsSelectors } from 'modules/api/account/detailsModule';
import {
	hasAuthToken,
	isAccountUser as isAccountUserSelector,
} from 'modules/auth/authSelectors';
import { selectors as routeSelectors } from 'modules/route';

import {
	selectors as passphraseGetSelectors,
	actions as passphraseGetActions,
} from 'modules/api/account/chatpassword/getModule';
import {
	selectors as passphraseSetSelectors,
	actions as passphraseSetActions,
} from 'modules/api/account/chatpassword/setModule';
import snackbarActions from 'modules/snackbar/snackbarActions';
import manageRedirectActions from 'modules/manageRedirect/manageRedirectActions';
import SignInButton from 'components/atoms/SignInButton';
import LWLink from 'components/common/LWLink';
import LWButton from 'components/common/LWButton';
import LWTypography from 'components/common/LWTypography';
import UserProfile from 'components/common/UserProfile';
import FavoritesList from 'components/common/FavoritesList';
import ThirdpartyExclude from 'components/common/ThirdpartyExclude';

const styles = ({
	maxWidth,
	spacing,
	palette,
	breakpoints,
	zIndex,
	mixins,
}) => ({
	accountBarInner: {
		maxWidth,
		margin: 'auto',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		[breakpoints.up('md')]: {
			justifyContent: 'space-between',
		},
		[breakpoints.down('sm')]: {
			flexWrap: 'wrap',
		},
		paddingTop: '12px',
		paddingBottom: '12px',
	},
	root: {
		background: palette.background.default,
		borderBottom: `1px solid ${palette.divider}`,
		paddingLeft: '16px',
		paddingRight: '16px',
	},
	closeIconButton: {
		color: '#454545 !important',
		minWidth: 'unset !important',
		borderRadius: '5px !important',
		fontSize: '12px !important',
		lineHeight: '12px !important',
		fontWeight: '500 !important',
		background: `${palette.grey.medium} !important`,
		boxShadow: 'unset !important',
		paddingTop: '7px',
	},
	leftSide: {
		overflow: 'hidden',
		alignItems: 'center',
		display: 'flex',
		color: palette.primary.main,
		fontSize: '12px',
		[breakpoints.down('xs')]: {
			fontSize: '.875em',
		},
	},
	contact: {
		marginLeft: 'auto',
		paddingLeft: '1em',
	},
	passphraseButtonContainer: {
		paddingLeft: '10px',
		paddingRight: '10px',
		flex: 1,
		alignItems: 'center',
		display: 'flex',
		'& .MuiSvgIcon-root': {
			marginRight: spacing(0.5),
			marginLeft: spacing(0.5),
		},
	},
	passphraseButton: {
		textTransform: 'capitalize',
		padding: '1.5px',
		color: '#454545',
		'&& .MuiButton-label': {
			color: '#454545',
		},
		marginRight: '.3em',
		minWidth: '4em',
		[breakpoints.down('xs')]: {
			fontSize: '.875em',
		},
		'&:hover svg': {
			color: palette.primary.main,
		},
		'&:hover': {
			background: 'white',
		},
	},
	logout: {
		marginLeft: spacing(1),
		'&:hover': {
			textDecoration: 'none',
		},
		color: 'white',
		'&& .MuiButton-label': {
			color: palette.primary.contrastText,
		},
		...mixins.alignCenter,
	},
	actionButtonsContainer: {
		display: 'inline-flex',
		position: 'relative',
		top: '-3px',
	},
	phone: {
		display: 'flex',
		padding: '.5em 0em',
		alignItems: 'center',
	},
	phoneNumber: {
		color: palette.text.primary,
	},
	passphraseText: {
		overflow: 'auto',
		textAlign: 'center',
		fontSize: '14px',
		marginRight: spacing(2),
		display: 'flex',
		minWidth: '13.750em',
		fontWeight: 500,
		color: palette.primary.main,
	},
	icon: {
		color: palette.grey.dark,
		display: 'flex',
		alignSelf: 'center',
		marginLeft: '-7px;',
		'&:hover': {
			color: 'black',
		},
	},
	supportPassphrase: {
		overflow: 'hidden',
		display: 'flex',
	},
	button: {
		marginTop: '2px',
		color: `${palette.grey.dark} !important`,
		textTransform: 'capitalize !important',
		'&:hover': {
			background: 'white',
			color: palette.primary.main,
		},
		'&:hover span': {
			color: palette.primary.main,
			textDecoration: 'underline',
		},
		'&&': {
			color: 'white',
			[breakpoints.down('sm')]: {
				padding: '4px',
			},
		},
	},
	label: {
		color: palette.primary.medium,
		fontSize: '12px',
		marginRight: '6px',
		'&:hover': {
			color: palette.primary.main,
			textDecoration: 'underline',
		},
	},
	rightSide: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
	},
	buttons: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		'&:last-child': {
			border: '0',
		},
	},
	saveButton: {
		marginLeft: '.5em',
		marginRight: '.5em',
		borderRadius: '5px !important',
		minWidth: 'unset !important',
		fontSize: '12px !important',
		lineHeight: '12px !important',
		fontWeight: '500 !important',
		boxShadow: 'unset !important',
		paddingTop: '7px',
	},
	spinner: {
		color: '#5e5e5e',
	},
	popup: {
		borderRadius: '5px',
		border: `1px solid ${palette.divider}`,
		background: palette.background.default,
		position: 'absolute',
		transform: 'translate(7em, 63%)',
		zIndex: zIndex.accountBarPopUp,
		width: '13em',
		padding: '.5em',
		'&::before': {
			content: '""',
			borderLeft: '5px solid transparent',
			borderRight: '5px solid transparent',
			borderBottom: '5px solid #3c98c5',
			top: '-5px',
			position: 'absolute',
		},
	},
	popupTop: {
		position: 'absolute',
		top: '5px',
		right: '5px',
	},
	passphraseLabel: {
		paddingBottom: '.5em',
	},
	passphraseContainer: {
		textAlign: 'center',
		fontWeight: '500',
		paddingBottom: '.5em',
	},
	textFieldContainer: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		width: '15vw',
		margin: 'auto 8px',
		left: '-13px',
	},
	textField: {
		[breakpoints.up('md')]: {
			margin: '5px',
			marginRight: spacing(2),
			zIndex: 2,
		},
		marginRight: spacing(1),
		'& .MuiInputBase-input': {
			padding: 3,
		},
		'& .MuiFilledInput-multiline': {
			padding: 0,
		},
		'& .MuiFilledInput-input': {
			background: palette.common.white1,
		},
	},
	passphrase: {
		display: 'flex',
		flexDirection: 'row',
	},
	passPhraseTextMobile: {
		overflow: 'auto',
		flex: 5,
	},
	passPhraseIconMobile: {
		flex: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	iconButtons: {
		'&:hover': {
			backgroundColor: palette.background.default,
		},
	},
});
const AccountBar = ({
	classes,
	accountNumber,
	isLoggedIn,
	isAccountUser,
	isLoading,
	supportPassphrase,
	getSupportPassphrase,
	openManageRedirectDialog,
	path,
	clearSupportPassphrase,
	setSupportPassphrase,
	setSupportPassphraseLocal,
	updatedSupportPassphrase,
	openSnackbar,
}) => {
	const history = useHistory();
	const [writeModePassphrase, setWriteModePassphrase] = useState(false);
	const [showSupportPassphrase, setShowSupportPassphrase] = useState(false);
	const [localSupportPassphrase, setLocalSupportPassphrase] = useState('');

	// Hide passphrase after 10 seconds
	useEffect(() => {
		if (!showSupportPassphrase) return undefined;
		// But not if editing
		if (writeModePassphrase) return undefined;

		const timer = setTimeout(() => {
			setShowSupportPassphrase(false);
		}, 10000);

		return () => {
			clearTimeout(timer);
		};
	}, [showSupportPassphrase, writeModePassphrase]);

	// Hide passphrase on route change
	useEffect(() => {
		if (path) {
			setShowSupportPassphrase(false);
		}
	}, [path]);

	useEffect(() => {
		if (!updatedSupportPassphrase.isLoading) {
			setShowSupportPassphrase(false);
		}
	}, [updatedSupportPassphrase.isLoading]);

	useEffect(() => {
		if (updatedSupportPassphrase.data) {
			setWriteModePassphrase(false);
			setSupportPassphraseLocal(updatedSupportPassphrase.data.get('password'));
			openSnackbar('Successfully updated support passphrase');
			clearSupportPassphrase();
		}
	}, [
		clearSupportPassphrase,
		openSnackbar,
		setSupportPassphraseLocal,
		updatedSupportPassphrase.data,
	]);

	const handleShowPassphraseClick = () => {
		if (!supportPassphrase.hasData) {
			getSupportPassphrase();
		}
		setShowSupportPassphrase((show) => !show);
		setWriteModePassphrase(false);
	};

	const handleSupportPassphraseChange = (e) =>
		setLocalSupportPassphrase(e.target.value);

	const saveSupportPassphrase = () =>
		setSupportPassphrase(localSupportPassphrase);

	const getPassphraseIcons = () => {
		let edit = null;
		if (showSupportPassphrase && !writeModePassphrase) {
			edit = (
				<IconButton
					classes={{ root: classes.iconButtons }}
					disabled={supportPassphrase.isLoading}
					onClick={() => {
						setWriteModePassphrase(true);
					}}
				>
					<Edit className={classes.icon} />
				</IconButton>
			);
		}
		return <>{edit}</>;
	};

	const mobilePassphraseEditForm = () => {
		return (
			<form onSubmit={saveSupportPassphrase}>
				<ThirdpartyExclude>
					<TextField
						variant="filled"
						rowsMax={6}
						defaultValue={supportPassphrase.data}
						disabled={updatedSupportPassphrase.isLoading}
						fullWidth
						multiline
						onChange={handleSupportPassphraseChange}
						placeholder="Enter a passphrase here"
						classes={{ root: classes.textField }}
					/>
				</ThirdpartyExclude>
			</form>
		);
	};

	const supportPassphraseContent = () => {
		if (writeModePassphrase && showSupportPassphrase) {
			return (
				<>
					<ThirdpartyExclude>
						<div className={classes.textFieldContainer}>
							<TextField
								rowsMax={6}
								variant="filled"
								defaultValue={supportPassphrase.data}
								disabled={updatedSupportPassphrase.isLoading}
								fullWidth
								multiline
								onChange={handleSupportPassphraseChange}
								placeholder="Enter a passphrase here"
								classes={{ root: classes.textField }}
								InputProps={{ disableUnderline: true }}
							/>
						</div>
					</ThirdpartyExclude>
				</>
			);
		}

		if (showSupportPassphrase) {
			return (
				<div className={classes.supportPassphrase}>
					<ThirdpartyExclude>
						<span className={classes.passphraseText}>
							{isLoading && (
								<CircularProgress size={20} className={classes.spinner} />
							)}
							{!isLoading &&
								(supportPassphrase.data || 'No support passphrase found')}
						</span>
					</ThirdpartyExclude>
				</div>
			);
		}
		return null;
	};
	const supportPassphraseButton = (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<div className={classes.passphraseButtonContainer}>
				{showSupportPassphrase && (
					<IconButton
						classes={{ root: classes.iconButtons }}
						onClick={() => {
							handleShowPassphraseClick();
						}}
					>
						<LockOpenIcon className={classes.icon} />
					</IconButton>
				)}
				{!showSupportPassphrase && (
					<Button
						size="small"
						classes={{ root: classes.passphraseButton }}
						startIcon={<LockIcon />}
						onClick={() => {
							if (!supportPassphrase.hasData) {
								getSupportPassphrase();
							}
							setShowSupportPassphrase(true);
						}}
					>
						Show My Support Passphrase
					</Button>
				)}
				{supportPassphraseContent()}
				{getPassphraseIcons()}
			</div>
			{writeModePassphrase && showSupportPassphrase && (
				<div className={classes.actionButtonsContainer}>
					<LWButton
						className={classes.saveButton}
						color="secondary"
						disabled={
							updatedSupportPassphrase.isLoading ||
							localSupportPassphrase === supportPassphrase.data
						}
						onClick={saveSupportPassphrase}
						variant="contained"
						size="small"
						isLoading={updatedSupportPassphrase.isLoading}
					>
						Save
					</LWButton>
					<LWButton
						className={classes.closeIconButton}
						disabled={updatedSupportPassphrase.isLoading}
						variant="contained"
						size="small"
						onClick={() => {
							setWriteModePassphrase(false);
						}}
					>
						Cancel
					</LWButton>
				</div>
			)}
		</div>
	);
	let left;
	let favoritesButton;
	if (isAccountUser) {
		left = (
			<React.Fragment>
				<LWTypography
					classes={{ root: classes.accountMargin }}
				>{`Account #${accountNumber}`}</LWTypography>
				<Hidden xsDown>{supportPassphraseButton}</Hidden>
				<Hidden smUp>
					<IconButton
						onClick={() => {
							if (!supportPassphrase.hasData) {
								getSupportPassphrase();
							}
							setShowSupportPassphrase((show) => !show);
						}}
						classes={{ root: classes.passphraseButton }}
					>
						<LockIcon className={classes.icon} />
					</IconButton>
					{showSupportPassphrase && (
						<ClickAwayListener
							onClickAway={() => {
								setShowSupportPassphrase(false);
								setWriteModePassphrase(false);
							}}
						>
							<div className={classes.popup}>
								<div className={classes.popupTop}>
									<IconButton
										size="small"
										onClick={() => {
											setShowSupportPassphrase(false);
										}}
										className={classes.closeIconButton}
									>
										<Close />
									</IconButton>
								</div>
								<div className={classes.passphraseLabel}>
									{'Support Passphrase: '}
								</div>
								<div className={classes.passphraseContainer}>
									{isLoading && (
										<CircularProgress size={20} className={classes.spinner} />
									)}
									{!isLoading && (
										<div>
											{supportPassphrase.data ? (
												<div className={classes.passphrase}>
													<ThirdpartyExclude>
														<div className={classes.passPhraseTextMobile}>
															{writeModePassphrase
																? mobilePassphraseEditForm()
																: supportPassphrase.data}
														</div>
													</ThirdpartyExclude>
													<div className={classes.passPhraseIconMobile}>
														{writeModePassphrase ? (
															<IconButton
																disabled={
																	updatedSupportPassphrase.isLoading ||
																	localSupportPassphrase ===
																		supportPassphrase.data ||
																	!localSupportPassphrase
																}
																onClick={saveSupportPassphrase}
															>
																{updatedSupportPassphrase.isLoading ? (
																	<CircularProgress size={30} />
																) : (
																	<Done
																		className={classes.icon}
																		fontSize="small"
																	/>
																)}
															</IconButton>
														) : (
															<IconButton
																disabled={supportPassphrase.isLoading}
																onClick={() => {
																	setWriteModePassphrase(true);
																}}
															>
																<Edit
																	className={classes.icon}
																	fontSize="small"
																/>
															</IconButton>
														)}
													</div>
												</div>
											) : (
												<div>No support passphrase found</div>
											)}
										</div>
									)}
								</div>
								<div className={classes.phone}>
									<Smartphone />
									<LWLink
										to="tel:1-800-580-4985"
										classes={{ root: classes.phoneNumber }}
									>
										{'1-800-580-4985'}
									</LWLink>
								</div>
								<div className={classes.phone}>
									<Smartphone />
									<LWLink
										to="tel:1-517-322-0434"
										classes={{ root: classes.phoneNumber }}
									>
										{'1-517-322-0434'}
									</LWLink>
									{' (Int’l.)'}
								</div>
							</div>
						</ClickAwayListener>
					)}
				</Hidden>
			</React.Fragment>
		);
		favoritesButton = <FavoritesList onClick={openManageRedirectDialog} />;
	}

	return (
		<span>
			<div className={classes.root}>
				<div className={classes.accountBarInner}>
					<LWTypography className={classes.leftSide} component="div">
						{left}
					</LWTypography>
					<div className={classes.rightSide}>
						<CompactCartSummary />
						{favoritesButton}
						{isLoggedIn && (
							<>
								<Tooltip title="My user profile">
									<IconButton classes={{ root: classes.iconButtons }}>
										<UserProfile classes={{ root: classes.actionButton }} />
									</IconButton>
								</Tooltip>

								<IconButton
									className={classes.button}
									aria-label="Logout"
									onClick={() => history.push('/logout')}
									disableRipple
									style={{ paddingRight: '0' }}
								>
									<span className={classes.label}>Logout</span> <Logout />
								</IconButton>
							</>
						)}
						{!isLoggedIn && <SignInButton label="Login" accountBarStyle />}
					</div>
				</div>
			</div>
		</span>
	);
};

AccountBar.propTypes = {
	classes: PropTypes.object.isRequired,
	accountNumber: PropTypes.string,
	supportPassphrase: PropTypes.shape({
		hasData: PropTypes.bool.isRequired,
		data: PropTypes.string,
	}),
	isLoading: PropTypes.bool.isRequired,
	isLoggedIn: PropTypes.bool,
	getSupportPassphrase: PropTypes.func.isRequired,
	openManageRedirectDialog: PropTypes.func.isRequired,
	path: PropTypes.string.isRequired,
	setSupportPassphrase: PropTypes.func.isRequired,
	setSupportPassphraseLocal: PropTypes.func.isRequired,
	updatedSupportPassphrase: PropTypes.shape({
		data: PropTypes.object,
		hasData: PropTypes.bool.isRequired,
		isLoading: PropTypes.bool.isRequired,
		hasError: PropTypes.bool.isRequired,
	}).isRequired,
	clearSupportPassphrase: PropTypes.func.isRequired,
	openSnackbar: PropTypes.func.isRequired,
};

AccountBar.defaultProps = {
	isLoggedIn: null,
	supportPassphrase: '',
};

const mapStateToProps = (state) => ({
	accountDetails: accountDetailsSelectors.getData(state),
	accountNumber: accountDetailsSelectors.getAccnt(state),
	isLoggedIn: hasAuthToken(state),
	isAccountUser: isAccountUserSelector(state),
	supportPassphrase: {
		hasData: passphraseGetSelectors.hasData(state),
		data: passphraseGetSelectors.supportPassphrase(state),
	},
	isLoading: passphraseGetSelectors.isLoading(state),
	path: routeSelectors.getPathName(state),
	updatedSupportPassphrase: {
		hasData: passphraseSetSelectors.hasData(state),
		data: passphraseSetSelectors.getData(state),
		isLoading: passphraseSetSelectors.isLoading(state),
		hasError: passphraseSetSelectors.hasError(state),
	},
});

const mapDispatchToProps = (dispatch) => ({
	clearSupportPassphrase: () => dispatch(passphraseSetActions.clear()),
	getSupportPassphrase: () => dispatch(passphraseGetActions.fetch()),
	setSupportPassphrase: (password) =>
		dispatch(passphraseSetActions.fetch({ password })),
	setSupportPassphraseLocal: (password) =>
		dispatch(passphraseGetActions.set({ password })),
	openManageRedirectDialog: () => dispatch(manageRedirectActions.setOpen()),
	openSnackbar: (message, color) =>
		dispatch(snackbarActions.pushMessage({ message, variant: color })),
});

export { AccountBar };

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(styles),
)(AccountBar);

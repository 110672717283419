import actions from './actions';

const initialState = {
	targetPower: {},
};

const reducer = (state = initialState, { type, uniqId, targetPowerData }) => {
	switch (type) {
		case actions.SET_SERVER_TARGET_POWER:
			return {
				...state,
				targetPower: {
					...state.targetPower,
					[uniqId]: targetPowerData,
				},
			};
		default:
			return state;
	}
};

export default reducer;

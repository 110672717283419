const addItemForCloneActions = {
	BASKET_ADD_ITEM_FOR_CLONE: 'BASKET_ADD_ITEM_FOR_CLONE',
	addItemForClone: ({ uniqId, force, productCode }) => ({
		type: addItemForCloneActions.BASKET_ADD_ITEM_FOR_CLONE,
		uniqId,
		force,
		productCode,
	}),
};

export default addItemForCloneActions;

import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.network.dns.domain.purchasePrivacy;

const { actions, reducer, sagas, selectors, additionalSagas } = createAPIModule(
	{
		getStateSlice,
		actionType: 'NETWORK_DNS_DOMAIN_PURCHASEPRIVACY',
		method: 'POST',
		url: '/network/dns/domain/purchasePrivacy.json',
	},
);

export {
	actions as domainPurchasePrivacyActions,
	reducer,
	sagas,
	selectors as domainPurchasePrivacySelectors,
	additionalSagas as domainPurchasePrivacyAdditionalSagas,
};

import { spawn } from 'redux-saga/effects';

import addressSagas from './address/addressSagas';
import createSagas from './create/createSagas';
import taxInfoSagas from './taxInfo/sagas';
import maxWidthSagas from './maxWidth/sagas';
import updateSagas from './update/sagas';

export default function* sagas() {
	yield spawn(addressSagas);
	yield spawn(createSagas);
	yield spawn(taxInfoSagas);
	yield spawn(maxWidthSagas);
	yield spawn(updateSagas);
}

const actions = {
	SERVICE_WORKER_INIT: 'SERVICE_WORKER_INIT',
	/**
	 * set the `initialized` value to true in the redux store.
	 * @returns {object}
	 */
	init: () => ({
		type: actions.SERVICE_WORKER_INIT,
	}),
	SERVICE_WORKER_LAUNCH_APP_INFO_MODAL: 'SERVICE_WORKER_LAUNCH_APP_INFO_MODAL',
	/**
	 * Lauches AppVersionModalContents.jsx
	 * @param {boolean} param0.dismissible - should the modal have the "X" in the upper-right?
	 * @returns {object}
	 */
	launchAppInfoModal: ({ dismissible }) => ({
		type: actions.SERVICE_WORKER_LAUNCH_APP_INFO_MODAL,
		dismissible,
	}),
	SERVICE_WORKER_PERSIST_REGISTRATION: 'SERVICE_WORKER_PERSIST_REGISTRATION',
	/**
	 * Persist the registration in redux for later use.
	 * @param {object} param0.registration - the registration from the service worker.
	 * @returns {object}
	 */
	persistRegistration: ({ registration } = {}) => ({
		type: actions.SERVICE_WORKER_PERSIST_REGISTRATION,
		registration,
	}),
	SERVICE_WORKER_UPDATE: 'SERVICE_WORKER_UPDATE',
	/**
	 * Refreshed the browser if the service worker is ready to update.
	 * @returns {object}
	 */
	serviceWorkerUpdate: () => ({ type: actions.SERVICE_WORKER_UPDATE }),
	SERVICE_WORKER_RESET: 'SERVICE_WORKER_RESET',
	/**
	 * Sets the redux store back to its initialized state.
	 * @returns {object}
	 */
	serviceWorkerReset: () => ({ type: actions.SERVICE_WORKER_RESET }),
};

export default actions;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// components
import LWTypography from 'components/common/LWTypography';
import Slider from '@material-ui/core/Slider';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';

// utility
import useDebounce from 'utility/effects/useDebounce';

const SSlider = styled(Slider)`
	& span:nth-child(1),
	& span:nth-child(2) {
		height: 4px;
		transform: translate(0px, -1px);
	}
`;

const DEBOUNCE_TIMER = 250;

const getMarks = ({ marks = [], min, max }) => {
	const displayMin = Math.floor(min);
	const displayMax = Math.ceil(max);

	if (min >= max || Number.isNaN(min) || Number.isNaN(max)) {
		return false;
	}

	return [
		{
			value: min,
			key: min,
			label: displayMin,
		},
		...marks.slice(1, -1).map((elem) => ({ ...elem, key: elem.value })),
		{
			value: max,
			key: max,
			label: displayMax,
		},
	];
};

const LWSlider = ({
	title,
	subtitle,
	helperText,
	min,
	max,
	input,
	onChange,
	onChangeCommitted,
	extraInput,
	value = 0, // Must be controlled.
	disabled: disabledFromParent,
	marks = [],
	step = 1,
	isLoading,
	dataTestId,
}) => {
	const realMin = min || marks[0]?.value || 0;
	const realMax = max || marks.slice(-1)[0]?.value || 100;

	const disabled = disabledFromParent || realMin >= realMax;

	const realValue = input?.value || value;

	const debouncedValue = useDebounce(realValue, DEBOUNCE_TIMER);
	useEffect(() => {
		if (debouncedValue === '' || !debouncedValue) return;
		if (onChange) onChange(debouncedValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedValue]);

	if (isLoading) return <Skeleton variant="rect" />;
	return (
		<Grid container spacing={1} direction="column">
			<Grid item>
				<LWTypography bold>{title}</LWTypography>
			</Grid>
			<Grid item>
				<LWTypography color="textSecondary" variant="caption">
					{subtitle}
				</LWTypography>
			</Grid>
			<Grid item>
				<Box mt={2}>
					<SSlider
						data-testid={dataTestId}
						onChange={(e, newValue) => {
							if (input?.onChange) input.onChange(newValue);
							// if we aren't using redux forms, we need to call this here rather than rely on debounce
							else if (onChange) onChange(newValue);
						}}
						onChangeCommitted={onChangeCommitted}
						marks={getMarks({ marks, min: realMin, max: realMax })}
						step={step}
						min={realMin}
						max={realMax}
						value={realValue}
						disabled={disabled}
					/>
				</Box>
			</Grid>
			<Grid item>{extraInput}</Grid>
			<Grid item>
				<LWTypography variant="body2">{helperText}</LWTypography>
			</Grid>
		</Grid>
	);
};

LWSlider.propTypes = {
	/** Text in bold above the slider */
	title: PropTypes.string,
	/** Subtext in grey above the slider */
	subtitle: PropTypes.string,
	/** Text beneath the slider. */
	helperText: PropTypes.string,
	/** Min value for slider */
	min: PropTypes.number,
	/** Max value for slider */
	max: PropTypes.number,
	/** Function to which the new value is passed as the first arg. */
	onChange: PropTypes.func,
	/** Callback function that is fired when the mouseup is triggered. */
	onChangeCommitted: PropTypes.func,
	/** The value of the slider. This must be a controlled component */
	value: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.arrayOf(PropTypes.number),
	]),
	/** Another input (such as Input or LWSelect) can be inserted beneath the slider if needed. */
	extraInput: PropTypes.node,
	/** Is the input disabled? */
	disabled: PropTypes.bool,
	/** An array of value available to be selected. Defaults to every increment of 1. If passed into without min/max, min/max will be set to mark values instead. */
	marks: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.number.isRequired,
			label: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
		}),
	),
	/** Amount that the slider may increment. Defaults to 1. Ignored if marks is pass in. */
	step: PropTypes.number,
};

export default LWSlider;

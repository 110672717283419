const getQuotaPricePath = (plan) => {
	switch (plan) {
		case 'CloudDaily':
		case 'Daily':
			return 'BackupStorage';
		case 'Quota':
			return 'BackupQuota';
		default:
			return 'LiquidWebBackupPlan';
	}
};

export default { getQuotaPricePath };
export { getQuotaPricePath };

// @ts-check
import { useMutation } from '@tanstack/react-query';
import useSnackbarError from 'utility/hooks/useSnackbarError';
import api from 'modules/queries/api';
import useInvalidateAssets from 'modules/queries/invalidateQueries/useInvalidateAssets';

const useAssetRemove = () => {
	const snackbarError = useSnackbarError();
	const invalidateAssets = useInvalidateAssets();

	return useMutation({
		mutationFn:
			/** @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetRemoveParamsRawI} params */
			(params) => api.asset.apiRaw.remove(params),

		onSuccess: (data, params) => {
			invalidateAssets(params.uniq_id);
		},

		onError: snackbarError,
	});
};

export default useAssetRemove;

// TODO: This is a molecule, not an atom, and should go in the molecules folder..

import React from 'react';
import PropTypes from 'prop-types';
import ProjectIcon from '@material-ui/icons/LayersRounded';
import LabeledIcon from 'components/atoms/LabeledIcon';
import LabeledText from 'components/atoms/LabeledText';

const ProjectName = ({
	iconColor = 'text.disabled',
	variant = 'secondaryDark',
	isLoading = false,
	name,
	showLabel,
	BoxProps,
}) => (
	<LabeledIcon
		icon={<ProjectIcon />}
		color={iconColor}
		text={
			<LabeledText
				label={showLabel ? 'Project' : ''}
				text={name || '[None]'}
				variant={name ? variant : 'secondarySub2'}
				inline
			/>
		}
		isLoading={isLoading}
		BoxProps={BoxProps}
	/>
);

ProjectName.propTypes = {
	iconColor: PropTypes.string,
	/** passed on to LabeledText. See LabeledText docs for details. */
	variant: PropTypes.string,
	isLoading: PropTypes.bool,
	name: PropTypes.string,
	showLabel: PropTypes.bool,
	/** passed on to LabeledIcon */
	BoxProps: PropTypes.object,
};

export default ProjectName;

import actions from './actions';

const initialState = { isOpen: false, contentKey: '', contentProps: {} };

const drawerReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.SET_DRAWER_OPEN:
			if (!action.contentKey) return state;
			return {
				...state,
				isOpen: true,
				contentKey: action.contentKey,
				contentProps: action.contentProps,
			};
		case actions.SET_DRAWER_CLOSE:
			return { ...state, isOpen: false };
		case actions.CLEAR_DRAWER_CONTENTS:
			return initialState;
		default:
			return state;
	}
};

export default drawerReducer;

import { formatPriceTimeUnit } from 'banana-stand/format';
import isOneIsh from 'utility/tools/isOneIsh';

/**
 * @file Data passing through is code is being persisted for the user's entire session.
 * parts of this code is going to run and persist data many thousands of times. We should
 * only be persisting the data we are actually going to use, which is why we are picking out
 * peices of data instead of simply storing entire objects. Data compression and pruning is
 * key to this being successful.
 *
 * ptoks - an option that can be assigned a value. Example: cheese
 * ptovs - values that can be associated with an option. Example: wisconsin cheddar
 */

/**
 * Returns the default ptov of each regions from an array of values.
 * @param {Value[]} values - values property of an element from a flat_options array (see api/product/mockData/flatDefails.js)
 * @returns {DefaultValuesByGeo}
 */
const getDefaultPtovsByRegion = (values = []) =>
	values.reduce(
		(accumulator, { ptov_id: id, region_id_defaults: regionIdDefaults }) => {
			Object.entries(regionIdDefaults).forEach(([region, isDefault]) => {
				if (isOneIsh(isDefault)) accumulator[region] = id;
			});
			return accumulator;
		},
		{},
	);

/**
 * Extrapolates data from a ptov entry
 * @param {Value} valueElem - an element from the values property of an element from a flat_options array (see api/product/mockData/flatDefails.js)
 * @returns {ValueDataDetails}
 */
const getPtovData = ({
	active: isAvailable,
	description: displayValue,
	region_id_prices: pricesByGeo,
	price_time_unit: timeUnit,
	public: isPublic,
	comments: valueComment,
	display_order: displayOrder,
	num_units: numUnits,
	num_units_min: numUnitsMin,
	num_units_max: numUnitsMax,
	num_units_locked: numUnitsLocked,
	value: valueString,
	ptov_acls: acls,
	tags,
}) => {
	const ptovData = {
		displayValue,
		pricesByGeo,
		timeUnit,
		isPublic: isOneIsh(isPublic),
		displayOrder,
		valueString,
	};
	// If this is 0 (or any other number), we need to show QuantityConfig.jsx.
	if (typeof numUnits === 'number') {
		ptovData.numUnits = {
			defaultValue: numUnits,
			min: numUnitsMin,
			max: numUnitsMax,
			isLocked: isOneIsh(numUnitsLocked),
		};
	}
	if (!isOneIsh(isAvailable)) ptovData.isUnavailable = true;
	if (valueComment) ptovData.valueComment = valueComment;
	if (acls) ptovData.acls = acls;
	if (tags) ptovData.tags = tags;
	return ptovData;
};

/**
 * extrapolates data from a ptok entry.
 * @param {FlatOption} flatOption - an element from a flat_options array (see api/product/mockData/flatDefails.js)
 * @returns {PtokDataDetails}
 */
const getPtokData = ({
	values,
	description,
	group,
	parent_ptov_id: parentPtovId,
	parent_ptok_id: parentPtokId,
	public: isPublic,
	key,
}) => {
	const ptokData = {
		defaultValuesByGeo: getDefaultPtovsByRegion(values),
		description,
		group,
		key: key.toString(),
		isPublic: isOneIsh(isPublic),
		values: values.reduce((accumulator, value) => {
			const { ptov_id: ptovId } = value;
			accumulator[ptovId] = getPtovData(value);
			return accumulator;
		}, {}),
	};
	if (parentPtovId) ptokData.parentPtovId = parentPtovId;
	if (parentPtokId) ptokData.parentPtokId = parentPtokId;
	return ptokData;
};

/**
 * creates a map of region ids to one-time prepay costs
 * @param {PrepaySettings} prepaySettings
 * @returns {Object.<string, Number>} keys are region ids, values are onetime costs
 */
const getRegionIdOneTimeCosts = (prepaySettings) => {
	const regionIdTotalOneTimeCostsMap = {};

	Object.values(prepaySettings).forEach((optionValue) => {
		// there will only ever be one key in this object and it is dynamic
		const configOnetimeCostsByRegion = Object.values(optionValue)[0].once;

		Object.entries(configOnetimeCostsByRegion).forEach(([region, cost]) => {
			if (!regionIdTotalOneTimeCostsMap[region]) {
				regionIdTotalOneTimeCostsMap[region] = Number(cost);
			} else {
				regionIdTotalOneTimeCostsMap[region] += Number(cost);
			}
		});
	});

	return regionIdTotalOneTimeCostsMap;
};

/**
 *
 * Parses flat details provided from the api
 * @param {FlatDetailsPayload} flatDetailsPayload
 * @returns {ProductDataDetails}
 * @see https://cart.liquidweb.com/storm/api/docs/bleed/Product.html#method_flatDetails

 */
const extrapolateFlatDetails = ({
	flat_options: options = [],
	region_id_prices: regionIdPrices,
	price_time_unit: priceTimeUnit,
	categories,
	description,
	title,
	prepay_settings: prepaySettings,
} = {}) => {
	const sortedOptions = options.sort(
		({ display_order: displayOrderA }, { display_order: displayOrderB }) => {
			if (displayOrderA === undefined) {
				return 1;
			}
			if (displayOrderB === undefined) {
				return -1;
			}
			return displayOrderA - displayOrderB;
		},
	);
	return sortedOptions.reduce(
		(accumulator, option) => {
			const {
				parent_ptok_id: parentId,
				ptok_id: optionId,
				group_display_order: groupDisplayOrder,
			} = option;
			if (parentId) {
				accumulator.ptokParentMap[optionId] = parentId;
				if (accumulator.ptokChildMap[parentId])
					accumulator.ptokChildMap[parentId].push(Number(optionId));
				else accumulator.ptokChildMap[parentId] = [Number(optionId)];
			} else {
				accumulator.rootPtoks[optionId] = true;
				accumulator.sortedRootPtoks.push(Number(optionId));
			}
			accumulator.ptokData[optionId] = getPtokData(option);
			accumulator.groupData[option.group] = {
				displayOrder: groupDisplayOrder,
			};

			if (prepaySettings) {
				accumulator.priceTimeUnit = 'one-time';
				accumulator.regionIdPrices = getRegionIdOneTimeCosts(prepaySettings);
			}

			return accumulator;
		},
		{
			rootPtoks: {},
			sortedRootPtoks: [],
			ptokParentMap: {},
			ptokChildMap: {},
			ptokData: {},
			groupData: {},
			regionIdPrices,
			priceTimeUnit: formatPriceTimeUnit(priceTimeUnit),
			categories,
			description,
			title,
		},
	);
};

export {
	getDefaultPtovsByRegion,
	getPtovData,
	getPtokData,
	getRegionIdOneTimeCosts,
};
export default extrapolateFlatDetails;

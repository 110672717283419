import { select, put, call } from 'redux-saga/effects';
import { takeSetOrError } from 'utility/redux/apiModuleHelpers';
import {
	actions as stormBackupListActions,
	selectors as stormBackupListSelectors,
} from 'modules/api/storm/backup/listModule';

/**
 * Fetches backup options if needed
 * @returns {boolean} ready status
 */
function* initStormBackupList() {
	const isReady = yield select(stormBackupListSelectors.hasData);
	if (isReady) return true;

	yield put(stormBackupListActions.init({ page_size: 9999 }));
	const backupRes = yield call(takeSetOrError, {
		actions: stormBackupListActions,
		selectors: stormBackupListSelectors,
	});

	if (backupRes.type === stormBackupListActions.errorType) {
		return false;
	}

	return true;
}

// eslint-disable-next-line import/prefer-default-export
export { initStormBackupList };

import { createAPIModule } from 'utility/redux/apiModuleHelpers';

import { createSelector } from 'reselect';

import { getFormValues } from 'redux-form';
import { CREATE_TRANSFER_FORM_ID } from 'containers/pages/domains/CreateTransfer/CreateTransferForm';

const getStateSlice = (state) => state.api.network.dns.domain.tldDetails;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_DNS_DOMAIN_TLDDETAILS',
	method: 'POST',
	url: '/network/dns/domain/tldDetails.json',
});

const selectors = {
	transferPrice: createSelector(
		defaultSelectors.getStateSlice,
		getFormValues(CREATE_TRANSFER_FORM_ID),
		(slice, formValues) => {
			const domain = formValues ? formValues.domain : null;
			return slice.getIn(['data', domain, 'transfer_price']);
		},
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };

import React from 'react';
import { useHistory } from 'react-router';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import LWTypography from 'components/common/LWTypography';
import useProjectDelete from 'modules/queries/project/useDelete';
import snackbarActions from 'modules/snackbar/snackbarActions';
import { selectors } from 'modules/deleteProjectDialog/selectors';
import actions from 'modules/deleteProjectDialog/actions';
import { useDispatch, useSelector } from 'react-redux';

const DeleteDialog = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { mutate: deleteProject } = useProjectDelete();
	const isOpen = useSelector(selectors.isOpen);
	const dialogProps = useSelector(selectors.dialogProps);
	const { projectId, projectName, redirect } = dialogProps;

	const toggleDialog = (value) => {
		dispatch(actions.toggleDialog(value));
	};

	return (
		<ConfirmationDialog
			color="danger"
			open={isOpen}
			onConfirm={() =>
				deleteProject(
					{ id: projectId },
					{
						onSuccess: () => {
							if (redirect) {
								history.push('/projects');
							}

							dispatch(
								snackbarActions.pushMessage({
									variant: 'success',
									message: `Successfully deleted project ${projectName}`,
								}),
							);
						},
					},
				)
			}
			onClose={() => toggleDialog(false)}
			title="Delete Project?"
		>
			<LWTypography>
				Deleting a project{' '}
				<LWTypography inline bold>
					will not
				</LWTypography>{' '}
				delete the assets it contains, only the Project Dashboard your selection
				has generated. Once a project is deleted, it cannot be recovered.
			</LWTypography>
			<br />
			<LWTypography>
				Are you sure you want to delete{' '}
				<LWTypography inline bold>
					{projectName}
				</LWTypography>
				?
			</LWTypography>
		</ConfirmationDialog>
	);
};

export default DeleteDialog;

import dialogActions from 'modules/dialogs/actions';

const cartActions = {
	CART_INITIALIZE: 'CART_INITIALIZE',
	initialize: () => ({
		type: cartActions.CART_INITIALIZE,
	}),
	CART_INIT_HAS_STARTED: 'CART_INIT_HAS_STARTED',
	initHasStarted: (bool = true) => ({
		type: cartActions.CART_INIT_HAS_STARTED,
		value: bool,
	}),
	CART_UNINITIALIZED: 'CART_UNINITIALIZED',
	uninitialized: () => ({
		type: cartActions.CART_UNINITIALIZED,
	}),
	CART_INITIALIZED: 'CART_INITIALIZED',
	initialized: () => ({
		type: cartActions.CART_INITIALIZED,
	}),
	CART_SET_CUSTOMER_PREVIEW: 'CART_SET_CUSTOMER_PREVIEW',
	setIsCustomerPreview: ({ value }) => ({
		type: cartActions.CART_SET_CUSTOMER_PREVIEW,
		payload: { isCustomerPreview: value },
	}),
	CART_STEPPER_INIT: 'CART_STEPPER_INIT',
	init: () => ({
		type: cartActions.CART_STEPPER_INIT,
	}),
	CART_PATCH_ITEM_PROPERTIES: 'CART_PATCH_ITEM_PROPERTIES',
	/**
	 * @param {object} param0
	 * @param {any} param0.properties
	 * @param {string} param0.uuid the item to patch
	 */
	patchItemProperties: ({ properties, uuid }) => ({
		type: cartActions.CART_PATCH_ITEM_PROPERTIES,
		properties,
		uuid,
	}),
	CART_APPLY_ADDITIONAL_INSTRUCTIONS: 'CART_APPLY_ADDITIONAL_INSTRUCTIONS',
	/**
	 * @param {object} param0
	 * @param {string} param0.additionalInstructions
	 */
	applyAdditionalInstructions: ({ additionalInstructions }) => ({
		type: cartActions.CART_APPLY_ADDITIONAL_INSTRUCTIONS,
		additionalInstructions,
	}),
	CART_SUBMIT_ORDER: 'CART_SUBMIT_ORDER',
	submitOrder: () => ({
		type: cartActions.CART_SUBMIT_ORDER,
	}),
	CART_RESET_IS_ORDER_PROCESSING: 'CART_RESET_IS_ORDER_PROCESSING',
	resetIsOrderProcessing: () => ({
		type: cartActions.CART_RESET_IS_ORDER_PROCESSING,
	}),
	/**
	 * @param {object} param0
	 * @param {Array} param0.alsowith
	 * @param {string} param0.itemUuid
	 * @param {string} param0.uuid
	 * @param {() => void} param0.onError
	 */
	CART_REMOVE_ITEM: 'CART_REMOVE_ITEM',
	removeItem: ({ alsowith, cartUuid, itemUuid, onError }) => ({
		type: cartActions.CART_REMOVE_ITEM,
		alsowith,
		cartUuid,
		itemUuid,
		onError,
	}),
	CART_ADD_COUPON: 'CART_ADD_COUPON',
	/**
	 * @param {object} param0
	 * @param {string} param0.code the coupon code
	 * @param {() => {}} param0.onFinally callback to be called once api call returns
	 * @param {(any) => {}} param0.onError callback to be called if an error is returned from the api
	 */
	addCoupon: ({ code, onFinally, onError }) => ({
		type: cartActions.CART_ADD_COUPON,
		code,
		onFinally,
		onError,
	}),
	CART_BULK_DISCOUNT: 'CART_BULK_DISCOUNT',
	/**
	 * @param {object} param0
	 * @param {number} param0.amount percent amount to be discounted
	 * @param {number | undefined} param0.repetitions the number of months that the discount should be applied
	 * @param {() => {}} param0.onFinally callback to be called once api call returns
	 * @param {(any) => {}} param0.onError callback to be called if an error is returned from the api
	 */
	bulkDiscount: ({ amount, repetitions, onFinally, onError }) => ({
		type: cartActions.CART_BULK_DISCOUNT,
		amount,
		repetitions,
		onFinally,
		onError,
	}),
	CART_RESET_BULK_DISCOUNT: 'CART_RESET_BULK_DISCOUNT',
	/**
	 * @param {object} param0
	 * @param {() => {}} param0.onFinally callback to be called once api call returns
	 * @param {(any) => {}} param0.onError callback to be called if an error is returned from the api
	 */
	resetBulkDiscount: ({ onError, onFinally }) => ({
		type: cartActions.CART_RESET_BULK_DISCOUNT,
		onError,
		onFinally,
	}),
	CART_REMOVE_DISCOUNT: 'CART_REMOVE_DISCOUNT',
	/**
	 * @param {object} param0
	 * @param {string | string[]} param0.uuid the discount uuid (or an array of discount uuids)
	 * @param {() => {}} param0.onFinally callback to be called once api call returns
	 * @param {(any) => {}} param0.onError callback to be called if an error is returned from the api
	 */
	removeDiscount: ({ uuid, onFinally, onError }) => ({
		type: cartActions.CART_REMOVE_DISCOUNT,
		uuid,
		onFinally,
		onError,
	}),
	CART_ASSERT_METADATA: 'CART_ASSERT_METADATA',
	/**
	 *
	 * @param {object} param0
	 * @param {object} param0.metadata
	 * @param {(error: object) => void} param0.onError
	 */
	assertMetadata: ({ metadata, onError }) => ({
		type: cartActions.CART_ASSERT_METADATA,
		metadata,
		onError,
	}),
	CART_ITEM_REMOVED: 'CART_ITEM_REMOVED',
	/**
	 * @param {object} param0
	 * @param {MarketCartItem} param0.item
	 * @param {MarketCartItemTotal} param0.itemTotals
	 * */
	itemRemoved: ({ item, itemTotals }) => ({
		type: cartActions.CART_ITEM_REMOVED,
		item,
		itemTotals,
	}),
	CART_DUPLICATE_ITEM: 'CART_DUPLICATE_ITEM',
	/**
	 * @param {object} param0
	 * @param {string} param0.itemUuid
	 * */
	duplicateItem: ({ itemUuid }) => ({
		type: cartActions.CART_DUPLICATE_ITEM,
		itemUuid,
	}),
	CART_MODIFY_HOSTNAME: 'CART_MODIFY_HOSTNAME',
	/**
	 * @param {object} param0
	 * @param {string} param0.entityUuid
	 * @param {string} param0.domain
	 * @param {string} [param0.subdomain]
	 * */
	modifyHostname: ({ entityUuid, domain, subdomain }) => ({
		type: cartActions.CART_MODIFY_HOSTNAME,
		entityUuid,
		domain,
		subdomain,
	}),
	CART_CLAIM: 'CART_CLAIM',
	claim: ({ isManual = true } = {}) => ({
		type: cartActions.CART_CLAIM,
		isManual,
	}),
	CART_ADMIN_UPDATE_ITEM: 'CART_ADMIN_UPDATE_ITEM',
	/**
	 * @param {object} param0
	 * @param {string} param0.itemUuid
	 * @param {number} param0.discountAmount
	 * @param {number | undefined} param0.discountRepetitions
	 * @param {string} param0.customerNotes
	 * @param {() => void} param0.onError
	 * @param {() => void} param0.onFinally
	 */
	adminUpdateItem: ({
		itemUuid,
		discountAmount,
		discountRepetitions,
		customerNotes,
		onError,
		onFinally,
	}) => ({
		type: cartActions.CART_ADMIN_UPDATE_ITEM,
		itemUuid,
		discountAmount,
		discountRepetitions,
		customerNotes,
		onError,
		onFinally,
	}),
	CART_ADMIN_SET_EXPIRE: 'CART_ADMIN_SET_EXPIRE',
	/**
	 * @param {object} param0
	 * @param {string} param0.date
	 * @param {number} param0.discountAmount
	 * @param {string} param0.customerNotes
	 */
	adminSetExpire: ({ date }) => ({
		type: cartActions.CART_ADMIN_SET_EXPIRE,
		date,
	}),
	CART_OPEN_ABANDON_DIALOG: 'CART_OPEN_ABANDON_DIALOG',
	/**
	 * @param {object} param0
	 * @param {() => void} param0.onAbandoned
	 */
	openAbandonCartDialog: ({ onAbandoned }) => {
		return dialogActions.open({
			contentKey: 'AbandonCartModalContents',
			contentProps: {
				onAbandoned,
			},
			color: 'danger',
			dismissible: false,
		});
	},
	CART_ABANDON: 'CART_ABANDON',
	/**
	 * @param {object} param0
	 * @param {() => void} param0.onAbandoned
	 */
	abandon: ({ onAbandoned }) => ({
		type: cartActions.CART_ABANDON,
		onAbandoned,
	}),
	CART_UPDATE_ITEM_UNITS: 'CART_UPDATE_ITEM_UNITS',
	/**
	 * @param {object} param0
	 * @param {string} param0.itemUuid
	 * @param {number} param0.units
	 * @param {() => void} param0.onSuccess
	 * @param {() => void} param0.onError
	 */
	updateItemUnits: ({ itemUuid, units, onSuccess, onError }) => ({
		type: cartActions.CART_UPDATE_ITEM_UNITS,
		itemUuid,
		units,
		onSuccess,
		onError,
	}),
	CART_EDIT_DETAILS: 'CART_EDIT_DETAILS',
	/**
	 * @param {object} param0
	 * @param {string} param0.description
	 * @param {string} param0.domainToAutoAdd
	 * @param {string} param0.name
	 * @param {(error: any) => void} param0.onError
	 */
	editDetails: ({ name, description, domainToAutoAdd, onError }) => ({
		type: cartActions.CART_EDIT_DETAILS,
		description,
		domainToAutoAdd,
		name,
		onError,
	}),
};

export default cartActions;

import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';

const NotFound = lazy(() => import('pages/error/NotFound'));

const SubNavRoutes = (props) => {
	const { subNavs, whitelistedRoles } = props;
	return (
		<Switch>
			{Object.values(subNavs).map((subNavData) => (
				<RoleRestrictedRoute
					key={subNavData?.path}
					path={subNavData?.path}
					component={subNavData?.component}
					whitelistedRoles={whitelistedRoles}
					exact
				/>
			))}
			<Route component={NotFound} />
		</Switch>
	);
};

SubNavRoutes.propTypes = {
	subNavs: PropTypes.object.isRequired,
	whitelistedRoles: PropTypes.array,
};

export default SubNavRoutes;

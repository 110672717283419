import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.server.destroy;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'SERVER_DESTROY',
	url: '/server/destroy.json',
});

export {
	actions as serverDestroyActions,
	reducer,
	sagas,
	selectors as serverDestroySelectors,
};

import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.market.cart.claim;

const { actions, reducer, sagas, selectors, additionalSagas } = createAPIModule(
	{
		getStateSlice,
		actionType: 'MARKET_CART_CLAIM',
		url: '/market/cart/claim.json',
	},
);

export {
	actions as cartClaimActions,
	reducer as cartClaimReducer,
	sagas as cartClaimSagas,
	selectors as cartClaimSelectors,
	additionalSagas as cartClaimAdditionalSagas,
};

import { createSelector } from 'reselect';

const getStateSlice = (state) => state.server.taskScheduler;

const getApiParams = createSelector(
	getStateSlice,
	(taskScheduler) => taskScheduler.apiParams,
);

const getHostname = createSelector(
	getStateSlice,
	(taskScheduler) => taskScheduler.hostname,
);

const getIsOpen = createSelector(
	getStateSlice,
	(taskScheduler) => taskScheduler.isOpen,
);

const getTask = createSelector(
	getStateSlice,
	(taskScheduler) => taskScheduler.task,
);

const getCanAutoReboot = createSelector(
	getStateSlice,
	(taskScheduler) => taskScheduler.canAutoReboot,
);

const getForceReboot = createSelector(
	getStateSlice,
	(taskScheduler) => taskScheduler.forceReboot,
);

const selectors = {
	getStateSlice,
	getApiParams,
	getHostname,
	getIsOpen,
	getTask,
	getCanAutoReboot,
	getForceReboot,
};

export default selectors;

import React from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { actions as addForParentActions } from 'modules/basket/addItemForParent';
import { cartItemAddSelectors } from 'modules/api/market/cart/item/addModule';
import cartSelectors from 'modules/cart/selectors';

import CCCTable from 'components/common/CCCTable';
import LWTypography from 'components/common/LWTypography';
import LWButton from 'components/common/LWButton';
import cartActions from 'modules/cart/actions';
import getTableData, { getTableHeaders } from './getTableData';

const testids = {
	PrivateParentChildren__Container: 'PrivateParentChildren__Container',
};

/** @param {import('modules/queries/asset/useDetails').AssetDetails} props */
const PrivateParentChildren = ({ uniq_id: uniqId, children }) => {
	const dispatch = useDispatch();
	const isCartLocked = useSelector(cartSelectors.getIsCartLocked);
	const addChild = () => {
		dispatch(addForParentActions.addItemForParent({ uniqId }));
	};
	const addingChild = useSelector(cartItemAddSelectors.isLoading);
	const headers = getTableHeaders();
	const data = getTableData(children);

	const handleClick = () => {
		if (isCartLocked) {
			dispatch(cartActions.openAbandonCartDialog({ onAbandoned: addChild }));
		} else {
			addChild();
		}
	};

	return (
		<CCCTable
			upperLeftContent={
				<LWTypography variant="subtitle1" BoxProps={{ pt: 2, pl: 2 }}>
					Child Instances
				</LWTypography>
			}
			upperRightContent={
				<LWButton
					isLoading={addingChild}
					onClick={handleClick}
					BoxProps={{ pt: 2, pr: 2 }}
				>
					Add Child
				</LWButton>
			}
			data={data}
			headers={headers}
			noDataText="Server has no Children"
			data-testid={testids.PrivateParentChildren__Container}
		/>
	);
};

export { testids };
export default PrivateParentChildren;

import axios from 'axios';
import { select } from 'redux-saga/effects';
import { selectors as appConfigSelectors } from 'modules/appConfig';

export default function* axiosCreate() {
	const openidHost = (yield select(appConfigSelectors.getOpenIdConfig))
		.authority;
	const baseURL = `${openidHost}/`;
	return axios.create({
		baseURL,
		timeout: 60000,
		withCredentials: true,
	});
}

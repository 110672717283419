import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import dialogActions from 'modules/dialogs/actions';
import { adminCartsActions } from 'modules/adminCarts';

// MUI components
import Grid from '@material-ui/core/Grid';

// custom components
import HookedTextField from 'components/LWForm/HookedTextField';
import HookedConfirmCancel from 'components/LWForm/HookedConfirmCancel';
import LWForm from 'components/LWForm';
import { validateOppId } from 'utility/lwForm/validators';

const OpportunityModalContents = () => {
	const dispatch = useDispatch();
	const [formValues, setFormValues] = useState({ oppId: '' });
	const [formErrors, setFormErrors] = useState({ oppId: '' });

	const closeDialog = () => dispatch(dialogActions.close());

	const handleSubmit = () => {
		closeDialog();
		dispatch(
			adminCartsActions.attachOpportunityId({
				opportunityId: formValues.oppId,
			}),
		);
	};

	return (
		<Grid
			container
			direction="column"
			component={LWForm}
			spacing={3}
			onSubmit={handleSubmit}
			values={formValues}
			errors={formErrors}
			onValuesChange={setFormValues}
			onErrorsChange={setFormErrors}
			validators={{ oppId: validateOppId }}
		>
			<Grid item>
				<HookedTextField
					label="Opportunity Number"
					name="oppId"
					id="opportunityNumber"
				/>
			</Grid>
			<Grid item>
				<HookedConfirmCancel confirmText="Save" cancel={closeDialog} />
			</Grid>
		</Grid>
	);
};

export default OpportunityModalContents;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'utility/compose';
import { withStyles } from '@material-ui/core/styles';
import LWLink from 'components/common/LWLink';
import {
	actions as advertisementActions,
	selectors as advertisementSelectors,
} from 'modules/api/account/adCampaignsModule';
import { selectors as routeSelectors } from 'modules/route';
import eventCategories from 'utility/constants/analyticEventCategories';

const styles = () => ({
	root: {
		flexBasis: '900px',
		height: '100%',
		width: '100%',
	},
	link: {
		height: '100%',
	},
	image: {
		width: '100%',
		height: '100%',
		display: 'block',
	},
});

const Ad = ({ classes, ads, path, fetchAds }) => {
	const [ad, setAd] = useState(ads.first());

	useEffect(() => {
		fetchAds();
	}, [fetchAds]);

	useEffect(() => {
		const sortedAds = ads.sortBy(Math.random);
		setAd(sortedAds.first());
	}, [ads, path]);

	return (
		<div className={classes.root}>
			{ad && (
				<LWLink
					to={ad.get('customer_portal_ad_link_url')}
					classes={{ root: classes.link }}
					eventOptions={{
						category: eventCategories.AD_INTERACTION,
						action: ad.get('customer_portal_ad_link_url'),
						label: 'Footer Ad',
					}}
				>
					<img
						src={ad.get('customer_portal_ad_image_url')}
						alt="Advertisement"
						className={classes.image}
					/>
				</LWLink>
			)}
		</div>
	);
};

Ad.propTypes = {
	classes: PropTypes.object.isRequired,
	ads: PropTypes.object,
	path: PropTypes.string.isRequired,
	fetchAds: PropTypes.func.isRequired,
};

Ad.defaultProps = {
	ads: null,
};

const mapStateToProps = (state) => ({
	ads: advertisementSelectors.getAds(state),
	path: routeSelectors.getPathName(state),
});

const mapDispatchToProps = (dispatch) => ({
	fetchAds: () => dispatch(advertisementActions.fetch()),
});

export default compose(
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
)(Ad);

export { Ad };

import React from 'react';
import { takeLatest, put, take, select, call } from 'redux-saga/effects';
import {
	actions as assetDetailsActions,
	selectors as assetDetailsSelectors,
	moduleKeys as assetDetailsModuleKeys,
} from 'modules/api/asset/detailsModule';
import {
	actions as productDetailsActions,
	moduleKeys as productDetailsModuleKeys,
} from 'modules/api/product/detailsModule';
import { actions as backupListActions } from 'modules/api/storm/backup/listModule';
import {
	actions as serverUpdateActions,
	selectors as serverUpdateSelectors,
} from 'modules/api/server/updateModule';
import { selectors as routeSelectors } from 'modules/route';
import acronisSelectors from 'modules/api/asset/detailsModule/acronisSelectors';
import { removeUndefinedKeys } from 'utility/tools/objects';
import dialogActions from 'modules/dialogs/actions';
import snackbarSaga from 'modules/snackbar/sagas';
import { actions as usageActions } from 'modules/api/acronis/backup/usagesModule';
import acronisActions from 'modules/acronis/actions';
import acronisProductMap from 'utility/acronisProductMap';
import { handleInitialize } from 'modules/basket/productConfig/sagas';
import productConfigActions from 'modules/basket/productConfig/actions';

import QuotaSettingsDialogContent from 'components/Backups/QuotaSettings/QuotaSettingsDialogContent';
import selectors from './selectors';
import actions from './actions';

const alsowith = [
	'backupsAndStorage',
	'featureDetails',
	'instance',
	'categories',
];

const createUpdatePayload = ({ selectedPlan, selectedQuota, daysToRetain }) => {
	const obj = { LiquidWebBackupPlan: selectedPlan };
	switch (selectedPlan) {
		case 'Quota':
			obj.BackupQuota = selectedQuota?.value?.toString();
			break;
		case 'CloudDaily':
		case 'Daily':
			obj.BackupDay = {
				value: daysToRetain && 1,
				num_units: daysToRetain?.toString(),
			};
			break;
		default:
			break;
	}
	return removeUndefinedKeys(obj);
};

// Submits user input to /server/update
function* updateSettings({ uniqId, fromModal }) {
	const { selectedPlan, selectedQuota, daysToRetain } = yield select(
		selectors.getStateSlice,
	);
	if (selectedPlan === 'Quota' && !fromModal) {
		yield put(
			dialogActions.open({
				title: 'Confirm Backups Settings Change',
				content: <QuotaSettingsDialogContent selectedQuota={selectedQuota} />,
			}),
		);
		return;
	}
	if (selectedPlan === 'Quota' && fromModal) {
		yield put(dialogActions.close());
	}

	yield put(
		serverUpdateActions.fetch({
			uniq_id: uniqId,
			features: createUpdatePayload({
				selectedPlan,
				selectedQuota,
				daysToRetain,
			}),
		}),
	);
	const result = yield take([
		serverUpdateActions.setType,
		serverUpdateActions.errorType,
	]);
	yield call(snackbarSaga, {
		error: result.type === serverUpdateActions.errorType,
		successMessage: 'Your settings have been saved.',
		errorMessage: yield select(serverUpdateSelectors.getErrorString),
	});
	yield put(actions.init({ uniqId }));
}

// Resets redux state to what the api response is.
function* resetSettings() {
	const hasAcronis = yield select(assetDetailsSelectors.hasAcronis);
	const { defaultSelection } = yield select(routeSelectors.getQueryParams);
	const currentPlan = (yield select(assetDetailsSelectors.backupPlanRaw))
		?.value;
	if (defaultSelection) {
		yield put(
			actions.setSelectedPlan({
				selectedPlan: defaultSelection,
			}),
		);
	} else {
		yield put(
			actions.setSelectedPlan({
				selectedPlan: hasAcronis ? 'Acronis' : currentPlan,
			}),
		);
	}
	const daysToRetain = yield select(assetDetailsSelectors.daysToRetain);
	yield put(actions.setDaysToRetain({ daysToRetain }));
	yield put(
		actions.setSelectedQuota({ selectedQuota: { value: '0', price: 0.0 } }),
	);
	// TODO: Reset all settings to the api response as we create them in the reducer.
	yield put(
		acronisActions.setSelectedProductOptionObj({
			selectedProductOptionObj: null,
		}),
	);
}

// Calls APIs necessary for the ChangeAcronis component and initializes redux values
function* initChangeAcronis() {
	const acronisId = yield select(assetDetailsSelectors.getAcronisId);
	if (!acronisId) return;
	yield put(
		assetDetailsActions.fetch(
			{
				uniq_id: acronisId,
				alsowith: ['featureDetails', 'acronisBackingUp', 'product'],
			},
			// Using a module key since we need two assets loaded at once.
			assetDetailsModuleKeys.ACRONIS,
		),
	);
	yield put(
		usageActions.fetch({
			uniq_id: acronisId,
		}),
	);
}

function* initSettings({ uniqId }) {
	yield put(backupListActions.fetch({ uniq_id: uniqId, page_size: 999 }));

	// Takes need to come directly after the fetch so that test will pass, and because they probably should anyway.
	yield put(assetDetailsActions.fetch({ uniq_id: uniqId, alsowith }));
	const {
		payload: { type },
	} = yield take(assetDetailsActions.setType);

	// Get the data we need for displaying ChangeAcronis if it's on this server.
	// This needs to run before the productDetails init as we need to know the existing Acronis product code (if available)
	yield call(initChangeAcronis);

	let acronisProductCode;
	const acronisId = yield select(assetDetailsSelectors.getAcronisId);
	if (acronisId) {
		if (yield select(acronisSelectors.isLoading)) {
			let moduleKey;
			while (moduleKey !== assetDetailsModuleKeys.ACRONIS) {
				const result = yield take(assetDetailsActions.setType); // Need to wait for the assetDetails in initChangeAcronis to complete
				moduleKey = result?.moduleKey;
			}
		}
		acronisProductCode = yield select(acronisSelectors.getProductCode);
	} else {
		acronisProductCode = acronisProductMap(type);
	}
	// This is needed changing acronis plans.
	yield put(
		productDetailsActions.init(
			{ code: acronisProductCode },
			productDetailsModuleKeys.ACRONIS,
		),
	);

	yield call(resetSettings); // Initializes the state once we have the data in the first place.
	yield put(productDetailsActions.fetch({ code: type })); // TODO: make a dynamic module key for each product and change this from [fetch] to [init]
}

function* handleInitAddAcronis() {
	const serverProductCode = yield select(assetDetailsSelectors.productType);
	const serverId = yield select(assetDetailsSelectors.uniqId);
	const domain = yield select(assetDetailsSelectors.domain);
	const region = yield select(assetDetailsSelectors.regionId);
	const acronisProductCode = acronisProductMap(serverProductCode);
	const acronisInBasketUuid = yield select(selectors.getAcronisInBasketUuid);
	const productKey = acronisInBasketUuid || acronisProductCode;

	yield call(handleInitialize, {
		productCode: acronisProductCode,
		uuid: acronisInBasketUuid,
	});

	yield put(
		productConfigActions.setProperties({
			productKey,
			properties: {
				related_subaccnt: serverId,
				domain,
			},
		}),
	);

	yield put(
		productConfigActions.patchSelectedProductRegions({ productKey, region }),
	);
}

export {
	initSettings,
	resetSettings,
	updateSettings,
	initChangeAcronis,
	alsowith,
};
export default function* root() {
	yield takeLatest(
		actions.SERVER_BACKUPS_AND_IMAGES_INIT_SETTINGS,
		initSettings,
	);
	yield takeLatest(
		actions.SERVER_BACKUPS_AND_IMAGES_INIT_CHANGE_ACRONIS,
		initChangeAcronis,
	);
	yield takeLatest(
		actions.SERVER_BACKUPS_AND_IMAGES_UPDATE_SETTINGS,
		updateSettings,
	);
	yield takeLatest(
		actions.SERVER_BACKUPS_AND_IMAGES_RESET_SETTINGS,
		resetSettings,
	);
	yield takeLatest(
		actions.SERVER_BACKUPS_AND_IMAGES_INIT_ADD_ACRONIS,
		handleInitAddAcronis,
	);
}

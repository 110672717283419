import React from 'react';

function PaperDotted(props) {
	return (
		<svg width={24} height={24} viewBox="0 0 24 24" {...props}>
			<g data-name="Icon-FileClient">
				<path data-name="Path 107" d="M0 0h24v24H0z" fill="none" />
				<path
					data-name="Path 106"
					d="M18.5 20h2a2 2 0 01-2 2zm-3 2v-2h2v2zm-3 0v-2h2v2zm-3 0v-2h2v2zm-3 0v-2h2v2zm-3-2h2v2a2 2 0 01-2-2zm15-1v-2h2v2zm-15 0v-2h2v2zm15-3v-2h2v2zm-15 0v-2h2v2zm15-3v-2h2v2zm-15 0v-2h2v2zm15-3V8h2v2zm-3 0V8h2v2zm-3 0V8h2v2zm-9 0V8h2v2zm15-3V5l2 2zm-3-2h2v2zm-3 2V5h2v2zm-9 0V5h2v2zm12-3V2l2 2zm-3 0V2h2v2zm-3 0V2h2v2zm-3 0V2h2v2zm-3 0a2 2 0 012-2v2z"
					fill="#03a0ca"
				/>
			</g>
		</svg>
	);
}

export default PaperDotted;

const actions = {
	GDPR_SET_COUNTRY_CODE: 'GDPR_SET_COUNTRY_CODE',
	/**
	 * Sets the country code of cartStack module.
	 * @param {string} param0.countryCode
	 * @returns {object}
	 */
	setCountryCode: ({ countryCode }) => ({
		type: actions.GDPR_SET_COUNTRY_CODE,
		payload: { countryCode },
	}),
	GDRP_SET_ERROR: 'GDRP_SET_ERROR',
	setError: ({ error }) => ({
		type: actions.GDRP_SET_ERROR,
		payload: { error },
	}),
	GDPR_GET_USER_COUNTRY: 'GDPR_GET_USER_COUNTRY',
	getUserCounty: () => ({
		type: actions.GDPR_GET_USER_COUNTRY,
	}),
	CART_STACK_SEND_ANALYTICS: 'CART_STACK_SEND_ANALYTICS',
	sendAnalytics: ({ countryCode, sendGtmData }) => ({
		type: actions.CART_STACK_SEND_ANALYTICS,
		payload: { countryCode, sendGtmData },
	}),
};

export default actions;

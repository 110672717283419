import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { uniqueList } from 'utility/array';
import { useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorText from 'components/molecules/ErrorText';

import {
	ResponsiveContainer,
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
} from 'recharts';

// MUI components
import Box from '@material-ui/core/Box';

// LW components
import LWTypography from 'components/common/LWTypography';

const Graph = ({
	data = [],
	title,
	subtitle,
	tickFormatter = (tickValue) => tickValue,
	isLoading,
	errorText,
}) => {
	const theme = useTheme();
	const dataKeys = useMemo(
		/**
		 * Takes the data array passed in and outputs a unique set of keys created from all of the objects in the data set.
		 * [{key1: 'value1', key2: 'value2'}, {key1: 'value1', key3: 'value3}] becomes ['key1', 'key2', 'key3']
		 * @returns {array}
		 */
		() => uniqueList(data.map((node) => Object.keys(node)).flat()),
		[data],
	);
	if (isLoading) return <CircularProgress />;
	if (errorText)
		return (
			<ErrorText
				contentArray={[
					{
						key: 'graphError',
						label: 'Graph Error',
						text: errorText,
					},
				]}
				title=""
				hideRefresh
			/>
		);
	return (
		<>
			{(title || subtitle) && (
				<Box mb={2}>
					{title && <LWTypography variant="h3">{title}</LWTypography>}
					{subtitle && (
						<LWTypography color="textSecondary" variant="caption">
							{subtitle}
						</LWTypography>
					)}
				</Box>
			)}

			<ResponsiveContainer height={250} width="100%">
				<LineChart
					data={data}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid />
					<XAxis
						interval="preserveStartEnd"
						height={100}
						angle={-35}
						textAnchor="end"
						dataKey={dataKeys.slice(-1)[0]}
						tickMargin={theme.spacing(1)}
					/>

					<YAxis
						tickFormatter={tickFormatter}
						interval="preserveStartEnd"
						width={100}
					/>
					<Tooltip isAnimationActive={false} formatter={tickFormatter} />
					<Legend />
					{dataKeys
						.slice(
							0,
							-1 /* do not include the last key, as that is the x key. */,
						)
						.map((key, colorIdx) => (
							<Line
								key={key}
								isAnimationActive={false}
								type="monotone"
								dataKey={key}
								stroke={
									theme.palette.graph?.[colorIdx % theme.palette.graph.length]
								}
							/>
						))}
				</LineChart>
			</ResponsiveContainer>
		</>
	);
};

Graph.propTypes = {
	/** An array of objects. Each key in the object is displayed with the associated value. The last key is the x axis. */
	data: PropTypes.arrayOf(
		PropTypes.objectOf(
			PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		),
	),
	title: PropTypes.string,
	subtitle: PropTypes.string,
	/** function to generate the y axis ticks. A value is passed as the first arg. The return value should be a string. */
	tickFormatter: PropTypes.func,
	isLoading: PropTypes.bool,
	/** replaces graph with an error message */
	errorText: PropTypes.string,
};

export default Graph;

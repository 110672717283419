// Local storage key name
const CART_UUID = 'lwCartID';
const CART_ITEM_COUNT_COOKIE = 'basket_item_count';

const configKeys = {
	ExtraIp: 'ExtraIp',
	Acronis: 'Acronis',
	AcronisStorageDestination: 'AcronisStorageDestination',
	AcronisStorageQuotaLiquidWeb: 'AcronisStorageQuotaLiquidWeb',
	AcronisStorageQuotaCloud: 'AcronisStorageQuotaCloud',
	BackupDay: 'BackupDay',
	BackupStorage: 'BackupStorage',
	Bandwidth: 'Bandwidth',
	ConfigId: 'ConfigId',
	Template: 'Template',
	MsSQL: 'MsSQL',
	WindowsLicense: 'WindowsLicense',
	LiquidWebBackupPlan: 'LiquidWebBackupPlan',
	BandwidthOverage: 'BandwidthOverage',
	cPanelLicenseTier: 'cPanelLicenseTier',
	DomainRegistration: 'DomainRegistration',
	DomainRegistrationAutoRenew: 'DomainRegistrationAutoRenew',
	DomainPrivacy: 'DomainPrivacy',
};

const groupNames = {
	'Network Options': 'Network Options',
};

/** constants that represent the valueStrings of ptovIds */
const configValueStrings = { dynamicConfigId: '0' };

export {
	CART_UUID,
	configKeys,
	CART_ITEM_COUNT_COOKIE,
	configValueStrings,
	groupNames,
};

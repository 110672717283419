import { createSelector } from 'reselect';

const getStateSlice = (state) => state.snackbar;

/**
 * Get all messages
 * @returns {Object[]}
 */
const getMessages = createSelector(
	getStateSlice,
	(slice) => slice?.messages,
);

/**
 * Get message count
 * @returns {number} count
 */
const getMessageCount = createSelector(
	getMessages,
	(message) => message.length,
);

/**
 * Get message ids
 * @returns {string[]}
 */
const getMessageIds = createSelector(
	getMessages,
	(messages) => messages.map(({ id }) => id),
);

/**
 * Get message by Id
 * @param {Object} state
 * @param {string} id
 * @returns {Object}
 */
const getMessageById = (id) =>
	createSelector(
		getMessages,
		(messages) => messages.filter((message) => message.id === id)[0],
	);

/**
 * Get the last snack in the queue
 * @returns {Object|undefined}
 */
const getLastSnack = createSelector(
	getMessages,
	(messages) => messages.slice(-1)[0],
);

/**
 * Get the last snack in the queue
 * @deprecated
 * @returns {Object|undefined}
 */
const getCurrentSnack = createSelector(getLastSnack);

/**
 * Get the last snack message in the queue
 * @deprecated
 * @returns {String}
 */
const getCurrentMessage = createSelector(
	getLastSnack,
	(snack) => snack?.message,
);

/**
 * Get the last snack varient in the queue
 * @deprecated
 * @returns {Object|undefined}
 */
const getCurrentVariant = createSelector(
	getLastSnack,
	(snack) => snack?.varient,
);

/**
 * Get the last snack contentProps in the queue
 * @deprecated
 * @returns {Object|undefined}
 */
const getCurrentContentProps = createSelector(
	getLastSnack,
	(snack) => snack?.contentProps,
);

/**
 * Get the last snack as an Object
 * @deprecated
 * @returns {Object}
 */
const getCurrentMessageObj = createSelector(
	getLastSnack,
	(snack) => snack || {},
);

const snackbarSelectors = {
	getMessages,
	getMessageCount,
	getMessageIds,
	getMessageById,
	getCurrentSnack,
	getCurrentMessage,
	getCurrentVariant,
	getCurrentMessageObj,
	getCurrentContentProps,
};
export default snackbarSelectors;

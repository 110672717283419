import React from 'react';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import upperFirst from 'lodash/upperFirst';
import { fromApiBoolean } from 'club-sauce';
import { withStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import NotFound from 'pages/error/NotFound';

import PageOverview from 'components/structural/PageOverview';
import NavTracker from 'components/structural/NavTracker';
import FullScreenError from 'components/molecules/FullScreenError';

import scheduledTaskCapabilities from 'utility/constants/scheduledTaskCapabilities';

import manageRedirectActions from 'modules/manageRedirect/manageRedirectActions';
import { useAllStorageBlockClusterList } from 'modules/queries/storage/block/cluster/useList';
import { usePollAssetDetails } from 'modules/queries/asset/useDetails';
import { getErrorStringHelper } from 'utility/redux/selectorHelperFunctions/apiModules';

import Networking from './Networking';
import ServerDetails from './ServerDetails';
import Project from './Project';
import Backups from './Backups';
import Services from './Services';
import PrivateParentChildren from './PrivateParentChildren';
import Health from './Health';
import TickStackHealth from './TickStackHealth';
import CloudHealth from './CloudHealth';
import PrivateParentResources from './PrivateParentResources';

const alsowith = [
	'nocworxRemoteDetails',
	'privateParent',
	'featureDetails',
	'categories',
	'capabilities',
	'networkSummary',
	'children',
	'backupsAndStorage',
	'resourceState',
	'diskDetails',
	'mesDetails',
	'powerStatus',
	'zone',
	'instance',
];

const styles = (theme) => ({
	infoWidgets: {
		[theme.breakpoints.down('sm')]: {
			padding: 0,
		},
		width: '100%',
		margin: '0',
	},
});

const Graphs = ({ canServerStatsCloud, canServerStatsLocal, uniqId }) => {
	switch (true) {
		// For now, we want to continue to prefer stats
		// reported from the Telegraf agent installed on
		// the customer's server, if available.
		case canServerStatsLocal:
			return (
				<Grid item xs={12} md={12}>
					<TickStackHealth uniqId={uniqId} />
				</Grid>
			);

		case canServerStatsCloud:
			return (
				<Grid item xs={12} md={12}>
					<CloudHealth uniqId={uniqId} />
				</Grid>
			);

		default:
			return (
				<Grid item xs={12} md={12}>
					<Health uniqId={uniqId} />
				</Grid>
			);
	}
};

/** @param {import('modules/queries/asset/useDetails').AssetDetails} props */
const PrivateParentOnly = (props) => (
	<>
		<Grid item xs={12} md={12}>
			<PrivateParentResources {...props} />
		</Grid>
		<Grid item xs={12} md={12}>
			<PrivateParentChildren {...props} />
		</Grid>
	</>
);

const HostingDetails = ({ classes, openManageRedirectDialog }) => {
	const { uniqId } = useParams();

	const {
		data: assetDetailsData,
		isLoading: assetDetailsIsLoading,
		error: assetDetailsError,
		isError: assetDetailsIsError,
	} = usePollAssetDetails({
		alsowith,
		uniq_id: uniqId,
	});

	// The storage subNav is displayed when the server already has block
	// storage or when there is a block storage cluster with availability
	// in the zone where the server exists.

	const hasBlockStorage = assetDetailsData?.hasBlockStorage;
	const isPrivateParent = assetDetailsData?.isPrivateParent;
	const isStorm = assetDetailsData?.isStorm;
	const zone = assetDetailsData?.zone;

	const clusterListIsEnabled = Boolean(
		zone && !hasBlockStorage && isStorm && !isPrivateParent,
	);

	const {
		data: clusterListData,
		isLoading: clusterListIsLoadingRaw,
	} = useAllStorageBlockClusterList({
		enabled: clusterListIsEnabled,
	});

	const clusterListIsLoading = clusterListIsEnabled && clusterListIsLoadingRaw;

	if (assetDetailsIsError && assetDetailsError.statusCode === 404) {
		return <NotFound />;
	}

	if (assetDetailsIsError) {
		return (
			<FullScreenError content={getErrorStringHelper(assetDetailsIsError)} />
		);
	}

	if (assetDetailsIsLoading || clusterListIsLoading) {
		return (
			<Box display="flex" justifyContent="center" alignItems="center" p={4}>
				<CircularProgress size={64} />
			</Box>
		);
	}

	const clusterListItems = clusterListData?.items;

	const {
		capabilities,
		categories,
		domain,
		project_id: projectId,
		assetType,
		isBeyondHosting,
		isOpenStack,
		isMWPv1,
		isVmware,
		displayType,
	} = assetDetailsData;
	const { serverStatsCloud, serverStatsLocal } = capabilities;
	const canServerStatsCloud = fromApiBoolean(serverStatsCloud);
	const canServerStatsLocal = fromApiBoolean(serverStatsLocal);
	const displayServerType = assetType === 'open stack' ? 'cloud' : assetType;
	const backupsPageTitle =
		assetType === 'dedicated' ? 'Backups' : 'Backups & Images';

	const parent = [
		{
			name: 'Servers',
			// Per Ken, Servers is a "category", not a page or link
		},
		{
			name: 'Servers List',
			path: '/servers/',
		},
		{
			name: domain,
			currentPage: true,
		},
	];

	const subNav = [
		{
			name: 'Activity Log',
			path: `${uniqId}/activity`,
		},
	];

	if (!isOpenStack && !isBeyondHosting && !isVmware) {
		subNav.push({
			name: backupsPageTitle,
			breadcrumbHidden: categories.includes('BeyondHostingVPS'),
			path: `${uniqId}/backups`,
		});
	}

	if (isMWPv1) {
		subNav.push({
			name: 'Manage Sites',
			onClick: (e) =>
				openManageRedirectDialog(
					e,
					`/manage/server/${uniqId}.html#wordpressDomains`,
				),
		});
	}

	const isAbleToScheduleTasks = !!scheduledTaskCapabilities.find(
		(item) => capabilities[item],
	);

	if (isAbleToScheduleTasks) {
		subNav.push({
			name: 'Scheduled Tasks',
			path: `${uniqId}/scheduled-tasks`,
		});
	}

	const couldBlockStorage =
		isStorm &&
		!isPrivateParent &&
		clusterListItems?.some((cluster) =>
			cluster.zoneAvailability.some(
				(clusterZoneID) => clusterZoneID === zone?.id,
			),
		);
	if (hasBlockStorage || couldBlockStorage) {
		subNav.push({
			name: 'Storage',
			path: `${uniqId}/storage`,
		});
	}

	const row = classes.infoWidgets;
	const name = `Server Dashboard - ${upperFirst(displayType)}`;
	// Having the hostname missing from the title already makes navigating using the browser history a challenge.
	// Want to at least make sure the favorites are still usable.
	const favoritesDisplayName = `Server Dashboard - ${domain}`;

	return (
		<PageOverview
			parent={parent}
			name={name}
			subNavData={subNav}
			favoritesDisplayName={favoritesDisplayName}
		>
			<Grid container direction="column">
				<Grid className={row} container spacing={3}>
					<Grid item xs={12} md={12}>
						<ServerDetails {...assetDetailsData} />
					</Grid>
					{projectId && (
						<Grid item xs={12} md={12}>
							<Project {...assetDetailsData} />
						</Grid>
					)}
				</Grid>
				<Grid className={row} alignItems="stretch" container spacing={3}>
					{!isPrivateParent && !isVmware && (
						<>
							<Grid item xs={12} md={4}>
								<Networking {...assetDetailsData} />
							</Grid>
							<Grid item xs={12} md={4}>
								<Backups {...assetDetailsData} />
							</Grid>
							<Grid item xs={12} md={4}>
								<Services {...assetDetailsData} />
							</Grid>
						</>
					)}
					{!isPrivateParent && (
						<Graphs
							canServerStatsCloud={canServerStatsCloud}
							canServerStatsLocal={canServerStatsLocal}
							uniqId={uniqId}
						/>
					)}
					{isPrivateParent && <PrivateParentOnly {...assetDetailsData} />}
				</Grid>
			</Grid>
			<NavTracker
				displayName={
					domain
						? `${upperFirst(displayServerType)} Hosting for ${domain}`
						: null
				}
				assetId={uniqId}
				isAssetDomain={false}
			/>
		</PageOverview>
	);
};

HostingDetails.propTypes = {
	classes: PropTypes.object.isRequired,
	openManageRedirectDialog: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
	openManageRedirectDialog: (e, url) => {
		e.preventDefault();
		dispatch(manageRedirectActions.setOpen(url));
	},
});

export { HostingDetails, alsowith };

export default compose(
	withStyles(styles),
	connect(
		undefined,
		mapDispatchToProps,
	),
)(HostingDetails);

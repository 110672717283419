import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';

const getStateSlice = (state) => state.api.network.loadbalancer.strategies;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_LOADBALANCER_STRATEGIES',
	url: '/network/loadbalancer/strategies.json',
});

const selectors = {
	getStrategies: createSelector(
		defaultSelectors.getNativeData,
		(data) => data?.strategies,
	),
	strategies: createSelector(
		defaultSelectors.getStateSlice,
		(slice) => slice.getIn(['data', 'strategies'], ImmutableList()),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };

import { createSelector } from 'reselect';
import { createAPIModule } from 'utility/redux/apiModuleHelpers';

/**
 * @typedef AssignableIp
 * @property {string | null} current_ptr
 * @property {string} domain
 * @property {string} ip
 * @property {string} uniq_id
 * */

const getStateSlice = (state) => state.api.network.dns.reverse.assignableIps;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
	additionalSagas,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_DNS_REVERSE_ASSIGNABLEIPS',
	url: '/network/dns/reverse/assignableIps.json',
});

const getDomainsToAssignableIps = createSelector(
	defaultSelectors.getNativeItems,
	/** @param {AssignableIp[]} items */
	(items) => {
		/** @type {Record<string, AssignableIp[]>} */
		const domainsToAssignableIps = {};

		items.forEach((assignableIp) => {
			if (domainsToAssignableIps[assignableIp.domain]) {
				domainsToAssignableIps[assignableIp.domain].push(assignableIp);
			} else {
				domainsToAssignableIps[assignableIp.domain] = [assignableIp];
			}
		});

		return domainsToAssignableIps;
	},
);

const getDomains = createSelector(
	getDomainsToAssignableIps,
	(domainsToAssignableIps) => Object.keys(domainsToAssignableIps),
);

const selectors = {
	...defaultSelectors,
	getDomains,
	getDomainsToAssignableIps,
};

export {
	actions as dnsReverseAssignableIpsActions,
	selectors as dnsReverseAssignableIpsSelectors,
	reducer,
	sagas,
	additionalSagas as dnsReverseAssignableIpsAdditionalSagas,
};

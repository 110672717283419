import { useEffect, useMemo } from 'react';
import toString from 'lodash/toString';
import { useDispatch } from 'react-redux';
import { useProductDataContext } from 'banana-stand/productConfig';
import packageConfigActions from 'modules/basket/packageConfig/actions';
import usePackageInCart from './usePackageInCart';

const useInitialProduct = () => {
	const dispatch = useDispatch();
	const {
		defaultRegionId,
		defaultSelectedConfigs,
		defaultZoneId,
		packageProductId,
	} = useProductDataContext();
	const packageInCart = usePackageInCart();

	const { configsInCart, propertiesInCart, regionIdInCart } = useMemo(() => {
		const currentProduct = packageInCart?.details?.products?.find(
			(product) =>
				toString(product.package_to_product_id) === toString(packageProductId),
		);

		if (!currentProduct) {
			return {};
		}

		const configs = {};
		currentProduct.options.forEach((option) => {
			const config = {
				active: true,
				value: option.ptov_id,
			};

			if (typeof option.num_units === 'number') {
				config.numUnits = option.num_units;
			}

			configs[option.ptok_id] = config;
		});

		return {
			configsInCart: configs,
			propertiesInCart: currentProduct.properties,
			regionIdInCart: currentProduct.region_id,
		};
	}, [packageInCart, packageProductId]);

	const initRegion = regionIdInCart || defaultRegionId;
	const initConfigs = configsInCart || defaultSelectedConfigs;

	useEffect(() => {
		dispatch(
			packageConfigActions.setSelectedRegion({
				selectedRegion: initRegion,
				packageProductId,
			}),
		);
	}, [dispatch, initRegion, packageProductId]);

	useEffect(() => {
		dispatch(
			packageConfigActions.setSelectedConfigs({
				selectedConfigs: initConfigs,
				packageProductId,
			}),
		);

		if (propertiesInCart) {
			dispatch(
				packageConfigActions.setProperties({
					packageProductId,
					properties: propertiesInCart,
				}),
			);
			// init with a zone if it needs to
		} else if (defaultZoneId) {
			dispatch(
				packageConfigActions.setProperties({
					packageProductId,
					properties: { zone: defaultZoneId },
				}),
			);
		}
	}, [
		defaultZoneId,
		dispatch,
		initConfigs,
		packageProductId,
		propertiesInCart,
	]);
};

export default useInitialProduct;

import { put, takeLatest, take, call, select } from 'redux-saga/effects';
import snackbarSaga from 'modules/snackbar/sagas';
import snackbarActions from 'modules/snackbar/snackbarActions';
import { push } from 'connected-react-router';
import { actions as assetRemoveActions } from 'modules/api/asset/removeModule';
import { actions as ssoActions } from 'modules/api/acronis/backup/ssoModule';
import { actions as updateActions } from 'modules/api/asset/updateModule';
import backupsSettingsActions from 'modules/server/backupsAndImages/settings/actions';
import { selectors as assetDetailsSelectors } from 'modules/api/asset/detailsModule';
import errorActions from 'modules/error/errorActions';
import {
	actions as assetListActions,
	moduleKeys,
} from 'modules/api/asset/listModule';
import { acronisProductCodes } from 'utility/acronisProductMap';
import acronisActions from './actions';
import acronisSelectors from './selectors';

function* fetchList() {
	const pageSize = yield select(acronisSelectors.getPageSize);
	const pageNum = yield select(acronisSelectors.getPageNum);
	yield put(
		assetListActions.fetch(
			{
				type: acronisProductCodes,
				alsowith: ['acronisBackingUp'],
				page_size: pageSize,
				page_num: pageNum,
			},
			moduleKeys.ACRONIS,
		),
	);
}

function* handleUpdate({ features }) {
	yield put(acronisActions.setError(false));
	const acronisId = yield select(assetDetailsSelectors.getAcronisId);
	yield put(updateActions.fetch({ uniq_id: acronisId, features }));
	const updateResult = yield take([
		updateActions.setType,
		updateActions.errorType,
	]);
	const error = updateResult.type === updateActions.errorType;
	yield call(snackbarSaga, {
		error,
		successMessage: 'Successfully updated Acronis plan',
		errorMessage: 'Failed to update Acronis plan',
	});
	if (!error) {
		yield put(acronisActions.toggleLoading(false));
		yield put(backupsSettingsActions.initChangeAcronis({ uniqId: acronisId }));
	} else {
		yield put(acronisActions.setError('Failed to update Acronis plan'));
	}
}

function* handleCancel({ serverId, uniqId }) {
	yield put(acronisActions.setError(false));

	yield put(assetRemoveActions.fetch({ uniq_id: uniqId }));

	const removeResult = yield take([
		assetRemoveActions.setType,
		assetRemoveActions.errorType,
	]);
	if (removeResult.type === assetRemoveActions.setType) {
		yield put(acronisActions.toggleDialog(false));
		yield put(
			snackbarActions.pushMessage({
				message: 'Acronis cancelled',
				variant: 'success',
			}),
		);
		if (serverId) {
			yield put(push(`/servers/details/${serverId}`));
		} else {
			yield put(push('/services/acronis'));
		}
	} else {
		yield put(acronisActions.setError('Failed to cancel Acronis'));
		yield put(
			snackbarActions.pushMessage({
				message: 'Failed to cancel Acronis',
				variant: 'error',
			}),
		);
	}
}

async function ssoAjax(ajax) {
	return fetch(ajax, { mode: 'no-cors' }).catch(() => {
		// TODO: Use the snackbarSaga so that is goes through gator (src/modules/reactGator/sagas.js)
		throw new Error(
			'SSO failed. Please try again and if the error persists contact support.',
		);
	});
}

function* handleGetSSO({ uniqId }) {
	yield put(
		ssoActions.fetch({
			uniq_id: uniqId,
		}),
	);
	const ssoResult = yield take([ssoActions.setType, ssoActions.errorType]);
	if (ssoResult.type === ssoActions.setType) {
		const {
			payload: { ajax: ajaxUrl, window: windowUrl },
		} = ssoResult;
		try {
			yield call(ssoAjax, ajaxUrl);
			window.open(windowUrl, '_blank'); // TODO: call? Does this even do anything
		} catch (error) {
			errorActions.pushMessage(
				'SSO failed. Please try again and if the error persists contact support.',
			);
		}
	} else if (ssoResult.type === ssoActions.errorType) {
		errorActions.pushMessage(
			'SSO failed. Please try again and if the error persists contact support.',
		);
	}
}

export default function* rootSaga() {
	yield takeLatest(acronisActions.SUBMIT_CANCEL, handleCancel);
	yield takeLatest(acronisActions.SUBMIT_UPDATE, handleUpdate);
	yield takeLatest(acronisActions.GET_SSO, handleGetSSO);
	yield takeLatest(
		[
			acronisActions.ACRONIS_FETCH_LIST,
			acronisActions.ACRONIS_SET_PAGE_NUM,
			acronisActions.ACRONIS_SET_PAGE_SIZE,
		],
		fetchList,
	);
}

import { useState, useEffect } from 'react';
import { testing } from 'utility/tools/storyOrTest';

/**
 * @template {unknown} T
 * @param {T} value
 * @param {number} delay Schedules execution of a one-time callback after delay milliseconds.
 * @see https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
 */
export default function useDebounce(value, delay) {
	const [debouncedValue, setDebouncedValue] = useState(value);
	useEffect(() => {
		const handlerTimeoutId = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handlerTimeoutId);
		};
	}, [value, delay]);

	if (testing) return value;
	return debouncedValue;
}

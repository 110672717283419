import {
	createAPIModule,
	createSelectors,
} from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.api.salesforce.article.list;

const moduleKeys = {
	ARTICLE_SEARCH: 'articleSearch',
};

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'SALESFORCE_ARTICLE_LIST',
	method: 'POST',
	url: '/salesforce/article/list.json',
});

const searchSelectors = createSelectors(
	getStateSlice,
	false,
	moduleKeys.ARTICLE_SEARCH,
);

const getArticles = createSelector(
	defaultSelectors.getData,
	(data) => data && data.get('items'),
);

const searchResults = createSelector(
	searchSelectors.getData,
	(data) => data && data.get('items'),
);

const selectors = {
	getArticles,
	searchResults,
	searchSelectors,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors, moduleKeys };

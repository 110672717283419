// @ts-check

const headerKeys = {
	status: { key: 'status', display: 'Status' },
	name: { key: 'name', display: 'Name' },
	ip: { key: 'ip', display: 'Ip' },
	description: { key: 'description', display: 'Description' },
};

export const getTableHeaders = () =>
	Object.entries(headerKeys).map(([key, { display }]) => ({
		key,
		value: display,
		contentProps: { variant: 'subtitle2', bold: true },
	}));

/** @param {import("modules/queries/asset/useDetails").AssetDetails['children']} children */
const getTableData = (children) => {
	return children?.map(
		({
			domain: name,
			ip,
			status,
			config_description: description,
			uniq_id: uniqId,
		}) => ({
			[headerKeys.status.key]: {
				contentKey: 'AssetStatus',
				contentProps: {
					uniqId,
					subaccntStatus: status,
				},
			},
			[headerKeys.name.key]: {
				value: name,
				contentKey: 'LWLink',
				contentProps: { variant: 'body2', to: `/servers/cloud/${uniqId}` },
			},
			[headerKeys.ip.key]: {
				value: ip,
				contentProps: { variant: 'body2' },
			},
			[headerKeys.description.key]: {
				value: description,
				contentProps: { variant: 'body2' },
			},
			key: { value: uniqId, display: null },
		}),
	);
};

export default getTableData;

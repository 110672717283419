/**
 * Enforce unique entries
 * @template T
 * @param {Iterable<T>} list
 * @returns {T[]}
 * @see https://liquidweb.atlassian.net/browse/NEWMAN-2687?focusedCommentId=563425
 */
function uniqueList(list) {
	return [...new Set(list)];
}

/**
 * Unique, filter falsy, then sort asc
 * @param {number[]} list
 * @returns {number[]}
 */
function sortedSet(list) {
	return uniqueList(list)
		.filter(Boolean)
		.sort((a, b) => a - b);
}

export { uniqueList, sortedSet };

// @ts-check
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useSnackbarError from 'utility/hooks/useSnackbarError';
import snackbarActions from 'modules/snackbar/snackbarActions';
import api from 'modules/queries/api';

const useCreateLoadBalancer = () => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const snackbarError = useSnackbarError();

	return useMutation({
		mutationFn:
			/** @param {import('club-sauce/public/network/loadbalancer/index.raw').LWApiPublicNetworkLoadBalancerCreateParamsI} params */
			(params) => api.network.loadbalancer.apiRaw.create(params),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['/asset/list'],
			});

			dispatch(
				// @ts-ignore
				snackbarActions.pushMessage({
					variant: 'success',
					message: 'Successfully created load balancer',
				}),
			);
		},
		onError: snackbarError,
	});
};

export default useCreateLoadBalancer;

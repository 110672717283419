import { createSelector } from 'reselect';

const getStateSlice = (state) => state.serviceWorker;

const getRegistration = createSelector(
	getStateSlice,
	(slice) => slice.registration,
);

const getUpdateIsAvailable = createSelector(
	getRegistration,
	(registration) => Boolean(registration),
);

const serviceWorkerSelectors = {
	getRegistration,
	getStateSlice,
	getUpdateIsAvailable,
};

export default serviceWorkerSelectors;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// api
import { selectors as zoneDetailsSelectors } from 'modules/api/network/dns/zone/detailsModule';
import { selectors as bindSelectors } from 'modules/api/network/dns/zone/formatAsBindModule';

// redux
import { actions as dialogActions } from 'modules/dialogs';
import { actions as dnsActions } from 'modules/dns';

// MUI components
import Grid from '@material-ui/core/Grid';

// custom components
import LWTypography from 'components/common/LWTypography';
import LWButton from 'components/common/LWButton';

const DeleteDNSZoneDialogContents1 = () => {
	const dispatch = useDispatch();
	const name = useSelector(zoneDetailsSelectors.getName);
	const possibly = useSelector(
		zoneDetailsSelectors.getIsDelegationStatusNotCorrect,
	)
		? 'possibly '
		: '';
	const isDownloading = useSelector(bindSelectors.isLoading);

	const close = () => {
		dispatch(dialogActions.close());
	};
	const downloadZoneData = () => {
		dispatch(dnsActions.downloadZoneData({ name }));
	};
	const proceed = () => {
		dispatch(dnsActions.launchConfirmDeleteZoneModal());
	};

	return (
		<Grid container spacing={4}>
			<Grid container item spacing={3}>
				<Grid item>
					<LWTypography>
						When deleting a DNS zone,{' '}
						<b>records and record history will be permanently removed</b>,{' '}
						{possibly}creating domain errors until a new DNS zone is added.
					</LWTypography>
				</Grid>
				<Grid item>
					<LWTypography>
						If a copy of the DNS zone is needed before deleting,{' '}
						<b>consider exporting the DNS zone first</b> before deleting from
						the domain.
					</LWTypography>
				</Grid>
			</Grid>
			<Grid container item spacing={2} justify="flex-end">
				<Grid item>
					<LWButton onClick={close} variant="subtle">
						Cancel
					</LWButton>
				</Grid>
				<Grid item>
					<LWButton onClick={downloadZoneData} isLoading={isDownloading}>
						Export DNS
					</LWButton>
				</Grid>
				<Grid item>
					<LWButton onClick={proceed} variant="strong">
						Continue With Delete
					</LWButton>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default DeleteDNSZoneDialogContents1;

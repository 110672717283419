import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.market.cart.order;

const { actions, reducer, sagas, selectors, additionalSagas } = createAPIModule(
	{
		getStateSlice,
		actionType: 'MARKET_CART_ORDER',
		url: '/market/cart/order.json',
	},
);

export {
	actions as cartOrderActions,
	reducer as cartOrderReducer,
	sagas as cartOrderSagas,
	selectors as cartOrderSelectors,
	additionalSagas as cartOrderAdditionalSagas,
};

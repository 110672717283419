import styled from 'styled-components';
import LWButton from 'components/common/LWButton';

const SLWButton = styled(LWButton)`
	min-width: 150px;
	margin: ${({ theme }) => theme.spacing(0.25, 1)};
`;

// eslint-disable-next-line import/prefer-default-export
export { SLWButton };

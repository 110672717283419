import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.account.ssh.key.remove;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'ACCOUNT_SSH_KEY_REMOVE',
	method: 'POST',
	url: 'account/ssh/key/remove.json',
});

export { actions, reducer, sagas, selectors };

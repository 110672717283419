import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import LWTypography from 'components/common/LWTypography';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';
import dialogActions from 'modules/dialogs/actions';

const DetourDialogContent = ({
	route,
	displayRoute = null,
	buttonsVisible = true,
}) => {
	const dispatch = useDispatch();
	const closeDialog = () => dispatch(dialogActions.close());
	return (
		<Grid container direction="column" spacing={2}>
			<Grid item xs={12}>
				<LWTypography>
					We are working on getting this feature added to My Liquid Web in the
					near future.
				</LWTypography>
			</Grid>
			{buttonsVisible && (
				<>
					<Grid item xs={12}>
						<LWTypography>
							For now, we can redirect you to{' '}
							<b>
								{displayRoute ||
									route?.substring(8, route?.indexOf('.com') + 4)}
							</b>{' '}
							where this feature exists.
						</LWTypography>
					</Grid>
					<Grid item xs={12}>
						<ConfirmCancel
							cancel={closeDialog}
							confirm={() => {
								window.open(route, '_blank', 'noopener');
								closeDialog();
							}}
							confirmText="Redirect Me"
						/>
					</Grid>
				</>
			)}
		</Grid>
	);
};

DetourDialogContent.propTypes = {
	route: PropTypes.string,
	displayRoute: PropTypes.string,
	buttonsVisible: PropTypes.bool,
};

export default DetourDialogContent;

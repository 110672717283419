import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import styled from 'styled-components';
import HeaderContext from 'utility/contexts/header';

export const styles = (theme) => ({
	root: {
		boxShadow: 'none',
		zIndex: theme.zIndex.appBar,
		backgroundColor: theme.palette.common.white,
	},
	container: {
		width: '100%',
		maxWidth: theme.maxWidth,
		margin: 'auto',
		paddingTop: '12px',
		paddingBottom: '12px',
	},
	toolbar: {
		paddingRight: 0,
		paddingLeft: 0,
		zIndex: theme.zIndex.appBar,
		height: '100%',
		justifyContent: 'space-between',
	},
});

const AppBar = styled(MuiAppBar)`
	${({ $admin, theme }) =>
		`box-shadow: ${
			$admin ? `0px 1px 4px ${theme.palette.primary.main}` : 'none'
		};
	z-index: ${theme.zIndex.appBar};
	background-color: ${theme.palette.background.default};`}
	border-radius: 0px;
	padding-left: 16px;
	padding-right: 16px;
	border-bottom: ${({ $admin, theme }) =>
		$admin ? 'none' : `1px solid ${theme.palette.divider}`};
`;

const NavAppBar = ({ classes, admin, children }) => {
	const {
		height: [, setHeaderHeight],
	} = useContext(HeaderContext);
	const ref = useRef(null);

	useEffect(() => {
		if (!ref.current) return;

		const resizeObserver = new ResizeObserver(() => {
			setHeaderHeight(ref.current.clientHeight);
		});
		resizeObserver.observe(ref.current);

		// eslint-disable-next-line consistent-return
		return () => resizeObserver.disconnect();
	}, [setHeaderHeight]);

	return (
		<AppBar position="sticky" $admin={admin} ref={ref}>
			<div className={classes.container}>
				<Toolbar className={classes.toolbar}>{children}</Toolbar>
			</div>
		</AppBar>
	);
};

NavAppBar.propTypes = {
	classes: PropTypes.object.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

export default withStyles(styles)(NavAppBar);

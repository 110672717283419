import { defaultParams, defaultData } from 'modules/gtm/defaults';
import authActions from 'modules/auth/authActions';

const identifyBeaconActions = {
	identify_login: 'identify_login',
};

const identify = {
	[authActions.AUTH_ACTIONS_GTM_IDENTIFY]: ({ payload: { idData } }) => {
		const obj = {
			...defaultParams,
			event: 'lw.identify',
			eventName: 'Identify',
			data: {
				...defaultData,
				...idData,
				action: identifyBeaconActions.identify_login,
				isNonInteraction: true,
			},
		};
		return obj;
	},
};

export { identifyBeaconActions };
export default identify;

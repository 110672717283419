import { createSelector } from 'reselect';

// api
import { selectors as availableConfigsSelectors } from 'modules/api/server/availableConfigsModule';
import { selectors as assetDetailsSelectors } from 'modules/api/asset/detailsModule';

// user input
import { deployOnto as deployOntoSelector } from 'modules/server/resize/options/selectors';

import { toInt, bytes, toGibibyte } from 'utility/format';

const getStateSlice = (state) => state.server.resize;

const filterValues = createSelector(
	assetDetailsSelectors.CPUint,
	assetDetailsSelectors.RAM,
	assetDetailsSelectors.storageSize,
	assetDetailsSelectors.diskType,
	availableConfigsSelectors.cores,
	availableConfigsSelectors.ram,
	availableConfigsSelectors.storage,
	availableConfigsSelectors.diskOptions,
	deployOntoSelector,
	(
		currentCpu,
		currentRam,
		currentStorage,
		currentDiskType,
		coreOptions,
		ramOptions,
		storageOptions,
		diskOptions,
		deployOnto,
	) => ({
		cores: {
			current: currentCpu,
			options: coreOptions,
			virtual: deployOnto === 'vps',
		},
		ram: {
			current: toGibibyte(currentRam),
			options: ramOptions,
		},
		disk: {
			current: `${bytes(
				toInt(currentStorage),
			)}${` ${currentDiskType?.toUpperCase() || ''}`}`,
			options: storageOptions,
			diskType: {
				options: diskOptions,
			},
		},
	}),
);

const totalOptions = createSelector(
	availableConfigsSelectors.optionsSelector,
	(options) => options.length || 0,
);

const selectors = {
	getStateSlice,
	filterValues,
	totalOptions,
};

export default selectors;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import LWTypography from 'components/common/LWTypography';
import CartItemExpansion from 'components/cart/CartItem/CartItemExpansion';
import VisibleToAdminsIcon from 'components/atoms/VisibleToAdminsIcon';
import LabeledIcon from 'components/atoms/LabeledIcon';

const SubtitleAndIcon = ({ subtitle, iconContentKey }) => {
	switch (iconContentKey) {
		case 'VisibleToAdminsIcon':
			return (
				<LabeledIcon
					textProps={{ variant: 'body2' }}
					text={subtitle}
					icon={<VisibleToAdminsIcon />}
					flexDirection="row-reverse"
				/>
			);
		default:
			return <LWTypography variant="body2">{subtitle}</LWTypography>;
	}
};

export const Detail = ({ title, subtitle, iconContentKey, subDetails }) => (
	<Box display="flex" flexDirection="column">
		<Box display="flex" flexWrap="wrap" marginY={0.5}>
			<LWTypography variant="subtitle2" color="palette.primary.main" bold>
				{title} :&nbsp;
			</LWTypography>
			<SubtitleAndIcon subtitle={subtitle} iconContentKey={iconContentKey} />
		</Box>
		<Box>
			{subDetails?.map((child) => (
				<ChildDetails child={child} key={`${child.title}.${child.value}`} />
			))}
		</Box>
	</Box>
);

const ChildDetails = ({ child }) => (
	<LWTypography
		variant="subtitle2"
		color="palette.text.secondary"
		BoxProps={{
			display: 'flex',
			marginLeft: 2,
			marginY: 0.5,
		}}
	>
		{`${child.title} : ${child.subtitle}`}
	</LWTypography>
);

const Details = ({
	details = [],
	customerNotes = '',
	salesRepEmail,
	variant,
}) => {
	// Admin needs outside control of this. TODO: find a better way to do this.
	const [isExpanded, setIsExpanded] = useState(false);
	const Container = variant === 'upsell' ? React.Fragment : CartItemExpansion;

	const containerProps =
		variant === 'upsell' ? {} : { isExpanded, setIsExpanded, title: 'Details' };
	return (
		<Container {...containerProps}>
			<Box display="flex" flexDirection="column">
				<Box>
					{details.map(
						({ title, subtitle, subDetails, value, iconContentKey }) => (
							<Detail
								title={title}
								subtitle={subtitle}
								subDetails={subDetails}
								iconContentKey={iconContentKey}
								key={`${title}.${value}`}
							/>
						),
					)}
				</Box>
				{customerNotes && (
					<Box mt={1}>
						<LWTypography variant="body2">
							{`Notes from ${salesRepEmail || 'your representative'}:`}
						</LWTypography>
						<LWTypography variant="body2" color="palette.text.secondary">
							{customerNotes}
						</LWTypography>
					</Box>
				)}
			</Box>
		</Container>
	);
};

Details.propTypes = {
	details: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			subtitle: PropTypes.string.isRequired,
			subDetails: PropTypes.arrayOf(
				PropTypes.shape({
					title: PropTypes.string.isRequired,
					subtitle: PropTypes.string.isRequired,
				}),
			),
		}),
	),
	customerNotes: PropTypes.string,
	salesRepEmail: PropTypes.string.isRequired,
};

export default Details;

import React from 'react';

import { ReactComponent as ScanIcon } from 'images/cartItem/scan.svg';
import { ReactComponent as MoneyPushPinsIcon } from 'images/cartItem/money-push-pins.svg';
import { ReactComponent as UptickIcon } from 'images/cartItem/uptick.svg';
import { ReactComponent as LockedBrowserIcon } from 'images/cartItem/locked-browser.svg';
import { ReactComponent as NetworkIcon } from 'images/cartItem/network.svg';

const iconMap = {
	scan: ScanIcon,
	'money-push-pins': MoneyPushPinsIcon,
	uptick: UptickIcon,
	'locked-browser': LockedBrowserIcon,
	network: NetworkIcon,
};

const HeaderIcon = ({ icon, className }) => {
	const Icon = iconMap[icon];
	return (
		<Icon className={className} data-testid={`HeaderIcon__Icon__${icon}`} />
	);
};

export default HeaderIcon;

export { iconMap };

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	actions as availableActions,
	selectors as availableSelectors,
} from 'modules/api/project/availableModule';
import { selectors } from 'modules/renameProjectDialog/selectors';
import actions from 'modules/renameProjectDialog/actions';
import EditNameDialog from './EditNameDialog';

const mapStateToProps = (state) => ({
	availableObj: {
		data: availableSelectors.getNativeData(state),
		isLoading: availableSelectors.isLoading(state),
		error: availableSelectors.getError(state),
	},
	dialogObj: {
		isOpen: selectors.isOpen(state),
		dialogProps: selectors.dialogProps(state),
	},
});

const mapDispatchToProps = (dispatch) => ({
	clearAvailable: () => dispatch(availableActions.clear()),
	fetchAvailable: (name) =>
		dispatch(availableActions.fetch({ project_name: name })),
	toggleDialog: (value) => dispatch(actions.toggleDialog(value)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(EditNameDialog));

const downloadActions = {
	UTILITY_DOWNLOAD_TEXT_FILE: 'UTILITY_DOWNLOAD_TEXT_FILE',
	downloadText: ({ data, name }) => ({
		type: downloadActions.UTILITY_DOWNLOAD_TEXT_FILE,
		data,
		name,
	}),
};

export default downloadActions;

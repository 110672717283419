import { combineReducers } from 'redux';
import { reducer as resize } from './resize';
import { reducer as reboot } from './reboot';
import { reducer as backupsAndImages } from './backupsAndImages';
import { reducer as taskScheduler } from './taskScheduler';
import { reducer as list } from './list';
import { reducer as targetPower } from './targetPower';
import { serverTemplatesReducer as templates } from './templates';

export default combineReducers({
	reboot,
	resize,
	backupsAndImages,
	taskScheduler,
	list,
	templates,
	targetPower,
});

import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.market.cart.item.remove;

const { actions, reducer, sagas, selectors, additionalSagas } = createAPIModule(
	{
		getStateSlice,
		actionType: 'MARKET_CART_ITEM_REMOVE',
		url: '/market/cart/item/remove.json',
	},
);

export {
	actions as cartItemRemoveActions,
	reducer as cartItemRemoveReducer,
	sagas as cartItemRemoveSagas,
	selectors as cartItemRemoveSelectors,
	additionalSagas as cartItemRemoveAdditionalSagas,
};

import { spawn, takeEvery, put, select, call } from 'redux-saga/effects';
import openidActions from 'modules/openid/actions';
import hitEndpoint from 'utility/redux/saga/hitEndpoint';
import { selectors as appConfigSelectors } from 'modules/appConfig';
import { sagas as authorizeSagas } from './authorizeModule';
import { actions as logoutActions, sagas as logoutSagas } from './logoutModule';

function* logoutSaga() {
	const deleteSessionCookieUrl = yield select(
		appConfigSelectors.getDeleteSessionCookieUrl,
	);

	yield call(hitEndpoint, deleteSessionCookieUrl);
	yield put(logoutActions.fetch());
}

export default function* sagas() {
	yield spawn(authorizeSagas);
	yield spawn(logoutSagas);
	yield takeEvery([openidActions.LOGOUT], logoutSaga);
}

const marketplaceActions = {
	SET_MARKETPLACE_TAGS: 'SET_MARKETPLACE_TAGS',
	/**
	 * @param {string} key
	 * @param {string[]} value
	 */
	setTags: (key, value) => ({
		type: marketplaceActions.SET_MARKETPLACE_TAGS,
		key,
		value,
	}),
	SET_MARKETPLACE_PAGE: 'SET_MARKETPLACE_PAGE',
	setPage: (newPage) => ({
		type: marketplaceActions.SET_MARKETPLACE_PAGE,
		page: newPage,
	}),
	SET_MARKETPLACE_ITEMS_PER_PAGE: 'SET_MARKETPLACE_ITEMS_PER_PAGE',
	setItemsPerPage: (newItemsPerPage) => ({
		type: marketplaceActions.SET_MARKETPLACE_ITEMS_PER_PAGE,
		itemsPerPage: newItemsPerPage,
	}),
	OPEN_SIDESHEET: 'OPEN_SIDE_SHEET',
	openSidesheet: ({
		domain,
		features,
		fullDescription,
		marketplacePrices,
		productCode,
		relatedSubaccnt,
		showConfigure,
		title,
		isAddon,
	}) => ({
		type: marketplaceActions.OPEN_SIDESHEET,
		domain,
		features,
		fullDescription,
		marketplacePrices,
		productCode,
		relatedSubaccnt,
		showConfigure,
		title,
		isAddon,
	}),
	OPEN_SIDESHEET_FOR_PRODUCT: 'OPEN_SIDESHEET_FOR_PRODUCT',
	openSidesheetForProduct: (payload) => ({
		type: marketplaceActions.OPEN_SIDESHEET_FOR_PRODUCT,
		payload,
	}),
	SET_MARKETPLACE_SELECTED_PRODUCT: 'SET_MARKETPLACE_SELECTED_PRODUCT',
	setSelectedProduct: (productCode) => ({
		type: marketplaceActions.SET_MARKETPLACE_SELECTED_PRODUCT,
		productCode,
	}),
	CLEAR_MARKETPLACE_SELECTED_PRODUCT: 'CLEAR_MARKETPLACE_SELECTED_PRODUCT',
	clearSelectedProduct: () => ({
		type: marketplaceActions.CLEAR_MARKETPLACE_SELECTED_PRODUCT,
	}),
	MARKETPLACE_CLEAR: 'MARKETPLACE_CLEAR',
	clear: () => ({
		type: marketplaceActions.MARKETPLACE_CLEAR,
	}),
};

export default marketplaceActions;

import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import dialogActions from 'modules/dialogs/actions';
import { selectors as appConfigSelectors } from 'modules/appConfig';
import manageRedirectActions from 'modules/manageRedirect/manageRedirectActions';
import { immutableToValueArray } from 'utility/immutable';

import LWLink from 'components/common/LWLink';
import NavLinkHelper from './NavLinkHelper';

const styles = (theme) => ({
	root: {},
	subNav: {
		marginTop: '.7em',
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'space-between',
		},
		'& .MuiMenuItem-root': {
			overflow: 'visible',
		},
		'& .MuiBadge-anchorOriginTopRightRectangle': {
			marginRight: -8,
		},
	},
	navLink: {
		marginRight: '3em',
		[theme.breakpoints.down('sm')]: {
			marginRight: '1em',
		},
	},
	linkTypography: {
		display: 'inline',
		fontWeight: '500',
		fontSize: '1.125rem',
		textTransform: 'uppercase',
	},
	expansionList: {
		textTransform: 'uppercase',
	},
});

const SubNav = ({
	classes,
	hosts,
	subNavData: rawSubNavData,
	openDialog,
	openManageRedirectDialog,
}) => {
	const subNavData = immutableToValueArray(rawSubNavData);
	return (
		<Grid container className={classes.subNav}>
			{subNavData.map((navLink) => {
				if (navLink.breadcrumbHidden) return null;
				const navLinkHelper = new NavLinkHelper({
					navLink,
					classes,
					hosts,
					openDialog,
					openManageRedirectDialog,
				});

				return (
					<Grid item key={navLink.name}>
						<LWLink
							classes={{ root: classes.navLink }}
							to={navLink.path}
							onClick={navLinkHelper.onClick}
							underline="hover"
						>
							{navLinkHelper.linkContents}
						</LWLink>
					</Grid>
				);
			})}
		</Grid>
	);
};

SubNav.propTypes = {
	classes: PropTypes.object.isRequired,
	/** Data used by subnav to display links */
	subNavData: PropTypes.oneOfType([
		PropTypes.shape({ name: PropTypes.string, path: PropTypes.string }),
		ImmutablePropTypes.map,
		ImmutablePropTypes.list,
		PropTypes.arrayOf(
			PropTypes.shape({ name: PropTypes.string, path: PropTypes.string }),
		),
	]),
	openManageRedirectDialog: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	hosts: appConfigSelectors.getHosts(state),
});
const mapDispatchToProps = (dispatch) => ({
	openManageRedirectDialog: (e, url) => {
		e.preventDefault();
		dispatch(manageRedirectActions.setOpen(url));
	},
	openDialog: ({ title, content, DialogProps, contentKey, contentProps }) =>
		dispatch(
			dialogActions.open({
				title,
				content,
				DialogProps,
				contentKey,
				contentProps,
			}),
		),
	closeDialog: () => dispatch(dialogActions.close()),
});

export { SubNav };

export default compose(
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
)(SubNav);

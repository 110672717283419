import { createSelector } from 'reselect';
import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { getId } from 'utility/redux/selectorHelperFunctions/common';
import isOneIsh from 'utility/tools/isOneIsh';

const getStateSlice = (state) => state.api.storm.template.list;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
	additionalSagas,
} = createAPIModule({
	getStateSlice,
	actionType: 'STORM_TEMPLATE_LIST',
	method: 'POST',
	url: '/storm/template/list.json',
});

const getSelected = createSelector(
	defaultSelectors.getNativeItems,
	getId,
	(slice, id) => slice.find((template) => template.name === id),
);

/** gets a map of Template names to zone availablity */
const getTemplateToZoneAvailablity = createSelector(
	defaultSelectors.getNativeItems,
	/** @returns {undefined | Object.<string, ZoneAvailabilty>} */
	(templates) => {
		if (!templates?.forEach) return undefined;
		const templateToZoneAvailablity = {};
		templates.forEach((template) => {
			templateToZoneAvailablity[template.name] = {
				...template.zone_availability,
			};
		});

		return templateToZoneAvailablity;
	},
);

/** creates consistency with how data is returned in the `extra_data` of `basket/details` */
const convertSupportLevelString = (supportLevel) => {
	switch (supportLevel) {
		case 'core':
			return 'Core-Managed';
		case 'self':
			return 'Self-Managed';
		case 'full':
			return 'Fully-Managed';
		default:
			return supportLevel;
	}
};

const getTemplateToExtraData = createSelector(
	defaultSelectors.getNativeItems,
	(templates) => {
		if (!templates?.forEach) return undefined;
		const templateToExtraData = {};
		templates.forEach((template) => {
			const extraData = {
				support_level: convertSupportLevelString(template.manage_level),
				os: template.os,
			};

			if (isOneIsh(template.deprecated)) {
				extraData.isDeprecated = true;
			}

			templateToExtraData[template.name] = { ...extraData };
		});

		return templateToExtraData;
	},
);

const selectors = {
	getSelected,
	getTemplateToZoneAvailablity,
	getTemplateToExtraData,
	...defaultSelectors,
};

export {
	actions,
	reducer,
	sagas,
	selectors,
	additionalSagas as stormTemplateListAdditionalSagas,
};

// @ts-check
import addonCapabilityDetails from 'utility/constants/asset/addonCapabilityDetails';

/** @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetConnectionsResultRawI} [connections] */
const createActiveSecurityAddonsDetails = (connections) => {
	/** @type {Array<import('utility/constants/asset/addonCapabilityDetails').AddonCapabilityT & {status?: string, uniqId?: string}>} */
	const activeAddons = [];
	const details = connections?.details;
	if (!details) return activeAddons;

	Object.entries(details).forEach(([uniqId, { type: productCode, status }]) => {
		const addonDetails = Object.values(addonCapabilityDetails).find(
			({ productCode: localProductCode }) => localProductCode === productCode,
		);
		if (addonDetails?.type === 'security') {
			activeAddons.push({ ...addonDetails, status, uniqId });
		}
	});

	return activeAddons;
};

export default createActiveSecurityAddonsDetails;

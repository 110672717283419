const actions = {
	SERVER_LIST_SET_PAGE_SIZE: 'SERVER_LIST_SET_PAGE_SIZE',
	setPageSize: (pageSize) => ({
		type: actions.SERVER_LIST_SET_PAGE_SIZE,
		pageSize,
	}),
	SERVER_LIST_SET_PAGE_NUM: 'SERVER_LIST_SET_PAGE_NUM',
	setPageNum: (pageNum) => ({
		type: actions.SERVER_LIST_SET_PAGE_NUM,
		pageNum,
	}),
	SERVER_LIST_SET_CATEGORIES: 'SERVER_LIST_SET_CATEGORIES',
	setCategories: (categories) => ({
		type: actions.SERVER_LIST_SET_CATEGORIES,
		categories,
	}),
	SERVER_LIST_SET_SEARCH_TEXT: 'SERVER_LIST_SET_SEARCH_TEXT',
	setSearchtext: (searchText) => ({
		type: actions.SERVER_LIST_SET_SEARCH_TEXT,
		searchText,
	}),
	SERVER_LIST_SET_SORT: 'SERVER_LIST_SET_SORT',
	setSort: (sort) => ({
		type: actions.SERVER_LIST_SET_SORT,
		sort,
	}),
	SERVER_LIST_SET_PROJECT_NAME: 'SERVER_LIST_SET_PROJECT_NAME',
	setProjectName: ({ projectName }) => ({
		type: actions.SERVER_LIST_SET_PROJECT_NAME,
		projectName,
	}),
	SERVER_LIST_RESET_FILTERS: 'SERVER_LIST_RESET_FILTERS',
	resetFilters: () => ({ type: actions.SERVER_LIST_RESET_FILTERS }),
};
export default actions;

import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.network.dns.domain.transfer.details;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_DNS_DOMAIN_TRANSFER_DETAILS',
	method: 'POST',
	url: '/network/dns/domain/transfer/details.json',
});

export { actions, reducer, sagas, selectors };

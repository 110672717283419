import Immutable from 'immutable';
import {
	all,
	call,
	cancel,
	delay,
	fork,
	put,
	select,
	take,
	takeEvery,
	takeLatest,
	actionChannel,
} from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { selectors as routeSelectors } from 'modules/route';
import snackbarSaga from 'modules/snackbar/sagas';
import { selectors as appConfigSelectors } from 'modules/appConfig';
import testUtilActions from 'testUtils/actions';
import { isKeroOnly as getIsKeroOnly } from 'modules/auth/authSelectors';
import axiosClientSaga from './saga/axiosClientSaga';
import { getErrorStringHelper } from './selectorHelperFunctions/apiModules';

/** api namespaces for which kero only users need to act as remote-signup users */
const keroOnlyAsRemoteSignupPaths = [
	'/tags',
	'/product',
	'/market',
	'/network',
	'/storm',
];

const POLL_SECONDS = 30;

const createAPIActions = (actionType, prefix) => {
	const apiActions = {
		actionType,
		fetchRawType: `${prefix}/${actionType}_FETCH_RAW`,
		/**
		 * @param {any} payload
		 * @param {string} moduleKey
		 * @param {boolean} isBackgroundPolling
		 * @param {object} [options]
		 * @param {boolean} [options.setAllRes]
		 * @param {boolean} [options.noop]
		 * @param {({data: any, error: any}) => {}} [options.onFinally]
		 * @param {(error: any) => {}} [options.onError]
		 * @param {(data: any) => {}} [options.onSuccess]
		 */
		fetchRaw: (payload, moduleKey, isBackgroundPolling, options = {}) => ({
			type: apiActions.fetchRawType,
			moduleKey,
			payload,
			isBackgroundPolling,
			...options,
		}),
		fetchType: `${prefix}/${actionType}_FETCH`,
		/**
		 * @param {any} payload
		 * @param {string} moduleKey
		 * @param {object} [options]
		 * @param {boolean} [options.setAllRes] if true, all responses will be set into state in the order that they return. Default behavior is to only set the response from the last call made into state
		 * @param {({data: any, error: any}) => {}} [options.onFinally] callback function to be called when api call returns
		 * @param {(error: any) => {}} [options.onError] callback function to be called if api call returns an error
		 * @param {(data: any) => {}} [options.onSuccess] callback function to be called if api call returns successfully
		 */
		fetch: (payload, moduleKey, options = {}) => ({
			type: apiActions.fetchType,
			moduleKey,
			payload,
			...options,
		}),
		fetchAndPollType: `${prefix}/${actionType}_FETCH_AND_POLL`,
		fetchAndPoll: (payload, moduleKey, { init } = {}) => ({
			type: apiActions.fetchAndPollType,
			moduleKey,
			payload,
			init,
		}),
		initType: `${prefix}/${actionType}_INIT`,
		init: (payload, moduleKey) => ({
			type: apiActions.initType,
			moduleKey,
			payload,
		}),
		cancelPollingType: `${prefix}/${actionType}_CANCEL_POLLING`,
		cancelPolling: (payload, moduleKey) => ({
			type: apiActions.cancelPollingType,
			moduleKey,
			payload,
		}),
		overrideType: `${prefix}/${actionType}_OVERRIDE`,
		override: (payload, moduleKey) => ({
			type: apiActions.overrideType,
			moduleKey,
			payload,
		}),
		setType: `${prefix}/${actionType}_SET`,
		/**
		 * Overwrites whatever is in the `data` key
		 * @param {object} payload data to save
		 * @param {string} moduleKey nest the data in this key
		 */
		set: (payload, moduleKey) => ({
			type: apiActions.setType,
			moduleKey,
			payload,
		}),
		setPendingType: `${prefix}/${actionType}_SET_PENDING`,
		/**
		 * Sets the pending status of an api module directly.
		 * @param {number} pending
		 */
		setPending: (pending, moduleKey) => ({
			type: apiActions.setPendingType,
			moduleKey,
			pending,
		}),
		incrementCallNumType: `${prefix}/${actionType}_INCREMENT_CALL_NUM`,
		incrementCallNum: (moduleKey) => ({
			type: apiActions.incrementCallNumType,
			moduleKey,
		}),
		resolvePendingType: `${prefix}/${actionType}_RESOLVE_PENDING`,
		resolvePending: (moduleKey) => ({
			type: apiActions.resolvePendingType,
			moduleKey,
		}),
		errorType: `${prefix}/${actionType}_ERROR`,
		error: (payload, moduleKey) => ({
			type: apiActions.errorType,
			moduleKey,
			payload,
		}),
		clearType: `${prefix}/${actionType}_CLEAR`,
		clear: (moduleKey) => ({
			type: apiActions.clearType,
			moduleKey,
		}),
		fetchGuestAuthType: `${prefix}/${actionType}_FETCH_GUEST_AUTH`,
		fetchGuestAuth: (payload, moduleKey) => ({
			type: apiActions.fetchGuestAuthType,
			moduleKey,
			payload,
		}),
		invalidateType: `${prefix}/${actionType}_INVALIDATE`,
		/**
		 * Makes a fetch without affecting the module's loading state
		 * @param {any} payload
		 * @param {string} moduleKey
		 * @param {object} [options]
		 * @param {boolean} [options.setAllRes] if true, all responses will be set into state in the order that they return. Default behavior is to only set the response from the last call made into state
		 * @param {({data: any, error: any}) => {}} [options.onFinally] callback function to be called when api call returns
		 * @param {(error: any) => {}} [options.onError] callback function to be called if api call returns an error
		 * @param {(data: any) => {}} [options.onSuccess] callback function to be called if api call returns successfully
		 * */
		invalidate: (payload, moduleKey, options = {}) => ({
			type: apiActions.invalidateType,
			moduleKey,
			payload,
			...options,
		}),
	};
	return apiActions;
};

/**
 * @typedef {Object} ApiModuleStateSlice
 * @property {Object} [data] data returned by an api endpoint - converted to an ImmutableMap
 * @property {number} [pending] the number of calls to this API that are pending
 * @property {number} [callNum] the number of calls to this API that have been made since the app was initialized
 * @property {Object} [error] whatever error data was returned by the API for non 200 status codes
 * @property {boolean} [isBackgroundPolling] whether or not this is polling in background on a regular interval
 */

const createAPIReducer = (actions) => {
	const initialState = {
		data: null,
		pending: 0,
		callNum: 0,
		error: null,
		isBackgroundPolling: false,
	};

	/** @returns {ApiModuleStateSlice} */
	return (state = initialState, action) => {
		switch (action.type) {
			case actions.overrideType: {
				const data = action.payload;

				if (action.moduleKey) {
					if (!(action.moduleKey in state)) {
						return {
							...state,
							[action.moduleKey]: {
								...initialState,
								data,
							},
						};
					}

					return {
						...state,
						[action.moduleKey]: {
							...state[action.moduleKey],
							data,
						},
					};
				}

				return { ...state, data };
			}
			case actions.fetchRawType: {
				const error = null;
				const { isBackgroundPolling } = action;

				if (action.moduleKey) {
					if (!(action.moduleKey in state)) {
						return {
							...state,
							[action.moduleKey]: {
								...initialState,
								pending: initialState.pending + 1,
								error,
								isBackgroundPolling,
							},
						};
					}

					return {
						...state,
						[action.moduleKey]: {
							...state[action.moduleKey],
							pending: state[action.moduleKey].pending + 1,
							error,
							isBackgroundPolling,
						},
					};
				}

				return {
					...state,
					pending: state.pending + 1,
					error,
					isBackgroundPolling,
				};
			}
			case actions.setType: {
				const data = action.payload;
				const error = null;

				if (action.moduleKey) {
					if (!(action.moduleKey in state)) {
						return {
							...state,
							[action.moduleKey]: {
								...initialState,
								data,
								pending: Math.max(initialState.pending - 1, 0),
								error,
							},
						};
					}

					return {
						...state,
						[action.moduleKey]: {
							...state[action.moduleKey],
							data,
							pending: Math.max(state[action.moduleKey].pending - 1, 0),
							error,
						},
					};
				}

				return {
					...state,
					data,
					pending: Math.max(state.pending - 1, 0),
					error,
				};
			}
			case actions.incrementCallNumType:
				if (action.moduleKey) {
					if (!(action.moduleKey in state)) {
						return {
							...state,
							[action.moduleKey]: {
								...initialState,
								callNum: initialState.callNum + 1,
							},
						};
					}

					return {
						...state,
						[action.moduleKey]: {
							...state[action.moduleKey],
							callNum: state[action.moduleKey].callNum + 1,
						},
					};
				}

				return { ...state, callNum: state.callNum + 1 };
			case actions.resolvePendingType:
				if (action.moduleKey) {
					if (!(action.moduleKey in state)) {
						return {
							...state,
							[action.moduleKey]: {
								...initialState,
								pending: Math.max(initialState.pending - 1, 0),
							},
						};
					}

					return {
						...state,
						[action.moduleKey]: {
							...state[action.moduleKey],
							pending: Math.max(state[action.moduleKey].pending - 1, 0),
						},
					};
				}

				return { ...state, pending: Math.max(state.pending - 1, 0) };
			case actions.errorType: {
				const data = null;
				const error = action.payload;

				if (action.moduleKey) {
					if (!(action.moduleKey in state)) {
						return {
							...state,
							[action.moduleKey]: {
								...initialState,
								pending: Math.max(initialState.pending - 1, 0),
								data,
								error,
							},
						};
					}

					return {
						...state,
						[action.moduleKey]: {
							...state[action.moduleKey],
							pending: Math.max(state[action.moduleKey].pending - 1, 0),
							data,
							error,
						},
					};
				}

				return {
					...state,
					pending: Math.max(state.pending - 1, 0),
					data,
					error,
				};
			}
			case actions.clearType: {
				if (action.moduleKey) {
					return {
						...state,
						[action.moduleKey]: initialState,
					};
				}

				return initialState;
			}
			case actions.setPendingType: {
				const { pending } = action;
				if (action.moduleKey) {
					if (!(action.moduleKey in state)) {
						return {
							...state,
							[action.moduleKey]: {
								...initialState,
								pending,
							},
						};
					}

					return {
						...state,
						[action.moduleKey]: {
							...state[action.moduleKey],
							pending,
						},
					};
				}

				return {
					...state,
					pending,
				};
			}
			case testUtilActions.TEST_UTIL_CLEAR_STATE:
				return initialState;
			default:
				return state;
		}
	};
};

/**
 * @typedef {Object} CreateSagasArgs
 * @property {Object} actions the module's actions
 * @property {string} url the url string for the endpoint; for example: `"/basket/details.json"`
 * @property {(state) => StateSlice} getStateSlice the root selector for the module
 * @property {(axiosData) => Object} [client]
 * @property {string} [method]
 * @property {Object} [extraHeaders]
 * @property {boolean} [isPaginated]
 * @property {(state) => Boolean} [getIsBlacklisted] a selector that determines whether the user should interact with this api module
 */

/** Not to be called directly by api modules! You probably want the `fetch` action or, in some cases, you may want the `fetchDirectly` saga
 * @param {CreateSagasArgs} createSagasArgs
 * @param {Object} action
 * @param {Object} [action.payload]
 * @param {string} [action.moduleKey]
 * @param {boolean} [action.setAllRes] whether or not all responses should be set into state regardless of whether or not the module is pending other fetches
 * @param {boolean} [action.justReturnRes] if true, returns response regardless of whether or not the module is pending other fetches AND does not put response into state
 * @param {boolean} [action.guestAuth] ignores current auth token and makes api call with remote signup auth
 * @param {({data: any, error: any}) => void} [action.onFinally] callback function to be called when api call returns
 * @param {(error: any) => void} [action.onError] callback function to be called if api call returns an error
 * @param {(data: any) => void} [action.onSuccess] callback function to be called if api call returns successfully
 */
function* callAndHandleApiRes(
	{
		actions,
		url,
		client,
		method,
		extraHeaders,
		getStateSlice,
		getIsBlacklisted,
	},
	{
		payload,
		moduleKey,
		setAllRes,
		justReturnRes,
		guestAuth,
		onError = () => {},
		onFinally = () => {},
		onSuccess = () => {},
	},
) {
	const isBlacklisted = yield select(getIsBlacklisted);
	if (isBlacklisted && typeof isBlacklisted === 'boolean' && !guestAuth) {
		yield put(actions.clear(moduleKey));
		return {};
	}

	if (!justReturnRes) {
		yield put(actions.incrementCallNum(moduleKey));
	}

	const beforeCallNum = moduleKey
		? (yield select(getStateSlice))?.[moduleKey]?.callNum
		: (yield select(getStateSlice))?.callNum;

	const isKeroOnly = yield select(getIsKeroOnly);
	const keroAsGuestAuth =
		isKeroOnly &&
		keroOnlyAsRemoteSignupPaths.some((path) => url.startsWith(path));

	const { data, error: rawError } = yield call(client, {
		method,
		url,
		data: payload.data,
		headers: {
			...extraHeaders,
			...payload.headers,
		},
		auth:
			guestAuth || keroAsGuestAuth
				? yield select(appConfigSelectors.getGuestCredentials)
				: payload.auth,
	});

	onFinally({ data, error: rawError });

	if (data) {
		onSuccess(data);
	}

	const error =
		rawError?.response || rawError?.message || rawError || data?.errors;

	if (justReturnRes) {
		yield put(actions.resolvePending(moduleKey));
		return {
			data,
			error,
		};
	}

	const afterCallNum = moduleKey
		? (yield select(getStateSlice))?.[moduleKey]?.callNum
		: (yield select(getStateSlice))?.callNum;

	if (!setAllRes && beforeCallNum < afterCallNum) {
		// This means another api call came in and we defere to the new one.
		yield put(actions.resolvePending(moduleKey));
		return {};
	}

	if (error) {
		onError(error);
		yield put(actions.error(error, moduleKey));
		return { error };
	}

	yield put(actions.set(data, moduleKey));
	return { data };
}

function* localHasData({ getStateSlice, moduleKey }) {
	return Boolean(
		moduleKey
			? (yield select(getStateSlice))?.[moduleKey]?.data
			: (yield select(getStateSlice))?.data,
	);
}

function* localIsPending({ getStateSlice, moduleKey }) {
	return Boolean(
		moduleKey
			? (yield select(getStateSlice))?.[moduleKey]?.pending
			: (yield select(getStateSlice))?.pending,
	);
}

/**
 * creates sagas that can be called directly in other sagas
 * @param {CreateSagasArgs} createSagasArgs
 */
const createAdditionalSagas = ({
	actions,
	url,
	client,
	method,
	extraHeaders,
	getStateSlice,
	getIsBlacklisted,
}) => {
	/**
	 * A saga that peforms the same thing as dispatching a `fetch` action but in addition returns the axios data / error response. Useful if you need to know when a particular API call has returned
	 * @param {Object} payload
	 * @param {string} [moduleKey]
	 * @param {Object} options
	 * @param {boolean} [options.setAllRes] whether or not all responses should be set into state regardless of whether or not the module is pending other fetches
	 * @param {boolean} [options.justReturnRes] if true, returns response regardless of whether or not the module is pending other fetches AND does not put response into state
	 * @param {boolean} [options.init] only ends up calling api if it has not yet been called during the session
	 * @param {boolean} [options.guestAuth] ignores current auth token and makes api call with remote signup auth
	 * @param {({data: any, error: any}) => void} [options.onFinally] callback function to be called when api call returns
	 * @param {(error: any) => void} [options.onError] callback function to be called if api call returns an error
	 * @param {(data: any) => void} [options.onSuccess] callback function to be called if api call returns successfully
	 */
	function* fetchDirectly(payload, moduleKey, { init, ...rest } = {}) {
		if (init) {
			if (
				(yield call(localHasData, {
					getStateSlice,
					moduleKey,
				})) ||
				(yield call(localIsPending, {
					getStateSlice,
					moduleKey,
				}))
			) {
				return {};
			}
		}

		yield put(
			actions.fetchRaw(undefined, moduleKey, undefined, { noop: true }),
		); // still want to trigger the same state updates as fetchRaw does in the reducer, but we don't want to trigger `performFetchRaw` saga

		const rawPayload = {
			data: {
				params: {
					...payload,
				},
			},
		};

		const { data, error } = yield call(
			callAndHandleApiRes,
			{
				actions,
				url,
				client,
				method,
				extraHeaders,
				getStateSlice,
				getIsBlacklisted,
			},
			{
				payload: rawPayload,
				moduleKey,
				...rest,
			},
		);

		return { data, error };
	}

	function* backgroundPoll(payload, moduleKey, pollingInterval = 30) {
		try {
			const initialPathName = yield select(routeSelectors.getFullPathName);

			// continue until navigating away from page
			while (
				initialPathName === (yield select(routeSelectors.getFullPathName))
			) {
				const { data, error: rawError } = yield call(client, {
					method,
					url,
					data: {
						params: {
							...payload,
						},
					},
					headers: {
						...extraHeaders,
						...payload.headers,
					},
					auth: payload.auth,
				});

				const error =
					rawError?.response || rawError?.message || rawError || data?.errors;

				if (error) {
					yield put(actions.error(error, moduleKey));
				}

				yield put(actions.set(data, moduleKey));

				yield delay(pollingInterval * 1000);
			}
		} catch {
			yield call(snackbarSaga, {
				error: true,
				errorMessage: 'The data on this page may no longer be up to date.',
			});
		}
	}

	return { fetchDirectly, backgroundPoll };
};

/**
 * @param {CreateSagasArgs} createSagasArgs
 */
const createAPISaga = ({
	actions,
	url,
	client,
	method,
	extraHeaders,
	getStateSlice,
	getIsBlacklisted,
}) => {
	function* performFetchRaw(action) {
		if (action.noop) {
			return;
		}

		yield call(
			callAndHandleApiRes,
			{
				actions,
				url,
				client,
				method,
				extraHeaders,
				getStateSlice,
				getIsBlacklisted,
			},
			action,
		);
	}

	function* performFetch(action) {
		const { payload = {}, moduleKey, type, ...rest } = action;

		const rawPayload = {
			data: {
				params: {
					...payload,
				},
			},
		};
		yield put(actions.fetchRaw(rawPayload, moduleKey, undefined, rest));
	}

	// This will only execute if it lacks a successful response.
	function* performInit(action) {
		if (
			!(yield call(localHasData, {
				getStateSlice,
				moduleKey: action.moduleKey,
			})) &&
			!(yield call(localIsPending, {
				getStateSlice,
				moduleKey: action.moduleKey,
			}))
		)
			yield call(performFetch, action);
	}

	function* fetchAndPollBackgroundPolling({
		payload,
		action,
		initialPathName,
	}) {
		yield delay(POLL_SECONDS * 1000);
		try {
			// continue until navigating away from page
			while (
				initialPathName === (yield select(routeSelectors.getFullPathName))
			) {
				yield put(actions.fetchRaw(payload, action.moduleKey, true));
				yield delay(POLL_SECONDS * 1000);
			}
		} catch {
			yield call(snackbarSaga, {
				error: true,
				errorMessage: 'The data on this page may no longer be up to date.',
			});
		}
	}

	function* performFetchAndPoll(action) {
		const { moduleKey, init, payload } = action;
		const initialPathName = yield select(routeSelectors.getFullPathName);
		const rawPayload = {
			data: {
				params: {
					...payload,
				},
			},
		};
		const hideLoading =
			init && (yield call(localHasData, { getStateSlice, moduleKey }));
		// Inital load is done on it's own as isBackgroundPolling is false
		// on the first call (this allows isLoading to be true)
		yield put(actions.fetchRaw(rawPayload, moduleKey, hideLoading));

		const backgroundPollingTask = yield fork(fetchAndPollBackgroundPolling, {
			payload: rawPayload,
			action,
			initialPathName,
		});

		yield take(actions.cancelPollingType);
		yield cancel(backgroundPollingTask);
	}

	function* performFetchGuestAuth(action) {
		let { payload = {} } = action;
		payload = {
			auth: yield select(appConfigSelectors.getGuestCredentials),
			data: {
				params: {
					...payload,
				},
			},
		};
		yield put(actions.fetchRaw(payload, action.moduleKey));
	}

	function* performInvalidate(action) {
		const { payload = {}, moduleKey, type, ...rest } = action;

		const rawPayload = {
			data: {
				params: {
					...payload,
				},
			},
		};

		yield call(
			callAndHandleApiRes,
			{
				actions,
				url,
				client,
				method,
				extraHeaders,
				getStateSlice,
				getIsBlacklisted,
			},
			{
				payload: rawPayload,
				moduleKey,
				...rest,
			},
		);
	}

	function* watchFetch() {
		yield takeEvery(actions.fetchType, performFetch);
	}

	function* watchPerformInit() {
		yield takeLatest(actions.initType, performInit);
	}

	function* watchFetchAndPoll() {
		yield takeLatest(actions.fetchAndPollType, performFetchAndPoll);
	}

	function* watchFetchGuestAuth() {
		yield takeEvery(actions.fetchGuestAuthType, performFetchGuestAuth);
	}

	function* watchFetchRaw() {
		yield takeEvery(actions.fetchRawType, performFetchRaw);
	}

	function* watchInvalidate() {
		yield takeEvery(actions.invalidateType, performInvalidate);
	}

	return function* combinedSaga() {
		return yield all([
			call(watchFetch),
			call(watchFetchAndPoll),
			call(watchFetchRaw),
			call(watchFetchGuestAuth),
			call(watchPerformInit), // We only want to call this once per session per module.
			call(watchInvalidate),
		]);
	};
};

const createSelectors = (
	getStateSlice,
	isPaginated, // TODO: make this accept an object instead of args to make code maintainable.
	moduleKey,
	customSelectors = {},
) => {
	const getNativeState = createSelector(
		getStateSlice,
		(slice) => {
			if (slice) {
				if (moduleKey) {
					return slice[moduleKey];
				}
				return slice;
			}
			return slice;
		},
	);

	const getNativeData = createSelector(
		getNativeState,
		(state) => state?.data,
	);

	const isLoading = createSelector(
		getNativeState,
		(state) => !!state && !!state.pending && !state.isBackgroundPolling,
	);

	const getPending = createSelector(
		getNativeState,
		/** @returns {Number} */
		(state) => state?.pending,
	);

	const getNativeItems = createSelector(
		getNativeData,
		(data) => data?.items || [],
	);

	const getHasItems = createSelector(
		getNativeItems,
		(items) => !!items?.length,
	);

	const getNativeError = createSelector(
		getNativeState,
		(slice) => slice?.error || {}, // TODO - this is potentially a problem
	);

	/**
	 * gets list of args sent to backend that are causing error
	 */
	const getNativeErrorDataField = createSelector(
		getNativeError,
		/**
		 * @param {object} error
		 * @returns {array}
		 */
		(error) => error?.data?.field || [],
	);

	const getErrorString = createSelector(
		getNativeError,
		(error) => getErrorStringHelper(error),
	);

	/** DEPRECATED - use `getNativeError` instead */
	const getError = createSelector(
		getNativeState,
		(state) => Immutable.fromJS(state?.error),
	);

	const hasError = createSelector(
		getNativeState,
		(state) => !!state?.error,
	);

	/** DEPRECATED - use `getNativeData` instead */
	const getData = createSelector(
		getNativeState,
		(state) => Immutable.fromJS(state?.data),
	);

	/** DEPRECATED - use `getNativeItems` instead */
	const getItems = createSelector(
		getNativeState,
		(state) => Immutable.fromJS(state?.data?.items),
	);

	const hasData = createSelector(
		getNativeState,
		(state) => !!state?.data,
	);

	const getIsIniting = createSelector(
		isLoading,
		hasData,
		(gotIsLoading, gotHasData) => gotIsLoading && !gotHasData,
	);

	/** DEPRECATED - use `getNativeState` instead */
	const getState = createSelector(
		getNativeData,
		getError,
		isLoading,
		(data, error, loading) => ({
			data,
			error: Immutable.fromJS(error),
			isLoading: loading,
		}),
	);

	// pagination
	// number of items on this page
	const getItemCount = createSelector(
		getNativeData,
		(data) => parseInt(data?.item_count, 10),
	);

	// total number of items overall
	const getTotalItems = createSelector(
		getNativeData,
		(data) => parseInt(data?.item_total, 10),
	);

	// current page number
	const getPageNum = createSelector(
		getNativeData,
		(data) => parseInt(data?.page_num, 10),
	);

	// number of items per page
	const getPageSize = createSelector(
		getNativeData,
		(data) => parseInt(data?.page_size, 10),
	);

	// total number of pages
	const getPageTotal = createSelector(
		getNativeData,
		(data) => parseInt(data?.page_total, 10),
	);

	const modifiedCustomSelectors = {};
	Object.keys(customSelectors).forEach((key) => {
		modifiedCustomSelectors[key] = createSelector(
			getNativeData,
			customSelectors[key],
		);
	});

	const deprecatedGetStateSlice = createSelector(
		getStateSlice,
		(slice) => Immutable.fromJS(slice),
	);

	return {
		getStateSlice: deprecatedGetStateSlice,
		isLoading,
		getPending,
		getError,
		getErrorString,
		hasError,
		getNativeError,
		getNativeErrorDataField,
		getData,
		getIsIniting,
		getItems,
		getHasItems,
		getNativeItems,
		hasData,
		getNativeData,
		getNativeState,
		getState,
		getItemCount,
		getTotalItems,
		getPageNum,
		getPageSize,
		getPageTotal,
		...modifiedCustomSelectors,
	};
};

/**
 * @typedef {Object} ModuleArgs
 * @property {string} actionType the module's base type constant; for example: `"BASKET_DETAILS"`
 * @property {string} url the url string for the endpoint; for example: `"/basket/details.json"`
 * @property {(state) => StateSlice} getStateSlice the root selector for the module
 * @property {(axiosData) => Object} [client]
 * @property {string} [method]
 * @property {Object} [extraHeaders]
 * @property {boolean} [isPaginated]
 * @property {boolean | string} [mocked]
 * @property {(state) => Boolean} [getIsBlacklisted] a selector that determines whether the user should interact with this api module
 */

/**
 * @param {ModuleArgs} moduleArgs
 */
const createAPIModule = ({
	actionType,
	url,
	getStateSlice,
	client = axiosClientSaga,
	method = 'POST',
	extraHeaders = {},
	isPaginated = false,
	customSelectors = {},
	prefix = 'api',
	getIsBlacklisted = () => false,
}) => {
	const actions = createAPIActions(actionType, prefix);
	return {
		actions,
		reducer: createAPIReducer(actions),
		sagas: createAPISaga({
			actions,
			url,
			client,
			method,
			extraHeaders,
			isPaginated,
			getStateSlice,
			getIsBlacklisted,
		}),
		selectors: createSelectors(
			getStateSlice,
			isPaginated,
			null,
			customSelectors,
		),
		additionalSagas: createAdditionalSagas({
			actions,
			url,
			client,
			method,
			extraHeaders,
			getStateSlice,
			getIsBlacklisted,
		}),
	};
};

/**
 * This saga takes the errorType or setType actions of an API module. Shows a snackbar if there is an error. Returns the action that was taken.
 *
 * @param {Object} param0
 * @param {Object} param0.actions - the action creator from an api module.
 * @param {Object} param0.selectors - the selectors from an api module.
 * @param {Object} param0.errorMessage - The snackbar error that responds an api failure. Defaults to `<getErrorString>`
 */
function* takeSetOrError({ actions, selectors, errorMessage }) {
	const res = (yield take([actions.setType, actions.errorType])) || {};
	const errorString = yield select(selectors.getErrorString);
	yield call(snackbarSaga, {
		error: res.type === actions.errorType,
		errorMessage: errorMessage || errorString,
	});
	return res;
}

/**
 * If the `selectors.isLoading` is true, this saga will then take the errorType or setType actions via takeSet.
 *
 * @param {Object} param0.actions - the action creator from an api module.
 * @param {Object} param0.selectors - the selectors from an api module.
 * @param {Object} param0.errorMessage - The snackbar error that responds an api failure. Defaults to `<getErrorString>`
 */
function* waitIfLoading({ actions, selectors, errorMessage }) {
	if (yield select(selectors.isLoading)) {
		yield call(takeSetOrError, { actions, selectors, errorMessage });
	}
}

/**
 * Queues action requests and processes them, one at a time, in the order received. Does not start next request until the previous one is complete. See https://redux-saga.js.org/docs/api#actionchannelpattern-buffer for more
 * @param {String} actionType The dispatched action type to track
 * @param {Function} handler Gets called for each request in the queue
 * @param {Object} [buffer] A custom buffer object. Useful to help track if the queue is empty or if you want to limit the amount of requests in the queue.
 * */
function* queueActions(actionType, handler, buffer) {
	const requestChannel = yield actionChannel(actionType, buffer);
	while (true) {
		const action = yield take(requestChannel);
		yield call(handler, action, buffer);
	}
}

export {
	createAPIActions,
	createAPIReducer,
	createAPISaga,
	createAPIModule,
	createSelectors,
	callAndHandleApiRes as callAndHandleApiResDONOTUSE,
	createAdditionalSagas,
	waitIfLoading,
	takeSetOrError,
	queueActions,
};

import React, { lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	actions as drawerActions,
	selectors as drawerSelectors,
	contentKeys,
} from 'modules/drawer';
import MuiDrawer from '@material-ui/core/Drawer';
import Loading from 'components/common/Loading';

const NotificationDrawer = lazy(() =>
	import('containers/structural/notification/NotificationDrawer'),
);
const MobileNavMenu = lazy(() =>
	import('containers/connectedOrganisms/MobileNavMenu'),
);

const getContents = (contentKey) => {
	switch (contentKey) {
		case contentKeys.NotificationDrawer:
			return NotificationDrawer;
		case contentKeys.MobileNavMenu:
			return MobileNavMenu;
		default:
			// eslint-disable-next-line no-console
			console.error(`The content Key, ${contentKey} could not be found.`);
			return null;
	}
};

const ContentFromKey = ({ contentKey, contentProps }) => {
	const Component = getContents(contentKey);
	return (
		<Suspense fallback={<Loading />}>
			<Component {...contentProps} />
		</Suspense>
	);
};

const Drawer = () => {
	const dispatch = useDispatch();
	const close = () => {
		dispatch(drawerActions.close());
	};
	const clearContents = () => {
		dispatch(drawerActions.clearContents());
	};
	const isOpen = useSelector(drawerSelectors.getOpen);
	const contentKey = useSelector(drawerSelectors.getContentKey);
	const contentProps = useSelector(drawerSelectors.getContentProps);
	return (
		<MuiDrawer
			anchor="top"
			open={isOpen}
			onClose={close}
			SlideProps={{ onExited: clearContents }}
		>
			{contentKey && (
				<ContentFromKey contentKey={contentKey} contentProps={contentProps} />
			)}
		</MuiDrawer>
	);
};

export default Drawer;

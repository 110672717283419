import { connect } from 'react-redux';
import dialogActions from 'modules/dialogs/actions';
import cartActions from 'modules/cart/actions';
import Header from './Header';

const mapDispatchToProps = (dispatch) => ({
	openDialog: ({ title, content, dialogProps, color }) =>
		dispatch(dialogActions.open({ title, content, dialogProps, color })),
	removeItem: ({ itemUuid }) => dispatch(cartActions.removeItem({ itemUuid })),
});

export default connect(mapDispatchToProps)(Header);

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import LWButton from 'components/common/LWButton';

import { useSelector } from 'react-redux';
import { selectors as routeSelectors } from 'modules/route';
import { selectors as appConfigSelectors } from 'modules/appConfig';

import { REDIRECT_PATH } from 'utility/constants/auth';

const SLWButton = styled(LWButton)`
	${({ $accountBarStyle, theme }) =>
		$accountBarStyle &&
		css`
			background-color: ${theme.palette.secondary.main};
			color: ${theme.palette.background.default};
			border: 1px solid transparent;
			padding: 3px 9px;
			font-size: 0.8125rem;
			border-radius: 4px;
			min-width: 64px;
			&:hover {
				background-color: ${theme.palette.secondary.dark};
			}
			@media (max-width: ${theme.breakpoints.values.sm}px) {
				padding: 4px;
			}
		`}
`;

const SignInButton = ({ label = 'Sign In', accountBarStyle = false }) => {
	const path = useSelector(routeSelectors.getPathName);
	const userManager = useSelector(appConfigSelectors.getUserManager);

	const navigateToLogin = () => {
		sessionStorage.setItem(REDIRECT_PATH, path);
		userManager.signinRedirect();
	};

	return (
		<SLWButton
			$accountBarStyle={accountBarStyle}
			onClick={navigateToLogin}
			data-testid="SignInButton__Button"
		>
			{label}
		</SLWButton>
	);
};

SignInButton.propTypes = {
	label: PropTypes.string,
	accountBarStyle: PropTypes.bool,
};

export default SignInButton;

import { createSelector } from 'reselect';
import { selectors as appConfigSelectors } from 'modules/appConfig';
import {
	selectors as notificationSelectors,
	actions as notificationActions,
} from 'modules/notifications';
import { selectors as accountDetailsSelectors } from 'modules/api/account/detailsModule';
import { selectors as routeSelectors } from 'modules/route';
import { actions as openidActions } from 'modules/openid';
import { actions as favoritesActions } from 'modules/favorites';
import chatActions from 'modules/chat/actions';
import { iconKeys } from 'components/atoms/NavIcon/NavIcon';
import { actions as checkoutActions } from 'modules/basket/checkout';

// navigation keys
const home = 'home';
const favorites = 'favorites';
const projects = 'projects';
const projectList = 'projectList';
const createProject = 'createProject';
const servers = 'servers';
const serversList = 'serversList';
const publicSshKeys = 'publicSshKeys';
const migrationCenter = 'migrationCenter';
const scheduledTasks = 'scheduledTasks';
const domain = 'domains';
const domainsList = 'domainsList';
const registerDomain = 'registerDomain';
const transferDomain = 'transferDomain';
const dns = 'dns';
const ssl = 'ssl';
const services = 'services';
const shop = 'shop';
const marketplace = 'marketplace';
const cart = 'cart';
const checkout = 'checkout';
const account = 'account';
const accountOverview = 'accountOverview';
const accountUsers = 'accountUsers';
const accountHistory = 'accountHistory';
const createAccount = 'createAccount';
const secureNotes = 'secureNotes';
const billingCenter = 'billingCenter';
const policies = 'policies';
const alerts = 'alerts';
const userProfile = 'userProfile';
const support = 'support';
const myTickets = 'myTickets';
const lwStatus = 'lwStatus';
const articles = 'articles';
const chat = 'chat';
const logout = 'logout';

/**
 * making arrays of the keys to avoid multiple defintions of the same config
 * so we have single sources of truth. These arrays are used to build the final object.
 * */
const navKeyArrays = {
	admin: [home, shop, marketplace, cart, alerts, userProfile, logout],
	setup: [shop, marketplace, cart, checkout],
	unauthed: [shop, marketplace, cart, checkout, createAccount],
	authed: [
		home,
		projects,
		projectList,
		createProject,
		servers,
		serversList,
		publicSshKeys,
		migrationCenter,
		scheduledTasks,
		domain,
		domainsList,
		registerDomain,
		transferDomain,
		dns,
		ssl,
		services,
		shop,
		marketplace,
		cart,
		checkout,
		account,
		accountOverview,
		accountUsers,
		accountHistory,
		secureNotes,
		billingCenter,
		policies,
		alerts,
		support,
		myTickets,
		lwStatus,
		chat,
		articles,
	],
};

/**
 * This is designed to become a nested data structure using the "parent" prop is
 * the configs.
 *
 * If a config has no parent, it is assumed that it is a parent in index.js ->
 * `getParentConfigs`. If a config _does_ have a parent, it will appear under
 * that parent key in index.js -> `getChildConfigs`.
 * @returns a selector that an object of key/item pairs linking a navKey to an
 * object that describes it.
 */
const getConfigs = createSelector(
	appConfigSelectors.getWwwHostname,
	appConfigSelectors.getUserProfilePath,
	notificationSelectors.getUnread,
	accountDetailsSelectors.getSecureNotesCount,
	appConfigSelectors.getKeroSignupLink,
	routeSelectors.getHomepagePath,
	/**
	 * @returns {Record<string, NavConfig}
	 */
	(
		wwwHostname,
		userProfilePath,
		unreadNotifications,
		secureNotesCount,
		keroSignupLink,
		homepagePath,
	) => {
		const navConfig = {
			[home]: {
				to: homepagePath,
				iconKey: iconKeys.home,
				label: 'Home',
			},
			[favorites]: {
				action: favoritesActions.openFavoritesModal(),
				iconKey: iconKeys.star,
				label: 'Favorites',
			},
			[projects]: {
				to: '/projects',
				iconKey: iconKeys.projects,
				label: 'Projects',
			},
			[projectList]: {
				to: '/projects',
				iconKey: iconKeys.projects,
				label: 'Projects List',
				parent: projects,
			},
			[createProject]: {
				to: '/projects/create',
				iconKey: iconKeys.projects,
				label: 'Create Project',
				parent: projects,
			},
			[servers]: {
				to: '/servers',
				iconKey: iconKeys.servers,
				label: 'Servers',
			},
			[serversList]: {
				to: '/servers',
				iconKey: iconKeys.servers,
				label: 'Servers List',
				parent: servers,
			},
			[publicSshKeys]: {
				to: '/servers/ssh-keys',
				iconKey: iconKeys.servers,
				label: 'Public SSH Keys',
				parent: servers,
			},
			[migrationCenter]: {
				to: '/servers/migration-center',
				iconKey: iconKeys.servers,
				label: 'Migration Center',
				parent: servers,
			},
			[scheduledTasks]: {
				to: '/servers/scheduled-tasks',
				iconKey: iconKeys.servers,
				label: 'Scheduled Tasks',
				parent: servers,
			},
			[domain]: {
				to: '/domain',
				iconKey: iconKeys.domain,
				label: 'Domains',
			},
			[domainsList]: {
				to: '/domain',
				iconKey: iconKeys.domain,
				label: 'Domains List',
				parent: domain,
			},
			[registerDomain]: {
				to: '/shop/domain',
				iconKey: iconKeys.domain,
				label: 'Register a New Domain',
				parent: domain,
			},
			[transferDomain]: {
				to: '/domain/create-transfer',
				iconKey: iconKeys.domain,
				label: 'Transfer a Domain',
				parent: domain,
			},
			[dns]: {
				to: '/domain/dns',
				iconKey: iconKeys.domain,
				label: 'DNS',
				parent: domain,
			},
			[ssl]: {
				to: '/domain/ssl',
				iconKey: iconKeys.domain,
				label: 'SSL',
				parent: domain,
			},
			[services]: {
				to: '/services',
				iconKey: iconKeys.services,
				label: 'Services',
			},
			[shop]: {
				to: '/shop/marketplace',
				iconKey: iconKeys.shop,
				label: 'Shop',
			},
			[marketplace]: {
				to: '/shop/marketplace',
				parent: shop,
				iconKey: iconKeys.shop,
				label: 'Marketplace',
			},
			[cart]: {
				parent: shop,
				iconKey: iconKeys.shop,
				label: 'Cart',
				to: '/cart',
			},
			[checkout]: {
				parent: shop,
				iconKey: iconKeys.shop,
				label: 'Checkout',
				action: checkoutActions.takeToNextStep(),
			},
			[account]: {
				to: '/account',
				label: 'Account',
				iconKey: iconKeys.account,
				notifications: secureNotesCount,
			},
			[accountOverview]: {
				to: '/account',
				label: 'Account Overview',
				iconKey: iconKeys.account,
				parent: account,
			},
			[accountUsers]: {
				to: '/account/users',
				label: 'Users',
				iconKey: iconKeys.account,
				parent: account,
			},
			[accountHistory]: {
				to: '/account/history',
				label: 'History',
				iconKey: iconKeys.account,
				parent: account,
			},
			[secureNotes]: {
				to: '/account/secure-notes',
				label: 'Secure Notes',
				iconKey: iconKeys.account,
				parent: account,
				notifications: secureNotesCount,
			},
			[billingCenter]: {
				to: '/account/billing',
				label: 'Billing Center',
				iconKey: iconKeys.account,
				parent: account,
			},
			[policies]: {
				to: '/account/policies',
				label: 'Policies',
				iconKey: iconKeys.account,
				parent: account,
			},
			[alerts]: {
				action: notificationActions.toggleDrawer(),
				iconKey: iconKeys.notifications,
				label: 'Alerts',
				notifications: unreadNotifications.length,
			},
			[userProfile]: {
				to: userProfilePath,
				iconKey: iconKeys.person,
				label: 'User',
			},
			[createAccount]: {
				to: keroSignupLink,
				iconKey: iconKeys.person,
				label: 'Create Account',
			},
			[support]: {
				to: '/support/mytickets',
				iconKey: iconKeys.support,
				label: 'Support',
			},
			[myTickets]: {
				to: '/support/mytickets',
				iconKey: iconKeys.support,
				label: 'My Tickets',
				parent: support,
			},
			[lwStatus]: {
				to: 'https://status.liquidweb.com/',
				iconKey: iconKeys.support,
				label: 'Liquid Web Status',
				parent: support,
			},
			[articles]: {
				to: 'https://www.liquidweb.com/help-docs/',
				iconKey: iconKeys.support,
				label: 'Articles',
				parent: support,
			},
			[chat]: {
				action: chatActions.startChat(),
				iconKey: iconKeys.support,
				label: 'Chat with a Human',
				parent: support,
			},
			[logout]: {
				action: openidActions.logout(),
				iconKey: iconKeys.logout,
				label: 'Logout',
			},
		};
		return navConfig;
	},
);

export { navKeyArrays };
export default getConfigs;

import React from 'react';
import { useSelector } from 'react-redux';
import { selectors as officeIpSelectors } from 'modules/api/network/util/isOfficeIpModule';
import { selectors as appConfigSelectors } from 'modules/appConfig';
import { selectors as whatismyipSelectors } from 'modules/api/utilities/info/whatismyipModule';
import PropTypes from 'prop-types';
import AppConfigError from 'containers/pages/error/AppConfigError';
import EnvironmentError from 'containers/pages/error/EnvironmentError';
import LWLoader from 'components/common/LWLoader';
import Box from '@material-ui/core/Box';

const Container = ({ children }) => (
	<Box
		display="flex"
		alignItems="center"
		justifyContent="center"
		height="80vh"
		width="100%"
	>
		{children}
	</Box>
);

const AppConfigGate = ({ children }) => {
	const appConfig = useSelector(appConfigSelectors.getAppConfig);
	const isOfficeIp = useSelector(officeIpSelectors.getIsOfficeIp);
	const officeIpIsLoading = useSelector(officeIpSelectors.isLoading);
	const officeIpHasData = useSelector(officeIpSelectors.hasData);
	const whatismyipHasError = useSelector(whatismyipSelectors.hasError); // This will be defined if you're not on the cloud vpn.

	if (!appConfig)
		return (
			<Container>
				<AppConfigError />
			</Container>
		);

	// Nothing in this block happens in production, so let's not be too picky about the loader handling.
	if (!isOfficeIp && process.env.REACT_APP_CONFIG !== 'prod') {
		if ((officeIpIsLoading || !officeIpHasData) && !whatismyipHasError)
			return (
				<Container>
					<LWLoader />
				</Container>
			);

		return (
			<Container>
				<EnvironmentError />
			</Container>
		);
	}

	return children;
};

AppConfigGate.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

export default AppConfigGate;

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import useProjectRename from 'modules/queries/project/useRename';
import snackbarActions from 'modules/snackbar/snackbarActions';
import useDebounce from 'utility/effects/useDebounce';

const EditNameDialog = ({
	availableObj: {
		error: availableError,
		data: availableData,
		isLoading: isLoadingAvailable,
	},
	dialogObj: { isOpen, dialogProps },
	toggleDialog,
	fetchAvailable,
}) => {
	const dispatch = useDispatch();
	const {
		mutate: renameProject,
		isLoading: isLoadingRename,
	} = useProjectRename();
	const { projectId, projectName } = dialogProps;
	const [newProjectName, setNewProjectName] = useState(projectName);
	const debouncedNewProjectName = useDebounce(newProjectName, 500);

	useEffect(() => {
		setNewProjectName(projectName);
	}, [projectName]);

	useEffect(() => {
		if (debouncedNewProjectName && debouncedNewProjectName !== projectName) {
			fetchAvailable(debouncedNewProjectName);
		}
	}, [fetchAvailable, debouncedNewProjectName, projectName]);

	const handleChangeName = (e) => {
		setNewProjectName(e.target.value);
	};
	const notAvailable =
		(availableData && availableData.available === 0) || !!availableError;
	return (
		<ConfirmationDialog
			open={isOpen}
			confirmProps={{
				disabled: notAvailable,
				isLoading: isLoadingRename || isLoadingAvailable,
			}}
			onConfirm={() =>
				renameProject(
					{ new_project_name: newProjectName, id: projectId },
					{
						onSuccess: () => {
							dispatch(
								snackbarActions.pushMessage({
									variant: 'success',
									message: `Successfully renamed project`,
								}),
							);
						},
					},
				)
			}
			onClose={() => toggleDialog(false)}
			title="Edit Project Name"
		>
			<TextField
				error={notAvailable}
				helperText={notAvailable && 'Project name not available'}
				value={newProjectName}
				label="Project Name"
				variant="outlined"
				onChange={handleChangeName}
			/>
		</ConfirmationDialog>
	);
};

export default EditNameDialog;

const actions = {
	DNS_DOMAIN_INIT: 'DNS_DOMAIN_INIT',
	/**
	 * Pass in the name of the domain to initialize everything needed to load DomainDNS.jsx
	 * @param {string} param0.name
	 * @returns {object}
	 */
	initDomainDNS: ({ name }) => ({
		type: actions.DNS_DOMAIN_INIT,
		name,
	}),
	DNS_LAUNCH_DELETE_ZONE_MODAL: 'DNS_LAUNCH_DELETE_ZONE_MODAL',
	launchDeleteZoneModal: () => ({
		type: actions.DNS_LAUNCH_DELETE_ZONE_MODAL,
	}),
	DNS_LAUNCH_CONFIRM_DELETE_ZONE_MODAL: 'DNS_LAUNCH_CONFIRM_DELETE_ZONE_MODAL',
	launchConfirmDeleteZoneModal: () => ({
		type: actions.DNS_LAUNCH_CONFIRM_DELETE_ZONE_MODAL,
	}),
	DNS_DOWNLOAD_ZONE_DATA: 'DNS_DOWNLOAD_ZONE_DATA',
	/**
	 * Triggers a download of a text file describing the dns zone data. If name is omitted, it will use the loaded zone name
	 * @param {string} [param0.name]
	 * @returns {object}
	 */
	downloadZoneData: ({ name } = {}) => ({
		type: actions.DNS_DOWNLOAD_ZONE_DATA,
		name,
	}),
	DNS_ZONE_FETCH_DETAILS: 'DNS_ZONE_FETCH_DETAILS',
	/**
	 * Pass in a name to retrieve the dns zone details associated with the name.
	 * @param {string} param0.name
	 * @returns {object}
	 */
	fetchZoneDetails: ({ name }) => ({
		type: actions.DNS_ZONE_FETCH_DETAILS,
		name,
	}),
	DNS_INIT_ASSIGNED_NAME_SERVERS: 'DNS_INIT_ASSIGNED_NAME_SERVERS',
	/**
	 * Pass in the name of the domain to initialize the apis for Assigned Name Servers.
	 * @param {string} param0.name
	 * @returns {object}
	 */
	initAssignedNameServers: ({ name }) => ({
		type: actions.DNS_INIT_ASSIGNED_NAME_SERVERS,
		name,
	}),
	DNS_DELETE_ZONE: 'DNS_DELETE_ZONE',
	/**
	 * Deletes the zone associated to the name. If no name is passed it, it deletes the zone loaded in the zoneDetails module.
	 * @param {string} param0.name
	 * @returns {object}
	 */
	deleteZone: ({ name } = {}) => ({
		type: 'DNS_DELETE_ZONE',
		name,
	}),
};

export default actions;

import { call, delay } from 'redux-saga/effects';

// Use this to simply hit an endpoint.
// Initial use case is setting cookies from other apps.
function* hitEndpoint(url) {
	// axios doesn't do 'no-cors'. https://github.com/axios/axios/issues/1358 So here we are...
	yield call(fetch, url, {
		method: 'GET',
		mode: 'no-cors',
		credentials: 'include',
	});
}

// hits the endpoint from the first arg, and the waits up to 30 seconds for the expectedProperty (string) to exist on the window object.
function* loadScriptFromUrl(url, expectedProperty) {
	if (window[expectedProperty]) return true; // No need to load it again.

	// append a script tag.
	const script = document.createElement('script');
	script.src = url;
	yield call([document.body, 'appendChild'], script);

	// If no expectedProperty was passed in, return the uncertainty.
	if (!expectedProperty) return undefined;

	// wait for the property to exist or timeout and return false.
	let seconds = 0;
	while (seconds < 30 && !window[expectedProperty]) {
		yield delay(1000);
		seconds += 1;
	}
	return !!window[expectedProperty];
}

export { loadScriptFromUrl };
export default hitEndpoint;

import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.market.cart.item.add;

const { actions, reducer, sagas, selectors, additionalSagas } = createAPIModule(
	{
		getStateSlice,
		actionType: 'MARKET_CART_ITEM_ADD',
		url: '/market/cart/item/add.json',
	},
);

export {
	actions as cartItemAddActions,
	reducer as cartItemAddReducer,
	sagas as cartItemAddSagas,
	selectors as cartItemAddSelectors,
	additionalSagas as cartItemAddAdditionalSagas,
};

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { cartDetailsSelectors } from 'modules/api/market/cart/detailsModule';
import CompactCartSummary from './CompactCartSummary';

const mapStateToProps = (state) => ({
	itemsCount: cartDetailsSelectors.getTotalUnits(state),
});

export default connect(mapStateToProps)(withRouter(CompactCartSummary));

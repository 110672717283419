import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.network.dns.reverse.update;

const { actions, reducer, sagas, selectors, additionalSagas } = createAPIModule(
	{
		getStateSlice,
		actionType: 'NETWORK_DNS_REVERSE_UPDATE',
		url: '/network/dns/reverse/update.json',
	},
);

export {
	actions as dnsReverseUpdateActions,
	selectors as dnsReverseUpdateSelectors,
	reducer,
	sagas,
	additionalSagas as dnsReverseUpdateAdditionalSagas,
};

import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.storage.objectstore.deleteKey;

const {
	actions,
	reducer,
	sagas,
	additionalSagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'STORAGE_OBJECTSTORE_DELETEKEY',
	method: 'POST',
	url: '/storage/objectstore/deleteKey.json',
});

const selectors = {
	...defaultSelectors,
};

export { actions, reducer, sagas, additionalSagas, selectors };

import React, { useContext } from 'react';

import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import LWTypography from 'components/common/LWTypography';

import Context from './context';

const RegionInput = ({ regions }) => {
	const {
		isSubmitting,
		regionId: [regionId, setRegionId],
	} = useContext(Context);

	const handleSelectedRegionIdChange = (event) =>
		setRegionId(event.target.value);

	return (
		<Grid spacing={2} container>
			<Grid item xs={4}>
				<LWTypography>
					<strong>Region:</strong>
				</LWTypography>
			</Grid>
			<Grid item xs={8}>
				<FormControl variant="outlined">
					<InputLabel id="region">Select</InputLabel>
					<Select
						label="Region"
						labelId="region"
						value={regionId}
						onChange={handleSelectedRegionIdChange}
						inputProps={{
							name: 'region',
							id: 'region',
						}}
						disabled={isSubmitting}
					>
						{regions &&
							regions.map((item, index) => (
								<MenuItem key={item.id} value={item.id} default={index === 0}>
									{item.name}
								</MenuItem>
							))}
					</Select>
				</FormControl>
			</Grid>
		</Grid>
	);
};

export default RegionInput;

import { call, put, select, takeLeading } from 'redux-saga/effects';
import { LOCATION_CHANGE, getHash } from 'connected-react-router';
import getAppVersion from 'utility/getAppVersion';
import { selectors as appConfigSelectors } from 'modules/appConfig';
import actions from './actions';
import { initialized } from './selectors';

const HASH_DANGER_LIST = ['access_token='];

const appVersion = getAppVersion({ prefix: true });

function* jsInjection() {
	// Avoid loading gtm until tokens are cleared
	const hash = yield select(getHash);
	if (HASH_DANGER_LIST.some((needle) => hash.includes(needle))) return;

	if (yield select(initialized)) return;

	const gtmId = yield select(appConfigSelectors.getGoogleTagManagerId);

	// Manually inject the required GTM JS into the DOM
	// https://developers.google.com/tag-manager/quickstart

	const headerScript = document.createElement('script');
	headerScript.async = true;

	headerScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
	new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
	j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
	'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
	})(window,document,'script','dataLayer','${gtmId}');
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({ 'appVersion': '${appVersion}' });`;

	const { head, body } = document;

	yield call([head, 'appendChild'], headerScript);

	const bodyNoScript = document.createElement('noscript');
	const iframe = document.createElement('iframe');
	iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
	iframe.height = 0;
	iframe.width = 0;
	iframe.style = 'display:none;visibility:hidden';

	yield call([bodyNoScript, 'append'], iframe);

	yield call([body, 'appendChild'], bodyNoScript);

	yield put(actions.initialized());
}

export default function* gtmRoot() {
	yield call(jsInjection);
	yield takeLeading(LOCATION_CHANGE, jsInjection);
}

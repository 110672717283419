import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LWButton from 'components/common/LWButton';
import LWLink from 'components/common/LWLink';
import LWTypography from 'components/common/LWTypography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import GenericError from 'components/common/GenericError';
import styled from 'styled-components';
import useAssetDetails from 'modules/queries/asset/useDetails';
import useAssetRemove from 'modules/queries/asset/useRemove';
import { useDispatch } from 'react-redux';
import snackbarActions from 'modules/snackbar/snackbarActions';
import dialogActions from 'modules/dialogs/actions';

const SBox = styled(Box)`
	width: 100%;

	.MuiInputBase-input {
		width: 100%;
	}
	button.MuiButtonBase-root {
		width: 128px;
	}
`;

/** @param {import('modules/queries/asset/useDetails').AssetDetails} props */
const DestroyOpenStack = ({
	project_id: projectId,
	project_name: projectName,
	uniq_id: uniqId,
	mesDetails,
	domain,
}) => {
	const dispatch = useDispatch();
	const titles = {
		host: 'Destroying a File Replication Host',
		primary: 'Destroying a Database Cluster Master',
		lb: 'Destroying a Load Balancer',
	};

	const mesUniqId = mesDetails?.uniq_id;
	const {
		data: mesAssetDetailsData,
		isLoading: mesAssetDetailsIsLoading,
		isError: mesAssetDetailsIsError,
	} = useAssetDetails(
		{
			uniq_id: mesUniqId,
			alsowith: ['servers'],
		},
		{ enabled: Boolean(mesUniqId) },
	);

	const {
		mutate: removeAsset,
		isError: removeAssetIsError,
		isLoading: removeAssetIsLoading,
	} = useAssetRemove();

	const [stageTwo, setStageTwo] = useState(
		!Object.keys(titles).includes(mesDetails.mes_role),
	);
	const [isConfirmed, setIsConfirmed] = useState(false);

	const setTitle = (newTitle) => dispatch(dialogActions.setTitle(newTitle));

	useEffect(() => {
		if (!stageTwo) {
			setTitle(titles[mesDetails.mes_role]);
		}
		// We only want this to trigger once
		// eslint-disable-next-line
	}, []);

	const closeDialog = () => {
		dispatch(dialogActions.close());
	};

	const handleDestroy = () => {
		removeAsset(
			{ uniq_id: uniqId },
			{
				onSuccess: () => {
					dispatch(
						snackbarActions.pushMessage({
							variant: 'success',
							message: 'Successfully Destroyed Server',
						}),
					);
					closeDialog();
				},
			},
		);
	};

	const serversList = mesAssetDetailsData?.servers
		?.filter((server) => server.domain !== domain)
		.map(({ domain: mesDomain }) => {
			return <LWTypography bold>{mesDomain}</LWTypography>;
		});

	const renderStageOneContent = (role) => {
		switch (role) {
			case 'host':
				return (
					<>
						<Grid item>
							<LWTypography>
								This server is part of a File Replication service, with the role
								of the Host server for the following Client servers:
							</LWTypography>
						</Grid>
						<Grid item container direction="column">
							{mesAssetDetailsIsLoading ? <CircularProgress /> : serversList}
						</Grid>

						<Grid item>
							<LWTypography bold>
								Destroying this Host server will immediately disrupt the File
								Replication service and possibly create other errors.
							</LWTypography>
						</Grid>
						<Grid item>
							<LWTypography>
								{`Destroying is usually a step towards cancelling the File Replication service at-large. If this is your intent, certainly continue. If it is not, we recommend viewing your `}
								<LWLink to={`projects/${projectId}`}>{projectName}</LWLink>
								{` project to review server roles for the File Replication service.`}
							</LWTypography>
						</Grid>
					</>
				);
			case 'primary':
				return (
					<>
						<Grid item>
							<LWTypography>
								This server is part of a Database Clustering service, with the
								role of the Primary server for the following Read-Only servers:
							</LWTypography>
						</Grid>
						<Grid item container direction="column">
							{mesAssetDetailsIsLoading ? <CircularProgress /> : serversList}
						</Grid>

						<Grid item>
							<LWTypography bold>
								Destroying this Primary server will immediately disrupt the
								Database Clustering service and possibly create other errors.
							</LWTypography>
						</Grid>
						<Grid item>
							<LWTypography>
								{`Destroying is usually a step towards cancelling the Database Clustering service at-large. If this is your intent, certainly continue. If it is not, we recommend viewing your `}
								<LWLink to={`projects/${projectId}`}>{projectName}</LWLink>
								{` project to review server roles for the Database Clustering service.`}
							</LWTypography>
						</Grid>
					</>
				);
			case 'lb':
				return (
					<>
						<Grid item>
							<LWTypography>
								This Load Balancer is currently balancing traffic to multiple
								servers.
							</LWTypography>
						</Grid>
						<Grid item>
							<LWTypography bold color="error">
								Destroying this Load Balancer will immediately disrupt the load
								balancing service and possibly disrupt other services.
							</LWTypography>
						</Grid>
					</>
				);
			default:
				return <CircularProgress />;
		}
	};

	const renderStageOne = () => (
		<Grid container direction="column" spacing={3}>
			{
				<Grid>
					<GenericError shown={mesAssetDetailsIsError}>
						Error Fetching Servers
					</GenericError>
				</Grid>
			}
			{renderStageOneContent(mesDetails.mes_role)}
			<Grid item>
				<DialogActions>
					<LWButton
						color="default"
						onClick={closeDialog}
						BoxProps={{ ml: 'auto', mr: 2 }}
					>
						Cancel
					</LWButton>
					<LWButton variant="contained" onClick={() => setStageTwo(true)}>
						Continue
					</LWButton>
				</DialogActions>
			</Grid>
		</Grid>
	);

	const stageTwoLabel = (role) => {
		switch (role) {
			case 'lb':
				return 'Yes, I confirm and acknowledge destroying this Load Balancer will end load balancing functionality.';
			case 'host':
			case 'primary':
			default:
				return 'Yes, I confirm and acknowledge destroying this server will permanently delete the data it contains.';
		}
	};

	const stageTwoDescription = (role) => {
		switch (role) {
			case 'lb':
				return `Are you sure you wish to destroy ${domain}?`;
			case 'host':
			case 'primary':
			default:
				return `All data on ${domain} will be permanently deleted.`;
		}
	};

	const renderStageTwo = () => (
		<>
			<Box>
				<GenericError shown={removeAssetIsError}>
					Error deleting Server
				</GenericError>
			</Box>
			<Box pt={2}>
				<LWTypography bold>
					{stageTwoDescription(mesDetails.mes_role)}
				</LWTypography>
			</Box>
			{mesDetails.mes_role !== 'lb' && (
				<Box pt={2}>
					<LWTypography>
						Are you sure you wish to destroy this volume?
					</LWTypography>
				</Box>
			)}
			<Box pt={2}>
				<FormControlLabel
					fullWidth
					control={
						<Checkbox
							checked={isConfirmed}
							onChange={(e) => setIsConfirmed(e.target.checked)}
							name="checkedB"
							color="primary"
						/>
					}
					label={stageTwoLabel(mesDetails.mes_role)}
				/>
			</Box>
			<Box pt={2} display="flex">
				<LWButton
					color="default"
					onClick={closeDialog}
					BoxProps={{ ml: 'auto', mr: 2 }}
				>
					Cancel
				</LWButton>
				<LWButton
					disabled={!isConfirmed || removeAssetIsLoading}
					isLoading={removeAssetIsLoading}
					color="secondary"
					variant="contained"
					onClick={handleDestroy}
				>
					Destroy
				</LWButton>
			</Box>
		</>
	);
	return (
		<SBox>
			{!stageTwo && renderStageOne()}
			{stageTwo && renderStageTwo()}
		</SBox>
	);
};

export default DestroyOpenStack;

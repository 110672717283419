import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';

const getStateSlice = (state) => state.api.billing.paypal.payment.create;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'BILLING_PAYPAL_PAYMENT_CREATE',
	url: '/billing/paypal/payment/create.json',
});

const selectors = {
	approvalUrl: createSelector(
		defaultSelectors.getStateSlice,
		(slice) => {
			const redirect = slice
				.getIn(['data', 'response', 'links'], ImmutableList())
				.filter((link) => link.get('rel') === 'approval_url')
				.first();
			return redirect ? redirect.get('href') : null;
		},
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };

import { call, put, select, takeLatest, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
	selectors as billingAddressSelectors,
	actions as billingAddressActions,
} from 'modules/api/account/billingAddress/detailsModule';
import {
	hasAuthToken,
	isKeroOnly as getIsKeroOnly,
} from 'modules/auth/authSelectors';
import { selectors as cartSelectors } from 'modules/cart';
import { cartDetailsSelectors } from 'modules/api/market/cart/detailsModule';
import {
	hasPaymentMethodCheck,
	init as initPaymentDetails,
} from 'modules/payment/paymentSagas';
import { assertMetadataSaga } from 'modules/cart/sagas';
import analyticsActions from 'modules/gtm/actions';
import basketCheckoutActions from './actions';

function* initBillingAndAddress() {
	if (!(yield select(billingAddressSelectors.hasData))) {
		yield put(billingAddressActions.fetch());
		yield take([
			billingAddressActions.setType,
			billingAddressActions.errorType,
		]);
	}
}

// used for if the user wants to shop before finishing creating their account and then tries to check out.
function* takeToNextStep({ path } = {}) {
	const shouldAssertShowSpecialOffers = yield select(
		cartSelectors.getShouldAssertShowSpecialOffers,
	);

	if (shouldAssertShowSpecialOffers) {
		yield call(assertMetadataSaga, { metadata: { showSpecialOffers: true } });
	}

	const isKeroOnly = yield select(getIsKeroOnly);
	if (isKeroOnly) {
		yield put(push('/account/create'));
		return;
	}

	const isLoggedIn = yield select(hasAuthToken);
	if (!isLoggedIn) {
		yield put(push('/shop/checkout'));
		return;
	} // this will redirect them to login page

	yield call(initPaymentDetails); // blocking.
	yield call(initBillingAndAddress); // blocking

	const nextStepPath = path || (yield select(cartSelectors.getNextStepPath));

	// We handle this situation here instead of in the selector because we need to show the snackbar.
	if (nextStepPath === '/shop/checkout') {
		const value = yield select(cartDetailsSelectors.getSubtotal);
		const items = yield select(cartDetailsSelectors.getNativeItems);
		const totals = yield select(cartDetailsSelectors.getTotals);
		yield put(
			analyticsActions.sendAnalyticsCheckoutBegin({
				items,
				value,
				totals,
			}),
		);
		yield call(hasPaymentMethodCheck, { navigate: true }); // doing this instead of the selector because we want the snackbar and navigation.
		return; // hasPaymentMethodCheck will have navigated already.
	}

	yield put(push(nextStepPath));
}

export { takeToNextStep };
export default function* saga() {
	yield takeLatest(
		basketCheckoutActions.ACCOUNT_CREATE_TAKE_TO_NEXT_STEP,
		takeToNextStep,
	);
}

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = ({ palette, spacing }) => ({
	root: {
		backgroundColor: palette.text.secondary,
		borderWidth: '1px',
		borderColor: palette.text.primary,
		borderStyle: 'solid',
	},
	value: {
		height: spacing(4),
		backgroundColor: palette.common.blue3,
	},
});

const ProgressBar = ({ classes, value }) => (
	<div className={classes.root}>
		<div className={classes.value} style={{ width: `${value}%` }} />
	</div>
);

ProgressBar.propTypes = {
	classes: PropTypes.object.isRequired,
	value: PropTypes.number.isRequired,
};

export default withStyles(styles)(ProgressBar);
export { ProgressBar };

import { combineReducers } from 'redux';

import { reducer as available } from './availableModule';
import { reducer as availableAssets } from './availableAssetsModule';
import { reducer as categories } from './categoriesModule';
import { reducer as create } from './createModule';

export default combineReducers({
	available,
	availableAssets,
	categories,
	create,
});

import { createSelector } from 'reselect';
import { selectors as currentNotificationSelectors } from 'modules/api/notifications/currentModule';
import { CRITICAL_SEVERITY } from 'utility/constants/notificationConstants';

const getStateSlice = (state) => state.notifications;

const getReadNotifications = createSelector(
	getStateSlice,
	(slice) => slice.readNotifications,
);

const getDrawerOpen = createSelector(
	getStateSlice,
	(slice) => slice.drawerOpen,
);

const getShowMore = createSelector(
	getStateSlice,
	(slice) => slice.showMore,
);

const getUnread = createSelector(
	getReadNotifications,
	currentNotificationSelectors.getFilteredAndSorted,
	(readNotifications, filteredAndSorted) =>
		filteredAndSorted.filter((x) => !readNotifications[x.id]),
);

const getHasUnread = createSelector(
	getUnread,
	(unread) => !!unread.length,
);

const getCriticalUnread = createSelector(
	getUnread,
	(unread) =>
		unread.filter(
			(notification) => notification.severity === CRITICAL_SEVERITY,
		),
);

const MAX_NOTIFICATIONS = 5;
const getNotificationsForDisplay = createSelector(
	getShowMore,
	currentNotificationSelectors.getFilteredAndSorted,
	(showMore, notifications) =>
		showMore ? notifications : notifications.slice(0, MAX_NOTIFICATIONS),
);

const getDisplayShowMore = createSelector(
	currentNotificationSelectors.getFilteredAndSorted,
	(notifications) => notifications.length > MAX_NOTIFICATIONS,
);

export default {
	getCriticalUnread,
	getDisplayShowMore,
	getDrawerOpen,
	getHasUnread,
	getNotificationsForDisplay,
	getReadNotifications,
	getShowMore,
	getStateSlice,
	getUnread,
};

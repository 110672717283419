import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.api.account.auth.twoFactor.activationKey;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ACCOUNT_AUTH_TWOFACTOR_ACTIVATIONKEY',
	url: '/account/auth/twofactor/activation_key.json',
});

const selectors = {
	key: createSelector(
		defaultSelectors.getStateSlice,
		(slice) => slice.getIn(['data', 'key']),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };

// @ts-check
import { useQuery } from '@tanstack/react-query';
import api from 'modules/queries/api';

/**
 * @param {import('club-sauce/public/acronis/backup/index.raw').LWApiPublicAcronisBackupUsagesParamsRawI} params
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 */
const useAcronisUsages = (params, { enabled } = {}) =>
	useQuery({
		queryKey: ['/acronis/backup/usages', params],
		queryFn: () => api.acronis.backup.apiRaw.usages(params),
		enabled,
	});

export default useAcronisUsages;

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LWLink from 'components/common/LWLink';
import { ReactComponent as FacebookIcon } from 'images/facebook-icon.svg';
import { ReactComponent as LinkedInIcon } from 'images/linkedin-icon.svg';
import { ReactComponent as XTwitterIcon } from 'images/x-twitter-icon.svg';

export const styles = (theme) => ({
	socialIcons: {
		textAlign: 'center',
		'&>a': {
			padding: '1em',
			fill: theme.palette.primary.contrastText,
		},
	},
	svg: {
		height: '20px',
	},
});

const SocialIcons = (props) => {
	const { classes } = props;
	return (
		<div className={classes.socialIcons}>
			<LWLink to="https://www.facebook.com/LiquidWeb/">
				<FacebookIcon className={classes.svg} />
			</LWLink>
			<LWLink to="https://www.linkedin.com/company/liquid-web">
				<LinkedInIcon className={classes.svg} />
			</LWLink>
			<LWLink to="https://x.com/liquidweb">
				<XTwitterIcon className={classes.svg} />
			</LWLink>
		</div>
	);
};

SocialIcons.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SocialIcons);

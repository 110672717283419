import React, { useContext, useMemo, useState } from 'react';

import Box from '@material-ui/core/Box';
import Delete from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import LWButton from 'components/common/LWButton';
import LWTypography from 'components/common/LWTypography';
import Context from './context';

const NodesInput = ({ nodes }) => {
	const {
		isSubmitting,
		ips: [ips, setIps],
	} = useContext(Context);

	const [ip, setIp] = useState(nodes?.[0]?.ips[0] || '');

	const handleSelectedNodeChange = (event) => setIp(event.target.value);

	const nodeIps = useMemo(
		() => nodes.reduce((result, item) => [...result, ...item.ips], []),
		[nodes],
	);
	const unselectedNodes = useMemo(
		() =>
			nodes
				.reduce((result, node) => [...result, ...node.ips], [])
				.filter((item) => !ips.includes(item)),
		[nodes, ips],
	);

	const addIp = () => {
		const remainingIps = unselectedNodes.filter((item) => item !== ip);
		setIp(remainingIps[0] || '');
		setIps(nodeIps.filter((item) => !remainingIps.includes(item)));
	};

	const removeIp = (node) => () => {
		const remainingIps = nodeIps.filter(
			(item) => item === node || !ips.includes(item),
		);
		setIps(nodeIps.filter((item) => !remainingIps.includes(item)));
		setIp(remainingIps[0] || '');
	};

	return (
		<Grid spacing={2} container>
			<Grid item xs={4}>
				<LWTypography>
					<strong>Nodes:</strong>
				</LWTypography>
			</Grid>
			<Grid item xs={8}>
				<Box display="flex">
					<FormControl variant="outlined">
						<InputLabel id="node">
							{unselectedNodes.length ? 'Select' : 'None Available'}
						</InputLabel>
						<Select
							label="Node"
							labelId="node"
							value={ip}
							onChange={handleSelectedNodeChange}
							inputProps={{
								name: 'node',
								id: 'node',
							}}
							disabled={unselectedNodes.length === 0 || isSubmitting}
						>
							{nodes &&
								nodes.map(
									(item, index) =>
										item.ips &&
										item.ips
											.filter((value) => !ips.includes(value))
											.map((value) => (
												<MenuItem
													key={value}
													value={value}
													default={index === 0}
												>
													{value} - {item.domain}
												</MenuItem>
											)),
								)}
						</Select>
					</FormControl>
					<LWButton
						variant="subtle"
						onClick={addIp}
						disabled={unselectedNodes.length === 0 || isSubmitting}
						BoxProps={{ ml: 1 }}
					>
						Add
					</LWButton>
				</Box>
				<Box mt={2}>
					{ips.length > 0 && (
						<>
							<Box borderBottom={1}>
								<LWTypography variant="body2">
									IPs to be Load Balanced
								</LWTypography>
							</Box>
							{ips.map((item) => (
								<Box key={item} display="flex" alignItems="center">
									<Box flex={1}>
										<LWTypography variant="body2" bold>
											{item}
										</LWTypography>
									</Box>
									<Box display="flex" justifyContent="end">
										<IconButton
											onClick={removeIp(item)}
											disabled={isSubmitting}
										>
											<Delete />
										</IconButton>
									</Box>
								</Box>
							))}
						</>
					)}
					{ips.length === 0 && (
						<Box>
							<LWTypography variant="body2" bold>
								Please select at least 1 node to load balance.
							</LWTypography>
						</Box>
					)}
				</Box>
			</Grid>
		</Grid>
	);
};

export default NodesInput;

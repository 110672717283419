const internalOnlyActions = {
	internalOnlyCheck: 'INTERNAL_ONLY_CHECK',
	check: (payload) => ({
		type: internalOnlyActions.internalOnlyCheck,
		payload,
	}),
	internalOnlySet: 'INTERNAL_ONLY_SET',
	set: (payload) => ({
		type: internalOnlyActions.internalOnlySet,
		payload,
	}),
};

export default internalOnlyActions;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import {
	actions as dialogActions,
	selectors as dialogSelectors,
} from 'modules/dialogs';

import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';
import { contentKeyButtonPadding } from 'components/common/Dialogs/maps';

const ConnectedConfirmCancel = ({ cancel, confirm, ...rest }) => {
	const dispatch = useDispatch();
	const contentKey = useSelector(dialogSelectors.contentKey);
	const closeDialog = () => dispatch(dialogActions.close());
	const handleClose = () => {
		closeDialog();
		if (cancel) cancel();
	};
	const handleConfirm = () => {
		closeDialog();
		if (confirm) confirm();
	};
	const buttons = ({ disableSpacing } = {}) => (
		<ConfirmCancel
			{...rest}
			disableSpacing={disableSpacing}
			confirm={handleConfirm}
			cancel={handleClose}
		/>
	);
	return contentKeyButtonPadding[contentKey] ? (
		<Box mt={4}>{buttons({ disableSpacing: true })}</Box>
	) : (
		buttons()
	);
};
export default ConnectedConfirmCancel;

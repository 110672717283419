import React from 'react';
import Box from '@material-ui/core/Box';
import LWTypography from 'components/common/LWTypography';

const Required = ({ mx = 1, mb = 2 }) => (
	<Box mx={mx} mb={mb}>
		<LWTypography bold color="palette.primary.dark" variant="body2">
			* Required
		</LWTypography>
	</Box>
);
export default Required;

import testUtilActions from 'testUtils/actions';
import actions from './actions';

const initialState = {
	readNotifications: {}, // set of read notification ids: { j16: true }
	showMore: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.NOTIFICATIONS_READ_SET:
			return {
				...state,
				readNotifications: {
					...state.readNotifications, // merge the objects together.
					...action.readNotifications,
				},
			};
		case actions.NOTIFICATIONS_SHOW_MORE:
			return {
				...state,
				showMore:
					typeof action.showMore === 'boolean'
						? action.showMore
						: !state.showMore,
			};
		case testUtilActions.TEST_UTIL_CLEAR_STATE:
			return initialState;
		default:
			return state;
	}
};

export default reducer;

import { createSelector } from 'reselect';
import { maxWidthSelectors } from 'modules/account/maxWidth';

const getStateSlice = (state) => state.chat;

const getIsStarting = createSelector(
	getStateSlice,
	(chat) => chat.isStarting,
);

const getWidth = createSelector(
	getStateSlice,
	(chat) => chat.width,
);

const getIsActive = createSelector(
	getStateSlice,
	(chat) => chat.active,
);

const getAdjust = createSelector(
	maxWidthSelectors.getMarginWidth,
	getWidth,
	(marginWidth, width) => Math.max(width - marginWidth, 0),
);

export { getStateSlice, getIsStarting };
export default {
	getStateSlice,
	getIsStarting,
	getWidth,
	getAdjust,
	getIsActive,
};

import React from 'react';
import Box from '@material-ui/core/Box';
import LWTypography from 'components/common/LWTypography';
import { withStyles } from '@material-ui/styles';
import ChatButton from './ChatButton';

const styles = ({ palette }) => ({
	root: {},
	typography: {
		fontWeight: 500,
		'& span': {
			color: palette.primary.alt,
		},
	},
});

const ChatCTA = ({ classes }) => (
	<Box
		className={classes.root}
		display="flex"
		justifyContent="center"
		height="100%"
	>
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="center"
			marginBottom={2}
		>
			<LWTypography variant="h4" className={classes.typography}>
				<Box>
					<span>Need some help?</span> It’s just
				</Box>
				<Box>a click away.</Box>
			</LWTypography>
			<Box paddingTop={2}>
				<ChatButton />
			</Box>
		</Box>
	</Box>
);

export { ChatCTA };
export default withStyles(styles)(ChatCTA);

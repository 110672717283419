import { combineReducers } from 'redux';
import { cartItemAddReducer } from './addModule';
import { cartItemModifyReducer } from './modifyModule';
import { cartItemRemoveReducer } from './removeModule';

export default combineReducers({
	add: cartItemAddReducer,
	modify: cartItemModifyReducer,
	remove: cartItemRemoveReducer,
});

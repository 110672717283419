import { spawn } from 'redux-saga/effects';

import { sagas as availableSagas } from './availableModule';
import { sagas as availableAssetsSagas } from './availableAssetsModule';
import { sagas as categoriesSagas } from './categoriesModule';
import { sagas as createSagas } from './createModule';

export default function* sagas() {
	yield spawn(availableSagas);
	yield spawn(availableAssetsSagas);
	yield spawn(categoriesSagas);
	yield spawn(createSagas);
}

import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import LWTypography from 'components/common/LWTypography';

import Context from './context';

const MethodInput = ({ strategies }) => {
	const {
		isSubmitting,
		strategy: [strategy, setStrategy],
	} = useContext(Context);

	const handleStrategyChange = (event) => setStrategy(event.target.value);

	return (
		<Grid spacing={2} container>
			<Grid item xs={4}>
				<LWTypography>
					<strong>Method:</strong>
				</LWTypography>
			</Grid>
			<Grid item xs={8}>
				<FormControl component="fieldset">
					<RadioGroup
						aria-label="strategy"
						name="strategy"
						value={strategy}
						onChange={handleStrategyChange}
					>
						{strategies &&
							strategies.map((item) => (
								<FormControlLabel
									key={item.strategy}
									value={item.strategy}
									control={<Radio color="primary" />}
									label={
										<Box mb={1}>
											<LWTypography variant="body2">
												<strong>{item.name}</strong>
											</LWTypography>
											<LWTypography variant="body2">
												{item.description}
											</LWTypography>
										</Box>
									}
									disabled={isSubmitting}
								/>
							))}
					</RadioGroup>
				</FormControl>
			</Grid>
		</Grid>
	);
};

export default MethodInput;

const actions = {
	ACCOUNT_CREATE_SIGNUP_PUT: 'ACCOUNT_CREATE_SIGNUP_PUT',
	signupPut: (payload) => ({
		type: actions.ACCOUNT_CREATE_SIGNUP_PUT,
		payload,
	}),
	CREATE_PAGE_INIT: 'CREATE_PAGE_INIT',
	pageInit: () => ({ type: actions.CREATE_PAGE_INIT }),
};

export default actions;

import { createSelector } from 'reselect';
import { selectors as parentListSelectors } from 'modules/api/storm/private/parent/listModule';
import { selectors as assetDetailsSelectors } from 'modules/api/asset/detailsModule';

const isLoading = createSelector(
	parentListSelectors.isLoading,
	assetDetailsSelectors.isLoading,
	(parent, asset) => parent || asset,
);

const getStateSlice = (state) => state.server.resize.options;

const deployOnto = createSelector(
	getStateSlice,
	(slice) => slice?.deployOnto,
);

const getSelectedValue = createSelector(
	getStateSlice,
	(slice) => slice?.selectedValue,
);

const selectedPrivateParent = createSelector(
	parentListSelectors.getNativeData,
	deployOnto, // This value is also set by the config page so the same data can be sourced.
	(slice, toFind) => {
		return slice?.items?.find(({ uniq_id: uniqId }) => uniqId === toFind);
	},
);

const getDisplayType = createSelector(
	deployOnto,
	(slice) => (slice === 'vps' ? 'ProductOptionSet' : 'ProductOptionTable'),
);

const selectors = {
	getStateSlice,
	deployOnto,
	getSelectedValue,
	getDisplayType,
	isLoading,
	selectedPrivateParent,
};

export {
	getStateSlice,
	deployOnto,
	getSelectedValue,
	isLoading,
	selectedPrivateParent,
	getDisplayType,
};
export default selectors;

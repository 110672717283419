import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.api.account.settings.hasTrait;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ACCOUNT_SETTINGS_HASTRAIT',
	url: '/account/settings/hasTrait.json',
});
// has_trait
const selectors = {
	hasTrait: createSelector(
		defaultSelectors.getData,
		(data) => !!data && !!data.get('has_trait'),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };

import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';

const getStateSlice = (state) => state.api.project.categories;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'PROJECT_CATEGORIES',
	url: '/project/categories.json',
});

const selectors = {
	categories: createSelector(
		defaultSelectors.getStateSlice,
		(slice) => slice.getIn(['data', 'categories'], ImmutableList()),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };

import React, { useEffect } from 'react';
import Immutable from 'immutable';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import compose from 'utility/compose';
import {
	actions as listForKeyActions,
	selectors as listForKeySelectors,
	moduleKeys,
} from 'modules/api/account/user/stateData/listForKeyModule';
import {
	actions as addActions,
	selectors as addSelectors,
} from 'modules/api/account/user/stateData/addModule';
import {
	actions as removeActions,
	selectors as removeSelectors,
} from 'modules/api/account/user/stateData/removeModule';
import { isLoggedInAndNotBasketAdmin as isLoggedInAndNotBasketAdminSelector } from 'modules/auth/authSelectors';

import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import StarFullIcon from '@material-ui/icons/Star';
import StarEmptyIcon from '@material-ui/icons/StarBorder';
import useDirty from 'utility/effects/useDirty';
import snackbarActions from 'modules/snackbar/snackbarActions';
import classnames from 'classnames';

const styles = (theme) => ({
	root: {},
	on: {
		color: theme.palette.secondary.main,
	},
	pending: {
		color: theme.palette.grey.light,
	},
});

const FavoritesToggle = ({
	classes,
	path,
	displayName,
	isLoggedInAndNotBasketAdmin,
	isLoading,
	favoritesData,
	favoritesError,
	addResponse,
	removeResponse,
	fetchFavorites,
	openSnackbar,
	addToFavorites,
	removeFromFavorites,
	clearRemove,
	clearAdd,
}) => {
	const addResPending = addResponse.get('pending');
	const addResData = addResponse.get('data');
	const addResError = addResponse.get('error');
	const removeResPending = removeResponse.get('pending');
	const removeResData = removeResponse.get('data');
	const removeResError = removeResponse.get('error');

	useEffect(() => {
		if (
			!favoritesData &&
			!isLoading &&
			!favoritesError &&
			isLoggedInAndNotBasketAdmin
		) {
			fetchFavorites();
		}
	}, [
		favoritesData,
		favoritesError,
		fetchFavorites,
		isLoggedInAndNotBasketAdmin,
		isLoading,
	]);
	const [localFavoritesData, setFavoritesData, setPristine] = useDirty(
		favoritesData,
		true,
	);

	useEffect(() => {
		if (addResPending) return;
		if (addResData) {
			fetchFavorites();
		}
		if (addResError) {
			openSnackbar(
				`Error occured when setting ${displayName} as Favorite, please try again.`,
				'error',
			);
			setPristine();
		}
		clearAdd(path);
	}, [
		addResData,
		addResError,
		addResPending,
		clearAdd,
		displayName,
		fetchFavorites,
		openSnackbar,
		path,
		setPristine,
	]);

	useEffect(() => {
		if (removeResPending) return;
		if (removeResData) {
			fetchFavorites();
		}
		if (removeResError) {
			openSnackbar(
				`Error occured when removing Favorite ${displayName}, please try again.`,
				'error',
			);
			setPristine();
		}
		clearRemove(path);
	}, [
		clearRemove,
		displayName,
		fetchFavorites,
		openSnackbar,
		path,
		removeResData,
		removeResError,
		removeResPending,
		setPristine,
	]);

	const favoritesList = localFavoritesData && localFavoritesData.get('data');
	const pageFavorite =
		favoritesList && favoritesList.find((x) => x.get('path') === path);

	const onFavoritesClicked = () => {
		if (isLoading || addResPending || removeResPending) return;
		if (pageFavorite) {
			const uuid = pageFavorite.get('uuid');
			removeFromFavorites(uuid, path);

			setFavoritesData(
				localFavoritesData.update('data', (data) =>
					data.filter((fav) => fav.get('uuid') !== uuid),
				),
			);
		} else {
			const favorite = { path, displayName };
			addToFavorites(favorite, path);

			setFavoritesData(
				localFavoritesData.update('data', (data) =>
					data.push(Immutable.fromJS(favorite)),
				),
			);
		}
	};
	return (
		<span className={classes.root}>
			<IconButton
				className={classnames({
					[classes.on]: pageFavorite,
					[classes.pending]: addResPending || removeResPending,
				})}
				aria-label="Favorites"
				onClick={onFavoritesClicked}
				data-testid="FavoritesToggle__Button"
			>
				{pageFavorite ? (
					<StarFullIcon fontSize="large" />
				) : (
					<StarEmptyIcon fontSize="large" />
				)}
			</IconButton>
		</span>
	);
};

FavoritesToggle.propTypes = {
	classes: PropTypes.object.isRequired,
	path: PropTypes.string.isRequired,
	displayName: PropTypes.string.isRequired,
	favoritesData: ImmutablePropTypes.map,
	isLoading: PropTypes.bool.isRequired,
	addResponse: ImmutablePropTypes.map,
	removeResponse: ImmutablePropTypes.map,

	fetchFavorites: PropTypes.func.isRequired,
	openSnackbar: PropTypes.func.isRequired,
	addToFavorites: PropTypes.func.isRequired,
	removeFromFavorites: PropTypes.func.isRequired,
	clearRemove: PropTypes.func.isRequired,
	clearAdd: PropTypes.func.isRequired,
};

FavoritesToggle.defaultProps = {
	favoritesData: null,
	addResponse: Immutable.Map(),
	removeResponse: Immutable.Map(),
};

const mapStateToProps = (state, ownProps) => {
	const path = ownProps.path || state.router.location.pathname;
	return {
		favoritesData: listForKeySelectors.favoritesSelectors.getData(state),
		favoritesError: listForKeySelectors.favoritesSelectors.getError(state),
		removeResponse: removeSelectors.getStateSlice(state).get(path),
		addResponse: addSelectors.getStateSlice(state).get(path),
		isLoggedInAndNotBasketAdmin: isLoggedInAndNotBasketAdminSelector(state),
		isLoading: listForKeySelectors.favoritesSelectors.isLoading(state),
		path,
	};
};

const mapDispatchToProps = (dispatch) => ({
	addToFavorites: (favorite, path) =>
		dispatch(
			addActions.fetch(
				{
					data: JSON.stringify(favorite),
					key: moduleKeys.FAVORITES,
				},
				path,
			),
		),
	removeFromFavorites: (uuid, path) =>
		dispatch(
			removeActions.fetch(
				{
					uuid,
					key: moduleKeys.FAVORITES,
				},
				path,
			),
		),
	fetchFavorites: () =>
		dispatch(
			listForKeyActions.fetch(
				{
					key: moduleKeys.FAVORITES,
				},
				moduleKeys.FAVORITES,
			),
		),
	openSnackbar: (message, color) =>
		dispatch(snackbarActions.pushMessage({ message, variant: color })),
	clearRemove: (path) => {
		dispatch(removeActions.clear(path));
	},
	clearAdd: (path) => {
		dispatch(addActions.clear(path));
	},
});

export { FavoritesToggle };
export default compose(
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
)(FavoritesToggle);

import React from 'react';
import { SnackbarProvider as NotistackProvider } from 'notistack';

const SnackbarProvider = (props) => {
	const { children } = props;

	return (
		<NotistackProvider
			maxSnack={5}
			hideIconVariant
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			autoHideDuration={6000}
		>
			{children}
		</NotistackProvider>
	);
};

export default SnackbarProvider;

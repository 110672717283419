// TODO: write unit tests for these selectors.

import { sortedSet, uniqueList } from 'utility/array';
import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { selectors as assetDetailSelectors } from 'modules/api/asset/detailsModule';
import {
	deployOnto as deployOntoSelector,
	getDisplayType,
} from 'modules/server/resize/options/selectors';
import {
	createOptionTileData,
	createHeaderData,
} from 'utility/redux/selectorHelperFunctions/stormHelpers';

const getStateSlice = (state) => state.api.server.availableConfigs;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	method: 'POST',
	actionType: 'SERVER_AVAILABLECONFIGS',
	url: '/server/availableConfigs.json',
});

const { getNativeState } = defaultSelectors;

// This takes the array we get from the api and munges in the current deployment if it doesn't exist.
const getConfigs = createSelector(
	getNativeState,
	assetDetailSelectors.configIdValue,
	assetDetailSelectors.configId,
	assetDetailSelectors.productType,
	assetDetailSelectors.getPrivateParent,
	assetDetailSelectors.getPreventResizeDown,
	(
		slice,
		currentId,
		currentConfig,
		productCode,
		privateParent,
		preventResizeDown,
	) => {
		const availableConfigs = slice?.data?.configs || [];
		const addCurrent =
			!availableConfigs.find(
				({ config_id: configId }) => configId === currentId,
			) && Boolean(!privateParent);
		// Since we don't reliably get the selected config from the api, we munge as needed.
		if (addCurrent)
			availableConfigs.push({
				category: currentConfig.category,
				config_id: currentId,
				cores: currentConfig.cpu_cores,
				is_baremetal: Number(currentConfig.category === 'bare-metal'),
				memory: currentConfig.memory,
				price: currentConfig.price,
				product_code: productCode,
				storage_size: currentConfig.disk,
				storage_type: currentConfig.disk_type,
				cpu_description: currentConfig.cpu_description,
				storage_raid: currentConfig.raid_level,
				cpu_speed: currentConfig.cpu_speed,
				cpu_hyperthreading: currentConfig.cpu_hyperthreading,
			});

		if (preventResizeDown) {
			const currentSize = currentConfig.disk;
			return availableConfigs.filter(
				(config) => config.storage_size >= currentSize,
			);
		}
		return availableConfigs;
	},
);

// returns an array designed to be consumed by ProductOptionTileSet or ProductOptionTable.
const getProductOptions = ({
	rawConfigs = [],
	current = NaN,
	deployOnto,
	displayType,
}) => {
	return rawConfigs
		.filter((elem) => elem.config_id)
		.map(({ config_id: value, price, ...rest } = {}) => {
			return createOptionTileData(
				{
					value,
					price_total: price,
					isCurrent: current === value,
					extra_data: rest,
				},
				deployOnto === 'vps',
				displayType,
			);
		});
};

const rawConfigsSelector = createSelector(
	getConfigs,
	deployOntoSelector,
	(slice, deployOnto) => {
		if (deployOnto === 'cloudDedicated') {
			return slice?.filter(({ is_baremetal: isBaremetal }) => isBaremetal);
		}
		if (deployOnto === 'vps') {
			return slice?.filter(({ is_baremetal: isBaremetal }) => !isBaremetal);
		}
		return [];
	},
);

const optionsSelector = createSelector(
	assetDetailSelectors.configIdValue,
	rawConfigsSelector,
	deployOntoSelector,
	getDisplayType,
	(current, rawConfigs, deployOnto, displayType) => {
		return getProductOptions({
			rawConfigs,
			current,
			deployOnto,
			displayType,
		});
	},
);

const optionsWithoutCurrent = createSelector(
	optionsSelector,
	assetDetailSelectors.configIdValue,
	(options, current) =>
		options.filter((elem) => elem?.value?.toString() !== current?.toString()),
);

const getHeaderData = createSelector(
	optionsSelector,
	createHeaderData,
);

// Selectors for LWSliders
/* all of the following can be selected dynamically
by the deployOnto property (values cloudDedicated or vps) in modules/server/resize */

const cores = createSelector(
	optionsWithoutCurrent,
	(slice) => sortedSet(slice.map((elem) => elem.cores)),
);

const ram = createSelector(
	optionsWithoutCurrent,
	(slice) => sortedSet(slice.map((elem) => elem.ram)),
);

const storage = createSelector(
	optionsWithoutCurrent,
	(slice) => sortedSet(slice.map((elem) => elem.storage)),
);

// returns an array designed to be consumed by LWSelect.
const getDiskOptions = (rawConfigs) => {
	const diskOptions = uniqueList(
		rawConfigs?.map(({ disk }) => disk).filter(Boolean),
	);

	return (
		diskOptions?.map((option) => ({
			value: option,
			label: option,
		})) || []
	);
};

const diskOptions = createSelector(
	optionsWithoutCurrent,
	(slice) => {
		return getDiskOptions(slice);
	},
);

const selectors = {
	getConfigs,
	optionsSelector,
	optionsWithoutCurrent,
	cores,
	ram,
	storage,
	diskOptions,
	getHeaderData,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };

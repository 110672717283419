import React from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardRounded';
import LWButton from 'components/common/LWButton';
import { useDispatch, useSelector } from 'react-redux';
import cartSelectors from 'modules/cart/selectors';
import cartActions from 'modules/cart/actions';
import { useHistory } from 'react-router';

const configureButtonTestIds = { button: 'ConfigureButton__LWButton' };

export const useConfigureClick = (path) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const isCartLocked = useSelector(cartSelectors.getIsCartLocked);
	const goToConfigure = () => {
		history.push(path);
	};

	return () => {
		if (isCartLocked) {
			dispatch(
				cartActions.openAbandonCartDialog({ onAbandoned: goToConfigure }),
			);
		} else {
			goToConfigure();
		}
	};
};

const ConfigureButton = ({ path }) => {
	const configure = useConfigureClick(path);

	return (
		<LWButton
			data-testid={configureButtonTestIds.button}
			onClick={configure}
			endIcon={<ArrowForwardIcon />}
		>
			Configure
		</LWButton>
	);
};

export { configureButtonTestIds };
export default ConfigureButton;

import React from 'react';
import { useDispatch } from 'react-redux';
import {
	useProductDataContext,
	useSelectionsContext,
} from 'banana-stand/productConfig';
import LWSelect from 'components/atoms/LWSelect';
import useInitialProduct from 'containers/pages/shop/ConfigPackage/useInitialProduct';
import packageConfigActions from 'modules/basket/packageConfig/actions';

const createLabel = ({ baseLabel, amountProducts, productTitle }) => {
	if (amountProducts > 1) {
		return `${baseLabel} - ${productTitle}`;
	}

	return baseLabel;
};

const SimpleConfigProduct = ({ amountProducts }) => {
	const dispatch = useDispatch();
	useInitialProduct();
	const {
		dataCenterOptions,
		packageProductId,
		productTitle,
		productCode,
	} = useProductDataContext();
	const { changeDataCenter, selectedDataCenter } = useSelectionsContext();

	if (dataCenterOptions.length < 2) {
		return null;
	}

	const handleDataCenterChange = (region) => {
		dispatch(
			packageConfigActions.setSelectedRegion({
				selectedRegion: region,
				packageProductId,
			}),
		);
		changeDataCenter(region);
	};

	const label = createLabel({
		baseLabel: 'Data Center',
		amountProducts,
		productTitle: productTitle || productCode,
	});

	return (
		<LWSelect
			fullWidth
			options={dataCenterOptions}
			label={label}
			value={selectedDataCenter}
			onChange={handleDataCenterChange}
		/>
	);
};

export default SimpleConfigProduct;

import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import get from 'lodash/get';

import SubNavRoutes from 'components/routers/SubNavRoutes';
import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';
import { roles } from 'utility/constants/roles';

const DedicatedLoadBalancerConfig = lazy(() =>
	import('containers/pages/services/DedicatedLoadBalancerConfig'),
);
const CloudLoadBalancerConfig = lazy(() =>
	import('containers/pages/services/CloudLoadBalancerConfig'),
);
const LoadBalancerList = lazy(() =>
	import('containers/pages/services/LoadBalancersContainer'),
);
const CloudBlockStorageDetails = lazy(() =>
	import(
		'containers/pages/services/CloudBlockStorage/CloudBlockStorageDetails'
	),
);
const ObjectStorage = lazy(() =>
	import('containers/pages/services/ObjectStorage'),
);
const CloudPrivateNetworks = lazy(() =>
	import('containers/pages/services/CloudPrivateNetworks'),
);
const AcronisRedirect = lazy(() =>
	import('containers/pages/servers/AcronisRedirect'),
);
const VirtualIPs = lazy(() => import('containers/pages/services/VirtualIPs'));
const IpPool = lazy(() => import('containers/pages/services/IpPool'));
const IpPoolCreate = lazy(() =>
	import('containers/pages/services/IpPool/Create'),
);
const IpPoolDetails = lazy(() =>
	import('containers/pages/services/IpPool/Details'),
);

const ServicesRouter = (props) => {
	const { navData } = props;
	return (
		<Switch>
			<Route
				exact
				path={get(navData, 'path')}
				component={get(navData, 'component')}
			/>
			<Route
				exact
				path={`${get(navData, 'path')}/load-balancers/dedicated`}
				component={LoadBalancerList}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				parent={navData}
			/>
			<Route
				exact
				path={`${get(navData, 'path')}/load-balancers/cloud`}
				component={LoadBalancerList}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				parent={navData}
			/>
			<Route
				exact
				path={`${get(navData, 'path')}/load-balancers/dedicated/:uniqId`}
				component={DedicatedLoadBalancerConfig}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				parent={navData}
			/>
			<Route
				exact
				path={`${get(navData, 'path')}/load-balancers/cloud/:uniqId`}
				component={CloudLoadBalancerConfig}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				parent={navData}
			/>
			{/* Backwards compatibility for older routes that didn't include server type */}
			<Route
				exact
				path={`${get(navData, 'path')}/load-balancers/:uniqId`}
				component={({ match }) => (
					<Redirect
						to={`${get(navData, 'path')}/load-balancers/cloud/${
							match.params.uniqId
						}`}
					/>
				)}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				parent={navData}
			/>
			<Route
				exact
				path={`${get(navData, 'path')}/cloud-block-storage/:uniqId`}
				component={CloudBlockStorageDetails}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				parent={navData}
			/>
			<Route
				exact
				path={`${get(navData, 'path')}/object-storage`}
				component={ObjectStorage}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				parent={navData}
			/>
			<Route
				exact
				path={`${get(navData, 'path')}/cloud-private-networks/:tab`}
				component={CloudPrivateNetworks}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				parent={navData}
			/>
			<Route
				exact
				path={`${get(navData, 'path')}/managed-engineering`}
				component={() => (
					<Redirect to={`${get(navData, 'path')}/advanced-services`} />
				)}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				parent={navData}
			/>
			<RoleRestrictedRoute
				path={`${get(navData, 'path')}/acronis/:acronisId`}
				component={AcronisRedirect}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
			<RoleRestrictedRoute
				path={`${get(navData, 'path')}/virtual-ips`}
				component={VirtualIPs}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
			<RoleRestrictedRoute
				exact
				path={`${get(navData, 'path')}/ip-pools`}
				component={IpPool}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
			<RoleRestrictedRoute
				path={`${get(navData, 'path')}/ip-pools/create`}
				component={IpPoolCreate}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
			<RoleRestrictedRoute
				path={`${get(navData, 'path')}/ip-pools/:uniqId`}
				component={IpPoolDetails}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
			<SubNavRoutes subNavs={get(navData, 'subNav')} />
		</Switch>
	);
};

ServicesRouter.propTypes = {
	navData: PropTypes.object.isRequired,
};

export default ServicesRouter;

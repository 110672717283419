import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import formatBytes from 'utility/formatBytes';
import get from 'lodash/get';

const getStateSlice = (state) => state.api.acronis.backup.usages;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ACRONIS_BACKUP_USAGE',
	method: 'POST',
	url: '/acronis/backup/usages.json',
});

const getStorageUsage = createSelector(
	defaultSelectors.getNativeState,
	(slice) => get(slice, ['data', 'usages', 'storage', 'value']),
);

const getStorageUsageGB = createSelector(
	getStorageUsage,
	(storageUsage) => formatBytes(storageUsage, 2).withUnit,
);

const getMaxUsage = createSelector(
	defaultSelectors.getNativeState,
	(slice) => get(slice, ['data', 'usages', 'storage', 'quota', 'max_usage']),
);

const selectors = {
	getStorageUsage,
	getStorageUsageGB,
	getMaxUsage,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import LWTypography from 'components/common/LWTypography';
import logo from 'images/lw-text-logo.svg';

const SPage = styled(Grid)`
	&:before {
		@media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
			content: '';
			position: absolute;
			right: 5%;
			width: 100%;
			height: 100%;
			opacity: 0.1;
			background-image: url(${logo});
			background-size: 42em;
			background-repeat: no-repeat;
			background-position: right;
			z-index: -1;
		}
	}
`;

const SLogo = styled.img`
	margin: ${({ theme }) => theme.spacing(4)}px;
	width: 3.5em;
`;

const FullScreenError = ({
	title = 'Whoops!',
	headerText = 'Error',
	subHeaderText = 'An error occurred.',
	content = 'Refresh to try again. Contact support if this error persists.',
}) => (
	<SPage container direction="column" alignItems="center">
		<SLogo src={logo} alt="Liquid Web Logo" />
		<LWTypography variant="h2" light color="palette.primary.main">
			{title}
		</LWTypography>
		<Paper component={Box} mx={4} my={1} p={7} maxWidth="600px">
			<LWTypography variant="h4" BoxProps={{ mb: 1 }}>
				{headerText}
			</LWTypography>
			<LWTypography variant="subtitle1" semiBold>
				{subHeaderText}
			</LWTypography>
			<LWTypography BoxProps={{ my: 4 }}>{content}</LWTypography>
			<LWTypography color="textSecondary">US: 800-580-4985</LWTypography>
			<LWTypography color="textSecondary">
				International: 1.517.322.0434
			</LWTypography>
		</Paper>
	</SPage>
);

FullScreenError.propTypes = {
	/** Text displayed at the very top of the page */
	title: PropTypes.string,
	/** Text displayed at the top of the box */
	headerText: PropTypes.string,
	/** Text displayed below the headerText */
	subHeaderText: PropTypes.string,
	/** Remaining text content on the page */
	content: PropTypes.string,
};

export default FullScreenError;

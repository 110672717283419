import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import WarningIcon from '@material-ui/icons/WarningRounded';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import LWDialog from 'components/common/LWDialog';
import LabeledIcon from 'components/atoms/LabeledIcon';
import LabeledText from 'components/atoms/LabeledText';
import LWTypography from 'components/common/LWTypography';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';
import ForceReboot from 'components/connectedMolecules/ForceReboot';
import {
	actions as taskSchedulerActions,
	selectors as taskSchedulerSelectors,
} from 'modules/server/taskScheduler';
import supportedTasks from 'modules/server/taskScheduler/supportedTasks';
import CurrentTaskList from './CurrentTaskList';

const getErrorMessage = ({ isDateInPast, isDateAfter7Days }) => {
	if (isDateInPast) return 'Set time can not be in the past';
	if (isDateAfter7Days)
		return 'A resize cannot be scheduled further than 7 days from now';
	return null;
};

const TaskSchedulerDialog = () => {
	const [hasFocus, setHasFocus] = useState(false);

	const dispatch = useDispatch();

	const params = useSelector(taskSchedulerSelectors.getApiParams);
	const hostname = useSelector(taskSchedulerSelectors.getHostname);
	const isOpen = useSelector(taskSchedulerSelectors.getIsOpen);
	const task = useSelector(taskSchedulerSelectors.getTask);
	const canAutoReboot = useSelector(taskSchedulerSelectors.getCanAutoReboot);

	const { uniq_id: uniqId } = params;

	// KeyboardDateTimePicker stores this as a Moment.js object
	const [dateTimeObject, setDateTimeObject] = useState(null);
	const isDateInPast = !!dateTimeObject?.isBefore();
	const sevenDaysFromNow = moment().add(7, 'days');
	// Only restrict to 7 days if task is resize
	const isDateAfter7Days =
		task === 'resize' && dateTimeObject?.isAfter(sevenDaysFromNow);

	// reset this to null each time dialog opens
	useEffect(() => {
		setDateTimeObject(null);
	}, [isOpen]);

	const { label: taskLabel, selectors: taskApiSelector } =
		supportedTasks[task] || {};

	const isLoading = useSelector(taskApiSelector?.isLoading || (() => false));
	const closeDialog = () => dispatch(taskSchedulerActions.closeDialog());

	return (
		<LWDialog
			open={isOpen}
			title="Task Scheduler"
			onClose={closeDialog}
			onCancel={closeDialog}
			contentPadding
			fullWidth
		>
			<Grid container direction="column" spacing={4}>
				<Grid item>
					{hostname && (
						<LabeledText
							inline
							variant="boldedLabel"
							label="Server"
							text={hostname}
							BoxProps={{ mb: 2 }}
						/>
					)}
					<LabeledText
						inline
						variant="boldedLabel"
						label="Type"
						text={taskLabel}
						BoxProps={{ mb: 3 }}
					/>
					<KeyboardDateTimePicker
						label="Date & Time"
						disablePast
						maxDate={sevenDaysFromNow}
						autoOk
						variant="inline"
						inputVariant="outlined"
						value={dateTimeObject}
						format="MM/DD/YYYY hh:mm a"
						onChange={setDateTimeObject}
						onFocus={() => setHasFocus(true)}
						onBlur={() => setHasFocus(false)}
						emptyLabel={hasFocus ? null : 'Immediately'} // If we show the text while the input field has focus, there is no way to fully delete the input field for manual entry
						error={isDateInPast || isDateAfter7Days}
						helperText={getErrorMessage({ isDateInPast, isDateAfter7Days })}
					/>
					{task === 'reboot' && canAutoReboot && <ForceReboot />}
					{/*  TODO: figure out if the following block of code is needed? */}
					{task === 'reboot' && !canAutoReboot && (
						<LWTypography variant="body1">
							A reboot request will be submitted, and a Helpful Human will
							respond shortly.
						</LWTypography>
					)}
					{uniqId && <CurrentTaskList uniqId={uniqId} />}
				</Grid>
				{task === 'resize' && (
					<Grid item>
						<LabeledIcon
							text="Server will perform a reboot to complete the resize."
							icon={<WarningIcon />}
							color="error.main"
						/>
					</Grid>
				)}
				<Grid item>
					<ConfirmCancel
						confirmText={dateTimeObject ? `Schedule ${taskLabel}` : taskLabel}
						cancel={closeDialog}
						isLoading={isLoading}
						disabled={isDateInPast || isDateAfter7Days}
						confirm={() =>
							dispatch(
								taskSchedulerActions.schedule({
									params,
									dateTime: dateTimeObject?.format(),
									task,
								}),
							)
						}
					/>
				</Grid>
			</Grid>
		</LWDialog>
	);
};

export default TaskSchedulerDialog;

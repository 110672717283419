import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCartRounded';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import useTheme from '@material-ui/styles/useTheme';
import { formatCurrency } from 'banana-stand/format';
import usePackageConfigContext from 'banana-stand/packageConfig/usePackageConfigContext';
import applyDiscount from 'banana-stand/parsers/applyDiscount';
import { ProductConfigProviders } from 'banana-stand/productConfig';
import LWButton from 'components/common/LWButton';
import LWTypography from 'components/common/LWTypography';
import {
	useQuickAdd,
	useQuickAddCaption,
} from 'components/connectedMolecules/QuickAddButton/QuickAddButton';
import { createDurationDisplay } from 'containers/pages/shop/ConfigPackage/BillingCycleSelect';
import { isBasketAdmin as getIsBasketAdmin } from 'modules/auth';
import packageConfigActions from 'modules/basket/packageConfig/actions';
import packageConfigSelectors from 'modules/basket/packageConfig/selectors';
import ConfigureButton from 'components/connectedMolecules/ConfigureButton';
import { maybeRound } from 'components/cart/Item/CartItemDiscounts';
import SimpleConfigProduct from './SimpleConfigProduct';

const PriceText = ({ totalConfiguredPrice, discount }) => {
	const priceAfterDiscount = applyDiscount(totalConfiguredPrice, discount);

	return (
		<div>
			<LWTypography variant="subtitle2" bold>
				Price
			</LWTypography>
			<LWTypography>
				{Boolean(discount) && <s>{formatCurrency(totalConfiguredPrice)}</s>}
				{/* add a space if there is a discount */}
				{Boolean(discount) && <span> </span>}
				{formatCurrency(priceAfterDiscount, { timeUnit: 'mo' })}
			</LWTypography>
		</div>
	);
};

const createDurationDisplayWithSavings = ({ discount, months }) => {
	if (!discount) {
		return createDurationDisplay(months);
	}

	return `${createDurationDisplay(months)} -
										(Save ${maybeRound(discount * 100)}%)`;
};

/**
 * @param {object} props
 * @param {string} props.code
 * @param {import('club-sauce/public/market/package/product.raw').LWApiPublicMarketPackageProductListResultRawI['items']} props.packageProducts
 */
const ConfigPackageProducts = ({
	code,
	packageProducts,
	zones,
	configIds,
	templates,
}) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const [isAddingToCart, setIsAddingToCart] = useState(false);

	const isBasketAdmin = useSelector(getIsBasketAdmin);
	const {
		allSelectedConfigs,
		allSelectedRegions,
		allProductProperties,
		selectedBillingCycleId,
	} = useSelector(packageConfigSelectors.getStateSlice);

	const {
		billingCycles,
		cartPayload,
		defaultBillingCycle,
		selectedCycle,
		totalConfiguredPrice,
	} = usePackageConfigContext();

	const defaultBillingCycleId = defaultBillingCycle?.id;

	useEffect(() => {
		if (defaultBillingCycleId) {
			dispatch(
				packageConfigActions.setBillingCycle({
					cycleId: defaultBillingCycleId,
				}),
			);
		}
	}, [defaultBillingCycleId, dispatch]);

	const quickAddCaption = useQuickAddCaption({
		code,
		isLoading: isAddingToCart,
	});

	const handleQuickAdd = useQuickAdd({
		onQuickAdd: () => {
			setIsAddingToCart(true);
			dispatch(
				packageConfigActions.quickAddToCart({
					...cartPayload,
					onFinally: () => setIsAddingToCart(false),
				}),
			);
		},
	});

	if (!packageProducts) {
		return null;
	}

	const handleBillingCycleChange = (event) => {
		dispatch(
			packageConfigActions.setBillingCycle({ cycleId: event.target.value }),
		);
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: `${theme.spacing(1)}px`,
			}}
		>
			<PriceText
				totalConfiguredPrice={totalConfiguredPrice}
				discount={selectedCycle?.discount}
			/>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: `${theme.spacing(1)}px`,
				}}
			>
				<LWButton
					endIcon={<AddShoppingCartIcon />}
					isLoading={isAddingToCart}
					onClick={handleQuickAdd}
				>
					Quick Add
				</LWButton>
				<LWTypography variant="caption" color="textSecondary">
					{quickAddCaption}
				</LWTypography>
			</div>
			<ConfigureButton path={`/shop/package/${code}`} />
			{billingCycles.length > 1 && (
				<Box my={1}>
					<FormControl variant="outlined">
						<InputLabel>Billing Cycle</InputLabel>
						<Select
							label="Billing Cycle"
							name="billing-cycle"
							onChange={handleBillingCycleChange}
							value={selectedBillingCycleId}
						>
							{billingCycles.map(({ id, months, discount }) => (
								<MenuItem key={id} value={id}>
									{createDurationDisplayWithSavings({ discount, months })}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Box>
			)}

			{packageProducts.map((product) => {
				const { id } = product;

				const handleConfigsChange = (newSelectedConfigs) => {
					dispatch(
						packageConfigActions.setSelectedConfigs({
							selectedConfigs: newSelectedConfigs,
							packageProductId: id,
						}),
					);
				};

				const handleAddProperty = (key, value) => {
					dispatch(
						packageConfigActions.addProperty({
							key,
							value,
							packageProductId: id,
						}),
					);
				};

				const handleRemoveProperty = (key) => {
					dispatch(
						packageConfigActions.deleteProperty({
							key,
							packageProductId: id,
						}),
					);
				};

				return (
					<Box key={id} my={1}>
						<ProductConfigProviders
							discountAmount={selectedCycle?.discount}
							isBasketAdmin={isBasketAdmin}
							networkZoneItems={zones}
							onAddProperty={handleAddProperty}
							onConfigsChange={handleConfigsChange}
							onRemoveProperty={handleRemoveProperty}
							packageProduct={product}
							selectedConfigs={allSelectedConfigs[id]}
							selectedDataCenter={allSelectedRegions[id] || '1'}
							selectedProperties={allProductProperties[id]}
							selectedZone={allProductProperties[id]?.zone}
							stormConfigIdItems={configIds}
							stormTemplateItems={templates}
						>
							<SimpleConfigProduct amountProducts={packageProducts.length} />
						</ProductConfigProviders>
					</Box>
				);
			})}
		</div>
	);
};

export default ConfigPackageProducts;

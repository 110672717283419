import { createMuiTheme } from '@material-ui/core/styles';

const theme = {
	palette: {
		common: {
			// Custom colors
			accent: {
				0: '#3F485E',
				1: '#7D3D67',
				'0light': '#66728b',
				'0dark': '#122034',
				'1light': '#ae6a95',
				'1dark': '#4f113c',
			},
			// Custom colors
			orange: '#EAC000',
			orange2: '#FCB043',
			yellowgreen: '#CEDA07',
			green: '#7BC730',
			bluegreen: '#52B380',
			blue: '#4EA0AA',
			bluepurple: '#4563A2',
			// TODO remove, not a MUI key
			black2: '#3d3d3d',
			white: '#fff',
			white1: '#ffffff',
			white2: '#f5f5f5',
			red: '#c62828',
			red2: '#f1c9c9',
			green1: '#e1f0e7',
			green2: '#c3e0ce',
			green3: '#a6d3b6',
			green4: '#89c29e',
			green5: '#7cc32e',
			green6: '#4caf50',
			green7: '#d2ebd3',
			blue1: '#9cd2eb',
			blue2: '#6cc2e6',
			blue3: '#04b2e1',
			blue4: '#00345f',
			blue5: '#5ed1fd',
			blue6: '#d7f3ff',
			blue7: '#d5ed20',
			blue8: '#30a9d3',
			blue9: '#03a0ca00',
			blue10: '#30a6e2',
			blue11: '#E6F8FF',
			orange1: '#efb061',
			purple1: '#894b78',
			purple3: '#633454',
			azure: '#26aae1',
			bluebar: '#2b6c98',
			yellow1: '#dbba00',
			yellow3: '#ffcc00',
			yellow4: '#ffeda5', // highlighted text background
			transparent: 'transparent',
		},
		divider: '#E5E5E5',
		background: {
			default: '#fff',
			dark: '#45556a',
			darker: '#2e3c4b',
			red: '#e0735e',
		},
		grey: {
			ultraSoft: '#F7F7F8',
			light: '#00000014',
			disabled: '#00000033',
			disabledOpaque: '#d2d2d2', // non-alpha hexes are required for gradients
			scrim: '#00000052',
			dark: '#7A7A7A',
			medium: '#E5E5E5',
		},
		other: {
			info: '#9cd2eb',
		},
		tertiary: {
			light: '#ECEBFF',
		},
		secondary: {
			light: '#BAB3FE',
			alt: '#1202C0',
			main: '#5647FD',
			dark: '#0C017E',
			contrastText: '#fff',
			// Custom, not MUI keys
			faint: '#ECEBFF',
			faintOpaque: '#ECEBFF',
			washed: '#BAB3FE',
			washedOpaque: '#BAB3FE',
			faded: '#BAB3FE',
			fadedOpaque: '#BAB3FE',
		},
		primary: {
			light: '#E6E6E6',
			alt: '#757575',
			main: '#0F0F0F',
			dark: '#000',
			contrastText: '#fff',
			// Custom, not MUI keys
			faint: '#F5F5F5',
			faintOpaque: '#F5F5F5',
			washed: '#E6E6E6',
			washedOpaque: '#E6E6E6',
			faded: '#C9C9C9',
		},
		error: {
			main: '#C62828',
			dark: '#9D1A1A',
			contrastText: '#fff',
			fadedOpaque: '#c27171',
		},
		success: {
			main: '#4CAF50',
			dark: '#317C34',
		},
		text: {
			primary: '#000000DD',
			secondary: '#00000098',
			secondaryOpaque: '#717171',
			disabled: '#00000062',
			error: '#C62828',
			// TODO remove, not a MUI key
			light: '#d2d2d2',
		},
		graph: ['#4563A2', '#4EA0AA', '#52B380', '#7BC730', '#CEDA07', '#EAC000'],
	},
	mixins: {
		content: {
			width: '100%',
			margin: '0em',
		},
		info: {
			color: '#fff',
		},
		warning: {
			color: '#fff',
		},
		error: {
			color: '#fff',
		},
		verticalCenter: {
			position: 'relative',
			top: '50%',
			transform: 'translateY(-50%)',
		},
		horizontalCenter: {
			width: 'fit-content',
			margin: '0 auto',
		},
		subheader: {
			fontSize: '1rem',
			color: '#a4a4a4',
		},
		subtitle: {
			fontSize: '0.75em',
			secondary: '#a4a4a4',
		},
		alignCenter: {
			display: 'flex',
			alignItems: 'center',
		},
		hidden: {
			color: 'transparent',
			textShadow: 'rgba(0, 0, 0, 0.95) 0 0 10px',
		},
	},
	spacingPage: '2em',
	typography: {
		fontFamily: 'FK Grotesk Neue',
		h1: {
			fontSize: '2.5rem',
			fontWeight: 600,
		},
		h2: {
			fontSize: '2rem',
			fontWeight: 700,
		},
		h4: { fontSize: '1.75rem' },
		h5: {
			fontSize: '1.5rem',
			fontWeight: 600,
		},
		h6: {
			fontSize: '1.25rem',
		},
		subtitle1: {
			fontWeight: 600,
			fontSize: '1.125rem',
			lineHeight: 'inherit',
		},
		subtitle2: {
			fontSize: '.875rem',
			lineHeight: 'inherit',
		},
		caption: {
			fontSize: '.75rem',
			lineHeight: 'inherit',
		},
		body2: {
			fontSize: '.875rem',
		},
		button: {
			fontWeight: 600,
		},
	},
	overrides: {
		MuiDialog: {
			paper: {
				borderRadius: 16,
			},
		},
		MuiDialogContent: {
			root: {
				padding: '16px 24px',
			},
		},
		MuiDialogActions: {
			spacing: {
				'& > :not(:first-child)': {
					marginLeft: 16,
				},
			},
		},
		MuiAutocomplete: {
			popper: {
				// Need this to display the autocomplete properly in modals and sidesheet
				zIndex: 1403,
			},
		},
		MuiCheckbox: {
			root: {
				color: '#000',
			},
		},
		MuiFormControlLabel: {
			root: {
				width: 'fit-content',
			},
			label: {
				color: '#000000DD',
			},
		},
		MuiTypography: {
			h2: {
				color: '#4a4a4a',
			},
			h3: {
				fontSize: '2rem',
			},
		},
		MuiIconButton: {
			root: {
				padding: 6,
			},
		},
		MuiButtonBase: {
			root: { borderWidth: '2px' },
		},
		MuiPopover: {
			root: {
				// Need this to display properly in modals and sidesheet
				zIndex: [1403, '!important'],
			},
		},
		MuiCardContent: {
			root: {
				padding: '32px',
				'&:last-child': {
					paddingBottom: '32px',
				},
			},
		},
		MuiFormControl: {
			root: {
				width: '100%',
			},
		},
		MuiPaper: {
			root: {
				borderRadius: '8px',
			},
			rounded: {
				borderRadius: '8px',
			},
			outlined: {
				boxShadow:
					'0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
			},
		},
		MuiTabs: {
			root: {
				borderRadius: '0px',
				'& button': {
					minWidth: '0px',
				},
			},
		},
		PrivateSwitchBase: {
			root: {
				padding: '8px',
			},
		},
	},
	maxWidth: 1376,
	sideSheetWidth: 400,
	zIndex: {
		// https://v4.mui.com/customization/z-index/#z-index
		accountBarPopUp: 98,
		snackbar: 1401,
		chatLoader: 2000,
		sideSheet: 1299, // behind the modals, which is 1300
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 992,
			lg: 1280,
			xl: 1920,
		},
	},
};

export { theme };
export default createMuiTheme(theme);

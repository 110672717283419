import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.account.invoicesAndPaymentsTimeline;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	method: 'POST',
	actionType: 'ACCOUNT_INVOICES_AND_PAYMENTS_TIMELINE',
	url: '/account/invoicesAndPaymentsTimeline.json',
});

export { actions, reducer, sagas, selectors };

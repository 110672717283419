import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.licensing.cpanel.changeVariety;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'LICENSING_CPANEL_CHANGEVARIETY',
	method: 'POST',
	url: '/licensing/cpanel/changeVariety.json',
});

const selectors = {
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
